import { useCarriersQuery } from '../../../../../graphql';
import { useMemo } from 'react';

export const useCarrierOptions = () => {
  const { data, loading, error } = useCarriersQuery();
  const carrierOptions = useMemo(() => (
    data?.getAllMedicareCarriers?.map((carrier) => ({
      label: carrier.name,
      value: carrier.id,
    })) ?? []
  ), [data]);
  return {
    loading,
    error,
    carrierOptions,
  };
};
