import { isPresent } from '@healthiqeng/core.util.is-present';
import { CompareRuleOperator, PrimitiveValue } from '../../types';
import { CompareRuleProperties } from './passesCompareRule.interfaces';

export function passesCompareRule(value: PrimitiveValue | PrimitiveValue[], compareRule: CompareRuleProperties): boolean {
  switch (compareRule.operator) {
    case CompareRuleOperator.Equals:
      return value === compareRule.compareValue;
    case CompareRuleOperator.NotEquals:
      return value !== compareRule.compareValue;
    case CompareRuleOperator.IsPresent:
      return isPresent(value);
    case CompareRuleOperator.IsNotPresent:
      return !isPresent(value);
    case CompareRuleOperator.Includes:
      return Array.isArray(value)
        && !Array.isArray(compareRule.compareValue)
        && value.includes(compareRule.compareValue);
    case CompareRuleOperator.IsOneOf:
      return Array.isArray(compareRule.compareValue)
        && !Array.isArray(value)
        && compareRule.compareValue.includes(value);
    default:
      throw new Error(`Unknown compare rule operator: ${compareRule.operator}`);
  }
}
