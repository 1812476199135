import * as React from 'react';
import { ProductTypePropertyAndCasualty } from '@hiq/crm.types';
import {
  PNCBaseExternalQuote,
  PrimaryButton,
  Card,
  useConfirmedFinalQuote,
} from '../../../../../../common';
import { usePropertyAndCasualtyAutoQuoteLazyQuery } from '../../../../../../../graphql';
import { useSelectedQuoteDetector } from '../../../../useSelectedQuoteDetector';
import { PropertyAndCasualtySelectedQuotes } from '../PropertyAndCasualtySelectedQuotes';
import { PropertyAndCasualtyQuoter } from '../common';
import { PropertyAndCasualtyFinalQuote } from '../common/PropertyAndCasualtyFinalQuote';

export const AutoQuotePanel: React.FunctionComponent = () => {
  const [getQuotes, { loading, data, error }] = usePropertyAndCasualtyAutoQuoteLazyQuery();
  const hasSelectedQuote = useSelectedQuoteDetector();
  const [useExternal, setUseExternal] = React.useState(false);
  const [confirmedFinalQuote] = useConfirmedFinalQuote();

  if (hasSelectedQuote) {
    return (
      <>
        <PropertyAndCasualtySelectedQuotes />
        { !confirmedFinalQuote && <PropertyAndCasualtyFinalQuote productType={ProductTypePropertyAndCasualty.Auto} />}
      </>
    );
  }

  return (
    <>
      <PrimaryButton onClick={() => setUseExternal(!useExternal)}>
        { useExternal ? 'Use EZLynx Quotes' : 'Use External Quote' }
      </PrimaryButton>
      { useExternal && (
        <Card>
          <PNCBaseExternalQuote
            hasSpecialRate
            productType={ProductTypePropertyAndCasualty.Auto}
          />
        </Card>
      ) }
      { !useExternal && (
        <PropertyAndCasualtyQuoter
          getQuotes={getQuotes}
          quoteResults={data?.getPropertyAndCasualtyAutoQuotes?.quoteResults}
          quoteErrors={data?.getPropertyAndCasualtyAutoQuotes?.errors}
          loading={loading}
          error={error}
        />
      ) }
    </>
  );
};
