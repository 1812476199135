import React from 'react';
import { Icon } from '../../Icon';
import { SendQuoteEmailButtonContainer, FeedbackMessage } from './SendQuoteEmailButton.elements';
import { PrimaryButton } from '../../inputs';
import { useSendQuoteEmail } from '../../../../graphql/mutations/useSendQuoteEmailMutation';
import { useActiveLeadId } from '../../../routers';

export const SendQuoteEmailButton = () => {
  const leadId = useActiveLeadId();
  const [sendQuoteEmail, { data, loading }] = useSendQuoteEmail();
  const handleClick = () => {
    sendQuoteEmail({ leadId });
  };
  const error = !data?.sendQuotesEmail?.success;
  const message = data?.sendQuotesEmail?.message;

  return (
    <SendQuoteEmailButtonContainer>
      <PrimaryButton
        onClick={handleClick}
        disabled={loading}
        endIcon={<Icon type="email" />}
      >
        { loading ? 'Sending...' : 'Email quotes' }
      </PrimaryButton>
      { data && (<FeedbackMessage error={error}>{message ?? 'Success'}</FeedbackMessage>)}
    </SendQuoteEmailButtonContainer>
  );
};
