/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import throttle from 'lodash.throttle';
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { JsonCompatible } from '@healthiqeng/core.types';
import { useActiveLeadId } from '../../routers';
import {
  VehicleDriverAssociationContainer,
  VehicleDriverAssociationTitle,
  VehicleDriverAssociationTable,
} from './VehicleDriverAssociation.elements';
import { getVehiclesAssignationSum, getItemsArray, getDrivers } from './utils';
import { useFormAnswers } from '../../../hooks';
import { VehicleAssignationData } from './VehicleDriverAssociation.interfaces';
import { VehicleDriverAssociationInput } from './VehicleDriverAssociationInput';
import { TotalUsePerVehicleRow } from './TotalUsePerVehicleRow';

export const VehicleDriverAssociation: FC = () => {
  const leadId = useActiveLeadId();
  const trackingId = React.useMemo(() => `CustomerCommunication-${Date.now()}`, []);
  const [formAnswers, onChange, persistAnswers] = useFormAnswers(leadId, false, trackingId);

  const vehicles = (getItemsArray('lead.vehicles.', formAnswers) || []) as JsonCompatible[];
  const drivers = getDrivers(formAnswers);
  const associations = (getItemsArray('lead.driversAssignment.', formAnswers) || []) as VehicleAssignationData;

  if (drivers.length <= 1) return null;

  const handlePersistThrottled = throttle(persistAnswers, 1000);

  const onAssociationChange = (driverIndex: number, vehicleIndex: number, amount?: number) => {
    onChange(`lead.driversAssignment.${driverIndex}.vehicles.${vehicleIndex}`, amount);
    handlePersistThrottled();
  };

  const vehiclesAssignationSum = getVehiclesAssignationSum(associations, vehicles.length);

  return (
    <VehicleDriverAssociationContainer>
      <VehicleDriverAssociationTitle>
        Vehicle Assignment
      </VehicleDriverAssociationTitle>

      <Alert severity="warning">
        Total use per vehicle must total 100%. Driver assignment cannot be 50% and must be a whole number.
      </Alert>

      <TableContainer>
        <VehicleDriverAssociationTable vehiclesAmount={vehicles.length}>
          <TableHead>
            <TableRow>
              <TableCell>Drivers</TableCell>
              {vehicles.map((vehicle: Record<string, any>, vehicleIndex: number) => (
                <TableCell key={`vehicle-header-cell-${vehicleIndex}`}>
                  {(vehicle.make
                    ? `${vehicle.make} ${vehicle.model || ''}`
                    : `Vehicle ${vehicleIndex + 1}`
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {drivers.map((driver: Record<string, any>, driverIndex: number) => (
              <TableRow key={`driver-row-${driverIndex}`}>
                <TableCell>
                  {driver.name?.first || `Driver ${driverIndex + 1}`}
                </TableCell>

                {vehicles.map((_vehicle: Record<string, any>, vehicleIndex: number) => (
                  <TableCell
                    key={`vehicle-cell-${vehicleIndex}`}
                    align="left"
                  >
                    <VehicleDriverAssociationInput
                      driverIndex={driverIndex}
                      vehicleIndex={vehicleIndex}
                      onChange={onAssociationChange}
                      value={associations[driverIndex] ? associations[driverIndex].vehicles?.[vehicleIndex] : 0}
                      vehicleAssignationSum={vehiclesAssignationSum[vehicleIndex]}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}

            <TotalUsePerVehicleRow vehiclesAssignationSum={vehiclesAssignationSum} />
          </TableBody>
        </VehicleDriverAssociationTable>
      </TableContainer>
    </VehicleDriverAssociationContainer>
  );
};
