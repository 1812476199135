import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import { AutomatedUnderwritingDecision } from '@hiq/crm.types';
import { TextRenderer } from '../../../QuestionFlow/QuestionFlowRenderer/TextRenderer';
import { ParagraphRenderer } from '../../../QuestionFlow/QuestionFlowRenderer/ParagraphRenderer';
import { BlockQuoteRenderer } from '../../../QuestionFlow/QuestionFlowRenderer/BlockQuoteRenderer';
import { HeadingRenderer } from '../../../QuestionFlow/QuestionFlowRenderer/HeadingRenderer';
import { scriptBlockRendererFactory } from '../../../QuestionFlow/QuestionFlowRenderer/ScriptBlockRenderer';
import { LinkRenderer } from '../../../QuestionFlow/QuestionFlowRenderer/LinkRenderer';
import { useQuestionFlowQuery } from '../../../../../graphql';
import { UnderwritingResponseScriptProps } from './UnderwritingResponseScript.interfaces';
import { QuestionFlowComponent } from '../../../QuestionFlow/QuestionFlow.interfaces';
import { QuestionFlowRendererComponent } from '../../../QuestionFlow/QuestionFlowRenderer/QuestionFlowRenderer.interfaces';

const resultScript = (decision: AutomatedUnderwritingDecision, preferredResponse?: string): string => {
  if (decision === AutomatedUnderwritingDecision.ApprovedPreferred) {
    return 'auto-underwriting-result-preferred-passed';
  }

  if (decision === AutomatedUnderwritingDecision.ApprovedStandard) {
    switch (preferredResponse) {
      case 'no applicable tests': return 'auto-underwriting-result-standard-no-tests';
      case 'no hit': return 'auto-underwriting-result-standard-no-hit';
      case 'applicable test, but data cannot be processed': return 'auto-underwriting-result-standard-bad-data';
      case 'fail':
      default:
        return 'auto-underwriting-result-standard-failed';
    }
  }

  if (decision === AutomatedUnderwritingDecision.ApprovedClass1) {
    return 'auto-underwriting-result-class-one';
  }

  return 'auto-underwriting-result-declined';
};

const SimplifiedQuestionFlowRenderer: QuestionFlowRendererComponent = ({ leadId, markdownSource, questionFlowComponent }) => {
  const ScriptBlockRenderer = React.useMemo(
    () => scriptBlockRendererFactory(leadId, questionFlowComponent, SimplifiedQuestionFlowRenderer),
    [],
  );
  return (
    <ReactMarkdown
      linkTarget="_blank"
      source={markdownSource}
      renderers={{
        heading: HeadingRenderer,
        blockquote: BlockQuoteRenderer,
        text: TextRenderer,
        paragraph: ParagraphRenderer,
        code: ScriptBlockRenderer,
        link: LinkRenderer,
      }}
    />
  );
};

const SimplifiedQuestionFlow: QuestionFlowComponent = ({ leadId, referenceId }) => {
  const { data: resultScriptData } = useQuestionFlowQuery(leadId, referenceId, { root: true });
  return (
    <SimplifiedQuestionFlowRenderer
      markdownSource={resultScriptData?.questionFlow?.body || ''}
      leadId={leadId}
      questionFlowComponent={SimplifiedQuestionFlow}
    />
  );
};

export const UnderwritingResponseScript: React.FunctionComponent<UnderwritingResponseScriptProps> = ({
  leadId,
  decision,
  preferredResponse,
}) => {
  const referenceId = resultScript(decision, preferredResponse);
  return (
    <SimplifiedQuestionFlow
      leadId={leadId}
      referenceId={referenceId}
    />
  );
};
