import styled from '@emotion/styled';
import { css } from '@emotion/core';

export const InputWidthLimiterContainer = styled('div')(() => css`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, max(250px, 33%));
  grid-gap: 10px;
  row-gap: 5px;
`);
