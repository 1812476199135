import React from 'react';
import { QuestionView } from '../FormGenerator/FormGeneratorQuestionFlow/Question/QuestionView';
import {
  CommunicationMethod,
  communicationMethodLabelMap,
  QuestionType,
} from '@hiq/crm.types';
import { CustomerCommunicationQuestionsProps } from './CustomerCommunicationQuestions.interfaces';
import { getEnumOptions } from '../../../util/getEnumOptions';
import { GenderOptions } from '../../../constants/options';
import Alert from '@material-ui/lab/Alert/Alert';
import { CustomerEmailWithWarning } from '../CustomerEmailWithWarning';
import { useFormAnswers } from '../../../hooks';

export const CustomerCommunicationQuestions: React.FunctionComponent<CustomerCommunicationQuestionsProps> = ({
  includeFirstName = true,
  includeLastName = true,
  leadId,
  formAnswers,
  includeGender,
  includeZipCode,
  includeEmail = true,
  includePhone = true,
  includeDOB,
  onChange,
  error,
  data,
  includeCommunicationPreference,
}) => {
  const [, autoSaveOnChange] = useFormAnswers(leadId, true);

  const onChangeHandler = onChange ?? autoSaveOnChange;

  const onFirstNameChange = React.useCallback((value) => onChangeHandler('customer.person.name.first', value, true), [onChangeHandler]);
  const onLastNameChange = React.useCallback((value) => onChangeHandler('customer.person.name.last', value, true), [onChangeHandler]);
  const onGenderChange = React.useCallback((value) => onChangeHandler('customer.person.gender', value), [onChangeHandler]);
  const onDobChange = React.useCallback((value) => onChangeHandler('customer.person.dateOfBirth', value), [onChangeHandler]);
  const onZipChange = React.useCallback((value) => onChangeHandler('customer.person.homeAddress.zip', value), [onChangeHandler]);
  const onPhoneChange = React.useCallback((value) => onChangeHandler('customer.person.phone.number', value), [onChangeHandler]);
  const onContactPreferenceChange = React.useCallback(
    (value) => onChangeHandler('customer.person.contactPreference', value),
    [onChangeHandler],
  );
  return (
    <>
      {includeFirstName && (
        <QuestionView
          id="customer.person.name.first"
          questionText="Confirm First Name"
          label="Confirm First Name"
          type={QuestionType.Text}
          leadId={leadId}
          value={formAnswers['customer.person.name.first']}
          onChange={onFirstNameChange}
        />
      )}
      {includeLastName && (
        <QuestionView
          id="customer.person.name.last"
          questionText="Confirm Last Name"
          label="Confirm Last Name"
          type={QuestionType.Text}
          leadId={leadId}
          value={formAnswers['customer.person.name.last']}
          onChange={onLastNameChange}
        />
      )}
      {includeGender && (
        <QuestionView
          id="customer.person.gender"
          questionText="Confirm Gender"
          label="Confirm Gender"
          type={QuestionType.Radio}
          options={GenderOptions}
          leadId={leadId}
          value={formAnswers['customer.person.gender']}
          onChange={onGenderChange}
        />
      )}
      {includeDOB && (
        <QuestionView
          id="customer.person.dateOfBirth"
          questionText="Confirm Date of Birth"
          label="Confirm Date of Birth"
          type={QuestionType.Date}
          leadId={leadId}
          value={formAnswers['customer.person.dateOfBirth']}
          onChange={onDobChange}
        />
      )}
      {includeZipCode && (
        <QuestionView
          id="customer.person.homeAddress.zip"
          questionText="Confirm Zip Code"
          label="Confirm Zip Code"
          type={QuestionType.ZipCode}
          leadId={leadId}
          value={formAnswers['customer.person.homeAddress.zip']}
          onChange={onZipChange}
        />
      )}
      {includeEmail && (
        <CustomerEmailWithWarning />
      )}
      {includePhone && (
        <QuestionView
          id="customer.person.phone.number"
          questionText="Confirm Phone Number"
          label="Confirm Phone Number"
          type={QuestionType.Phone}
          leadId={leadId}
          value={formAnswers['customer.person.phone.number']}
          onChange={onPhoneChange}
        />
      )}
      {includeCommunicationPreference && (
        <QuestionView
          id="customer.person.contactPreference"
          questionText="Preferred Contact Method"
          label="Preferred Contact Method"
          type={QuestionType.Radio}
          options={getEnumOptions(CommunicationMethod, communicationMethodLabelMap)}
          leadId={leadId}
          value={formAnswers['customer.person.contactPreference']}
          onChange={onContactPreferenceChange}
        />
      )}
      { error && (
        <Alert severity="error">
          { error.message }
        </Alert>
      ) }
      { data?.sendCommunication?.message && (
        <Alert severity={data?.sendCommunication?.success ? 'success' : 'error'}>
          { data?.sendCommunication?.message }
        </Alert>
      ) }
    </>
  );
};
