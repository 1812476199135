import { isPresent } from '@healthiqeng/core.util.is-present';
import { InputTransformer } from '../InputTransformer';

export class NumberTransformer extends InputTransformer<number> {
  constructor(initialValue: number) {
    super(initialValue);
    this.draftValue = NumberTransformer.normalizeNumberString(`${initialValue}`);
  }

  public update(nextValue: string) {
    this.updateDraftValue(nextValue);
    this.updatePersistedValue();
  }

  public getDraftValue() {
    if (!isPresent(this.draftValue)) {
      return '';
    }

    return formatNumber(this.draftValue);
  }

  public getPersistedValue() {
    return this.persistedValue;
  }

  public getFormattedValue() {
    return formatNumber(`${this.getPersistedValue()}`);
  }

  protected updateDraftValue(value: string) {
    this.draftValue = NumberTransformer.normalizeNumberString(value);
  }

  protected updatePersistedValue() {
    if (!isPresent(this.draftValue)) {
      this.persistedValue = null;
      return;
    }

    if (this.draftValue.includes('.')) {
      this.persistedValue = Number.parseFloat(this.draftValue);
      return;
    }
    this.persistedValue = Number.parseInt(this.draftValue);
  }

  public static normalizeNumberString(value: string): string {
    if (!isPresent(value)) {
      return null;
    }
    let cleanedValue = value
      .replace(/[^0-9.]/g, '')
      .replace(/\.+/g, '.');

    if ((cleanedValue.match(/\./g) ?? []).length > 1) {
      cleanedValue = cleanedValue.replace(/\./, '_')
        .replace(/\./g, '')
        .replace(/_/, '.');
    }
    return cleanedValue;
  }
}

function formatNumber(value: string): string {
  const [integer, decimal] = (value ?? '').split('.');
  return [
    isPresent(integer) ? Number.parseInt(integer).toLocaleString() : '0',
    decimal,
  ].filter((val) => val === '' || isPresent(val)).join('.');
}
