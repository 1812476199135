import { gql } from 'apollo-boost';
import { MutationHookOptions } from '@apollo/react-hooks';
import { useMutationCustom } from '../useMutationCustom';
import {
  SendEmailMutationResponse,
  SendEmailMutationVariables,
} from './useSendEmailMutation.interfaces';

const SEND_EMAIL_MUTATION = gql`
  mutation SendEmail($leadId: ID!, $content: String!, $subject: String!) {
    sendEmail(leadId: $leadId, content: $content, subject: $subject) {
      code
      success
      message
      timestamp
    }
  }
`;

export function useSendEmail(options: MutationHookOptions<SendEmailMutationResponse, SendEmailMutationVariables> = {}) {
  return useMutationCustom<SendEmailMutationResponse, SendEmailMutationVariables>(SEND_EMAIL_MUTATION, options);
}
