import styled from '@emotion/styled';

export const QuestionContainer = styled.div`
  width: 100%;
  padding: 10px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const QuantityInputContainer = styled.div`
  width: 70px;
`;
