import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const HelpfulLinksWrapper = styled('div')(css``);
export const HelpfulLinksText = styled('p')(css`
  font-weight: bold;
  font-style: italic;
`);

export const HelpfulLink = styled('a')(css`
  text-decoration: none;
`);
