import * as React from 'react';
import { CustomerSummaryHeader } from '../../CustomerSummaryHeader';
import { useCustomerLatestCallQuery } from '../../../../../graphql';
import { useCustomerId } from '../../../../routers';

export const DialerCallHeader: React.FunctionComponent = () => {
  const customerId = useCustomerId();
  const result = useCustomerLatestCallQuery(customerId);
  const { campaignName, callSkill } = result?.data?.customer?.latestCallEvent ?? {};
  return (
    <CustomerSummaryHeader value={campaignName ?? callSkill} />
  );
};
