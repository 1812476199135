import * as React from 'react';
import { useCustomerQuery } from '../useCustomerQuery';
import { LeadIdContext } from '../../../components/contexts/LeadIdContext';
import { getEventBus } from '../../../events';
import { SetPropsEvent } from '@healthiqeng/core.services.fe-analytics';

export function useLeadQuery(leadId?: string, customerId?: string) {
  const eventBus = getEventBus();
  const result = useCustomerQuery(customerId);
  const resolvedLeadId = getLeadId(leadId);
  const resolvedLead = result.data?.customer?.leads?.find((lead) => lead.id === resolvedLeadId);
  eventBus.emit(new SetPropsEvent({ leadType: resolvedLead?.industry?.type, productType: resolvedLead?.productType }));
  return React.useMemo(() => ({
    ...result,
    data: resolvedLead,
  }), [result, resolvedLead]);
}

function getLeadId(leadId?: string) {
  const leadIdFromContext = React.useContext(LeadIdContext);
  return leadId ?? leadIdFromContext;
}
