import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import {
  SelectedDoctorsQueryData,
  SelectedDoctorsQueryVariables,
} from './useSelectedDoctorsQuery.interfaces';
import { PRECISION_MEDICARE_DOCTOR_FRAGMENT } from '../../fragments';

export const SELECTED_DOCTORS_QUERY = gql`
  query SelectedDoctors($leadId: LeadId!) {
    selectedDoctors(leadId: $leadId) {
      ...PrecisionMedicareDoctorFragment
    }
  }
  ${PRECISION_MEDICARE_DOCTOR_FRAGMENT}
`;

export function useSelectedDoctorsQuery(leadId: string) {
  return useQuery<SelectedDoctorsQueryData, SelectedDoctorsQueryVariables>(SELECTED_DOCTORS_QUERY, {
    variables: { leadId },
  });
}
