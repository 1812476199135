import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../../../themes';

export const ConversionMessageContainer = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid ${theme.color.grayLight};
`);
export const ConversionMessageHeaderContainer = styled('div')(css`
  font-size: 20px;
  display: flex;
`);
export const ConversionMessageIconContainer = styled('div')(css`
  width: 40px;
`);
export const ConversionMessageText = styled('div')(css`
  flex: 1;
`);
