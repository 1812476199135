import * as React from 'react';
import { Link } from '@reach/router';
import { QuestionType } from '@hiq/crm.types';
import { CircularProgress } from '@material-ui/core';
import { InputFactory } from '../../../common/inputs/InputFactory';
import { useCustomerSearch } from './useCustomerSearch';
import { NavBarSearchProps } from './NavBarSearch.interfaces';
import {
  NavBarSearchInputContainer,
  NavBarSearchResultsList,
  NavBarSearchResultsListItem,
  NavBarSearchEmptyMessage,
  NavBarSearchResultsListItemRow,
  NavbarSearchTextInputContainer,
  NavbarSearchLoaderContainer,
} from './NavBarSearch.elements';

export const NavBarSearch: React.FunctionComponent<NavBarSearchProps> = ({ debounce = 750 }) => {
  const [blurTimeout, setBlurTimeout] = React.useState(null);
  const [showSearchResults, setShowSearchResults] = React.useState(false);

  const [customers, searching, searchTerm, setSearchTerm, searchImmediately] = useCustomerSearch(debounce);

  const onTermChange = (term: string) => {
    setShowSearchResults(true);
    setSearchTerm(term);
  };

  const onSearchInputBlur = () => {
    setBlurTimeout(setTimeout(() => setShowSearchResults(false), 500));
  };

  const onSearchEnter = React.useCallback((evt: any, term: string) => {
    if (evt.key === 'Enter' && term.length) {
      if (blurTimeout) clearTimeout(blurTimeout);
      setShowSearchResults(true);
      searchImmediately();
    }
  }, [searchImmediately, showSearchResults]);

  return (
    <NavBarSearchInputContainer>
      <NavbarSearchTextInputContainer>
        <InputFactory
          type={QuestionType.Text}
          value={searchTerm}
          onChange={onTermChange}
          onKeyUp={onSearchEnter}
          onBlur={onSearchInputBlur}
          size="small"
          placeholder="Search customers by name, email or phone..."
          fullWidth
        />
        <NavbarSearchLoaderContainer>
          {searching && <CircularProgress size={16} />}
        </NavbarSearchLoaderContainer>
      </NavbarSearchTextInputContainer>
      {showSearchResults && (
        <NavBarSearchResultsList>
          {customers.length ? customers.map((customer) => (
            <NavBarSearchResultsListItem key={customer.id}>
              <Link to={`/customer/${customer.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <NavBarSearchResultsListItemRow>
                  <strong>{`${customer.person.name.first} ${customer.person.name.last}`}</strong>
                </NavBarSearchResultsListItemRow>
                <NavBarSearchResultsListItemRow>{customer.person.email}</NavBarSearchResultsListItemRow>
                <NavBarSearchResultsListItemRow>
                  Phone:
                  {customer.person.phone.number}
                </NavBarSearchResultsListItemRow>
              </Link>
            </NavBarSearchResultsListItem>
          )) : (
            <NavBarSearchEmptyMessage>
              Nothing to show
            </NavBarSearchEmptyMessage>
          )}
        </NavBarSearchResultsList>
      )}
    </NavBarSearchInputContainer>
  );
};
