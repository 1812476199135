import * as React from 'react';
import { FormItemTag, QuestionType } from '@hiq/crm.types';
import { useActiveLeadId } from '../../routers';
import { useChangeLeadStageMutation, useLeadQuery, useLeadStageOptionsQuery } from '../../../graphql';
import { QuestionView } from '../FormGenerator/FormGeneratorQuestionFlow/Question/QuestionView';
import { QueryLoader } from '../QueryLoader';

export const PropertyAndCasualtyLeadStages: React.FunctionComponent = () => {
  const leadId = useActiveLeadId();
  const { data: lead } = useLeadQuery(leadId);
  const [saveStage, { loading, error }] = useChangeLeadStageMutation();
  const { data: stageOptionsData, loading: stageOptionsLoading } = useLeadStageOptionsQuery(leadId);

  const handleChange = (stage: string) => {
    saveStage({
      leadId,
      stageInput: {
        stage,
        subStage: null,
      },
    });
  };

  return (
    <QueryLoader
      loading={loading || stageOptionsLoading}
      error={error}
      componentName="PropertyAndCasualtyLeadStages"
    >
      <>
        <QuestionView
          id="stage"
          value={lead?.stage?.stage}
          tags={[FormItemTag.RequiredToConvert]}
          onChange={handleChange}
          options={stageOptionsData?.leadStageOptions ?? []}
          questionText="Policy Stage"
          label="Policy Stage"
          type={QuestionType.Select}
          leadId={leadId}
          loading={loading || stageOptionsLoading}
          locked={lead?.isSold}
        />
      </>
    </QueryLoader>
  );
};
