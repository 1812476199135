import * as React from 'react';
import { HealthConditionProps } from './BankersHealthCondition.interfaces';
import { BankersHealthConditionDetailQuestions } from './BankersHealthConditionDetailQuestions';
import { ReflexiveQuestion } from '../ReflexiveQuestionInput';

export const BankersHealthCondition: React.FunctionComponent<HealthConditionProps> = React.memo(({
  defaultValue,
  disabled,
  answersKey,
}) => (
  <ReflexiveQuestion
    answersKey={answersKey}
    defaultValue={defaultValue}
    disabled={disabled}
    initialQuestionIdSuffix="hasCondition"
    FollowUpComponent={BankersHealthConditionDetailQuestions}
  />
));
