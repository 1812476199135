import * as React from 'react';
import { animated, useSpring } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import { TransitionInPortal } from '../TransitionInPortal';
import { ISwipeableSidebarProps } from './SwipeableSidebar.interfaces';

const interpolateTranslateX = (x: number) => `translateX(${x}px`;

function SidebarView(props: ISwipeableSidebarProps & { transitionStateSwitcher: () => void }) {
  const width = props.width || 320;
  const [animatedProps, setAnimation] = useSpring(() => ({
    pos: width,
    opacity: 0,
    config: {
      tension: 250,
      friction: 26,
      clamp: true,
    },
    onRest: props.transitionStateSwitcher,
  }));

  const bind = useDrag(({
    movement, memo = animatedProps.pos.getValue(), down, xy, initial,
  }) => {
    if (xy[0] > initial[0]) {
      if (down) {
        setAnimation({
          pos: memo + movement[0],
          opacity: (1 - ((xy[0] - initial[0]) / width)) * 0.7,
        });
      } else {
        setAnimation({
          pos: 0,
          opacity: 0.7,
        });
        if ((xy[0] - initial[0] > 70)) {
          props.onSwipe();
        }
      }
    }
    return memo;
  });

  if (props.open) {
    setAnimation({
      pos: 0,
      opacity: 0.7,
    });
  } else {
    setAnimation({
      pos: width,
      opacity: 0,
    });
  }

  return (
    <div
      style={{
        position: 'fixed',
        zIndex: 1,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <animated.div
        style={{
          position: 'absolute',
          zIndex: 0,
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'black',
          opacity: animatedProps.opacity,
        }}
        onClick={props.onOverlayClick}
      />
      <animated.div
        {...bind()}
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          width: `${width + 100}px`,
          height: '100vh',
          zIndex: 1,
          transform: animatedProps.pos.interpolate(interpolateTranslateX),
        }}
      />
      <animated.div
        {...bind()}
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          width: `${width}px`,
          height: '100vh',
          zIndex: 1,
          background: 'white',
          transform: animatedProps.pos.interpolate(interpolateTranslateX),
        }}
      >
        {props.children}
      </animated.div>
    </div>
  );
}

export function SwipeableSidebar(props: ISwipeableSidebarProps) {
  return (
    <TransitionInPortal
      opened={props.open}
      render={(transitionState, stateSwitcher) => (
        <SidebarView
          {...props}
          transitionStateSwitcher={stateSwitcher}
        >
          {props.children}
        </SidebarView>
      )}
    />
  );
}
