import * as React from 'react';
import { QuestionViewProps } from './QuestionView.interfaces';
import { InputFactory } from '../../../../inputs/InputFactory';
import { QuestionCell } from './QuestionCell';

const QuestionViewComponent: React.FunctionComponent<QuestionViewProps> = ({
  id,
  questionText,
  tags,
  type,
  placeholder,
  helpText,
  options,
  value,
  onChange,
  locked,
  CustomViewRenderer,
  loading,
  size,
  dataQa,
  referenceId,
  answersKey,
  optionsId,
  dependencies,
  arrayFormIndex,
  defaultValue,
  minLength,
  maxLength,
  allowClientRefused,
  overrideQuestionText,
  maxItems,
}) => (
  <QuestionCell
    questionText={questionText}
    tags={tags}
    helpText={helpText}
    CustomViewRenderer={CustomViewRenderer}
    overrideQuestionText={overrideQuestionText}
  >
    <InputFactory
      id={id}
      tags={tags}
      type={type}
      placeholder={placeholder}
      options={options}
      value={value}
      onChange={onChange}
      variant="outlined"
      fullWidth
      disabled={locked}
      loading={loading}
      size={size}
      dataQa={dataQa}
      referenceId={referenceId}
      answersKey={answersKey}
      optionsId={optionsId}
      dependencies={dependencies}
      arrayFormIndex={arrayFormIndex}
      defaultValue={defaultValue}
      minLength={minLength}
      maxLength={maxLength}
      allowClientRefused={allowClientRefused}
      maxItems={maxItems}
    />
  </QuestionCell>
);

export const QuestionView = React.memo(QuestionViewComponent);
