import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { CriticalDoctorsQueryData, CriticalDoctorsVariables } from './useCriticalDoctorsQuery.interfaces';
import { PRECISION_MEDICARE_CRITICAL_DOCTOR_FRAGMENT } from '../../fragments';

export const CRITICAL_DOCTORS_QUERY = gql`
  query CriticalDoctors($leadId: LeadId!) {
    criticalDoctors(leadId: $leadId) {
      ...PrecisionMedicareCriticalDoctorFragment
    }
  }
  ${PRECISION_MEDICARE_CRITICAL_DOCTOR_FRAGMENT}
`;

export function useCriticalDoctorsQuery(leadId: string) {
  return useQuery<CriticalDoctorsQueryData, CriticalDoctorsVariables>(CRITICAL_DOCTORS_QUERY, { variables: { leadId } });
}
