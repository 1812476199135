import * as React from 'react';
import { Spring } from 'react-spring/renderprops.cjs';
import { Transition } from 'react-transition-group';
import { ExpanderBody, ExpanderFrame } from './Expander.elements';
import { IExpanderProps } from './Expander.interfaces';

export class Expander extends React.Component<IExpanderProps> {
  public bodyRef = React.createRef<HTMLDivElement>();
  public frameRef = React.createRef<HTMLDivElement>();

  public componentDidMount() {
    if (this.props.opened) {
      this.forceUpdate();
    }
  }

  public render() {
    return (
      <Transition in={this.props.opened} mountOnEnter timeout={0}>
        {(transitionState) => {
          let prevHeight = 0;
          let nextHeight = 0;
          if (this.bodyRef.current) {
            prevHeight = this.frameRef.current.offsetHeight;
          }
          if (this.props.opened && this.bodyRef.current) {
            nextHeight = this.bodyRef.current.offsetHeight;
          }
          if (transitionState === 'entering' || transitionState === 'entered') {
            if (this.bodyRef.current) {
              nextHeight = this.bodyRef.current.offsetHeight;
            }
          }
          return (
            <Spring
              native
              from={{ height: prevHeight }}
              to={{ height: nextHeight }}
            >
              {(animatedStyles) => (
                <ExpanderFrame style={animatedStyles} ref={this.frameRef}>
                  <ExpanderBody ref={this.bodyRef}>
                    {this.props.children}
                  </ExpanderBody>
                </ExpanderFrame>
              )}
            </Spring>
          );
        }}
      </Transition>
    );
  }
}
