import React from 'react';
import { PlanSelectionPlan } from '@hiq/crm.types';

export interface CompareContextData {
  currentPlan?: PlanSelectionPlan
  currentComparisonPlan?: PlanSelectionPlan
  totalPlans: number
  totalRecommendedPlans: number
  planType?: string,
  zipCode?: string,
  customerCode?: string
  countyCode?: string,
  nextPlan: () => void
  previousPlan: () => void
  currentComparisonPlanIndex: number,
  baseUrl: string,
  emitPlanComparisonEvent: (message: string, properties?: object) => void
  updateCarrierFilter:(carrier: Array<string>) => void
  carriers: Array<string>
  carrierFilter: Array<string>
  updateRatingFilter:(rating: Array<number>) => void
  ratingFilter: Array<number>
  resetFilters: () => void
}

export const CompareContext = React.createContext<CompareContextData>({
  baseUrl: '',
  currentPlan: undefined,
  currentComparisonPlan: undefined,
  currentComparisonPlanIndex: 0,
  totalPlans: 0,
  totalRecommendedPlans: 0,
  nextPlan: () => {},
  previousPlan: () => {},
  emitPlanComparisonEvent: () => {},
  updateCarrierFilter: () => {},
  carriers: [],
  carrierFilter: [],
  updateRatingFilter: () => {},
  ratingFilter: [],
  resetFilters: () => {},
});
