import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../../themes';
import { css } from '@emotion/core';

export const ScriptBlockContainer = styled('div')(css`
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  width: 100%;
`);

export const ScriptBlockContent = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  padding: 24px;
  background-color: ${theme.color.primaryLighter};
  border: 1px solid ${theme.color.grayLight};
  border-radius: 0px 5px 5px 0px;
  display: flex;
  flex-direction: row;
  width: 100%;
`);

export const ScriptBlockIconContainer = styled('div')(css`
  margin-right: 20px;
  width: 45px;
  height: 35px;
`);

export const ScriptBlockIcon = styled('img')(css`
  position: relative;
  top: 5px;
`);

interface ScriptBlockColorDivProps {
  color: string;
}

export const ScriptBlockColorDiv = styled('div')<ScriptBlockColorDivProps>(({ color }) => css`
  position: relative;
  width: 10px;
  border: 5px solid ${color};
  border-radius: 5px 0px 0px 5px;
`);

interface ScriptBlockTextProps {
  color?: string
}

export const ScriptBlockText = styled('div')<ScriptBlockTextProps>`
  font-size: 14px;
  p {
    margin-top: 0;
  }

  span {
    margin-top: 0;
  }
`;

export const ScriptBlockConfirmContainer = styled('div')(css`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
`);

export const ScriptBlockCheckboxContainer = styled('div')(css`
  margin-left: -12px;
`);

export const ScriptBlockCheckboxText = styled('p')(css`
  padding-top: 10px;
  font-weight: 600;
`);
