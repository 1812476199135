import React from 'react';
import {
  Button, Dialog, DialogActions, DialogContent,
} from '@material-ui/core';
import { NavBarDementiaFlagWarningInterface } from './NavBarDementiaFlagWarning.interfaces';
import { DementiaFlagText, DementiaFlagTextBold } from './NavBarDementiaFlagWarning.elements';

export const NavBarDementiaFlagWarning = ({ open, onDialogClose }: NavBarDementiaFlagWarningInterface) => (
  <Dialog open={open} onClose={onDialogClose} aria-labelledby="dialog-title">
    <DialogContent>
      <DementiaFlagTextBold>
        Potential cognitive impairment
      </DementiaFlagTextBold>
      <DementiaFlagText>
        Based on information found in this person’s record, there is a strong chance that they may have a cognitive impairment.
      </DementiaFlagText>
    </DialogContent>
    <DialogActions>
      <Button autoFocus onClick={onDialogClose} color="primary">
        I understand
      </Button>
    </DialogActions>
  </Dialog>
);
