import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import {
  LeadStageOptionsQueryData,
  LeadStageOptionsQueryVariables,
  LeadSubStageOptionsQueryData,
  LeadSubStageOptionsQueryVariables,
} from './useLeadStageOptionsQuery.interfaces';
import { useActiveLeadId } from '../../../components/routers/LeadRouter/useActiveLeadId';

const LEAD_STAGE_OPTIONS_QUERY = gql`
  query GetLeadStageOptions($leadId: ID!) {
    leadStageOptions(leadId: $leadId) {
      label
      value
    }
  }
`;

const LEAD_SUB_STAGE_OPTIONS_QUERY = gql`
  query GetLeadSubStageOptions($leadId: ID!, $newStage: String) {
    leadSubStageOptions(leadId: $leadId, newStage: $newStage) {
      label
      value
    }
  }
`;

export function useLeadStageOptionsQuery(leadId?: string) {
  const activeLeadId = useActiveLeadId(leadId);
  return useQuery<LeadStageOptionsQueryData, LeadStageOptionsQueryVariables>(LEAD_STAGE_OPTIONS_QUERY, {
    variables: {
      leadId: activeLeadId,
    },
  });
}

export function useLeadSubStageOptionsQuery(leadId?: string, newStage?: string) {
  const activeLeadId = useActiveLeadId(leadId);
  return useQuery<LeadSubStageOptionsQueryData, LeadSubStageOptionsQueryVariables>(LEAD_SUB_STAGE_OPTIONS_QUERY, {
    variables: {
      leadId: activeLeadId,
      newStage,
    },
  });
}
