import { gql, InMemoryCache } from 'apollo-boost';
import { CALENDAR_EVENT_FRAGMENT } from '../../../fragments';
import { MutationCustomReturn, useMutationCustom } from '../../useMutationCustom';
import {
  ScheduleFollowUpMutationData,
  ScheduleFollowUpMutationVariables,
} from './useScheduleFollowUpMutation.interfaces';
import { addCalendarEvent } from '../../../queries/followUp/useLeadFollowUpQuery';

const SCHEDULE_FOLLOW_UP_MUTATION = gql`
  mutation ScheduleFollowUp($leadId: ID!, $options: ScheduleFollowUpOptions!) {
    scheduleFollowUp(leadId: $leadId, options: $options) {
      code
      success
      message
      timestamp
      type
      event {
        ...CalendarEventFragment
      }
    }
  }
  ${CALENDAR_EVENT_FRAGMENT}
`;

export function useScheduleFollowUpMutation(leadId: string): MutationCustomReturn<
ScheduleFollowUpMutationData,
ScheduleFollowUpMutationVariables> {
  return useMutationCustom<
  ScheduleFollowUpMutationData,
  ScheduleFollowUpMutationVariables
  >(SCHEDULE_FOLLOW_UP_MUTATION, {
    // @ts-ignore
    update: updateFactory(leadId),
  });
}

function updateFactory(leadId: string) {
  return (cache: InMemoryCache, { data }: { data: ScheduleFollowUpMutationData }) => {
    const event = data?.scheduleFollowUp?.event;
    addCalendarEvent(cache, leadId, event);
  };
}
