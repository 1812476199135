import { QuestionType, SunfirePlan } from '@hiq/crm.types';
import moment from 'moment';
import * as React from 'react';
import { useFormAnswers } from '../../../hooks';
import { SimpleQuestionView } from '../FormGenerator/FormGeneratorQuestionFlow/Question/SimpleQuestionView';
import { InfoBlock, ScriptBlock } from '../ScriptBlock';

export const MedicareRetentionSpecialistHRA: React.FunctionComponent = () => {
  const [answers] = useFormAnswers();
  if (!answers) return null;

  const clientAgreedToTakeHRAQuestionId = 'call.humana.clientAgreedToTakeHRA';
  const completedHRAWithClientQuestionId = 'lead.hraCompletedWithClient';
  const hraCompletedDate = answers['lead.hraCompletionTime'];

  const selectedPlanId = 'lead.sunfire.v2.selectedPlan';
  const selectedPlan = answers[selectedPlanId];

  const currentlyEnrolledV2Id = 'lead.sunfire.v2.currentlyEnrolledV2';
  const currentlyEnrolled = answers[currentlyEnrolledV2Id];

  const [showScript, setShowScript] = React.useState(false);

  React.useEffect(() => {
    if (!selectedPlan || !currentlyEnrolled) {
      setShowScript(true);
      return;
    }

    const selectedPlanData = JSON.parse(selectedPlan) as SunfirePlan;
    const planName = selectedPlanData.name;

    if (!isHumanaPlan(planName)) {
      setShowScript(true);
    }
  }, []);

  if (!showScript) return null;

  return (
    <>
      <>
        <ScriptBlock>
          Health IQ and Humana are committed to improving the health of our members beyond the doctor visits and prescriptions that most of
          us think of when we hear “healthcare”. Humana offers a short survey that focuses on food scarcity, loneliness, transportation,
          financial strain, and housing to improve the health of our members. Our resource coordinators can connect you with all of the
          resources Humana offers. Would you like to complete your Member Care Assessment with me now?
        </ScriptBlock>
      </>
      <>
        <p>Did the Client Agree to take the HRA ?</p>
        <InfoBlock>
          Link to Humana&apos;s HR Site, REVEL:

          <a href="https://connect.revel-health.com/sign-in" target="_blank" rel="noreferrer">
            https://connect.revel-health.com/sign-in
          </a>
        </InfoBlock>
        <SimpleQuestionView
          id={clientAgreedToTakeHRAQuestionId}
          type={QuestionType.RadioYesNo}
        />
      </>
      <>
        <p>Did you complete the entire HRA with the client?</p>
        <SimpleQuestionView
          id={completedHRAWithClientQuestionId}
          type={QuestionType.RadioYesNo}
        />
        <p>
          {hraCompletedDate && (
            <p>
              <b>HRA Completed Date:</b>
              {moment(hraCompletedDate).format('MM/DD/YYYY hh:mm A')}
            </p>
          )}
        </p>
      </>
    </>
  );
};

export function isHumanaPlan(planName: string) {
  const humanaRegex = /.*humana.*/i;
  return humanaRegex.test(planName);
}
