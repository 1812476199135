import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../../../themes';

export const ContentContainer = styled.div`
  padding: 6px;
  border-bottom: 1px solid #DEE2E5;
`;

export const SuccessMessage = styled(ContentContainer)<ComponentWithThemeProps>(({ theme }) => css`
  color: ${theme.color.primary};
`);
