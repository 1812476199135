import * as React from 'react';
import { QueryLoaderProps } from './QueryLoader.interfaces';
import { Loader } from '../Loader';
import { FormSkeleton } from '../FormSkeleton';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { captureEvent } from '@sentry/gatsby';
import { PrimaryButton } from '../inputs/PrimaryButton';

export const QueryLoader: React.FunctionComponent<QueryLoaderProps> = ({
  loading,
  error,
  renderError,
  variant,
  children,
}) => {
  const handleReloadPageClick = React.useCallback(() => {
    captureEvent({
      level: 'error',
      message: '[QueryLoader] Page reloaded',
      extra: {
        error,
      },
      tags: {
        type: 'react',
      },
    });
    window.location.reload();
  }, [error]);

  if (loading) {
    switch (variant) {
      case 'hiq':
        return <Loader />;
      case 'skeleton-small':
        return <FormSkeleton rows={2} />;
      default:
        return <FormSkeleton />;
    }
  }
  if (error) {
    return (renderError && renderError(error)) ?? (
      <div style={{ maxWidth: '100%' }}>
        <p>
          Something went wrong...
        </p>
        <pre>
          { error.message }
        </pre>
        <PrimaryButton onClick={handleReloadPageClick}>
          Reload
        </PrimaryButton>
      </div>
    );
  }
  return (
    <ErrorBoundary>
      { children }
    </ErrorBoundary>
  );
};
