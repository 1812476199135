import * as React from 'react';
import { SpinnerSvg } from './Spinner.elements';
import { ISpinnerProps } from './Spinner.interfaces';

export function Spinner(props: ISpinnerProps) {
  const stroke = props.stroke || '#DDD';
  return (
    <SpinnerSvg
      width="100%"
      height="100%"
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#4CA9ED"
      overflow="visible"
    >
      <g fill="none" fillRule="evenodd">
        <g strokeWidth="3">
          <circle cx="18" cy="18" r="18" stroke={stroke} />
          <path d="M36 18c0-9.94-8.06-18-18-18" />
        </g>
      </g>
    </SpinnerSvg>
  );
}
