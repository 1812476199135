import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const DispositionSelectContainer = styled('div')(css`
  margin-right: 5px;
  margin-bottom: 10px;  
`);

export const DNCDialogWrapperContainer = styled('div')(css`
  margin-right: 0px;`);

export const DispositionSelectLoaderContainer = styled('div')(css`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 15px;
`);

export const SpinnerContainer = styled('div')(css`
  width: 30px;
  margin-right: 10px;
`);

export const DiospositionLoaderText = styled('p')(css`
  margin-top: 6px;
`);

export const dimOutFolloUpStyle = `.dimoutFolloUp {
  position: fixed; 
  box-shadow: 0 0 0 1000px rgba(0,0,0,.3);
  box-shadow: 0 0 0 100vmax rgba(0,0,0,.3);
  pointer-events: none;
  z-index:40;
  max-width: 100%;
}`;

export const Paragraph = styled.p`
  margin-bottom: 10px;
  margin-top: 0;
`;

export const Header = styled.p`
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: medium;
`;
