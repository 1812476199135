import { useCallback } from 'react';
import { useLeadIdRoute } from '../../components/routers/LeadRouter/useLeadIdRoute/useLeadIdRoute';
import { useFormAnswers } from '../useFormAnswers';
import { emitAnalyticsEventNoLimit } from '../../events/emitAnalyticsEvent';

const digitalMedUserIdKey = 'customer.person.cmhrDigitalMedUserId';

export function useEmitEventCmhrAware() {
  const [leadId] = useLeadIdRoute();
  const [formAnswers] = useFormAnswers();
  const digitalMedUserId = formAnswers[digitalMedUserIdKey];
  const emitEvent = useCallback((message: string, properties: object = {}) => {
    emitAnalyticsEventNoLimit(message, { digitalMedUserId, leadId, ...properties });
  }, [leadId, digitalMedUserId]);
  return emitEvent;
}
