import { InputTransformer } from '../InputTransformer';
import { isPresent } from '@healthiqeng/core.util.is-present';

export class MedicareIdTransformer extends InputTransformer<string> {
  constructor(initialValue: string) {
    super(initialValue);
    this.draftValue = initialValue;
  }

  public update(nextValue: string) {
    this.updateDraftValue(nextValue);
    this.updatePersistedValue();
  }

  public getDraftValue(): string {
    return formatMedicareId(this.draftValue);
  }

  public getPersistedValue(): string {
    return this.persistedValue;
  }

  public getFormattedValue(): string {
    return formatMedicareId(this.getPersistedValue());
  }

  private updateDraftValue(nextValue: string) {
    this.draftValue = MedicareIdTransformer.normalizeMedicareId(nextValue);
  }

  private updatePersistedValue() {
    if ((this.draftValue ?? '') === '') {
      this.persistedValue = null;
    }
    if (/^[a-zA-Z0-9]{11}$/.test(this.draftValue ?? '')) {
      this.persistedValue = this.draftValue;
    }
  }

  public static normalizeMedicareId(value: string): string {
    if (!isPresent(value)) {
      return '';
    }

    const cleaned = value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
    return cleaned.substring(0, 11);
  }
}

function formatMedicareId(value: string): string {
  const normalized = MedicareIdTransformer.normalizeMedicareId(value);
  if (normalized.length < 11) {
    return normalized;
  }
  return `${normalized.substring(0, 4)}-${normalized.substring(4, 7)}-${normalized.substring(7, 11)}`;
}
