import { useMemo } from 'react';
import { ProductTypeLife, Uuid } from '@hiq/crm.types';
import { useLifeProductsQuery } from '../../../../../../graphql/queries/useLifeProductsQuery';
import { useActiveLeadId } from '../../../../../routers/LeadRouter/useActiveLeadId';

export function useLifeProducts(productType: ProductTypeLife, carrierId: Uuid) {
  const leadId = useActiveLeadId();
  const { data, loading, error } = useLifeProductsQuery(leadId, {
    productType,
    carrierId,
  });
  const productOptions = useMemo(() => (
    data?.lifeQuoteProducts
      ?.map((product) => ({
        label: product.name,
        value: product.id,
      }))
      ?.sort((a, b) => (a.label > b.label ? 1 : -1)) ?? [] ?? []
  ), [JSON.stringify(data), carrierId]);

  return {
    loading,
    error,
    productOptions,
  };
}
