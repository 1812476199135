import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { USER_FRAGMENT } from '../../fragments';
import { UsersQueryData, UsersQueryVariables } from './useUsersQuery.interfaces';

const USERS_QUERY = gql`
    query Users {
      users {
        ...UserFragment
      }
    }
    ${USER_FRAGMENT}
`;

export function useUsersQuery(skip: boolean = false) {
  return useQuery<UsersQueryData, UsersQueryVariables>(USERS_QUERY, {
    skip,
  });
}
