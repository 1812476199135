import * as React from 'react';
import { useLeadQuery } from '../../../../graphql';
import { QueryLoader } from '../../../common/QueryLoader';
import { MedicarePreConversionLead } from './MedicarePreConversionLead';
import { MedicarePostConversionLead } from './MedicarePostConversionLead';
import { ComponentName } from '../../../common/Page/PageLoadDetector';

export const MedicareLead: React.FunctionComponent = () => {
  const { data, loading, error } = useLeadQuery();
  return (
    <QueryLoader loading={loading} error={error} componentName={ComponentName.MedicareLead}>
      <>
        { !data?.isSold && (
        <MedicarePreConversionLead />
        ) }
        { data?.isSold && (
          <MedicarePostConversionLead />
        ) }
      </>
    </QueryLoader>
  );
};
