import { useFormAnswers } from '../../../hooks';
import { unflattenObject } from '@hiq/crm.core.util.unflatten-object';
import { isPresent } from '@healthiqeng/core.util.is-present';

export function useGetLeadDNCPhoneNumbers() : [string[], (arg: string[]) => void] {
  const [answers, onChangeAnswers] = useFormAnswers();
  const questionFlowPath = 'lead.dnc.phoneNumbers';
  const savedAnswers = unflattenObject(answers) as any;
  const savedDncPhoneNumbers = savedAnswers?.lead?.dnc?.phoneNumbers.filter((phoneNumber:string) => isPresent(phoneNumber)) ?? [];

  function onChange(dncPhoneNumbers: string[]) {
    onChangeAnswers(questionFlowPath, dncPhoneNumbers);
  }

  return [savedDncPhoneNumbers, onChange];
}
