import * as React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { CustomerQueryData, CustomerQueryVariables } from './useCustomerQuery.interfaces';
import { CUSTOMER_FRAGMENT } from '../../fragments';
import { CustomerIdContext } from '../../../components/contexts/CustomerIdContext';
import { useEventListener, getEventBus, ChangeStageSuccessEvent } from '../../../events';
import { CrossSaleCreateSuccessEvent } from '../../mutations/useCrossSaleMutation/events';
import { CrossSaleCustomer } from '@hiq/crm.types';
import { QuoteSelectedEvent } from '../../mutations/useSelectQuoteMutation';
import { QuoteUnselectedEvent } from '../../mutations/useUnselectQuoteMutation/events';
import { CustomerRefetchCompletedEvent } from './events';
import { QuoteHistoryAddedEvent } from '../../mutations/useAddQuoteHistoryMutation';
import { QuoteHistoryRemovedEvent } from '../../mutations/useRemoveQuoteHistoryMutation';

export const CUSTOMER_QUERY = gql`
    query getCustomer($customerId: ID) {
      customer(id: $customerId) {
        ...CustomerFragment
      }
    }
    ${CUSTOMER_FRAGMENT}
`;

export function useCustomerQuery(customerId?: string, notifyOnNetworkStatusChange?: boolean) {
  const id = getCustomerId(customerId);
  const result = useQuery<CustomerQueryData, CustomerQueryVariables>(CUSTOMER_QUERY, {
    variables: {
      customerId: id,
    },
    notifyOnNetworkStatusChange,
    skip: !id,
  });
  const eventBus = getEventBus();
  useEventListener(CrossSaleCreateSuccessEvent, (event: CrossSaleCreateSuccessEvent) => {
    if (event.customerType === CrossSaleCustomer.Current) {
      result.refetch();
    }
  });
  useEventListener(QuoteSelectedEvent, async () => {
    await result.refetch();
    eventBus.emit(new CustomerRefetchCompletedEvent());
  });
  useEventListener(QuoteUnselectedEvent, async () => {
    await result.refetch();
    eventBus.emit(new CustomerRefetchCompletedEvent());
  });
  useEventListener(ChangeStageSuccessEvent, async () => {
    await result.refetch();
  });
  useEventListener(QuoteHistoryAddedEvent, async () => {
    await result.refetch();
    eventBus.emit(new CustomerRefetchCompletedEvent());
  });
  useEventListener(QuoteHistoryRemovedEvent, async () => {
    await result.refetch();
    eventBus.emit(new CustomerRefetchCompletedEvent());
  });
  return result;
}

function getCustomerId(customerId?: string) {
  return customerId ?? React.useContext(CustomerIdContext);
}
