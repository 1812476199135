import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../../themes';

export const TotalUsePerVehicleRowAmount = styled('b')<ComponentWithThemeProps & {
  value: number;
}>((props) => css`
  margin-left: 10px;
  color: ${props.value !== 100 ? props.theme.color.error : props.theme.color.text};
`);
