import * as React from 'react';
import { PropertyAndCasualtySelectedQuoteContainer } from './PropertyAndCasualtySelectedQuotes.elements';
import { useLeadQuery } from '../../../../../../../graphql';
import {
  Card,
  QueryLoader,
  QuoteCellFactory,
} from '../../../../../../common';

export const PropertyAndCasualtySelectedQuotes: React.FunctionComponent = () => {
  const { data, loading, error } = useLeadQuery();

  return (
    <QueryLoader loading={loading} error={error} componentName="PropertyAndCasualtySelectedQuotes">
      <>
        { data?.selectedQuotes?.map((quote, index) => (
          <PropertyAndCasualtySelectedQuoteContainer key={quote?.hash || index}>
            <Card>
              <QuoteCellFactory
                type={quote?.product?.type}
                hash={quote?.hash}
              />
            </Card>
          </PropertyAndCasualtySelectedQuoteContainer>
        )) }
      </>
    </QueryLoader>
  );
};
