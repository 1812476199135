import React from 'react';
import { Box } from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons';
import { ThemeLight } from '../../../../themes/ThemeLight';
import { useCheckMyHealthRecordConsent } from '../useCheckMyHealthRecordConsent';

export const CheckMyHealthRecordHipaaAcceptedBefore: React.FunctionComponent = () => {
  const { hipaaApprovedDateFormatted, hipaaApprovalExpireDate, consentGiven } = useCheckMyHealthRecordConsent();

  if (!consentGiven) return null;

  return (
    <ClientApprovedHipaaInfoBlock>
      <i>
        Client consented to HIPAA on
        {' '}
        {hipaaApprovedDateFormatted}
        , this consent is valid until
        {' '}
        {hipaaApprovalExpireDate}
      </i>
    </ClientApprovedHipaaInfoBlock>
  );
};

export const ClientApprovedHipaaInfoBlock: React.FC = ({ children }) => (
  <Box
    style={{
      background: ThemeLight.color.accentLight,
      borderRadius: 10,
      padding: 15,
      display: 'flex',
      alignItems: 'center',
      marginBottom: 10,
    }}
  >
    <CheckCircleOutline style={{ marginRight: 10, color: ThemeLight.color.accentDark }} />
    {children}
  </Box>
);
