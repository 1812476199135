import React, { useEffect } from 'react';
import { QuestionView } from '../../FormGenerator/FormGeneratorQuestionFlow/Question/QuestionView';
import { useActiveLeadId } from '../../../routers/LeadRouter/useActiveLeadId';
import {
  CommunicationMethod,
  CommunicationTemplate, FormItemTag,
  QuestionType,
  UserProfile,
} from '@hiq/crm.types';
import { useFormAnswers, useCurrentUserIsAgent, useCurrentUserProfile } from '../../../../hooks';
import { PrimaryButton } from '../../inputs/PrimaryButton';
import { useSendCommunication } from '../../../../graphql/mutations/useSendCommunicationMutation';
import { useCustomerId } from '../../../routers/CustomerRouter/useCustomerId';
import { useDebounce } from '@healthiqeng/core.hooks.use-debounce';
import { calculateAge } from '@healthiqeng/core.util.calculate-age';
import { isPresent } from '@healthiqeng/core.util.is-present';
import Alert from '@material-ui/lab/Alert/Alert';
import { useCampaign } from './hooks/useCampain';
import { useManageChanges } from './hooks/useManageChanges';
import { JsonCompatible } from '@healthiqeng/core.types';
import { getCMHRHipaaConstants } from './hooks/useCMHRHipaaConstants';
import { InfoBlock } from '../../ScriptBlock/InfoBlock';
import { CustomerCommunicationQuestions } from '../../CustomerCommunication/CustomerCommunicationQuestions';
import { useCustomerDataFormState } from './hooks/useCustomerDataFormState';
import { useApproveHipaaConsentMutation, useRequestHealthDataMutation, useRevokeHipaaConsentMutation } from '../../../../graphql';

import { emitAnalyticsEventNoLimit } from '../../../../events/emitAnalyticsEvent';
import { ScriptBlock } from '../../ScriptBlock/ScriptBlock';
import { CircularProgress } from '@material-ui/core';
import { LoadingContainer } from './CheckMyHealthRecordHipaaAnswer.elements';

export const CheckMyHealthRecordHipaaAnswer: React.FunctionComponent = () => {
  const leadId = useActiveLeadId();
  const trackingId = React.useMemo(() => `CheckMyHealthRecordHipaaAnswer-${Date.now()}`, []);
  const isAgent = useCurrentUserIsAgent();
  const [revokeHipaa, revokeHipaaData] = useRevokeHipaaConsentMutation();
  const [approveHipaa, approveHipaaData] = useApproveHipaaConsentMutation();

  const [answers, onChange, persistAnswers] = useFormAnswers(leadId, false, trackingId);
  const userProfile = useCurrentUserProfile();
  const isRetentionSpecialist = userProfile === UserProfile.RetentionSpecialist;
  const buttonText = isAgent ? 'Pull Health Record' : 'Send Email/Text';
  const customerId = useCustomerId();
  const utmCampaign = useCampaign();
  const [
    requestHealthDataMutation,
    { data: requestHealthDataMutationData },
  ] = useRequestHealthDataMutation({ variables: { leadId, utmCampaign }, refetchQueries: ['GetCustomerHealthRecordStatusQuery'] });

  const {
    onValueChanged,
    onDataChanged,
    setTempHipaaApproval,
    onConfirmConsentAttest,
  } = useManageChanges(onChange, persistAnswers);

  useEffect(() => {
    if (isPresent(answers['customer.person.hipaaConsentApproved'])) {
      setTempHipaaApproval(answers['customer.person.hipaaConsentApproved']);
    }
  }, []);

  const firstName = answers['customer.person.name.first'];
  const lastName = answers['customer.person.name.last'];
  const name = `${firstName} ${lastName}`;

  const [sendCommunication, { data, loading, error }] = useSendCommunication();

  const sendMessage = () => {
    sendCommunication({
      customerId,
      options: {
        communicationTemplate: CommunicationTemplate.CheckMyHealthRecordLink,
        communicationMethod: CommunicationMethod.Email,
        utmCampaign,
      },
    });
  };

  const debouncedCommunicationSend = useDebounce(sendMessage, 500);

  const {
    declineReasons,
    declineQuestionText,
    declineQuestionTextRetentionSpecialist,
    tempHipaaApprovedOptions,
  } = getCMHRHipaaConstants();

  const currentDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  }).format(new Date());

  const checkAllAnswers = (
    firstName: string,
    lastName: string,
    gender: string,
    dateOfBirth: string,
    zip: string,
    phone: string,
    contactPreference: string,
    attested: boolean,
    county: string,
  ) => {
    const errors = [];
    const name = `${firstName} ${lastName}`;

    if (name.toLowerCase().includes('inbound call')) {
      errors.push('Please enter a valid name (no Inbound Call on the name)');
    }

    if (!firstName || firstName === '') {
      errors.push('Please verify the first name in the verification form above');
    }

    if (!lastName || lastName === '') {
      errors.push('Please verify the last name in the verification form above');
    }
    if (firstName && firstName.match(/[#$%&*(){}?!@<>~`\d/=\\_]/g)) {
      errors.push('Please enter a valid first name. No special characters or numbers allowed.');
    }

    if (lastName && lastName.match(/[#$%&*(){}?!@<>~`\d/=\\_]/g)) {
      errors.push('Please enter a valid last name. No special characters or numbers allowed.');
    }

    if (!gender || gender === '') {
      errors.push('Please verify the gender in the verification form above');
    }

    if (!dateOfBirth || dateOfBirth === '') {
      errors.push('Please verify the date of birth in the verification form above');
    }

    if (!zip || zip === '') {
      errors.push('Please verify the zip in the verification form above');
    }

    if (!county || county === '') {
      errors.push('Please verify the county in the verification form above');
    }

    if (!phone || phone === '') {
      errors.push('Please verify the phone in the verification form above');
    }

    if (isAgent && !attested) {
      errors.push('Please confirm consent was given');
    }

    return { hasErrors: errors.length > 0, errors };
  };

  const gender = answers['customer.person.gender'];
  const dateOfBirth = answers['customer.person.dateOfBirth'];
  const zip = answers['customer.person.homeAddress.zip'];
  const phone = answers['customer.person.phone.number'];
  const contactPreference = answers['customer.person.contactPreference'];
  const attested = answers['customer.person.hipaaConsentAttest'];
  const hipaaApproved = answers['customer.person.hipaaConsentApproved'];
  const county = answers['lead.sunfire.v2.countyCode'] || answers['customer.person.homeAddress.county'];

  const { hasErrors, errors } = React.useMemo(
    () => checkAllAnswers(
      firstName,
      lastName,
      gender,
      dateOfBirth,
      zip,
      phone,
      contactPreference,
      attested,
      county,
    ), [
      firstName,
      lastName,
      gender,
      dateOfBirth,
      zip,
      phone,
      contactPreference,
      attested,
      county,
    ],
  );

  const sendDisabled = loading || hasErrors
    || !answers['customer.person.hipaaConsentAttest'];

  const {
    includeFirstName,
    includeLastName,
    includeGender,
    includeDOB,
    includeZip,
    includePhone,
  } = useCustomerDataFormState();
  const hasDataToConfirm = includeFirstName || includeLastName || includeGender || includeDOB || includeZip || includePhone;

  const onHipaaApprovedChange = React.useCallback((value: JsonCompatible) => {
    if (!value) revokeHipaa({ customerId });
    if (value) approveHipaa({ customerId });
    onValueChanged(value);
  }, [onValueChanged, customerId]);

  const hipaaConsentAttestOptions = React.useMemo(() => [{ label: 'Confirm', value: true }], []);

  const onHipaaConsentAttestChange = React.useCallback((value: JsonCompatible) => {
    if (value) emitAnalyticsEventNoLimit('HIPAA Consented', { customerId, leadId });
    onConfirmConsentAttest(value);
  }, [onConfirmConsentAttest, customerId, leadId]);

  const onCMHRInviteButtonClick = React.useCallback(() => {
    onChange('lead.cmhrInviteSent', true);
    onChange('lead.cmhrInviteSentTime', new Date().toISOString());
    persistAnswers();
    emitAnalyticsEventNoLimit('Requested Health Record', { customerId, leadId });
    requestHealthDataMutation();
    debouncedCommunicationSend();
  }, [onChange, persistAnswers, emitAnalyticsEventNoLimit, requestHealthDataMutation, debouncedCommunicationSend]);

  const onDeclineReasonChange = React.useCallback(
    (value: JsonCompatible) => onDataChanged('lead.cmhrSingupDeclineReason', value),
    [onDataChanged],
  );

  const tags = React.useMemo(() => [FormItemTag.RequiredToNavigate], []);

  const onDeclineSkipHealthRecordPullChange = React.useCallback(
    (value: JsonCompatible) => onDataChanged('customer.person.hipaaSkipUsingHealthRecord', value),
    [onDataChanged],
  );

  return (
    <>
      <QuestionView
        id="customer.person.hipaaConsentApproved"
        questionText={`To finish the process I need to capture your verbal permission. If you agree, ${name}
        on ${currentDate}, please say yes.`}
        label="Select Yes or No"
        type={QuestionType.Radio}
        tags={tags}
        options={tempHipaaApprovedOptions}
        leadId={leadId}
        value={hipaaApproved}
        onChange={onHipaaApprovedChange}
      />
      {revokeHipaaData?.loading && (
      <LoadingContainer>
        <CircularProgress />
      </LoadingContainer>
)}
      {revokeHipaaData?.error && (
      <Alert severity="error">
        Could not revoke Hipaa consent, please try again
        {revokeHipaaData?.error?.message}
      </Alert>
)}

      {approveHipaaData?.loading && (
      <LoadingContainer>
        <CircularProgress />
      </LoadingContainer>
      )}
      {approveHipaaData?.error && (
      <Alert severity="error">
        Could not approve Hipaa consent, please try again
        {approveHipaaData?.error?.message}
      </Alert>
      )}
      {hipaaApproved === true && (
        <>
          {isRetentionSpecialist && calculateAge(new Date(dateOfBirth)) >= 65 && (
            <QuestionView
              id="customer.person.hipaaConsentAttest"
              questionText="As a HealthIQ employee, I attest that the client consented to the HIPAA disclosure.
              Failure to gather proper consent will result in disciplinary action"
              label="Select Yes or No"
              type={QuestionType.Radio}
              options={hipaaConsentAttestOptions}
              leadId={leadId}
              value={answers['customer.person.hipaaConsentAttest']}
              onChange={onHipaaConsentAttestChange}
            />
          )}
          {hasDataToConfirm && <InfoBlock>Please verify the following information if the user consents:</InfoBlock>}
          <CustomerCommunicationQuestions
            leadId={leadId}
            formAnswers={answers}
            includeFirstName={includeFirstName}
            includeLastName={includeLastName}
            includeGender={includeGender}
            includeDOB={includeDOB}
            includeZipCode={includeZip}
            includePhone={includePhone}
            includeEmail={isAgent}
            includeCommunicationPreference={isRetentionSpecialist}
            onChange={isAgent ? onChange : undefined}
          />
          { hasErrors && (
            <Alert severity="error">
              <div>
                Please verify the following answers before you click the send button.
              </div>
              <div>
                Please ensure that all the fields incidated below are filled in:
              </div>
              {errors.map((error) => <div>{error}</div>)}
            </Alert>
          )}
          {isAgent && (
            <div style={{ marginBottom: 10 }}>
              <QuestionView
                id="customer.person.hipaaConsentAttest"
                questionText="As a HealthIQ employee, I attest that the client consented to the HIPAA disclosure.
              Failure to gather proper consent will result in disciplinary action"
                label="Select Yes or No"
                type={QuestionType.Radio}
                options={hipaaConsentAttestOptions}
                leadId={leadId}
                value={answers['customer.person.hipaaConsentAttest']}
                onChange={onHipaaConsentAttestChange}
              />
            </div>
          )}

          {(isAgent || isRetentionSpecialist) && (
          <PrimaryButton
            onClick={onCMHRInviteButtonClick}
            disabled={sendDisabled}
            variant="contained"
          >
            { buttonText }
          </PrimaryButton>
          )}
          { isAgent && isPresent(requestHealthDataMutationData?.requestHealthRecords.success) && (
            <Alert severity={requestHealthDataMutationData?.requestHealthRecords.success ? 'success' : 'error'}>
              { requestHealthDataMutationData?.requestHealthRecords.success
                ? 'Pull initiated' : requestHealthDataMutationData?.requestHealthRecords?.message ?? error?.message}
            </Alert>
          )}
          { isRetentionSpecialist && (
          <>
            <ScriptBlock>
              Thank you
              {' '}
              {name}
              . You will receive a confirmation email, and/or a text message with a link
              to login and use the service now.
              {' '}
            </ScriptBlock>
            { (isPresent(data?.sendCommunication?.success)) && (
            <Alert severity={data?.sendCommunication?.success ? 'success' : 'error'}>
              { data?.sendCommunication?.success ? data?.sendCommunication?.message : error.message}
            </Alert>
            ) }
            { (isPresent(data?.sendCommunication?.success)) && data.sendCommunication.success === true && (
            <ScriptBlock>You should have received it, are you able to confirm you received it?</ScriptBlock>
            )}
          </>
          )}
        </>
      )}
      {answers['customer.person.hipaaConsentApproved'] === false && isRetentionSpecialist && (
        <QuestionView
          id="lead.cmhrInviteDeclineReason"
          questionText={declineQuestionTextRetentionSpecialist}
          label="Select an option from the list below:"
          type={QuestionType.Radio}
          options={declineReasons}
          leadId={leadId}
          value={answers['lead.cmhrSingupDeclineReason']}
          onChange={onDeclineReasonChange}
        />
      )}
      {answers['customer.person.hipaaConsentApproved'] === false && !isRetentionSpecialist && (
        <QuestionView
          id="lead.cmhrInviteDeclineReason"
          questionText={declineQuestionText}
          label="Select an option from the list below:"
          type={QuestionType.Radio}
          options={tempHipaaApprovedOptions}
          leadId={leadId}
          value={answers['customer.person.hipaaSkipUsingHealthRecord']}
          onChange={onDeclineSkipHealthRecordPullChange}
        />
      )}
      {answers['customer.person.hipaaConsentApproved'] === false
        && answers['customer.person.hipaaSkipUsingHealthRecord'] === false && !isRetentionSpecialist && (
        <div style={{ marginTop: 10 }}>
          Great. I just need to get your verbal permission to access your health records.
          If you agree,
          {' '}
          {name}
          , on
          {' '}
          {currentDate}
          , please say yes.
        </div>
      )}
      {answers['customer.person.hipaaConsentApproved'] === false
        && answers['customer.person.hipaaSkipUsingHealthRecord'] === true && !isRetentionSpecialist && (
        <div style={{ marginTop: 10 }}>
          No problem, this does not impact your plan choices.
        </div>
      )}
    </>
  );
};
