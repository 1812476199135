import { gql } from 'apollo-boost';
import { MutationHookOptions } from '@apollo/react-hooks';
import { useMutationCustom } from '../useMutationCustom';
import {
  LocalFormAnswersMutationData,
  LocalFormAnswersMutationVariables,
} from './useLocalFormAnswersMutation.interfaces';

const LOCAL_FORM_ANSWERS_MUTATION = gql`
  mutation SaveLocalFormAnswers($answers: JSON!) {
    localFormAnswers(answers: $answers) @client
  }
`;

export function useLocalFormAnswersMutation(
  options: MutationHookOptions<LocalFormAnswersMutationData, LocalFormAnswersMutationVariables> = {},
) {
  return useMutationCustom<LocalFormAnswersMutationData, LocalFormAnswersMutationVariables>(LOCAL_FORM_ANSWERS_MUTATION, options);
}
