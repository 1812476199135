import { DocumentNode } from 'apollo-boost';
import { useCallback } from 'react';
import { MutationHookOptions, useMutation } from '@apollo/react-hooks';
import { MutationCustomReturn } from './useMutationCustom.interfaces';

export function useMutationCustom<ResponseType, VariablesType>(
  mutation: DocumentNode,
  options?: MutationHookOptions<ResponseType, VariablesType>,
): MutationCustomReturn<ResponseType, VariablesType> {
  const [mutationFunction, result] = useMutation<ResponseType, VariablesType>(mutation, options);
  const mutationFunctionWrapped = useCallback((variables: VariablesType) => (
    mutationFunction({ variables })
  ), [mutationFunction]);
  return [
    // @ts-ignore
    mutationFunctionWrapped,
    // @ts-ignore
    result,
  ];
}
