export function getEnumOptions(enumObj: EnumObject | string[], enumLabelMap: EnumLabelMap, blacklist?: any[]): Option[] {
  const values = Array.isArray(enumObj) ? enumObj : Object.values(enumObj);
  const whitelistedValues = blacklist ? values.filter((val) => !blacklist.includes(val)) : values;
  return whitelistedValues.map((value) => ({
    label: enumLabelMap[value] ?? value,
    value,
  }));
}

interface EnumLabelMap {
  [index: string]: string;
}

interface Option {
  label: string;
  value: string;
}

interface EnumObject {
  [index: string]: string;
}
