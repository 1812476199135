import { gql } from 'apollo-boost';
import { useCallback } from 'react';
import { AddQuoteHistoryMutationResponse, AddQuoteHistoryMutationVariables } from './useAddQuoteMutation.interfaces';
import { MutationCustomReturn, useMutationCustom } from '../useMutationCustom';
import { LEAD_FRAGMENT, QUOTE_FRAGMENT } from '../../fragments';

const ADD_QUOTE_HISTORY_MUTATION = gql`
    mutation AddQuoteHistory($leadId: ID!, $hash: ID!, $type: QuotePresentationType!) {
      addQuoteHistory(leadId: $leadId, hash: $hash, type: $type) {
        code
        success
        message
        timestamp
        quotes {
          ...QuoteFragment
        }
        lead {
          ...LeadFragment
        }
      }
    }
    ${QUOTE_FRAGMENT}
    ${LEAD_FRAGMENT}
`;

export function useAddQuoteHistoryMutation(): MutationCustomReturn<AddQuoteHistoryMutationResponse, AddQuoteHistoryMutationVariables> {
  const [addQuoteHistory, result] = useMutationCustom<AddQuoteHistoryMutationResponse, AddQuoteHistoryMutationVariables>(
    ADD_QUOTE_HISTORY_MUTATION,
    {
      update: (cache, { data }) => {
        if (!data?.addQuoteHistory?.success) return;
        cache.writeFragment({
          data: data.addQuoteHistory.lead,
          fragment: LEAD_FRAGMENT,
          fragmentName: 'LeadFragment',
        });
      },
    },
  );
  const addQuoteHistoryWrapped = useCallback(
    (variables: AddQuoteHistoryMutationVariables) => addQuoteHistory(variables), [addQuoteHistory],
  );

  return [addQuoteHistoryWrapped, result];
}
