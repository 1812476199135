import * as React from 'react';
import {
  NewCrossSaleFormProps,
  NewCrossSalesReducerAction,
  NewCrossSalesReducerState,
} from './NewCrossSaleForm.interfaces';
import { ErrorContainer, FormItemContainer, NewCrossSaleFormContainer } from './NewCrossSaleForm.elements';
import {
  CrossSaleCustomer,
  CrossSaleCustomerLabelMap,
  FormItemTag,
  IndustryType,
  ProductType,
  ProductTypeLabelMap,
  ProductTypeLife,
  ProductTypeMedicare,
  ProductTypePropertyAndCasualty,
  QuestionType,
} from '@hiq/crm.types';
import { QuestionView } from '../FormGenerator/FormGeneratorQuestionFlow/Question/QuestionView';
import { getEnumOptions } from '../../../util/getEnumOptions';
import { PrimaryButton } from '../inputs/PrimaryButton';
import { useCrossSaleMutation } from '../../../graphql/mutations/useCrossSaleMutation';
import { useCustomerQuery } from '../../../graphql/queries/useCustomerQuery';

export const NewCrossSaleForm: React.FunctionComponent<NewCrossSaleFormProps> = ({
  customerId,
  leadId,
  industry,
  defaultProductType,
  disabled,
  showMaAndPdpOptions,
}) => {
  const { data } = useCustomerQuery(customerId);
  const [submitCrossSale, { loading, error }] = useCrossSaleMutation();
  const [state, dispatch] = React.useReducer(reducer, {
    industry: industry ?? IndustryType.Medicare,
    product: defaultProductType ?? null,
    customer: null,
  });
  const industryOptions = [
    { label: 'Medicare', value: IndustryType.Medicare },
    { label: 'Property And Casualty', value: IndustryType.PropertyAndCasualty },
    { label: 'Life', value: IndustryType.Life },
  ];
  const productOptions = getProductOptions(showMaAndPdpOptions, state.industry);
  const customerValid = state.product === ProductTypeLife.Juvenile ? true : !!state.customer;

  return (
    <NewCrossSaleFormContainer>
      <FormItemContainer>
        { !industry && (
          <QuestionView
            id="industry"
            leadId={leadId}
            questionText="Which Industry?"
            label="Industry"
            type={QuestionType.Select}
            tags={[FormItemTag.Required]}
            value={state.industry}
            onChange={(value: ProductType) => dispatch({ key: 'industry', value })}
            options={industryOptions}
            locked={!!industry}
          />
        ) }
        <QuestionView
          id="product"
          leadId={leadId}
          questionText="Which Product?"
          label="Product"
          type={QuestionType.Select}
          tags={[FormItemTag.Required]}
          value={state.product}
          onChange={(value: ProductType) => dispatch({ key: 'product', value, customer: data?.customer })}
          options={productOptions}
        />
      </FormItemContainer>
      <FormItemContainer>
        { state.product !== ProductTypeLife.Juvenile && (
          <QuestionView
            id="customer"
            leadId={leadId}
            questionText="Who is the customer?"
            label="Customer"
            type={QuestionType.Radio}
            tags={[FormItemTag.Required]}
            value={state.customer}
            onChange={(value: CrossSaleCustomer) => dispatch({ key: 'customer', value })}
            options={getEnumOptions(CrossSaleCustomer, CrossSaleCustomerLabelMap, [CrossSaleCustomer.Juvenile])}
          />
        ) }
      </FormItemContainer>
      <PrimaryButton
        onClick={() => submitCrossSale({
          customerId,
          options: state,
        })}
        variant="contained"
        disabled={disabled || !customerValid || !state.product || loading}
      >
        { loading ? 'Creating...' : 'Create New Lead' }
      </PrimaryButton>
      { error && (
        <ErrorContainer>
          { error.message }
        </ErrorContainer>
      ) }
    </NewCrossSaleFormContainer>
  );
};

function reducer(state: NewCrossSalesReducerState, action: NewCrossSalesReducerAction): NewCrossSalesReducerState {
  const newState = {
    ...state,
    [action.key]: action.value,
  };
  if (action.key === 'product' && !newState.customer && action.value === ProductTypeMedicare.MedicareAdvantage) {
    if (action.customer?.leads?.find((lead) => lead.productType === ProductTypeMedicare.MedicareAdvantage)) {
      newState.customer = CrossSaleCustomer.Spouse;
    }
  }
  if (action.key !== 'industry') return newState;
  return {
    ...newState,
    product: null,
  };
}

function getProductOptions(showMaAndPdpOptions: boolean | 'exclusively', industry: IndustryType) {
  if (industry === IndustryType.PropertyAndCasualty) {
    return getEnumOptions(ProductTypePropertyAndCasualty, ProductTypeLabelMap);
  }

  if (industry === IndustryType.Life) {
    return [
      { label: 'Accidental Death', value: ProductTypeLife.Accidental },
      { label: 'Juvenile', value: ProductTypeLife.Juvenile },
      { label: 'Whole Life', value: ProductTypeLife.WholeLife },
      { label: 'Critical Illness', value: ProductTypeLife.CriticalIllness },
    ];
  }

  const allMedicareOptions = getEnumOptions(ProductTypeMedicare, ProductTypeLabelMap);

  if (showMaAndPdpOptions === true) {
    return allMedicareOptions;
  } if (showMaAndPdpOptions === 'exclusively') {
    return allMedicareOptions.filter((option) => [
      ProductTypeMedicare.MedicareAdvantage,
      ProductTypeMedicare.PrescriptionDrug,
    ].includes(option.value as ProductTypeMedicare));
  }
  return allMedicareOptions.filter((option) => ![
    ProductTypeMedicare.MedicareAdvantage,
    ProductTypeMedicare.PrescriptionDrug,
  ].includes(option.value as ProductTypeMedicare));
}
