import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { ApolloError, gql } from 'apollo-boost';
import {
  CustomerByExternalDataQueryVariables,
  CustomerByExternalDataQueryData,
  ExternalDataInput,
} from './useCustomerByExternalDataQuery.interface';
import { emitAnalyticsEvent } from '../../../events/emitAnalyticsEvent';

const CUSTOMER_BY_EXTERNAL_DATA_QUERY = gql`
    query getCustomerByExternalData($externalDataInput: ExternalDataInput!) {
      customerByExternalData(externalDataInput: $externalDataInput) {
        customerId
        leadId
        matchMethod
      }
    }
`;

const onCompleted = (data: CustomerByExternalDataQueryData) => emitAnalyticsEvent('Success Fetching Customer by External Data',
  {
    customerId: data?.customerByExternalData?.customerId,
    leadId: data?.customerByExternalData?.leadId,
    matchMethod: data?.customerByExternalData?.matchMethod,
    timestamp: new Date().toISOString(),
  });

const onError = (error: ApolloError) => emitAnalyticsEvent('Error Fetching Customer by External Data', { error: error?.message });

export function useCustomerByExternalDataQuery(externalDataInput: ExternalDataInput) {
  useEffect(() => emitAnalyticsEvent('Fetching Customer by External Data', { externalDataInput }), []);
  const result = useQuery<CustomerByExternalDataQueryData, CustomerByExternalDataQueryVariables>(
    CUSTOMER_BY_EXTERNAL_DATA_QUERY,
    {
      onCompleted,
      onError,
      variables: {
        externalDataInput,
      },
    },
  );

  const { refetch, error } = result;
  const [retries, setRetries] = useState(0);

  useEffect(() => {
    if (retries > 3) return;
    if (!error) return;

    setTimeout(() => {
      refetch();
      setRetries(retries + 1);
    }, 500);
  }, [error, retries]);

  return { ...result, retries };
}
