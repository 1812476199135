import React, { FC, ReactNode, useState } from 'react';
import { useDebounce } from '@healthiqeng/core.hooks.use-debounce';
import Autocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { AddressPlace } from '@healthiqeng/core.services.address.base';
import { useAddressLookupQuery } from '../../../../graphql/queries';
import { AddressLookupInputProps } from './AddressLookupInput.interfaces';
import { AddressLookupInputOption } from './AddressLookupInputOption';
import { useCustomerId } from '../../../routers/CustomerRouter';

export const AddressLookupInput: FC<AddressLookupInputProps> = ({
  placeholder,
  label,
  size,
  disabled,
  fullWidth,
  variant,
  onBlur,
  onFocus,
  onChange,
  dataQa,
}) => {
  const customerId = useCustomerId();
  const [value, setValue] = useState<AddressPlace | null>(null);
  const [fetchPredictions, { error: queryError, loading, data }] = useAddressLookupQuery(customerId);

  const handleChange = (_event: any, selectedPlace: AddressPlace | null) => {
    setValue(selectedPlace);
    if (selectedPlace) {
      onChange(selectedPlace.placeId);
    }
  };

  const debounceFetchPredictions = useDebounce((input: string) => fetchPredictions({
    variables: { input },
  }), 600);

  const handleInputChange = (_event: any, newInputValue: string) => {
    if (newInputValue?.length > 2) {
      debounceFetchPredictions(newInputValue);
    }
  };

  const renderInput = (params: AutocompleteRenderInputParams): ReactNode => (
    <TextField
      placeholder={placeholder}
      label={label}
      size={size}
      disabled={disabled}
      fullWidth={fullWidth}
      variant={variant}
      onBlur={onBlur}
      onFocus={onFocus}
      {...params}
      inputProps={{
        ...params.inputProps,
        autoComplete: 'new-password',
      }}
    />
  );

  return (
    <>
      <Autocomplete
        filterSelectedOptions
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
        includeInputInList
        loading={loading}
        options={data?.searchAddress?.places || []}
        value={value}
        onChange={handleChange}
        onInputChange={handleInputChange}
        renderInput={renderInput}
        renderOption={(option) => <AddressLookupInputOption option={option} />}
        data-qa={dataQa}
      />

      {queryError && (<p>{queryError.message}</p>)}
    </>
  );
};
