import styled from '@emotion/styled';

export const DosageLabel = styled.div`
  margin-right: 30px;
`;

export const DosageSearchPanelControlsContainer = styled.div`
  display: flex;
  align-items: center;
  height: 42px;
`;
