import styled from '@emotion/styled';

export const ScriptVersionText = styled.span`
font-size: 12px;
`;

export const ScriptVersionValueText = styled.span`
color: ${(props) => props.color};
font-size: 28px;
line-height: 28px;
`;

export const RightTextContainer = styled.div`
font-weight: 600;
padding-right: 20px;
display: flex;
width: 100%;
flex-direction: row;
justify-content: flex-end;
align-items: end;
gap: 10px;
`;

export const FooterColorfulLine = styled.div`
background-color: ${(props) => props.color};
width: 100%;
height: 8px;
`;

export const ScriptVersionFooterContainer = styled.div`
width: 100%;
overflow: hidden;
position: fixed;
bottom: 0;
left: 0;
`;
