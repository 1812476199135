import React, { useContext } from 'react';
import { DialogActions, Dialog } from '@material-ui/core';
import { SendPPEProps } from '../TRMQuote.interfaces';
import { Bold } from '../TRMQuote.elements';
import { CompareContext } from '../CompareContext/CompareContext';
import { useFormAnswers } from '../../../../../hooks';
import { useActiveLeadId } from '../../../../routers';
import {
  SendPPEContainer, Row, SendPPETextInput, ConfirmButton, CancelButton, SendPPEWarning, SendPPETitle, SendPPEInvalidEmail,
} from './SendPPE.elements';
import { useCreateActivityMutation, useCustomerQuery, useSendPlanProposalEmail } from '../../../../../graphql';
import moment from 'moment';
import { ActivityType } from '@hiq/crm.types';
import { useToggle } from '../../../CheckMyHealthRecord/CheckMyHealthRecordDataGrid/useToggle';
import SendPPESuccess from './SendPPESuccess';
import { SendPPEMutationResponse } from '../../../../../graphql/mutations/useSendPPEMutation/useSendPPEMutation.interfaces';

const EMAIL_QUESTION_ID = 'customer.person.email';
const FIRST_NAME_QUESTION_ID = 'customer.person.name.first';
const LAST_NAME_QUESTION_ID = 'customer.person.name.last';

export const SendPPE = ({ onClose, open }: SendPPEProps) => {
  const { currentComparisonPlan, emitPlanComparisonEvent } = useContext(CompareContext);
  const leadId = useActiveLeadId();
  const [answers] = useFormAnswers(leadId, false);

  const [email, setEmail] = React.useState(answers[EMAIL_QUESTION_ID]);
  const [firstName, setFirstName] = React.useState(answers[FIRST_NAME_QUESTION_ID]);
  const [lastName, setLastName] = React.useState(answers[LAST_NAME_QUESTION_ID]);
  const [hasInvalidEmail, setHasInvalidEmail] = React.useState(false);
  const [showPinSuccess, toggleShowPinSuccess] = useToggle();
  const { data: customerData } = useCustomerQuery();
  const { id: customerId, activityFeedId } = customerData.customer ?? {};
  const [createActivity] = useCreateActivityMutation(customerId, activityFeedId);

  const createActivityForPPE = React.useCallback((sendPPEResponse: SendPPEMutationResponse) => {
    const pin = sendPPEResponse?.sendPPE?.pin;
    if (!pin || showPinSuccess) return;
    toggleShowPinSuccess();
    createActivity({
      customerId,
      options: {
        type: ActivityType.Note,
        body: `PPE sent to client at ${moment().format('M/D/yyyy')} with pin: ${pin}`,
      },
    });
  }, [showPinSuccess, toggleShowPinSuccess, createActivity, customerId]);

  const [sendPPEMutation, ppeData] = useSendPlanProposalEmail({ onCompleted: createActivityForPPE });

  const onChangeEmail = React.useCallback((event) => {
    setHasInvalidEmail(false);
    setEmail(event.target.value);
  }, []);

  const onChangeFirstName = React.useCallback((event) => {
    setFirstName(event.target.value);
  }, []);

  const onChangeLastName = React.useCallback((event) => {
    setLastName(event.target.value);
  }, []);

  const sendPPE = React.useCallback((email, firstName, lastName) => {
    emitPlanComparisonEvent('Send PPE');
    const planId = currentComparisonPlan?.id;
    sendPPEMutation({
      planId, leadId, email, firstName, lastName,
    });
  }, [emitPlanComparisonEvent, currentComparisonPlan]);

  const onSubmit = React.useCallback(() => {
    if (!email || !email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)) setHasInvalidEmail(true);
    else sendPPE(email, firstName, lastName);
  }, [sendPPE, email, firstName, lastName]);

  const onCloseAfterSent = React.useCallback(() => {
    toggleShowPinSuccess();
    onClose();
  }, [onClose, toggleShowPinSuccess]);

  const disabled = ppeData?.loading || !firstName || !lastName;

  if (showPinSuccess) {
    return (
      <SendPPESuccess
        onClose={onCloseAfterSent}
        open={open}
        currentPlanName={currentComparisonPlan?.name}
        firstName={firstName}
        lastName={lastName}
        email={email}
      />
    );
  }

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="dialog-title" maxWidth={false}>
      <SendPPEContainer>
        <SendPPETitle>
          Send Plan Proposal Email (PPE)
        </SendPPETitle>
        <SendPPEWarning>
          You are sending a plan proposal for
          {' '}
          <Bold>{currentComparisonPlan?.name}</Bold>
        </SendPPEWarning>
        <Row>
          <SendPPETextInput
            label="First Name"
            value={firstName}
            variant="outlined"
            size="small"
            fullWidth
            onChange={onChangeFirstName}
          />
          <SendPPETextInput
            label="Last Name"
            value={lastName}
            variant="outlined"
            size="small"
            fullWidth
            onChange={onChangeLastName}
          />
        </Row>
        <SendPPETextInput
          label="Email"
          value={email}
          variant="outlined"
          size="small"
          fullWidth
          onChange={onChangeEmail}
        />
        {hasInvalidEmail && <SendPPEInvalidEmail>Please enter a valid email address.</SendPPEInvalidEmail>}
        {ppeData?.data?.sendPPE?.success === false && (
        <SendPPEInvalidEmail>
          There was an error sending the PPE. Please try again.
          {ppeData?.data?.sendPPE?.message}
        </SendPPEInvalidEmail>
)}
        <DialogActions>
          <CancelButton onClick={onClose}>
            Close
          </CancelButton>
          <ConfirmButton onClick={onSubmit} disabled={disabled}>
            Continue
          </ConfirmButton>
        </DialogActions>
      </SendPPEContainer>
    </Dialog>
  );
};
