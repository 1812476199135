import * as React from 'react';
import { isPresent } from '@healthiqeng/core.util.is-present';
import { FileListItemProps } from './FileListItem.interfaces';
import { FileListItemContainer, FileItemButtonContainer, FileListItemButtons } from '../FileList.elements';
import { Button, CircularProgress } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import GetAppIcon from '@material-ui/icons/GetApp';
import LinkIcon from '@material-ui/icons/Link';
import { useFileUrlQuery } from '../../useFileUrlQuery';
import { noop } from '../../../../../util/noop';

export const FileListItem: React.FunctionComponent<FileListItemProps> = ({
  id,
  name,
  allowClear,
  onClear,
  allowDownload,
}) => {
  const [getFile, { data, loading }] = useFileUrlQuery();

  const onDownloadUrlClick = () => {
    getFile({ variables: { id } });
  };

  return (
    <FileListItemContainer>
      { name }
      <FileListItemButtons>
        { allowClear && (
        <FileItemButtonContainer>
          <Button onClick={onClear}><Clear /></Button>
        </FileItemButtonContainer>
        )}
        { allowDownload && (
        <FileItemButtonContainer>
          { loading && <CircularProgress size={16} style={{ marginLeft: 10 }} /> }
          { (!loading && !isPresent(data?.fileUrl?.url)) && <Button onClick={onDownloadUrlClick}><GetAppIcon /></Button>}
          { (!loading && isPresent(data?.fileUrl?.url)) && (
            <a href={data?.fileUrl?.url} target="_blank" rel="noreferrer">
              <Button onClick={noop}><LinkIcon /></Button>
            </a>
          )}
        </FileItemButtonContainer>
        )}

      </FileListItemButtons>
    </FileListItemContainer>
  );
};
