import React, {
  memo, FunctionComponent, useCallback, useState,
} from 'react';
import {
  Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText,
} from '@material-ui/core';

import { ConfirmButtonProps } from './ConfirmButton.interfaces';
import { PrimaryButton } from '../PrimaryButton';

export const ConfirmButton: FunctionComponent<ConfirmButtonProps> = memo(({
  cancelText, confirmText, children, dataQa, disabled = false, endIcon, label, onConfirm, title,
}) => {
  const [open, setOpen] = useState(false);
  const openDialog = useCallback(() => {
    setOpen(true);
  }, [setOpen]);
  const closeDialog = useCallback(() => setOpen(false), [setOpen]);
  const confirm = useCallback(async () => {
    await onConfirm();
    closeDialog();
  }, [onConfirm]);
  return (
    <>
      <PrimaryButton
        data-qa={dataQa}
        disabled={disabled}
        onClick={openDialog}
        variant="outlined"
        endIcon={endIcon}
      >
        {label}
      </PrimaryButton>
      <Dialog data-qa={`${dataQa}-dialog`} open={open && !disabled} onClose={openDialog}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <PrimaryButton onClick={closeDialog}>
            {cancelText}
          </PrimaryButton>
          <PrimaryButton onClick={confirm}>
            {confirmText}
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
});
