import { FormAnswers } from '@hiq/crm.types';
import { useCallback, useRef } from 'react';
import { useDebounce } from '@healthiqeng/core.hooks.use-debounce';
import { useFormAnswersMutation, useLocalFormAnswersMutation } from '../../../graphql';
import { UseFormAnswersSaveHook } from './useFormAnswersSave.interfaces';
import { FormAnswersState } from '../useFormAnswersReducer';
import { useSaveAWSFormAnswers } from '../../../components/common/FormGenerator/useSaveAWSFormAnswers';
import { isRetentionSpecialist, isSalesDevelopmentRep } from '../../../util';

export const useFormAnswersSave: UseFormAnswersSaveHook = ({
  leadId, delayMilliseconds, trackingId, callUrlPath, userProfile, onFailure,
}) => {
  const [saveAnswers] = useFormAnswersMutation(trackingId, onFailure);
  const [localSaveAnswers] = useLocalFormAnswersMutation();
  const saveAnswersDebounced = useDebounce(saveAnswers, delayMilliseconds);
  const saveAWSAnswersDebounced = useDebounce(useSaveAWSFormAnswers, delayMilliseconds);
  const isSDR = isSalesDevelopmentRep(userProfile);
  const isRetSpecialist = isRetentionSpecialist(userProfile);
  const timeout = useRef<ReturnType<typeof setTimeout>>();
  const previousBeforeUnloadEventHandler = useRef<() => void>();

  return useCallback((state: FormAnswersState, immediate?: boolean) => {
    const answers = dirtyValues(state);
    if (isSDR || isRetSpecialist) {
      const localSavePromise = localSaveAnswers({ answers })
        .then((localSaveResult) => {
          // Only triggers when we are in the /customer/:customerid/lead/:leadId route
          if (leadId && callUrlPath && isSDR) {
            saveAWSAnswersDebounced(leadId, callUrlPath, answers, {
              timestamp: new Date().getTime(),
            });
          }
          return localSaveResult;
        });
      if (!leadId) return localSavePromise;
    }
    if (immediate) {
      return saveAnswers({
        leadId,
        answers,
        callUrlPath,
      });
    }

    // Remove previous event listener, if existing
    if (previousBeforeUnloadEventHandler.current) {
      window.removeEventListener('beforeunload', previousBeforeUnloadEventHandler.current);
      window.clearTimeout(timeout.current);
    }
    // Adds an event listener to save answers before the user exits the page
    const beforeUnloadHandler = () => {
      saveAnswers({ leadId, answers, callUrlPath });
    };
    previousBeforeUnloadEventHandler.current = beforeUnloadHandler;
    window.addEventListener('beforeunload', beforeUnloadHandler);

    // If user doesn't exits the page, removes the event listener so that debounced save gets called
    timeout.current = setTimeout(() => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    }, delayMilliseconds - 25);

    return saveAnswersDebounced({
      leadId,
      answers,
      callUrlPath,
    });
  }, [localSaveAnswers, saveAnswersDebounced, leadId, callUrlPath, isSDR]);
};

function dirtyValues(state: FormAnswersState): FormAnswers {
  return [...state.dirtyKeys].reduce((answers: FormAnswers, key: string) => ({
    ...answers,
    [key]: state.answers[key],
  }), {});
}
