import { gql } from 'apollo-boost';
import { MutationCustomReturn, useMutationCustom } from '../useMutationCustom';
import { ToggleDoctorMutationResponse, ToggleDoctorMutationVariables } from './useToggleDoctorMutation.interfaces';
import {
  SELECTED_DOCTORS_QUERY,
  SelectedDoctorsQueryData,
  SelectedDoctorsQueryVariables,
} from '../../queries';
import { PRECISION_MEDICARE_DOCTOR_FRAGMENT } from '../../fragments';

const TOGGLE_DOCTOR_MUTATION = gql`
  mutation ToggleDoctor($input: PrecisionMedicareToggleDoctorInput!) {
    toggleDoctor(input: $input) {
      doctor {
        ...PrecisionMedicareDoctorFragment
      }
      removed
    }
  }
  ${PRECISION_MEDICARE_DOCTOR_FRAGMENT}
`;

export function useToggleDoctorMutation(): MutationCustomReturn<
ToggleDoctorMutationResponse, ToggleDoctorMutationVariables> {
  return useMutationCustom<ToggleDoctorMutationResponse, ToggleDoctorMutationVariables>(TOGGLE_DOCTOR_MUTATION, {
    update: (cache, mutationResult) => {
      const { doctor, removed } = mutationResult.data.toggleDoctor;
      const cachedQuery: SelectedDoctorsQueryData = cache.readQuery({
        query: SELECTED_DOCTORS_QUERY,
        variables: {
          leadId: doctor.leadId,
        },
      });
      const updatedData = removed
        ? cachedQuery.selectedDoctors.filter((doc) => doc.id !== doctor.id)
        : [...cachedQuery.selectedDoctors, doctor];
      cache.writeQuery<SelectedDoctorsQueryData, SelectedDoctorsQueryVariables>({
        query: SELECTED_DOCTORS_QUERY,
        variables: {
          leadId: doctor.leadId,
        },
        data: {
          selectedDoctors: updatedData,
        },
      });
    },
  });
}
