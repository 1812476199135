import * as React from 'react';
import { CheckMyHealthRecordVerifyInfoProps } from './CheckMyHealthRecordVerifyInfo.interfaces';
import { useActiveLeadId } from '../../../routers';
import { useFormAnswers } from '../../../../hooks';
import { InfoBlock } from '../../ScriptBlock/InfoBlock';
import { CustomerCommunicationQuestions } from '../../CustomerCommunication/CustomerCommunicationQuestions';
import { CheckMyHealthRecordVerifyInfoContainer } from './CheckMyHealthRecordVerifyInfo.elements';

export const CheckMyHealthRecordVerifyInfo: React.FunctionComponent<CheckMyHealthRecordVerifyInfoProps> = () => {
  const leadId = useActiveLeadId();
  const trackingId = React.useMemo(() => `CustomerCommunication-${Date.now()}`, []);
  const [formAnswers, onChange] = useFormAnswers(leadId, true, trackingId);

  const handleOnChange = (key: string, val: string) => {
    onChange(key, val);
  };

  return (
    <CheckMyHealthRecordVerifyInfoContainer>
      <InfoBlock>Please verify the following information if the user agrees to sign up to CMHR:</InfoBlock>
      <CustomerCommunicationQuestions
        leadId={leadId}
        formAnswers={formAnswers}
        onChange={handleOnChange}
        includeGender
        includeDOB
        includeZipCode
      />
    </CheckMyHealthRecordVerifyInfoContainer>
  );
};
