import React from 'react';
import { Bold, Text, WarningMessage } from './HealthRecordPullStatus.elements';
import { HealthRecordPullStatusInterface } from './HealthRecordPullStatus.interfaces';
import { CustomerHealthRecordPullStatus, CustomerHealthRecordPullErrorKey } from '@hiq/crm.types';

const customerHealthRecordPullWarningKeys = [CustomerHealthRecordPullErrorKey.NOT_FOUND];

const HealthRecordPullStatusWarning = ({
  pullStatus,
}: HealthRecordPullStatusInterface) => React.useMemo(() => {
  const getPrefix = (errorKey: string) => {
    if (errorKey === CustomerHealthRecordPullErrorKey.NOT_FOUND) return 'Not found.';
    if (errorKey === CustomerHealthRecordPullErrorKey.USER_NOT_FOUND) return 'User not found.';
    return 'Error.';
  };

  if (!pullStatus?.success && pullStatus?.recordPullStatus === CustomerHealthRecordPullStatus.PROCESSING) {
    return (
      <WarningMessage severity="warning">
        <Text>
          Health Record Status:&nbsp;
          <Bold>On Hold.</Bold>
          &nbsp;
          {pullStatus?.message}
        </Text>
      </WarningMessage>
    );
  }

  if (pullStatus?.success === false && customerHealthRecordPullWarningKeys.includes(pullStatus?.errorKey)) {
    return (
      <WarningMessage severity="warning">
        <Text>
          Health Record Status:&nbsp;
          <Bold>{getPrefix(pullStatus?.errorKey)}</Bold>
          &nbsp;
          {pullStatus?.message}
        </Text>
      </WarningMessage>
    );
  }

  if (pullStatus?.success === false) {
    return (
      <WarningMessage severity="error">
        <Text>
          Health Record Status:&nbsp;
          <Bold>{getPrefix(pullStatus?.errorKey)}</Bold>
          &nbsp;
          {pullStatus?.message}
        </Text>
      </WarningMessage>
    );
  }
  if (pullStatus?.success) {
    return (
      <WarningMessage severity="success">
        <Text>
          Health Record Status:&nbsp;
          <Bold>Completed.</Bold>
          &nbsp;
          – Health Record import was successful!
        </Text>
      </WarningMessage>
    );
  }
  return (
    <WarningMessage severity="warning">
      <Text>
        Health Record Status:&nbsp;
        <Bold>Loading.</Bold>
        &nbsp;
        – Searching for customer Health Record
      </Text>
    </WarningMessage>
  );
}, [pullStatus]);

export default HealthRecordPullStatusWarning;
