import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../../../themes';

interface AnchorTabWrapperProps extends ComponentWithThemeProps {
  active?: boolean;
}

export const AnchorTabWrapper = styled('div')<AnchorTabWrapperProps>(({ theme, active }) => css`
  width: 100%;
  display: flex;
  padding-left: 34px;
  background-color: ${active ? theme.color.grayDark : theme.color.grayLight};
`);
