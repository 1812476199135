import React from 'react';
import { CompareContext } from '../CompareContext/CompareContext';
import { CompareDoctorBenefit } from './CompareDoctorBenefit';
import { CompareSection } from '../CompareSection';
import { CompareContainer } from '../TRMQuote.elements';

const CompareDoctor = () => {
  const {
    currentPlan,
    currentComparisonPlan,
    emitPlanComparisonEvent,
  } = React.useContext(CompareContext);

  const onToggle = React.useCallback(() => emitPlanComparisonEvent('Opened Doctors'),
    [emitPlanComparisonEvent]);

  return (
    <CompareContainer>
      <CompareSection
        title="Doctors"
        currentPlanRank={currentPlan?.hiqProviderResults?.providerRank}
        comparisonPlanRank={currentComparisonPlan?.hiqProviderResults?.providerRank}
        onToggle={onToggle}
        currentPlanRankLink={currentPlan?.hiqProviderLookupUrl}
        comparisonPlanRankLink={currentComparisonPlan?.hiqProviderLookupUrl}
      >
        {currentComparisonPlan?.hiqProviderResults.providersMap.otherDoctors.map(
          (doctor, index) => (
            <CompareDoctorBenefit
              benefitName={doctor.name}
              currentDoctor={currentPlan?.hiqProviderResults.providersMap.otherDoctors[index]}
              compareDoctor={doctor}
            />
          ),
        )}
      </CompareSection>
    </CompareContainer>

  );
};

export default CompareDoctor;
