import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { DividerProps } from './Divider.interfaces';
import { ComponentWithThemeProps } from '../../../themes';

export const DividerContainer = styled('div')<DividerProps & ComponentWithThemeProps>(({ theme, color, marginCss }) => css`
  height: 1px;
  background-color: ${theme.color[color || 'grayLight']};
  margin: ${marginCss || '8px -10px'};
`);
