import * as React from 'react';
import { JsonCompatible } from '@healthiqeng/core.types';
import { ReflexiveQuestionProps } from './ReflexiveQuestionInput.interfaces';
import { useFormAnswers } from '../../../../hooks';
import { RadioYesNoInput } from '../RadioYesNoInput';
import { FormItemTypeFormContainer } from '../../FormGenerator/FormGeneratorQuestionFlow/FormItem/FormItemTypeForm/FormItemTypeForm.elements';

export const ReflexiveQuestion: React.FunctionComponent<ReflexiveQuestionProps> = ({
  defaultValue,
  disabled,
  answersKey,
  FollowUpComponent,
}) => {
  const [answers, onChange] = useFormAnswers();
  const rootLevelAnswersKey = answersKey.substring(0, answersKey.lastIndexOf('.'));
  const onReflexiveQuestionChange = React.useCallback((val: JsonCompatible) => (onChange(answersKey, val)),
    [answersKey, onChange]);
  const value = answers[answersKey];

  const followUpQuestions = value === true && (
    <FormItemTypeFormContainer depth={1}>
      <FollowUpComponent
        questionId={rootLevelAnswersKey}
        answers={answers}
        onChange={onChange}
      />
    </FormItemTypeFormContainer>
  );
  return (
    <>
      <RadioYesNoInput
        value={value ?? defaultValue}
        onChange={onReflexiveQuestionChange}
        disabled={disabled}
        dataQa={`question-${answersKey}`}
      />
      {followUpQuestions}
    </>
  );
};
