import React from 'react';
import {
  Container, Bold, Column, DoctorsTitle, SaveButton, Row,
} from './PlanComparisonError.elements';
import { PlanComparisonErrorProps } from './PlanComparisonError.interfaces';
import { useDoctorColumnConfig } from '../../CheckMyHealthRecord/CheckMyHealthRecordDataGrid/useDoctorColumnsConfig';
import { useCellConfig } from '../../CheckMyHealthRecord/CheckMyHealthRecordDataGrid/useCellConfig';
import { withContentRect } from 'react-measure';
import { HealthRecordDoctor, PlanErrorDoctor } from '@hiq/crm.types';
import { Datagrid } from '../../Datagrid';
import { useCriticalDoctorsQuery, useToggleCriticalDoctor } from '../../../../graphql';
import { useActiveLeadId } from '../../../routers';
import { eventBus } from '../../../../services';
import { SunfireV2DataChangeEvent } from '../../../../events';
import { emitAnalyticsEventNoLimit } from '../../../../events/emitAnalyticsEvent';

export const PlanComparisonError: React.ComponentType<PlanComparisonErrorProps> = withContentRect('bounds')(({
  measureRef,
  contentRect,
  doctors,
  switchView,
}) => {
  const [cellWidth, isReducedViewport] = useCellConfig(contentRect.bounds.width);
  const leadId = useActiveLeadId();
  const [toggleCriticalDoctor] = useToggleCriticalDoctor();
  const onCriticalDoctorSelect = React.useCallback((data: HealthRecordDoctor) => {
    emitAnalyticsEventNoLimit('Remove Must Have Doctor', { includedPlanCount: data?.includedPlanCount });

    toggleCriticalDoctor({
      input: {
        leadId,
        npi: data.npi,
        name: data.name,
      },
    });
  }, [toggleCriticalDoctor]);

  const onDoctorSelection = () => {
  };
  const doctorsColumns = useDoctorColumnConfig({
    cellWidth,
    isReducedViewport,
    onSelect: onDoctorSelection,
    onCriticalSelect: onCriticalDoctorSelect,
    isRemoving: false,
    disabled: true,
  });
  const getDoctorRowId = React.useCallback(
    (data: Partial<PlanErrorDoctor>) => data.id,
    [],
  );
  const criticalDoctorsQueryResult = useCriticalDoctorsQuery(leadId);
  const criticalDoctors = criticalDoctorsQueryResult?.data?.criticalDoctors ?? [];

  const doctorsToBeReviewed = React.useMemo(() => (
    doctors?.map((doc) => ({
      ...doc,
      npi: doc.id,
      practice: doc.specialty,
      selected: true,
      includedPlanCount: doc.includedPlanCount,
      criticalSelected: criticalDoctors.some((current) => current.npi === doc.id),
    }))
  ), [doctors, criticalDoctors]);

  const selectedRowsIds = React.useMemo(() => doctors?.map((doctor) => getDoctorRowId(doctor)), [doctors]);

  const doctorsGrid = React.useMemo(() => (
    <Datagrid
      data={doctorsToBeReviewed ?? []}
      enableSelection={false}
      selectionMultiselect
      getRowId={getDoctorRowId}
      selectedRowIds={selectedRowsIds}
      customColumns={doctorsColumns}
      selectedColor="#05AB5B"
      selectedBackground="#F6FFFB"
    />
  ), [doctorsToBeReviewed, doctorsColumns, selectedRowsIds]);

  const onSaveAndContinue = React.useCallback(() => {
    switchView();
    eventBus.emit(new SunfireV2DataChangeEvent());
  }, [eventBus, switchView]);

  return (
    <Container ref={measureRef}>
      <Column>
        <Column style={{ justifyItems: 'center' }}>
          <Bold>
            Please review and deselect some must have doctors
          </Bold>
          <span>
            There weren’t enough plans that included everything listed below, so the lowest priorities should be removed.
          </span>
        </Column>
        <DoctorsTitle>
          MUST HAVE
        </DoctorsTitle>
        {doctorsGrid}
      </Column>
      <Row style={{ marginTop: '10px', gap: '8px' }}>
        <SaveButton onClick={onSaveAndContinue}>
          Save and continue
        </SaveButton>
      </Row>
    </Container>
  );
});
