import React from 'react';
import { DialogProps } from './UIToolsContext.interfaces';

interface UIToolsContextInterface {
  createDialog: (dialogProps: DialogProps) => void;
  closeDialog: () => void;
}

export const UIToolsContext = React.createContext<UIToolsContextInterface>({} as UIToolsContextInterface);

export const useUITools = () => React.useContext(UIToolsContext);
