import { useMemo } from 'react';
import { ProductTypePropertyAndCasualty } from '@hiq/crm.types';
import { useCarriersQuery } from '../../../../../../graphql/queries';

export function usePropertyAndCasualtyCarriers(type?: ProductTypePropertyAndCasualty) {
  const { data, loading, error } = useCarriersQuery(type);
  const carrierOptions = useMemo(() => (
    data?.getAllPropertyAndCasualtyCarriers?.map((carrier) => ({
      label: carrier.name,
      value: carrier.id,
    })) ?? []
  ), [data]);

  return {
    loading,
    error,
    carrierOptions,
  };
}
