import React from 'react';
import { CompareContext } from '../CompareContext/CompareContext';
import {
  CompareContainer, CompareData, CompareTitle, CompareTitleRow,
} from '../TRMQuote.elements';
import { CompareRank } from '../CompareGrid/CompareRank';

export const CompareServiceScore = () => {
  const { currentPlan, currentComparisonPlan, totalPlans } = React.useContext(CompareContext);
  const currentPlanRank = currentPlan?.hiqCustomerServiceResults?.customerServiceRank;
  const currentComparisonPlanRank = currentComparisonPlan?.hiqCustomerServiceResults?.customerServiceRank;
  return (
    <CompareContainer>
      <CompareTitleRow>
        <CompareData hidePadding>
          <CompareTitle style={{ marginLeft: '24px' }}>
            Customer Satisfaction
          </CompareTitle>
        </CompareData>
        <CompareRank
          rank={currentPlanRank}
          totalPlans={totalPlans}
          highlight={currentPlanRank < currentComparisonPlanRank}
        />
        <CompareRank
          rank={currentComparisonPlanRank}
          totalPlans={totalPlans}
          highlight={currentComparisonPlanRank < currentPlanRank}
        />
      </CompareTitleRow>
    </CompareContainer>
  );
};
