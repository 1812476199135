import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { AvertaPeNormal500 } from '@healthiqeng/core.fonts';
import { COLOR } from '../constants';
import { resetA } from '../resets';

interface ICtaButtonContainerProps {
  colorTheme: 'green' | 'blue' | 'red' | 'white';
  fillingTheme: 'full' | 'bordered' | 'transparent';
  size: 'small' | 'large';
  fullWidth: boolean;
  disabled: boolean;
}

interface IDecisionTree {
  [condition: string]: IDecisionTree | string;
}
function match(tree: IDecisionTree, conditions: string[], defaultValue: string) {
  let node = tree;
  for (const condition of conditions) {
    if (typeof node[condition] === 'undefined') {
      return defaultValue;
    }
    if (typeof node[condition] === 'string') {
      return node[condition];
    }
    node = node[condition] as IDecisionTree;
  }
}

export const CtaButtonContainer = styled('a')<ICtaButtonContainerProps>(resetA, (props) => {
  const background = match({
    full: {
      green: COLOR.GREEN,
      blue: COLOR.BLUE,
      red: COLOR.RED,
      white: COLOR.WHITE,
    },
    bordered: 'transparent',
    transparent: 'transparent',
  }, [props.fillingTheme, props.colorTheme], 'transparent');

  const hoveredBackground = match({
    full: {
      green: COLOR.GREEN_SELECTED,
      blue: COLOR.BLUE_SELECTED,
      red: COLOR.RED_SELECTED,
      white: COLOR.WHITE_SELECTED,
    },
    bordered: 'transparent',
    transparent: 'transparent',
  }, [props.fillingTheme, props.colorTheme], 'transparent');

  const border = match({
    full: 'none',
    bordered: {
      green: `1px solid ${COLOR.GREEN}`,
      blue: `1px solid ${COLOR.BLUE}`,
      red: `1px solid ${COLOR.RED}`,
      white: `1px solid ${COLOR.WHITE}`,
    },
    transparent: 'none',
  }, [props.fillingTheme, props.colorTheme], 'none');

  const hoveredBorder = match({
    full: 'none',
    bordered: {
      green: `1px solid ${COLOR.GREEN_SELECTED}`,
      blue: `1px solid ${COLOR.BLUE_SELECTED}`,
      red: `1px solid ${COLOR.RED_SELECTED}`,
      white: `1px solid ${COLOR.WHITE_SELECTED}`,
    },
    transparent: 'none',
  }, [props.fillingTheme, props.colorTheme], 'none');

  const color = match({
    full: 'white',
    bordered: {
      green: COLOR.GREEN,
      blue: COLOR.BLUE,
      red: COLOR.RED,
      white: COLOR.WHITE,
    },
    transparent: {
      green: COLOR.GREEN,
      blue: COLOR.BLUE,
      red: COLOR.RED,
      white: COLOR.WHITE,
    },
  }, [props.fillingTheme, props.colorTheme], 'inherit');

  const hoveredColor = match({
    full: 'white',
    bordered: {
      green: COLOR.GREEN_SELECTED,
      blue: COLOR.BLUE_SELECTED,
      red: COLOR.RED_SELECTED,
      white: COLOR.WHITE_SELECTED,
    },
    transparent: {
      green: COLOR.GREEN_SELECTED,
      blue: COLOR.BLUE_SELECTED,
      red: COLOR.RED_SELECTED,
      white: COLOR.WHITE_SELECTED,
    },
  }, [props.fillingTheme, props.colorTheme], 'inherit');

  const size = match({
    small: {
      full: `
        padding: 12px;
        line-height: 24px;
        font-size: 16px;
      `,
      bordered: `
        padding: 11px;
        line-height: 24px;
        font-size: 16px;
      `,
      transparent: `
        padding: 12px;
        line-height: 24px;
        font-size: 16px;
      `,
    },
    large: {
      full: `
        padding: 20px;
        line-height: 16px;
        font-size: 16px;
      `,
      bordered: `
        padding: 19px;
        line-height: 16px;
        font-size: 16px;
      `,
      transparent: `
        padding: 20px;
        line-height: 16px;
        font-size: 16px;
      `,
    },
  }, [props.size, props.fillingTheme], '');

  return css`
    ${AvertaPeNormal500};
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    ${size};
    border: ${border};
    border-radius: 2px;
    background-color: ${background};
    color: ${color};
    ${props.fullWidth && css`
      width: 100%;
    `}

    &:active,
    &:visited,
    &:focus {
      color: ${color};
    }

    &:hover {
      background-color: ${hoveredBackground};
      color: ${hoveredColor};
      border: ${hoveredBorder};
    }

    &[disabled] {
      cursor: default;
      opacity: 0.8;
    }
  `;
});
