import { gql } from 'apollo-boost';
import { LazyQueryHookOptions, useLazyQuery } from '@apollo/react-hooks';
import {
  SunfireV2PlansByZipAndCountyQueryData, SunfireV2PlansByZipAndCountyQueryVariables,
} from './useSunfireV2PlansByZipAndCountyQuery.interfaces';

const SUNFIRE_PLANS_QUERY = gql`
  query FindSunfireV2PlansByZipAndCounty($zipCode: String!, $county: String!, $year: Int!) {
    findSunfirePlansByZipAndCounty(zipCode: $zipCode, county: $county, year: $year) {
      contractId
      name
      planId
      id
      segmentId
      rating
      snp
      type
      carrierName
    }
  }
`;

export function useSunfireV2PlansByZipAndCountyQueryLazy(
  options?: LazyQueryHookOptions<SunfireV2PlansByZipAndCountyQueryData, SunfireV2PlansByZipAndCountyQueryVariables>,
) {
  return useLazyQuery<SunfireV2PlansByZipAndCountyQueryData, SunfireV2PlansByZipAndCountyQueryVariables>(SUNFIRE_PLANS_QUERY, options);
}
