import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import { QuestionFlowRendererProps } from './QuestionFlowRenderer.interfaces';
import { referenceRendererFactory } from './ReferenceRenderer';
import { TextRenderer } from './TextRenderer';
import { ParagraphRenderer } from './ParagraphRenderer';
import { BlockQuoteRenderer } from './BlockQuoteRenderer';
import { HeadingRenderer } from './HeadingRenderer';
import { useTemplateReplacer } from './useTemplateReplacer';
import { scriptBlockRendererFactory } from './ScriptBlockRenderer';
import { LinkRenderer } from './LinkRenderer';

export const QuestionFlowRenderer: React.FunctionComponent<QuestionFlowRendererProps> = ({
  markdownSource,
  leadId,
  questionFlowComponent,
  onFieldsChange,
  CustomElement,
}) => {
  const renderers = React.useMemo(() => ({
    heading: HeadingRenderer,
    blockquote: BlockQuoteRenderer,
    inlineCode: referenceRendererFactory(leadId, questionFlowComponent, CustomElement, onFieldsChange),
    text: TextRenderer,
    paragraph: ParagraphRenderer,
    code: scriptBlockRendererFactory(leadId, questionFlowComponent, QuestionFlowRenderer),
    link: LinkRenderer,
  }), []);
  return (
    <ReactMarkdown
      source={useTemplateReplacer(markdownSource)}
      renderers={renderers}
      linkTarget="_blank"
    />
  );
};
