import { gql } from 'apollo-boost';
import {
  FollowUpAvailabilityQueryData,
  FollowUpAvailabilityVariables,
} from './useFollowUpAvailabilityLazyQuery.interfaces';
import { useLazyQuery } from '@apollo/react-hooks';

const FOLLOW_UP_AVAILABILITY_QUERY = gql`
  query followUpAvailability($inputs: FollowUpAvailabilityInput!) {
    followUpAvailability(inputs: $inputs) {
      start
      end
      timeFrame
    }
  }
`;

export function useFollowUpAvailabilityLazyQuery() {
  return useLazyQuery<FollowUpAvailabilityQueryData, FollowUpAvailabilityVariables>(FOLLOW_UP_AVAILABILITY_QUERY);
}
