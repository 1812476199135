import {
  PropertyAndCasualtyPolicyTermLength,
  BillingFrequency,
  ProductTypePropertyAndCasualty,
  QuotePresentationType,
  PropertyAndCasualtyBaseQuoteCreateProperties,
} from '@hiq/crm.types';

import { useActiveLeadId } from '../../../../../routers/LeadRouter/useActiveLeadId';
import { usePropertyAndCasualtyQuoteMutation } from '../../../../../../graphql/mutations/usePropertyAndCasualtyQuoteMutation';
import { usePropertyAndCasualtyCarriers } from './usePropertyAndCasualtyCarriers';
import { useExternalQuoteInput } from '../useExternalQuoteInput';

interface PropertyAndCasualtyQuoteBaseProperties {
  type: ProductTypePropertyAndCasualty;
  presentationType: QuotePresentationType,
  policyTermLength: PropertyAndCasualtyPolicyTermLength;
  billingFrequency: BillingFrequency;
  effectiveDate: string;
  rate: {
    monthly?: number;
    quarterly?: number;
    semiAnnually?: number;
    annually?: number;
  }
}

export type ExternalQuoteState<T> = Partial<T> & PropertyAndCasualtyQuoteBaseProperties;

export function useExternalPropertyAndCasualtyQuote<T extends PropertyAndCasualtyBaseQuoteCreateProperties>(
  defaults: ExternalQuoteState<T>,
) {
  const leadId = useActiveLeadId();
  const [addQuote, { loading, error, data: addQuoteData }] = usePropertyAndCasualtyQuoteMutation();
  const { carrierOptions, loading: carriersLoading, error: carriersError } = usePropertyAndCasualtyCarriers(defaults.type);
  const [quoteInput, setQuoteInput] = useExternalQuoteInput<ExternalQuoteState<T>>(defaults);
  const submitQuote = () => addQuote({
    leadId,
    properties: quoteInput as any,
  });

  return {
    quoteInput,
    setQuoteInput,
    carrierOptions,
    submitQuote,
    loading: loading || carriersLoading,
    error: error || carriersError,
    addQuoteData,
  };
}
