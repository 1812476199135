import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { ComponentWithThemeProps } from '../../../../../themes';
import { PrimaryButton } from '../../../inputs/PrimaryButton';

export const CarrierLinkButton = styled(PrimaryButton)(css`
  margin-top: 10px;
`);

export const CarrierLink = styled('a')<ComponentWithThemeProps>(({ theme }) => css`
  text-decoration: none;
  text-align: center;
  margin-left: 5px;
  color: ${theme.color.primary};
`);

export const FinalQuoteChipContainer = styled('div')(css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 14px 0 0 0;
`);
