import { gql } from 'apollo-boost';
import { MutationCustomReturn, useMutationCustom } from '../useMutationCustom';
import {
  SELECTED_NON_ICDS_QUERY,
  SelectedNonICDsQueryData,
  SelectedNonICDsQueryVariables,
} from '../../queries';
import { PRECISION_MEDICARE_NON_ICD_FRAGMENT } from '../../fragments';
import { ToggleNonICDMutationResponse, ToggleNonICDMutationVariables } from './useToggleNonICDMutation.interfaces';

const TOGGLE_NON_ICD_MUTATION = gql`
  mutation ToggleNonICD($input: PrecisionMedicareToggleNonICDInput!) {
    toggleNonICD(input: $input) {
      nonIcd {
        ...PrecisionMedicareNonICDFragment
      }
      removed
    }
  }
  ${PRECISION_MEDICARE_NON_ICD_FRAGMENT}
`;

export function useToggleNonICDMutation(): MutationCustomReturn<
ToggleNonICDMutationResponse, ToggleNonICDMutationVariables> {
  return useMutationCustom<ToggleNonICDMutationResponse, ToggleNonICDMutationVariables>(TOGGLE_NON_ICD_MUTATION, {
    update: (cache, mutationResult) => {
      const { nonIcd, removed } = mutationResult.data.toggleNonICD;
      const cachedQuery: SelectedNonICDsQueryData = cache.readQuery({
        query: SELECTED_NON_ICDS_QUERY,
        variables: {
          leadId: nonIcd.leadId,
        },
      });
      const updatedData = removed
        ? cachedQuery.selectedNonICDs.filter((current) => current.id !== nonIcd.id)
        : [...cachedQuery.selectedNonICDs, nonIcd];
      cache.writeQuery<SelectedNonICDsQueryData, SelectedNonICDsQueryVariables>({
        query: SELECTED_NON_ICDS_QUERY,
        variables: {
          leadId: nonIcd.leadId,
        },
        data: {
          selectedNonICDs: updatedData,
        },
      });
    },
  });
}
