import * as React from 'react';
import { useLogPageLoad } from '../../../../graphql';
import { LogPageLoadProps } from './LogPageLoad.interfaces';

export const LogPageLoad: React.FunctionComponent<LogPageLoadProps> = ({ page, detail }: LogPageLoadProps) => {
  const [logPageLoad] = useLogPageLoad();
  React.useEffect(() => {
    logPageLoad({ page, detail });
  }, []);

  return null;
};
