import * as React from 'react';
import {
  useCustomerQuery,
} from '../../../../graphql';
import {
  ActivitiesQueryVariables,
  EmailsQueryData,
  useEmailQuery,
} from '../../../../graphql/queries/useActivitiesQuery';
import { FetchMoreOptions } from 'apollo-boost';

import { ACTIVITIES_LIMIT, Activity, groupActivities } from '../../ActivityFeed/Activities/Activities';

export const Emails: React.FunctionComponent = () => {
  const { loading: customerLoading, error: customerError, data: customerData } = useCustomerQuery();
  const { activityFeedId, id: customerId } = customerData?.customer ?? {};
  const [fetchEmailHistory, {
    data, error, loading, fetchMore,
  }] = useEmailQuery();
  const [isFetchMoreVisible, setIsFetchMoreVisible] = React.useState(true);

  React.useEffect(() => {
    if (activityFeedId) {
      fetchEmailHistory({
        variables: {
          customerId,
          activityFeedId,
          offset: 0,
          limit: ACTIVITIES_LIMIT,
          pullTasks: true,
        },
      });
    }
  }, [customerId, activityFeedId]);

  React.useEffect(() => {
    handleFetchMoreVisibility();
  }, [data?.emails?.length]);

  const handleFetchMoreVisibility = () => {
    if (data?.emails?.length < ACTIVITIES_LIMIT) {
      setIsFetchMoreVisible(false);
    } else if (data?.emails?.length === ACTIVITIES_LIMIT) {
      setIsFetchMoreVisible(true);
    }
  };

  const onFetchMoreHandler = React.useCallback(async () => {
    fetchMore({
      variables: {
        offset: data?.emails?.length,
        pullTasks: false,
      },
      updateQuery: (previousData, { fetchMoreResult }) => {
        if (!fetchMoreResult?.emails?.length || fetchMoreResult?.emails?.length < ACTIVITIES_LIMIT) {
          setIsFetchMoreVisible(false);
        }
        return ({
          emails: [
            ...(previousData?.emails ?? []),
            ...(fetchMoreResult?.emails ?? []),
          ],
        });
      },
    } as FetchMoreOptions<EmailsQueryData, ActivitiesQueryVariables>);
  }, [fetchMore, data?.emails?.length]);

  const { emails } = data ?? {};
  const groupedActivities = React.useMemo(
    () => groupActivities(emails ?? []),
    [emails],
  );
  return (
    <Activity
      groupedActivities={groupedActivities}
      customerLoading={customerLoading}
      loading={loading}
      customerError={customerError}
      error={error}
      isFetchMoreVisible={isFetchMoreVisible}
      onFetchMoreHandler={onFetchMoreHandler}
    />
  );
};
