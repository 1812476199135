import { HealthRecordDoctor } from '@hiq/crm.types';
import React, { useState } from 'react';
import { AddDoctorPanelContainer } from './AddDoctorPanel.elements';
import { AddDoctorPanelProps, Doctor } from './AddDoctorPanel.interfaces';
import { DoctorLocationSearchPanel } from '../../DoctorLocationSearchPanel';
import { DoctorSearchPanel } from '../DoctorSearchPanel';
import { useUpsertOverlayMutation } from '../../../../../../graphql';
import { useCustomerId, useActiveLeadId } from '../../../../../routers';
import {
  CTA,
  PanelInnerWrapper,
  RemoveItemButton,
  SaveItemButton,
  SectionTitle,
} from '../../CheckMyHealthRecordDataGrid.elements';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { emitAnalyticsEventNoLimit } from '../../../../../../events/emitAnalyticsEvent';

export const AddDoctorPanel = ({ setPanelClosed, zip }: AddDoctorPanelProps) => {
  const [upsertOverlayDoctor] = useUpsertOverlayMutation();
  const customerId = useCustomerId();
  const leadId = useActiveLeadId();
  const onCancel = () => setPanelClosed();
  const [location, setLocation] = useState<{ city: string, state: string, zip: string }>();
  const [doctor, setDoctor] = useState<Doctor>();

  const onSave = () => {
    const doctorToInsert: HealthRecordDoctor = {
      name: doctor.name,
      addressLine1: '',
      city: location.city,
      state: location.state,
      zip: location.zip,
      npi: doctor.id,
      visitsLastYear: null,
      visitsLast3Years: null,
      visitsLast5Years: null,
      lastVisit: null,
      practice: '',
    };

    upsertOverlayDoctor({
      input: {
        customerId,
        externalId: doctor.id,
        externalType: 'HealthRecordDoctor',
        data: doctorToInsert,
      },
    });
    emitAnalyticsEventNoLimit('New Doctor Added', { ...doctorToInsert, customerId, leadId });
    setPanelClosed();
  };

  const handleOnLocationChange = (location?: { city: string, state: string, zip: string }) => setLocation(location);
  const handleDoctorChange = (doctor?: Doctor) => setDoctor(doctor);

  const isSaveDisabled = doctor === undefined;

  return (
    <AddDoctorPanelContainer>
      <SectionTitle>
        Add Doctors
      </SectionTitle>
      <PanelInnerWrapper>
        {location
          ? <DoctorSearchPanel onDoctorChanged={handleDoctorChange} onLocationChanged={handleOnLocationChange} location={location} />
          : <DoctorLocationSearchPanel onLocationChanged={handleOnLocationChange} zip={zip} />}
      </PanelInnerWrapper>
      <CTA>
        <RemoveItemButton variant="contained" startIcon={<CloseIcon />} onClick={onCancel}>
          Cancel
        </RemoveItemButton>
        <SaveItemButton variant="contained" startIcon={<CheckIcon />} onClick={onSave} disabled={isSaveDisabled}>
          Save
        </SaveItemButton>
      </CTA>
    </AddDoctorPanelContainer>
  );
};
