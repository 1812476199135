import { gql } from 'apollo-boost';
import { MutationCustomReturn, useMutationCustom } from '../useMutationCustom';
import { UpdateCallQualityMutationResponse, UpdateCallQualityMutationVariables } from './useUpdateCallQualityMutation.variables';
import { useCallback } from 'react';
import { emitAnalyticsEvent } from '../../../events/emitAnalyticsEvent';
import { MutationHookOptions } from '@apollo/react-hooks';

const UPDATE_CALL_QUALITY_MUTATION = gql`
  mutation UpdateCallQualityMutation($customerId: ID!, $input: UpdateCallQualityDataInput!) {
    updateCallQuality(customerId: $customerId, input: $input) {
     success
     message
    }
  }
`;

export function useUpdateCallQualityMutation(
  options: MutationHookOptions<UpdateCallQualityMutationResponse, UpdateCallQualityMutationVariables>,
): MutationCustomReturn<
  UpdateCallQualityMutationResponse,
  UpdateCallQualityMutationVariables> {
  const [updateCallQuality, result] = useMutationCustom<
  UpdateCallQualityMutationResponse,
  UpdateCallQualityMutationVariables>(UPDATE_CALL_QUALITY_MUTATION, options);
  const addDialerCallWrapped = useCallback(async (variables: UpdateCallQualityMutationVariables) => {
    emitAnalyticsEvent('Updating Quality Dialer Call', { dialerCallInput: variables.input, customerId: variables.customerId });
    const response = await updateCallQuality(variables);
    return response;
  }, [updateCallQuality]);

  return [addDialerCallWrapped, result];
}
