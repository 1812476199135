import { ComponentWithThemeProps } from '../../../../../themes';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const PropertyAndCasualtyLeadPanelCard = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  margin: 10px;
  padding: 15px;
  background-color: white;
  border: 1px solid ${theme.color.grayLight};
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
`);
