import { gql } from 'apollo-boost';

export const ACTIVITY_FRAGMENT = gql`
  fragment ActivityFragment on Activity {
    timestamp
    activityFeed {
      customer {
        id
        person {
          id
          name {
            first
            last
          }
        }
      }
    }
    createdBy {
      name {
        first
        last
      }
    }
    lead {
      id
    }
    hash
    ... on Note {
      body
    }
    
    ... on Requirement {
      body
    }

    ... on Task {
      body
    }

    ... on EmailMessage {
      body
      subject
    }

    ... on TextMessage {
      body
    }

    ... on FollowUp {
      toEmail
    }
  }
`;

export const LEAN_ACTIVITY_FRAGMENT = gql`
  fragment LeanActivityFragment on Activity {
    timestamp
    type
    createdBy {
      name {
        fullFriendlyName
      }
    }
    hash
    ... on Note {
      body
    }
    ... on Requirement {
      requirementType
      body
      completedAt
      isCompleted
    }
    ... on Task {
      assignedTo {
        name {
          fullFriendlyName
        }
      }
      body
      completedAt
      isCompleted
    }
    ... on EmailMessage {
      body
      subject
    }
    ... on TextMessage {
      body
    }
    ... on FollowUp {
      appointmentTime
    }
  }
`;
