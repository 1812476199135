import * as React from 'react';
import ReactDOM from 'react-dom';
import { Transition } from 'react-transition-group';
import { ITransitionInPortalProps } from './TransitionInPortal.interfaces';

export class TransitionInPortal extends React.Component<ITransitionInPortalProps> {
  public state = {
    isClientSide: false,
  };

  public componentDidMount() {
    this.setState({
      isClientSide: true,
    });
  }

  public render() {
    return this.state.isClientSide && ReactDOM.createPortal(
      <Transition
        appear
        in={this.props.opened}
        timeout={this.props.timeout || 4000}
        addEndListener={(done) => this.switchState = done}
        mountOnEnter
        unmountOnExit
      >
        {(transitionState) => this.props.render(transitionState, this.stateSwitcher)}
      </Transition>,
      document.body,
    );
  }

  private switchState: () => void = () => null;
  private stateSwitcher = () => this.switchState();
}
