import React from 'react';
import { FullHeight, InitialContactLayout } from '../../../layouts';
import {
  Icon, Link, PrimaryButton,
} from '../../../common';
import {
  DialerCallRouter, LeadRouter, useCustomerId, useLeadIdRoute,
} from '../../../routers';
import { LeftPanelContainer } from '../PreSalesHome/PreSalesHome.elements';
import { ActivityFeed, InitialContactCustomerSummary } from '../../../panels';
import { LeadInfo } from '../../../panels/Lead/common';
import {
  ElectronicSalesQualifierHomeCard,
  ElectronicSalesQualifierHomeContainer,
  HomeLinkContainer, HomeLinkTitle,
} from './ElectronicSalesQualifierHome.elements';
import { useAddLeadToDoNotCall, useCustomerQuery, useLeadQuery } from '../../../../graphql';
import { Alert } from '@material-ui/lab';
import { QuestionCell, QuestionValueEditable } from '../../../common/FormGenerator/FormGeneratorCompact';
import { QuestionType } from '@hiq/crm.types';
import { ClickToDialButton } from '../../../common/ClickToDialButton';
import { EsqFollowUp } from './ESQFollowUp/ESQFollowUp';
import { useToggle } from '../../../common/CheckMyHealthRecord/CheckMyHealthRecordDataGrid/useToggle';

export const ElectronicSalesQualifierHome = () => {
  const customerId = useCustomerId();
  const { data: customerData } = useCustomerQuery(customerId);
  const [leadId] = useLeadIdRoute(customerData?.customer?.leads?.[0]?.id);

  const [addLeadToDnc, addToDncData] = useAddLeadToDoNotCall({ refetchQueries: ['getCustomer'] });
  const [open, toggleFollowUp] = useToggle();

  const addToDnc = React.useCallback(() => {
    addLeadToDnc({ input: { phoneNumber: customerData?.customer?.person?.phone?.number, leadId } });
  }, [customerData]);

  const { data: lead } = useLeadQuery(leadId);
  const policyNumber = lead?.policyNumber;

  return (
    <LeadRouter>
      <DialerCallRouter>
        <FullHeight>
          <InitialContactLayout
            sideContent={(
              <LeftPanelContainer>
                <HomeLinkContainer>
                  <Link to="/">
                    <Icon type="hiq-torch" />
                    <HomeLinkTitle>
                      Home
                    </HomeLinkTitle>
                  </Link>
                </HomeLinkContainer>
                <InitialContactCustomerSummary locked />
                {customerData?.customer?.person?.phone?.number
                && <ClickToDialButton number={customerData?.customer?.person?.phone?.number as string} />}
              </LeftPanelContainer>
        )}
            mainContent={(
              <ElectronicSalesQualifierHomeContainer>
                <ElectronicSalesQualifierHomeCard width="100%">
                  <LeadInfo leadId={leadId} hideFollowUp />
                  <QuestionCell
                    label="Customer SF ID"
                    input={<QuestionValueEditable value={customerData?.customer?.customerReferenceId} type={QuestionType.Text} locked />}
                  />
                  <QuestionCell
                    label="Assigned Policy Number"
                    input={<QuestionValueEditable value={policyNumber} type={QuestionType.Text} locked />}
                  />
                  <PrimaryButton
                    fullWidth
                    onClick={toggleFollowUp}
                  >
                    Schedule Followup
                  </PrimaryButton>
                  <PrimaryButton
                    fullWidth
                    onClick={addToDnc}
                    disabled={addToDncData?.data?.addLeadToDnc?.success || addToDncData?.loading}
                  >
                    Add to DNC
                  </PrimaryButton>
                  {addToDncData?.data && (
                  <Alert severity={addToDncData?.data?.addLeadToDnc?.success ? 'success' : 'error'}>
                    {addToDncData?.data?.addLeadToDnc?.success
                      ? 'Successfully added to DNC' : addToDncData?.data?.addLeadToDnc?.message ?? 'Could not add to DNC list'}
                  </Alert>
)}
                  <EsqFollowUp open={open} onClose={toggleFollowUp} />
                </ElectronicSalesQualifierHomeCard>
                <ElectronicSalesQualifierHomeCard width="600px">
                  {customerData?.customer && <ActivityFeed />}
                </ElectronicSalesQualifierHomeCard>
              </ElectronicSalesQualifierHomeContainer>
        )}
          />

        </FullHeight>
      </DialerCallRouter>
    </LeadRouter>

  );
};
