import * as React from 'react';
import { FormGenerator, FormGeneratorStyle } from '../../../common/FormGenerator';
import { CustomerDemographicContainer } from './CustomerDemographic.elements';
import { CustomerDemographicProps } from './CustomerDemographic.interfaces';

export const CustomerDemographic: React.FunctionComponent<CustomerDemographicProps> = ({
  leadId,
  locked,
  isBionic,
}) => {
  const referenceId = isBionic ? 'customer-summary-panel-bionic' : 'customer-summary-panel';
  return (
    <CustomerDemographicContainer data-qa="customer-demographic-panel">
      <FormGenerator
        lockable
        leadId={leadId}
        referenceId={referenceId}
        style={locked ? FormGeneratorStyle.ReadOnly : FormGeneratorStyle.Standard}
      />
    </CustomerDemographicContainer>
  );
};
