import { gql } from 'apollo-boost';
import { useMutationCustom } from '../useMutationCustom';
import {
  ToggleCriticalDoctorMutationResponse,
  ToggleCriticalDoctorMutationVariables,
} from './useToggleCriticalDoctorMutation.interfaces';
import { PRECISION_MEDICARE_CRITICAL_DOCTOR_FRAGMENT } from '../../fragments';
import { CRITICAL_DOCTORS_QUERY, CriticalDoctorsQueryData, CriticalDoctorsVariables } from '../../queries';

const TOGGLE_CRITICAL_DOCTOR_MUTATION = gql`
  mutation ToggleCriticalDoctor($input: PrecisionMedicareToggleCriticalDoctorInput!) {
    toggleCriticalDoctor(input: $input) {
      leadId
      criticalDoctors {
        ...PrecisionMedicareCriticalDoctorFragment
      }
    }
  }
  ${PRECISION_MEDICARE_CRITICAL_DOCTOR_FRAGMENT}
`;

export function useToggleCriticalDoctor() {
  return useMutationCustom<ToggleCriticalDoctorMutationResponse, ToggleCriticalDoctorMutationVariables>(
    TOGGLE_CRITICAL_DOCTOR_MUTATION, {
      update: (cache, result) => {
        cache.writeQuery<CriticalDoctorsQueryData, CriticalDoctorsVariables>({
          query: CRITICAL_DOCTORS_QUERY,
          variables: { leadId: result.data.toggleCriticalDoctor.leadId },
          data: {
            criticalDoctors: result.data.toggleCriticalDoctor.criticalDoctors,
          },
        });
      },
    },
  );
}
