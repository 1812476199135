import React, { useCallback, useEffect, useState } from 'react';
import { identityTransformerFactory, useInputOnChange } from '../../inputs';
import { useScheduleSDRFollowUpMutation } from '../../../../graphql';
import { ErrorMessage } from './SDRFollowUp.elements';
import { useActiveLeadId } from '../../../routers';
import { useFormAnswers } from '../../../../hooks';
import { TextField } from '@material-ui/core';
import { emitAnalyticsEvent } from '../../../../events/emitAnalyticsEvent';

export const SDRFollowUp: React.FunctionComponent = React.memo(() => {
  const leadId = useActiveLeadId();
  const [scheduleSDRFollowUp, { error }] = useScheduleSDRFollowUpMutation();
  const [answers, saveAnswers] = useFormAnswers();
  const nextFollowUpDate = answers['call.nextFollowUpDate'];
  const nextFollowUpTimezone = answers['call.nextFollowUpTimezone'];
  const [validationMessage, setValidationMessage] = useState('');

  const onDateChange = useCallback(
    (value) => {
      saveAnswers('call.nextFollowUpDate', value);
    },
    [saveAnswers],
  );

  useEffect(() => {
    if (nextFollowUpTimezone) {
      if (nextFollowUpDate) {
        const hours = new Date(nextFollowUpDate).getHours();
        if (hours >= 5 && hours < 19) {
          setValidationMessage('');
          scheduleSDRFollowUp({
            leadId,
            date: nextFollowUpDate,
            timezone: nextFollowUpTimezone,
          });
          emitAnalyticsEvent('Scheduled Follow Up', { leadId, date: nextFollowUpDate, timezone: nextFollowUpTimezone });
        } else {
          setValidationMessage('Pick a time between 5 AM and 7 PM');
        }
      }
    } else {
      setValidationMessage('Time zone is required');
    }
  }, [nextFollowUpTimezone, nextFollowUpDate]);

  const [formattedValue, handleChange] = useInputOnChange(nextFollowUpDate, onDateChange, identityTransformerFactory);
  return (
    <>
      <TextField
        value={formattedValue}
        onChange={handleChange}
        type="datetime-local"
        disabled={!leadId || !nextFollowUpTimezone}
      />
      {!!validationMessage && (
        <ErrorMessage>
          <p>{validationMessage}</p>
        </ErrorMessage>
      )}
      {error && (
        <ErrorMessage>
          <span>Error: </span>
          {error.message}
        </ErrorMessage>
      )}
    </>
  );
});
