import * as React from 'react';
import { useDashboardListQuery } from '../../../graphql';
import { MainPanelLayout, MainPanelLayoutTabItem } from '../../layouts';
import { HomeStyledIframe } from './AgentHome.elements';

enum AgentSections {
  AGENTS_HOME = 'Agents Home',
  LEAD_PIPELINE = 'Lead Pipeline',
  SOLD_CUSTOMER_PIPELINE = 'Sold Customer Pipeline',
  MEDICARE_SALES_DASHBOARD = 'Medicare Sales Dashboard',
  CALL_LOG = 'Call Log'
}

export const AgentHome: React.FunctionComponent = () => {
  const { data } = useDashboardListQuery();

  const [activeTab, setActiveTab] = React.useState('home');
  const tabs: MainPanelLayoutTabItem[] = React.useMemo(() => {
    const memoTabs: MainPanelLayoutTabItem[] = [
      {
        id: 'home',
        label: 'HOME',
        icon: null,
        content: (
          <HomeStyledIframe
            data-qa="agent-home-tab"
            src={data?.dashboardList?.find((dl) => dl.name === AgentSections.AGENTS_HOME)?.url}
          />
        ),
      },
      {
        id: 'appointments',
        label: 'APPOINTMENTS',
        icon: null,
        content: (
          <HomeStyledIframe
            data-qa="agent-appointments-tab"
            src={data?.dashboardList?.find((dl) => dl.name === AgentSections.LEAD_PIPELINE)?.url}
          />
        ),
      },
      {
        id: 'sold-customers',
        label: 'SOLD CUSTOMERS',
        icon: null,
        content: (
          <HomeStyledIframe
            data-qa="agent-sold-customers-tab"
            src={data?.dashboardList?.find((dl) => dl.name === AgentSections.SOLD_CUSTOMER_PIPELINE)?.url}
          />
        ),
      },
      {
        id: 'agent-stats',
        label: 'AGENT STATS',
        icon: null,
        content: (
          <HomeStyledIframe
            data-qa="agent-stats-tab"
            src={data?.dashboardList?.find((dl) => dl.name === AgentSections.MEDICARE_SALES_DASHBOARD)?.url}
          />
        ),
      },
      {
        id: 'call-log',
        label: 'CALL LOG',
        icon: null,
        content: (
          <HomeStyledIframe
            data-qa="agent-call-log-tab"
            src={data?.dashboardList?.find((dl) => dl.name === AgentSections.CALL_LOG)?.url}
          />
        ),
      },
    ];
    return memoTabs;
  }, [data?.dashboardList]);

  return (
    <MainPanelLayout
      tabs={tabs}
      activeId={activeTab}
      onTabChange={setActiveTab}
      tabColumnVerticalOverflow="hidden"
    />
  );
};
