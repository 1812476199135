import { gql } from 'apollo-boost';
import { MutationCustomReturn, useMutationCustom } from '../useMutationCustom';
import { ToggleICDMutationResponse, ToggleICDMutationVariables } from './useToggleICDMutation.interfaces';
import {
  SELECTED_ICDS_QUERY,
  SelectedICDsQueryData,
  SelectedICDsQueryVariables,
} from '../../queries';
import { PRECISION_MEDICARE_ICD_FRAGMENT } from '../../fragments';

const TOGGLE_ICD_MUTATION = gql`
  mutation ToggleICD($input: PrecisionMedicareToggleICDInput!) {
    toggleICD(input: $input) {
      icd {
        ...PrecisionMedicareICDFragment
      }
      removed
    }
  }
  ${PRECISION_MEDICARE_ICD_FRAGMENT}
`;

export function useToggleICDMutation(): MutationCustomReturn<
ToggleICDMutationResponse, ToggleICDMutationVariables> {
  return useMutationCustom<ToggleICDMutationResponse, ToggleICDMutationVariables>(TOGGLE_ICD_MUTATION, {
    update: (cache, mutationResult) => {
      const { icd, removed } = mutationResult.data.toggleICD;
      const cachedQuery: SelectedICDsQueryData = cache.readQuery({
        query: SELECTED_ICDS_QUERY,
        variables: {
          leadId: icd.leadId,
        },
      });
      const updatedData = removed
        ? cachedQuery.selectedICDs.filter((current) => current.id !== icd.id)
        : [...cachedQuery.selectedICDs, icd];
      cache.writeQuery<SelectedICDsQueryData, SelectedICDsQueryVariables>({
        query: SELECTED_ICDS_QUERY,
        variables: {
          leadId: icd.leadId,
        },
        data: {
          selectedICDs: updatedData,
        },
      });
    },
  });
}
