import React, { useEffect, useState } from 'react';
import { FeedbackMessage, SmsMessageInput } from './SendSms.elements';
import { SendSmsProps } from './SendSms.interfaces';
import { PrimaryButton, QueryLoader } from '../../common';
import { useSendSms } from '../../../graphql/mutations/useSendSmsMutation';
import { SidePanelContentLayout } from '../../layouts/SidePanelContentLayout';
import { MySms, Sms } from './Sms';
import {
  ContentContainer,
  ContentRowContainer,
  StyledTabs,
  StyledTabsContainer,
  TabRowContainer, TabTopLayoutContainer,
} from '../../layouts/TabTopLayout/TabTopLayout.elements';
import { noop } from '../../../util';
import { TabTopLayoutTab } from '../../layouts/TabTopLayout/TabTopLayoutTab';
import { MainPanelLayoutTabItem, TabTopLayoutTabItem } from '../../layouts';
import { Alert } from '@material-ui/lab';
import { useCustomerMaxContactRateReached } from '../../../graphql/queries/useCustomerMaxContactRateReached';

export const SendSms: React.FunctionComponent<SendSmsProps> = ({ leadId, userId }: SendSmsProps) => {
  const [message, setMessage] = useState('');
  const [sendSms, { loading, data, error }] = useSendSms({
    onCompleted: () => setMessage(''),
  });
  const [activeTab, setActiveTab] = React.useState('my-sms');

  const {
    exceededMaxContactRate: maxCustomerContactRateExceeded,
    loading: loadingCustomerMaxContactRate,
    refetch: refetchCustomerMaxContactRate,
    displayMaxContactRateWarning,
  } = useCustomerMaxContactRateReached();

  useEffect(() => {
    refetchCustomerMaxContactRate();
  }, []);

  const onSend = () => {
    sendSms({
      body: message,
      leadId,
      userId,
    });
  };
  const isSendButtonDisabled = !message || message.trim().length === 0;

  const tabs: MainPanelLayoutTabItem[] = [
    {
      id: 'my-sms',
      label: 'MY',
      icon: 'note',
      content: (
        <MySms />
      ),
    },
    {
      id: 'all-sms',
      label: 'ALL',
      icon: 'note',
      content: (
        <Sms />
      ),
    },
  ];

  function getActiveIndex(tabs: TabTopLayoutTabItem[], activeId: string): number {
    const index = tabs.map((tab) => tab.id).indexOf(activeId);
    return Math.max(index, 0);
  }

  return (
    <SidePanelContentLayout header="Send SMS">
      <QueryLoader loading={loading} error={error} componentName="SendSms">
        {
        displayMaxContactRateWarning && (
        <div style={{ padding: 5 }}>
          <Alert severity="error">Warning: Clients in this state can only be contacted via phone 3 times within a 24 hour period</Alert>
        </div>
        )
        }
        <SmsMessageInput
          disabled={maxCustomerContactRateExceeded || loadingCustomerMaxContactRate}
          onChange={(event) => setMessage(event.target.value)}
          value={message}
        />
        <PrimaryButton
          onClick={onSend}
          disabled={
          isSendButtonDisabled || loadingCustomerMaxContactRate || maxCustomerContactRateExceeded
        }
        >
          Send
        </PrimaryButton>
        {data?.sendSms?.message && <FeedbackMessage>{data.sendSms.message}</FeedbackMessage>}
      </QueryLoader>
      <TabTopLayoutContainer>
        <TabRowContainer>
          <StyledTabsContainer data-qa="top-layout-tabs">
            <StyledTabs
              value={getActiveIndex(tabs, activeTab)}
              onChange={noop}
              scrollButtons="auto"
              variant="scrollable"
            >
              {tabs.map(({ id, label }) => (
                <TabTopLayoutTab
                  key={id}
                  id={id}
                  label={label}
                  onClick={setActiveTab}
                />
              ))}
            </StyledTabs>
          </StyledTabsContainer>
        </TabRowContainer>
        <ContentRowContainer>
          {tabs.map(({ id, content }) => (
            <ContentContainer
              key={id}
              role="tabpanel"
              hidden={activeTab !== id}
            >
              {activeTab === id && content}
            </ContentContainer>
          ))}
        </ContentRowContainer>
      </TabTopLayoutContainer>
    </SidePanelContentLayout>
  );
};
