import * as React from 'react';
import { SpecialRateVerificationContainer } from './SpecialRateVerification.elements';
import { useCustomerQuery } from '../../../graphql/queries/useCustomerQuery';
import { QueryLoader } from '../QueryLoader';
import { Icon } from '../Icon';
import { QuestionFlow } from '../QuestionFlow';
import { useActiveLeadId } from '../../routers/LeadRouter/useActiveLeadId';

export const SpecialRateVerification: React.FunctionComponent = () => {
  const { data, loading, error } = useCustomerQuery();
  const leadId = useActiveLeadId();
  const quizPassed = data?.customer?.quizResult?.elite === true;
  return (
    <QueryLoader loading={loading} error={error} componentName="SpecialRateVerification">
      <SpecialRateVerificationContainer>
        { quizPassed && (
          <h3>
            <Icon type="hiq-torch" />
            {' '}
            Customer Passed HealthIQ Quiz
          </h3>
        ) }
        { !quizPassed && (
          <QuestionFlow leadId={leadId} referenceId="manual-tweak-verification" />
        ) }
      </SpecialRateVerificationContainer>
    </QueryLoader>
  );
};
