import React, { FC, ReactNode, useState } from 'react';
import { useDebounce } from '@healthiqeng/core.hooks.use-debounce';
import Autocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { AddressPlace } from '@healthiqeng/core.services.address.base';
import { usePlaceLookupQuery } from '../../../../graphql/queries';
import { AddressLookupInputOption } from './AddressLookupInputOption';
import { PlaceLookupInputProps } from './PlaceLookupInput.interfaces';

export const PlaceLookupInput: FC<PlaceLookupInputProps> = ({
  placeholder,
  label,
  size,
  disabled,
  fullWidth,
  variant,
  onBlur,
  onFocus,
  onChange,
  dataQa,
  sessionToken,
  inputRef,
}) => {
  const [value, setValue] = useState<AddressPlace | null>(null);
  const [fetchPredictions, { error: queryError, loading, data }] = usePlaceLookupQuery();

  const handleChange = (_event: any, selectedPlace: AddressPlace | null) => {
    setValue(selectedPlace);
    if (selectedPlace) {
      onChange(selectedPlace.placeId);
    }
  };

  const debounceFetchPredictions = useDebounce((input: string) => fetchPredictions({
    variables: { input, sessionToken },
  }), 600);

  const handleInputChange = (_event: any, newInputValue: string) => {
    if (newInputValue?.length > 2) {
      debounceFetchPredictions(newInputValue);
    }
  };

  const renderInput = (params: AutocompleteRenderInputParams): ReactNode => (
    <TextField
      placeholder={placeholder}
      label={label}
      size={size}
      disabled={disabled}
      fullWidth={fullWidth}
      variant={variant}
      onBlur={onBlur}
      onFocus={onFocus}
      {...params}
      inputProps={{
        ...params.inputProps,
        autoComplete: 'new-password',
      }}
      InputProps={inputRef // eslint-disable-line
        ? {
          ...params.InputProps,
          inputRef,
        }
        : params.InputProps}
    />
  );

  return (
    <>
      <Autocomplete
        filterSelectedOptions
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
        includeInputInList
        loading={loading}
        options={data?.searchPlace?.places || []}
        value={value}
        onChange={handleChange}
        onInputChange={handleInputChange}
        renderInput={renderInput}
        renderOption={(option) => <AddressLookupInputOption option={option} />}
        data-qa={dataQa}
      />

      {queryError && (<p>{queryError.message}</p>)}
    </>
  );
};
