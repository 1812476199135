import styled from '@emotion/styled';

export const LisText = styled.span`
    font-weight: 400;
    font-size: 16px;
`;

export const LisLink = styled(LisText)`
    text-decoration-line: underline;
    color: #0075CA;
    cursor: pointer;
`;

export const LisContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
`;
