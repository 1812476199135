import { JsonCompatible } from '@healthiqeng/core.types';
import { unflattenObject } from '@hiq/crm.core.util.unflatten-object';
import { FormAnswers } from '@hiq/crm.types';

export function getItemsArray(formId: string, flattenAnswers: FormAnswers) {
  const allKeys = Object.keys(flattenAnswers);
  const result: FormAnswers = {};
  let filled: boolean = false;

  for (const key of allKeys) {
    if (key.startsWith(formId) && !key.includes('__deleted')) {
      const shortenKey = key.replace(formId, '');
      result[shortenKey] = flattenAnswers[key];
      filled = true;
    }
  }

  if (!filled) return [];

  return unflattenObject(result) as JsonCompatible;
}
