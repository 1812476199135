import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';
import { GetSunfireCountiesFromZipQueryData, GetSunfireCountiesFromZipQueryVariables } from './useGetSunfireCountiesFromZipQuery.interface';

const GET_SUNFIRE_COUNTIES_FROM_ZIP_QUERY = gql`
  query GetSunfireCountiesFromZipQuery($zip: String!) {
    findSunfireCountiesByZip(zip: $zip) {
      success
      message
      counties  {
        code
        name
      }
    }
  }
`;

export function useGetSunfireCountiesFromZipQuery() {
  return useLazyQuery<GetSunfireCountiesFromZipQueryData, GetSunfireCountiesFromZipQueryVariables>(GET_SUNFIRE_COUNTIES_FROM_ZIP_QUERY);
}
