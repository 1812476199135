/* eslint react/require-default-props: 0 */

import React from 'react';
import { formatCurrencyTruncated } from '@healthiqeng/core.ui';
import {
  Column, CompareData, CompareDataBold, CompareRow,
} from '../TRMQuote.elements';
import { NoPlanPlaceHolder } from '../NoPlanPlaceHolder';

type CoinCopay = {
  coins?: number
  copay?: number
};

type CompareCoinCopayProps = {
  currentPlan: CoinCopay;
  currentComparisonPlan: CoinCopay;
  name: string;
};

export const CompareCoinCopay = ({ name, currentComparisonPlan, currentPlan }: CompareCoinCopayProps) => (
  <CompareRow>
    <CompareDataBold>
      {name}
    </CompareDataBold>
    <CompareData empty={currentPlan === undefined} highlight={currentPlan?.copay < currentComparisonPlan?.copay}>

      {currentPlan === undefined ? <NoPlanPlaceHolder />
        : (
          <Column>
            <CostRow value={currentPlan?.copay} label="Copay" />
            <CostRow value={currentPlan?.coins} label="Coinsurance" percentage />
          </Column>
        )}

    </CompareData>
    <CompareData highlight={currentPlan?.copay > currentComparisonPlan?.copay}>
      <Column>
        <CostRow value={currentComparisonPlan?.copay} label="Copay" />
        <CostRow value={currentComparisonPlan?.coins} label="Coinsurance" percentage />
      </Column>
    </CompareData>
  </CompareRow>
);

const CostRow = ({ value, label, percentage = false }: {value: number, label: string, percentage?: boolean}) => {
  if (value === null || value === undefined) return null;
  if (percentage) return <span>{`${value}% ${label}`}</span>;
  return <span>{`${label}: ${formatCurrency(value)}`}</span>;
};

function formatCurrency(currency: number) {
  const formatted = formatCurrencyTruncated(currency, false, false);
  if (!formatted) return 'N/A';
  return formatted;
}
