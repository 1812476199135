import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const FormGeneratorCompactButtonsContainer = styled('div')(css`
  display: flex;
  flex-direction: row;
  margin: 0 -5px;
`);

export const ButtonContainer = styled('div')(css`
  flex: 1;
  padding: 5px;
`);
