import * as React from 'react';
import { LeadContextContainerProps } from './LeadContextContainer.interfaces';
import { LeadIdContext } from '../../../contexts/LeadIdContext';

export const LeadContextContainer: React.FunctionComponent<LeadContextContainerProps> = ({
  leadId,
  content,
}) => (
  <LeadIdContext.Provider value={leadId}>
    { content }
  </LeadIdContext.Provider>
);
