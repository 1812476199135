import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../../../themes';

export const AutomatedUnderwritingResultContainer = styled('div')(css``);

export const AutomatedUnderwritingError = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  color: ${theme.color.error};
`);

export const ApprovalMessageDeclined = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  font-weight: bold;
  color: ${theme.color.error};
`);

export const ApprovalMessageAsApplied = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  font-weight: bold;
  color: ${theme.color.accent};
`);

export const ApprovalMessageOther = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  font-weight: bold;
  color: ${theme.color.warning};
`);

export const QuoteContainer = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  margin: 10px 0;
  padding 10px;
  border-radius: 2px;
  border: 1px solid ${theme.color.grayLight};
`);
