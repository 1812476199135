import * as React from 'react';
import { IContentPlaceholderProps } from './ContentPlaceholder.interfaces';

export function ContentPlaceholder(props: IContentPlaceholderProps) {
  return (
    <img
      src={props.transparent ? '' : require('./assets/placeholder.jpg')}
      style={{
        width: props.width || '100%',
        height: props.height || '200px',
      }}
    />
  );
}
