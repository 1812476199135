import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import {
  SelectedNonICDsQueryData,
  SelectedNonICDsQueryVariables,
} from './useSelectedNonICDsQuery.interfaces';
import { PRECISION_MEDICARE_NON_ICD_FRAGMENT } from '../../fragments';

export const SELECTED_NON_ICDS_QUERY = gql`
  query SelectedNonICDs($leadId: LeadId!) {
    selectedNonICDs(leadId: $leadId) {
      ...PrecisionMedicareNonICDFragment
    }
  }
  ${PRECISION_MEDICARE_NON_ICD_FRAGMENT}
`;

export function useSelectedNonICDsQuery(leadId: string) {
  return useQuery<SelectedNonICDsQueryData, SelectedNonICDsQueryVariables>(SELECTED_NON_ICDS_QUERY, {
    variables: { leadId },
  });
}
