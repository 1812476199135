import * as React from 'react';
import { BoxContainer, IBoxContainerProps } from './Box.elements';
import { IBoxProps } from './Box.interfaces';

export class Box extends React.Component<IBoxProps> {
  public static defaultProps = {
    boxAlign: 'center',
    boxTop: 0,
    boxBottom: 0,
    boxLeft: 0,
    boxRight: 0,
  };

  public render() {
    const boxContainerProps: IBoxContainerProps = {
      boxAlign: Array.isArray(this.props.boxAlign)
        ? this.props.boxAlign
        : [this.props.boxAlign, this.props.boxAlign, this.props.boxAlign, this.props.boxAlign],
      boxTop: Array.isArray(this.props.boxTop)
        ? this.props.boxTop
        : [this.props.boxTop, this.props.boxTop, this.props.boxTop, this.props.boxTop],
      boxBottom: Array.isArray(this.props.boxBottom)
        ? this.props.boxBottom
        : [this.props.boxBottom, this.props.boxBottom, this.props.boxBottom, this.props.boxBottom],
      boxLeft: Array.isArray(this.props.boxLeft)
        ? this.props.boxLeft
        : [this.props.boxLeft, this.props.boxLeft, this.props.boxLeft, this.props.boxLeft],
      boxRight: Array.isArray(this.props.boxRight)
        ? this.props.boxRight
        : [this.props.boxRight, this.props.boxRight, this.props.boxRight, this.props.boxRight],
    };

    return (
      <BoxContainer {...boxContainerProps}>
        {this.props.children}
      </BoxContainer>
    );
  }
}
