import * as React from 'react';
import { QuestionProps } from './Question.interfaces';
import { useQuestionQuery } from '../../../../../graphql/queries/useQuestionQuery';
import { useConditionalRendering } from '../../useConditionalRendering';
import { QueryLoader } from '../../../QueryLoader';
import { InputFactory } from '../../../inputs/InputFactory';
import { useTemplateReplacer } from '../../../QuestionFlow/QuestionFlowRenderer/useTemplateReplacer';
import { isPresent } from '@healthiqeng/core.util.is-present';
import { isTextInputQuestionType } from '../../../../../util';

export const Question: React.FunctionComponent<QuestionProps> = ({
  leadId,
  id,
  answers,
  onChange,
  visibleCondition,
}) => {
  const { error, loading, data } = useQuestionQuery(leadId, id);
  const questionIsVisible = useConditionalRendering({
    questionId: id,
    parentQuestionId: null,
    answers,
    visibleCondition,
  });

  if (!questionIsVisible) {
    return <></>;
  }

  const {
    id: dataId, helpText, options, dependencies, type, ...rest
  } = data ?? {};
  const value = answers[dataId];
  const optionsMemo = React.useMemo(() => options, [JSON.stringify(options)]);
  const dependenciesMemo = React.useMemo(() => dependencies, [JSON.stringify(dependencies)]);
  const handleChange = React.useCallback(
    (val) => onChange(dataId, val, isTextInputQuestionType(data.type)),
    [dataId, data.type],
  );
  return (
    <QueryLoader loading={loading} error={error} componentName="Question">
      <InputFactory
        {...rest}
        type={type}
        options={optionsMemo}
        dependencies={dependenciesMemo}
        value={value}
        onChange={handleChange}
        size="small"
        variant="outlined"
        fullWidth
        validationMessage={isPresent(helpText) ? getValidationMessage(helpText) : null}
        dataQa={`question-${dataId}`}
        id={dataId}
      />
    </QueryLoader>
  );
};

function getValidationMessage(helpText: string) {
  return useTemplateReplacer(helpText).replace(/__/g, '');
}
