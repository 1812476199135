import * as React from 'react';
import {
  ProductTypePropertyAndCasualty,
} from '@hiq/crm.types';
import { useActiveLeadId, useLeadSectionRoute } from '../../../../routers';
import {
  useLeadQuery,
  ConvertSuccessEvent,
} from '../../../../../graphql';
import { useEventListener } from '../../../../../events/useEventListener';
import { useQuestionFlowAnchorQuery } from '../../useQuestionFlowAnchorQuery';
import {
  useSectionNavigation,
  LogPanelChange,
  QuestionFlow,
  IconProps,
} from '../../../../common';
import { MainPanelLayout, MainPanelLayoutTabItem } from '../../../../layouts';
import { PropertyAndCasualtyLeadPanelCard } from '../elements';
import {
  PropertyAndCasualtyQuotePanelFactory,
  PropertyAndCasualtyConversionPanel,
  PropertyAndCasualtyProducerStatementPanel,
} from '../panels';
import { LeadPanelContainer } from '../../elements';
import { LeadInfo } from '../../common/LeadInfo';

export const PropertyAndCasualtyPreConversionLead: React.FunctionComponent = () => {
  const leadId = useActiveLeadId();
  const { data: leadData, refetch } = useLeadQuery();

  useEventListener(ConvertSuccessEvent, () => refetch());

  const productType: ProductTypePropertyAndCasualty = leadData?.productType as ProductTypePropertyAndCasualty;

  const interviewAnchors = useQuestionFlowAnchorQuery(leadId, 'interview');
  const [activeTab, setActiveTab] = useLeadSectionRoute('interview');

  const moveToQuoting = () => setActiveTab('quoting');
  const interviewAnchorsList = interviewAnchors.map(({ value }) => value);
  const [currentSection, moveBackwards, moveForward] = useSectionNavigation(interviewAnchorsList, moveToQuoting);

  const tabs: MainPanelLayoutTabItem[] = React.useMemo(() => {
    const memoTabs: MainPanelLayoutTabItem[] = [
      {
        id: 'lead-info',
        label: 'Lead Info',
        icon: 'person' as IconProps['type'],
        content: (
          <PropertyAndCasualtyLeadPanelCard>
            <LeadInfo leadId={leadId} />
          </PropertyAndCasualtyLeadPanelCard>
        ),
      },
      {
        id: 'interview',
        label: 'Interview',
        icon: 'assignment' as IconProps['type'],
        anchors: interviewAnchors,
        content: (
          <PropertyAndCasualtyLeadPanelCard>
            <LogPanelChange panel="interview" />
            <QuestionFlow
              leadId={leadId}
              referenceId="interview"
              root
              split
              activeSectionIndex={currentSection}
              onNext={moveForward}
              onPrevious={moveBackwards}
            />
          </PropertyAndCasualtyLeadPanelCard>
        ),
      },
      {
        id: 'quoting',
        label: 'Quote',
        icon: 'local-offer' as IconProps['type'],
        content: (
          <LeadPanelContainer>
            <LogPanelChange panel="quoting" />
            <PropertyAndCasualtyQuotePanelFactory
              productType={productType}
            />
          </LeadPanelContainer>
        ),
      },
      {
        id: 'producer-statement',
        label: 'Producer Statement',
        icon: 'person' as IconProps['type'],
        content: (
          <PropertyAndCasualtyLeadPanelCard>
            <LogPanelChange panel="producer-statement" />
            <PropertyAndCasualtyProducerStatementPanel productType={productType} />
          </PropertyAndCasualtyLeadPanelCard>
        ),
      },
      {
        id: 'conversion',
        label: 'Conversion',
        icon: 'done' as IconProps['type'],
        content: (
          <>
            <LogPanelChange panel="conversion" />
            <PropertyAndCasualtyConversionPanel />
          </>
        ),
      },
    ];
    return memoTabs;
  }, [leadId, productType, currentSection, moveForward, moveBackwards]);

  return (
    <MainPanelLayout
      tabs={tabs}
      activeId={activeTab}
      onTabChange={setActiveTab}
    />
  );
};
