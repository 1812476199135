import * as React from 'react';
import { WarningBlockViewProps } from './WarningBlockView.interfaces';
import {
  ScriptBlockColorDiv,
  ScriptBlockContainer,
  ScriptBlockContent,
  ScriptBlockIcon,
  ScriptBlockIconContainer,
  ScriptBlockText,
} from '../../elements';
import { ThemeLight } from '../../../../../themes';
import { ScriptBlockConfirm } from '../../common/ScriptBlockConfirm';

export const WarningBlockView: React.FunctionComponent<WarningBlockViewProps> = ({
  children,
  confirmationRequired,
  confirmText,
  value,
  onChange,
}) => (
  <ScriptBlockContainer>
    <ScriptBlockColorDiv color={ThemeLight.color.error} />
    <ScriptBlockContent>
      <ScriptBlockIconContainer>
        <ScriptBlockIcon src={require('../../assets/icn-more-info-circle-red.svg')} alt="warning info icon" />
      </ScriptBlockIconContainer>
      <ScriptBlockText>
        { children }
        { confirmationRequired && (
          <ScriptBlockConfirm
            isConfirmed={value}
            onChange={() => onChange(!value)}
            confirmText={confirmText}
          />
        )}
      </ScriptBlockText>
    </ScriptBlockContent>
  </ScriptBlockContainer>
);
