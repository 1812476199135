import * as React from 'react';
import {
  PressLogosImage,
  PressLogosImageWrapper,
  PressLogosTitle,
  PressLogosWrapper,
} from './PressLogos.elements';

export function PressLogos() {
  return (
    <PressLogosWrapper>
      <PressLogosTitle>As seen in</PressLogosTitle>
      <PressLogosImageWrapper>
        <PressLogosImage />
      </PressLogosImageWrapper>
    </PressLogosWrapper>
  );
}
