import React from 'react';
import { Column, Container } from '../TRMQuote.elements';
import { SunfirePlanType } from '../../SunfirePlanType';
import { NoPlansErrorProps } from '../TRMQuote.interfaces';

const MedicareType = [
  {
    label: 'Medicare Advantage with Part D',
    value: 'MAPD',
  },
  {
    label: 'Medicare Advantage only',
    value: 'MA',
  },
  {
    label: 'Special Needs plan',
    value: 'SN',
  },
];

export const NoPlansError = ({ planType }: NoPlansErrorProps) => {
  const medicareTypeLabel = React.useMemo(() => MedicareType.find((type) => type.value === planType)?.label, [planType, MedicareType]);
  return (
    <Container>
      <Column>
        <Column justifyItems="center">
          <span>
            Sorry there are no
            {' '}
            {medicareTypeLabel}
            {' '}
            plans available in this county
          </span>
          <SunfirePlanType />
        </Column>
      </Column>
    </Container>
  );
};
