import {
  CellProps,
  Column,
  HeaderGroup,
  Renderer,
  Row,
  UseResizeColumnsColumnProps,
  UseRowSelectRowProps,
  UseRowSelectState,
  UseSortByColumnOptions,
  UseSortByColumnProps,
  UseTableInstanceProps,
} from 'react-table';
import { AlertProps } from '@material-ui/lab/Alert/Alert';

export interface ResizerProps {
  isResizing: boolean;
}

export enum DatagridDataType {
  Text = 'Text',
  Number = 'Number',
  Date = 'Date',
  Phone = 'Phone',
}

export interface DatagridColumnDefinition<D extends Object> {
  header: string;
  accessor: keyof D;
  type?: DatagridDataType;
  width?: number;
  minWidth?: number;
  maxWidth?: number;
  copyable?: boolean;
}

export interface DatagridCustomColumn<D extends Object> {
  id: string;
  header?: string;
  renderer: Renderer<CellProps<D>>;
  width?: number;
}

export type DatagridProps<D extends Object> = {
  columns?: DatagridColumnDefinition<D>[];
  data: D[];
  width?: number;
  minWidth?: number;
  maxWidth?: number;
  enableSelection?: boolean;
  addStubSelectionColumn?: boolean;
  selectedRowIds?: string[];
  customColumns?: DatagridCustomColumn<D>[];
  postDatagridContent?: React.ComponentType<UseTableInstanceProps<D> & UseRowSelectState<D>>;
  onSelectionChange?: (data: D) => void;
  selectionLabel?: string;
  getRowId?: (data: D) => string;
  fullWidth?: boolean;
  selectionMultiselect?: boolean;
  selectedColor?: string
  selectedBackground?: string
  selectedLabelColor?: string;
  alwaysIncluded?: boolean
  disableNewSelections?: boolean
};

export interface DatagridFeedbackMessage {
  severity: AlertProps['severity'];
  message: string;
}

export type ReactTableColumn<D extends Object> = Column<D> & UseSortByColumnOptions<D>;

export type ReactTableHeader<D extends Object> = HeaderGroup<D> & UseResizeColumnsColumnProps<D>
& UseSortByColumnProps<D>;

export type ReactTableRow<D extends Object> = Row<D> & UseRowSelectRowProps<D>;
