import { EventBusToken } from '@healthiqeng/core.infrastructure';
import * as React from 'react';
import { IComponentWithDiProps, withDi } from '../DiReactContext';
import { KeyDownEvent, KeyUpEvent } from './events';
import { IKeyboardListenerProps } from './KeyboardListener.interfaces';
import { keysMap } from './keys/keysMap';

export const KeyboardListener = withDi(
  class extends React.Component<IKeyboardListenerProps & IComponentWithDiProps> {
    private eventBus = this.props.di.get(EventBusToken);

    public render() {
      return React.cloneElement(this.props.children, {
        onKeyDown: this.keyDownHandler,
        onKeyUp: this.keyUpHandler,
        tabIndex: 0,
        style: { outline: 'none' },
      });
    }

    private keyDownHandler = (e: KeyboardEvent) => {
      if (e.defaultPrevented) {
        return;
      }
      if (keysMap[e.key]) {
        this.eventBus.emit(new KeyDownEvent(keysMap[e.key]));
      }
    };

    private keyUpHandler = (e: KeyboardEvent) => {
      if (e.defaultPrevented) {
        return;
      }
      if (keysMap[e.key]) {
        this.eventBus.emit(new KeyUpEvent(keysMap[e.key]));
      }
    };
  },
);
