import { JsonCompatible } from '@healthiqeng/core.types';
import { QuestionType, UserProfile } from '@hiq/crm.types';
import React, { useCallback } from 'react';
import { RedText } from './CustomerEmailWithWarning.elements';
import { QuestionView } from '../FormGenerator/FormGeneratorQuestionFlow/Question/QuestionView';
import { useCurrentUserProfile, useFormAnswers } from '../../../hooks';

export const CustomerEmailWithWarning = () => {
  const [answers, saveFormAnswers] = useFormAnswers();
  const userProfile = useCurrentUserProfile();
  const onChange = useCallback((value: JsonCompatible) => {
    saveFormAnswers('customer.person.email', value as string, true);
  }, [saveFormAnswers]);
  return (
    <>
      <QuestionView
        id="customer.person.email"
        questionText="Email"
        label="Email"
        type={QuestionType.Email}
        value={answers['customer.person.email']}
        onChange={onChange}
      />
      {[UserProfile.PrecisionMedicareLiteAgent, UserProfile.PrecisionMedicareHeavyAgent].includes(userProfile) && (
        <RedText>Do NOT put in a dummy email address. Email is optional and not required for the health record pull</RedText>
      )}
    </>
  );
};
