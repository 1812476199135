import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { gql, ApolloError } from 'apollo-boost';
import {
  FORM_FRAGMENT, QUESTION_FLOW_FRAGMENT, QUESTION_FRAGMENT,
} from '../../fragments';
import {
  IndexedRecords,
  QuestionFlowQueryData,
  QuestionFlowQueryParsedData,
  QuestionFlowQueryVariables,
  RecordWithReference,
  UseQuestionFlowQueryOptions,
  QuestionFlowQueryDataStructure,
} from './useQuestionFlowQuery.interfaces';
import { buildRecursiveStructure } from '../useFormQuery';
import { VariationChangeEvent, useEventListener } from '../../../events';
import { logger } from '../../../services';
import { getQuestionFlowDataId } from '../../../util/dataIds';

export const QUESTION_FLOW_QUERY = gql`
  query getQuestionFlow($leadId: ID!, $referenceId: String!, $shallow: Boolean, $callUrlPath: String) {
    questionFlow(leadId: $leadId, referenceId: $referenceId, shallow: $shallow, callUrlPath: $callUrlPath) {
      questionFlow {
        ...QuestionFlowFragment
      }
      questionFlows {
        ...QuestionFlowFragment
      }
      forms {
        ...FormFragment
      }
      questions {
        ...QuestionFragment
      }
    }
  }
  ${QUESTION_FLOW_FRAGMENT}
  ${FORM_FRAGMENT}
  ${QUESTION_FRAGMENT}
`;

export function useQuestionFlowQuery(leadId: string, referenceId: string, options: UseQuestionFlowQueryOptions = {}) {
  const dataId = getQuestionFlowDataId({ leadId, referenceId });
  const apolloClient = useApolloClient();
  const questionFlow = apolloClient.readFragment({
    id: dataId,
    fragment: QUESTION_FLOW_FRAGMENT,
    fragmentName: 'QuestionFlowFragment',
  });
  const {
    loading, error, data, refetch,
  } = useQuery<QuestionFlowQueryData, QuestionFlowQueryVariables>(QUESTION_FLOW_QUERY, {
    variables: {
      leadId,
      referenceId,
      shallow: !!options?.shallow,
      allowExternal: !!options?.allowExternal,
      callUrlPath: options?.callUrlPath,
    },
    skip: !!questionFlow,
  });

  useEventListener(VariationChangeEvent, () => {
    refetch().catch((err) => logger.crit(err));
  });

  if (questionFlow && (loading || error || !data?.questionFlow)) {
    return {
      loading: false,
      error: null as ApolloError,
      data: {
        questionFlow,
        questionFlows: {
          [referenceId]: questionFlow,
        },
        forms: {},
        questions: {},
      },
    };
  }

  return {
    loading,
    error,
    data: data && parseData(data?.questionFlow),
  };
}

export function parseData(data: QuestionFlowQueryDataStructure): QuestionFlowQueryParsedData {
  return {
    questionFlow: data?.questionFlow,
    questionFlows: indexByReferenceId(data?.questionFlows),
    forms: indexByReferenceId(data?.forms.map(buildRecursiveStructure)),
    questions: indexById(data?.questions),
  };
}

function indexByReferenceId<T extends RecordWithReference>(records: T[]): IndexedRecords<T> {
  if (!records || !(Array.isArray(records))) {
    return null;
  }

  return records.reduce((reduction, current) => ({
    ...reduction,
    [current.referenceId]: current,
  }), {});
}

function indexById<T extends { id: string; }>(records: T[]): IndexedRecords<T> {
  if (!records || !(Array.isArray(records))) {
    return null;
  }

  return records.reduce((reduction, current) => ({
    ...reduction,
    [current.id]: current,
  }), {});
}
