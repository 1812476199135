import * as React from 'react';
import { isPresent } from '@healthiqeng/core.util.is-present';
import { FormItemTypeFormProps } from './FormItemTypeForm.interfaces';
import {
  FormItemTypeFormContainer,
} from './FormItemTypeForm.elements';
import { Question } from '../../Question';
import { useConditionalRendering } from '../../../useConditionalRendering';

export const FormItemTypeForm: React.FunctionComponent<FormItemTypeFormProps> = ({
  questionId,
  parentQuestionId,
  leadId,
  tags,
  helpText,
  visibleCondition,
  childrenVisibleCondition,
  children,
  FormItemFactory,
  depth,
  answers,
  onChange,
  locked,
  FormGeneratorQuestionFlow,
  arrayFormIndex,
  formId,
  overrideQuestionText,
}) => {
  // The key in "answers" of the question value. For non-array forms, this is the same
  // as the questionId. For array forms, we splice in the form index,
  // e.g. lead.someArrayForm.0.someQuestionId
  const answersKey = isPresent(arrayFormIndex)
    ? spliceArrayFormIndex(questionId, formId, arrayFormIndex)
    : questionId;
  const formItemVisible = useConditionalRendering({
    questionId: answersKey,
    parentQuestionId,
    answers,
    visibleCondition,
  });

  const childrenVisible = useConditionalRendering({
    questionId: answersKey,
    parentQuestionId,
    answers,
    visibleCondition: childrenVisibleCondition,
  });

  if (!formItemVisible) {
    return <></>;
  }

  const value = answers[answersKey ?? questionId];
  return (
    <FormItemTypeFormContainer depth={depth ?? 0}>
      <Question
        referenceId={questionId}
        answersKey={answersKey}
        onChange={onChange}
        leadId={leadId}
        tags={tags}
        helpText={helpText}
        locked={locked}
        arrayFormIndex={arrayFormIndex}
        value={value}
        overrideQuestionText={overrideQuestionText}
      />
      { childrenVisible && children.map((child) => (
        <FormItemFactory
          key={child.questionId}
          leadId={leadId}
          depth={depth + 1}
          answers={answers}
          onChange={onChange}
          parentQuestionId={questionId}
          FormGeneratorQuestionFlow={FormGeneratorQuestionFlow}
          arrayFormIndex={arrayFormIndex}
          {...child}
          formId={child.formId ?? formId}
        />
      )) }
    </FormItemTypeFormContainer>
  );
};

function spliceArrayFormIndex(questionId: string, formId: string, arrayFormIndex: number): string {
  // Invariant: questionId is prefixed by formId
  const strippedQuestionId = questionId.replace(`${formId}.`, '');
  return `${formId}.${arrayFormIndex}.${strippedQuestionId}`;
}
