import * as React from 'react';
import { Router } from '@reach/router';
import { LeadContextContainer } from './LeadContextContainer';

export const LeadRouter: React.FunctionComponent = ({
  children,
}) => {
  const style = React.useMemo(() => ({ height: '100%' }), []);
  return (
    <Router style={style}>
      <LeadContextContainer path="/lead/:leadId/*" content={children} />
    </Router>
  );
};
