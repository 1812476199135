import * as React from 'react';
import { eventBus, graphqlClient, internalAuthService } from '../../../services';
import { MuiThemeLight, ThemeLight } from '../../../themes';
import {
  AuthenticatedPage, DialerListener, LicenseWarning, SaveAnswersListener,
} from '../../common';
import { Home } from './Home';
import { CustomerRouter } from '../../routers/CustomerRouter';
import { PageName } from '../../common/Page/PageLoadDetector';
import { InitialContactReadOnlyView } from '../../panels';
import { DialerTransferProvider } from '../../contexts';
import { DialerServiceProvider } from '@healthiqeng/core.hooks.use-dialer-service';
import { config } from '../../../config';
import { AppointmentNotification } from '../../common/AppointmentNotification/AppointmentNotification';

export const CustomerHome: React.FunctionComponent = () => (
  <AuthenticatedPage
    title={PageName.CustomerHome}
    theme={ThemeLight}
    muiTheme={MuiThemeLight}
    graphqlClient={graphqlClient}
    internalAuthService={internalAuthService}
  >
    <DialerServiceProvider useMock={config.USE_MOCK.DIALER_SERVICE} eventBus={eventBus}>
      <DialerTransferProvider>
        <CustomerRouter initialContactContent={InitialContactReadOnlyView}>
          <>
            <LicenseWarning />
            <Home />
            <AppointmentNotification />
            <DialerListener />
            <SaveAnswersListener />
          </>
        </CustomerRouter>
      </DialerTransferProvider>
    </DialerServiceProvider>
  </AuthenticatedPage>
);
