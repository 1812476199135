import { ApolloError, gql } from 'apollo-boost';
import { MutationHookOptions, MutationTuple } from '@apollo/react-hooks';
import { useMutationCustom } from '../useMutationCustom';
import {
  VerifyCmhrUserByMarxMutationData,
  VerifyCmhrUserByMarxMutationVariables,
  VerifyCmhrUserMutationData,
  VerifyCmhrUserMutationVariables,
} from './useVerifyCmhrUserMutation.interface';
import { getEventBus } from '../../../events';
import { useCallback } from 'react';
import { HealthRecordMarxEventProperties } from '../../../events/MedDigitalAnalyticsEvent.interfaces';
import { MedDigitalAnalyticsEvent } from '../../../events/MedDigitalAnalyticsEvent';

const VERIFY_CMHR_USER_MUTATION = gql`
  mutation VerifyCmhrUser($customerId: ID!, $userDoctorIds: [String]!) {
    verifyCmhrUser(customerId: $customerId, userDoctorIds: $userDoctorIds) {
      code
      success
      message
      timestamp
    }
  }
`;

const VERIFY_CMHR_USER_MUTATION_BY_MARX = gql`
  mutation VerifyCmhrUserByMarx($customerId: ID!, $verifiedWithCarrier: Boolean) {
    verifyCmhrUserByMarx(customerId: $customerId, verifiedWithCarrier: $verifiedWithCarrier) {
      code
      success
      message
      timestamp
    }
  }
`;

export function useVerifyCmhrUserMutation(
  options: MutationHookOptions<VerifyCmhrUserMutationData, VerifyCmhrUserMutationVariables>,
) {
  return useMutationCustom<VerifyCmhrUserMutationData, VerifyCmhrUserMutationVariables>(
    VERIFY_CMHR_USER_MUTATION, options,
  );
}

export function useVerifyCmhrUserMutationByMarx(
  options: MutationHookOptions<VerifyCmhrUserByMarxMutationData, VerifyCmhrUserByMarxMutationVariables>,
):MutationTuple<VerifyCmhrUserByMarxMutationData, VerifyCmhrUserByMarxMutationVariables> {
  const eventBus = getEventBus();

  const emitEvent = useCallback((message: string, properties: HealthRecordMarxEventProperties) => {
    eventBus.emit(new MedDigitalAnalyticsEvent(message, properties));
  }, [eventBus]);

  const onCompleted = useCallback((data: VerifyCmhrUserByMarxMutationData) => emitEvent(
    'Success',
    {
      location: 'marx_verification',
      message: data.verifyCmhrUserByMarx.message,
      success: data.verifyCmhrUserByMarx.success,
    },
  ), [emitEvent]);

  const onError = useCallback((error: ApolloError) => emitEvent(
    'Error',
    {
      location: 'marx_verification',
      error: error.message,
    },
  ), [emitEvent]);

  const [verifyChmrUserByMarxMutation, result] = useMutationCustom<VerifyCmhrUserByMarxMutationData, VerifyCmhrUserByMarxMutationVariables>(
    VERIFY_CMHR_USER_MUTATION_BY_MARX, { onError, onCompleted, ...options },
  );

  const verifyChmrUserByMarxMutationWrapped = useCallback(({ variables }: { variables: VerifyCmhrUserByMarxMutationVariables }) => {
    emitEvent(
      'Request',
      {
        location: 'marx_verification',
      },
    );
    return verifyChmrUserByMarxMutation(variables);
  }, [verifyChmrUserByMarxMutation, emitEvent]);

  return [
    verifyChmrUserByMarxMutationWrapped,
    // @ts-ignore
    result,
  ];
}
