import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../themes';

export const CardListItemContainer = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  border-top: 1px solid ${theme.color.grayLight};
  transition: all 200ms;
  
  &:hover {
    cursor: pointer;
    background-color: ${theme.color.grayLight};
  }
  
  a {
    color: ${theme.color.primary};
  }
`);
