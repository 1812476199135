import React from 'react';
import { Collapse, useTheme } from '@material-ui/core';
import { useToggle } from '../../useToggle';
import { SectionTitle, SectionTitleContainer } from './HealthRecordSection.elements';
import { ArrowDropDown } from '@material-ui/icons';
import { HealthRecordSectionInterface } from './HealthRecordSectionInterfaces';

const HealthRecordSection: React.FC<HealthRecordSectionInterface> = ({
  children, name, defaultOpen, onToggle, alwaysOpen,
}) => {
  const [open, toggleSection] = useToggle(defaultOpen);
  const theme = useTheme();
  const onTitleClick = React.useCallback(() => {
    onToggle?.();
    toggleSection();
  }, [onToggle, toggleSection]);

  const style = React.useMemo(() => ({
    transform: !open ? 'rotate(-90deg)' : 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }), [open, theme]);

  if (alwaysOpen) {
    return (
      <div>
        {children}
      </div>
    );
  }

  return (
    <div>
      <SectionTitleContainer onClick={onTitleClick}>
        <ArrowDropDown style={style} />
        <SectionTitle>{name}</SectionTitle>
      </SectionTitleContainer>
      <Collapse in={open}>
        {children}
      </Collapse>
    </div>
  );
};

export default HealthRecordSection;
