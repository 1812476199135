import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../../themes';

export const UserActivityViewContainer = styled('div')(css`
  height: 100%;
  overflow: scroll;
`);

export const UserActivityViewHeader = styled('h2')(css`
  padding: 10px 16px;
  margin: 0;
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
`);

export const Anchor = styled('a')(css`
  text-decoration: none;
  color: #000;
`);

export const TaskContainer = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  border-top: 1px solid ${theme.color.grayLight};
  transition: all 200ms;
  
  &:hover {
    cursor: pointer;
    background-color: ${theme.color.grayLight};
  }
`);
