import * as React from 'react';
import { DispositionSelectContainer } from './DispostionDialogeSelect.elements';
import { Disposition } from '@healthiqeng/core.services.dialer.base';
import {
  FormControl, Select, InputLabel, MenuItem, Snackbar,
} from '@material-ui/core';
import {
  DNCModalWrapper,
} from './DNCModalWrapper';
import { Alert } from '@material-ui/lab';
import { useDialerService, captureDialerException } from '@healthiqeng/core.hooks.use-dialer-service';
import { useDialerTransfer } from '../../../common/DialerTransferButtons/useDialerTransferButtons/useDialerTransfer';
import { emitAnalyticsEvent } from '../../../../events/emitAnalyticsEvent';
import { DispositionTypes } from '@hiq/crm.types';
import { GenericModal } from '../../../common/GenericModal';
import { DispositionModalContent } from '../../../common/DispositionModal/DispositionModalContent';
import { useMostRecentAppointmentsQuery } from '../../../../graphql/queries/useMostRecentAppointmentsQuery';

const customDNCWorkflow: string[] = [
  'Do Not Call',
  'DNC List',
];

const customPipelineWorkflow = 'Pipeline';

export const DispositionDialogSelect: React.FC = () => {
  const [errorMessage, setError] = React.useState<string | null>(null);
  const [successMessage, dispositionSaveSuccess] = React.useState<string | null>(null);
  const vertical = 'top';
  const horizontal = 'center';
  const { dialerService } = useDialerService();
  const { setSelectedDisposition, selectedDisposition, dispositions } = useDialerTransfer();
  const { data: appointments } = useMostRecentAppointmentsQuery();

  async function handleDispositionChange(event: React.ChangeEvent<HTMLInputElement>) {
    const selectedDisposition = dispositions.find((disposition) => disposition.value === event?.target?.value) as Disposition;
    setSelectedDisposition(selectedDisposition);
  }

  function handleClose() {
    setSelectedDisposition(null);
  }

  function closeError() {
    setError(null);
  }

  function closeSuccess() {
    dispositionSaveSuccess(null);
  }

  async function handleSave() {
    await handleAgentDisposition(selectedDisposition);
    setSelectedDisposition(null);
    dispositionSaveSuccess('Disposition Saved');
  }

  async function handleAgentDisposition(disposition: Disposition) {
    try {
      await dialerService.endCall(selectedDisposition);
      emitAnalyticsEvent('Disposition Call', { disposition: disposition.label });
    } catch (err) {
      captureDialerException(err, 'endCall', dialerService.getCallData());
    }
  }

  function sortDispositions(dispositions: Disposition[]): Disposition[] {
    const sortedList = Object.values<string>(DispositionTypes);
    const sorted: Disposition[] = [];
    const notFoundInSortedList: Disposition[] = dispositions.filter((d) => !sortedList.includes(d.label));
    for (const dispositionName of sortedList) {
      const disp = dispositions.find((d) => d.label === dispositionName);
      if (disp) {
        sorted.push(disp);
      }
    }
    sorted.push(...notFoundInSortedList);
    return sorted;
  }

  return (
    <DispositionSelectContainer>
      {errorMessage && (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={!!errorMessage}
          autoHideDuration={6000}
          onClose={closeError}
        >
          <Alert severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      )}

      <Snackbar
        open={!!successMessage}
        autoHideDuration={4000}
        onClose={closeSuccess}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert severity="success">{successMessage}</Alert>
      </Snackbar>
      <FormControl fullWidth>
        <InputLabel id="disposition-select-label">Disposition</InputLabel>
        <Select
          labelId="disposition-select-label"
          id="disposition-select"
          onChange={handleDispositionChange}
        >
          {sortDispositions(dispositions).map((disposition) => (
            <MenuItem
              key={disposition.value}
              value={disposition.value}
            >
              {disposition.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <DNCModalWrapper
        open={selectedDisposition && customDNCWorkflow.includes(selectedDisposition.label)}
        onClose={handleClose}
      />
      <GenericModal
        open={!!selectedDisposition
          && !customDNCWorkflow.includes(selectedDisposition.label)
          && customPipelineWorkflow !== selectedDisposition.label}
        headerText="Disposition"
        modalContent={(
          <DispositionModalContent
            contentText={`This call will be marked as ${selectedDisposition?.label}`}
            onClose={handleClose}
            onClickSave={handleSave}
            upcomingAppointments={appointments?.upcomingAgentAppointments?.filter(Boolean)}
            missedAppointments={appointments?.missedAgentAppointments?.filter(Boolean)}
          />
        )}
      />
    </DispositionSelectContainer>
  );
};
