import { useDebounce } from '@healthiqeng/core.hooks.use-debounce';
import { Alert, Autocomplete } from '@material-ui/lab';
import { Box, CircularProgress, TextField } from '@material-ui/core';
import React, { SyntheticEvent, useMemo, useState } from 'react';
import { useFormAnswers } from '../../../hooks';
import { useSunfireV2PlansByTermQuery } from '../../../graphql';
import { QueryLoader } from '../QueryLoader';
import { useActiveLeadId } from '../../routers';
import { SunfirePlan } from '@hiq/crm.types';

const SELECTED_PLAN_ANSWER_KEY = 'lead.sunfire.v2.selectedPlan';
const ZIP_QUESTION_ID = 'customer.person.homeAddress.zip';
const SUNFIRE_COUNTY_QUESTION_ID = 'lead.sunfire.v2.countyCode';

export const SunfirePlanAutocomplete = () => {
  const [answers, onChange] = useFormAnswers();
  const selectedPlan = React.useMemo(
    () => (answers[SELECTED_PLAN_ANSWER_KEY]
      ? JSON.parse(answers[SELECTED_PLAN_ANSWER_KEY]) as SunfirePlan
      : undefined),
    [answers[SELECTED_PLAN_ANSWER_KEY]],
  );
  const [planName, setPlanName] = useState(
    selectedPlan ? `${selectedPlan.name} - ${selectedPlan.contractId}-${selectedPlan.planId}` : '',
  );

  const [findPlansByTerm, { data: planData, loading, error }] = useSunfireV2PlansByTermQuery();
  const planOptions = useMemo(
    () => planData?.findSunfirePlansByTerm.map((plan) => ({
      label: `${plan.name} - ${plan.contractId}-${plan.planId}`,
      value: plan.id,
    })) ?? [],
    [planData?.findSunfirePlansByTerm],
  );

  const leadId = useActiveLeadId();
  const debouncedFindPlans = useDebounce(
    React.useCallback(
      (planName: string) => {
        if (planName.length >= 2) findPlansByTerm({ variables: { leadId, term: planName } });
      },
      [findPlansByTerm, leadId],
    ),
    800,
  );

  const handleOnTextInput = React.useCallback((event: SyntheticEvent, value: string, reason: string) => {
    if (reason === 'input') {
      setPlanName(value);
      if (value === '') {
        onChange(SELECTED_PLAN_ANSWER_KEY, '');
      } else {
        debouncedFindPlans(value);
      }
    }
  }, [setPlanName, debouncedFindPlans]);

  const handlePlanSelected = React.useCallback((event: SyntheticEvent, option: any, reason: string) => {
    if (reason === 'select-option') {
      setPlanName(option.label);
      const selectedPlan = planData.findSunfirePlansByTerm.find(({ id }) => option.value === id);
      onChange(SELECTED_PLAN_ANSWER_KEY, JSON.stringify(selectedPlan));
    }
  }, [planData, setPlanName, onChange]);

  const disabled = !answers[ZIP_QUESTION_ID] || !answers[SUNFIRE_COUNTY_QUESTION_ID];

  return (
    <QueryLoader error={error} loading={false} componentName="SunfirePlanAutocomplete">
      <Box flexDirection="column" gridGap="4px" display="flex">
        {disabled && (
          <Alert severity="info">
            Fill county and zipcode to search for current plan
          </Alert>
        )}
        <Autocomplete
          inputValue={planName}
          onInputChange={handleOnTextInput}
          onChange={handlePlanSelected}
          disabled={disabled}
          options={planOptions}
          getOptionLabel={(option) => option.label}
          style={{ width: 500 }}
          loading={loading}
          value={selectedPlan?.planId}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: loading ? <CircularProgress color="inherit" size={20} /> : null,
              }}
            />
          )}
        />
      </Box>

    </QueryLoader>
  );
};
