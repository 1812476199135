import { JsonCompatible } from '@healthiqeng/core.types';
import { FormAnswers } from '@hiq/crm.types';

export type UseFormAnswersReducerHook = (answers: FormAnswers) => [FormAnswers, FormAnswersReducerDispatch];

export type FormAnswersReducerDispatch = (action: FormAnswersAction) => void;

export interface FormAnswersState {
  answers: FormAnswers;
  dirtyKeys: Set<string>;
}

export type FormAnswersAction = FormAnswersChangeAction
| FormAnswersRefreshAction
| FormAnswersPersistAction
| FormAnswersResetAction;

export enum FormAnswersActionType {
  Change,
  Refresh,
  Persist,
  Reset,
}

export interface FormAnswersChangeAction {
  type: FormAnswersActionType;
  key: string;
  value: JsonCompatible;
  saveAnswers?: SaveAnswersFunction;
  debounce?: boolean;
}

export interface FormAnswersRefreshAction {
  type: FormAnswersActionType;
  answers: FormAnswers;
}

export interface FormAnswersPersistAction {
  type: FormAnswersActionType;
  saveAnswers: SaveAnswersFunction;
}

export interface FormAnswersResetAction {
  type: FormAnswersActionType;
  answers: FormAnswers;
}

type SaveAnswersFunction = (state: FormAnswersState, immediate?: boolean) => void | Promise<unknown>;
