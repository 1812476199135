import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../themes';

export const NewCrossSaleFormContainer = styled('div')(css``);

export const FormItemContainer = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  border-top: 1px solid ${theme.color.grayLight};
  padding: 10px 0;
`);

export const ErrorContainer = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  color: ${theme.color.error};
`);
