import { useLocation } from '@reach/router';
import * as React from 'react';
import {
  ComponentWrapper,
  LoadingComponent,
  LoadingComponentContainer, SelectPlanTypeContainer,
} from './SunfireIframe.elements';
import {
  useBionicCreateSessionMutation,
  useBionicFetchRecommendedPlansQuery, useTogglePharmacyMutation,
} from '../../../graphql';
import { useCustomerId, useLeadIdRoute } from '../../routers';
import { QueryLoader } from '../QueryLoader';
import { useDirtySunfireData } from './useDirtySunfireData';
import { useCurrentUserIsBionic, useFormAnswers } from '../../../hooks';
import { emitAnalyticsEventNoLimit } from '../../../events/emitAnalyticsEvent';
import { PlanComparisonError } from './PlanComparisonError';
import { TRMQuote } from './TRMQuote/TRMQuote';

import { Alert } from '@material-ui/lab';
import { SunfirePlanType } from './SunfirePlanType';

const loadingGif = require('../../../../static/animation-analyzing.gif');

enum QuoteMode {
  TRM = 'TRM',
  PLAN_COMPARISON_ERROR = 'PLAN_COMPARISON_ERROR'
}

const PM_GRID_VALID = 'lead.sunfire.v2.isGridValid';
export const SELECTED_MEDICARE_TYPE = 'lead.sunfire.v2.medicareType';
const SUNFIRE_COUNTY_QUESTION_ID = 'lead.sunfire.v2.countyCode';
const ZIPCODE_QUESTION_ID = 'customer.person.homeAddress.zip';

export const SunfireIframe: React.FunctionComponent = () => {
  const location = useLocation();
  const customerId = useCustomerId();
  const [leadId] = useLeadIdRoute();
  const isBionic = useCurrentUserIsBionic();
  const [isSunfireDataDirty, setSunfireDataDirty] = useDirtySunfireData();
  const [answers, saveFormAnswers] = useFormAnswers(leadId, true);
  const countyCode = answers[SUNFIRE_COUNTY_QUESTION_ID];
  const zipcode = answers[ZIPCODE_QUESTION_ID];
  const planType = answers[SELECTED_MEDICARE_TYPE];
  const [currentQuoteMode, setCurrentQuoteMode] = React.useState(QuoteMode.TRM);

  const goToTrmQuote = () => {
    setCurrentQuoteMode(QuoteMode.TRM);
  };

  const [
    uploadHealthDataQuery,
    { data: createSessionData, error: createSessionError },
  ] = useBionicCreateSessionMutation();
  const {
    url, unsupportedPharmacy, baseUrl, customerCode, prospectId,
  } = createSessionData?.bionicCreateSession ?? {};

  const [
    loadPlans,
    { data, error: loadPlansError },
  ] = useBionicFetchRecommendedPlansQuery(leadId, 'network-only');
  const {
    plans, error, message, doctors, currentPlan,
  } = data?.fetchBionicRecommendedPlans ?? {};

  React.useEffect(() => {
    // Should match: /customer/9da55e57-e156-4b51-ae55-92266a699a13/lead/d14ac0ad-ed20-4583-907b-dff337cc1bc9#quote
    const pathRegex = /^\/customer\/[\w\d-]+\/lead\/[\w\d-]/;
    if (location && pathRegex.test(location.pathname) && location.hash === '#quote' && isSunfireDataDirty && planType
    && zipcode && countyCode) {
      uploadHealthDataQuery({ leadId });
    }
  }, [location, isSunfireDataDirty, zipcode, countyCode, planType]);

  React.useEffect(() => {
    // Should match: /customer/9da55e57-e156-4b51-ae55-92266a699a13/lead/d14ac0ad-ed20-4583-907b-dff337cc1bc9#quote
    const pathRegex = /^\/customer\/[\w\d-]+\/lead\/[\w\d-]/;
    if (location && pathRegex.test(location.pathname) && location.hash === '#quote') {
      emitAnalyticsEventNoLimit('Quote Plans', { customerId, leadId, isPrecisionMedicareHeavy: isBionic });
    }
  }, [location]);

  React.useEffect(() => {
    if (url) {
      if (isBionic) {
        loadPlans();
      } else {
        setSunfireDataDirty(false);
      }
    }
  }, [url]);

  const [togglePharmacy] = useTogglePharmacyMutation();
  React.useEffect(() => {
    if (unsupportedPharmacy) {
      togglePharmacy({ input: { leadId } }); // unselects pharmacy
      saveFormAnswers(PM_GRID_VALID, false);
    }
  }, [unsupportedPharmacy]);

  React.useEffect(() => {
    if (plans && isBionic) {
      setCurrentQuoteMode(QuoteMode.TRM);
      setSunfireDataDirty(false);
    }
  }, [plans, isBionic]);

  React.useEffect(() => {
    if (error) setCurrentQuoteMode(QuoteMode.PLAN_COMPARISON_ERROR);
  }, [error]);

  const trmQuote = React.useMemo(() => (
    <TRMQuote
      plans={plans}
      currentPlan={currentPlan}
      baseUrl={baseUrl}
      customerCode={customerCode}
      countyCode={countyCode}
      prospectId={prospectId}
    />
  ), [plans, currentPlan, baseUrl, customerCode, countyCode, prospectId]);

  const plansComparisonError = React.useMemo(() => (
    <PlanComparisonError doctors={doctors} message={message} switchView={goToTrmQuote} />
  ), [doctors, message, goToTrmQuote]);

  const errorMessage = React.useMemo(
    () => {
      const technicalErrorMessage = (createSessionError || loadPlansError)?.message;
      return technicalErrorMessage
        ? {
          message: `Please try reloading TRM and check your credentials.\n
Please report this to the Slack help channel: ${technicalErrorMessage}`,
        }
        : undefined;
    },
    [createSessionError, loadPlansError],
  );

  if (!planType) {
    return (
      <SelectPlanTypeContainer>
        Select a plan type to view quotes
        <SunfirePlanType />
      </SelectPlanTypeContainer>
    );
  }

  return (
    <QueryLoader loading={false} error={errorMessage} componentName="SunfireIframe">
      {isSunfireDataDirty
        ? (
          <LoadingComponentContainer>
            <LoadingComponent src={loadingGif} alt="Loading quotes" />
          </LoadingComponentContainer>
        ) : null}
      <ComponentWrapper hidden={isSunfireDataDirty}>
        {unsupportedPharmacy
          ? (
            <Alert severity="warning">
              Failed to select pharmacy
              &nbsp;
              {unsupportedPharmacy?.name}
              . Reason:&nbsp;
              {unsupportedPharmacy?.message}

              Please select a different pharmacy.
            </Alert>
          )
          : (
            <>
              {currentQuoteMode === QuoteMode.TRM && trmQuote}
              {currentQuoteMode === QuoteMode.PLAN_COMPARISON_ERROR && plansComparisonError}
            </>
          )}

      </ComponentWrapper>
    </QueryLoader>
  );
};
