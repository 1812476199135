import React from 'react';
import { CellProps, Renderer } from 'react-table';
import { DatePickerInput } from '../../../inputs/DatePickerInput';
import { noop } from '../../../../../util';

export function DateCell <V extends Object>({ value }: CellProps<V, string>): Renderer<CellProps<V, string>> {
  return (
    <DatePickerInput
      value={value}
      onChange={noop}
      disabled
    />
  );
}
