import { gql } from 'apollo-boost';
import { useMutationCustom } from '../useMutationCustom';
import {
  UpdateQuestionFlowAnswersMutationResponse,
  UpdateQuestionFlowAnswersMutationVariables,
} from './useUpdateQuestionFlowAnswersMutation.interfaces';

const UPDATE_QUESTION_FLOW_ANSWERS_MUTATION = gql`
  mutation UpdateQuestionFlowAnswers($leadId: ID!, $answers: JSON!) {
    updateQuestionFlowAnswers(leadId: $leadId, answers: $answers) {
      code
      success
      message
      timestamp
      answers
    }
  }
`;

export function useUpdateQuestionFlowAnswersMutation() {
  return useMutationCustom<
  UpdateQuestionFlowAnswersMutationResponse,
  UpdateQuestionFlowAnswersMutationVariables
  >(UPDATE_QUESTION_FLOW_ANSWERS_MUTATION);
}
