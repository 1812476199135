import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import * as React from 'react';
import { CustomerIdContext } from '../../../components/contexts/CustomerIdContext';
import {
  SpecialRateAvailabilityQueryData,
  SpecialRateAvailabilityQueryVariables,
} from './useSpecialRateAvailabilityQuery.interfaces';
import { useEventListener, ZipOrStateChangeEvent } from '../../../events';

const SPECIAL_RATE_AVAILABILITY_QUERY = gql`
  query getSpecialRateAvailability($customerId: ID!) {
    getSpecialRateAvailability(customerId: $customerId)
  }
`;

export function useSpecialRateAvailabilityQuery(customerId?: string) {
  const result = useQuery<
  SpecialRateAvailabilityQueryData,
  SpecialRateAvailabilityQueryVariables>(SPECIAL_RATE_AVAILABILITY_QUERY, {
    variables: {
      customerId: getCustomerId(customerId),
    },
  });
  useEventListener(ZipOrStateChangeEvent, async () => {
    await result.refetch();
  });
  return result;
}

function getCustomerId(customerId?: string) {
  return customerId ?? React.useContext(CustomerIdContext);
}
