import React, { useEffect, useRef } from 'react';
import {
  AddressTitle, LocationButton, MatchedAddress, Panel, SearchBox,
} from './DoctorLocationSearchPanel.elements';
import { DoctorLocationSearchPanelProps } from './DoctorLocationSearchPanel.interfaces';
import { QueryLoader } from '../../../QueryLoader';
import { useGetPlaceDetailsQuery, useSunfireV2CitiesByZipQuery } from '../../../../../graphql';
import { PlaceLookupInput } from '../../../inputs/AddressLookupInput/PlaceLookupInput';

export const DoctorLocationSearchPanel = ({ onLocationChanged, zip }: DoctorLocationSearchPanelProps) => {
  const sessionToken = useRef<string>(`address-lookup-${Date.now()}`);

  const { data, loading, error } = useSunfireV2CitiesByZipQuery(zip);
  const [
    getPlaceDetails,
    { data: placeDetailsData, loading: placeDetailsLoading, error: placeDetailsError },
  ] = useGetPlaceDetailsQuery();

  const onPlaceSelected = React.useCallback((placeId: string) => {
    if (placeId) {
      getPlaceDetails({ variables: { placeId, sessionToken: sessionToken.current } });
    }
  }, [getPlaceDetails]);

  useEffect(() => {
    if (placeDetailsData?.getPlaceDetails) {
      onLocationChanged({
        ...placeDetailsData.getPlaceDetails,
      });
    }
  }, [placeDetailsData]);

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (!loading) {
      inputRef.current.focus();
    }
  }, [loading]);

  return (
    <QueryLoader loading={placeDetailsLoading} error={placeDetailsError} componentName="LocationSearchPanel">
      <Panel>
        <SearchBox>
          <div>
            Where is your doctor located?
          </div>
          <PlaceLookupInput
            onChange={onPlaceSelected}
            label="Search for a city or zip code:"
            size="small"
            fullWidth
            variant="outlined"
            sessionToken={sessionToken.current}
            inputRef={inputRef}
          />
        </SearchBox>
        <QueryLoader loading={loading} error={error} componentName="LocationSearchPanel">
          {data?.findSunfireCitiesByZip?.length > 0 && (
            <MatchedAddress>
              <AddressTitle>Nearby cities/towns:</AddressTitle>
              {data?.findSunfireCitiesByZip?.map((location) => (
                <LocationButton onClick={() => onLocationChanged(location)}>
                  {location.city}
                </LocationButton>
              ))}
            </MatchedAddress>
            )}
        </QueryLoader>
      </Panel>
    </QueryLoader>
  );
};
