import { gql } from 'apollo-boost';
import { MutationCustomReturn, useMutationCustom } from '../useMutationCustom';
import { TogglePharmacyMutationResponse, TogglePharmacyMutationVariables } from './useTogglePharmacyMutation.interfaces';
import {
  SELECTED_PHARMACIES_QUERY,
  SelectedPharmaciesQueryData,
  SelectedPharmaciesQueryVariables,
} from '../../queries';
import { PRECISION_MEDICARE_PHARMACY_FRAGMENT } from '../../fragments';

const TOGGLE_PHARMACY_MUTATION = gql`
  mutation TogglePharmacy($input: PrecisionMedicareTogglePharmacyInput!) {
    togglePharmacy(input: $input) {
      pharmacy {
        ...PrecisionMedicarePharmacyFragment
      }
      removed
    }
  }
  ${PRECISION_MEDICARE_PHARMACY_FRAGMENT}
`;

export function useTogglePharmacyMutation(): MutationCustomReturn<
TogglePharmacyMutationResponse, TogglePharmacyMutationVariables> {
  return useMutationCustom<TogglePharmacyMutationResponse, TogglePharmacyMutationVariables>(TOGGLE_PHARMACY_MUTATION, {
    update: (cache, mutationResult) => {
      const { pharmacy, removed } = mutationResult.data.togglePharmacy;
      cache.writeQuery<SelectedPharmaciesQueryData, SelectedPharmaciesQueryVariables>({
        query: SELECTED_PHARMACIES_QUERY,
        variables: {
          leadId: pharmacy.leadId,
        },
        data: {
          selectedPharmacies: removed ? [] : [pharmacy],
        },
      });
    },
  });
}
