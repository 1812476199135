import * as React from 'react';
import { WarningBlockProps } from './WarningBlock.interfaces';
import { useMemo } from 'react';
import { WarningBlockView } from './WarningBlockView';
import { useActiveLeadId } from '../../../routers/LeadRouter/useActiveLeadId';
import { useFormAnswers } from '../../../../hooks';
import { getHashedKey } from '../MustReadBlock/getHashedKey';

export const WarningBlock: React.FunctionComponent<WarningBlockProps> = ({
  referenceId,
  text,
  children,
  confirmationRequired,
  confirmText,
  checked,
}) => {
  const leadId = useActiveLeadId();

  const key = referenceId ?? useMemo(() => getHashedKey(text), [text]);
  const [answers, saveAnswers] = useFormAnswers(leadId);
  const isAcknowledged = answers[key] as boolean;

  const handleChange = () => {
    saveAnswers(key, !isAcknowledged);
  };

  return (
    <WarningBlockView
      confirmationRequired={confirmationRequired}
      confirmText={confirmText}
      value={checked ?? isAcknowledged}
      onChange={handleChange}
    >
      { children }
    </WarningBlockView>
  );
};
