import * as React from 'react';
import { isPresent } from '@healthiqeng/core.util.is-present';
import { useExternalQuote } from '../useExternalQuote';
import {
  MedicareVisionQuoteCreateProperties,
  ProductTypeMedicare,
  QuestionType,
  QuotePresentationType,
} from '@hiq/crm.types';
import { QueryLoader } from '../../../QueryLoader';
import { QuoteOptionsLayout } from '../../QuoteOptionsLayout';
import { QuoteOption } from '../../QuoteOptions/QuoteOption';
import { PrimaryButton } from '../../../inputs/PrimaryButton';
import { useLogClick } from '../../../../../graphql';
import { useCallback } from 'react';
import { emitAnalyticsEvent } from '../../../../../events/emitAnalyticsEvent/emitAnalyticsEvent';

export const MedicareVisionExternalQuote: React.FunctionComponent = () => {
  const {
    quoteInput,
    setQuoteInput,
    carrierOptions,
    submitQuote,
    loading,
    error,
  } = useExternalQuote<MedicareVisionQuoteCreateProperties>({
    type: ProductTypeMedicare.Vision,
    presentationType: QuotePresentationType.Selected,
    rate: {
      monthly: null,
      annually: null,
      policyFee: 0,
    },
  });

  const [logClick] = useLogClick();
  const onAddQuoteButtonClick = useCallback(() => {
    logClick({ control: 'Add Quote', detail: 'Medicare Vision' });
    emitAnalyticsEvent('Add Quote', { type: 'Medicare Vision' });
    submitQuote();
  }, [logClick, submitQuote]);

  const canSubmit = [
    quoteInput.rate.monthly,
    quoteInput.rate.annually,
    quoteInput.carrierId,
    quoteInput.effectiveDate,
    quoteInput.planName,
  ].every(isPresent);

  return (
    <QueryLoader loading={loading} error={error} componentName="MedicareVisionExternalQuote">
      <QuoteOptionsLayout
        leftContent={(
          <>
            <QuoteOption
              id="carrierId"
              value={quoteInput.carrierId ?? ''}
              handleChange={setQuoteInput}
              label="Carrier"
              type={QuestionType.Select}
              options={carrierOptions || []}
            />
            <QuoteOption
              id="planName"
              label="Plan Name"
              value={quoteInput.planName ?? ''}
              type={QuestionType.Text}
              handleChange={setQuoteInput}
            />
            <QuoteOption
              id="rate.annually"
              label="Annual Premium"
              value={quoteInput.rate.annually}
              type={QuestionType.Currency}
              handleChange={setQuoteInput}
            />
            <QuoteOption
              id="rate.monthly"
              label="Monthly Premium"
              value={quoteInput.rate.monthly}
              type={QuestionType.Currency}
              handleChange={setQuoteInput}
            />
          </>
        )}
        rightContent={(
          <>
            <QuoteOption
              id="effectiveDate"
              value={quoteInput.effectiveDate}
              handleChange={setQuoteInput}
              label="Effective Date"
              type={QuestionType.Date}
            />
            <PrimaryButton
              onClick={onAddQuoteButtonClick}
              disabled={!canSubmit}
              variant="contained"
            >
              { loading ? 'Submitting...' : 'Add Quote' }
            </PrimaryButton>
            { error && <div>{ error.message }</div> }
          </>
        )}
        minWidth={200}
        gutter={20}
      />
    </QueryLoader>
  );
};
