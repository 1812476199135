import * as React from 'react';
import { MedicareSalesPanelProps } from './MedicareSalesPanel.interfaces';
import { QuoteInfoCard, FormGeneratorCompact } from '../../../../../common';
import { LeadPanelCard, LeadPanelStickyCard } from '../../../elements';

export const MedicareSalesPanel: React.FunctionComponent<MedicareSalesPanelProps> = ({
  leadId,
}) => (
  <>
    <LeadPanelStickyCard>
      <QuoteInfoCard leadId={leadId} />
    </LeadPanelStickyCard>
    <LeadPanelCard>
      <FormGeneratorCompact
        leadId={leadId}
        referenceId="medicare-backend-sales"
      />
    </LeadPanelCard>
  </>
);
