import * as React from 'react';
import { ResizableColumnsProps } from './ResizableColumns.interfaces';
import {
  ResizableColumnsContainer,
  Column,
} from './ResizableColumns.elements';
import { DraggableDivider } from './DraggableDivider';
import { CollapseColumnsContext } from '../../contexts';
import { CollapsedResizableColumn } from '../CollapsedResizableColumn/CollapsedResizableColumn';

export const COLLAPSE_WIDTH = 60;

export const ResizableColumns: React.FunctionComponent<ResizableColumnsProps> = ({
  columnContents,
  sideBarCollapsed,
  initialColumnWidths,
  navBarPresent,
  height,
  collapsableColumnIndexes,
  columnVerticalOverflow,
}) => {
  const [columnWidths, dispatch] = React.useReducer(reducer, initialColumnWidths);
  const columnWidthsWithDivider = columnsWithDivider(columnWidths, '1px');
  if (sideBarCollapsed) {
    columnWidthsWithDivider[columnWidthsWithDivider.length - 1] = '50px';
  }
  const { collapseAll } = React.useContext(CollapseColumnsContext);

  React.useEffect(() => {
    if (collapseAll) {
      dispatch({ collapseAll });
    }
  }, [collapseAll]);

  const handleCollapse = (index: number) => {
    dispatch({ index, collapse: true });
  };

  const handlePositionChange = (index: number, movementX: number) => {
    dispatch({ index, movementX });
  };

  function reducer(columnWidths: string[], {
    index, movementX, collapse, collapseAll,
  }: { index?: number, movementX?: number, collapse?: boolean, collapseAll?: boolean }) {
    const indexOfAuto = columnWidths.indexOf('auto');
    const newColumnWidths = [...columnWidths];

    if (collapseAll) {
      return newColumnWidths.map((el) => (el !== 'auto' ? `${COLLAPSE_WIDTH}px` : el));
    }

    if (collapse) {
      const currentlyCollapsed = columnWidths[index / 2] === `${COLLAPSE_WIDTH}px` || parseWidth(columnWidths[index / 2]) <= COLLAPSE_WIDTH;
      newColumnWidths[index / 2] = currentlyCollapsed ? initialColumnWidths[index / 2] : `${COLLAPSE_WIDTH}px`;

      return newColumnWidths;
    }

    if (index >= indexOfAuto) {
      const newWidth = parseWidth(columnWidths[index + 1]) - movementX;
      newColumnWidths[index + 1] = `${newWidth}px`;
      return newColumnWidths;
    }

    if (index < indexOfAuto) {
      const newWidth = parseWidth(columnWidths[index]) + movementX;
      newColumnWidths[index] = `${newWidth}px`;
      return newColumnWidths;
    }

    return columnWidths;
  }

  // @ts-ignore
  return (
    <ResizableColumnsContainer columnWidths={columnWidthsWithDivider} height={height} navBarPresent={navBarPresent}>
      {
        columnWidthsWithDivider.map((width, index) => {
          const isCollapsable = collapsableColumnIndexes && collapsableColumnIndexes.includes(index);

          return (
            index % 2
              ? (
                <DraggableDivider
                  columnIndex={Math.floor(index / 2)}
                  key={index} // eslint-disable-line
                  onPositionChange={handlePositionChange}
                />
              )
              : (
                // eslint-disable-next-line
                <Column key={index}
                  columnVerticalOverflow={columnVerticalOverflow || 'scroll'}
                >
                  {
                    isCollapsable ? (
                      <CollapsedResizableColumn
                        width={width}
                        columnIndex={index}
                        handleCollapse={handleCollapse}
                        columnContents={columnContents}
                      />
                    ) : (
                      columnContents[Math.floor(index / 2)]
                    )
                  }
                </Column>
              )
          );
        })
      }
    </ResizableColumnsContainer>
  );
};

function columnsWithDivider(columnWidths: string[], dividerWidth: string) {
  const withDivider = columnWidths.reduce((reduction, width) => (
    reduction.concat([width, dividerWidth])
  ), []);
  withDivider.pop();
  return withDivider;
}

export function parseWidth(width: string): number {
  return Number.parseFloat(width.replace(/[^0-9.]/g, ''));
}
