import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { SoldLeadInfoQueryData, SolsLeadInfoQueryVariables } from './useSoldLeadInfoQuery.interfaces';

const SOLD_LEAD_INFO_QUERY = gql`
  query getSoldLeadInfo($leadId: ID!) {
    soldLeadInfo(leadId: $leadId) {
      isLocked
      soldDate
      finalCoverageAmount {
        value
        formatted
      }
      finalPremiums {
        monthly {
          value
          formatted
        }
        quarterly {
          value
          formatted
        }
        semiAnnually {
          value
          formatted
        }
        annually {
          value
          formatted
        }
      }
      stage {
        stage
        subStage
      }
    }
  }
`;

export function useSoldLeadInfoQuery(leadId?: string | null, notSold?: boolean) {
  const result = useQuery<SoldLeadInfoQueryData, SolsLeadInfoQueryVariables>(SOLD_LEAD_INFO_QUERY, {
    skip: !leadId || notSold,
    variables: {
      leadId,
    },
  });
  return result;
}
