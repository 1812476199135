import { useState, useCallback } from 'react';

export const useToggle = (initialState: boolean = false, onOpen?: () => void) : [boolean, () => void, (open: boolean) => void, ] => {
  const [isOpen, setIsOpen] = useState(initialState);
  const toggle = useCallback(() => setIsOpen((prevState) => {
    if (!prevState) onOpen?.();
    return !prevState;
  }), [onOpen]);

  return [isOpen, toggle, setIsOpen];
};
