import React, { useEffect } from 'react';
import { useFormAnswers } from '../../../../../../hooks';
import { useActiveLeadId } from '../../../../../routers';
import { QuestionTextContainer } from '../ValidateAdditionalDoctors.elements';
import { QuestionView } from '../../../../FormGenerator/FormGeneratorQuestionFlow/Question/QuestionView';
import { QuestionType } from '@hiq/crm.types';
import { useDebounce } from '@healthiqeng/core.hooks.use-debounce';

const HAS_MINIMUM_RECOMMENDED_DOCTORS_QUESTION_REASON_OTHER_ID = 'lead.sunfire.v2.hasMinimumRecommendedDoctorsReasonOther';
const HAS_MINIMUM_RECOMMENDED_DOCTORS_QUESTION_ID = 'lead.sunfire.v2.hasMinimumRecommendedDoctors';

export const OtherTextQuestion = () => {
  const leadId = useActiveLeadId();

  const [answers, onChange] = useFormAnswers(leadId, true, undefined, 1000);
  const [otherText, setOtherText] = React.useState(answers[HAS_MINIMUM_RECOMMENDED_DOCTORS_QUESTION_REASON_OTHER_ID] || '');

  const updateOtherAnswer = React.useCallback(() => {
    if (otherText) onChange(HAS_MINIMUM_RECOMMENDED_DOCTORS_QUESTION_ID, true);
    onChange(HAS_MINIMUM_RECOMMENDED_DOCTORS_QUESTION_REASON_OTHER_ID, otherText);
  }, [onChange, HAS_MINIMUM_RECOMMENDED_DOCTORS_QUESTION_REASON_OTHER_ID, otherText]);

  const debounceSave = useDebounce(updateOtherAnswer, 1000);

  useEffect(() => {
    debounceSave();
  }, [otherText]);

  return (
    <QuestionTextContainer>
      <QuestionView
        id={HAS_MINIMUM_RECOMMENDED_DOCTORS_QUESTION_REASON_OTHER_ID}
        questionText="Other reason"
        type={QuestionType.TextArea}
        label="Select all that apply"
        leadId={leadId}
        value={otherText}
        onChange={setOtherText}
      />
    </QuestionTextContainer>
  );
};
