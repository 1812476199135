import { useMemo } from 'react';
import { MainPanelLayoutTabItemAnchor } from '../../../layouts/MainPanelLayout';
import { QuestionFlowQueryParsedData, useQuestionFlowQuery } from '../../../../graphql';
import { extractAnchors } from '../../../common/QuestionFlow';

export function useQuestionFlowAnchorQuery(leadId: string, referenceId: string): MainPanelLayoutTabItemAnchor[] {
  const { data } = useQuestionFlowQuery(leadId, referenceId);
  return useMemo(() => extractAnchors(extractRecursiveMarkdown(data, referenceId)), [
    data,
    referenceId,
  ]);
}

export function extractRecursiveMarkdown(data: QuestionFlowQueryParsedData, referenceId: string): string {
  const baseMarkdown = data?.questionFlows?.[referenceId]?.body ?? '';
  const references = baseMarkdown.match(/`partial:[a-zA-Z-]+`/g) ?? [];
  const referenceIds = references.map((ref) => ref.replace(/(partial:|`)/g, ''));
  return [
    baseMarkdown,
    ...referenceIds.map((id) => extractRecursiveMarkdown(data, id)),
  ].join('\n');
}
