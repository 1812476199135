import * as React from 'react';
import { FileListProps } from './FileList.interfaces';
import {
  EmptyFileListItem,
  FileListTitle,
  FileListContainer,
} from './FileList.elements';
import { isEmpty } from '@healthiqeng/core.util.is-empty';
import { FileListItem } from './FileListItem';

export const FileList: React.FunctionComponent<FileListProps> = ({
  files,
}) => (
  <>
    <FileListTitle>Attached files</FileListTitle>
    <FileListContainer>
      { isEmpty(files) && <EmptyFileListItem>No files were found</EmptyFileListItem>}
      { !isEmpty(files) && files.map((file) => (
        <FileListItem name={file.name} id={file.id} allowDownload />
      ))}
    </FileListContainer>
  </>
);
