import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Checkbox, CheckboxProps } from '@material-ui/core';

export const ColoredCheckbox = styled(Checkbox)<CheckboxProps & { forcedColor?: string }>(({ forcedColor }) => css`
  ${forcedColor ? `color: ${forcedColor} !important;` : ''}
`);

export const DisableCheckbox = styled(Checkbox)`
 pointer-events: none; 
`;
