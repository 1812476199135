import { gql } from 'apollo-boost';
import { MutationCustomReturn, useMutationCustom } from '../useMutationCustom';
import { DeleteOverlayMutationResponse, DeleteOverlayMutationVariables } from './useDeleteOverlayMutation.interfaces';
import { OVERLAY_QUERY, OverlayQueryData } from '../../queries';
import { OVERLAY_FRAGMENT, OverlayFragment } from '../../fragments';

export const DELETE_OVERLAY_MUTATION = gql`
  mutation DeleteOverlay($id: OverlayId!) {
    deleteOverlay(id: $id) {
      success
      message
      overlay {
        ...OverlayFragment
      }
    }
  }
  ${OVERLAY_FRAGMENT}
`;

export function useDeleteOverlayMutation(): MutationCustomReturn<
DeleteOverlayMutationResponse,
DeleteOverlayMutationVariables
> {
  return useMutationCustom<DeleteOverlayMutationResponse, DeleteOverlayMutationVariables>(DELETE_OVERLAY_MUTATION, {
    update(cache, { data: { deleteOverlay } }) {
      if (deleteOverlay.success && deleteOverlay.overlay) {
        const { id, customerId } = deleteOverlay.overlay;
        const cachedQuery: OverlayQueryData = cache.readQuery({
          query: OVERLAY_QUERY,
          variables: {
            customerId,
          },
        });
        let targetQuery: keyof OverlayQueryData;
        switch (deleteOverlay.overlay.externalType) {
          case 'HealthRecordDrug':
          case 'SunfireDrug':
            targetQuery = 'drugsOverlays';
            break;
          case 'HealthRecordDoctor':
            targetQuery = 'doctorsOverlays';
            break;
          case 'HealthRecordPharmacy':
            targetQuery = 'pharmaciesOverlays';
            break;
          case 'HealthRecordUserClaim':
            targetQuery = 'userClaimsOverlays';
            break;
          case 'HealthRecordAnticipatedNeed':
            targetQuery = 'anticipatedNeedsOverlays';
            break;
          default:
            break;
        }
        const updatedCachedQuery = (cachedQuery[targetQuery] as OverlayFragment<any>[])
          ?.filter((overlay) => overlay.id !== id);
        cache.writeQuery({
          query: OVERLAY_QUERY,
          variables: {
            customerId,
          },
          data: {
            ...cachedQuery,
            [targetQuery]: updatedCachedQuery,
          },
        });
      }
    },
  });
}
