import * as React from 'react';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { InputValidationStatus, QuestionOption } from '@hiq/crm.types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IEventBusEvent } from '@healthiqeng/core.services.event-bus';
import { SelectInputProps } from './SelectInput.interfaces';
import { useActiveLeadId } from '../../../routers';
import { useQuestionOptionsLazyQuery, SaveBeginEvent, SaveCompleteEvent } from '../../../../graphql';
import { useEventInProgress, useEventListener } from '../../../../events';
import { TextField } from '../TextInput/TextInput.elements';
import { InputWidthLimiterContainer } from '../../InputWidthLimiterContainer';

const filter = createFilterOptions();

export const SelectInput: React.FunctionComponent<SelectInputProps> = ({
  value,
  placeholder,
  onChange,
  options,
  optionsId,
  dependencies,
  label,
  size,
  disabled,
  validationStatus,
  validationMessage,
  fullWidth,
  variant,
  loading,
  answersKey,
  arrayFormIndex,
  dataQa,
  showEmptyOption = true,
  disableClearable = false,
  freeSolo = false,
  tags,
}) => {
  const leadId = useActiveLeadId();
  const [fetchDynamicOptions, {
    data: dynamicOptionsData,
    loading: dynamicOptionsLoading,
    error: dynamicOptionsError,
  }] = useQuestionOptionsLazyQuery(leadId, answersKey);

  React.useEffect(() => {
    if (optionsId && !options?.length) fetchDynamicOptions();
  }, []);

  const selectOptions = options?.length
    ? options
    : dynamicOptionsData?.questionOptions || [];

  const inProgress = useEventInProgress(
    (event: IEventBusEvent) => matchesEvent({
      event,
      dependencies,
      triggerType: 'start',
      currentFormIndex: arrayFormIndex,
    }),
    (event: IEventBusEvent) => matchesEvent({
      event,
      dependencies,
      triggerType: 'end',
      currentFormIndex: arrayFormIndex,
    }),
    [dependencies],
  );

  useEventListener(SaveCompleteEvent, (event: SaveCompleteEvent) => {
    const hasDependency = isDependent(event.keys || new Set<string>(), dependencies, arrayFormIndex);
    if (!hasDependency || !answersKey) return;
    onChange(null);
    fetchDynamicOptions();
  }, dependencies);

  // Need to keep the double equals here..
  // graphql can't handle primitive union types, so options that are numbers
  // with be passed as strings
  const getOptionLabel = React.useCallback((val: any) => (
    selectOptions.find(({ value: optionValue }) => val == optionValue) // eslint-disable-line
      ?.label ?? val ?? ''),
  [selectOptions]);

  const getOptionSelected = React.useCallback(
    (optionValue: any, val: any) => optionValue == val, // eslint-disable-line
    [],
  );

  const onAutocompleteChange = React.useCallback(
    (event: any, newValue: string) => onChange(newValue),
    [onChange],
  );

  const isLoading = loading || dynamicOptionsLoading || inProgress;

  const selectedLabel = React.useMemo(
    () => selectOptions.find((opt) => opt.value === value)?.label ?? '',
    [value, selectOptions],
  );

  const [inputValue, setInputValue] = React.useState(selectedLabel ?? '');
  const handleInputChange = React.useCallback((event, newInputValue) => {
    setInputValue(newInputValue);
  }, [setInputValue]);

  React.useEffect(() => {
    setInputValue(selectedLabel ?? '');
  }, [selectedLabel]);

  const getTextInput = (params: any) => (
    <TextField
      tags={tags}
      {...params}
      label={label}
      size={size}
      placeholder={isLoading ? 'Loading...' : placeholder}
      fullWidth={fullWidth}
      variant={variant ?? 'outlined'}
      error={validationStatus === InputValidationStatus.Error || !!dynamicOptionsError}
      helperText={validationMessage || dynamicOptionsError?.message}
      disabled={disabled}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
            {params.InputProps.endAdornment}
          </>
        ),
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={{
        ...params.inputProps,
        'data-qa': dataQa,
      }}
    />
  );

  return (
    <InputWidthLimiterContainer>
      <Autocomplete
        value={value}
        onChange={onAutocompleteChange}
        autoHighlight
        disabled={disabled || (selectOptions.length === 1 && selectOptions[0].value === value)}
        options={getOptions(value, showEmptyOption, selectOptions)}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        renderInput={getTextInput}
        freeSolo={freeSolo}
        fullWidth={fullWidth}
        loading={isLoading}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          if (!freeSolo) return filtered;
          const { inputValue } = params;
          const isExisting = options.some((option) => inputValue === option.title);
          if (inputValue !== '' && !isExisting) filtered.push(inputValue);
          return filtered;
        }}
        style={{ background: 'white' }}
        disableClearable={disableClearable}
        inputValue={inputValue}
        onInputChange={handleInputChange}
      />
    </InputWidthLimiterContainer>
  );
};

function getOptions(
  value: any,
  showEmptyOption: boolean,
  options?: QuestionOption[],
): Array<string | number | boolean> {
  if (!options?.length && value) {
    return [value];
  }
  if (showEmptyOption) {
    return [
      ...(options.some(({ value: val }) => val === null) ? [] : [null]),
      ...options.map(({ value: val }) => val),
    ];
  }
  return options.map(({ value: val }) => val);
}

function matchesEvent({
  event,
  dependencies,
  triggerType,
  currentFormIndex,
}: {
  event: IEventBusEvent,
  dependencies: string[],
  triggerType: 'start' | 'end',
  currentFormIndex?: number,
}): boolean {
  if (triggerType === 'start' && !(event instanceof SaveBeginEvent)) return false;
  if (triggerType === 'end' && !(event instanceof SaveCompleteEvent)) return false;

  type SaveEvent = SaveBeginEvent | SaveCompleteEvent;

  const keys = (event as SaveEvent).keys || new Set<string>();
  return isDependent(keys, dependencies, currentFormIndex);
}

function isDependent(keys: Set<string>, dependencies: string[], currentFormIndex?: number) {
  return !!dependencies?.some((dep) => keys.has(dep) || keys.has(getIndexedDep(dep, currentFormIndex)));
}

function getIndexedDep(depName: string, currentFormIndex: number): string {
  const lastDotIndex = depName.lastIndexOf('.');
  if (lastDotIndex < 0) return depName;
  return `${depName.slice(0, lastDotIndex)}.${currentFormIndex}${depName.slice(lastDotIndex)}`;
}
