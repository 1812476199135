import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Table } from '@material-ui/core';

export const VehicleDriverAssociationContainer = styled('div')(css`
  margin: 10px 0;
`);

export const VehicleDriverAssociationTitle = styled('h1')(css`
  padding: 10px;
`);

export const VehicleDriverAssociationTable = styled(Table)<{
  vehiclesAmount: number
}>((props) => css`
  min-width: ${(props.vehiclesAmount + 1) * 110}px;
`);
