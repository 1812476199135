import React, { useEffect, useState } from 'react';

import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { CustomerQueryData, CustomerQueryVariables } from '../useCustomerQuery';
import { CustomerIdContext } from '../../../components/contexts/CustomerIdContext';

const DISPLAY_WARNING_STATES = ['FL', 'OK'];

export const CUSTOMER_QUERY = gql`
  query getCustomer($customerId: ID) {
    customer(id: $customerId) {
      id
      hasExceededMaxContactRate
      person {
        homeAddress {
          state {
            code
          }
        }
      }
    }
  }
`;

export function useCustomerMaxContactRateReached(customerId?: string) {
  const [exceededMaxContactRate, setExceededMaxContactRate] = useState(false);
  const [displayMaxContactRateWarning, setDisplayMaxContactRateWarning] = useState(false);

  const { data, loading, refetch } = useQuery<CustomerQueryData, CustomerQueryVariables>(CUSTOMER_QUERY, {
    variables: {
      customerId: getCustomerId(customerId),
    },
  });

  useEffect(() => {
    const stateCode = data?.customer?.person?.homeAddress?.state?.code;
    if (stateCode && DISPLAY_WARNING_STATES.includes(stateCode)) {
      setDisplayMaxContactRateWarning(true);
    } else {
      setDisplayMaxContactRateWarning(false);
    }

    if (data?.customer?.hasExceededMaxContactRate) {
      setExceededMaxContactRate(true);
    } else {
      setExceededMaxContactRate(false);
    }
  }, [data]);

  return {
    exceededMaxContactRate, loading, refetch, displayMaxContactRateWarning,
  };
}

function getCustomerId(customerId?: string) {
  return customerId ?? React.useContext(CustomerIdContext);
}
