import * as React from 'react';
import { FullHeight } from '../../layouts';
import { ErrorMessage } from './UserNotFound.elements';

export const UserNotFound: React.FunctionComponent = () => (
  <FullHeight>
    <ErrorMessage>
      User not found. Please contact IT for support.
    </ErrorMessage>
  </FullHeight>
);
