import { gql } from 'apollo-boost';
import { useMutationCustom } from '../useMutationCustom';
import {
  SendPPEMutationResponse,
  SendPPEMutationVariables,
} from './useSendPPEMutation.interfaces';
import { MutationHookOptions } from '@apollo/react-hooks';

const SEND_QUOTES_EMAIL_MUTATION = gql`
  mutation SendPPE($leadId: LeadId!, $planId: Int!, $email: String!, $firstName: String!, $lastName: String!) {
    sendPPE(leadId: $leadId, planId: $planId, email: $email, firstName: $firstName, lastName: $lastName) {
      pin
      success
      message
    }
  }
`;

export function useSendPlanProposalEmail(options: MutationHookOptions<SendPPEMutationResponse, SendPPEMutationVariables>) {
  return useMutationCustom<SendPPEMutationResponse, SendPPEMutationVariables>(SEND_QUOTES_EMAIL_MUTATION, options);
}
