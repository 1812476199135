import * as React from 'react';
import { DialerCallContextContainerProps } from './DialerCallContextContainer.interfaces';
import { DialerCallContext } from '../../../contexts';
import { useMatch } from '@reach/router';

export const DialerCallContextContainer: React.FunctionComponent<DialerCallContextContainerProps> = (props) => {
  const match = useMatch('/customer/:customerId/lead/:leadId/call/:callUrlPath/*');
  const { content } = props;
  return (
    <DialerCallContext.Provider value={match?.callUrlPath}>
      {content}
    </DialerCallContext.Provider>
  );
};
