import * as React from 'react';
import { Box } from '../Box';
import { SingleImageContainer } from './SingleImage.elements';
import { ISingleImageProps } from './SingleImage.interfaces';

export class SingleImage extends React.Component<ISingleImageProps> {
  public render() {
    return (
      <Box {...this.props}>
        <SingleImageContainer
          src={this.props.image.src}
          width={this.props.image.width}
          height={this.props.image.height}
          maxWidth={this.props.image.width}
          maxHeight={this.props.image.height}
        />
      </Box>
    );
  }
}
