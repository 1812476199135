import React from 'react';
import { useActiveLeadId } from '../../../routers/LeadRouter/useActiveLeadId';
import { useCurrentUserIsAgent, useFormAnswers } from '../../../../hooks';
import { PrimaryButton } from '../../inputs/PrimaryButton';
import { useCustomerId } from '../../../routers/CustomerRouter/useCustomerId';

import { isPresent } from '@healthiqeng/core.util.is-present';
import Alert from '@material-ui/lab/Alert/Alert';

import { useRequestHealthDataMutation } from '../../../../graphql';
import { emitAnalyticsEventNoLimit } from '../../../../events/emitAnalyticsEvent';
import { Box } from '@material-ui/core';
import { useCampaign } from './hooks/useCampain';
import { CustomerCommunicationQuestions } from '../../CustomerCommunication/CustomerCommunicationQuestions';
import { InfoBlock } from '../../ScriptBlock';
import { useCustomerDataFormState } from './hooks/useCustomerDataFormState';
import GetAppIcon from '@material-ui/icons/GetApp';

export const CheckMyRecordPullRecordButton: React.FunctionComponent = () => {
  const isAgent = useCurrentUserIsAgent();
  const leadId = useActiveLeadId();
  const trackingId = React.useMemo(() => `CheckMyHealthRecordHipaaAnswer-${Date.now()}`, []);
  const [answers, onChange, persistAnswers] = useFormAnswers(leadId, false, trackingId);
  const customerId = useCustomerId();
  const utmCampaign = useCampaign();
  const [requestHealthDataMutation, requestHealthDataMutationData] = useRequestHealthDataMutation({
    variables: { leadId, utmCampaign },
    refetchQueries: ['GetCustomerHealthRecordStatusQuery'],
  });

  const {
    includeFirstName, includeLastName, includeGender, includeDOB, includeZip, includePhone,
  } = useCustomerDataFormState();
  const hasDataToConfirm = includeFirstName || includeLastName || includeGender || includeDOB || includeZip || includePhone;

  const sendCMHRInvite = () => {
    onChange('lead.cmhrInviteSentTime', new Date().toISOString());
    persistAnswers();
    emitAnalyticsEventNoLimit('Requested Health Record', { customerId, leadId });
    requestHealthDataMutation();
  };

  React.useEffect(() => {
    if (requestHealthDataMutationData.data?.requestHealthRecords?.success) {
      onChange('lead.cmhrInviteSent', true);
      persistAnswers();
      emitAnalyticsEventNoLimit('Requested Health Record Success', { customerId, leadId });
    }
  }, [requestHealthDataMutationData.data]);

  const checkAllAnswers = (firstName: string, lastName: string, gender: string, dateOfBirth: string, zip: string, phone: string) => {
    const errors = [];

    if (!firstName || firstName === '') {
      errors.push('Please verify the first name in the verification form above');
    }

    if (!lastName || lastName === '') {
      errors.push('Please verify the last name in the verification form above');
    }

    if (!gender || gender === '') {
      errors.push('Please verify the gender in the verification form above');
    }

    if (!dateOfBirth || dateOfBirth === '') {
      errors.push('Please verify the date of birth in the verification form above');
    }

    if (!zip || zip === '') {
      errors.push('Please verify the zip in the verification form above');
    }

    if (!phone || phone === '') {
      errors.push('Please verify the phone in the verification form above');
    }

    return { hasErrors: errors.length > 0, errors };
  };
  const firstName = answers['customer.person.name.first'];
  const lastName = answers['customer.person.name.last'];
  const gender = answers['customer.person.gender'];
  const dateOfBirth = answers['customer.person.dateOfBirth'];
  const zip = answers['customer.person.homeAddress.zip'];
  const phone = answers['customer.person.phone.number'];
  const hipaaApproved = answers['customer.person.hipaaConsentApproved'];

  const { hasErrors } = React.useMemo(() => checkAllAnswers(firstName, lastName, gender, dateOfBirth, zip, phone), [
    firstName,
    lastName,
    gender,
    dateOfBirth,
    zip,
    phone,
  ]);

  const onCMHRInviteButtonClick = React.useCallback(() => sendCMHRInvite(), [sendCMHRInvite]);

  const sendDisabled = !answers['customer.person.hipaaConsentAttest'] || hasErrors;

  return (
    <>
      {hasDataToConfirm && <InfoBlock>Please verify the following information if the user consents:</InfoBlock>}
      <CustomerCommunicationQuestions
        leadId={leadId}
        formAnswers={answers}
        includeFirstName={includeFirstName}
        includeLastName={includeLastName}
        includeGender={includeGender}
        includeDOB={includeDOB}
        includeZipCode={includeZip}
        includePhone={includePhone}
        includeEmail={isAgent}
        onChange={isAgent ? onChange : undefined}
      />
      {hipaaApproved === true && (
        <>
          <Box marginY="10px">
            <PrimaryButton onClick={onCMHRInviteButtonClick} disabled={sendDisabled} variant="contained">
              <GetAppIcon />
              {' '}
&nbsp; Pull Health Record
            </PrimaryButton>
          </Box>
          {isPresent(requestHealthDataMutationData?.data?.requestHealthRecords?.success) && (
            <>
              <Alert severity={requestHealthDataMutationData?.data?.requestHealthRecords?.success ? 'success' : 'error'}>
                {requestHealthDataMutationData?.data?.requestHealthRecords?.message}
              </Alert>
            </>
          )}
        </>
      )}
    </>
  );
};
