import React from 'react';
import { TableToggleRowsSelectedProps } from 'react-table';
import { Radio } from '@material-ui/core';

export const RowSelectionRadio = React.forwardRef<HTMLInputElement, TableToggleRowsSelectedProps>(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef<HTMLInputElement>();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      (resolvedRef as React.MutableRefObject<HTMLInputElement>).current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <Radio color="secondary" ref={resolvedRef} {...rest} />
    );
  },
);
