import * as React from 'react';
import { useFormAnswersQuery } from '../../../hooks';
import { useActiveLeadId } from '../../routers/LeadRouter/useActiveLeadId';
import { usePrefillBoltMutation } from '../../../graphql';
import { PrimaryButton } from '../inputs/PrimaryButton';
import { QueryLoader } from '../QueryLoader';

export const BoltPrefill: React.FunctionComponent = () => {
  const leadId = useActiveLeadId();
  const formAnswers = useFormAnswersQuery(leadId);
  const [prefillBolt, { data, loading, error }] = usePrefillBoltMutation();
  const url: string = data?.prefillBolt?.boltReference?.url ?? formAnswers['lead.bolt.url'];

  return (
    <QueryLoader loading={false} error={error} componentName="BoltPrefill">
      { !url && (
        <PrimaryButton
          onClick={() => prefillBolt({ leadId })}
          variant="outlined"
          disabled={loading}
        >
          {loading ? 'Loading...' : 'Prefill Bolt'}
        </PrimaryButton>
      ) }
      { url && (
        <PrimaryButton
          onClick={() => window.open(url, '_blank')}
          variant="contained"
        >
          Open Quote in Bolt
        </PrimaryButton>
      ) }
    </QueryLoader>
  );
};
