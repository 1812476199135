import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps, ThemeProps } from '../../../../../../themes';

export const ActivityLayoutContainer = styled('div')(css`
  display: flex;
  flex-direction: row;
  margin: 20px 5px;
`);

export const ActivityAvatarContainer = styled('div')(css`
  width: 36px;
  margin-right: 5px;
`);

type ActivityNoteContainerProps = ComponentWithThemeProps & { isLast: boolean; };
export const ActivityNoteContainer = styled('div')<ActivityNoteContainerProps>(({ theme, isLast }) => css`
  flex: 1;
  padding-bottom: 10px;
  border-bottom: ${isLast ? 'none' : `1px solid ${theme.color.grayLight}`};
`);

type ActivityAvatarProps = ComponentWithThemeProps & { avatarColor?: keyof ThemeProps['color']; };
export const ActivityAvatar = styled('div')<ActivityAvatarProps>(({ theme, avatarColor }) => css`
  border-radius: 50%;
  font-size: 16px;
  height: 32px;
  width: 32px;
  text-align: center;
  position: relative;
  background-color: ${theme.color[avatarColor ?? 'primary']};
  color: white;
`);

export const ActivityAvatarText = styled('div')(css`
  position: relative;
  top: 5px;
`);

export const ActivityNoteHeader = styled('div')(css`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
`);

export const ActivityNoteHeaderDetail = styled('span')<ComponentWithThemeProps>(({ theme }) => css`
  color: ${theme.color.grayDark};
  margin-left: 4px;
  font-weight: 400;
  font-size: 12px;
`);

export const ActivityNoteSubHeader = styled('div')(css`
  font-size: 14px;
  font-weight: 500;
`);

export const ActivityNoteBody = styled('div')(css`
  font-size: 14px;
`);

export const ActivityNoteCheckboxContainer = styled('div')(css`
  display: flex;
  flex-direction: row;
`);

export const ActivityNoteCheckboxLabel = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  color: ${theme.color.grayDark};
  flex: 1;
  padding-top: 9px;
`);

export const ActivityNoteCheckbox = styled('div')(css`
  margin-left: -10px;
`);
