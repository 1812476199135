import styled from '@emotion/styled';
import { css } from '@emotion/core';
import Box from '@material-ui/core/Box';
import { ComponentWithThemeProps, ThemeLight } from '../../../../themes';
import { FlexibleScriptVariationType } from './FlexibleScript.interfaces';

type FlexibleScriptTextContainerProps = ComponentWithThemeProps & {
  readExactly?: boolean;
  variation: FlexibleScriptVariationType;
};

export const FlexibleScriptTextContainer = styled('div')<FlexibleScriptTextContainerProps>(
  ({ variation, theme }) => css`
    font-family: 'Roboto', sans-serif;
    color: ${getTextColor(variation)};
    margin-left: 5;
    font-size: 16px;
    font-weight: 400;
    ${(variation === FlexibleScriptVariationType.READ_FLEXIBLE_EXACT || variation === FlexibleScriptVariationType.READ_HIGHLIGHTED_EXACT)
    && ` strong {
      color: ${theme.color.darkRed};
      font-weight: bold;
    }`}
  `,
);

export const FlexibleScriptInnerContainer = styled(Box)`
  display: flex;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
`;

export const PurpleText = styled.span`
  color: ${ThemeLight.color.purpleLight};
`;

export const RedText = styled.span`
  color: ${ThemeLight.color.darkRed};
`;

export const GrayText = styled.span`
  color: gray;
`;

function getTextColor(variation: FlexibleScriptVariationType) {
  switch (variation) {
    case FlexibleScriptVariationType.READ_EXACTLY:
      return ThemeLight.color.darkRed;
    case FlexibleScriptVariationType.REBUTTAL:
      return ThemeLight.color.purpleDark;
    default:
      return 'black';
  }
}
