import * as React from 'react';
import { Icon, Link, SalesTimer } from '../../common';
import { NavBarSearch } from './NavBarSearch';
import {
  NavBarColumnContainer, NavBarContainer, NavBarTitle, NavBarCenterContainer,
} from './NavBar.elements';
import { NewLead } from './NewLead';
import { NavBarDementiaFlag } from './NavBarDementiaFlag';
import { NavBarPrecisionScore } from './NavBarPrecisionScore';
import { useMostRecentAppointmentsQuery } from '../../../graphql/queries/useMostRecentAppointmentsQuery';
import { NavBarAppointment } from './NavBarAppointment';
import { NavBarProps } from './NavBar.interfaces';
import { NavBarMenu } from './NavBarMenu';

export const NavBar: React.FunctionComponent<NavBarProps> = ({
  title,
  newLead,
  search,
  timer,
  menu,
  dementiaFlag,
  precisionScore,
}) => {
  const { loading, data: appointments } = useMostRecentAppointmentsQuery();

  return (
    <NavBarContainer id="hiq-navbar">
      <NavBarColumnContainer>
        <Link to="/"><Icon type="hiq-torch" /></Link>
        <NavBarTitle>
          { title }
        </NavBarTitle>
      </NavBarColumnContainer>
      <NavBarCenterContainer>
        { timer && <SalesTimer />}
        {dementiaFlag && <NavBarDementiaFlag />}
        {precisionScore && <NavBarPrecisionScore />}
      </NavBarCenterContainer>
      <NavBarColumnContainer>
        { !loading && (
        <NavBarAppointment
          title="Last: "
          type="last"
          appointment={appointments?.lastAgentAppointment}
        />
        )}
        { !loading && (
        <NavBarAppointment
          title="Next: "
          type="next"
          appointment={appointments?.nextAgentAppointment}
        />
        )}
        {newLead && <NewLead />}
        {search && <NavBarSearch />}
        {menu && <NavBarMenu />}
      </NavBarColumnContainer>
    </NavBarContainer>
  );
};
