import React, { FC } from 'react';
import { CustomInputElementProps } from './CustomInputElement.interfaces';
import { VehicleDriverAssociation } from '../../VehicleDriverAssociation';
import SunfireCounty from '../../SunfireCounty/SunfireCounty';

export const CustomInputElement: FC<CustomInputElementProps> = ({ referenceId, defaultRender }) => {
  switch (referenceId) {
    case 'lead.drivers.assignment':
      return <VehicleDriverAssociation key={referenceId} />;
    case 'lead.sunfire.v2.countyCode':
      return <SunfireCounty key={referenceId} label="County" />;
    default:
      return defaultRender ?? <div>{ `CUSTOM ${referenceId} input element not implemented` }</div>;
  }
};
