import { isPresent } from '@healthiqeng/core.util.is-present';
import { InputTransformer } from '../InputTransformer';

const MAX_LENGTH = 17;

export class VinTransformer extends InputTransformer<string> {
  constructor(initialValue: string) {
    super(initialValue);
    this.draftValue = initialValue;
  }

  public update(nextValue: string) {
    this.draftValue = nextValue;
    this.persistedValue = this.draftValue
      .replace('O', '0')
      .replace('Q', '0')
      .replace('I', '1')
      .substring(0, MAX_LENGTH)
      .toUpperCase();
  }

  public getDraftValue() {
    if (!isPresent(this.draftValue)) {
      return '';
    }
    return this.draftValue;
  }

  public getPersistedValue() {
    return this.persistedValue;
  }

  public getFormattedValue(): string {
    return this.getPersistedValue();
  }
}
