import { gql } from 'apollo-boost';
import { MutationCustomReturn, useMutationCustom } from '../useMutationCustom';
import {
  ChangeLeadStageMutationResponse,
  ChangeLeadStageMutationVariables,
} from './useChangeLeadStageMutation.interfaces';
import { eventBus } from '../../../services';
import { ChangeStageSuccessEvent } from '../../../events';
import { useCallback } from 'react';
import { LEAD_FRAGMENT } from '../../fragments/lead';

export const CHANGE_LEAD_STAGE_MUTATION = gql`
  mutation ChangeLeadStage($leadId: ID!, $stageInput: StageInput!) {
    changeStage(leadId: $leadId, stageInput: $stageInput) {
      code
      success
      message
      timestamp
      stage {
        stage
        subStage
      }
      lead {
        ...LeadFragment
      }
    }
  }
  ${LEAD_FRAGMENT}
`;

export function useChangeLeadStageMutation(): MutationCustomReturn<ChangeLeadStageMutationResponse, ChangeLeadStageMutationVariables> {
  const [changeStage, result] = useMutationCustom<
  ChangeLeadStageMutationResponse,
  ChangeLeadStageMutationVariables>(CHANGE_LEAD_STAGE_MUTATION);
  const changeStageWrapped = useCallback(async (variables: ChangeLeadStageMutationVariables) => {
    const response = await changeStage(variables);
    if (response?.data?.changeStage?.success) {
      eventBus.emit(new ChangeStageSuccessEvent());
    }
    return response;
  }, [changeStage]);
  return [changeStageWrapped, result];
}
