import { CompareProps } from '../TRMQuote.interfaces';
import {
  Bold, Column, CompareData, CompareRow,
} from '../TRMQuote.elements';
import React from 'react';
import { NoPlanPlaceHolder } from '../NoPlanPlaceHolder';

interface CompareScoreProps extends CompareProps {
  currentScore: number
  comparisonScore: number
}

const healthBenefitName = 'Healthy food/Produce card';
const checkSob = 'Check Summary of Benefits for details';

export const CompareScore = ({
  benefitName, currentValue, comparisonValue, currentScore, comparisonScore,
}: CompareScoreProps) => {
  const hasCheckSob = React.useMemo(() => currentValue === checkSob || comparisonValue === checkSob, [currentValue, comparisonValue]);
  if (!benefitName) return <></>;
  return (
    <CompareRow>
      <CompareData>
        <Column gridGap="0px">
          <Bold>
            {benefitName}
          </Bold>
          {benefitName === healthBenefitName && '(check client eligibility)'}
        </Column>
      </CompareData>
      <CompareData empty={currentValue === undefined} highlight={currentScore > comparisonScore && !hasCheckSob}>
        {currentValue === undefined ? <NoPlanPlaceHolder /> : currentValue}
      </CompareData>
      <CompareData highlight={comparisonScore > currentScore && !hasCheckSob}>
        {comparisonValue}
      </CompareData>
    </CompareRow>
  );
};
