import * as React from 'react';
import { Spinner } from '../../../../../../../../core/ui/src';

import { DiospositionLoaderText, DispositionSelectLoaderContainer, SpinnerContainer } from './DispostionDialogeSelect.elements';

export const WaitingToEndLoader: React.FC = () => (
  <DispositionSelectLoaderContainer>
    <SpinnerContainer><Spinner /></SpinnerContainer>
    <DiospositionLoaderText>Waiting to end the call </DiospositionLoaderText>
  </DispositionSelectLoaderContainer>
);
