import React, { FC, useState } from 'react';
import { InputValidationStatus } from '@hiq/crm.types';
import { TextInput } from '../../inputs/TextInput';
import { numberTransformerFactory } from '../../inputs/transformers';
import { VehicleDriverAssociationInputProps } from './VehicleDriverAssociationInput.interfaces';

export const VehicleDriverAssociationInput: FC<VehicleDriverAssociationInputProps> = ({
  onChange,
  driverIndex,
  vehicleIndex,
  value: originalValue,
  vehicleAssignationSum,
}) => {
  const [value, setValue] = useState<number>(originalValue);
  const handleChange = (newValue: number) => {
    onChange(driverIndex, vehicleIndex, newValue);
    setValue(newValue);
  };
  const hasError = vehicleAssignationSum !== 100 || value === 50 || value < 0;

  return (
    <TextInput
      id={Math.random().toString()}
      fullWidth
      onChange={handleChange}
      placeholder="% Assignation"
      size="small"
      suffix="%"
      transformerFactory={numberTransformerFactory}
      value={value || 0}
      validationStatus={hasError && InputValidationStatus.Error}
      dataQa="vehicle-driver-association-input"
    />
  );
};
