import * as React from 'react';
import { CircularProgress, Grow, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

export const WaitingNextCallHome: React.FunctionComponent = () => (
  <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: '100vh' }}>
    <Grid item xs={6}>
      <Grow in timeout={2000}>
        <Typography variant="h2" align="center">
          Waiting for the
          <b> next </b>
          call...
        </Typography>
      </Grow>
      <Grow in timeout={2000}>
        <div style={{ width: '100%', textAlign: 'center', marginTop: 20 }}>
          <CircularProgress size={60} thickness={5} />
        </div>
      </Grow>
    </Grid>
  </Grid>
);
