import * as React from 'react';
import { MainPanelLayout, MainPanelLayoutTabItem } from '../../../../layouts';
import { LeadPanelContainer, LeadPanelContainerCard } from '../../elements';
import { useLeadSectionRoute, useActiveLeadId } from '../../../../routers';
import { useQuestionFlowAnchorQuery } from '../../useQuestionFlowAnchorQuery';
import {
  MedicareSalesPanel,
  MedicareStatusPanel,
  MedicarePlanInformationPanel,
  MedicarePaymentPanel,
  MedicareQuotePanelFactory,
  MedicareEnrollmentPanel,
  MedicareConversionPanel,
} from '../panels';
import { ProductTypeMedicare } from '@hiq/crm.types';
import { QuestionFlow, LogPanelChange, IconProps } from '../../../../common';
import {
  useLeadQuery, useQuestionFlowQuery, useApplicationAnchorsQuery,
} from '../../../../../graphql';
import { isEmpty } from '@healthiqeng/core.util.is-empty';
import { useSectionNavigation } from '../../../../common/QuestionFlow/MultiSectionQuestionFlowRenderer/useSectionNavigation';
import { LeadInfo } from '../../common/LeadInfo';
import { useCurrentUserIsAgent } from '../../../../../hooks';

export const MedicarePostConversionLead: React.FunctionComponent = () => {
  const leadId = useActiveLeadId();
  const { data } = useLeadQuery();
  const [activeTab, setActiveTab] = useLeadSectionRoute('status');
  const enrollmentAnchors = useQuestionFlowAnchorQuery(leadId, 'status');
  const interviewAnchors = useQuestionFlowAnchorQuery(leadId, 'interview');
  const { data: applicationAnchorsData } = useApplicationAnchorsQuery(leadId);
  const { data: enrollmentFlow } = useQuestionFlowQuery(leadId, 'medicare-enrollment');
  const hasEnrollment = !isEmpty(data?.selectedQuotes) && !!enrollmentFlow?.questionFlow;

  const moveToQuoting = () => setActiveTab('quoting');
  const [currentSection, moveBackwards, moveForward] = useSectionNavigation(interviewAnchors.map(({ value }) => value), moveToQuoting);

  const applicationAnchors = applicationAnchorsData?.applicationAnchors ?? [];
  const isAgent = useCurrentUserIsAgent();

  const tabs: MainPanelLayoutTabItem[] = React.useMemo(() => {
    const memoTabs: MainPanelLayoutTabItem[] = [
      {
        id: 'lead-info',
        label: 'Lead Info',
        icon: 'person',
        content: (
          <LeadPanelContainerCard>
            <LeadInfo leadId={leadId} />
          </LeadPanelContainerCard>
        ),
      },
      {
        id: 'status',
        label: 'Status',
        icon: 'assignment-turned-in',
        anchors: enrollmentAnchors,
        content: (
          <>
            <LogPanelChange panel="status" />
            <MedicareStatusPanel leadId={leadId} />
          </>
        ),
      },
      {
        id: 'plan',
        label: 'Plan',
        icon: 'note',
        anchors: enrollmentAnchors,
        content: (
          <LeadPanelContainerCard>
            <LogPanelChange panel="plan" />
            <MedicarePlanInformationPanel leadId={leadId} />
          </LeadPanelContainerCard>
        ),
      },
      {
        id: 'sales',
        label: 'Sales',
        icon: 'ballot',
        anchors: enrollmentAnchors,
        content: (
          <>
            <LogPanelChange panel="sales" />
            <MedicareSalesPanel leadId={leadId} />
          </>
        ),
      },
      {
        id: 'quoting',
        label: 'Quote',
        icon: 'local-offer',
        content: (
          <LeadPanelContainer>
            <LogPanelChange panel="quoting" />
            <MedicareQuotePanelFactory productType={data?.productType as ProductTypeMedicare} />
          </LeadPanelContainer>
        ),
      },
      {
        id: 'payment',
        label: 'Payment & Billing',
        icon: 'dollar',
        anchors: enrollmentAnchors,
        content: (
          <>
            <LogPanelChange panel="payment" />
            <MedicarePaymentPanel leadId={leadId} />
          </>
        ),
      },
      {
        id: 'interview',
        label: 'Agent Interview',
        icon: 'assignment',
        anchors: interviewAnchors,
        content: (
          <LeadPanelContainerCard>
            <LogPanelChange panel="interview" />
            <QuestionFlow
              leadId={leadId}
              referenceId="interview"
              root
              split
              activeSectionIndex={currentSection}
              onNext={moveForward}
              onPrevious={moveBackwards}
            />
          </LeadPanelContainerCard>
        ),
      },
      ...(!hasEnrollment ? [] : [
        {
          id: 'application',
          label: applicationAnchors.length ? 'Americo Special Rate App' : 'Application',
          icon: 'ballot' as IconProps['type'],
          anchors: applicationAnchors.length ? applicationAnchors : undefined,
          content: (
            <LeadPanelContainerCard>
              <LogPanelChange panel="application" />
              <MedicareEnrollmentPanel hasQuoteSelected={!isEmpty(data?.selectedQuotes)} split={!!applicationAnchors.length} />
            </LeadPanelContainerCard>
          ),
        },
      ]),
      (!isAgent && (
        {
          id: 'enrollment-specialist',
          label: 'Enrollment Specialist',
          icon: 'ballot' as IconProps['type'],
          content: (
            <LeadPanelContainerCard>
              <QuestionFlow leadId={leadId} referenceId="enrollment-specialist-section" />
            </LeadPanelContainerCard>
          ),
        }
      )),
      (!isAgent && (
        {
          id: 'retention-specialist',
          label: 'Retention Specialist',
          icon: 'ballot' as IconProps['type'],
          content: (
            <LeadPanelContainerCard>
              <QuestionFlow leadId={leadId} referenceId="retention" allowExternal={false} />
            </LeadPanelContainerCard>
          ),
        }
      )),
      {
        id: 'conversion',
        label: 'Conversion',
        icon: 'done',
        content: data?.isSold
          ? (
            <LeadPanelContainerCard>
              Policy is converted
              <br />
              <br />
              If Enroller: Must read the closing disclaimers to the client
            </LeadPanelContainerCard>
          )
          : (
            <LeadPanelContainerCard>
              <LogPanelChange panel="conversion" />
              <MedicareConversionPanel />
            </LeadPanelContainerCard>
          ),
      },
    ];
    return memoTabs;
  }, [leadId, currentSection, hasEnrollment, isAgent, moveForward, moveBackwards]);
  return (
    <MainPanelLayout
      tabs={tabs}
      activeId={activeTab}
      onTabChange={setActiveTab}
    />
  );
};
