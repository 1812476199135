import styled from '@emotion/styled';

export const CompareRow = styled.tr`
  & td:first-child {
    padding-left: 23px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
`;
