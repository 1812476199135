import * as React from 'react';
import { FormGeneratorReadOnlyProps } from './FormGeneratorReadOnly.interfaces';
import { useFormQuery } from '../../../../graphql/queries/useFormQuery';
import { useFormAnswers } from '../../../../hooks';
import { QueryLoader } from '../../QueryLoader';
import { Question } from './Question';
import { FormGeneratorReadOnlyContainer } from './FormGeneratorReadOnly.elements';
import { ComponentName } from '../../Page/PageLoadDetector';

export const FormGeneratorReadOnly: React.FunctionComponent<FormGeneratorReadOnlyProps> = ({
  leadId,
  referenceId,
}) => {
  const { data: formStructure, error, loading } = useFormQuery(leadId, referenceId);
  const [answers] = useFormAnswers(leadId, false);

  return (
    <QueryLoader loading={loading} error={error} componentName={ComponentName.FormGeneratorReadOnly}>
      <FormGeneratorReadOnlyContainer>
        { formStructure?.items.map((formItem) => (
          <Question
            leadId={leadId}
            key={formItem.questionId}
            answers={answers}
            id={formItem.questionId}
            visibleCondition={formItem.visibleCondition}
          />
        )) }
      </FormGeneratorReadOnlyContainer>
    </QueryLoader>
  );
};
