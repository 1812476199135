import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../../../themes';

export const AppSubmissionResultContainer = styled('div')(css``);

export const AppSubmissionError = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  color: ${theme.color.error};
`);

export const AppSubmissionInfo = styled('li')(css``);

export const AppSubmissionSuccess = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  color: ${theme.color.accent};
  font-size: 16px;
  font-weight: 500;
`);
