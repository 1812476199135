import * as React from 'react';
import { ProductTypeLife } from '@hiq/crm.types';
import { RelatedCustomersProps } from './RelatedCustomers.interfaces';
import { RelatedCustomersContainer } from './RelatedCustomers.elements';
import { useCustomerId } from '../../../routers/CustomerRouter';
import {
  CustomerFragment,
  RelatedCustomerFragment,
  useCustomerQuery,
  useRelatedCustomersQuery,
} from '../../../../graphql';
import { QueryLoader } from '../../../common/QueryLoader';
import { RelatedCustomer } from './RelatedCustomer';

export const RelatedCustomers: React.FunctionComponent<RelatedCustomersProps> = () => {
  const customerId = useCustomerId();
  const { error, loading, relatedCustomers } = useRelatedCustomersQuery(customerId);
  const { data: currentCustomer } = useCustomerQuery(customerId);

  if (!relatedCustomers?.length) return <></>;
  return (
    <QueryLoader loading={loading} error={error} componentName="RelatedCustomers">
      <RelatedCustomersContainer>
        { relatedCustomers.map((customer) => (
          <RelatedCustomer
            id={customer.id}
            key={customer.id}
            relationship={getRelationship(currentCustomer?.customer, customer)}
            name={customer.person?.name?.fullFriendlyName}
          />
        ))}
      </RelatedCustomersContainer>
    </QueryLoader>
  );
};

function getRelationship(currentCustomer: CustomerFragment, relatedCustomer: RelatedCustomerFragment): string {
  if (currentCustomer?.leads?.some(({ productType }) => productType === ProductTypeLife.Juvenile)) {
    return 'Juvenile Owner';
  }
  if (relatedCustomer?.leads?.some(({ productType }) => productType === ProductTypeLife.Juvenile)) {
    return 'Juvenile';
  }
  return 'Spouse';
}
