import { ApolloError, gql } from 'apollo-boost';
import { MutationHookOptions, MutationTuple } from '@apollo/react-hooks';
import { useMutationCustom } from '../useMutationCustom';
import {
  RequestHealthRecordMutationData,
  RequestHealthRecordMutationVariables,
} from './useRequestHealthRecordMutation.interfaces';
import { getEventBus } from '../../../events';
import { useCallback } from 'react';
import { HealthRecordPullEventProperties } from '../../../events/MedDigitalAnalyticsEvent.interfaces';
import { MedDigitalAnalyticsEvent } from '../../../events/MedDigitalAnalyticsEvent';

const REQUEST_HEALTH_DATA_MUTATION = gql`
  mutation RequestHealthData($leadId: LeadId!, $utmCampaign: String!) {
    requestHealthRecords(leadId: $leadId, utmCampaign: $utmCampaign) {
      code
      success
      message
      timestamp
      requestId
    }
  }
`;

export function useRequestHealthDataMutation(
  options: MutationHookOptions<RequestHealthRecordMutationData, RequestHealthRecordMutationVariables>,
):MutationTuple<RequestHealthRecordMutationData, RequestHealthRecordMutationVariables> {
  const eventBus = getEventBus();

  const emitEvent = useCallback((message: string, properties: HealthRecordPullEventProperties) => {
    eventBus.emit(new MedDigitalAnalyticsEvent(message, properties));
  }, [eventBus]);

  const onCompleted = useCallback((data: RequestHealthRecordMutationData) => emitEvent(
    'Success',
    {
      location: 'health_record_pull',
      message: data.requestHealthRecords.message,
      success: data.requestHealthRecords.success,
    },
  ), [emitEvent]);

  const onError = useCallback((error: ApolloError) => emitEvent(
    'Error',
    {
      location: 'health_record_pull',
      error: error.message,
    },
  ), [emitEvent]);

  const [requestHealthRecord, result] = useMutationCustom<RequestHealthRecordMutationData, RequestHealthRecordMutationVariables>(
    REQUEST_HEALTH_DATA_MUTATION, { onError, onCompleted, ...options },
  );

  const requestHealthRecordWrapped = useCallback((options) => {
    emitEvent(
      'Request',
      {
        location: 'health_record_pull',
      },
    );
    return requestHealthRecord(options);
  }, [requestHealthRecord, emitEvent]);

  return [
    requestHealthRecordWrapped,
    // @ts-ignore
    result,
  ];
}
