import * as React from 'react';
import { QuestionValueEditableProps } from './QuestionValueEditable.interfaces';
import { QuestionValueEditableContainer } from './QuestionValueEditable.elements';
import { useInputFormatter } from '../../../../../inputs';

export const QuestionValueEditable: React.FunctionComponent<QuestionValueEditableProps> = ({
  value,
  type,
  onEdit,
  options,
  locked,
}) => {
  const formattedValue = useInputFormatter(value, type, options);
  const handleClick = () => {
    if (locked) return;
    if (onEdit) onEdit();
  };
  return (
    <QuestionValueEditableContainer locked={locked} onClick={handleClick}>
      <div style={{ paddingLeft: '10px' }}>
        {formattedValue}
      </div>
    </QuestionValueEditableContainer>
  );
};
