import styled from '@emotion/styled';

export const DiagnosesContainer = styled.div`
  padding: 10px;
`;

export const DiagnosesTitle = styled.h3`
  margin: 5px 0;
  font-size: 16px;
  line-height: 24px;
`;

export const DiagnosesBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 8px;
`;

export const DiagnosisHeader = styled.div`
  padding: 8px;
  border-radius: 8px 8px 0px 0px;
  background: #0075CA;
  color: white;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
`;

export const DiagnosisContentWrapper = styled.div`
  border-radius: 8px;
  border: 1px solid #B1B7BA;
  background: white;
  gap: 8px;
  margin: 0 8px;
`;

export const DiagnosisContent = styled.div`
  padding: 5px 8px;
`;

export const Bold = styled.span`
  font-weight: bold;
`;

export const Feature = styled.span`
  color: #0075CA;
`;

export const Divider = styled.hr`
  width: calc(100% - 16px);
  border: 1px solid #DEE2E5;
`;

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 4px;
  margin-left: -4px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 4px;
`;

export const ButtonContainer = styled.div`
  cursor: pointer;
`;

export const NonIcd = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #4A4A4A;
  padding: 8px;
  background: #F3F5F7;
  border-radius: 8px;
  margin: 4px 0px;
`;
