import * as React from 'react';
import { UserLeadsViewProps } from './UserLeadsView.interfaces';
import { Anchor, UserLeadsViewContainer, UserLeadsViewHeader } from './UserLeadsView.elements';
import { CardListItem } from '../../../common/CardListItem';

export const UserLeadsView: React.FunctionComponent<UserLeadsViewProps> = ({
  leads,
}) => (
  <UserLeadsViewContainer data-qa="home-customers-panel">
    <UserLeadsViewHeader>
      Customers
    </UserLeadsViewHeader>
    {leads?.map((lead) => (
      <Anchor key={lead.leadId} href={lead.url} target="_blank">
        <CardListItem
          icon="account-circle"
          subject={lead.name}
          body={(
            <>
              { lead.productType ? `${lead.industryType} | ${lead.productType}` : lead.industryType }
              <br />
              { lead.subStage ? (`${lead.stage} | ${lead.subStage}`) : lead.stage }
            </>
          )}
        />
      </Anchor>
    ))}
  </UserLeadsViewContainer>
);
