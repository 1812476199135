import * as React from 'react';
import { RebuttalAccordionProps } from './RebuttalAccordion.interfaces';
import {
  RebuttalAccordionContainer,
} from './RebuttalAccordion.elements';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';

export const RebuttalAccordion: React.FunctionComponent<RebuttalAccordionProps> = ({
  id,
  title,
  text,
}) => (
  <RebuttalAccordionContainer>
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id={id}
      >
        <h4>{ title }</h4>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          { text.split('\n').filter((str) => str).map((line) => (
            <p key={`${id}-${line}`}>
              { line }
            </p>
          )) }
        </div>
      </AccordionDetails>
    </Accordion>
  </RebuttalAccordionContainer>
);
