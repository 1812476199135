import * as React from 'react';
import { useValidForConversionQuery, useConvertLeadMutation } from '../../../../../../graphql';
import { PropertyAndCasualtyConversionHeader } from './PropertyAndCasualtyConersionPanel.elements';
import { useActiveLeadId } from '../../../../../routers/LeadRouter/useActiveLeadId';
import { useLogClick } from '../../../../../../graphql/mutations/useLogUserInteractionMutation';
import { ConversionMessage } from '../../../common';
import {
  PrimaryButton,
  QueryLoader,
} from '../../../../../common';
import { PropertyAndCasualtyLeadPanelCard } from '../../elements';
import { emitAnalyticsEvent } from '../../../../../../events/emitAnalyticsEvent/emitAnalyticsEvent';
import { useFormAnswers } from '../../../../../../hooks';

export const PropertyAndCasualtyConversionPanel: React.FunctionComponent<{ converted?: boolean;}> = ({ converted }) => {
  const { data, error, loading } = useValidForConversionQuery();
  const [convertLead, { loading: converting, data: mutationData }] = useConvertLeadMutation();
  const leadId = useActiveLeadId();
  const [answers, onChange] = useFormAnswers(leadId);
  const [logClick] = useLogClick();
  const errors = [
    ...(data?.validForConversion?.errors ?? []),
    ...(mutationData?.convertLead?.convertResult?.errors ?? []),
  ];

  const onConvertButtonClick = React.useCallback(() => {
    logClick({ control: 'Convert Button' });
    emitAnalyticsEvent('Convert Button');
    convertLead({ leadId });
  }, [logClick, convertLead]);

  if (converted) {
    return (
      <PropertyAndCasualtyLeadPanelCard>
        <PropertyAndCasualtyConversionHeader>
          Conversion Successful
        </PropertyAndCasualtyConversionHeader>
      </PropertyAndCasualtyLeadPanelCard>
    );
  }

  return (
    <QueryLoader loading={loading} error={error} componentName="PropertyAndCasualtyConversionPanel">
      <PropertyAndCasualtyLeadPanelCard>
        <PropertyAndCasualtyConversionHeader>
          { data?.validForConversion?.success ? 'Validation Passed' : 'Before You Can Convert' }
        </PropertyAndCasualtyConversionHeader>
        { errors.map((err) => (
          <ConversionMessage
            key={err.questionId || err.message}
            type="error"
            leadId={leadId}
            answers={answers}
            onChange={onChange}
            {...err}
          />
        )) }
        { data?.validForConversion?.warnings?.map((warning) => (
          <ConversionMessage
            key={warning.questionId || warning.message}
            type="warning"
            leadId={leadId}
            answers={answers}
            onChange={onChange}
            {...warning}
          />
        )) }
        <PrimaryButton
          onClick={onConvertButtonClick}
          disabled={!data?.validForConversion?.success || converting}
          variant="contained"
          size="large"
          fullWidth
        >
          { converting ? 'Converting...' : 'Convert' }
        </PrimaryButton>
      </PropertyAndCasualtyLeadPanelCard>
    </QueryLoader>
  );
};
