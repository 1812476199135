import * as React from 'react';
import { QuoteCellFactoryProps } from './QuoteCellFactory.interfaces';
import { ProductTypePropertyAndCasualty, ProductTypeMedicare, ProductTypeLife } from '@hiq/crm.types';
import { MedicareSupplementQuoteCell } from './MedicareSupplementQuoteCell';
import { MedicareAdvantageQuoteCell } from './MedicareAdvantageQuoteCell';
import { MedicareDentalQuoteCell } from './MedicareDentalQuoteCell';
import { MedicareVisionQuoteCell } from './MedicareVisionQuoteCell';
import { MedicarePrescriptionDrugPlanQuoteCell } from './MedicarePrescriptionDrugPlanQuoteCell';
import { MedicareHospitalIndemnityQuoteCell } from './MedicareHospitalIndemnityQuoteCell';
import { PropertyAndCasualtyQuoteCell } from './PropertyAndCasualtyQuoteCell';
import { LifeQuoteCell } from './LifeQuoteCell';

export const QuoteCellFactory: React.FunctionComponent<QuoteCellFactoryProps> = ({
  type,
  hash,
  selectable = true,
  onPin,
  isPinned,
}) => {
  switch (type) {
    case ProductTypeMedicare.MedicareSupplement:
      return <MedicareSupplementQuoteCell hash={hash} onPin={onPin} isPinned={isPinned} />;
    case ProductTypeMedicare.MedicareAdvantage:
      return <MedicareAdvantageQuoteCell hash={hash} onPin={onPin} isPinned={isPinned} />;
    case ProductTypeMedicare.Dental:
      return <MedicareDentalQuoteCell hash={hash} onPin={onPin} isPinned={isPinned} />;
    case ProductTypeMedicare.Vision:
      return <MedicareVisionQuoteCell hash={hash} onPin={onPin} isPinned={isPinned} />;
    case ProductTypeMedicare.PrescriptionDrug:
      return <MedicarePrescriptionDrugPlanQuoteCell hash={hash} onPin={onPin} isPinned={isPinned} />;
    case ProductTypeMedicare.HospitalIndemnity:
      return <MedicareHospitalIndemnityQuoteCell hash={hash} onPin={onPin} isPinned={isPinned} />;
    case ProductTypePropertyAndCasualty.Auto:
    case ProductTypePropertyAndCasualty.Home:
    case ProductTypePropertyAndCasualty.Renters:
    case ProductTypePropertyAndCasualty.Motorcycle:
    case ProductTypePropertyAndCasualty.Umbrella:
    case ProductTypePropertyAndCasualty.Watercraft:
    case ProductTypePropertyAndCasualty.Condo:
    case ProductTypePropertyAndCasualty.Powersports:
    case ProductTypePropertyAndCasualty.Landlord:
    case ProductTypePropertyAndCasualty.DwellingFire:
    case ProductTypePropertyAndCasualty.Earthquake:
    case ProductTypePropertyAndCasualty.Flood:
    case ProductTypePropertyAndCasualty.MobileHome:
    case ProductTypePropertyAndCasualty.InlandMarine:
    case ProductTypePropertyAndCasualty.OffRoad:
    case ProductTypePropertyAndCasualty.MotorhomeRV:
      return <PropertyAndCasualtyQuoteCell hash={hash} onPin={onPin} isPinned={isPinned} />;
    case ProductTypeLife.Accidental:
    case ProductTypeLife.Juvenile:
    case ProductTypeLife.WholeLife:
    case ProductTypeLife.CriticalIllness:
      return <LifeQuoteCell hash={hash} selectable={selectable} onPin={onPin} isPinned={isPinned} />;
    default:
      return (
        <>
          { `QuoteCellFactory not implemented for type ${type}` }
        </>
      );
  }
};
