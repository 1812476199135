import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  CurrentTransferQueryData,
  CurrentTransferVariables,
} from './useCurrentTransferQuery.interfaces';

const CURRENT_TRANSFER_QUERY = gql`
  query CurrentTransfer($dialerActivityId: DialerActivityId!, $leadId: ID!, $refreshContactData: Boolean) {
    currentTransfer(dialerActivityId: $dialerActivityId, leadId: $leadId, refreshContactData: $refreshContactData) {
      callDirection
      fromUser {
        name {
          fullFriendlyName
        }
      }
      transferNotes {
        label
        value
      }
    }
  }
`;

export function useCurrentTransferQuery(dialerActivityId: string, leadId: string) {
  return useLazyQuery<CurrentTransferQueryData, CurrentTransferVariables>(CURRENT_TRANSFER_QUERY, {
    variables: {
      dialerActivityId,
      leadId,
    },
    fetchPolicy: 'cache-and-network', // we need this for the polling, otherwise it won't update the 'called' and 'loading' state
  });
}
