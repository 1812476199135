import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { DISASTER_SEP_FRAGMENT } from '../../fragments/disasterSEP';
import {
  DisasterSEPQueryData,
  DisasterSEPQueryVariables,
  DisasterSEPQueryResponse,
} from './useDisasterSEPQuery.interfaces';

const DISASTER_SEP_QUERY = gql`
  query disasterSEP($state: String!, $county: String!) {
    getDisasterSEPItem(state: $state, county: $county) {
      ...DisasterSEPFragment
    }
  }

  ${DISASTER_SEP_FRAGMENT}
`;

type Props = {
  state: string
  county: string
};

export const useDisasterSEPQuery = (props: Props): DisasterSEPQueryResponse => {
  const result = useQuery<DisasterSEPQueryData, DisasterSEPQueryVariables>(DISASTER_SEP_QUERY, {
    variables: {
      state: props.state ?? '',
      county: props.county ?? '',
    },
    fetchPolicy: 'cache-and-network',
  });

  const getDisasterSEPItem = result?.data?.getDisasterSEPItem;

  return {
    ...result,
    data: getDisasterSEPItem ?? null,
  };
};
