import styled from '@emotion/styled';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Bold = styled.span`
  font-weight: bold;
  font-size: 20px;
`;

export const Column = styled.div`
  display: grid;
  grid-gap: 10px;
  justify-items: flex-start;
`;

export const DoctorsTitle = styled.h4`
  font-size: 14px;
  color: #4A4A4A;
  margin: 5px 0;
`;

const PlanErrorButton = withStyles({
  root: {
    height: 48,
    padding: '0 20px',
    textTransform: 'capitalize',
  },
})(Button);

export const CancelButton = withStyles({
  root: {
    background: 'white',
    border: '0.5px solid #F1F1F1CC',
    '&:hover': {
      background: 'rgba(241,241,241,0.8)',
    },
  },
})(PlanErrorButton);

export const SaveButton = withStyles({
  root: {
    background: '#0075CA',
    color: 'white',
    '&:hover': {
      background: 'rgba(0,117,202,0.8)',
    },
  },
})(PlanErrorButton);

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
