import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ThemeLight } from '../../../themes';

export const DialerTransferButtonsContainer = styled('div')(css``);

export const DialerTransferButtonsWrapper = styled('div')(css`
  display: flex;
  gap: 16px;
  background-color: ${ThemeLight.color.grayLighter};
  padding: 20px;
  margin-bottom: 10px;
`);
export const LoadingDialerContainer = styled('div')(css`
  width: 30px;
  margin-right: 10px;
`);

export const DialerTransferButtonsTextWrapper = styled('div')(css`
  flex-grow: 1;
  color: ${ThemeLight.color.grayDarker};
  display: flex;
  align-items: center;
  padding-left: 20px;
`);
