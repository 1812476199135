import { useNavigate } from '@reach/router';
import { useCustomerByExternalDataQuery } from '../../../../../graphql';
import { logger } from '../../../../../services';
import { getRedirectUrl } from '../../../../../util';

export function useSalesforceRedirect(salesforceId: string) {
  const { data, loading, error } = useCustomerByExternalDataQuery({ salesforceId });
  const navigate = useNavigate();
  if (data?.customerByExternalData) {
    const url = getRedirectUrl(data?.customerByExternalData);
    navigate(url)
      .catch((err) => logger.crit(err));
  }

  return { loading, error };
}
