export enum DispositionFive9Label {
  EmailOnly = 'Touched: Email Only',
  NotInterested = 'NRN: Not Interested',
  DoNotCall = 'NFC: DNC',
  NoContact = 'Not Contacted: No Contact',
  VoiceMail = 'Not Contacted: No VM',
  WrongNumber = 'Not Contacted: Wrong Number',
  ScheduledCallBack = 'Touched: Scheduled Call Back',
  HangUp = 'Touched: Customer Hung Up',
  Uninsurable = 'NFC: Wrong Product',
  TransferSuccess = 'Transfer: Success',
  TransferDrop = 'Transfer: Drop',
  LongHold = 'Transfer: Schedule - long hold',
  Medicaid = 'NRN: Medicaid',
  NoEnglish = 'NRN: No English',
}

export const ALLOWED_DISPOSITIONS: DispositionFive9Label[] = [
  DispositionFive9Label.NotInterested,
  DispositionFive9Label.DoNotCall,
  DispositionFive9Label.NoContact,
  DispositionFive9Label.VoiceMail,
  DispositionFive9Label.WrongNumber,
  DispositionFive9Label.ScheduledCallBack,
  DispositionFive9Label.HangUp,
  DispositionFive9Label.Uninsurable,
  DispositionFive9Label.TransferSuccess,
  DispositionFive9Label.TransferDrop,
  DispositionFive9Label.LongHold,
  DispositionFive9Label.Medicaid,
  DispositionFive9Label.NoEnglish,
];

export const DispositionLabelMap = {
  [DispositionFive9Label.EmailOnly]: 'Email Only',
  [DispositionFive9Label.NotInterested]: 'Not Interested',
  [DispositionFive9Label.DoNotCall]: 'Do Not Call',
  [DispositionFive9Label.NoContact]: 'Client not on the line',
  [DispositionFive9Label.VoiceMail]: 'Voicemail',
  [DispositionFive9Label.WrongNumber]: 'Wrong Number',
  [DispositionFive9Label.ScheduledCallBack]: 'Scheduled Call Back',
  [DispositionFive9Label.HangUp]: 'Consumer Hung Up',
  [DispositionFive9Label.Uninsurable]: 'Uninsurable',
  [DispositionFive9Label.TransferSuccess]: 'Transfer Success',
  [DispositionFive9Label.TransferDrop]: 'Transfer Drop',
  [DispositionFive9Label.LongHold]: 'Transfer Schedule Long Hold',
  [DispositionFive9Label.Medicaid]: 'NRN: Medicaid',
  [DispositionFive9Label.NoEnglish]: 'NRN: No English',
};

export enum DispositionVisibility {
  Always = 'ALWAYS',
  BeforeTransferInitiated = 'BEFORE_TRANSFER_INITIATED',
  AfterTransferInitiated = 'AFTER_TRANSFER_INITIATED',
}

export type DispositionRules = {
  [key: string]: {
    visibility: DispositionVisibility,
  }
};

export const DISPOSITION_RULES: DispositionRules = {
  [DispositionFive9Label.EmailOnly]: {
    visibility: DispositionVisibility.Always,
  },
  [DispositionFive9Label.NotInterested]: {
    visibility: DispositionVisibility.Always,
  },
  [DispositionFive9Label.DoNotCall]: {
    visibility: DispositionVisibility.Always,
  },
  [DispositionFive9Label.HangUp]: {
    visibility: DispositionVisibility.Always,
  },
  [DispositionFive9Label.NoContact]: {
    visibility: DispositionVisibility.Always,
  },
  [DispositionFive9Label.VoiceMail]: {
    visibility: DispositionVisibility.BeforeTransferInitiated,
  },
  [DispositionFive9Label.WrongNumber]: {
    visibility: DispositionVisibility.BeforeTransferInitiated,
  },
  [DispositionFive9Label.ScheduledCallBack]: {
    visibility: DispositionVisibility.BeforeTransferInitiated,
  },
  [DispositionFive9Label.Uninsurable]: {
    visibility: DispositionVisibility.BeforeTransferInitiated,
  },
  [DispositionFive9Label.TransferDrop]: {
    visibility: DispositionVisibility.AfterTransferInitiated,
  },
  [DispositionFive9Label.LongHold]: {
    visibility: DispositionVisibility.AfterTransferInitiated,
  },
  [DispositionFive9Label.Medicaid]: {
    visibility: DispositionVisibility.Always,
  },
  [DispositionFive9Label.NoEnglish]: {
    visibility: DispositionVisibility.Always,
  },
};
