import React from 'react';
import { useCustomerQuery } from '../../../../graphql/queries/useCustomerQuery';
import { useAddNumbersToDoNotCall } from '../../../../graphql/mutations/useAddNumbersToDoNotCallMutation';
import { useGetLeadDNCPhoneNumbers } from '../../DNCPhoneNumberEditor';
import { DialerDispositionButton, DialerDispositionButtonProps } from './DialerDispositionButton';
import { useCustomerId } from '../../../routers';
import { DNCModal } from './DNCModal';

export const DialerDispositionButtonDNC: React.FC<DialerDispositionButtonProps> = ({
  disabled,
  value,
  onClick,
  label,
}) => {
  const [dncPhoneNumbers] = useGetLeadDNCPhoneNumbers();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [addNumbers] = useAddNumbersToDoNotCall({});
  const customerId = useCustomerId();
  const { data } = useCustomerQuery(customerId);
  const leadState = data?.customer?.person?.homeAddress?.state?.code;

  function openModal() {
    setModalOpen(true);
  }

  function closeModal() {
    setModalOpen(false);
  }

  async function handleSave() {
    await addNumbers({ input: { phoneNumbers: dncPhoneNumbers } });
    onClick();
  }

  return (
    <>
      <DialerDispositionButton
        disabled={disabled}
        value={value}
        label={label}
        onClick={openModal}
      />
      <DNCModal
        open={modalOpen}
        onClose={closeModal}
        onClickSave={handleSave}
        stateDisclaimerText={getStateDoNotCallDisclaimerText(leadState)}
      />
    </>
  );
};

function getStateDoNotCallDisclaimerText(state: string): string | null {
  switch (state) {
    case 'WA':
      return 'Per WA Law, your contact information will be removed from Health IQ call lists for at least one year. '
          + 'Can you confirm which numbers you would like added in our Do Not Call list?';
    default:
      return null;
  }
}
