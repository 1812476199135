import { isPresent } from '@healthiqeng/core.util.is-present';
import { InputTransformer } from '../InputTransformer';

export class SsnTransformer extends InputTransformer<string> {
  constructor(initialValue: string) {
    super(initialValue);
    this.draftValue = initialValue;
  }

  public update(nextValue: string) {
    this.updateDraftValue(nextValue);
    this.updatePersistedValue();
  }

  public getDraftValue(): string {
    return formatSsn(this.draftValue);
  }

  public getPersistedValue(): string {
    return this.persistedValue;
  }

  public getFormattedValue(): string {
    return formatSsn(this.getPersistedValue());
  }

  private updateDraftValue(nextValue: string) {
    this.draftValue = SsnTransformer.normalizeSsn(nextValue);
  }

  private updatePersistedValue() {
    if ((this.draftValue ?? '') === '') {
      this.persistedValue = null;
    }
    if (/^[0-9]{9}$/.test(this.draftValue ?? '')) {
      this.persistedValue = this.draftValue;
    }
  }

  public static normalizeSsn(value: string): string {
    if (!isPresent(value)) {
      return '';
    }

    const cleanedSsn = value.replace(/[^0-9]/g, '');
    return cleanedSsn.substring(0, 9);
  }
}

function formatSsn(value: string): string {
  const normalized = SsnTransformer.normalizeSsn(value);
  if (normalized.length < 9) {
    return normalized;
  }
  return `${normalized.substring(0, 3)}-${normalized.substring(3, 5)}-${normalized.substring(5, 9)}`;
}
