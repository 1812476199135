import * as React from 'react';
import { AppStatus } from '@hiq/crm.types';
import { AppSubmissionResultProps } from './AppSubmissionResult.interfaces';
import {
  AppSubmissionResultContainer,
  AppSubmissionError, AppSubmissionInfo, AppSubmissionSuccess,
} from './AppSubmissionResult.elements';

export const AppSubmissionResult: React.FunctionComponent<AppSubmissionResultProps> = ({
  errors,
  createdDate,
  policyNumber,
  appStatus,
}) => (
  <AppSubmissionResultContainer>
    { errors?.map((message) => (
      <AppSubmissionError key={message}>
        { message }
      </AppSubmissionError>
    )) }
    { policyNumber && (
      <div>
        <AppSubmissionSuccess>
          Success
        </AppSubmissionSuccess>
        <ul>
          <AppSubmissionInfo>
            { `Policy Number: ${policyNumber}` }
          </AppSubmissionInfo>
          <AppSubmissionInfo>
            { `Submitted at ${createdDate}` }
          </AppSubmissionInfo>
          {appStatus === AppStatus.NotTaken && <AppSubmissionInfo>Submitted as withdrawn</AppSubmissionInfo>}
        </ul>
      </div>
    ) }
  </AppSubmissionResultContainer>
);
