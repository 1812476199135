import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../../themes';

export const OAuthAppsContainer = styled('div')(css`
  display: flex;
  flex-direction: row;
  justify-content: center;
`);

export const OAuthAppCard = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  margin: 10px;
  padding: 10px;
  background-color: ${theme.color.background};
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  
  transition: all 200ms;
  
  &:hover {
    transform: scale(1.1);
  }
`);

export const OAuthAppLink = styled('a')<ComponentWithThemeProps>(({ theme }) => css`
  text-decoration: none;
  color: ${theme.color.black};
  font-weight: 600;
`);

export const OAuthAppIcon = styled('img')(css`
  width: 48px;
  margin: 0 auto;
  display: block;
`);

export const OAuthAppLabel = styled('div')(css`
  margin-top: 15px;
`);
