import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  SunfireV2FindPharmaciesByTermQueryData,
  SunfireV2FindPharmaciesByTermQueryVariables,
} from './useSunfireV2PharmaciesByTermQuery.interfaces';

const SUNFIRE_PHARMACIES_QUERY = gql`
  query FindSunfireV2PharmaciesByTerm($term: String!,  $leadId: ID!,$zipcode: String) {
    findSunfirePharmaciesByTerm(term: $term, leadId: $leadId, zipcode: $zipcode) {
      id
      name
      address1
      address2
      city
      state
      zip
      phone
      distance
      latitude
      longitude
    }
  }
`;

export function useSunfireV2PharmaciesByTermQuery() {
  return useLazyQuery<SunfireV2FindPharmaciesByTermQueryData, SunfireV2FindPharmaciesByTermQueryVariables>(SUNFIRE_PHARMACIES_QUERY);
}
