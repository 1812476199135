import {
  MedicareQuoteCreatePropertiesUnion,
  ProductTypeMedicare,
} from '@hiq/crm.types';
import { useActiveLeadId } from '../../../../routers';
import { useCarrierOptions } from '../useCarrierOptions';
import { useMedicareQuoteMutation } from '../../../../../graphql';
import { useExternalQuoteInput } from './useExternalQuoteInput';

interface MedicareQuoteBaseProperties {
  type: ProductTypeMedicare;
}

type ExternalQuoteState<T> = Partial<T> & MedicareQuoteBaseProperties;

export function useExternalQuote<T>(
  defaults: ExternalQuoteState<T>,
) {
  const leadId = useActiveLeadId();
  const { carrierOptions, loading: loadingCarriers, error: carrierQueryError } = useCarrierOptions();
  const [addQuote, { loading, error }] = useMedicareQuoteMutation();
  const [quoteInput, setQuoteInput] = useExternalQuoteInput<ExternalQuoteState<T>>(defaults);
  const submitQuote = () => addQuote({
    leadId,
    properties: quoteInput as any as MedicareQuoteCreatePropertiesUnion,
  });

  return {
    quoteInput,
    setQuoteInput,
    carrierOptions,
    submitQuote,
    loading: loading || loadingCarriers,
    error: carrierQueryError || error,
  };
}
