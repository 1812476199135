import { gql } from 'apollo-boost';
import { useCallback } from 'react';
import { MutationCustomReturn, useMutationCustom } from '../useMutationCustom';
import { CONVERT_RESULT_FRAGMENT } from '../../fragments';
import { ConvertLeadMutationResponse, ConvertLeadMutationVariables } from './useConvertLeadMutation.interfaces';
import { eventBus } from '../../../services';
import { ConvertBeginEvent, ConvertFailedEvent, ConvertSuccessEvent } from './events';

const CONVERT_LEAD_MUTATION = gql`
  mutation ConvertLead($leadId: ID!) {
    convertLead(leadId: $leadId) {
      code
      success
      message
      timestamp
      type
      convertResult {
        ...ConvertResultFragment
      }
    }
  }
  ${CONVERT_RESULT_FRAGMENT}
`;

export function useConvertLeadMutation(): MutationCustomReturn<ConvertLeadMutationResponse, ConvertLeadMutationVariables> {
  const [convertLead, result] = useMutationCustom<
  ConvertLeadMutationResponse,
  ConvertLeadMutationVariables
  >(CONVERT_LEAD_MUTATION);
  const convertLeadWrapped = useCallback(async (variables: ConvertLeadMutationVariables) => {
    eventBus.emit(new ConvertBeginEvent());
    const response = await convertLead(variables);
    if (response?.data?.convertLead?.success) {
      eventBus.emit(new ConvertSuccessEvent());
    } else {
      eventBus.emit(new ConvertFailedEvent());
    }
    return response;
  }, [convertLead]);
  return [convertLeadWrapped, result];
}
