import * as React from 'react';
import Tabs from '@material-ui/core/Tabs';
import { MainPanelLayoutProps, MainPanelLayoutTabItem } from './MainPanelLayout.interfaces';
import {
  MainPanelLayoutContainer,
  ContentContainer,
  TabColumnContainer,
  ContentColumnContainer,
} from './MainPanelLayout.elements';
import { noop } from '../../../util/noop';
import { MainPanelTab } from './MainPanelTab';
import { useEventListener } from '../../../events/useEventListener';
import { PanelChangeEvent } from '../../../events';
import { ResizableColumns } from '../ResizableColumns';

const MainPanelLayoutInner: React.FunctionComponent<MainPanelLayoutProps> = ({
  tabs,
  activeId,
  onTabChange,
  tabColumnVerticalOverflow,
  tabColumnHeight,
}) => {
  const contentColumnContainerRef = React.useRef<HTMLDivElement>(null);
  useEventListener(PanelChangeEvent, () => {
    if (contentColumnContainerRef.current) {
      contentColumnContainerRef.current.scrollTo(0, 0);
    }
  });
  return (
    <MainPanelLayoutContainer>
      <ResizableColumns
        navBarPresent
        initialColumnWidths={['260px', 'auto']}
        height={tabColumnHeight || 'calc(100vh - 100px)'}
        collapsableColumnIndexes={[0]}
        columnVerticalOverflow={tabColumnVerticalOverflow}
        columnContents={[
          (
            <TabColumnContainer key="Navigation" data-qa="main-page-layout-tabs">
              <Tabs
                orientation="vertical"
                value={getActiveIndex(tabs, activeId)}
                onChange={noop}
              >
                { tabs.map(({
                  id, label, icon, anchors,
                }) => (
                  <MainPanelTab
                    key={id}
                    id={id}
                    label={label}
                    icon={icon}
                    onClick={onTabChange}
                    active={activeId === id}
                    anchors={anchors}
                  />
                )) }
              </Tabs>
            </TabColumnContainer>
          ),
          (
            <ContentColumnContainer ref={contentColumnContainerRef}>
              { tabs.map(({ id, content }) => (
                <ContentContainer
                  key={id}
                  role="tabpanel"
                  hidden={activeId !== id}
                  data-qa={`main-page-layout-tab-content-${id}`}
                >
                  { activeId === id && content }
                </ContentContainer>
              )) }
            </ContentColumnContainer>
          ),
        ]}
      />

    </MainPanelLayoutContainer>
  );
};

export const MainPanelLayout = React.memo(MainPanelLayoutInner);

function getActiveIndex(tabs: MainPanelLayoutTabItem[], activeId: string): number {
  const index = tabs.map((tab) => tab.id).indexOf(activeId);
  return Math.max(index, 0);
}
