import withStyles from '@material-ui/core/styles/withStyles';
import Tab from '@material-ui/core/Tab';

export const StyledTab = withStyles({
  root: {
    minWidth: 80,
  },
  wrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
})(Tab);
