import React from 'react';
import { QuestionContainer, QuestionContent } from './ValidateAdditionalDoctors.elements';
import { useFormAnswers } from '../../../../../hooks';
import { QuestionView } from '../../../FormGenerator/FormGeneratorQuestionFlow/Question/QuestionView';
import { QuestionType } from '@hiq/crm.types';
import { useActiveLeadId } from '../../../../routers';

export const ValidateAdditionalDoctors = () => {
  const validateAdditionalDoctorsQuestionId = 'lead.sunfire.v2.validateAdditionalDoctors';
  const leadId = useActiveLeadId();
  const [answers, onChange] = useFormAnswers(leadId, true);

  const updateAnswer = React.useCallback((value) => {
    onChange(validateAdditionalDoctorsQuestionId, value);
  }, [onChange, validateAdditionalDoctorsQuestionId]);

  return (
    <QuestionContainer>
      <QuestionContent>
        <QuestionView
          id={validateAdditionalDoctorsQuestionId}
          questionText="Are there any other doctors that I have not mentioned that you want to make sure are covered under your plan"
          label="Select Yes or No"
          type={QuestionType.RadioYesNo}
          leadId={leadId}
          value={answers[validateAdditionalDoctorsQuestionId]}
          onChange={updateAnswer}
        />
      </QuestionContent>
    </QuestionContainer>
  );
};
