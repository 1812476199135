import React, { useState } from 'react';
import { useUpsertOverlayMutation } from '../../../../../../graphql';
import { useActiveLeadId, useCustomerId } from '../../../../../routers';
import {
  CTA,
  PanelInnerWrapper,
  RemoveItemButton,
  SaveItemButton,
  SectionTitle,
} from '../../CheckMyHealthRecordDataGrid.elements';
import { AddPharmacyPanelProps, Pharmacy } from './AddPharmacyPanel.interfaces';
import { AddressFromZip, LocationSearchPanel } from '../../LocationSearchPanel';
import { AddPharmacyPanelContainer } from './AddPharmacyPanel.elements';
import { PharmacySearchPanel } from '../PharmacySearchPanel';
import { HealthRecordPharmacy } from '@hiq/crm.types';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { emitAnalyticsEventNoLimit } from '../../../../../../events/emitAnalyticsEvent';

export const AddPharmacyPanel = ({ setPanelClosed, easyPharmacyZip }: AddPharmacyPanelProps) => {
  const [upsertOverlayPharmacy] = useUpsertOverlayMutation();
  const customerId = useCustomerId();
  const leadId = useActiveLeadId();
  const onCancel = () => setPanelClosed();
  const [location, setLocation] = useState<AddressFromZip | undefined>(undefined);
  const [pharmacy, setPharmacy] = useState<Pharmacy | undefined>(undefined);

  const onSave = () => {
    const pharmacyToInsert: HealthRecordPharmacy = {
      id: null,
      name: pharmacy.name,
      addressLine1: pharmacy.address1,
      addressLine2: pharmacy.address2,
      city: pharmacy.city,
      state: pharmacy.state,
      zip: pharmacy.zip,
      npi: pharmacy.id.toString(),
      fillsLastYear: null,
    };

    upsertOverlayPharmacy({
      input: {
        customerId,
        externalId: pharmacy.id.toString(),
        externalType: 'HealthRecordPharmacy',
        data: pharmacyToInsert,
      },
    });
    emitAnalyticsEventNoLimit('New Pharmacy Added', { ...pharmacyToInsert, customerId, leadId });
    setPanelClosed();
  };

  const handleOnLocationChange = (location: AddressFromZip | undefined) => setLocation(location);
  const handleOnPharmacyChanged = (pharmacy: Pharmacy | undefined) => setPharmacy(pharmacy);

  const isSaveDisabled = pharmacy === undefined;

  return (
    <AddPharmacyPanelContainer>
      <SectionTitle>
        Add Pharmacy
      </SectionTitle>
      <PanelInnerWrapper>
        {
          location === undefined
          && <LocationSearchPanel onLocationChanged={handleOnLocationChange} type="pharmacy" zipcode={easyPharmacyZip} />
        }
        {
          location !== undefined
          && (
            <PharmacySearchPanel
              onPharmacyChanged={handleOnPharmacyChanged}
              onLocationChanged={handleOnLocationChange}
              location={location}
            />
          )
        }
      </PanelInnerWrapper>
      <CTA>
        <RemoveItemButton variant="contained" startIcon={<CloseIcon />} onClick={onCancel}>
          Cancel
        </RemoveItemButton>
        <SaveItemButton variant="contained" startIcon={<CheckIcon />} onClick={onSave} disabled={isSaveDisabled}>
          Save
        </SaveItemButton>
      </CTA>
    </AddPharmacyPanelContainer>
  );
};
