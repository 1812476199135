import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { QueryResult } from '@apollo/react-common';
import {
  LatestUnderwritingResultQueryResponse,
  LatestUnderwritingResultQueryVariables,
} from './useLatestUnderwritingResultQuery.interfaces';

export const LATEST_UNDERWRITING_RESULT_QUERY = gql`
    query FetchLatestUnderwritingResult($leadId: ID!) {
      fetchLatestUnderwritingResults(leadId: $leadId) {
        result {
          decision
          appliedHouseholdDiscount
          appliedTobacco
          approved
          plan
          approvedPremium
        }
      }
    }
`;

export function useLatestUnderwritingResultQuery(leadId: string): QueryResult<
LatestUnderwritingResultQueryResponse,
LatestUnderwritingResultQueryVariables
> {
  // @ts-ignore
  return useQuery<
  LatestUnderwritingResultQueryResponse,
  LatestUnderwritingResultQueryVariables
  >(LATEST_UNDERWRITING_RESULT_QUERY, {
    variables: {
      leadId,
    },
  });
}
