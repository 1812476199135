import * as React from 'react';
import { ButtonContainer } from '../FileUploader.elements';
import { Button, CircularProgress } from '@material-ui/core';
import { FileUploadButtonProps } from './FileUploadButton.interfaces';
import { ChangeEvent } from 'react';

export const FileUploadButtons: React.FunctionComponent<FileUploadButtonProps> = ({
  isUploadDisabled,
  isUploading,
  onFileInputChange,
  onUploadButtonClick,
}) => {
  const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onFileInputChange(e.target.files[0]);
    e.target.value = '';
  };
  return (
    <>
      <ButtonContainer>
        <Button color="primary" component="label" disabled={isUploading}>
          Select File
          <input
            type="file"
            style={{ display: 'none' }}
            onChange={handleFileInputChange}
          />
        </Button>
      </ButtonContainer>
      <ButtonContainer>
        <Button color="primary" variant="contained" disabled={isUploadDisabled} onClick={onUploadButtonClick}>
          { isUploading ? 'Uploading...' : 'Upload' }
          { isUploading && <CircularProgress size={16} style={{ marginLeft: 10 }} /> }
        </Button>
      </ButtonContainer>
    </>
  );
};
