import * as React from 'react';
import { CarrierLink, CarrierLinkButton, FinalQuoteChipContainer } from './PropertyAndCasualtyQuoteCell.elements';
import { PropertyAndCasualtyQuoteCellProps } from './PropertyAndCasualtyQuoteCell.interfaces';
import { useQuoteCell } from '../useQuoteCell';
import { QueryLoader } from '../../../QueryLoader';
import { QuoteCellLayout } from '../QuoteCellLayout';
import { QuoteCarrierInfo } from '../QuoteCarrierInfo';
import { QuoteDetails } from '../QuoteDetails';
import { QuoteRates } from '../QuoteRates';
import { QuoteSelectButton } from '../QuoteSelectButton';
import {
  PropertyAndCasualtyPolicyTermLength,
  PropertyAndCasualtyPolicyTermLengthLabelMap,
  QuotePresentationType,
} from '@hiq/crm.types';
import { isPresent } from '@healthiqeng/core.util.is-present';
import { formatDate } from '@healthiqeng/core.util.format-date';
import { QuoteFragment } from '../../../../../graphql';
import { Icon } from '../../../Icon';
import Chip from '@material-ui/core/Chip';
import { useConfirmedFinalQuote } from '../../useConfirmedFinalQuote';
import { QuotePin } from '../QuotePin';

export const PropertyAndCasualtyQuoteCell: React.FunctionComponent<PropertyAndCasualtyQuoteCellProps> = ({
  hash,
  onPin,
  isPinned,
}) => {
  const {
    quote,
    handleSelectClick,
    isSelected,
    loading,
    error,
  } = useQuoteCell(hash);

  const handleCarrierLinkClick = () => {};
  const [confirmed, toggleFinalQuoteClick] = useConfirmedFinalQuote();
  const onSelectClick = () => {
    if (confirmed && isSelected) toggleFinalQuoteClick();
    handleSelectClick();
  };

  return (
    <QueryLoader loading={loading} error={error} componentName="PropertyAndCasualtyQuoteCell">
      <QuoteCellLayout
        pinColumn={onPin && <QuotePin onPin={onPin} isPinned={isPinned} />}
        carrierColumn={(
          <QuoteCarrierInfo
            companyName={quote?.product?.carrier?.name}
          />
        )}
        detailColumn={(
          <QuoteDetails
            items={[
              { label: 'Policy Duration', value: formatPolicyDuration(quote?.policyTermLength as any) },
              { label: 'Effective Date', value: formatDate(quote?.effectiveDate) },
              { label: 'Quote Type', value: quote?.product?.tweakType?.type },
            ]}
          />
        )}
        premiumColumn={(
          <QuoteRates
            rateMonthly={quote?.premium?.monthly?.value}
            rateAnnually={getAnnualRate(quote)}
            rateQuarterly={quote?.premium?.quarterly?.value}
            rateSemiAnnually={quote?.premium?.semiAnnually?.value}
            labels={{ annual: ' for 1 year', semiAnnually: ' for 6 months' }}
          />
        )}
        selectColumn={(
          <>
            <QuoteSelectButton
              allowSelect={quote?.presentationType !== QuotePresentationType.Competition}
              isSelected={isSelected}
              onClick={onSelectClick}
            />
            { isPresent(quote?.carrierLink) && (
              <CarrierLinkButton variant="outlined" onClick={handleCarrierLinkClick} fullWidth>
                <CarrierLink
                  href={quote?.carrierLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  Bind Carrier
                </CarrierLink>
              </CarrierLinkButton>
            )}
            { isSelected && (
              <FinalQuoteChipContainer>
                <FinalQuoteChip
                  confirmedFinalQuote={confirmed}
                  onClick={toggleFinalQuoteClick}
                />
              </FinalQuoteChipContainer>
            )}
          </>
        )}
      />
    </QueryLoader>
  );
};

function formatPolicyDuration(policyTermLength: PropertyAndCasualtyPolicyTermLength): string {
  if (policyTermLength in PropertyAndCasualtyPolicyTermLengthLabelMap) {
    return PropertyAndCasualtyPolicyTermLengthLabelMap[policyTermLength];
  }
  return policyTermLength;
}

function getAnnualRate(quote: QuoteFragment): number {
  if (quote?.policyTermLength === PropertyAndCasualtyPolicyTermLength.SixMonths) {
    const semiAnnualRate = quote?.premium?.semiAnnually?.value;
    return isPresent(semiAnnualRate) ? semiAnnualRate * 2 : null;
  }
  return quote?.premium?.annually?.value;
}

interface FinalQuoteChipProps {
  confirmedFinalQuote: boolean;
  onClick: () => void;
}

const FinalQuoteChip: React.FunctionComponent<FinalQuoteChipProps> = ({
  confirmedFinalQuote,
  onClick,
}) => (confirmedFinalQuote ? (
  <Chip
    clickable
    color="default"
    variant="outlined"
    size="small"
    label="Final Quote"
    onDelete={onClick}
  />
) : (
  <Chip
    clickable
    color="primary"
    variant="outlined"
    size="small"
    icon={<Icon type="add" />}
    label="Mark as Final Quote"
    onClick={onClick}
  />
));
