import {
  AppSubmissionStatus,
  AutomatedUnderwritingResultStatus,
  FormItemTag,
  UserRole,
} from '@hiq/crm.types';
import moment from 'moment';
import * as React from 'react';
import { AppSubmissionProps } from './AppSubmission.interfaces';
import { AppSubmissionContainer } from './AppSubmission.elements';
import {
  useAppSubmissionMutation, useCurrentUserQuery, useLeadQuery, useValidForTagQuery,
} from '../../../../graphql';
import { QueryLoader } from '../../QueryLoader';
import { AppSubmissionResult } from './AppSubmissionResult';
import { SubmitApp } from './SubmitApp';
import { UnderwritingClassGeneric } from '@healthiqeng/core.types';
import { useFormAnswers } from '../../../../hooks';

export const AppSubmission: React.FunctionComponent<AppSubmissionProps> = ({
  dataQa,
}) => {
  const {
    data: validationData,
    loading: validationLoading,
    error: validationError,
  } = useValidForTagQuery(FormItemTag.RequiredForAppSubmission);
  const [submitApp, {
    data: appSubmissionData,
    loading: appSubmissionLoading,
    error: appSubmissionError,
  }] = useAppSubmissionMutation();
  const {
    data: leadData,
    loading: leadLoading,
    error: leadError,
  } = useLeadQuery();
  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useCurrentUserQuery();
  const [answers] = useFormAnswers();

  const selectedQuoteHash = leadData?.selectedQuote?.hash;
  const appSubmission = leadData?.appSubmissions
    ?.find(({ quoteHash, status }) => quoteHash === selectedQuoteHash && (!status || status === AppSubmissionStatus.Success));
  const underwritingResult = leadData?.automatedUnderwritingResults
    ?.find(({ quoteHash, status }) => quoteHash === selectedQuoteHash && status === AutomatedUnderwritingResultStatus.Success);

  const loading = validationLoading || appSubmissionLoading || leadLoading || userLoading;
  const error = validationError || appSubmissionError || leadError || userError;
  const isDeclined = underwritingResult?.result === UnderwritingClassGeneric.Decline;

  const canSubmitApp = userData?.currentUser?.roles?.includes(UserRole.SeniorLifeManager);
  const hasInvalidPolicyEffectiveDate = !isValidDate(leadData?.policyEffectiveDate);
  const hasInvalidDraftDate = !isValidDate(answers['lead.bankers.initialPremiumPaymentDate']);
  const dateHelpText = 'The date cannot be earlier than yesterday and not further in the future than today + 30 days'
    + ' (as long as not on 29th, 30th, 31st).';
  return (
    <QueryLoader loading={loading} error={error} componentName="AppSubmission">
      <AppSubmissionContainer data-qa={dataQa}>
        { (appSubmission || appSubmissionData?.submitApp?.errors) && (
          <AppSubmissionResult
            errors={appSubmissionData?.submitApp?.errors}
            createdDate={appSubmission?.createdDate}
            policyNumber={appSubmission?.policyNumber}
            appStatus={appSubmission?.appStatus}
          />
        ) }
        { !appSubmission && (
          <SubmitApp
            onSubmitApp={() => submitApp()}
            loading={loading}
            validationSuccess={
              validationData?.validForTag?.success
              && !!underwritingResult
              && canSubmitApp
              && !isDeclined
            }
            validationErrors={[
              ...(appSubmissionData?.submitApp?.errors?.map((message) => ({ message })) ?? []),
              ...(validationData?.validForTag?.errors ?? []),
              ...(underwritingResult ? [] : [{ message: 'Automated Underwriting is Required' }]),
              ...(canSubmitApp ? [] : [{ message: 'Must be a manager to submit the application' }]),
              ...(!isDeclined ? [] : [{ message: 'Applicant was declined' }]),
              ...(!hasInvalidPolicyEffectiveDate ? [] : [{ message: `Invalid policy effective date. ${dateHelpText}` }]),
              ...(!hasInvalidDraftDate ? [] : [{ message: `Initial draft date. ${dateHelpText}` }]),
            ]}
          />
        ) }
      </AppSubmissionContainer>
    </QueryLoader>
  );
};

function isValidDate(dateAsString: string) {
  if (!dateAsString) return false;
  const date = new Date(dateAsString);

  // Can't be 29th, 30th or 31st
  if (date.getDate() > 28) return false;

  // Can't be earlier than yesterday and not further in the future than today + 30 days
  const momentDate = moment(date);
  if (momentDate.isBefore(moment().subtract(1, 'day').startOf('day'))
    || momentDate.isAfter(moment().add(30, 'days').startOf('day'))) return false;

  return true;
}
