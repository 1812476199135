import React from 'react';
import { useActiveLeadId } from '../../../routers/LeadRouter/useActiveLeadId';
import { useFormAnswers } from '../../../../hooks';
// import { useDialerService } from '@healthiqeng/core.hooks.use-dialer-service';
// import { SpeedDialName } from '@healthiqeng/core.services.dialer.base';
// import { useLeadQuery } from '../../../../graphql/queries/useLeadQuery';
import { PrimaryButton } from '../../inputs';
// import { emitAnalyticsEventNoLimit } from '../../../../events/emitAnalyticsEvent';
// import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { QueryLoader } from '../../QueryLoader';

const HIPAA_PLAYBACK_KEY = 'lead.startedCMHRHipaaPlayback';

export const CheckMyHealthRecordPlaybackHipaaRecordingButton: React.FunctionComponent = () => {
  const leadId = useActiveLeadId();
  const trackingId = React.useMemo(() => `CheckMyHealthRecordPlaybackHipaaRecording-${Date.now()}`, []);
  const [answers, onChange, persistAnswers] = useFormAnswers(leadId, false, trackingId);
  const pressedHipaaBefore = answers[HIPAA_PLAYBACK_KEY];
  const [playing, setPlaying] = React.useState<boolean>(false);

  const [serviceError] = React.useState<Error>();

  // const { loading, error } = useDialerService();
  // const lead = useLeadQuery();

  const handleInitiateRecording = async () => {
    // try {
    //   const speedDial = SpeedDialName.HipaaCMHR;
    //   await dialerService.initiateConference(speedDial);
    //   emitAnalyticsEventNoLimit('Initiated Hipaa Consent Recording', { campaignName: lead?.data?.campaignName });
    // } catch (err) {
    //   setServiceError(err);
    // }

    onChange(HIPAA_PLAYBACK_KEY, true);
    onChange('lead.cmhrHipaaPlaybackStartTime', new Date().toISOString());
    persistAnswers();
    setPlaying(true);
    setTimeout(() => {
      setPlaying(false);
    }, 2000);
  };

  return (
    <QueryLoader loading={false} error={serviceError} componentName="Five9 Dialer Service CMHR Health Record Playback">
      <PrimaryButton onClick={() => handleInitiateRecording()} variant="contained" disabled={playing}>
        {pressedHipaaBefore ? 'Continue' : 'Continue'}
        &nbsp;Recording
      </PrimaryButton>
    </QueryLoader>
  );
};
