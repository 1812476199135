import styled from '@emotion/styled';
import { css } from '@emotion/core';

export const ButtonContainer = styled('div')(css`
  display: inline-block;
  padding-right: 20px;
`);

export const RemoveFileContainer = styled('div')(css`
  padding: 10px 0;
  text-align: right;
`);
