import * as React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { CONVERT_RESULT_FRAGMENT } from '../../fragments/convertResult';
import { ValidForConversionQueryData, ValidForConversionQueryVariables } from './useValidForConversionQuery.interfaces';
import { LeadIdContext } from '../../../components/contexts/LeadIdContext';
import { useEventListener } from '../../../events/useEventListener';
import { QuoteSelectedEvent } from '../../mutations/useSelectQuoteMutation';
import { QuoteUnselectedEvent } from '../../mutations/useUnselectQuoteMutation';
import { SaveCompleteEvent } from '../../mutations/useFormAnswersMutation';

const VALID_FOR_CONVERSION_QUERY = gql`
  query isValidForConversion($leadId: ID!) {
    validForConversion(leadId: $leadId) {
      ...ConvertResultFragment
    }
  }
  
  ${CONVERT_RESULT_FRAGMENT}
`;

export function useValidForConversionQuery(leadId?: string) {
  const result = useQuery<ValidForConversionQueryData, ValidForConversionQueryVariables>(VALID_FOR_CONVERSION_QUERY, {
    variables: {
      leadId: getLeadId(leadId),
    },
    fetchPolicy: 'cache-and-network',
  });
  useEventListener(QuoteSelectedEvent, () => result.refetch());
  useEventListener(QuoteUnselectedEvent, () => result.refetch());
  useEventListener(SaveCompleteEvent, () => result.refetch());
  return result;
}

function getLeadId(leadId?: string) {
  return leadId ?? React.useContext(LeadIdContext);
}
