import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { UsersQueryData, UsersQueryVariables } from './useUsersQuery.interfaces';

const USERS_FRIENDLY_NAME_QUERY = gql`
    query UsersName {
      users {
        id
        name {
          fullFriendlyName
        }
      }
    }
`;

export function useUsersFriendlyNameQuery() {
  return useQuery<UsersQueryData, UsersQueryVariables>(USERS_FRIENDLY_NAME_QUERY);
}
