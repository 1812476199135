import { QuestionType } from '@hiq/crm.types';
import React from 'react';
import { useFormAnswers } from '../../../../../../hooks';
import { useActiveLeadId } from '../../../../../routers';
import { QuestionView, QuestionViewProps } from '../QuestionView';

export const SimpleQuestionView: React.FunctionComponent<{ type: QuestionType } & Partial<QuestionViewProps>> = (props) => {
  const leadId = useActiveLeadId();
  const [answers, onChange] = useFormAnswers();

  const {
    id, label, questionText, ...rest
  } = props;

  const handleChange = React.useCallback(
    (value: any) => {
      onChange(id, value);
    },
    [onChange, id],
  );

  return (
    <QuestionView
      {...rest}
      id={id}
      leadId={leadId}
      questionText={questionText ?? ''}
      label={label ?? ''}
      value={answers[id]}
      onChange={handleChange}
    />
  );
};
