import { FormFieldsOnChangeHandler } from '@hiq/crm.types';

export interface FormGeneratorProps {
  leadId: string;
  referenceId: string;
  style?: FormGeneratorStyle;
  lockable?: boolean;
  onFieldsChange?: FormFieldsOnChangeHandler;
  locked?: boolean;
}

export enum FormGeneratorStyle {
  QuestionFlow = 'QUESTION_FLOW',
  Compact = 'COMPACT',
  Standard = 'STANDARD',
  ReadOnly = 'READ_ONLY',
}
