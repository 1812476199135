import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Table, TableCell, TableRow } from '@material-ui/core';
import { ResizerProps } from './Datagrid.interfaces';

export const FullWidthDatagridTable = styled(Table)`
  overflow: auto;
  min-width: unset !important;
`;

export const DatagridTable = styled(FullWidthDatagridTable)`
  display: inline-block !important;
`;

export const DatagridRow = styled(TableRow)`
  outline: 1px solid lightgray;
  border: 1px solid transparent;
  border-radius: 4px;
  background: white;
  margin: 8px 0;
`;

export const DatagridCell = styled(TableCell)`
  padding: 10px !important;
  display: flex !important;
  align-items: center;
  justify-content: space-evenly;
`;

export const SortContainer = styled.div`
  padding: 10px;
`;

export const Resizer = styled.div<ResizerProps>(({ isResizing }) => css`
  padding: 10px 3px !important;
  background: #8b8b8b;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  touch-action: none;
  ${isResizing ? 'background: #484848FF;' : ''}
`);

export const SwitchContainer = styled.div<{ cursor?: string }>(({ cursor = 'pointer' }) => css`
  justify-content: flex-end;
  cursor: ${cursor};
  padding: 4px;
`);

export const CopyButtonSpacing = styled.div`
  cursor: pointer;
  margin-left: 15px;
`;

export const SelectionLabel = styled.span<{ color?: string }>(({ color }) => css`
  font-weight: 700;
  font-size: 16px;
  ${color ? `color: ${color}` : ''}
`);
