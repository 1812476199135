import * as React from 'react';
import { navigateToTrmSection } from '../../../util';
import { PrimaryButton } from '../inputs/PrimaryButton';

export const MedicareSkipEligibilityButton: React.FC = () => {
  function handleButtonClick() {
    navigateToTrmSection('presentation');
  }

  return (
    <>
      <PrimaryButton variant="outlined" onClick={handleButtonClick} fullWidth>
        SKIP ELIGIBILITY
      </PrimaryButton>
    </>

  );
};
