import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  GetDementiaFlagData,
  GetDementiaFlagVariables,
} from './useGetDementiaFlag.interfaces';

const GET_DEMENTIA_FLAG = gql`
  query GetDementiaFlag($customerId: CustomerId!, $drugs: [String!]!) {
    getDementiaFlag(customerId: $customerId, drugs: $drugs) {
        success
        message
        dementiaFlag
      }
  }
`;

export function useGetDementiaFlag() {
  return useLazyQuery<GetDementiaFlagData, GetDementiaFlagVariables>(GET_DEMENTIA_FLAG, { fetchPolicy: 'network-only' });
}
