import * as React from 'react';
import { isPresent } from '@healthiqeng/core.util.is-present';
import { isEmpty } from '@healthiqeng/core.util.is-empty';
import { FormAnswers } from '@hiq/crm.types';
import { ValidationMessage } from './MedicareEnrollmentValidation.elements';
import { Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useActiveLeadId } from '../../routers';
import { useFormAnswers } from '../../../hooks';
import { useValidForConversionQuery } from '../../../graphql';
import { QueryLoader } from '../QueryLoader';

export const MedicareEnrollmentValidation: React.FunctionComponent = () => {
  const leadId = useActiveLeadId();
  const [answers] = useFormAnswers(leadId);
  const readyToEnroll = answers['lead.medicareAdvantageReadyToEnroll'];
  const sendToEnroller = mustSendCaseToEnroller(answers);
  const { data, loading, error } = useValidForConversionQuery(leadId);
  const [displayErrorsAlert, setErrorsAlertOpen] = React.useState(readyToEnroll && sendToEnroller);
  const enrollmmentErrorsList = data?.validForConversion?.errors?.filter((err) => filterEnrollmentErrorsList(err.questionId));
  React.useEffect(() => {
    if (readyToEnroll && sendToEnroller && !isEmpty(enrollmmentErrorsList)) {
      setErrorsAlertOpen(true);
    } else {
      setErrorsAlertOpen(false);
    }
  }, [enrollmmentErrorsList]);

  return (
    <QueryLoader loading={loading} error={error} componentName="MedicareEnrollmentValidation">
      { (readyToEnroll && sendToEnroller) && (
        <ValidationMessage>
          DO NOT TRANSFER TO ENROLLER IF THIS A UNITED HEALTHCARE MA/MAPD ENROLLMENT
        </ValidationMessage>
      )}
      <Snackbar open={displayErrorsAlert}>
        <Alert variant="filled" severity="error">
          <AlertTitle>Please fill in all required fields before sending to the enroller</AlertTitle>
          <ul>
            { enrollmmentErrorsList
              ?.map((err) => <li key={err.questionId}>{ err.message }</li>)}
          </ul>
        </Alert>
      </Snackbar>
    </QueryLoader>
  );
};

function mustSendCaseToEnroller(answers: FormAnswers) {
  const planCategory = answers['lead.medicareAdvantagePlanCategory'];
  const planType = answers['lead.medicareAdvantagePlanType'];

  return ['MA', 'MAPD'].includes(planCategory) && ['HMO', 'PPO', 'POS'].includes(planType);
}

function filterEnrollmentErrorsList(questionId: string) {
  if (questionId === 'customer.person.futureCommunicationConsent') {
    return false;
  }
  return isPresent(questionId);
}
