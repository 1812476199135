import styled from '@emotion/styled';

export const SectionTitle = styled.p`
  font-weight: 700;
  font-size: 20px;
  text-decoration-line: underline;
  color: #0075CA;
`;

export const SectionTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer
`;
