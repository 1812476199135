import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { Icon } from '../../../Icon';
import { QuotePinProps } from './QuotePin.interfaces';
import { QuotePinContainer } from './QuotePin.elements';

export const QuotePin = ({ isPinned, onPin }: QuotePinProps) => (
  <QuotePinContainer>
    <IconButton onClick={onPin} title={isPinned ? 'Unpin quote' : 'Pin quote'}>
      <Icon type={isPinned ? 'turned-in' : 'turned-in-not'} />
    </IconButton>
  </QuotePinContainer>
);
