import React from 'react';
import {
  PrecisionScoreContainer,
  PrecisionScoreMedal,
  PrecisionScoreText,
  PrecisionScoreBronzeMedal,
  PrecisionScoreGoldMedal,
  PrecisionScoreSilverMedal,
} from './NavBarPrecisionScore.elements';
import { Box } from '@material-ui/core';
import { useFormAnswers } from '../../../../hooks';
import { useActiveLeadId } from '../../../routers';
import { PrecisionScoreDetails } from './PrecisionScoreDetails';
import { useToggle } from '../../../common/CheckMyHealthRecord/CheckMyHealthRecordDataGrid/useToggle';
import { useSelectedDoctorsQuery, useSelectedICDsQuery, useSelectedPrescriptionsQuery } from '../../../../graphql';

const HIPAA_QUESTION_ID = 'customer.person.hipaaConsentApproved';
const ENROLLED_PLAN_HIQ_RANK_QUESTION_ID = 'lead.sunfire.enrolledPlan.hiqRank';
const PRECISION_SCORE = 'lead.sunfire.precisionScore';
export const NavBarPrecisionScore = () => {
  const leadId = useActiveLeadId();
  const [answers, saveAnswer] = useFormAnswers(leadId, true);
  const [openDetails, toggleDetails] = useToggle();

  const hipaaApproved = answers[HIPAA_QUESTION_ID];
  const selectedPrescriptions = useSelectedPrescriptionsQuery(leadId)?.data?.selectedPrescriptions || [];
  const selectedDiagnoses = useSelectedICDsQuery(leadId)?.data?.selectedICDs || [];
  const selectedDoctors = useSelectedDoctorsQuery(leadId)?.data?.selectedDoctors || [];
  const enrolledPlanHiqRank = answers[ENROLLED_PLAN_HIQ_RANK_QUESTION_ID];

  const limitDoctorPoints = React.useMemo(() => {
    const doctors = [...selectedDoctors].splice(0, 3);
    if (doctors.length > 0) return doctors.length;
    return 1;
  }, [selectedDoctors]);

  const pointsStructure = React.useMemo(() => [
    {
      label: 'Getting consent',
      points: 1,
      done: !!hipaaApproved,
    },
    {
      label: 'Each doctor covered (up to 3)',
      points: limitDoctorPoints,
      done: selectedDoctors.length > 0,
    },
    {
      label: 'Including drugs',
      points: 1,
      done: selectedPrescriptions.length > 0,
    },
    {
      label: 'Including diagnoses',
      points: 1,
      done: selectedDiagnoses.length > 0,
    },
    {
      label: 'Picking a top-4 plan',
      points: 2,
      done: enrolledPlanHiqRank < 5,
    },
  ], [hipaaApproved, limitDoctorPoints, selectedPrescriptions, selectedDiagnoses, enrolledPlanHiqRank, selectedDoctors]);

  const score = pointsStructure.reduce((acc, point) => acc + (point.done ? point.points : 0), 0);

  React.useEffect(() => {
    if (score !== answers[PRECISION_SCORE]) {
      saveAnswer(PRECISION_SCORE, score);
    }
  }, [score, saveAnswer]);

  const Medal = React.useMemo(() => {
    if (score > 6) return PrecisionScoreGoldMedal;
    if (score > 4) return PrecisionScoreSilverMedal;
    if (score > 1) return PrecisionScoreBronzeMedal;
    return PrecisionScoreMedal;
  }, [score]);

  return (
    <Box>
      <PrecisionScoreContainer onClick={toggleDetails}>
        <Medal>
          <PrecisionScoreText>
            {score}
          </PrecisionScoreText>
        </Medal>
        <PrecisionScoreText>
          PRECISION SCORE
        </PrecisionScoreText>
      </PrecisionScoreContainer>
      <PrecisionScoreDetails open={openDetails} onDialogClose={toggleDetails} pointsStructure={pointsStructure} />
    </Box>
  );
};
