import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../../themes';

export const ErrorMessage = styled.p<ComponentWithThemeProps>(({ theme }) => `
  color: ${theme.color.error};
`);

export const MarginLeft = styled.div`
  display: inline-block;
  margin-left: 10px;
`;
