import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../themes';

export const NewCrossSaleContainer = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  margin: 10px 10px 300px;
  padding: 15px;
  background-color: white;
  border: 1px solid ${theme.color.grayLight};
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
`);

export const NewCrossSaleHeader = styled('h2')(css``);

export const FormItemContainer = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  border-top: 1px solid ${theme.color.grayLight};
  padding: 10px 0;
`);
