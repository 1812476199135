import { QuestionType } from '@hiq/crm.types';
import { SelectOption } from '../../../../../types';
import { dateTransformerFactory } from '../DateTransformer';
import { phoneTransformerFactory } from '../PhoneTransformer';
import { currencyTransformerFactory } from '../CurrencyTransformer';
import { emailTransformerFactory } from '../EmailTransformer';
import { numberTransformerFactory } from '../NumberTransformer';
import { percentTransformerFactory } from '../PercentTransformer';
import { ssnTransformerFactory } from '../SsnTransformer';
import { identityTransformerFactory } from '../IdentityTransformer';
import { getSelectTransformerFactory } from '../SelectTransformer';
import { isPresent } from '@healthiqeng/core.util.is-present';
import { InputTransformer } from '../InputTransformer';
import { medicareIdTransformerFactory } from '../MedicareIdTransformer';

export const useInputFormatter = <T>(value: T, type: QuestionType, options?: SelectOption[]): string => {
  if (!isPresent(value)) return '';
  const transformerFactory = getTransformerFactory(type, options);
  const transformer = transformerFactory(value);
  return transformer.getFormattedValue();
};

function getTransformerFactory(type: QuestionType, options?: SelectOption[]): (val: any) => InputTransformer<any> {
  switch (type) {
    case QuestionType.Currency:
      return currencyTransformerFactory;
    case QuestionType.Date:
      return dateTransformerFactory;
    case QuestionType.Email:
      return emailTransformerFactory;
    case QuestionType.Number:
      return numberTransformerFactory;
    case QuestionType.Percent:
      return percentTransformerFactory;
    case QuestionType.Phone:
      return phoneTransformerFactory;
    case QuestionType.Select:
      return getSelectTransformerFactory(options);
    case QuestionType.SSN:
      return ssnTransformerFactory;
    case QuestionType.MedicareId:
      return medicareIdTransformerFactory;
    default:
      return identityTransformerFactory;
  }
}
