import * as React from 'react';
import { useActiveLeadId } from '../../routers';
import { useFormAnswers } from '../../../hooks';
import { useDisasterSEPQuery } from '../../../graphql';
import { QueryLoader } from '../QueryLoader';
import { InfoBlock } from '../ScriptBlock';

export const MedicareDSTNotification: React.FunctionComponent = () => {
  const leadId = useActiveLeadId();
  const [answers] = useFormAnswers(leadId);

  const county = answers['customer.person.homeAddress.county'];
  const state = answers['customer.person.homeAddress.state.code'];

  const { data, loading, error } = useDisasterSEPQuery({ state, county });
  if (!loading && !error && !data) return null;

  return (
    <QueryLoader loading={loading} error={error} componentName="MedicareDSTNotification">
      <InfoBlock>
        I see that your area was impacted by
        <b>
          { ` ${data?.title}. ` }
        </b>
        Did this affect your ability to enroll into a plan?
        <p>
          If lead says yes agent MUST determine if they were eligible for an SEP during this time
        </p>
      </InfoBlock>
    </QueryLoader>
  );
};
