import { useEffect, useCallback } from 'react';
import { JsonCompatible } from '@healthiqeng/core.types';
import { FormAnswers, UserProfile } from '@hiq/crm.types';
import { UseFormAnswersHook } from './useFormAnswers.interfaces';
import {
  FormAnswersActionType, FormAnswersReducerDispatch, useFormAnswersReducer,
} from './useFormAnswersReducer';
import { SaveAnswersFunction, useFormAnswersSave } from './useFormAnswersSave';
import { useFormAnswersQuery } from './useFormAnswersQuery';
import { useActiveLeadId } from '../../components/routers/LeadRouter/useActiveLeadId';
import { useDialerCallContext } from '../../components/routers';
import { useCurrentUserQuery } from '../../graphql';

export const useFormAnswers: UseFormAnswersHook = (leadId: string = null, autoSave: boolean = true, trackingId?, autoSaveDelay = 500) => {
  const activeLeadId = useActiveLeadId(leadId);
  const user = useCurrentUserQuery();
  const callUrlPath = useDialerCallContext();
  const answers = useFormAnswersQuery(activeLeadId);
  const [answersLocal, dispatch] = useFormAnswersReducer(answers);
  const saveAnswers = useFormAnswersSave({
    leadId: activeLeadId,
    delayMilliseconds: autoSave ? autoSaveDelay : Number.MAX_SAFE_INTEGER,
    trackingId,
    callUrlPath,
    userProfile: user?.data?.currentUser?.profile as UserProfile,
    onFailure: () => {
      dispatch({
        type: FormAnswersActionType.Reset,
        answers,
      });
    },
  });

  const onChange = useCallback(onChangeFactory(dispatch, autoSave ? saveAnswers : undefined), [
    dispatch,
    autoSave ? saveAnswers : undefined,
  ]);
  const persistState = useCallback(persistStateFactory(dispatch, saveAnswers), [dispatch, saveAnswers]);
  const clearState = useCallback(clearStateFactory(answers, dispatch), [answers, dispatch]);

  useEffect(refreshLocalStateEffectFactory(answers, dispatch), [answers]);

  return [answersLocal, onChange, persistState, clearState];
};

function onChangeFactory(dispatch: FormAnswersReducerDispatch, saveAnswers?: SaveAnswersFunction) {
  return (key: string, value: JsonCompatible, debounce?: boolean) => {
    dispatch({
      type: FormAnswersActionType.Change,
      key,
      value,
      saveAnswers,
      debounce,
    });
  };
}

function refreshLocalStateEffectFactory(answers: FormAnswers, dispatch: FormAnswersReducerDispatch) {
  return () => {
    dispatch({
      type: FormAnswersActionType.Refresh,
      answers,
    });
  };
}

function persistStateFactory(dispatch: FormAnswersReducerDispatch, saveAnswers: SaveAnswersFunction) {
  return () => {
    dispatch({
      type: FormAnswersActionType.Persist,
      saveAnswers,
    });
  };
}

function clearStateFactory(answers: FormAnswers, dispatch: FormAnswersReducerDispatch) {
  return () => {
    dispatch({
      type: FormAnswersActionType.Reset,
      answers,
    });
  };
}
