import React from 'react';
import { CompareContext } from '../../CompareContext/CompareContext';
import {
  Checkbox, FormControl, OutlinedInput, ListItemText, MenuItem, Select,
} from '@material-ui/core';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const CarrierFilter = () => {
  const { carriers, updateCarrierFilter, carrierFilter } = React.useContext(CompareContext);

  return (
    <FormControl>
      <span>Carrier</span>
      <Select
        multiple
        style={{ width: '300px' }}
        id="carrier-select"
        value={carrierFilter}
        variant="outlined"
        label="Carrier"
        fullWidth
        onChange={(event) => updateCarrierFilter(event.target.value as Array<string>)}
        input={<OutlinedInput label="Carrier" margin="dense" placeholder="Carrier" />}
        renderValue={(selected: Array<string>) => selected.join(', ')}
        MenuProps={MenuProps}
      >
        {carriers?.map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={carrierFilter.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
