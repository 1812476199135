import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { ACTIVITY_FRAGMENT } from '../../fragments';
import { UserTasksFilter, UserTasksQueryData, UserTasksQueryVariables } from './useUserTasksQuery.interfaces';

const USER_TASKS_QUERY = gql`
  query GetUserTasks($filter: UserTasksFilter!) {
    userTasks(filter: $filter) {
      ...ActivityFragment
    }
  }
  ${ACTIVITY_FRAGMENT}
`;

export function useUserTasksQuery(filter: UserTasksFilter) {
  return useQuery<UserTasksQueryData, UserTasksQueryVariables>(USER_TASKS_QUERY, {
    variables: { filter },
  });
}
