import * as React from 'react';
import { Spring } from 'react-spring/renderprops.cjs';
import { TransitionInPortal } from '../TransitionInPortal';
import {
  SidebarBody,
  SidebarCloseButton,
  SidebarCloseButtonContainer,
  SidebarContainer,
  SidebarContentContainer,
  SidebarOverlay,
} from './Sidebar.elements';
import { ISidebarProps } from './Sidebar.interfaces';

export class Sidebar extends React.Component<ISidebarProps> {
  public static defaultProps = {
    onOpen: () => 0,
    onClose: () => 0,
  };

  private overlayTransitionInProgress = false;
  private bodyTransitionInProgress = false;
  private open = false;

  public render() {
    return (
      <TransitionInPortal
        opened={this.props.open}
        render={(transitionState, stateSwitcher) => {
          this.stateSwitcher = stateSwitcher;
          const openedOverlayStyles = { opacity: 0.7 };
          const closedOverlayStyles = { opacity: 0 };
          const openedBodyStyles = { transform: 'translateX(0px)' };
          const closedBodyStyles = { transform: 'translateX(100%)' };

          let overlayFrom;
          let overlayTo;
          let bodyFrom;
          let bodyTo;

          if (transitionState === 'entering') {
            overlayFrom = closedOverlayStyles;
            overlayTo = openedOverlayStyles;
            bodyFrom = closedBodyStyles;
            bodyTo = openedBodyStyles;
          }
          if (transitionState === 'exiting') {
            overlayFrom = openedOverlayStyles;
            overlayTo = closedOverlayStyles;
            bodyFrom = openedBodyStyles;
            bodyTo = closedBodyStyles;
          }

          return (
            <SidebarContainer>
              <Spring
                native
                from={overlayFrom}
                to={overlayTo}
                onStart={this.overlayAnimationStartHandler}
                onRest={this.overlayAnimationEndHandler}
              >
                {(animatedStyles) => (
                  <SidebarOverlay
                    style={animatedStyles}
                    onClick={this.props.onOverlayClick}
                  />
                )}
              </Spring>
              <Spring
                native
                from={bodyFrom}
                to={bodyTo}
                onStart={this.bodyAnimationStartHandler}
                onRest={this.bodyAnimationEndHandler}
              >
                {(animatedStyles) => (
                  <SidebarBody style={animatedStyles}>
                    {this.props.withCloseButton && (
                    <SidebarCloseButtonContainer>
                      <SidebarCloseButton onClick={this.props.onCloseButtonClick}>
                        Close
                      </SidebarCloseButton>
                    </SidebarCloseButtonContainer>
                    )}
                    <SidebarContentContainer>
                      {this.props.children}
                    </SidebarContentContainer>
                  </SidebarBody>
                )}
              </Spring>
            </SidebarContainer>
          );
        }}
      />
    );
  }

  private stateSwitcher: () => void = () => null;

  private overlayAnimationStartHandler = () => {
    this.overlayTransitionInProgress = true;
  };

  private overlayAnimationEndHandler = () => {
    this.overlayTransitionInProgress = false;
    this.animationEndHandler();
  };

  private bodyAnimationStartHandler = () => {
    this.bodyTransitionInProgress = true;
  };

  private bodyAnimationEndHandler = () => {
    this.bodyTransitionInProgress = false;
    this.animationEndHandler();
  };

  private animationEndHandler() {
    if (!this.overlayTransitionInProgress && !this.bodyTransitionInProgress) {
      this.onAllAnimationsComplete();
    }
  }

  private onAllAnimationsComplete() {
    this.stateSwitcher();
    if (this.props.open && !this.open) {
      this.open = true;
      this.props.onOpen();
      return;
    }
    if (!this.props.open && this.open) {
      this.open = false;
      this.props.onClose();
    }
  }
}
