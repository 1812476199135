import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { ComponentWithThemeProps } from '../../../../themes';

export const DirectionalCopyContainer = styled('div')<ComponentWithThemeProps>(
  () => css`
    display: flex;
    align-items: center;
  `,
);
