import * as React from 'react';
import { CardListItemProps } from './CardListItem.interfaces';
import { CardListItemContainer } from './CardListItem.elements';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import { Icon } from '../Icon';

export const CardListItem: React.FunctionComponent<CardListItemProps> = ({
  icon,
  subject,
  body,
}) => (
  <CardListItemContainer>
    <List dense disablePadding>
      <ListItem alignItems="flex-start">
        { icon && (
          <ListItemIcon style={{ position: 'relative', top: '8px' }}>
            <Icon type={icon} />
          </ListItemIcon>
        ) }
        <ListItemText
          primary={subject}
          secondary={body}
        />
      </ListItem>
    </List>
  </CardListItemContainer>
);
