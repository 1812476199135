import * as React from 'react';
import { useWaitingForNextCall, WaitingNextCallHome } from '../../../common';
import { DialerCallRouter, LeadRouter } from '../../../routers';

type InitialContactHomeProps = {
  home: React.ReactNode
};

export const InitialHome: React.FunctionComponent<InitialContactHomeProps> = ({ home }: InitialContactHomeProps) => {
  const { isWaitingForNextCall } = useWaitingForNextCall();

  if (isWaitingForNextCall) return <WaitingNextCallHome />;

  return (
    <LeadRouter>
      <DialerCallRouter>
        {home}
      </DialerCallRouter>
    </LeadRouter>
  );
};
