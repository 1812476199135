import React, { FC } from 'react';
import parse from 'autosuggest-highlight/parse';

import Grid from '@material-ui/core/Grid';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Typography from '@material-ui/core/Typography';

import { AddressLookupInputOptionElem } from './AddressLookupInputOption.elements';
import { AddressLookupInputOptionProps } from './AddressLookupInputOption.interfaces';

export const AddressLookupInputOption: FC<AddressLookupInputOptionProps> = ({ option }) => {
  const matches = option.mainTextMatchings;
  const matchingParts = parse(
    option.mainText,
    matches.map((match: { offset: number; length: number; }) => [
      match.offset,
      match.offset + match.length,
    ]),
  );

  return (
    <Grid container alignItems="center">
      <Grid item>
        <LocationOnIcon />
      </Grid>
      <Grid item xs>
        {matchingParts.map((part) => (
          <AddressLookupInputOptionElem
            key={part.text}
            isHighligthed={part.highlight}
          >
            {part.text}
          </AddressLookupInputOptionElem>
        ))}

        <Typography variant="body2" color="textSecondary">
          {option.secondaryText}
        </Typography>
      </Grid>
    </Grid>
  );
};
