import { gql } from 'apollo-boost';
import { MutationCustomReturn, useMutationCustom } from '../../useMutationCustom';
import {
  DeleteFollowUpMutationResponse,
  DeleteFollowUpMutationVariables,
} from './useDeleteFollowUpMutation.interfaces';
import { deleteCalendarEvent } from '../../../queries/followUp/useLeadFollowUpQuery';

const DELETE_FOLLOW_UP_MUTATION = gql`
  mutation DeleteFollowUp($id: ID!) {
    deleteFollowUp(id: $id) {
      code
      success
      message
      timestamp
      type
      eventId
    }
  }
`;

export function useDeleteFollowUpMutation(leadId: string): MutationCustomReturn<
DeleteFollowUpMutationResponse,
DeleteFollowUpMutationVariables> {
  return useMutationCustom<
  DeleteFollowUpMutationResponse,
  DeleteFollowUpMutationVariables
  >(DELETE_FOLLOW_UP_MUTATION, {
    update: (cache, { data }) => {
      if (!data?.deleteFollowUp?.success) return;
      // @ts-ignore
      deleteCalendarEvent(cache, leadId, data?.deleteFollowUp?.eventId);
    },
  });
}
