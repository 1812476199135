import { isPresent } from '@healthiqeng/core.util.is-present';
import { useLocation, useMatch, useNavigate } from '@reach/router';
import { logger } from '../../../../services';

export function useLeadIdRoute(defaultId?: string, defaultSection?: string): [string, (id: string) => void, boolean] {
  const navigate = useNavigate();
  const location = useLocation();
  const match = useMatch('/customer/:customerId/lead/:leadId/*');

  const onChange = (id: string) => {
    if (!id) {
      return;
    }

    if (!match?.leadId) {
      navigate(`${location.pathname}/lead/${id}`.replace(/\/+/g, '/'))
        .catch((error) => logger.crit(error));
      return;
    }

    if (defaultSection) {
      navigate(`/customer/${match?.customerId}/lead/${id}/${defaultSection}`)
        .catch((error) => logger.crit(error));
      return;
    }

    navigate(location.pathname.replace(match?.leadId, id))
      .catch((error) => logger.crit(error));
  };

  if (defaultId && !match?.leadId) {
    onChange(defaultId);
  }

  // Regular expression to check if string is a valid UUID
  const uuidRegexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  const isValidLead = !isPresent(match?.leadId) || uuidRegexExp.test(match?.leadId) || ['new', 'ma-conversion'].includes(match?.leadId);

  return [
    match?.leadId,
    onChange,
    isValidLead,
  ];
}
