import * as React from 'react';

import {
  Dialog, DialogTitle, DialogContent, Box, Button,
} from '@material-ui/core';
import { DNCPhoneNumberEditor } from '../../DNCPhoneNumberEditor';
import { DNCModalProps } from './DNCModal.interfaces';

export const DNCModal: React.FC<DNCModalProps> = ({
  headerText,
  open,
  onClose,
  onClickSave,
  stateDisclaimerText,
}) => (
  <Dialog open={open}>
    <DialogTitle>DNC Customer</DialogTitle>
    <DialogContent>
      { headerText && <p style={{ color: 'red' }}>{ headerText }</p>}
      { stateDisclaimerText && <p style={{ color: 'red' }}>{ stateDisclaimerText }</p> }
      <Box padding={1} marginY={2} border={1} borderRadius={10} borderColor="lightgrey">
        <DNCPhoneNumberEditor />
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Button variant="outlined" style={{ marginRight: 5 }} onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" style={{ color: 'white' }} onClick={onClickSave}>
          Save and End Call
        </Button>
      </Box>
    </DialogContent>
  </Dialog>
);
