import { UserAbValues } from '@hiq/crm.types';
import * as React from 'react';
import { useCurrentUserQuery } from '../../../graphql';
import { ScriptVersionColorMap } from './ScriptVersionColorMap';
import {
  ScriptVersionFooterContainer,
  RightTextContainer,
  ScriptVersionText,
  ScriptVersionValueText,
  FooterColorfulLine,
} from './ScriptVersionFooter.elements';

export const defaultScriptColor = 'blue';

export const ScriptVersionFooter: React.FC = () => {
  const { data } = useCurrentUserQuery();

  if (!data) return null;

  const userAbValue = data?.currentUser?.abValue as UserAbValues;
  const color = React.useMemo(() => getScriptFooterColor(userAbValue), [userAbValue]);

  return (
    <ScriptVersionFooterContainer>
      <RightTextContainer>
        <ScriptVersionText>SCRIPT VERSION</ScriptVersionText>
        <ScriptVersionValueText color={color}>{userAbValue ?? 'NO AB'}</ScriptVersionValueText>
      </RightTextContainer>
      <div>
        <FooterColorfulLine color={color} />
      </div>
    </ScriptVersionFooterContainer>
  );
};

export function getScriptFooterColor(userAbValue?: UserAbValues) {
  let color = defaultScriptColor;
  if (!userAbValue) return color;

  const letterPrefix = getAbValuePrefix(userAbValue);

  if (letterPrefix && letterPrefix in ScriptVersionColorMap) {
    color = ScriptVersionColorMap[letterPrefix as keyof typeof ScriptVersionColorMap];
  }

  return color;
}

export function getAbValuePrefix(abValue: string) {
  return abValue.match(/^[A-Z]([1-9][0-9]?)?$/gm) ? abValue[0] : undefined;
}
