import * as React from 'react';
import { TextInputProps } from './TextInput.interfaces';
import { TextField } from './TextInput.elements';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useInputOnChange } from '../useInputOnChange';
import { InputValidationStatus } from '@hiq/crm.types';
import { isPresent } from '@healthiqeng/core.util.is-present';
import { useEffect } from 'react';
import { IconButton } from '@material-ui/core';
import { InputWidthLimiterContainer } from '../../InputWidthLimiterContainer';

export const TextInput: React.FunctionComponent<TextInputProps> = ({
  placeholder,
  value,
  onChange,
  transformerFactory,
  validationStatus,
  validationMessage,
  label,
  size,
  disabled,
  fullWidth,
  variant,
  prefix,
  suffix,
  multiline,
  rows,
  onBlur,
  onFocus,
  onKeyUp,
  mask,
  inputProps,
  dataQa,
  endIcon,
  inputRef,
  tags,
}) => {
  const [formattedValue, handleChange] = useInputOnChange(value, onChange, transformerFactory);

  const onKeyUpCallback = React.useCallback((e) => {
    if (onKeyUp) onKeyUp(e, formattedValue);
  }, [formattedValue, onKeyUp]);

  const onKeyPressCallback = React.useCallback((e) => {
    if (e.key === 'Enter' && onBlur) {
      onBlur();
    }
  }, [onBlur]);

  const [isMasked, setIsMasked] = React.useState(isPresent(value) && mask);
  const handleBlur = () => {
    if (onBlur) onBlur();
    if (mask && isPresent(value)) setIsMasked(true);
  };

  useEffect(() => {
    if (!isPresent(value)) setIsMasked(false);
  }, [value]);

  return (
    <InputWidthLimiterContainer>
      <TextField
        tags={tags}
        value={formattedValue}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={onFocus}
        placeholder={placeholder}
        label={label}
        error={validationStatus === InputValidationStatus.Error}
        helperText={validationMessage}
        disabled={disabled}
        variant={variant ?? 'outlined'}
        size={size}
        fullWidth={fullWidth}
        multiline={multiline}
        rows={rows}
        onKeyUp={onKeyUpCallback}
        InputProps={{
          startAdornment: prefix && <InputAdornment position="start">{prefix}</InputAdornment>,
          endAdornment:
          (suffix && <InputAdornment position="end">{suffix}</InputAdornment>) || (endIcon && <IconButton>{endIcon}</IconButton>),
          inputRef,
        }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{
          ...inputProps,
          'data-qa': dataQa,
        }}
        onKeyPress={onKeyPressCallback}
        type={isMasked && mask ? 'password' : 'text'}
      />
    </InputWidthLimiterContainer>
  );
};
