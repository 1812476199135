import * as React from 'react';
import { ProductTypeMedicare } from '@hiq/crm.types';
import { isEmpty } from '@healthiqeng/core.util.is-empty';
import {
  ConvertSuccessEvent,
  useApplicationAnchorsQuery,
  useCustomerQuery,
  useLeadQuery,
  useQuestionFlowsQuery,
} from '../../../../../graphql';
import { useEventListener } from '../../../../../events';
import { extractRecursiveMarkdown } from '../../useQuestionFlowAnchorQuery';

import { useActiveLeadId, useLeadSectionRoute } from '../../../../routers';
import { MainPanelLayout, MainPanelLayoutTabItem } from '../../../../layouts';
import { LeadPanelContainer, LeadPanelContainerCard } from '../../elements';
import {
  extractAnchors, IconProps, LogPanelChange, QuestionFlow,
} from '../../../../common';
import { MedicareQuotePanelFactory } from '../panels/MedicareQuotePanelFactory';
import { MedicareConversionPanel, MedicareEnrollmentPanel, SunfireInventoryPanel } from '../panels';
import { useSectionNavigation } from '../../../../common/QuestionFlow/MultiSectionQuestionFlowRenderer/useSectionNavigation';
import { LeadInfo } from '../../common/LeadInfo';
import { useCurrentUserIsAgent } from '../../../../../hooks';
import { useEmitEventCmhrAware } from '../../../../../hooks/useEmitEventCmhrAware/useEmitEventCmhrAware';
import { MedDigitalAnalyticsEvent } from '../../../../../events/MedDigitalAnalyticsEvent';

export const MedicarePreConversionLead: React.FunctionComponent = () => {
  const leadId = useActiveLeadId();
  const { data: customerData } = useCustomerQuery();
  const { data, refetch } = useLeadQuery();
  useEventListener(ConvertSuccessEvent, () => refetch());
  const emitEvent = useEmitEventCmhrAware();
  useEventListener(MedDigitalAnalyticsEvent, (event: MedDigitalAnalyticsEvent) => emitEvent(event.message, event.properties));
  const isAgent = useCurrentUserIsAgent();
  const { data: applicationAnchorsData } = useApplicationAnchorsQuery(leadId);
  const [activeTab, setActiveTab] = useLeadSectionRoute('interview');
  const { data: multiQuestionFlowData } = useQuestionFlowsQuery(leadId, [
    'interview', 'medicare-enrollment', 'medicare-greetings-and-role-statement', 'medicare-interview-product',
    'presentation', 'medicare-greeting-script',
  ]);
  const interviewAnchors = extractAnchors(extractRecursiveMarkdown(multiQuestionFlowData?.[0], 'interview'));
  const enrollmentFlow = multiQuestionFlowData?.[1];
  const hasEnrollment = !isEmpty(data?.selectedQuotes) && !!enrollmentFlow?.questionFlow;

  const showSunfireInventory = !!(customerData?.customer?.person?.homeAddress?.zip
    && (!data?.productType
    || [
      ProductTypeMedicare.MedicareAdvantage,
      ProductTypeMedicare.MedicareSupplement,
      ProductTypeMedicare.PrescriptionDrug,
    ].includes(data?.productType as ProductTypeMedicare)));

  const moveToQuoting = () => setActiveTab('quoting');
  const [currentSection, moveBackwards, moveForward] = useSectionNavigation(interviewAnchors.map(({ value }) => value), moveToQuoting);

  const applicationAnchors = applicationAnchorsData?.applicationAnchors ?? [];
  const tabs: MainPanelLayoutTabItem[] = React.useMemo(() => {
    const memoTabs: MainPanelLayoutTabItem[] = [
      {
        id: 'lead-info',
        label: 'Lead Info',
        icon: 'person',
        content: (
          <LeadPanelContainerCard>
            <LeadInfo leadId={leadId} />
          </LeadPanelContainerCard>
        ),
      },
      {
        id: 'interview',
        label: 'Interview',
        icon: 'assignment',
        anchors: interviewAnchors,
        content: (
          <LeadPanelContainerCard>
            <LogPanelChange panel="interview" />
            <QuestionFlow
              leadId={leadId}
              referenceId="interview"
              root
              split
              activeSectionIndex={currentSection}
              onNext={moveForward}
              onPrevious={moveBackwards}
            />
          </LeadPanelContainerCard>
        ),
      },
      {
        id: 'quoting',
        label: 'Quote',
        icon: 'local-offer',
        content: (
          <LeadPanelContainer>
            <LogPanelChange panel="quoting" />
            <MedicareQuotePanelFactory productType={data?.productType as ProductTypeMedicare} />
          </LeadPanelContainer>
        ),
      },
      ...(!showSunfireInventory ? [] : [
        {
          id: 'sunfire-inventory',
          label: 'MA/PDP Inventory',
          icon: 'chat' as IconProps['type'],
          content: (
            <>
              <LogPanelChange panel="sunfire-inventory" />
              <SunfireInventoryPanel />
            </>
          ),
        },
      ]),
      ...(!hasEnrollment ? [] : [
        {
          id: 'application',
          label: applicationAnchors.length ? 'Americo Special Rate App' : 'Application',
          icon: 'ballot' as IconProps['type'],
          anchors: applicationAnchors.length ? applicationAnchors : undefined,
          content: (
            <LeadPanelContainerCard>
              <LogPanelChange panel="application" />
              <MedicareEnrollmentPanel hasQuoteSelected={!isEmpty(data?.selectedQuotes)} split={!!applicationAnchors.length} />
            </LeadPanelContainerCard>
          ),
        },
      ]),
      ...(isAgent ? [] : [
        {
          id: 'enrollment-specialist',
          label: 'Enrollment Specialist',
          icon: 'ballot' as IconProps['type'],
          content: (
            <LeadPanelContainerCard>
              <QuestionFlow leadId={leadId} referenceId="enrollment-specialist-section" />
            </LeadPanelContainerCard>
          ),
        },
      ]),
      ...(isAgent ? [] : [
        {
          id: 'retention-specialist',
          label: 'Retention Specialist',
          icon: 'ballot' as IconProps['type'],
          content: (
            <LeadPanelContainerCard>
              <QuestionFlow leadId={leadId} referenceId="retention" allowExternal={false} />
            </LeadPanelContainerCard>
          ),
        },
      ]),
      {
        id: 'conversion',
        label: 'Conversion',
        icon: 'done',
        content: data?.isSold
          ? (
            <LeadPanelContainerCard>
              Lead Converted
            </LeadPanelContainerCard>
          )
          : (
            <LeadPanelContainerCard>
              <LogPanelChange panel="conversion" />
              <MedicareConversionPanel />
            </LeadPanelContainerCard>
          ),
      },
    ];
    return memoTabs;
  }, [leadId, currentSection, showSunfireInventory, hasEnrollment, isAgent, moveForward, moveBackwards, data?.productType]);
  return (
    <MainPanelLayout
      tabs={tabs}
      activeId={activeTab}
      onTabChange={setActiveTab}
    />
  );
};
