import { FormAnswers } from '@hiq/crm.types';

export function hoistQuestionFlowAnswers(answers: any): any {
  return Object.entries(answers ?? {}).reduce((reduction, [key, value]) => {
    if (key !== 'questionFlowAnswers') {
      return {
        ...reduction,
        [key]: typeof value === 'object' ? hoistQuestionFlowAnswers(value) : value,
      };
    }
    return {
      ...(value as FormAnswers),
      ...reduction,
    };
  }, {});
}
