import React from 'react';
import { LockedInfoContainer, LockedInfoText } from './LockedInfo.elements';
import { Icon } from '../../common/Icon';
import { PrimaryButtonLink } from '../../common/inputs/PrimaryButton/PrimaryButton.elements';
import { LockedInfoProps } from './LockedInfo.interfaces';
import { config } from '../../../config';

export const LockedInfo: React.FunctionComponent<LockedInfoProps> = ({
  sfId,
}) => (
  <LockedInfoContainer>
    <Icon size="small" type="warning" />
    <LockedInfoText>
      Lead is locked! For any changes please go to
      {' '}
      {
        sfId
          ? (
            <PrimaryButtonLink target="_blank" href={`${getSalesforceBaseUrl()}/${sfId}`}>
              Salesforce.
            </PrimaryButtonLink>
          )
          : 'Salesforce.'
      }
    </LockedInfoText>
  </LockedInfoContainer>
);

function getSalesforceBaseUrl() {
  return config.ENV === 'production'
    ? 'https://hiq.my.salesforce.com'
    : 'https://hiq--staging.visualforce.com/';
}
