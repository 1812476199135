import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../../themes';

export const LeadOwnerContainer = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  display: flex;
  flex-direction: row;
  padding: 6px 16px;
  margin-top: 5px;
  height: 32px;
  background: ${theme.color.primary};
  border-radius: 6px;
`);

export const LeadOwnerLabel = styled('div')(css`
  width: 100%;
  height: 14px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap
`);

export const LeadOwnerReleaseButton = styled('div')(css`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 14px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-decoration-line: underline;
  cursor: pointer;
`);

export const LeadOwnerEmptyContainer = styled('div')(css`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 14px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-decoration-line: underline;
`);
