import { FormControlLabel as MuiFormControlLabel } from '@material-ui/core';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { ComponentWithThemeProps } from '../../../../themes';

export const FormControlLabel = styled(MuiFormControlLabel)<ComponentWithThemeProps>(
  ({ theme }) => css`
    color: ${theme.color.text};
    background: ${theme.color.primaryLighter};
    border-radius: 7px;
    padding: 2px 15px 2px 5px;
    border: 1px solid ${theme.color.grayDark};
  `,
);
