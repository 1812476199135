export const useCellConfig = (width: number): [number, boolean] => {
  let cellWidth: number;
  const isReducedViewport = width < 780;
  if (width >= 1020) cellWidth = 280;
  else if (width >= 960) cellWidth = 260;
  else if (width >= 900) cellWidth = 240;
  else if (width >= 615) cellWidth = 200;
  else if (width >= 535) cellWidth = 160;
  else cellWidth = 140;
  return [cellWidth, isReducedViewport];
};
