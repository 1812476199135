import styled from '@emotion/styled';

export const Paragraph = styled.p`
  margin-bottom: 10px;
  margin-top: 0;
`;

export const Header = styled.p`
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: medium;
`;
