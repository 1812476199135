import * as React from 'react';
import { ProductTypeLife } from '@hiq/crm.types';
import { useActiveLeadId, useLeadSectionRoute } from '../../../../routers';
import {
  useLeadQuery,
  ConvertSuccessEvent,
  useQuestionFlowQuery,
} from '../../../../../graphql';
import { useEventListener } from '../../../../../events/useEventListener';
import { useQuestionFlowAnchorQuery } from '../../useQuestionFlowAnchorQuery';
import {
  useSectionNavigation,
  LogPanelChange,
  QuestionFlow,
  IconProps, SpecialRateVerification,
} from '../../../../common';
import { MainPanelLayout, MainPanelLayoutTabItem } from '../../../../layouts';
import {
  LifeQuotePanelFactory,
  LifeConversionPanel,
} from '../panels';
import { LeadPanelContainer, LeadPanelContainerCard } from '../../elements';
import { LeadInfo } from '../../common/LeadInfo';
import { FileUploader } from '../../../../common/FileUploader';
import { APPLICATION_PANEL_ID, LIFE_QUOTING_PANEL_ID } from '../LifeLead.interfaces';
import { useCurrentUserIsAgent } from '../../../../../hooks';

export const LifePreConversionLead: React.FunctionComponent = () => {
  const leadId = useActiveLeadId();
  const { data: leadData, refetch } = useLeadQuery();
  useEventListener(ConvertSuccessEvent, () => refetch());
  const isAgent = useCurrentUserIsAgent();
  const productType: ProductTypeLife = leadData?.productType as ProductTypeLife;

  const interviewAnchors = useQuestionFlowAnchorQuery(leadId, 'interview');
  const [activeTab, setActiveTab] = useLeadSectionRoute('interview');

  const moveToQuoting = () => setActiveTab('quoting');
  const moveToConversion = () => setActiveTab('conversion');
  const interviewAnchorsList = interviewAnchors.map(({ value }) => value);
  const [currentSection, moveBackwards, moveForward] = useSectionNavigation(interviewAnchorsList, moveToQuoting);

  const { data: application } = useQuestionFlowQuery(leadId, 'application');
  const applicationAnchors = useQuestionFlowAnchorQuery(leadId, 'application');
  const applicationAnchorsList = applicationAnchors.map(({ value }) => value);
  const [currentAppSection, moveAppBackwards, moveAppForward] = useSectionNavigation(applicationAnchorsList, moveToConversion);

  const isSpecialRate = leadData?.selectedQuote?.product?.isSpecialRate;

  const tabs: MainPanelLayoutTabItem[] = React.useMemo(() => {
    const memoTabs: MainPanelLayoutTabItem[] = [
      {
        id: 'lead-info',
        label: 'Lead Info',
        icon: 'person' as IconProps['type'],
        content: (
          <LeadPanelContainerCard>
            <LeadInfo leadId={leadId} />
          </LeadPanelContainerCard>
        ),
      },
      {
        id: 'interview',
        label: 'Interview',
        icon: 'assignment' as IconProps['type'],
        anchors: interviewAnchors,
        content: (
          <LeadPanelContainerCard>
            <LogPanelChange panel="interview" />
            <QuestionFlow
              leadId={leadId}
              referenceId="interview"
              root
              split
              activeSectionIndex={currentSection}
              onNext={moveForward}
              onPrevious={moveBackwards}
            />
          </LeadPanelContainerCard>
        ),
      },
      {
        id: LIFE_QUOTING_PANEL_ID,
        label: 'Quote',
        icon: 'local-offer' as IconProps['type'],
        content: (
          <LeadPanelContainer>
            <LogPanelChange panel="quoting" />
            <LifeQuotePanelFactory
              productType={productType}
            />
          </LeadPanelContainer>
        ),
      },
      ...(!isSpecialRate ? [] : [
        {
          id: 'special-rate-verification',
          label: 'Special Rate Verify',
          icon: 'hiq-torch' as IconProps['type'],
          content: (
            <LeadPanelContainerCard>
              <LogPanelChange panel="special-rate-verification" />
              <SpecialRateVerification />
            </LeadPanelContainerCard>
          ),
        },
      ]),
      ...(!application ? [] : [
        {
          id: APPLICATION_PANEL_ID,
          label: 'Application',
          icon: 'ballot' as IconProps['type'],
          anchors: applicationAnchors,
          content: (
            <LeadPanelContainerCard>
              <LogPanelChange panel="application" />
              <QuestionFlow
                leadId={leadId}
                referenceId="application"
                root
                split
                activeSectionIndex={currentAppSection}
                onNext={moveAppForward}
                onPrevious={moveAppBackwards}
              />
            </LeadPanelContainerCard>
          ),
        },
      ]),
      (!isAgent && (
        {
          id: 'retention-specialist',
          label: 'Retention Specialist',
          icon: 'ballot' as IconProps['type'],
          content: (
            <LeadPanelContainerCard>
              <QuestionFlow leadId={leadId} referenceId="retention" allowExternal={false} />
            </LeadPanelContainerCard>
          ),
        }
      )),
      {
        id: 'attachments',
        label: 'Attachments',
        icon: 'assignment' as IconProps['type'],
        content: (
          <>
            <LogPanelChange panel="attachments" />
            <FileUploader />
          </>
        ),
      },
      {
        id: 'conversion',
        label: 'Conversion',
        icon: 'done' as IconProps['type'],
        content: (
          <>
            <LogPanelChange panel="conversion" />
            <LifeConversionPanel />
          </>
        ),
      },
    ];
    return memoTabs;
  }, [
    leadId, productType, currentSection, moveForward, moveBackwards, !!application,
    currentAppSection, moveAppBackwards, moveAppForward, isSpecialRate,
  ]);

  return (
    <MainPanelLayout
      tabs={tabs}
      activeId={activeTab}
      onTabChange={setActiveTab}
    />
  );
};
