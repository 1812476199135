import * as React from 'react';
import { isPresent } from '@healthiqeng/core.util.is-present';
import { CreateActivityFormContainer, InputContainer } from './CreateActivityForm.elements';
import { InputFactory, PrimaryButton } from '../inputs';
import {
  ActivityType,
  activityTypeLabelMap,
  CreateActivityOptions,
  MedicareRequirementType,
  QuestionType,
  requirementTypeLabelMap,
} from '@hiq/crm.types';
import { getEnumOptions } from '../../../util';
import { useCreateActivityMutation, useCustomerQuery } from '../../../graphql';
import { CreateActivityFormProps } from './CreateActivityForm.interfaces';
import { TaskInput } from './ActivityFormInput';
import { useCurrentUserIsAgent } from '../../../hooks';

export const CreateActivityForm: React.FunctionComponent<CreateActivityFormProps> = ({
  defaultActivityType,
  onSaveSuccess,
  placeholder,
  displayFullFormOnStart,
}) => {
  const { data: customerData, loading: customerLoading } = useCustomerQuery();

  // eslint-disable-next-line max-len
  const activityTypeBlacklist = [ActivityType.EmailMessage, ActivityType.TextMessage, ActivityType.FollowUp, ActivityType.Call, ActivityType.ScratchPad];

  const isAgent = useCurrentUserIsAgent();

  if (isAgent) {
    activityTypeBlacklist.push(ActivityType.Requirement, ActivityType.Task);
  }

  const { id: customerId, activityFeedId } = customerData.customer ?? {};
  const [createActivity, { loading }] = useCreateActivityMutation(customerId, activityFeedId);
  const [values, setValues] = React.useState<CreateActivityOptions>({
    type: defaultActivityType ?? ActivityType.Note,
  });

  const save = async () => {
    await createActivity({ customerId, options: values });
    setValues({ type: ActivityType.Note });
    if (onSaveSuccess) onSaveSuccess();
  };

  const disabled = loading || customerLoading
    || (values.type === ActivityType.Requirement && !isPresent(values.requirementType))
    || (values.type === ActivityType.Task && !isPresent(values.assignedTo));

  const hasBegun = isPresent(values.body);
  return (
    <CreateActivityFormContainer>
      <InputContainer>
        <InputFactory
          type={QuestionType.TextArea}
          value={values.body}
          onChange={(value: string) => setValues({ ...values, body: value })}
          placeholder={placeholder ?? 'New Note...'}
          fullWidth
          size="small"
          rows={hasBegun ? 4 : 1}
        />
      </InputContainer>
      { (hasBegun || displayFullFormOnStart) && (
        <>
          <InputContainer>
            <InputFactory
              type={QuestionType.Select}
              value={values.type}
              onChange={(value: ActivityType) => setValues({ ...values, type: value })}
              options={getEnumOptions(
                ActivityType,
                activityTypeLabelMap,
                activityTypeBlacklist,
              )}
              fullWidth
              size="small"
            />
          </InputContainer>
          { values.type === ActivityType.Requirement && (
            <InputContainer>
              <InputFactory
                type={QuestionType.Select}
                value={values.requirementType}
                onChange={(value: MedicareRequirementType) => setValues({ ...values, requirementType: value })}
                options={getEnumOptions(
                  MedicareRequirementType,
                  requirementTypeLabelMap,
                )}
                fullWidth
                size="small"
              />
            </InputContainer>
          ) }
          { values.type === ActivityType.Task && (
            <TaskInput
              value={values.assignedTo}
              onChange={(value: string) => setValues({ ...values, assignedTo: value })}
            />
          ) }
          <PrimaryButton
            onClick={save}
            variant="contained"
            fullWidth
            disabled={disabled}
          >
            { loading ? 'Saving...' : 'Save' }
          </PrimaryButton>
        </>
      ) }
    </CreateActivityFormContainer>
  );
};
