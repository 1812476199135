import { isPresent } from '@healthiqeng/core.util.is-present';
import { InputTransformer } from '../InputTransformer';

export class EmailTransformer extends InputTransformer<string> {
  constructor(initialValue: string) {
    super(initialValue);
    this.draftValue = initialValue;
  }

  public update(nextValue: string) {
    this.updateDraftValue(nextValue);
    this.updatePersistedValue();
  }

  public getDraftValue(): string {
    return EmailTransformer.normalizeEmail(this.draftValue);
  }

  public getPersistedValue(): string {
    return this.persistedValue;
  }

  public getFormattedValue(): string {
    return EmailTransformer.normalizeEmail(this.getPersistedValue());
  }

  private updateDraftValue(nextValue: string) {
    this.draftValue = EmailTransformer.normalizeEmail(nextValue);
  }

  private updatePersistedValue() {
    if ((this.draftValue ?? '') === '') {
      this.persistedValue = null;
    }

    const validEmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
    if (validEmailRegex.test(this.draftValue ?? '')) {
      this.persistedValue = this.draftValue;
    }
  }

  public static normalizeEmail(value: string): string {
    if (!isPresent(value)) {
      return '';
    }

    return value.toLowerCase();
  }
}
