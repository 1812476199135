import { gql } from 'apollo-boost';
import { MutationCustomReturn, useMutationCustom } from '../useMutationCustom';
import {
  CreateActivityMutationResponse,
  CreateActivityMutationVariables,
} from './useCreateActivityMutation.interfaces';
import {
  MY_ACTIVITIES_QUERY,
  ACTIVITIES_QUERY,
  MyActivitiesQueryData,
  ActivitiesQueryVariables,
  AllActivitiesQueryData,
} from '../../queries/useActivitiesQuery';
import { LEAN_ACTIVITY_FRAGMENT } from '../../fragments';

const CREATE_ACTIVITY_MUTATION = gql`
  mutation CreateActivity($customerId: ID!, $options: ActivityInput!) {
    createActivity(customerId: $customerId, options: $options) {
      code
      success
      message
      timestamp
      type
      activities {
        ... LeanActivityFragment
      }
      myActivities {
        ... LeanActivityFragment
      }
    }
  }
  ${LEAN_ACTIVITY_FRAGMENT}
`;

export function useCreateActivityMutation(customerId: string, activityFeedId: string): MutationCustomReturn<
CreateActivityMutationResponse,
CreateActivityMutationVariables> {
  return useMutationCustom<CreateActivityMutationResponse, CreateActivityMutationVariables>(CREATE_ACTIVITY_MUTATION, {
    update: (cache, result) => {
      cache.writeQuery<MyActivitiesQueryData, ActivitiesQueryVariables>({
        query: MY_ACTIVITIES_QUERY,
        variables: {
          customerId,
          activityFeedId,
          offset: 0,
          limit: 10,
          pullTasks: true,
        },
        data: {
          myActivities: result.data.createActivity.myActivities,
        },
      });
      cache.writeQuery<AllActivitiesQueryData, ActivitiesQueryVariables>({
        query: ACTIVITIES_QUERY,
        variables: {
          customerId,
          activityFeedId,
          offset: 0,
          limit: 10,
          pullTasks: true,
        },
        data: {
          activities: result.data.createActivity.activities,
        },
      });
    },
  });
}
