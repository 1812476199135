export const formatCurrency = (amount: string) => {
  if (!amount) {
    return '$';
  }

  return `$${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatWithCommas = (num: string) => {
  if (!num) {
    return '';
  }

  return `${num}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatPhoneNumber = (num: string) => {
  if (!num) {
    return num;
  }
  let cleaned = num.replace(/\D/g, '');
  if (cleaned.length === 11 && cleaned[0] === '1') {
    cleaned = cleaned.substring(1, 11);
  }

  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return cleaned;
};

const currencyFormatterTruncated = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

export function formatCurrencyTruncated(amount: number, truncate: boolean = true, shorten: boolean = true) {
  if (!amount && amount !== 0) {
    return '';
  }
  const oneMillion = 1000000;
  const oneThousand = 1000;
  if (shorten) {
    if (amount >= oneMillion) {
      if (Number.isInteger(amount / oneMillion)) {
        return `$${amount / oneMillion}M`;
      }

      if (Number.isInteger(amount / (oneMillion / 10))) {
        return `$${(amount / oneMillion).toFixed(1)}M`;
      }

      if (Number.isInteger(amount / (oneMillion / 100))) {
        return `$${(amount / oneMillion).toFixed(2)}M`;
      }
    } else if (amount >= oneThousand) {
      if (Number.isInteger(amount / oneThousand)) {
        return `$${amount / oneThousand}K`;
      }

      if (Number.isInteger(amount / (oneThousand / 10)) && amount < 100 * oneThousand) {
        return `$${(amount / oneThousand).toFixed(1)}K`;
      }
    }
  }

  if (truncate || Number.isInteger(amount)) {
    return currencyFormatterTruncated.format(amount);
  }

  return currencyFormatter.format(amount);
}

export function parsePhoneNumber(phoneNum: String) {
  if (!phoneNum) {
    return '';
  }
  return phoneNum.replace(/[^0-9]/g, '');
}
