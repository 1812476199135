import * as React from 'react';
import moment, { Moment } from 'moment';
import { DatePickerInputProps } from './DatePickerInput.interfaces';
import { DatePicker } from '@material-ui/pickers';
import {
  DatePickerInputContainer,
} from './DatePickerInput.elements';

export const DatePickerInput: React.FunctionComponent<DatePickerInputProps> = ({
  value,
  onChange,
  label,
  fullWidth,
  size,
  disabled,
}) => {
  const handleChange = React.useCallback((date: Moment) => {
    const parsedDate = moment
      .utc(date.format('YYYY-MM-DD'))
      .startOf('day')
      .toISOString();
    onChange(parsedDate);
  }, [onChange]);

  const shiftedValue = React.useMemo(() => value && moment
    .utc(value as string)
    .subtract(moment().utcOffset(), 'minutes'),
  [value]);

  return (
    <DatePickerInputContainer>
      <DatePicker
        value={shiftedValue}
        onChange={handleChange}
        inputVariant="outlined"
        fullWidth={fullWidth}
        label={label}
        size={size}
        disabled={disabled}
        minDate={new Date()}
      />
    </DatePickerInputContainer>
  );
};
