import * as React from 'react';
import { MedicareApplicationType } from '@hiq/crm.types';
import { CircularProgress, Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { SecondaryButton } from '../../inputs/SecondaryButton';
import { useActiveLeadId } from '../../../routers/LeadRouter';
import { useUnderwritingValidation } from './useUnderwritingValidation';
import { UnderwritingResponseScript } from './UnderwritingResponseScript';
import {
  useLeadQuery,
  useAutomatedUnderwritingMutation,
  useLatestUnderwritingResultQuery,
} from '../../../../graphql';
import {
  UnderwritingSubmitProps,
  AutomatedUnderwritingResult,
} from './SubmitUnderwriting.interfaces';
import {
  UnderwritingResultContaner,
  UnderwritingResultRow,
  UnderwritingValidationWarningContainer,
  UnderwritingValidationWarning,
} from './SubmitUnderwriting.elements';

export const UnderwritingSubmit: React.FunctionComponent<UnderwritingSubmitProps> = ({ onResult }) => {
  const leadId = useActiveLeadId();
  const { data: leadData } = useLeadQuery(leadId);
  const { loading: loadingValidation, valid, warnings } = useUnderwritingValidation();
  const { data: latestUnderwritingResult, refetch: fetchLatestResult } = useLatestUnderwritingResultQuery(leadId);
  const [runUnderwriting, { data: underwritingResponse, error, loading: runningUnderwriting }] = useAutomatedUnderwritingMutation();
  const result = underwritingResponse?.automatedUnderwriting?.result ?? latestUnderwritingResult?.fetchLatestUnderwritingResults?.result;
  const loading = loadingValidation || runningUnderwriting;
  const disabled = loading || !valid || !!result;
  const isUnderwritten = (leadData as any).applicationType === MedicareApplicationType.Underwritten;

  const onSubmit = React.useCallback(async () => {
    await runUnderwriting({ leadId });
  }, [runUnderwriting]);

  React.useEffect(() => {
    fetchLatestResult();
    if (onResult) { onResult(result as AutomatedUnderwritingResult); }
  }, [result, leadId]);

  React.useEffect(() => {
    fetchLatestResult();
  }, []);

  return (
    <div>
      <SecondaryButton
        disabled={disabled}
        onClick={onSubmit}
        variant="contained"
        fullWidth
      >
        {isUnderwritten
          ? 'Run Auto Underwriting'
          : 'Finalize Rate'}
        { loading && <CircularProgress color="secondary" size={16} style={{ marginLeft: 10 }} /> }
      </SecondaryButton>
      {result && (
        <UnderwritingResultContaner>
          <UnderwritingResultRow>
            <strong>Decision:</strong>
            {' '}
            {result.decision}
          </UnderwritingResultRow>
          {result.decision !== 'Declined' && (
            <UnderwritingResultRow>
              <strong>Approved Premium:</strong>
              {result.approvedPremium}
            </UnderwritingResultRow>
          )}
          <UnderwritingResultRow>
            <strong>Plan:</strong>
            {' '}
            {result.plan}
          </UnderwritingResultRow>
          <UnderwritingResultRow>
            <strong>Household Discount Applied:</strong>
            {' '}
            {result.appliedHouseholdDiscount ? 'Yes' : 'No'}
          </UnderwritingResultRow>
          <UnderwritingResultRow>
            <strong>Tobacco:</strong>
            {' '}
            {result.appliedTobacco ? 'Yes' : 'No'}
          </UnderwritingResultRow>
        </UnderwritingResultContaner>
      )}
      {!!warnings?.length && (
        <UnderwritingValidationWarningContainer>
          {warnings.map((warning) => (
            <UnderwritingValidationWarning>
              <Alert key={warning} color="warning">{warning}</Alert>
            </UnderwritingValidationWarning>
          ))}
        </UnderwritingValidationWarningContainer>
      )}
      {isUnderwritten && result && (
        <UnderwritingResponseScript
          leadId={leadId}
          decision={result?.decision}
          preferredResponse={result?.preferredResponse}
        />
      )}
      <Snackbar open={!!error} autoHideDuration={5000}>
        <Alert color="error">
          <AlertTitle>Error while running underwriting</AlertTitle>
          <p>{error?.message}</p>
        </Alert>
      </Snackbar>
    </div>
  );
};
