import * as React from 'react';
import { CustomerCallComplianceProps } from './CustomerCallCompliance.interfaces';
import {
  CustomerCallComplianceContainer,
  CustomerCallComplianceText,
} from './CustomerCallCompliance.elements';
import { Icon } from '../../../common';
import { LeadCallDirection, useLeadCallDirection } from '../../../common/CheckMyHealthRecord/CheckMyHealthRecordHipaaAnswer/hooks/useLeadCallDirection';
import { ThemeLight } from '../../../../themes';

export const CustomerCallCompliance: React.FunctionComponent<CustomerCallComplianceProps> = () => {
  const leadCallDirection = useLeadCallDirection();

  return (
    <CustomerCallComplianceContainer backgroundColor={BACKGROUND_COLORS[leadCallDirection]}>
      {ICONS[leadCallDirection]}
      <CustomerCallComplianceText data-qa="customer-call-compliance">
        {leadCallDirection}
      </CustomerCallComplianceText>
    </CustomerCallComplianceContainer>
  );
};

const BACKGROUND_COLORS = {
  [LeadCallDirection.INBOUND]: ThemeLight.color.accent,
  [LeadCallDirection.OUTBOUND]: ThemeLight.color.error,
};

const ICONS = {
  [LeadCallDirection.INBOUND]: <Icon type="call-received" />,
  [LeadCallDirection.OUTBOUND]: <Icon type="call-made" />,
};
