import styled from '@emotion/styled';
import { Button, TextField } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  grid-gap: 8px;
`;

export const SendPPEContainer = styled.div`
    width: 600px;
    padding: 24px;
    height: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
`;

export const SendPPETextInput = styled(TextField)`
    width: 100%;
    grid-template-columns: 1fr 1fr !important;
`;

export const ConfirmButton = withStyles({
  root: {
    background: '#0075CA',
    width: '116px',
    color: 'white',
    '&:hover': {
      background: 'rgba(0,117,202,0.8)',
    },
  },
})(Button);

export const CancelButton = withStyles({
  root: {
    background: 'white',
    width: '116px',
    borderColor: '#0075CA',
    color: '#0075CA',
    '&:hover': {
      background: 'rgba(255,242,255,0.7)',
    },
  },
})(Button);

export const SendPPEWarning = styled.span`
    margin-bottom: 32px;
`;

export const SendPPETitle = styled.span`
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: #000000;
`;

export const SendPPEInvalidEmail = styled.span`
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.25px;
    color: #E14747;
`;
