import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const LockedInfoContainer = styled('div')(css`
  padding: 0.1rem 1rem;
  background-color: #ffe2a0;
  display: flex;
  align-items: center;
`);

export const LockedInfoText = styled('p')(css`
    margin-left: 0.5rem;
`);
