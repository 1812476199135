import * as React from 'react';
import { TextRendererProps } from './TextRenderer.interfaces';
import {
  TextRendererContainer,
} from './TextRenderer.elements';

export const TextRenderer: React.FunctionComponent<TextRendererProps> = ({ value }) => (
  <TextRendererContainer>
    { value }
  </TextRendererContainer>
);
