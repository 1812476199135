import { useActiveLeadId } from '../../../routers';
import { useFormAnswers } from '../../../../hooks';
import moment from 'moment';

export function useCheckMyHealthRecordConsent() {
  const leadId = useActiveLeadId();
  const [answers] = useFormAnswers(leadId, false);

  const hipaaApproved = answers['customer.person.hipaaConsentApproved'];
  const hipaaApprovedDate = moment(answers['customer.person.hipaaConsentDatetime']);
  const hipaaApprovedDateFormatted = hipaaApprovedDate.format('M/D/YYYY');
  const hipaaApprovalExpireDate = hipaaApprovedDate.add(2, 'year').format('M/D/YYYY');
  const consentGiven = hipaaApproved && hipaaApprovedDate;

  return {
    consentGiven,
    hipaaApproved,
    hipaaApprovedDateFormatted,
    hipaaApprovalExpireDate,
  };
}
