import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../../../themes';
import { css } from '@emotion/core';

export const QuestionContainer = styled.div`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  background-color: #F3F5F7;
`;

export const QuestionContent = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  padding: 24px;
  background-color: ${theme.color.grayBackground};
  border: 1px solid ${theme.color.grayLight};
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  width: 100%;
`);

export const Column = styled.div`
  display: grid;
  grid-gap: 10px;
  width: 100%;    
  justify-items: flex-start;
`;

export const QuestionTextContainer = styled.div`
  width: 100%; 
`;
