import * as React from 'react';
import { QuoteCardProps } from './QuoteCard.interfaces';
import { Card } from '../../Card';
import { QuoteCellFactory } from '../QuoteCellFactory';
import { QuoteNotes } from '../QuoteNotes';

export const QuoteCard: React.FunctionComponent<QuoteCardProps> = ({
  quote,
  notes,
  highlight = false,
  onPin,
  isPinned = false,
}) => (
  <Card
    key={quote?.hash}
    margin="20px 0"
    padding="20px"
    highlight={highlight}
  >
    <QuoteCellFactory
      type={quote?.product?.type}
      hash={quote?.hash}
      onPin={onPin}
      isPinned={isPinned}
    />
    <QuoteNotes notes={notes} isSpecialRate={quote?.product?.isSpecialRate} />
  </Card>
);
