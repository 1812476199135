import * as React from 'react';
import {
  Card,
  QueryLoader,
  PropertyAndCasualtyQuoteOptions,
  QuoteSelector,
} from '../../../../../../../common';
import { NoQuoteContainer, NoQuoteHeader, ErrorsHeader } from './PropertyAndCasualtyQuoter.elements';
import { useActiveLeadId } from '../../../../../../../routers';
import { isEmpty } from '@healthiqeng/core.util.is-empty';
import { PropertyAndCasualtyQuoterProps } from './PropertyAndCasualtyQuoter.interfaces';
import { isPresent } from '@healthiqeng/core.util.is-present';
import { TweakTypeEnum } from '@hiq/crm.types';
import { useFormAnswers } from '../../../../../../../../hooks';

export const PropertyAndCasualtyQuoter: React.FunctionComponent<PropertyAndCasualtyQuoterProps> = ({
  getQuotes,
  quoteResults,
  quoteErrors,
  loading,
}) => {
  const leadId = useActiveLeadId();
  const [answers] = useFormAnswers(leadId);
  const REQUIRED_QUESTIONS_IDS = [
    'customer.person.name.first',
    'customer.person.name.last',
    'customer.person.homeAddress.zip',
    'customer.person.homeAddress.street',
    'customer.person.homeAddress.city',
    'customer.person.homeAddress.county',
    'customer.person.homeAddress.state.code',
    'customer.person.dateOfBirth',
    'customer.person.gender',
  ];
  const [missingQuestions, setMissingQuestions] = React.useState([]);
  const parsePersonQuestionId = (id: string) => id.replace('customer.person.', '');
  React.useEffect(() => {
    const missing: string[] = [];
    REQUIRED_QUESTIONS_IDS.forEach((q) => {
      if (!isPresent(answers[q])) {
        missing.push(parsePersonQuestionId(q));
      }
    });
    setMissingQuestions(missing);
  }, [answers]);

  const isDisabled = loading || !isEmpty(missingQuestions);

  return (
    <>
      <Card>
        <PropertyAndCasualtyQuoteOptions
          onQuote={(inputs: any) => getQuotes({ variables: { leadId, inputs } })}
          isDisabled={isDisabled}
          isFetching={loading}
        />
      </Card>

      <QueryLoader loading={loading} componentName="PropertyAndCasualtyQuoter">
        <>
          { !isEmpty(quoteErrors) && (
            <Card
              key="quote-errors"
              margin="20px 0"
              padding="20px"
            >
              <ErrorsHeader>Errors:</ErrorsHeader>
              <ul>
                { quoteErrors?.map((err) => <li>{ err }</li>)}
              </ul>
            </Card>
          )}
          {
            !isEmpty(missingQuestions) && (
              <Card
                key="missing-questions"
                margin="20px 0"
                padding="20px"
              >
                <NoQuoteContainer>
                  <ErrorsHeader>Missing Questions:</ErrorsHeader>
                  <ul>
                    { missingQuestions?.map((q) => <li>{ q }</li>)}
                  </ul>
                </NoQuoteContainer>
              </Card>
            )
          }
          <QuoteSelector
            quoteResults={quoteResults}
            isHighlight={(quote) => quote?.product?.tweakType?.type !== TweakTypeEnum.Retail}
          />
          { (quoteResults && isEmpty(quoteResults)) && (
            <Card
              key="no-quotes"
              margin="20px 0"
              padding="20px"
            >
              <NoQuoteContainer>
                <NoQuoteHeader>
                  No quote results found.
                </NoQuoteHeader>
              </NoQuoteContainer>
            </Card>
          ) }
        </>
      </QueryLoader>
    </>
  );
};
