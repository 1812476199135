import React from 'react';
import { FormItemTag, QuestionType } from '@hiq/crm.types';
import { FollowUpQuestionCell } from '../ReflexiveQuestionInput/FollowUpQuestionCell';
import { FollowUpCompomentProps } from '../ReflexiveQuestionInput';

const NO_TAGS: FormItemTag[] = [];
const REQUIRED_TAGS = [FormItemTag.RequiredToConvert, FormItemTag.RequiredToQuote];

export const BankersHealthConditionDetailQuestions: React.FunctionComponent<FollowUpCompomentProps> = React.memo(({
  questionId, answers, onChange,
}) => {
  const diagnosisDateQuestionId = `${questionId}.diagnosisDate`;
  const isTreatedQuestionId = `${questionId}.isTreated`;
  const lastTreatmentQuestionId = `${questionId}.lastTreatmentDate`;
  const medicationsQuestionId = `${questionId}.medications`;
  const doctorInfo = `${questionId}.doctorInfo`;
  return (
    <>
      <FollowUpQuestionCell
        questionId={diagnosisDateQuestionId}
        questionText="When were you diagnosed/when did this occur?"
        type={QuestionType.Date}
        value={answers[diagnosisDateQuestionId]}
        onChange={onChange}
        tags={REQUIRED_TAGS}
      />
      <FollowUpQuestionCell
        questionId={isTreatedQuestionId}
        questionText="Is treated?"
        type={QuestionType.RadioYesNo}
        value={answers[isTreatedQuestionId]}
        onChange={onChange}
        tags={NO_TAGS}
      />
      <FollowUpQuestionCell
        questionId={lastTreatmentQuestionId}
        questionText="When were you last treated or medicated for this?"
        type={QuestionType.Date}
        value={answers[lastTreatmentQuestionId]}
        onChange={onChange}
        tags={NO_TAGS}
      />
      <FollowUpQuestionCell
        questionId={medicationsQuestionId}
        questionText="What medication(s) are you taking?"
        type={QuestionType.Text}
        value={answers[medicationsQuestionId]}
        onChange={onChange}
        tags={NO_TAGS}
      />
      <FollowUpQuestionCell
        questionId={doctorInfo}
        questionText="Doctor info"
        type={QuestionType.TextArea}
        value={answers[doctorInfo]}
        onChange={onChange}
        tags={NO_TAGS}
      />
    </>
  );
});
