import { gql } from 'apollo-boost';
import { MutationCustomReturn, useMutationCustom } from '../useMutationCustom';
import { LEAD_FRAGMENT } from '../../fragments/lead';
import {
  RefreshContactDataMutationResponse,
  RefreshContactDataMutationVariables,
} from './useRefreshContactDataMutation.interfaces';

const REFRESH_CONTACT_DATA_MUTATION = gql`
  mutation RefreshContactData($leadId: ID!) {
    refreshContactData(leadId: $leadId) {
      code
      success
      message
      timestamp
      lead {
        ...LeadFragment
      }
    }
  }
  ${LEAD_FRAGMENT}
`;

export function useRefreshContactDataMutation(): MutationCustomReturn<
RefreshContactDataMutationResponse,
RefreshContactDataMutationVariables> {
  return useMutationCustom<
  RefreshContactDataMutationResponse,
  RefreshContactDataMutationVariables>(REFRESH_CONTACT_DATA_MUTATION);
}
