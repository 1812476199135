import { FormAnswers } from '@hiq/crm.types';

export function getAllSubKeys(answers: FormAnswers, formId: string): Set<string> {
  return Object.keys(answers)
    .filter((key) => key.startsWith(formId))
    .map((key) => key.replace(`${formId}.`, ''))
    .map((key) => key.replace(/^\d+\./, ''))
    .filter((key) => !key.startsWith('__'))
    .reduce((allKeys, currentKey) => allKeys.add(currentKey), new Set<string>());
}
