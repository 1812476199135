import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../themes';

export const CustomerLayoutContainer = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  background-color: ${theme.color.white};
`);

export const InfoPanelContainer = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  display: flex;
  background-color: ${theme.color.white};
`);

export const MainTabPanelContainer = styled('div')(css`

`);

export const ToolPanelContainer = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  height: 100%;
  background-color: ${theme.color.white};
  overflow-y: scroll;
`);
