import { isPresent } from '@healthiqeng/core.util.is-present';
import { capitalCase } from 'change-case';
import React from 'react';
import { DatagridProps, Datagrid, DatagridDataType } from '../../Datagrid';
import { Icon } from '../../Icon/Icon';
import { ConfirmButton } from '../../inputs/ConfirmButton';
import { VerifyUserGridProps } from './VerifyUserGrid.interfaces';

export const VerifyUserGrid = React.memo(({ data, handleVerifyUser }: VerifyUserGridProps<any>) => {
  const dataToDisplay: any[] = [];
  const idsByRow: string[] = [];
  data.forEach(({
    name, addressLine1, addressLine2, phone, city, state, lastVisit, userDoctorId,
  }) => {
    dataToDisplay.push({
      name,
      addressLine1,
      addressLine2,
      phone,
      city,
      state,
      lastVisit,
    });

    idsByRow.push(userDoctorId);
  });

  const datagridProps: DatagridProps<any> = React.useMemo(() => ({
    data,
    columns: getColumnDefinitionFromData(dataToDisplay),
    enableSelection: true,
    postDatagridContent: (data) => {
      const selectedRows = Object.entries(data.selectedRowIds).filter(([, v]) => v).map(([k]) => k);
      const idsToSend = selectedRows.map((item) => idsByRow[parseInt(item)]);
      return (
        <ConfirmButton
          confirmText="Verify"
          cancelText="Cancel"
          title="Verify"
          label="Verify"
          disabled={selectedRows.length === 0}
          endIcon={<Icon type="done" />}
          onConfirm={() => handleVerifyUser(idsToSend)}

        >
          Please click verify if the user provided you with the correct doctor names shown in the doctors list.
        </ConfirmButton>
      );
    },
  }), []);
  return (
    <>
      <Datagrid {...datagridProps} />
    </>
  );
});

function getColumnDefinitionFromData<D>(data: D[]): DatagridProps<D>['columns'] {
  if (!data.length) return [];
  const keys = Object.keys(data[0]).filter((k) => k !== '__typename');
  const maxLengthByKey = Object.keys(data[0]).reduce((maxLengthByKey, currentKey) => {
    const maxLength = data.map((obj) => obj[currentKey as keyof D])
      .reduce((max, v) => Math.max(max, v?.toString()?.length ?? 0), 0);
    return {
      ...maxLengthByKey,
      [currentKey]: maxLength,
    };
  }, {} as { [key: string]: number });

  const typeByKey = Object.keys(data[0]).reduce((typeByKey, currentKey) => {
    const type: DatagridDataType = data.map((obj) => obj[currentKey as keyof D])
      .reduce(
        (type, v) => (!type && isPresent(v) ? inferTypeFromData(v) : type),
        undefined as DatagridDataType,
      );
    return {
      ...typeByKey,
      [currentKey]: type ?? DatagridDataType.Text,
    };
  }, {} as { [key: string]: DatagridDataType });

  return keys.map((k) => {
    const type = typeByKey[k];
    return {
      header: capitalCase(k),
      accessor: k as keyof D,
      type,
      width: (maxLengthByKey[k] * 10) + getBaseWidthByType(type), // width in px
    };
  });
}

function inferTypeFromData(data: any): DatagridDataType {
  if (typeof data === 'number') return DatagridDataType.Number;
  if (typeof data === 'string') {
    if (/^\d{5}$/g.test(data)) return DatagridDataType.Text; // Zip Code
    const isoDateRegex = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/;
    if (isoDateRegex.test(data)) return DatagridDataType.Date;
    if (/^\d{10}$/g.test(data)) return DatagridDataType.Phone;
    return DatagridDataType.Text;
  }
  if (data === null || data === undefined) return undefined;
  throw new Error(`Could not infer data grid type from data: "${data}"`);
}

function getBaseWidthByType(type: DatagridDataType): number {
  const cellPadding = 28;
  switch (type) {
    case DatagridDataType.Text:
    case DatagridDataType.Number: return cellPadding + 10;
    case DatagridDataType.Phone: return cellPadding + 30;
    case DatagridDataType.Date: return cellPadding - 100;
    default:
      throw new Error(`Unexpected data type: ${type}`);
  }
}
