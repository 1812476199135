import * as React from 'react';
import { ResponsiveBr } from './ResponsiveBreaksParser.elements';
import { IResponsiveBreaksParserProps } from './ResponsiveBreaksParser.interfaces';

export class ResponsiveBreaksParser extends React.PureComponent<IResponsiveBreaksParserProps> {
  private static parse(source: string) {
    const regExp = /({mobile_xs}|{mobile}|{tablet}|{desktop})/;
    return source.split(regExp).map((item, i) => {
      switch (item) {
        case '{mobile_xs}': return <ResponsiveBr key={i} visibleOn="mobile_xs" />;
        case '{mobile}': return <ResponsiveBr key={i} visibleOn="mobile" />;
        case '{tablet}': return <ResponsiveBr key={i} visibleOn="tablet" />;
        case '{desktop}': return <ResponsiveBr key={i} visibleOn="desktop" />;
        default: return item;
      }
    });
  }

  public render() {
    return (
      <>
        {ResponsiveBreaksParser.parse(this.props.source)}
      </>
    );
  }
}
