import React, {
  memo, FunctionComponent, useCallback, useState,
} from 'react';
import {
  Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText,
} from '@material-ui/core';
import { useLocation } from '@reach/router';
import { APPLICATION_PANEL_ID, LIFE_QUOTING_PANEL_ID } from '../../../../panels/Lead/LifeLead/LifeLead.interfaces';
import { PrimaryButton } from '../../PrimaryButton';
import { SpecialRatePromptProps } from './SpecialRatePrompt.interfaces';
import { noop } from '../../../../../util';

export const SpecialRatePrompt: FunctionComponent<SpecialRatePromptProps> = memo(({ onClose }) => {
  const { pathname } = useLocation();
  const quotingUrl = `${pathname.substr(0, pathname.lastIndexOf(`/${APPLICATION_PANEL_ID}`) + 1)}${LIFE_QUOTING_PANEL_ID}`;
  const [open, setOpen] = useState(true);
  const openDialog = useCallback(() => {
    setOpen(true);
    onClose();
  }, [setOpen]);
  const closeDialog = useCallback(() => {
    setOpen(false);
    onClose();
  }, [setOpen]);
  return (
    <Dialog open={open} onClose={openDialog}>
      <DialogTitle>Special Rate Available</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Since Health IQ is also the only company rewarding the health conscious with special rate life insurance,
          would you like to take a quick 10 question quiz on general health knowledge? (We can apply the results and it
          can only help the rate.)
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <PrimaryButton onClick={closeDialog}>No</PrimaryButton>
        <PrimaryButton onClick={noop} href={quotingUrl}>Yes</PrimaryButton>
      </DialogActions>
    </Dialog>
  );
});
