import { gql } from 'apollo-boost';
import { MutationCustomReturn, useMutationCustom } from '../useMutationCustom';
import { ApproveHipaaConsentMutationResponse, ApproveHipaaConsentMutationVariables } from './useApproveHipaaConsentMutation.interfaces';

const APPROVE_HIPAA_CONSENT_MUTATION = gql`
  mutation ApproveHipaaConsentMutation($customerId: ID!) {
    approveHipaaConsent(customerId: $customerId) {
       success
       message
       digitalMedUserId
       hipaaStatus
       hipaaApprovedDate
    }
  }
`;

export function useApproveHipaaConsentMutation()
: MutationCustomReturn<ApproveHipaaConsentMutationResponse, ApproveHipaaConsentMutationVariables> {
  return useMutationCustom<ApproveHipaaConsentMutationResponse, ApproveHipaaConsentMutationVariables>(APPROVE_HIPAA_CONSENT_MUTATION,
    { refetchQueries: ['GetCustomerHealthRecordStatusQuery', 'BionicFetchHealthRecords'] });
}
