import { ActivityType, FollowUpDetailProperties, Uuid } from '@hiq/crm.types';

export interface AppointmentProps {
  type: ActivityType;
  timestamp: string;
  lead?: {
    id: string;
    customer?: {
      id: Uuid;
      person?: {
        name?: {
          first: string;
          last: string;
        };
      };
    }
  };
  appointmentTime?: string;
  detail?: FollowUpDetailProperties
}

export interface NavBarAppointmentProps {
  type: 'last' | 'next';
  title: string;
  appointment: AppointmentProps;
}

export enum NavBarContentVariation {
  Primary = '#4CA9ED',
  Warning = '#FFA600',
  Critical = '#E14747'
}
