import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { GetFutureBenefitOptionsData } from './useGetFutureBenefitOptions.interfaces';

const GET_FUTURE_BENEFIT_OPTIONS = gql`
  query GetFutureBenefitOptions {
    getFutureBenefitOptions {
       code
       codeType
       codeSubtype
       hiqDescription
       quantityApplicable
       quantityDefault
      }
  }
`;

export function useGetFutureBenefitOptions() {
  return useQuery<GetFutureBenefitOptionsData>(GET_FUTURE_BENEFIT_OPTIONS);
}
