import { renderStylesToString } from 'emotion-server';
import * as React from 'react';
import ReactDomServer from 'react-dom/server';
import Helmet from 'react-helmet';

export function renderPage(Element: JSX.Element, mountingPointId: string, bundlePath: string, additionalHtml: string = '') {
  const appHtml = renderStylesToString(ReactDomServer.renderToString(Element));
  const helmet = Helmet.renderStatic();
  return `
    <!DOCTYPE html>
    <html lang="en">
      <head>
        <style>
          body {
            margin: 0;
            padding: 0;
            min-width: 320px;
          }
        </style>
        ${helmet.title.toString()}
        ${helmet.meta.toString()}
        ${helmet.link.toString()}
        ${helmet.script.toString()}
      </head>
      <body ${helmet.bodyAttributes.toString()}>
        <div id=${mountingPointId}>${appHtml}</div>
        ${additionalHtml}
        <script src="${process.env.ASSETS_PATH}${bundlePath}" async></script>
      </body>
    </html>
  `;
}
