import * as React from 'react';
import { graphqlClient, internalAuthService } from '../../../services';
import { MuiThemeLight, ThemeLight } from '../../../themes';
import { AuthenticatedPage } from '../../common';
import { NavBar } from '../../panels/NavBar';
import { OAuthApps } from './OAuthApps';

export const UserOAuthApps: React.FunctionComponent = () => (
  <AuthenticatedPage
    title="HIQ CRM | OAuth Apps"
    theme={ThemeLight}
    muiTheme={MuiThemeLight}
    graphqlClient={graphqlClient}
    internalAuthService={internalAuthService}
  >
    <>
      <NavBar title="TRM | Agent Home" />
      <OAuthApps />
    </>
  </AuthenticatedPage>
);
