import { useMemo } from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import {
  FORM_FRAGMENT, QUESTION_FLOW_FRAGMENT, QUESTION_FRAGMENT,
} from '../../fragments';
import {
  QuestionFlowsQueryData,
  QuestionFlowsQueryVariables,
  UseQuestionFlowsQueryOptions,
} from './useQuestionFlowsQuery.interfaces';
import { VariationChangeEvent, useEventListener } from '../../../events';
import { logger } from '../../../services';
import { getQuestionFlowDataId } from '../../../util/dataIds';
import { parseData } from '../useQuestionFlowQuery';

export const QUESTION_FLOWS_QUERY = gql`
  query getQuestionFlows($leadId: ID!, $referenceIds: [String!], $shallow: Boolean, $callUrlPath: String) {
    questionFlows(leadId: $leadId, referenceIds: $referenceIds, shallow: $shallow, callUrlPath: $callUrlPath) {
      questionFlows {
        questionFlow {
          ...QuestionFlowFragment
        }
        questionFlows {
          ...QuestionFlowFragment
        }
        forms {
          ...FormFragment
        }
        questions {
          ...QuestionFragment
        }
      }
    }
  }
  ${QUESTION_FLOW_FRAGMENT}
  ${FORM_FRAGMENT}
  ${QUESTION_FRAGMENT}
`;

export function useQuestionFlowsQuery(leadId: string, referenceIds: string[], options: UseQuestionFlowsQueryOptions = {}) {
  const apolloClient = useApolloClient();
  const {
    loading, error, data, refetch,
  } = useQuery<QuestionFlowsQueryData, QuestionFlowsQueryVariables>(QUESTION_FLOWS_QUERY, {
    variables: {
      leadId,
      referenceIds,
      shallow: !!options?.shallow,
      allowExternal: !!options?.allowExternal,
      callUrlPath: options?.callUrlPath,
    },
  });

  useEventListener(VariationChangeEvent, () => {
    refetch().catch((err) => logger.crit(err));
  });

  return {
    loading,
    error,
    data: useMemo(() => data && data?.questionFlows?.questionFlows.map((mFlow, idx) => {
      const dataId = getQuestionFlowDataId({ leadId, referenceId: referenceIds[idx] });
      apolloClient.writeFragment({
        id: dataId,
        fragment: QUESTION_FLOW_FRAGMENT,
        data: mFlow.questionFlow,
      });
      return parseData(mFlow);
    }), [data, getQuestionFlowDataId, apolloClient, parseData]),
  };
}
