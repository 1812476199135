import * as React from 'react';
import { QueryLoader } from '../../../../../common/QueryLoader';
import { QuestionCell } from '../../../../../common/FormGenerator/FormGeneratorCompact/Question/QuestionCell';
import { QuestionValueEditable } from '../../../../../common/FormGenerator/FormGeneratorCompact/Question/QuestionView/QuestionValueEditable';
import { useSoldLeadInfoQuery } from '../../../../../../graphql';
import { LeadPostSaleSubStage, QuestionType } from '@hiq/crm.types';
import { LeadSoldInfoProps } from './LeadSoldInfo.interfaces';
import { formatDate } from '@healthiqeng/core.util.format-date';
import {
  getFormattedIndustryType, getFormattedIsSold, getFormattedProductType, getFormattedStage, getFormattedSubStage,
} from '../Formatters';
import { LeadStage } from '../LeadInfo.interfaces';
import { useFormAnswers } from '../../../../../../hooks';

export const LeadSoldInfo: React.FunctionComponent<LeadSoldInfoProps> = ({
  leadId,
  isSold,
  industryType,
  productType,
  quoteCarrier,
  quoteProduct,
}) => {
  const [answers] = useFormAnswers();
  const { data, loading } = useSoldLeadInfoQuery(leadId);
  const sunfireEnrollmentCodeId = 'lead.sunfire.enrollmentCode';
  const sunfireEnrollmentCode = answers?.[sunfireEnrollmentCodeId];

  return (
    <QueryLoader loading={loading} componentName="LeadSoldInfo">
      <QuestionCell
        label="SF Stage"
        input={(
          <QuestionValueEditable
            value={getFormattedStage(data?.soldLeadInfo?.stage?.stage as LeadStage)}
            type={QuestionType.Select}
            options={[]}
            locked
          />
        )}
      />

      <QuestionCell
        label="SF Sub Stage"
        input={(
          <QuestionValueEditable
            value={getFormattedSubStage(data?.soldLeadInfo?.stage?.subStage as LeadPostSaleSubStage)}
            type={QuestionType.Text}
            locked
          />
        )}
      />

      <QuestionCell
        label="Is Sold"
        input={<QuestionValueEditable value={getFormattedIsSold(isSold)} type={QuestionType.Text} locked />}
      />
      <QuestionCell
        label="Sold Date"
        input={<QuestionValueEditable value={formatDate(data?.soldLeadInfo?.soldDate)} type={QuestionType.Text} locked />}
      />
      <QuestionCell
        label="Industry Type"
        input={(
          <QuestionValueEditable
            value={getFormattedIndustryType(industryType)}
            type={QuestionType.Text}
            locked
          />
        )}
      />
      <QuestionCell
        label="Quote Product Type"
        input={<QuestionValueEditable value={getFormattedProductType(productType)} type={QuestionType.Text} locked />}
      />
      <QuestionCell
        label="Quote Carrier"
        input={(
          <QuestionValueEditable
            value={quoteCarrier}
            type={QuestionType.Text}
            locked
          />
        )}
      />
      <QuestionCell
        label="Quote Product"
        input={(
          <QuestionValueEditable
            value={quoteProduct}
            type={QuestionType.Text}
            locked
          />
        )}
      />
      <QuestionCell
        label="Enrollment#"
        input={(
          <QuestionValueEditable
            value={sunfireEnrollmentCode}
            type={QuestionType.Text}
            locked
          />
        )}
      />
      <QuestionCell
        label="Final Coverage Amount"
        input={(
          <QuestionValueEditable
            value={data?.soldLeadInfo?.finalCoverageAmount?.formatted}
            type={QuestionType.Text}
            locked
          />
        )}
      />
      <QuestionCell
        label="Final Monthly Premium"
        input={(
          <QuestionValueEditable
            value={data?.soldLeadInfo?.finalPremiums?.monthly?.formatted}
            type={QuestionType.Text}
            locked
          />
        )}
      />
      <QuestionCell
        label="Final Quarterly Premium"
        input={(
          <QuestionValueEditable
            value={data?.soldLeadInfo?.finalPremiums?.quarterly?.formatted}
            type={QuestionType.Text}
            locked
          />
        )}
      />
      <QuestionCell
        label="Final Semianual Premium"
        input={(
          <QuestionValueEditable
            value={data?.soldLeadInfo?.finalPremiums?.semiAnnually?.formatted}
            type={QuestionType.Text}
            locked
          />
        )}
      />
      <QuestionCell
        label="Final Yearly Premium"
        input={(
          <QuestionValueEditable
            value={data?.soldLeadInfo?.finalPremiums?.annually?.formatted}
            type={QuestionType.Text}
            locked
          />
        )}
      />
    </QueryLoader>
  );
};
