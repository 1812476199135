import * as React from 'react';
import { IAnchorProps } from './Anchor.interfaces';

export class Anchor extends React.Component<IAnchorProps> {
  public static attributeName = 'data-anchor';
  public render() {
    return React.createElement('span', {
      id: this.props.name,
      [Anchor.attributeName]: this.props.name,
    });
  }
}
