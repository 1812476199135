import * as React from 'react';
import { QuoteOptionsLayoutProps } from './QuoteOptionsLayout.interfaces';
import {
  QuoteOptionsLayoutContainer,
  QuoteOptionsLayoutColumn,
} from './QuoteOptionsLayout.elements';

export const QuoteOptionsLayout: React.FunctionComponent<QuoteOptionsLayoutProps> = ({
  leftContent,
  rightContent,
  minWidth,
  gutter,
}) => (
  <QuoteOptionsLayoutContainer>
    <QuoteOptionsLayoutColumn minWidth={minWidth} gutter={gutter} isLast={false}>
      { leftContent }
    </QuoteOptionsLayoutColumn>
    <QuoteOptionsLayoutColumn minWidth={minWidth} gutter={gutter} isLast>
      { rightContent }
    </QuoteOptionsLayoutColumn>
  </QuoteOptionsLayoutContainer>
);
