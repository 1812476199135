import React, { ChangeEvent, useMemo, useCallback } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import { CheckboxInputProps } from './CheckboxInput.interfaces';
import { CheckboxItem } from './CheckboxItem';
import { InputWidthLimiterContainer } from '../../InputWidthLimiterContainer';

export const CheckboxInput: React.FunctionComponent<CheckboxInputProps> = ({
  value,
  onChange,
  options,
  disabled,
  max,
  variant = 'default',
}) => {
  const checkedValues = useMemo(() => getCheckedValues(value), [value]);
  const maxAmountSelected = Object.keys(checkedValues).length >= (max ?? options.length);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    onChange(getCheckedValuesFromState({
      ...checkedValues,
      [name]: !checkedValues[name],
    }));
  }, [onChange, checkedValues]);

  return (
    <FormGroup>
      <InputWidthLimiterContainer>
        { options.map((option) => (
          <CheckboxItem
            key={option.value}
            checked={checkedValues[option.value] ?? false}
            label={option.label}
            name={option.value}
            onChange={handleChange}
            disabled={disabled || (!checkedValues[option.value] && maxAmountSelected)}
            variant={variant}
          />
        ))}
      </InputWidthLimiterContainer>
    </FormGroup>
  );
};

function getCheckedValues(values: string[]) {
  if (!values) return {};
  return values.reduce((checked, value) => {
    checked[value] = true; // eslint-disable-line no-param-reassign
    return checked;
  }, {} as { [key: string]: boolean });
}

function getCheckedValuesFromState(state: any) {
  return Object.entries(state).filter(([, value]) => !!value).map(([key]) => key);
}
