import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { ComponentWithThemeProps, ThemeProps } from '../../../themes';
import { SalesTimerStatus } from './useSalesTimer';

interface SalesTimerContainerProps extends ComponentWithThemeProps {
  status: SalesTimerStatus;
}

export const SalesTimerContainer = styled('div')<SalesTimerContainerProps>(({ status, theme }) => css`
  color: white;
  display: flex;
  padding: 4px;
  margin: 10px 0px;
  background-color: ${getBackgroundColor(status, theme)};
  border-radius: 25px;
  min-width: 100px;
  text-align: center;
`);

export const SalesTimerText = styled('span')(css`
  font-weight: bolder;
  font-size: 14px;
  margin: auto;
`);

function getBackgroundColor(status: SalesTimerStatus, theme: ThemeProps): string {
  if (status === SalesTimerStatus.Error) {
    return theme.color.error;
  }
  if (status === SalesTimerStatus.Warning) {
    return theme.color.warning;
  }

  return theme.color.accent;
}
