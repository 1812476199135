import * as luhn from 'luhn';
import { isPresent } from '@healthiqeng/core.util.is-present';
import { InputTransformer } from '../InputTransformer';

export class CreditCardNumberTransformer extends InputTransformer<string> {
  constructor(initialValue: string) {
    super(initialValue);
    this.draftValue = initialValue;
  }

  public update(nextValue: string) {
    this.updateDraftValue(nextValue);
    this.updatePersistedValue();
  }

  public getDraftValue(): string {
    return this.draftValue;
  }

  public getPersistedValue(): string {
    return this.persistedValue;
  }

  public getFormattedValue(): string {
    return formatCreditCard(this.getPersistedValue());
  }

  private updateDraftValue(nextValue: string) {
    this.draftValue = formatCreditCard(CreditCardNumberTransformer.normalizeCreditCardNumber(nextValue));
  }

  private updatePersistedValue() {
    const normalized = CreditCardNumberTransformer.normalizeCreditCardNumber(this.draftValue);
    if (luhn.validate(normalized)) {
      this.persistedValue = normalized;
    }
  }

  public static normalizeCreditCardNumber(value: string): string {
    if (!isPresent(value)) {
      return '';
    }
    return value.replace(/[^0-9]/g, '').substring(0, 19);
  }
}

function formatCreditCard(value: string): string {
  const out: string[] = [];
  value.split('').forEach((digit, index) => {
    if ((index !== 0) && ((index % 4) === 0)) out.push(' ');
    out.push(digit);
  });
  return out.join('');
}
