import * as React from 'react';
import {
  Card,
  MedicareSupplementQuoteOptions,
  QueryLoader, QuoteSelector,
} from '../../../../../../../common';
import { useMedicareSupplementQuoteLazyQuery } from '../../../../../../../../graphql';
import { useActiveLeadId } from '../../../../../../../routers';
import {
  MedicareSupplementNoQuoteHeader,
  MedicareSupplementNoQuoteContainer,
  MedicareSupplementNoQuoteList,
  MedicareSupplementNoQuoteListItem,
} from './MedicareSupplementQuoter.elements';

export const MedicareSupplementQuoter: React.FunctionComponent = () => {
  const [getQuotes, { loading, error, data }] = useMedicareSupplementQuoteLazyQuery();
  const leadId = useActiveLeadId();
  const quoteResults = data?.medicareSupplementQuote?.quoteResults;
  return (
    <>
      <Card>
        <MedicareSupplementQuoteOptions
          onQuote={(inputs) => getQuotes({ variables: { leadId, inputs } })}
          availableRiders={data?.medicareSupplementQuote?.availableRiders ?? []}
          isFetching={loading}
        />
      </Card>
      <QueryLoader loading={loading} error={error} componentName="MedicareSupplementQuoter">
        <>
          <QuoteSelector quoteResults={quoteResults} isHighlight={(quote) => quote?.product?.isSpecialRate} />
          { (quoteResults && !quoteResults.length) && (
            <Card
              key="no-quotes"
              margin="20px 0"
              padding="20px"
            >
              <MedicareSupplementNoQuoteContainer>
                <MedicareSupplementNoQuoteHeader>
                  No quote results found. Make sure that
                </MedicareSupplementNoQuoteHeader>
                <MedicareSupplementNoQuoteList>
                  <MedicareSupplementNoQuoteListItem>
                    Zip code is valid
                  </MedicareSupplementNoQuoteListItem>
                  <MedicareSupplementNoQuoteListItem>
                    Effective date is reasonable (not too far in the past or future)
                  </MedicareSupplementNoQuoteListItem>
                  <MedicareSupplementNoQuoteListItem>
                    Plan is available in your state (WI and MN have special plan types)
                  </MedicareSupplementNoQuoteListItem>
                </MedicareSupplementNoQuoteList>
              </MedicareSupplementNoQuoteContainer>
            </Card>
          ) }
        </>
      </QueryLoader>
    </>
  );
};
