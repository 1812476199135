import React from 'react';
import { CompareDoctorProps } from './CompareDoctorBenefit.interfaces';
import { Bold, CompareData, CompareDataBold } from '../../TRMQuote.elements';
import { CompareDoctorLocations } from '../CompareDoctorLocations';
import { CompareRow, ContentContainer } from './CompareDoctorBenefit.elements';

export const CompareDoctorBenefit = ({
  benefitName, currentDoctor, compareDoctor,
}: CompareDoctorProps) => {
  const { coveredCount: currentValue, locations: currentLocations, pcpIds: currentDoctorPcpIds } = currentDoctor || {};
  const { coveredCount: comparisonValue, locations: comparisonLocations, pcpIds: comparisonDoctorPcpIds } = compareDoctor || {};
  const isCurrentDoctorPcp = !!currentDoctorPcpIds?.length;
  const isComparisonDoctorPcp = !!comparisonDoctorPcpIds?.length;
  return (
    <CompareRow>
      <CompareDataBold>
        {benefitName}
      </CompareDataBold>
      <CompareData empty={!currentValue} highlight={currentValue > comparisonValue}>
        <ContentContainer>
          {currentDoctor && (
            <>
              {isCurrentDoctorPcp && <Bold>(PCP)&nbsp;</Bold>}
              {currentValue && currentLocations?.length
                ? (
                  <CompareDoctorLocations
                    covered={currentValue as number}
                    total={currentLocations.length}
                    locations={currentLocations}
                  />
                ) : 'No locations in network'}
            </>
          )}
        </ContentContainer>
      </CompareData>
      <CompareData empty={!comparisonValue} highlight={comparisonValue > currentValue}>
        <ContentContainer>
          {isComparisonDoctorPcp && <Bold>(PCP)&nbsp;</Bold>}
          {comparisonValue && comparisonLocations?.length
            ? (
              <CompareDoctorLocations
                covered={comparisonValue as number}
                total={comparisonLocations.length}
                locations={comparisonLocations}
              />
            ) : 'No locations in network'}
        </ContentContainer>
      </CompareData>
    </CompareRow>
  );
};
