import React from 'react';
import { QuestionView } from '../../FormGenerator/FormGeneratorQuestionFlow/Question/QuestionView';
import { useActiveLeadId } from '../../../routers/LeadRouter/useActiveLeadId';
import { QuestionType } from '@hiq/crm.types';
import { useFormAnswers } from '../../../../hooks';
import { useCustomerId } from '../../../routers/CustomerRouter/useCustomerId';
import { JsonCompatible } from '@healthiqeng/core.types';

import { emitAnalyticsEventNoLimit } from '../../../../events/emitAnalyticsEvent';
import { useManageChanges } from '../CheckMyHealthRecordHipaaAnswer/hooks/useManageChanges';
import { HipaaConsentAttestContainer } from './CheckMyHealthRecordHipaaConsentAttest.elements';

export const CheckMyHealthRecordHipaaConsentAttest: React.FunctionComponent = () => {
  const leadId = useActiveLeadId();
  const trackingId = React.useMemo(() => `CheckMyHealthRecordHipaaAnswer-${Date.now()}`, []);
  const [answers, onChange, persistAnswers] = useFormAnswers(leadId, false, trackingId);
  const customerId = useCustomerId();
  const questionId = 'customer.person.hipaaConsentAttest';
  const attested = answers[questionId] === true ? ['HIPAA_CONSENT_ATTEST'] : [];

  const hipaaConsentAttestOptions = React.useMemo(() => [
    {
      label:
        `As a HealthIQ employee, I attest that the client consented to the HIPAA disclosure.
        Failure to gather proper consent will result in disciplinary action`,
      value: 'HIPAA_CONSENT_ATTEST',
    },
  ], []);

  const { onConfirmConsentAttest } = useManageChanges(onChange, persistAnswers);

  const onHipaaConsentAttestChange = React.useCallback(
    (value: JsonCompatible) => {
      if (value) emitAnalyticsEventNoLimit('HIPAA Consented', { customerId, leadId });
      const hipaaConsentAttestValue = (value as string[]).includes('HIPAA_CONSENT_ATTEST');

      onConfirmConsentAttest(hipaaConsentAttestValue);
    },
    [onConfirmConsentAttest, customerId, leadId],
  );

  return (
    <HipaaConsentAttestContainer>
      <QuestionView
        id={questionId}
        questionText=""
        label="Select Yes or No"
        type={QuestionType.Checkbox}
        options={hipaaConsentAttestOptions}
        leadId={leadId}
        value={attested}
        onChange={onHipaaConsentAttestChange}
      />
    </HipaaConsentAttestContainer>
  );
};
