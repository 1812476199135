import React from 'react';
import { Button, Dialog, DialogActions } from '@material-ui/core';
import { PrecisionScoreDetailsInterface } from './PrecisionScoreDetails.interfaces';
import {
  PrecisionScoreDetailsTextBold,
  PrecisionScoreDetailsText,
  Column,
  Row,
  PrecisionScoreDetailsPoints, CheckIcon,
} from './PrecisionScoreDetails.elements';

export const PrecisionScoreDetails = ({ open, onDialogClose, pointsStructure }: PrecisionScoreDetailsInterface) => (
  <Dialog open={open} onClose={onDialogClose} aria-labelledby="dialog-title">
    <Column>
      <PrecisionScoreDetailsTextBold>
        Precision Score
      </PrecisionScoreDetailsTextBold>
      <PrecisionScoreDetailsText>
        Your Precision Score measures your usage of Precision Medicare in each call
      </PrecisionScoreDetailsText>
      <PrecisionScoreDetailsText>
        There will be no bonus awarded for sales that circumvent the TRM process.
      </PrecisionScoreDetailsText>
      <PrecisionScoreDetailsText>
        You are awarded points according to the following:
      </PrecisionScoreDetailsText>
      {pointsStructure.map((point) => (
        <Row width="100%" key={point.label}>
          <Row>
            {point.done && <CheckIcon />}
            <PrecisionScoreDetailsText done={point.done}>
              {point.label}
            </PrecisionScoreDetailsText>
          </Row>
          <PrecisionScoreDetailsPoints>
            {point.points}
            {' '}
            {point.points > 1 ? 'points' : 'point'}
          </PrecisionScoreDetailsPoints>
        </Row>

      ))}
    </Column>
    <DialogActions>
      <Button autoFocus onClick={onDialogClose}>
        Close
      </Button>
    </DialogActions>
  </Dialog>
);
