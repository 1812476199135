import * as React from 'react';
import { navigateToTrmSection } from '../../../util';
import { PrimaryButton } from '../inputs/PrimaryButton';

export const MedicareGoToAnalysisSectionButton: React.FC = () => {
  function handleButtonClick() {
    navigateToTrmSection('needs-analysis');
  }

  return (
    <>
      <PrimaryButton variant="outlined" onClick={handleButtonClick} fullWidth>
        GO TO ANALYSIS SECTION
      </PrimaryButton>
    </>

  );
};
