import { gql } from 'apollo-boost';

export const PRODUCT_FRAGMENT = gql`
  fragment ProductFragment on Product {
    id
    type
    name
    isSpecialRate
    carrier {
      id
      hiqId
      name
    }
    industry {
      type
    }
    tweakType {
      type
    }
    carrierSubsidiary {
      name
      details {
        amBestRating
        spRating
        yearsInMarket
      }
    }
  }
`;
