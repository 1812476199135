import { useLocation } from '@reach/router';
import React, { useState } from 'react';
import { useCurrentUserIsAgent, useFormAnswers } from '../../../../../hooks';

export const useCustomerDataFormState = () => {
  const [answers] = useFormAnswers();
  const isAgent = useCurrentUserIsAgent();
  const [includeFirstName, setIncludeFirstName] = useState(true);
  const [includeLastName, setIncludeLastName] = useState(true);
  const [includeGender, setIncludeGender] = useState(true);
  const [includeDOB, setIncludeDOB] = useState(true);
  const [includeZip, setIncludeZip] = useState(true);
  const [includeEmail, setIncludeEmail] = useState(true);
  const [includePhone, setIncludePhone] = useState(true);

  const location = useLocation();
  React.useEffect(() => {
    // Should match: /customer/9da55e57-e156-4b51-ae55-92266a699a13/lead/d14ac0ad-ed20-4583-907b-dff337cc1bc9/interview#needs-analysis
    const pathRegex = /^\/customer\/[\w\d-]+\/lead\/[\w\d-]+/;
    if (isAgent && location && pathRegex.test(location.pathname)
      && (location.hash === '#needs-analysis' || location.hash === '#profile-verification')) {
      setIncludeFirstName(!answers['customer.person.name.first']);
      setIncludeLastName(!answers['customer.person.name.last']);
      setIncludeGender(!answers['customer.person.gender']);
      setIncludeDOB(!answers['customer.person.dateOfBirth']);
      setIncludeZip(!answers['customer.person.homeAddress.zip']);
      setIncludeEmail(!answers['customer.person.email']);
      setIncludePhone(!answers['customer.person.phone.number']);
    }
  }, [location]);

  return {
    includeFirstName,
    includeLastName,
    includeGender,
    includeDOB,
    includeZip,
    includeEmail,
    includePhone,
  };
};
