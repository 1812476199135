import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../../themes';

export const AddressLookupInputOptionElem = styled('span')<
ComponentWithThemeProps & {
  isHighligthed: boolean;
}
>(({ theme, isHighligthed }) => css`
  color: ${isHighligthed ? theme.color.primary : theme.color.black};
  font-weight: ${isHighligthed ? 700 : 400};
`);
