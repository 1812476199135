import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { LicensingQueryData, LicensingQueryVariables } from './useLicensingQuery.interfaces';
import { useEventListener } from '../../../events/useEventListener';
import { ZipOrStateChangeEvent } from '../../../events';

const LICENSING_QUERY = gql`
  query getLicensing($leadId: ID!) {
    licensing(leadId: $leadId) {
      isLicensed
      message
    }
  }
`;

export function useLicensingQuery(leadId: string) {
  const result = useQuery<LicensingQueryData, LicensingQueryVariables>(LICENSING_QUERY, {
    variables: {
      leadId,
    },
  });
  useEventListener(ZipOrStateChangeEvent, () => {
    result.refetch();
  });
  return result;
}
