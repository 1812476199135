import * as React from 'react';
import { useInternalAuth } from '@healthiqeng/core.hooks.use-internal-auth';
import { AuthenticatedPageProps } from './AuthenticatedPage.interfaces';
import { Page } from '../Page';
import { logger } from '../../../../services';
import { config } from '../../../../config';
import { Loader } from '../../Loader';
import { AnalyticsEvent } from '@healthiqeng/core.services.fe-analytics';
import { getEventBus } from '../../../../events';
import { useCurrentUserQuery } from '../../../../graphql';
import { useEffectOnce } from '../../../../hooks';
import { setUser } from '@sentry/gatsby';

export const AuthenticatedPage: React.FunctionComponent<AuthenticatedPageProps> = ({
  title,
  theme,
  muiTheme,
  graphqlClient,
  internalAuthService,
  children,
}) => {
  const loginUrl = `https://${config.AWS.COGNITO.DOMAIN}.auth.${config.AWS.COGNITO.REGION}.amazoncognito.com/login`;
  const { loading, error } = useInternalAuth({
    authService: internalAuthService,
    logger,
    loginUrl,
    redirectUrl: config.AWS.COGNITO.REDIRECT_URI,
    scopes: ['email', 'openid'],
    clientId: config.AWS.COGNITO.CLIENT_ID,
  });
  const eventBus = getEventBus();

  if (loading) {
    return <Loader />;
  }

  if (error) {
    eventBus.emit(new AnalyticsEvent(`Auth Error on page ${title}`, { error: error.message }));

    return (
      <>
        <div>
          Error Logging In:
          {' '}
          {error.message}
        </div>
      </>
    );
  }

  return (
    <Page
      title={title}
      theme={theme}
      muiTheme={muiTheme}
      graphqlClient={graphqlClient}
    >
      <SetupUserSentry>
        { children }
      </SetupUserSentry>
    </Page>
  );
};

const SetupUserSentry: React.FC = ({ children }) => {
  const { data: userData } = useCurrentUserQuery();

  useEffectOnce(() => {
    const { email, id } = userData?.currentUser;
    if (email && id) {
      setUser({ email, id });
    }
  }, [userData?.currentUser]);

  return (<>{children}</>);
};
