import styled from '@emotion/styled';
import StarIcon from '@material-ui/icons/Star';

export const PlanStarIcon = styled(StarIcon)`
  color: #7F8385 !important;
`;

export const Text = styled.span`
  color: #7F8385;
  font-size: 16px
  padding-top: 5px
`;

export const EmptyRating = styled.span`
  padding-top: 5px;
  color: #7F8385;
`;
