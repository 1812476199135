import * as React from 'react';
import { DirectionalCopyProps } from './DirectionalCopy.interfaces';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { DirectionalCopyContainer } from './DirectionalCopy.elements';

export const DirectionalCopy: React.FunctionComponent<DirectionalCopyProps> = ({ children }) => (
  <DirectionalCopyContainer>
    <ArrowForward color="primary" />
    <span style={{ fontStyle: 'italic', marginLeft: 5 }}>{children}</span>
  </DirectionalCopyContainer>
);
