import * as React from 'react';
import { UserActivityViewProps } from './UserActivityView.interfaces';
import {
  UserActivityViewContainer,
  UserActivityViewHeader,
  Anchor,
} from './UserActivityView.elements';
import moment from 'moment';
import { CardListItem } from '../../../common/CardListItem';

export const UserActivityView: React.FunctionComponent<UserActivityViewProps> = ({
  tasks,
}) => (
  <UserActivityViewContainer data-qa="home-tasks-panel">
    <UserActivityViewHeader>
      Tasks
    </UserActivityViewHeader>
    {tasks?.map((task) => (
      <Anchor href={task.url} key={task.id} target="_blank">
        <CardListItem
          icon="assignment"
          subject={task.subject}
          body={(
            <>
              { `Created By: ${task.createdBy}` }
              <br />
              { moment(task.timestamp).format('dddd, MMMM Do YYYY, h:mm a') }
              <br />
              {task.body}
            </>
          )}
        />
      </Anchor>
    ))}
  </UserActivityViewContainer>
);
