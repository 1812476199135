import { gql } from 'apollo-boost';

export const PERSON_FRAGMENT = gql`
  fragment PersonFragment on Person {
    id
    gender
    dateOfBirth
    age
    name {
      salutation
      first
      middle
      last
      suffix
      preferredFirstName
      fullFriendlyName
      fullLegalName
    }
    email
    maritalStatus
    phone {
      number
      formatted
    }
    phoneSecondary {
      number
    }
    medicareIdNumber
    questionFlowAnswers
    homeAddress {
      street
      apt
      city
      county
      state {
        code
        name
      }
      zip
      type
    }
    previousAddress {
      street
      apt
      city
      county
      state {
        code
        name
      }
      zip
      type
    }
    __typename
  }
`;
