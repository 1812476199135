import React from 'react';
import { formatCurrencyTruncated } from '@healthiqeng/core.ui';
import { NoPlanPlaceHolder } from '../NoPlanPlaceHolder';
import { CompareData, CompareDataItem, ViewLinkButton } from '../TRMQuote.elements';
import { CompareRankProps } from '../TRMQuote.interfaces';

export const CompareRank = ({
  rank, totalPlans, highlight, link, totalCost, noRank,
}: CompareRankProps) => {
  if (!rank) {
    return (
      <CompareData empty={!noRank}>
        <NoPlanPlaceHolder />
      </CompareData>
    );
  }
  return (
    <CompareData highlight={highlight}>
      <CompareDataItem>
        <b>{`Ranked #${rank} / ${totalPlans}`}</b>
        {link ? <ViewLinkButton paddingLeft="10px" onClick={() => window.open(link, '_blank')}>Provider</ViewLinkButton> : undefined}
        {totalCost ? <span>{formatCurrency(totalCost)}</span> : null}
      </CompareDataItem>
    </CompareData>
  );
};

function formatCurrency(currency: number) {
  const formatted = formatCurrencyTruncated(currency, false, false);
  if (!formatted) return '-';
  return formatted;
}
