import { FunctionComponent, useEffect } from 'react';
import { useQuestionFlowQuery } from '../../../../../graphql';
import { QuestionFlowPartialProps, QuestionFlowPartialFactoryProps } from './QuestionFlowPartial.interfaces';

export const createQuestionFlowPartial = ({ onMarkdownChange }: QuestionFlowPartialFactoryProps) => {
  const QuestionFlowPartial: FunctionComponent<QuestionFlowPartialProps> = ({
    leadId,
    referenceId,
  }) => {
    const { data } = useQuestionFlowQuery(leadId, referenceId);

    const markdown = data?.questionFlows?.[referenceId]?.body ?? '';
    useEffect(() => {
      onMarkdownChange(referenceId, markdown);
    }, [markdown, referenceId]);

    return null;
  };

  return QuestionFlowPartial;
};
