import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { DialogContent } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

export const PrecisionScoreDetailsTextBold = styled('span')(css`
    font-weight: 700;
    font-size: 22px;
    line-height: 24px;
`);

interface SpanProps {
  done?: boolean;
}

export const PrecisionScoreDetailsText = styled.span<SpanProps>(({ done }) => css`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${done ? '#1A1C1F' : '#7F8385'};s
`);

export const PrecisionScoreDetailsPoints = styled('span')(css`
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
`);

export const CheckIcon = styled(CheckCircleOutlineIcon)(css`
    color: #037849;
    margin-right: 8px
`);
export const Column = styled(DialogContent)(css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 400px;
    padding: 0px 24px 24px 24px;
    grid-gap: 10px;
`);

interface RowProps {
  width?: string;
}

export const Row = styled.div<RowProps>(({ width = 'auto' }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: ${width};
`);
