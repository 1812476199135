import { HealthRecordDoctor } from '@hiq/crm.types';
import { noop } from '../../../../../util';
import React, { ChangeEvent } from 'react';
import { Row, UseRowSelectRowProps } from 'react-table';
import { SelectionLabel, SwitchContainer } from '../../../Datagrid/Datagrid.elements';
import { RowSelectionCheckbox } from '../../../Datagrid/inputs';
import { SelectColumnContainer } from '../CheckMyHealthRecordDataGrid.elements';
import { DoctorSelectionColumnProps } from './useDoctorColumnConfig.interfaces';

export const DoctorSelectionColumn = ({
  row, onSelect, onCriticalSelect, disabled,
}: DoctorSelectionColumnProps) => {
  const toggleRowSelectedProps = (row as Row<HealthRecordDoctor> & UseRowSelectRowProps<HealthRecordDoctor>).getToggleRowSelectedProps();
  const onContainerClick = () => {
    toggleRowSelectedProps.onChange({ target: { value: toggleRowSelectedProps.checked } } as any as ChangeEvent);
    if ((row.original as any).selected && (row.original as any).criticalSelected) {
      onCriticalSelect({ ...row.original, ...row.values });
    }
    onSelect({ ...row.original, ...row.values });
  };
  return (
    <SelectColumnContainer>
      <SwitchContainer onClick={onContainerClick} style={{ opacity: disabled ? 0.2 : 1 }}>
        <SelectionLabel>Include</SelectionLabel>
        <RowSelectionCheckbox {...toggleRowSelectedProps} onChange={noop} color="#05AB5B" disabled={disabled} />
      </SwitchContainer>
    </SelectColumnContainer>
  );
};
