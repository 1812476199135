import * as React from 'react';
import { SubmitAppProps } from './SubmitApp.interfaces';
import { ValidationError } from './SubmitApp.elements';
import { PrimaryButton } from '../../PrimaryButton';

export const SubmitApp: React.FunctionComponent<SubmitAppProps> = ({
  onSubmitApp,
  loading,
  validationSuccess,
  validationErrors,
}) => (
  <>
    <PrimaryButton
      onClick={() => onSubmitApp()}
      variant="contained"
      fullWidth
      disabled={loading || !validationSuccess}
    >
      Submit Application
    </PrimaryButton>
    { !!validationErrors?.length && (
      <ul>
        { validationErrors.map(({ message }) => (
          <ValidationError key={message}>
            { message }
          </ValidationError>
        )) }
      </ul>
    ) }
  </>
);
