import styled from '@emotion/styled';
import { css } from '@emotion/core';

export const PrecisionScoreMedal = styled('div')(css`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 31px;
    height: 28px;
    border-radius: 16px;
`);

export const PrecisionScoreBronzeMedal = styled(PrecisionScoreMedal)(css`
    background: linear-gradient(119.31deg, #644414 8.13%, #C8660C 54.43%, #703C0D 96.15%);
    border: 1px solid #AE6B2D;
`);

export const PrecisionScoreSilverMedal = styled(PrecisionScoreMedal)(css`
    background: linear-gradient(119.31deg, #525661 8.13%, #949FA3 54.43%, #3F444E 96.15%);
    border: 1px solid #B4B4B4;
`);

export const PrecisionScoreGoldMedal = styled(PrecisionScoreMedal)(css`
    background: linear-gradient(119.31deg, #835600 8.13%, #CDA641 52.14%, #614600 96.15%);
    border: 1px solid #CC9B3B;
`);

export const PrecisionScoreContainer = styled('div')(css`
  color: black;
  display: flex;
  margin: 10px 0px;
  margin-left: 10px;
  border-radius: 25px;
  min-width: 100px;
  background-color: #4A4A4A;
  text-align: center;
  display: flex;
  font-size: 14px !important;
  align-items: center;
  cursor: pointer;
  padding-right: 15px;
`);

export const PrecisionScoreText = styled('span')(css`
  margin-left: 3px;
  margin-right: 3px;
  color: white;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
`);
