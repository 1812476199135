import * as React from 'react';
import { LoaderProps } from './Loader.interfaces';
import {
  LoaderContainer,
  LoaderContent,
} from './Loader.elements';

export const Loader: React.FunctionComponent<LoaderProps> = () => (
  <LoaderContainer>
    <LoaderContent />
  </LoaderContainer>
);
