import styled from '@emotion/styled';
import * as React from 'react';
import { IResponsiveHorizontalListConfig, IResponsiveHorizontalListProps } from './ResponsiveHorizontalList.interfaces';

export function ResponsiveHorizontalList(baseComponent: any) {
  return <T>(configFactory: (props: T) => IResponsiveHorizontalListConfig) => {
    const Container = styled(baseComponent)((props) => {
      const config = configFactory(props);
      let styles = `
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        height: ${config.height};
      `;
      if (config.hidingDirection === 'end') {
        styles += `
          flex-direction: row;
        `;
      }
      if (config.hidingDirection === 'start') {
        styles += `
          flex-direction: row-reverse;
        `;
        for (let i = 0, j = props.children.length; i < props.children.length; i++, j--) {
          styles += `
            & > *:nth-of-type(${i}) {
              order: ${j};
            }
          `;
        }
      }
      if (config.align === 'start') {
        if (config.hidingDirection === 'start') {
          styles += `
            justify-content: flex-end;
          `;
        }
        if (config.hidingDirection === 'end') {
          styles += `
            justify-content: flex-start;
          `;
        }
      }
      if (config.align === 'end') {
        if (config.hidingDirection === 'start') {
          styles += `
            justify-content: flex-start;
          `;
        }
        if (config.hidingDirection === 'end') {
          styles += `
            justify-content: flex-end;
          `;
        }
      }
      return styles;
    });

    return (props: IResponsiveHorizontalListProps) => (
      React.createElement(Container, props, props.children.map((child, i) => (
        React.createElement('div', { key: i }, child)
      )))
    );
  };
}
