import * as React from 'react';
import { LeadPanelCard } from '../../../elements';
import { SunfireInventory } from '../../../../../common/SunfireInventory';
import { CopyText } from '../../../../../common/CopyText';
import { InventoryHeader, InventoryHelpText, CredsContainer } from './SunfireInventoryPanel.elements';

export const SunfireInventoryPanel: React.FunctionComponent = () => (
  <LeadPanelCard>
    <InventoryHeader>
      Sunfire MA and PDP Plan Inventory
    </InventoryHeader>
    <InventoryHelpText>
      Click the button below to view plan inventory in the customer&apos;s geography.
      <br />
      <br />
      Please note, you will not be able to enroll a customer through this training/inventory environment.
    </InventoryHelpText>
    <SunfireInventory />
    <InventoryHelpText>
      The common credentials for this site are
    </InventoryHelpText>
    <CredsContainer>
      <CopyText text="footprint@hiq.com" label="Username" />
    </CredsContainer>
    <CredsContainer>
      <CopyText text="Welcome1#" label="Password" />
    </CredsContainer>
  </LeadPanelCard>
);
