import * as React from 'react';
import {
  QuotePresentationType, TermLength, TermLengthLabelMap, UnderwritingType, UnderwritingTypeLabelMap,
} from '@hiq/crm.types';
import { formatCurrencyTruncated } from '@healthiqeng/core.util.format-currency-truncated';
import { LifeQuoteCellProps } from './LifeQuoteCell.interfaces';
import { QuoteCellLayout } from '../QuoteCellLayout';
import { QuoteCarrierInfo } from '../QuoteCarrierInfo';
import { QuoteDetails } from '../QuoteDetails';
import { QuoteRates } from '../QuoteRates';
import { QuoteSelectButton } from '../QuoteSelectButton';
import { QueryLoader } from '../../../QueryLoader';
import { useQuoteCell } from '../useQuoteCell';
import { QuotePin } from '../QuotePin';
import { PrimaryButton } from '../../../inputs/PrimaryButton';

export const LifeQuoteCell: React.FunctionComponent<LifeQuoteCellProps> = ({
  hash,
  selectable = true,
  onPin,
  isPinned,
}) => {
  const {
    quote,
    handleSelectClick,
    isSelected,
    loading,
    error,
  } = useQuoteCell(hash);
  return (
    <QueryLoader loading={loading} error={error} componentName="LifeQuoteCell">
      <QuoteCellLayout
        pinColumn={onPin && <QuotePin onPin={onPin} isPinned={isPinned} />}
        carrierColumn={(
          <QuoteCarrierInfo
            parentCompanyName={quote?.product?.carrier?.name}
            companyName={quote?.product?.carrierSubsidiary?.name}
            amBestRating={quote?.product?.carrierSubsidiary?.details?.amBestRating}
            spRating={quote?.product?.carrierSubsidiary?.details?.spRating}
            yearsInMarket={quote?.product?.carrierSubsidiary?.details?.yearsInMarket}
          />
        )}
        detailColumn={(
          <QuoteDetails
            items={[
              { label: 'Product', value: quote?.product?.name },
              { label: 'Face Amount', value: formatCurrencyTruncated(quote?.faceAmount?.value, false, false) },
              { label: 'Policy Term Length', value: formatPolicyDuration(quote?.policyTermLength as TermLength) },
              { label: 'Underwriting Type', value: formatUnderwritingType(quote?.underwritingType) },
              ...(!quote?.underwritingClass ? [] : [{ label: 'Underwriting Class', value: quote?.underwritingClass }]),
            ]}
          />
        )}
        premiumColumn={(
          <QuoteRates
            rateMonthly={quote?.premium?.monthly?.value}
            rateAnnually={quote?.premium?.annually?.value}
            rateSemiAnnually={quote?.premium?.semiAnnually?.value}
            rateQuarterly={quote?.premium?.quarterly?.value}
          />
        )}
        selectColumn={(
          <>
            <QuoteSelectButton
              allowSelect={selectable && quote?.presentationType !== QuotePresentationType.Competition}
              isSelected={isSelected}
              onClick={handleSelectClick}
            />
            { quote?.eAppLink && (
              <div style={{ marginTop: '10px' }}>
                <PrimaryButton
                  onClick={() => window.open(quote?.eAppLink, '_blank')}
                  variant="outlined"
                  fullWidth
                >
                  E-App
                </PrimaryButton>
              </div>
            ) }
          </>
        )}
      />
    </QueryLoader>
  );
};

function formatPolicyDuration(policyTermLength: TermLength): string {
  return TermLengthLabelMap[policyTermLength];
}

function formatUnderwritingType(underwritingType: UnderwritingType): string {
  return UnderwritingTypeLabelMap[underwritingType];
}
