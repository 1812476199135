import * as React from 'react';
import { ConversionMessageProps } from './ConversionMessage.interfaces';
import {
  ConversionMessageContainer,
  ConversionMessageIconContainer,
  ConversionMessageText,
  ConversionMessageHeaderContainer,
} from './ConversionMessage.elements';
import { Icon, Question } from '../../../../common';

export const ConversionMessage: React.FunctionComponent<ConversionMessageProps> = ({
  message,
  type,
  referenceId,
  questionId,
  answers,
  onChange,
  leadId,
}) => {
  const value = answers[questionId];
  return (
    <ConversionMessageContainer>
      <ConversionMessageHeaderContainer>
        <ConversionMessageIconContainer>
          { type === 'error'
            ? <Icon type="clear" color="error" />
            : <Icon type="clear" color="error" />}
        </ConversionMessageIconContainer>
        <ConversionMessageText>
          { message }
        </ConversionMessageText>
      </ConversionMessageHeaderContainer>
      { referenceId && (
        <Question
          leadId={leadId}
          referenceId={referenceId}
          onChange={onChange}
          value={value}
        />
      ) }
    </ConversionMessageContainer>
  );
};
