import React from 'react';
import { MedicareApplicationType } from '@hiq/crm.types';
import { useLeadQuery } from '../../../../../../../graphql';
import { useActiveLeadId } from '../../../../../../routers/LeadRouter/useActiveLeadId';
import { QuestionFlow } from '../../../../../../common/QuestionFlow';

export const AutomatedUnderwriting: React.FunctionComponent<any> = () => {
  const leadId = useActiveLeadId();
  const { data } = useLeadQuery();
  const isUnderwritten = (data as any).applicationType === MedicareApplicationType.Underwritten;
  return (
    <div>
      {isUnderwritten
        ? (
          <QuestionFlow leadId={leadId} referenceId="medicare-automated-underwriting" root />
        )
        : (
          <QuestionFlow leadId={leadId} referenceId="medicare-finalize-rate" root />
        )}
    </div>
  );
};
