import * as React from 'react';
import { CustomerContextContainerProps } from './CustomerContextContainer.interfaces';
import { CustomerIdContext } from '../../../contexts';
import { getEventBus } from '../../../../events';
import { SetPropsEvent } from '@healthiqeng/core.services.fe-analytics';

export const CustomerContextContainer: React.FunctionComponent<CustomerContextContainerProps> = ({
  customerId,
  content,
}) => {
  React.useEffect(() => {
    const eventBus = getEventBus();
    eventBus.emit(new SetPropsEvent({ customerId }));
  });
  if (customerId === 'sf') {
    return (
      <div>
        Page not found. You must include the salesforce Lead or Opp ID after customer/sf
      </div>
    );
  }

  return (
    <CustomerIdContext.Provider value={customerId}>
      { content }
    </CustomerIdContext.Provider>
  );
};
