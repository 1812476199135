import { gql } from 'apollo-boost';

export const FORM_ANSWERS_VALIDATION_ITEM_FRAGMENT = gql`
  fragment FormAnswersValidationItemFragment on FormAnswersValidationItem {
    message
    referenceId
    questionId
    label
    questionText
  }
`;
