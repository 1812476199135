import styled from '@emotion/styled';

export type ApptNotifModalContentProps = {
  onClose?: () => void;
  onClickSave: () => void;
  clientName: string;
  appointmentTime: string;
};

export const Paragraph = styled.p`
  margin-bottom: 10px;
  margin-top: 0;
`;
