import React from 'react';
import { TableToggleAllRowsSelectedProps } from 'react-table';
import { ColoredCheckbox, DisableCheckbox } from './RowSelectionCheckbox.elements';

export const RowSelectionCheckbox = React.forwardRef<HTMLInputElement, TableToggleAllRowsSelectedProps
& { color?: string, disabled?: boolean }>(
  ({
    indeterminate, color, disabled, ...rest
  }, ref) => {
    const defaultRef = React.useRef<HTMLInputElement>();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      (resolvedRef as React.MutableRefObject<HTMLInputElement>).current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    if (disabled) return <DisableCheckbox ref={resolvedRef} disabled={disabled} />;

    return (
      <ColoredCheckbox color="secondary" forcedColor={color} ref={resolvedRef} {...rest} />
    );
  },
);
