import * as React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import { FollowUpListProps } from './FollowUpList.interfaces';
import { FollowUpDescription } from '../../../common/followUp/FollowUpDescription';
import { Icon } from '../../../common/Icon';
import { FollowUpListHeader } from './FollowUpList.elements';
import { formatFollowUpDate } from '../../../../util/formatFollowUpDate';

export const FollowUpList: React.FunctionComponent<FollowUpListProps> = ({
  followUps,
  onDelete,
}) => (
  <List dense disablePadding>
    { !!followUps?.length && (
      <FollowUpListHeader>
        Existing Follow-ups
      </FollowUpListHeader>
    ) }
    { followUps.map((followUp) => (
      <ListItem key={followUp.id}>
        <ListItemText
          primary={formatFollowUpDate(followUp.start, followUp.end)}
          secondary={(
            <FollowUpDescription
              description={followUp.description}
            />
          )}
        />
        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="delete" onClick={() => onDelete(followUp.id)}>
            <Icon type="delete" />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    )) }
  </List>
);
