import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const SmsMessageInput = styled('textarea')(css`
  min-height: 100px;
  width: 100%;
  resize: none;
  outline: none;
  padding: 5px;
  font: inherit;
`);

export const FeedbackMessage = styled.div`
  font-weight: bold;
  text-align: center;
`;

export const AccordionTitle = styled('h4')(css``);
