import { VehicleAssignationData } from '../VehicleDriverAssociation.interfaces';

export function getVehiclesAssignationSum(associations: VehicleAssignationData, vehiclesAmount: number): number[] {
  const result: number[] = [];

  for (let index = 0; index < associations.length; index++) {
    const vehiclesAssignment = associations[index]?.vehicles || [];
    for (let vehicleIndex = 0; vehicleIndex < vehiclesAssignment.length; vehicleIndex++) {
      const vehicleAssignment = +(vehiclesAssignment[vehicleIndex] || 0);
      result[vehicleIndex] = (result[vehicleIndex] || 0) + vehicleAssignment;
    }
  }

  if (result.length < vehiclesAmount) {
    for (let index = result.length; index < vehiclesAmount; index++) {
      result[index] = 0;
    }
  }
  return result.splice(0, vehiclesAmount);
}
