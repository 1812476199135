import { useLazyQuery } from '@apollo/react-hooks';
import {
  QuestionFlowQueryData,
  QuestionFlowQueryVariables,
  UseQuestionFlowQueryOptions,
} from './useQuestionFlowQuery.interfaces';
import { QUESTION_FLOW_QUERY } from './useQuestionFlowQuery';

export function useLazyQuestionFlowQuery(leadId: string, referenceId: string, options: UseQuestionFlowQueryOptions = {}) {
  const result = useLazyQuery<QuestionFlowQueryData, QuestionFlowQueryVariables>(QUESTION_FLOW_QUERY, {
    variables: {
      leadId,
      referenceId,
      shallow: !!options?.shallow,
    },
  });
  return result;
}
