import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { LifeStatusPanelProps } from './LifeStatusPanel.interfaces';
import {
  LifeStatusPanelContainer,
} from './LifeStatusPanel.elements';
import { LeadPanelCard, LeadPanelStickyCard } from '../../../elements';
import {
  QuoteInfoCard,
  LifeLeadStages,
  FormGenerator,
  FormGeneratorStyle,
} from '../../../../../common';
import { useLeadQuery } from '../../../../../../graphql';

export const LifeStatusPanel: React.FunctionComponent<LifeStatusPanelProps> = ({
  leadId,
}) => {
  const { data, refetch } = useLeadQuery(leadId);
  React.useEffect(() => {
    const id = setInterval(() => {
      if (!data?.sfOpportunityId) {
        refetch();
      }
    }, 5000);
    return () => clearInterval(id);
  }, [data, refetch]);
  return (
    <LifeStatusPanelContainer>
      <LeadPanelStickyCard>
        <QuoteInfoCard leadId={leadId} />
      </LeadPanelStickyCard>
      <LeadPanelCard>
        <LifeLeadStages />
      </LeadPanelCard>
      <LeadPanelCard>
        <FormGenerator
          lockable
          style={FormGeneratorStyle.Compact}
          leadId={leadId}
          referenceId="life-status"
        />
      </LeadPanelCard>
      <LeadPanelCard>
        <FormGenerator
          lockable
          leadId={leadId}
          referenceId="status"
        />
      </LeadPanelCard>
      <LeadPanelCard>
        { data?.sfOpportunityId ? (
          <a href={`https://hiq.my.salesforce.com/${data?.sfOpportunityId}`}>
            Salesforce Opportunity
          </a>
        ) : (
          <div>
            <CircularProgress />
            Salesforce Sync in Progress. This can take up to 2 minutes.
          </div>
        ) }
      </LeadPanelCard>
    </LifeStatusPanelContainer>
  );
};
