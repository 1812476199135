import * as React from 'react';
import { JsonCompatible } from '@healthiqeng/core.types';
import {
  CrossSaleCustomer, FormItemTag, IndustryType, ProductTypeMedicare, QuestionType,
} from '@hiq/crm.types';
import { useFormAnswers } from '../../../hooks';
import { QuestionView } from '../FormGenerator/FormGeneratorQuestionFlow/Question/QuestionView';
import { useActiveLeadId, useCustomerId, useLeadIdRoute } from '../../routers';
import { useEventListener } from '../../../events';
import { CrossSaleCreateSuccessEvent, useCrossSaleMutation, useLeadQuery } from '../../../graphql';
import { onCrossSaleSuccess } from '../../../listeners';
import { QueryLoader } from '../QueryLoader';

export const MedicarePlanTransition: React.FunctionComponent = () => {
  const customerId = useCustomerId();
  const leadId = useActiveLeadId();
  const { data: lead } = useLeadQuery(leadId);
  const [answers, onChange] = useFormAnswers();
  const interestedPlanQuestionId = 'lead.medicarePlanTransition';
  const interestedPlanProductType = answers[interestedPlanQuestionId];
  const permissionToDiscussMaOrPdpQuestionId = 'lead.permissionToDiscussMaOrPdp';
  const permissionToDiscuss = answers[permissionToDiscussMaOrPdpQuestionId];
  const [, routeToLead] = useLeadIdRoute(undefined, 'interview');
  const [submitCrossSale, { loading, error }] = useCrossSaleMutation();
  const [allowNewLead, setAllowNewLead] = React.useState(false);
  useEventListener(CrossSaleCreateSuccessEvent, (event: CrossSaleCreateSuccessEvent) => {
    onCrossSaleSuccess(routeToLead)(event);
    setTimeout(() => {
      window.location.hash = '#poa-verify';
    }, 1500);
  });
  const handleChange = React.useCallback((key: string, value: JsonCompatible) => {
    onChange(key, value);

    if (key === permissionToDiscussMaOrPdpQuestionId && value) {
      setAllowNewLead(true);
    }
  }, [onChange, setAllowNewLead]);

  const onInterestedPlanChange = React.useCallback((value) => {
    handleChange(interestedPlanQuestionId, value);
  }, [handleChange]);
  const onPermissionChange = React.useCallback((value) => {
    handleChange(permissionToDiscussMaOrPdpQuestionId, value);
  }, [handleChange]);
  const requiredTags = React.useMemo(() => [FormItemTag.RequiredToConvert], []);

  const createNewLead = (interestedPlanProductType: ProductTypeMedicare) => {
    submitCrossSale({
      customerId,
      options: {
        industry: IndustryType.Medicare,
        product: interestedPlanProductType,
        customer: CrossSaleCustomer.Current,
      },
    });
  };

  React.useEffect(() => {
    if (
      allowNewLead
      && isMaOrPdpSelected(interestedPlanProductType)
      && permissionToDiscuss
      && lead?.productType !== interestedPlanProductType
    ) {
      createNewLead(interestedPlanProductType);
    }
  }, [interestedPlanProductType, permissionToDiscuss]);

  return (
    <QueryLoader loading={loading} error={error} componentName="MedicarePlanTransition">
      <QuestionView
        id={interestedPlanQuestionId}
        leadId={leadId}
        value={interestedPlanProductType as string}
        questionText="What plan would you like to explore?"
        label="Interested Plan Type"
        type={QuestionType.Select}
        options={productTypeOptions}
        onChange={onInterestedPlanChange}
      />
      { isMaOrPdpSelected(interestedPlanProductType) && (
      <>
        <br />
        <QuestionView
          id={permissionToDiscussMaOrPdpQuestionId}
          leadId={leadId}
          value={permissionToDiscuss}
          questionText={'```text\n'
          + 'Now we are going to look at {Medicare Advantage or PDP} plans in your area. '
          + 'Please note this does not affect your current or future Medicare enrollment status. '
          + 'Getting information will not change status but ENROLLMENT in a plan will change your current coverage.\n\n'
          + 'Do I have your permission to discuss these plans?```'}
          label="Permission To Discuss MA/PDP"
          type={QuestionType.RadioYesNo}
          onChange={onPermissionChange}
          tags={requiredTags}
        />
      </>
      )}
    </QueryLoader>
  );
};

function isMaOrPdpSelected(productType: ProductTypeMedicare): boolean {
  return [ProductTypeMedicare.MedicareAdvantage,
    ProductTypeMedicare.PrescriptionDrug,
  ].includes(productType);
}

const productTypeOptions = [
  {
    label: 'Medicare Advantage',
    value: ProductTypeMedicare.MedicareAdvantage,
  },
  {
    label: 'Vision',
    value: ProductTypeMedicare.Vision,
  },
  {
    label: 'Dental',
    value: ProductTypeMedicare.Dental,
  }, {
    label: 'Medicare Supplement',
    value: ProductTypeMedicare.MedicareSupplement,
  },
];
