import * as React from 'react';
import { Gender, QuestionOption, QuestionType } from '@hiq/crm.types';
import { formatDate } from '@healthiqeng/core.util.format-date';
import { HiqMedsupQuoterRider, hiqMedsupQuoterRiderLabelMap } from '@healthiqeng/core.clients.hiq-medsup-quoter';
import { isPresent } from '@healthiqeng/core.util.is-present';
import { calculateAge } from '@healthiqeng/core.util.calculate-age';
import { MedicareSupplementQuoteOptionsProps } from './MedicareSupplementQuoteOptions.interfaces';
import { QuoteOptionsLayout } from '../../QuoteOptionsLayout';
import { QuoteOption } from '../QuoteOption';
import {
  HouseholdDiscountOptions,
  medicareAgeOptions,
  TobaccoOptions,
} from '../../../../../constants';
import { PrimaryButton } from '../../../inputs/PrimaryButton';
import { CustomerQueryData, useCustomerQuery, useMedicareSupplementQuotePlans } from '../../../../../graphql';
import { getEnumOptions } from '../../../../../util';
import { useFormAnswers } from '../../../../../hooks';
import { useActiveLeadId } from '../../../../routers';
import { useEffect } from 'react';
import { useQuoteEffectiveDateOptions } from '../../useQuoteEffectiveDateOptions';
import { isEmpty } from '@healthiqeng/core.util.is-empty';
import { HelpfulLink, HelpfulLinksText, HelpfulLinksWrapper } from './MedicareSupplementQuoteOptions.elements';
import { WarningBlockView } from '../../../ScriptBlock/WarningBlock/WarningBlockView';

export const MedicareSupplementQuoteOptions: React.FunctionComponent<MedicareSupplementQuoteOptionsProps> = ({
  onQuote,
  availableRiders,
  isFetching,
}) => {
  const { data } = useCustomerQuery();
  const leadId = useActiveLeadId();
  const [answers, onChange] = useFormAnswers(leadId);
  const { plans, plansLoading } = useMedicareSupplementQuotePlans();
  const [nextMonth, effectiveDateOptions] = useQuoteEffectiveDateOptions();
  const customerOptions = {
    zipCode: data?.customer?.person?.homeAddress?.zip,
    gender: (data?.customer?.person?.gender === Gender.Female ? 'f' : 'm') as 'm' | 'f',
    birthday: formatDate(data?.customer?.person?.dateOfBirth, 'YYYY-MM-DD'),
  };
  const agentReviewedCoverageDetails = answers['lead.quoteOptions.agentReviewedCoverageDetails'];
  const disabled = isFetching
    || !isPresent(answers['lead.quoteOptions.effectiveDate'])
    || !isPresent(customerOptions?.zipCode)
    || !agentReviewedCoverageDetails;
  const runQuote = () => onQuote({
    age: (answers['lead.quoteOptions.age'] ?? getAge(data)) as number,
    tobacco: (answers['lead.quoteOptions.tobacco'] ?? false) as boolean,
    plan: (answers['lead.quoteOptions.plan'] ?? 'F') as string,
    effectiveDate: (answers['lead.quoteOptions.effectiveDate'] ?? null) as string,
    applyDiscounts: (answers['lead.quoteOptions.applyDiscounts'] ?? false) as boolean,
    riders: (answers['lead.quoteOptions.riders'] ?? []) as HiqMedsupQuoterRider[],
    ...customerOptions,
  });

  useEffect(() => {
    if (isEmpty(answers['lead.quoteOptions.effectiveDate'])) onChange('lead.quoteOptions.effectiveDate', nextMonth as string);
    if (!disabled) runQuote();
  }, []);

  return (
    <>
      <WarningBlockView
        value={agentReviewedCoverageDetails}
        confirmationRequired
        confirmText="I acknowledge that I've reviewed this"
        onChange={(value) => onChange('lead.quoteOptions.agentReviewedCoverageDetails', value)}
      >
        You must review the plan coverage details with the customer such as how the plan covers deductibles, copays, and coinsurance.
      </WarningBlockView>
      <QuoteOptionsLayout
        leftContent={(
          <>
            <QuoteOption
              id="lead.quoteOptions.zipCode"
              label="Zip Code"
              value={data?.customer?.person?.homeAddress?.zip}
              type={QuestionType.ZipCode}
              handleChange={onChange}
              disabled
            />
            <QuoteOption
              id="lead.quoteOptions.age"
              label="Age"
              value={answers['lead.quoteOptions.age'] ?? getAge(data)}
              type={QuestionType.Select}
              options={medicareAgeOptions}
              handleChange={onChange}
            />
            <QuoteOption
              id="lead.quoteOptions.tobacco"
              label="Tobacco"
              value={answers['lead.quoteOptions.tobacco'] ?? false}
              type={QuestionType.Radio}
              options={TobaccoOptions}
              handleChange={onChange}
            />
            <HelpfulLinksWrapper>
              <HelpfulLinksText>Helpful Links:</HelpfulLinksText>
              <ul>
                <li>
                  <HelpfulLink
                    target="_blank"
                    href="https://hiq-uw.atlassian.net/wiki/spaces/Medicare/pages/1105362963/Medicare+Supplement+Plans+standard+MA+MN+WI"
                  >
                    Medicare Supplement Plans (standard, MA, MN, WI)
                  </HelpfulLink>
                </li>
              </ul>
            </HelpfulLinksWrapper>
          </>
      )}
        rightContent={(
          <>
            <QuoteOption
              id="lead.quoteOptions.effectiveDate"
              label="Desired Effective Date"
              value={answers['lead.quoteOptions.effectiveDate'] ?? null}
              type={QuestionType.Select}
              handleChange={onChange}
              options={effectiveDateOptions as QuestionOption[] ?? []}
            />
            <QuoteOption
              id="lead.quoteOptions.plan"
              label="Plan"
              value={answers['lead.quoteOptions.plan'] ?? getDefaultPlan(plans)}
              type={QuestionType.Select}
              options={plans ?? []}
              handleChange={onChange}
              loading={plansLoading}
            />
            <QuoteOption
              id="lead.quoteOptions.applyDiscounts"
              label="Household Discount"
              value={answers['lead.quoteOptions.applyDiscounts'] ?? false}
              type={QuestionType.Radio}
              options={HouseholdDiscountOptions}
              handleChange={onChange}
            />
            { !!availableRiders?.length && (
            <QuoteOption
              id="lead.quoteOptions.riders"
              label="Riders"
              value={answers['lead.quoteOptions.riders'] ?? [] as HiqMedsupQuoterRider[]}
              type={QuestionType.MultiSelect}
              options={getEnumOptions(availableRiders, hiqMedsupQuoterRiderLabelMap)}
              handleChange={onChange}
            />
            ) }
            <PrimaryButton
              onClick={runQuote}
              disabled={disabled}
              variant="contained"
            >
              { isFetching ? 'Fetching...' : 'Get Quotes' }
            </PrimaryButton>
          </>
      )}
        minWidth={200}
        gutter={20}
      />
    </>
  );
};

function getAge(data: CustomerQueryData): number {
  return data?.customer?.person?.dateOfBirth
    ? Math.max(calculateAge(new Date(data?.customer?.person?.dateOfBirth)), 64)
    : 65;
}

function getDefaultPlan(planOptions: QuestionOption[]) {
  const plans = planOptions?.map((plan) => plan?.value) ?? [];
  if (plans.includes('F')) {
    return 'F';
  }
  if (plans.includes('MN_BASIC')) {
    return 'MN_BASIC';
  }
  if (plans.includes('WI_BASE')) {
    return 'WI_BASE';
  }
  return plans[0];
}
