import { gql } from 'apollo-boost';

export const APPOINTMENT_FRAGMENT = gql`
  fragment AppointmentFragment on Appointment {
    type
    timestamp
    lead {
      id
      customer {
        id
        person {
          id
          name {
            first
            last
          }
          phone {
              number
          }
        }
      }
    }
    appointmentTime
    detail {
      callStatus
      callDisposition
      sequenceNum
      callAttemptNum
    }
  }
`;
