import React from 'react';
import { useFormAnswers } from '../../../../../../hooks';
import { JsonValue } from '@healthiqeng/core.types';
import { SelectInput } from '../../../../inputs';
import { FormControl } from '@material-ui/core';
import { useActiveLeadId } from '../../../../../routers';
import { useDirtySunfireData } from '../../../useDirtySunfireData';

export const EFFECTIVE_DATE_QUESTION_ID = 'lead.sunfire.v2.effectiveDate';

const YearOptions = [
  {
    label: '2022',
    value: 2022,
  },
  {
    label: '2023',
    value: 2023,
  },
];

const PLAN_SELECTION_YEAR = 2023;

export const YearFilter = () => {
  const [, setSunfireDataDirty] = useDirtySunfireData();
  const leadId = useActiveLeadId();
  const [answers, saveAnswer] = useFormAnswers(leadId, true);

  const saveEffectiveDate = React.useCallback((value: JsonValue) => {
    saveAnswer(EFFECTIVE_DATE_QUESTION_ID, value);
    setSunfireDataDirty(true);
  }, [saveAnswer, setSunfireDataDirty]);

  return (
    <FormControl>
      <span>Effective Date</span>
      <SelectInput
        fullWidth
        size="small"
        value={answers[EFFECTIVE_DATE_QUESTION_ID] ?? PLAN_SELECTION_YEAR}
        onChange={saveEffectiveDate}
        options={YearOptions}
        disableClearable
        showEmptyOption={false}
      />
    </FormControl>
  );
};
