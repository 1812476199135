import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { TextRenderer } from '../QuestionFlow/QuestionFlowRenderer/TextRenderer';

export const QuestionViewContainer = styled('div')(css`
  margin: -15px 0;
`);

export const QuestionTextContainer = styled('div')(css`
  display: flex;
`);

export const QuestionText = styled('div')(css`
  flex: 1;
  font-size: 16px;
`);

export const Question: React.FunctionComponent<any> = ({ questionText, children }) => (
  <QuestionViewContainer>
    <QuestionTextContainer>
      <QuestionText>
        <ReactMarkdown
          linkTarget="_blank"
          source={questionText}
          renderers={{
            text: TextRenderer,
          }}
        />
      </QuestionText>
    </QuestionTextContainer>
    { children }
  </QuestionViewContainer>
);
