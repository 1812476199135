import { gql } from 'apollo-boost';
import { MutationCustomReturn, useMutationCustom } from '../useMutationCustom';
import { TogglePrescriptionMutationResponse, TogglePrescriptionMutationVariables } from './useTogglePrescriptionMutation.interfaces';
import {
  SELECTED_PRESCRIPTIONS_QUERY,
  SelectedPrescriptionsQueryData,
  SelectedPrescriptionsQueryVariables,
} from '../../queries';
import { PRECISION_MEDICARE_PRESCRIPTION_FRAGMENT } from '../../fragments';

const TOGGLE_PRESCRIPTION_MUTATION = gql`
  mutation TogglePrescription($input: PrecisionMedicareTogglePrescriptionInput!) {
    togglePrescription(input: $input) {
      prescription {
        ...PrecisionMedicarePrescriptionFragment
      }
      removed
    }
  }
  ${PRECISION_MEDICARE_PRESCRIPTION_FRAGMENT}
`;

export function useTogglePrescriptionMutation(): MutationCustomReturn<
TogglePrescriptionMutationResponse, TogglePrescriptionMutationVariables> {
  return useMutationCustom<TogglePrescriptionMutationResponse, TogglePrescriptionMutationVariables>(TOGGLE_PRESCRIPTION_MUTATION, {
    update: (cache, mutationResult) => {
      const { prescription, removed } = mutationResult.data.togglePrescription;
      const cachedQuery: SelectedPrescriptionsQueryData = cache.readQuery({
        query: SELECTED_PRESCRIPTIONS_QUERY,
        variables: {
          leadId: prescription.leadId,
        },
      });
      const updatedData = removed
        ? cachedQuery.selectedPrescriptions.filter((pr) => pr.id !== prescription.id)
        : [...cachedQuery.selectedPrescriptions, prescription];
      cache.writeQuery<SelectedPrescriptionsQueryData, SelectedPrescriptionsQueryVariables>({
        query: SELECTED_PRESCRIPTIONS_QUERY,
        variables: {
          leadId: prescription.leadId,
        },
        data: {
          selectedPrescriptions: updatedData,
        },
      });
    },
  });
}
