import * as React from 'react';
import { CustomerSummaryHeaderProps } from './CustomerSummaryHeader.interfaces';
import {
  CustomerSummaryHeaderContainer,
} from './CustomerSummaryHeader.elements';
import { isPresent } from '@healthiqeng/core.util.is-present';

export const CustomerSummaryHeader: React.FunctionComponent<CustomerSummaryHeaderProps> = ({
  value,
}) => (
  <CustomerSummaryHeaderContainer>
    { isPresent(value) && value}
  </CustomerSummaryHeaderContainer>
);
