import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  SunfireV2PlansByTermQueryData, SunfireV2PlansByTermQueryVariables,
} from './useSunfireV2PlansByTermQuery.interfaces';

const SUNFIRE_PLANS_QUERY = gql`
  query FindSunfireV2PlansByTerm($term: String!, $leadId: LeadId!) {
    findSunfirePlansByTerm(term: $term, leadId: $leadId) {
      contractId
      name
      planId
      id
      segmentId
      id
      carrierName
    }
  }
`;

export function useSunfireV2PlansByTermQuery() {
  return useLazyQuery<SunfireV2PlansByTermQueryData, SunfireV2PlansByTermQueryVariables>(SUNFIRE_PLANS_QUERY);
}
