import * as React from 'react';
import { InitialContactLayoutProps } from './InitialContactLayout.interfaces';
import { ResizableColumns } from '../ResizableColumns';

export const InitialContactLayout: React.FunctionComponent<InitialContactLayoutProps> = ({
  mainContent,
  sideContent,
  toolPanelContent,
}) => {
  const columnContents = React.useMemo(
    () => [sideContent, mainContent, toolPanelContent].filter((component) => !!component),
    [sideContent, mainContent, toolPanelContent],
  );
  const initialColumnWidths = React.useMemo(() => {
    if (columnContents.length === 2) return ['380px', 'auto'];
    return ['380px', 'auto', '250px'];
  }, [columnContents.length]);
  return (
    <ResizableColumns
      columnContents={columnContents}
      initialColumnWidths={initialColumnWidths}
    />
  );
};
