import { SpeedDialName } from '@healthiqeng/core.services.dialer.base';
import { DefeaultSpeedDialMapping, SpeedDialMapping } from '@hiq/crm.types';
import { config } from '../../../../config';
import { logger } from '../../../../services';
import { captureEvent } from '@sentry/gatsby';

let speedDialMapping: SpeedDialMapping;

async function getSpeedDialMapping() : Promise<SpeedDialMapping> {
  if (speedDialMapping) return speedDialMapping;

  try {
    const resp = await fetch(`${config.HIQ_CRM.HOST}/${config.HIQ_CRM.SPEED_DIAL_MAPPING_ENDPOINT}`);
    if (resp.status !== 200) {
      throw new Error(`Request Failed with Status; ${resp.status}`);
    }
    const jsonResp = await resp.json();
    if (!jsonResp.success) {
      throw new Error(`Get SpeedDialMapping Error ${JSON.stringify(jsonResp)}`);
    }

    speedDialMapping = jsonResp.mapping;
  } catch (error) {
    captureEvent({
      level: 'error',
      message: `[getSpeedDialMapping] ${error.message}`,
      extra: {
        ...error,
      },
      tags: {
        type: 'react',
      },
    });
    logger.crit(error);
    speedDialMapping = DefeaultSpeedDialMapping;
  }

  return speedDialMapping;
}

export async function getSpeedDialFromCampaign(campaignName: string, callSkill?: string): Promise<SpeedDialName> {
  const speedDialMappings = await getSpeedDialMapping();
  const campaignNameRegexes = Object.keys(speedDialMappings);

  // Parses the first level of Regexes, if find some that match the campaign name, evaluate the rules.
  for (let i = 0; i < campaignNameRegexes.length; i++) {
    const campaignRegexStr = campaignNameRegexes[i];
    const campaignRegex = new RegExp(campaignRegexStr);
    // eslint-disable-next-line no-continue
    if (!campaignRegex.test(campaignName)) continue;

    // Evaluates all rules inside that given campaign set.
    const campaignRules = speedDialMappings[campaignRegexStr];
    const campaignRulesNames = Object.keys(campaignRules);
    for (let j = 0; j < campaignRulesNames.length; j++) {
      const rule = campaignRules[campaignRulesNames[j]];
      if (rule.callSkill && rule.callSkill === callSkill) return rule.speedDialName as SpeedDialName;
      if (rule.campaignName && rule.campaignName === campaignName) return rule.speedDialName as SpeedDialName;
      if (rule.callSkillRegex && (new RegExp(rule.callSkillRegex)).test(callSkill)) return rule.speedDialName as SpeedDialName;
      if (rule.campaignNameRegex && (new RegExp(rule.campaignNameRegex)).test(campaignName)) return rule.speedDialName as SpeedDialName;
      if (rule.callSkillRegexNotMatch && !(new RegExp(rule.callSkillRegexNotMatch)).test(callSkill)) {
        return rule.speedDialName as SpeedDialName;
      }
      if (rule.campaignNameRegexNotMatch && !(new RegExp(rule.campaignNameRegexNotMatch)).test(campaignName)) {
        return rule.speedDialName as SpeedDialName;
      }
    }

    if (campaignRules.default) {
      return campaignRules.default.speedDialName as SpeedDialName;
    }
  }

  throw new Error(`Unable to determine dialer speed dial for campaign: ${campaignName}`);
}
