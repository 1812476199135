import * as React from 'react';
import { MedicarePrescriptionDrugPlanQuotePanelProps } from './MedicarePrescriptionDrugPlanQuotePanel.interfaces';
import { useSelectedQuoteDetector } from '../../../../useSelectedQuoteDetector';
import { MedicareSelectedQuotes } from '../MedicareSelectedQuotes';
import { MedicareExternalQuote } from '../MedicareExternalQuote';

export const MedicarePrescriptionDrugPlanQuotePanel: React.FunctionComponent<
MedicarePrescriptionDrugPlanQuotePanelProps> = () => {
  const hasSelectedQuote = useSelectedQuoteDetector();
  if (hasSelectedQuote) {
    return <MedicareSelectedQuotes />;
  }
  return <MedicareExternalQuote />;
};
