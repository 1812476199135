import { UserProfile } from '@hiq/crm.types';
import { useCurrentUserQuery } from '../../graphql';

export function useCurrentUserIsAgent(): boolean {
  const { data } = useCurrentUserQuery();
  return [
    UserProfile.Agent,
    UserProfile.BionicAgent,
    UserProfile.BionicAgentLite,
    UserProfile.ControlAgent,
    UserProfile.PrecisionMedicareLiteAgent,
    UserProfile.PrecisionMedicareHeavyAgent,
  ].includes(data?.currentUser?.profile as UserProfile);
}
