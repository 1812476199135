import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import { QuestionType } from '@hiq/crm.types';
import { InputFactory } from '../inputs';
import { Button, Typography } from '@material-ui/core';

type DNCAddPhoneNumberInputProps = {
  onSave: (phoneNumber: string) => void;
};

export const DNCAddPhoneNumberInput: React.FC<DNCAddPhoneNumberInputProps> = ({ onSave }) => {
  const [isAdding, setIsAdding] = React.useState(false);
  const [newPhoneNumber, setNewNumber] = React.useState('');

  const [errors, setErrors] = React.useState<string[]>([]);

  function handleCancel() {
    cleanInput();
  }

  function handleSave() {
    if (!validate()) return;

    onSave(newPhoneNumber);
    cleanInput();
  }

  function cleanInput() {
    setNewNumber('');
    setIsAdding(false);
  }

  function validate(): boolean {
    const errors = [];

    if (!newPhoneNumber) {
      errors.push('Phone Number must be 10 digits and numeric.');
    }

    setErrors(errors);
    return errors.length === 0;
  }

  return (
    <>
      {errors.length > 0
          && errors.map((e) => (
            <Typography style={{ color: 'red' }} key={e} paragraph>
              {' '}
              {e}
              {' '}
            </Typography>
          ))}

      {isAdding ? (
        <>
          <InputFactory
            type={QuestionType.Phone}
            onChange={(value) => {
              setNewNumber(value?.toString());
            }}
            value={newPhoneNumber}
          />
          <Button variant="contained" color="primary" style={{ color: 'white', marginLeft: 10 }} onClick={handleSave}>
            {' '}
            Add
            {' '}
          </Button>
          <Button variant="contained" style={{ color: 'white', marginLeft: 10 }} onClick={handleCancel}>
            {' '}
            Cancel
            {' '}
          </Button>
        </>
      ) : (
        <Button startIcon={<AddIcon />} style={{ color: 'white' }} variant="contained" color="primary" onClick={() => setIsAdding(true)}>
          Add phone number
        </Button>
      )}
    </>
  );
};
