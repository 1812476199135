import * as React from 'react';
import {
  TransferNotesContainer,
  TransferNotesLabel,
  TransferNotesRow,
  TransferNotesValue,
  TransferNotesValueLine,
} from './TransferNotes.elements';
import { useCurrentTransfer } from './useCurrentTransfer';

export const TransferNotes: React.FunctionComponent = () => {
  const currentTransfer = useCurrentTransfer();
  if (!currentTransfer) {
    return <></>;
  }
  return (
    <TransferNotesContainer>
      <TransferNoteItem
        key="sdr-transferring-call"
        label="SDR Transferring Call"
        value={currentTransfer.fromUser?.name?.fullFriendlyName}
      />
      <TransferNoteItem
        key="call-direction"
        label="Call Direction"
        value={currentTransfer.callDirection}
      />
      { currentTransfer.transferNotes?.map(({ label, value }) => (
        <TransferNoteItem
          key={label}
          label={label}
          value={value}
        />
      )) }
    </TransferNotesContainer>
  );
};

function TransferNoteItem({ label, value }: { label: string; value: string; }) {
  return (
    <TransferNotesRow>
      <TransferNotesLabel>
        { label }
      </TransferNotesLabel>
      <TransferNotesValue>
        { (value ?? '- - -')?.split('\n').map((val) => (
          <TransferNotesValueLine key={val}>
            { val }
          </TransferNotesValueLine>
        )) }
      </TransferNotesValue>
    </TransferNotesRow>
  );
}
