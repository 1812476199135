import React from 'react';
import {
  CompareData, CompareLinkTitle, CompareTitleContainer, CompareTitleRow,
} from './TRMQuote.elements';
import { CompareDropdown } from './CompareDropdown';
import { CompareRank } from './CompareGrid/CompareRank';
import { useToggle } from '../../CheckMyHealthRecord/CheckMyHealthRecordDataGrid/useToggle';
import { CompareContext } from './CompareContext/CompareContext';

export type CompareTitleProps = {
  title: string
  currentPlanRank?: number
  comparisonPlanRank?: number
  padding?: string
  onToggle?: () => void
  isCollapsable?: boolean
  isOpen?: boolean
  noRank?: boolean
  children?: React.ReactNode
  fontSize?: string
  currentPlanRankLink?: string
  comparisonPlanRankLink?: string
  currentPlanTotalCost?: number
  comparisonPlanTotalCost?: number
};

export const CompareSection = ({
  title,
  currentPlanRank,
  comparisonPlanRank,
  padding,
  onToggle,
  isCollapsable = true,
  isOpen = true,
  noRank = false,
  children,
  fontSize,
  comparisonPlanRankLink,
  currentPlanRankLink,
  currentPlanTotalCost,
  comparisonPlanTotalCost,
}: CompareTitleProps) => {
  const [open, toggleSection] = useToggle(isOpen, onToggle);
  const { totalPlans } = React.useContext(CompareContext);
  return (
    <>
      <CompareTitleRow padding={padding}>
        <CompareData hidePadding>
          <CompareTitleContainer onClick={isCollapsable ? toggleSection : () => {}}>
            {isCollapsable ? <CompareDropdown open={open} /> : null}
            <CompareLinkTitle isCollapsable={isCollapsable} fontSize={fontSize}>{title}</CompareLinkTitle>
          </CompareTitleContainer>
        </CompareData>

        <CompareRank
          rank={currentPlanRank}
          totalPlans={totalPlans}
          highlight={currentPlanRank < comparisonPlanRank}
          link={currentPlanRankLink}
          totalCost={currentPlanTotalCost}
          noRank={noRank}
        />
        <CompareRank
          rank={comparisonPlanRank}
          totalPlans={totalPlans}
          link={comparisonPlanRankLink}
          highlight={comparisonPlanRank < currentPlanRank}
          totalCost={comparisonPlanTotalCost}
          noRank={noRank}
        />

      </CompareTitleRow>
      {open && children}
    </>
  );
};
