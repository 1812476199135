import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../../../themes';

export const RelatedCustomerContainer = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${theme.color.grayLight};
  flex-wrap: wrap;
  padding: 3px 0;
`);

export const RelatedCustomerLabel = styled('div')(css`
  flex: 1;
  min-width: 50px;
  text-align: left;
  margin-right: 5px;
  padding-top: 5px;
  font-weight: bold;
`);

export const RelatedCustomerLink = styled('a')<ComponentWithThemeProps>(({ theme }) => css`
  margin-top: 5px;
  display: block;
  min-width: 250px;
  flex: 2;
  font-size: 16px;
  text-decoration: none;
  color: ${theme.color.primary};
`);
