import { gql } from 'apollo-boost';
import { MutationCustomReturn, useMutationCustom } from '../useMutationCustom';
import {
  PrefillBoltMutationResponse,
  PrefillBoltMutationVariables,
} from './usePrefillBoltMutation.interfaces';

const PREFILL_BOLT_MUTATION = gql`
  mutation PrefillBolt($leadId: ID!) {
    prefillBolt(leadId: $leadId) {
      code
      success
      message
      timestamp
      boltReference {
        id
        url
      }
    }
  }
`;

export function usePrefillBoltMutation(): MutationCustomReturn<
PrefillBoltMutationResponse,
PrefillBoltMutationVariables> {
  return useMutationCustom<
  PrefillBoltMutationResponse,
  PrefillBoltMutationVariables>(PREFILL_BOLT_MUTATION);
}
