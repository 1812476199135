import React, { FC } from 'react';
import { ProductTypePropertyAndCasualty } from '@hiq/crm.types';
import { isPresent } from '@healthiqeng/core.util.is-present';
import { PNCBaseExternalQuote } from '../../../../../common';
import { PropertyAndCasualtyQuotePanelFactoryProps } from './PropertyAndCasualtyQuotePanelFactory.interfaces';
import { useSelectedQuoteDetector } from '../../../useSelectedQuoteDetector';
import { PropertyAndCasualtyLeadPanelCard } from '../../elements';
import { PropertyAndCasualtySelectedQuotes } from './PropertyAndCasualtySelectedQuotes';
import { AutoQuotePanel } from './AutoQuotePanel';
import { HomeQuotePanel } from './HomeQuotePanel';

export const PropertyAndCasualtyQuotePanelFactory: FC<PropertyAndCasualtyQuotePanelFactoryProps> = ({ productType }) => {
  const hasSelectedQuote = useSelectedQuoteDetector();
  if (hasSelectedQuote && isNonAutoOrHomeProduct(productType)) {
    return <PropertyAndCasualtySelectedQuotes />;
  }

  if (!isPresent(productType)) {
    return <>Choose a product type to begin quoting</>;
  }

  switch (productType) {
    case ProductTypePropertyAndCasualty.Auto:
      return <AutoQuotePanel />;
    case ProductTypePropertyAndCasualty.Home:
    case ProductTypePropertyAndCasualty.Renters:
    case ProductTypePropertyAndCasualty.Condo:
      return <HomeQuotePanel productType={productType} />;
    case ProductTypePropertyAndCasualty.Motorcycle:
    case ProductTypePropertyAndCasualty.Umbrella:
    case ProductTypePropertyAndCasualty.Watercraft:
    case ProductTypePropertyAndCasualty.Powersports:
    case ProductTypePropertyAndCasualty.Landlord:
    case ProductTypePropertyAndCasualty.DwellingFire:
    case ProductTypePropertyAndCasualty.Earthquake:
    case ProductTypePropertyAndCasualty.Flood:
    case ProductTypePropertyAndCasualty.MobileHome:
    case ProductTypePropertyAndCasualty.InlandMarine:
    case ProductTypePropertyAndCasualty.OffRoad:
    case ProductTypePropertyAndCasualty.MotorhomeRV:
      return (
        <PropertyAndCasualtyLeadPanelCard>
          <PNCBaseExternalQuote productType={productType} />
        </PropertyAndCasualtyLeadPanelCard>
      );
    default:
      return (
        <>
          { `Product type ${productType} not implemented` }
        </>
      );
  }
};

function isNonAutoOrHomeProduct(productType: ProductTypePropertyAndCasualty): boolean {
  return ![
    ProductTypePropertyAndCasualty.Auto,
    ProductTypePropertyAndCasualty.Home,
    ProductTypePropertyAndCasualty.Renters,
    ProductTypePropertyAndCasualty.Condo,
  ].includes(productType);
}
