import * as React from 'react';
import { MedicareSelectedQuotes } from '../MedicareSelectedQuotes';
import { MedicareExternalQuote } from '../MedicareExternalQuote';
import { MedicareSupplementQuoter } from './MedicareSupplementQuoter';
import { QuotePanel } from '../../../../common/QuotePanel';

export const MedicareSupplementQuotePanel: React.FunctionComponent = () => (
  <QuotePanel
    selectedQuotes={<MedicareSelectedQuotes />}
    externalQuote={<MedicareExternalQuote />}
    quoter={<MedicareSupplementQuoter />}
  />
);
