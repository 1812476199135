/* eslint max-len: 0 */
import React from 'react';
import { CompareContext } from '../CompareContext/CompareContext';
import { ComparePrice } from './ComparePrice';
import { CompareMedCosts } from './CompareMedCosts/CompareMedCosts';
import { ComparePremium } from './ComparePremium';
import { CompareSection } from '../CompareSection';
import { CompareContainer } from '../TRMQuote.elements';
import { useCurrentUserQuery } from '../../../../../graphql';
import { UserAbValues } from '@hiq/crm.types';
import { RequiredBenefits } from './RequiredBenefits/RequiredBenefits';

export const CompareOop = () => {
  const {
    currentPlan,
    currentComparisonPlan,
    planType,
    zipCode,
    countyCode,
    baseUrl,
    emitPlanComparisonEvent,
    customerCode,
  } = React.useContext(CompareContext);

  const onToggle = React.useCallback(() => emitPlanComparisonEvent('Opened Oop'),
    [emitPlanComparisonEvent]);

  const parsePlanType = React.useCallback((planType: string) => {
    if (planType === 'SN') return 'SNP';
    return planType;
  }, []);

  const { data: currentUser } = useCurrentUserQuery();

  const showMedCosts = React.useMemo(() => (currentPlan?.hiqMedCostsResults?.hiqSections?.length || currentComparisonPlan?.hiqMedCostsResults?.hiqSections?.length)
        && currentUser?.currentUser?.abValue === UserAbValues.COST_SHARE, [currentPlan, currentComparisonPlan, currentUser]);

  const formatRxLink = React.useCallback(
    (id: number) => `${baseUrl}/app/blaze/portal/?cc=${customerCode}#/plandetails/drugs/${zipCode}/${countyCode}/${parsePlanType(planType)}/${id}`,
    [baseUrl, customerCode, zipCode, countyCode, planType],
  );

  return (
    <CompareContainer>
      <CompareSection
        title="Annual OOP Costs"
        currentPlanRank={currentPlan?.hiqOopCostsResults?.oopCostsRank}
        comparisonPlanRank={currentComparisonPlan?.hiqOopCostsResults?.oopCostsRank}
        onToggle={onToggle}
      >
        <ComparePrice
          benefitName="Rx Costs"
          comparisonValue={currentComparisonPlan?.hiqOopCostsResults?.drugCostAnnual}
          currentValue={currentPlan?.hiqOopCostsResults?.drugCostAnnual}
          currentLink={formatRxLink(currentPlan?.id)}
          comparisonLink={formatRxLink(currentComparisonPlan?.id)}
        />
        {showMedCosts ? <CompareMedCosts /> : null}
        <ComparePremium />
        <ComparePrice
          benefitName="Buybacks"
          comparisonValue={currentComparisonPlan?.hiqOopCostsResults?.buybacks}
          currentValue={currentPlan?.hiqOopCostsResults?.buybacks}
          allowNegative
        />
        <ComparePrice
          benefitName="Total OOP"
          comparisonValue={currentComparisonPlan?.hiqOopCostsResults?.totalCostsAnnual}
          currentValue={currentPlan?.hiqOopCostsResults?.totalCostsAnnual}
        />
      </CompareSection>
      <RequiredBenefits
        currentPlan={currentPlan}
        currentComparisonPlan={currentComparisonPlan}
        onToggle={onToggle}
      />
    </CompareContainer>
  );
};
