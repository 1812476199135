import * as React from 'react';
import { DateTimeInputProps } from './DateTimeInput.interfaces';
import { TextField } from '@material-ui/core';
import { useInputOnChange } from '../useInputOnChange';
import { identityTransformerFactory } from '../transformers';

export const DateTimeInput: React.FunctionComponent<DateTimeInputProps> = ({
  value,
  onChange,
  label,
  fullWidth,
  size,
  variant,
}) => {
  const [formattedValue, handleChange] = useInputOnChange(value, onChange, identityTransformerFactory);

  return (
    <TextField
      value={formattedValue}
      onChange={handleChange}
      label={label}
      fullWidth={fullWidth}
      size={size}
      variant={variant}
      type="datetime-local"
    />
  );
};
