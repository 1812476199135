import * as React from 'react';

type WrappedComponentProps<
  INNER_PROPS,
  OUTER_INPUT_PROPS,
  OUTER_OUTPUT_PROPS
  > = Omit<INNER_PROPS, keyof OUTER_OUTPUT_PROPS> & Omit<OUTER_INPUT_PROPS, 'render'>;

export function wrap<OUTER_OUTPUT_PROPS>() {
  return <
    INNER_PROPS,
    OUTER_INPUT_PROPS extends {render: (props: OUTER_OUTPUT_PROPS) => JSX.Element}
    >(
    Inner: React.ComponentType<INNER_PROPS>,
    Outer: React.ComponentType<OUTER_INPUT_PROPS>,
  ) => ((props: any) => (
    <Outer
      render={(controllerOutputProps: any) => (
        <Inner
          {...controllerOutputProps}
          {...props}
        />
      )}
      {...props}
    />
  )) as any as React.ComponentType<WrappedComponentProps<INNER_PROPS, OUTER_INPUT_PROPS, OUTER_OUTPUT_PROPS>>;
}
