import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';
import { DiseaseBenefitsQueryData, DiseaseBenefitsQueryVariables } from './useDiseaseBenefitsQuery.interfaces';

const GET_DISEASE_BENEFITS_QUERY = gql`
  query GetDiseaseBenefits($diseaseKeys: [DiseaseBenefit!]!) {
    getDiseaseBenefits(diseaseKeys: $diseaseKeys) {
      diseaseKey
      benefits {
        benefit
        context
      }
    }
  }
`;

export function useDiseaseBenefitsQuery() {
  return useLazyQuery<DiseaseBenefitsQueryData, DiseaseBenefitsQueryVariables>(GET_DISEASE_BENEFITS_QUERY);
}
