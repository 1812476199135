import * as React from 'react';
import { useDashboardListQuery } from '../../../graphql';
import { MainPanelLayout, MainPanelLayoutTabItem } from '../../layouts';
import { HomeStyledIframe } from './ManagerHome.elements';

enum ManagerSections {
  AGENTS_HOME = 'Agents Home',
  CALL_LOG = 'Call Log',
  MANAGER_PIPELINE = 'Manager Agents Pipeline',
  MANAGER_STATS = 'Manager Stats',
  MANAGER_SOLD_CUSTOMERS = 'Manager Sold Customers',
}

export const ManagerHome: React.FunctionComponent = () => {
  const { data } = useDashboardListQuery();
  const [activeTab, setActiveTab] = React.useState('home');
  const tabs: MainPanelLayoutTabItem[] = React.useMemo(() => {
    const memoTabs: MainPanelLayoutTabItem[] = [
      {
        id: 'home',
        label: 'HOME',
        content: (
          <HomeStyledIframe
            data-qa="agent-home-tab"
            src={data?.dashboardList?.find((dl) => dl.name === ManagerSections.AGENTS_HOME)?.url}
          />
        ),
      },
      {
        id: 'appointments',
        label: 'APPOINTMENTS',
        content: (
          <HomeStyledIframe
            data-qa="agent-appointments-tab"
            src={data?.dashboardList?.find((dl) => dl.name === ManagerSections.MANAGER_PIPELINE)?.url}
          />
        ),
      },
      {
        id: 'sold-customers',
        label: 'SOLD CUSTOMERS',
        content: (
          <HomeStyledIframe
            data-qa="agent-sold-customers-tab"
            src={data?.dashboardList?.find((dl) => dl.name === ManagerSections.MANAGER_SOLD_CUSTOMERS)?.url}
          />
        ),
      },
      {
        id: 'agent-stats',
        label: 'AGENT STATS',
        content: (
          <HomeStyledIframe
            data-qa="agent-stats-tab"
            src={data?.dashboardList?.find((dl) => dl.name === ManagerSections.MANAGER_STATS)?.url}
          />
        ),
      },
      {
        id: 'call-log',
        label: 'CALL LOG',
        content: (
          <HomeStyledIframe
            data-qa="agent-call-log-tab"
            src={data?.dashboardList?.find((dl) => dl.name === ManagerSections.CALL_LOG)?.url}
          />
        ),
      },
    ];
    return memoTabs;
  }, [data?.dashboardList]);

  return (
    <MainPanelLayout
      tabs={tabs}
      activeId={activeTab}
      onTabChange={setActiveTab}
      tabColumnVerticalOverflow="hidden"
    />
  );
};
