import React from 'react';
import { Anchor } from '../../Anchor';

export abstract class AbstractAnchorDetector<P, S> extends React.Component<P, S> {
  private static isElementInViewPort(elem: Element) {
    const rect = elem.getBoundingClientRect();
    const elemTop = rect.top;
    const elemBottom = rect.bottom;

    return elemTop < window.innerHeight && elemBottom >= 0;
  }

  protected abstract onScroll: () => void;
  private ANCHOR_CSS_SELECTOR = `*[${Anchor.attributeName}]`;
  private ANCHOR_NAME_ATTRIBUTE = Anchor.attributeName;

  public componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
  }

  public componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  public render() {
    return this.props.children;
  }

  protected forEachVisibleAnchor(callback: (anchorName: string) => void) {
    const elements = document.querySelectorAll(this.ANCHOR_CSS_SELECTOR);
    elements.forEach((elem) => {
      if (AbstractAnchorDetector.isElementInViewPort(elem)) {
        const anchorName = elem.getAttribute(this.ANCHOR_NAME_ATTRIBUTE);
        callback(anchorName);
      }
    });
  }
}
