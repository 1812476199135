import { FormAnswers } from '@hiq/crm.types';

export function getNumArrayItems(formId: string, answers: FormAnswers): number {
  const indexes = Object.keys(answers)
    .filter((key) => key.startsWith(formId))
    .map((key) => key.replace(`${formId}.`, ''))
    .map((key) => Number.parseInt(key.split('.')[0]))
    .filter((index) => !Number.isNaN(index));
  const deletedIndexes = Object.entries(answers)
    .filter(([key, value]) => key.includes('__deleted') && value)
    .map(([key]) => key)
    .map((key) => key.replace(`${formId}.`, ''))
    .map((key) => Number.parseInt(key.split('.')[0]));
  const activeIndexes = indexes.filter((i) => !deletedIndexes.includes(i));
  if (activeIndexes.length === 0) return 0;
  const maxIndex = activeIndexes
    .reduce((max, current) => Math.max(max, current), 0);
  return maxIndex + 1;
}
