import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const GridListUlElement = styled('ul')<{ minWidth: string; }>((props) => css`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(${props.minWidth}, 1fr));
  grid-gap: 5px;
  margin: 0;
  padding: 0;
`);

export const GridListLiElement = styled('li')(css`
  list-style-type: none;
  margin: 0;
`);
