import * as React from 'react';
import { QuestionFlowRendererComponent } from '../QuestionFlowRenderer.interfaces';
import { ScriptBlockRendererProps } from './ScriptBlockRenderer.interfaces';
import { ScriptBlockRenderer } from './ScriptBlockRenderer';
import { QuestionFlowComponent } from '../../QuestionFlow.interfaces';

type ScriptBlockRendererWrapperProps = Omit<
ScriptBlockRendererProps,
'leadId' | 'QuestionFlowRendererComponent' | 'questionFlowComponent'
>;
export const scriptBlockRendererFactory = (
  leadId: string,
  questionFlowComponent: QuestionFlowComponent,
  questionFlowRendererComponent: QuestionFlowRendererComponent,
) => {
  const ScriptBlockRendererWrapper: React.FunctionComponent<ScriptBlockRendererWrapperProps> = (props) => (
    <ScriptBlockRenderer
      leadId={leadId}
      questionFlowComponent={questionFlowComponent}
      QuestionFlowRenderer={questionFlowRendererComponent}
      {...props}
    />
  );
  return ScriptBlockRendererWrapper;
};
