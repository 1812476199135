import React, {
  SyntheticEvent, useEffect, useMemo, useRef, useState,
} from 'react';
import { LocationButton } from '../../LocationSearchPanel/LocationSearchPanel.elements';
import { LocationDetail, Panel } from './DoctorSearchPanel.elements';
import { DoctorSearchPanelProps } from './DoctorSearchPanel.interfaces';
import { useDebounce } from '@healthiqeng/core.hooks.use-debounce';
import { Autocomplete } from '@material-ui/lab';
import { CircularProgress, TextField } from '@material-ui/core';
import { useSunfireV2DoctorLocationsQuery, useSunfireV2DoctorsByTermQuery } from '../../../../../../graphql';
import { useActiveLeadId } from '../../../../../routers';
import { QueryLoader } from '../../../../QueryLoader';
import { Doctor } from '../AddDoctorPanel/AddDoctorPanel.interfaces';

export const DoctorSearchPanel = ({ onDoctorChanged, location, onLocationChanged }: DoctorSearchPanelProps) => {
  const [providerName, setProviderName] = useState('');
  const [doctorOptions, setDoctorOptions] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState<Doctor>();
  const [isOpen, setIsOpen] = useState(true);
  const leadId = useActiveLeadId();
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    inputRef.current.focus();
  }, []);
  const [
    findSunfireDoctorsByTerm,
    {
      data: doctorData, loading, error,
    },
  ] = useSunfireV2DoctorsByTermQuery();
  const [
    findSunfireDoctorLocations,
    {
      data: locationsData, error: locationsError,
    },
  ] = useSunfireV2DoctorLocationsQuery();

  const locations = React.useMemo(() => {
    if (!selectedDoctor || !locationsData?.findSunfireDoctorLocations?.length) return null;

    const fullAddresses = locationsData.findSunfireDoctorLocations.map(
      ({
        address1, address2, city, state, zip,
      }) => [address1, address2, city, state, zip].filter((str) => str).join(', '),
    );
    return (
      <ul>
        {fullAddresses.map((address) => <li>{address}</li>)}
      </ul>
    );
  }, [selectedDoctor, locationsData]);

  const updateDropDown = (doctorName: string) => () => {
    findSunfireDoctorsByTerm({
      variables: {
        leadId,
        city: location.city,
        state: location.state,
        term: doctorName,
      },
    });
  };

  const memoizedUpdateDropDown = useMemo(() => updateDropDown(providerName), [providerName]);

  const debouncedUpdated = useDebounce(memoizedUpdateDropDown, 2000);

  useEffect(() => {
    if (providerName && providerName.length > 1) debouncedUpdated();
  }, [providerName]);

  const handleOnDoctorChange = (event: SyntheticEvent, value: string, reason: string) => {
    setProviderName(value);

    if (reason === 'input') {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  const { city, state } = location;
  const handleDoctorSelected = React.useCallback((event: SyntheticEvent, value: any, reason: string) => {
    if (reason === 'select-option') {
      setSelectedDoctor(value.doctor);
      onDoctorChanged(value.doctor);
      findSunfireDoctorLocations({
        variables: {
          doctorId: value.doctor.id,
          city,
          state,
        },
      });
    } else if (reason === 'clear') {
      setSelectedDoctor(undefined);
      onDoctorChanged(undefined);
    }
  }, [city, state, setSelectedDoctor, onDoctorChanged, findSunfireDoctorLocations]);

  useEffect(() => {
    if (doctorData?.findSunfireDoctorsByTerm?.length > 0) {
      const doctors = doctorData?.findSunfireDoctorsByTerm;
      const doctorOptionsMap = doctors.map((doctor) => ({
        label: doctor.name,
        doctor,
      }));
      setDoctorOptions(doctorOptionsMap);
    }
  }, [doctorData]);

  return (
    <Panel>
      <div>
        Doctors near&nbsp;
        <LocationDetail>{`${city}, ${state}`}</LocationDetail>
        <LocationButton
          onClick={() => onLocationChanged(undefined)}
        >
          Change Location
        </LocationButton>
      </div>
      <div>What is your doctor&apos;s last name</div>
      <QueryLoader error={error || locationsError} loading={false} componentName="DoctorSearchPanel">
        <Autocomplete
          inputValue={providerName}
          onInputChange={handleOnDoctorChange}
          onChange={handleDoctorSelected}
          options={doctorOptions}
          getOptionLabel={(option) => option.label}
          style={{ width: 300 }}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Doctor"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                inputRef,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          open={providerName.length > 2 && doctorOptions.length > 0 && isOpen}
        />
        {locations}
      </QueryLoader>
    </Panel>
  );
};
