import * as React from 'react';
import { QuestionCellProps } from './QuestionCell.interfaces';
import {
  QuestionCellContainer,
  QuestionText,
  QuestionInputContainer,
} from './QuestionCell.elements';
import { QuestionTags } from '../QuestionTags';

export const QuestionCell: React.FunctionComponent<QuestionCellProps> = ({
  label,
  tags,
  input,
}) => (
  <QuestionCellContainer>
    <QuestionText>
      { label }
      <QuestionTags tags={tags} />
    </QuestionText>
    <QuestionInputContainer>
      { input }
    </QuestionInputContainer>
  </QuestionCellContainer>
);
