import { FormFieldsStatus } from '@hiq/crm.types';
import { isPresent } from '@healthiqeng/core.util.is-present';
import { FormsStatusMap } from '../MultiSectionQuestionFlowRenderer.interfaces';

export interface ParsedQuestionFlowSection {
  markdown: string;
  isLocked?: boolean;
}

export const useControlledMarkdownSections = (
  sectionsMarkdown: string[],
  formStatusMaps: FormsStatusMap[],
): [ParsedQuestionFlowSection[], string[]] => {
  if (!isPresent(formStatusMaps[0])) {
    return [(sectionsMarkdown ?? []).map((s) => ({ markdown: s, isLocked: false })), []];
  }

  let shouldLock = false;
  let invalidFields: string[] = [];
  const sections = sectionsMarkdown.map((markdown: string, index: number) => {
    const formStatus = formStatusMaps[index];
    if (!isPresent(formStatus)) {
      return { markdown, isLocked: !!invalidFields.length };
    }
    invalidFields = [
      ...invalidFields,
      ...getInvalidFieldsForNavigation(formStatusMaps[index]),
    ];
    const section = {
      markdown,
      isLocked: shouldLock,
    };
    if (!isValidForNavigation(formStatusMaps[index])) {
      shouldLock = true;
    }
    return section;
  });
  return [sections, invalidFields];
};

function getInvalidFieldsForNavigation(statuses: FormsStatusMap): string[] {
  return Array
    .from(statuses.values())
    .flatMap((status: FormFieldsStatus) => Object
      .values(status)
      .filter((s) => !s.validNav).map((s) => s.label));
}

function isValidForNavigation(statuses: FormsStatusMap): boolean {
  return Array
    .from(statuses.values())
    .every((status: FormFieldsStatus) => Object.values(status).every((s) => s.validNav));
}
