import { gql } from 'apollo-boost';
import { PRODUCT_FRAGMENT } from '../../fragments';
import { FetchLifeProductsOptions, Uuid } from '@hiq/crm.types';
import { useQuery } from '@apollo/react-hooks';
import { LifeProductsQueryData, LifeProductsQueryVariables } from './useLifeProductsQuery.interfaces';

const LIFE_PRODUCTS_QUERY = gql`
  query getLifeProducts($leadId: ID!, $inputs: LifeProductInput!) {
    lifeQuoteProducts(leadId: $leadId, inputs: $inputs) {
      ...ProductFragment
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export function useLifeProductsQuery(leadId: Uuid, inputs: FetchLifeProductsOptions) {
  return useQuery<LifeProductsQueryData, LifeProductsQueryVariables>(LIFE_PRODUCTS_QUERY, {
    variables: { leadId, inputs },
    skip: !inputs.carrierId,
  });
}
