import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../../themes';

export const MainPanelTabContainer = styled('div')(css`
  width: 100%;
  display: flex;
  flex-direction: column;
`);

export const IconWrapper = styled('div')(css`
  position: relative;
  top: 6px;
  margin-right: 10px;
`);

interface TabWrapperProps extends ComponentWithThemeProps {
  active?: boolean;
}

export const TabWrapper = styled('div')<TabWrapperProps>(({ theme, active }) => css`
  width: 100%;
  display: flex;
  padding-left: 34px;
  background-color: ${active ? theme.color.primaryLight : theme.color.white};
`);
