import * as React from 'react';
import { DropdownOptions } from '../DropdownOptions';
import { InputControl } from './Dropdown.elements';
import { IDropdownProps } from './Dropdown.interfaces';

export class Dropdown extends React.Component<IDropdownProps> {
  public render() {
    return (
      <InputControl
        controlSize={this.props.controlSize || 'large'}
        value={this.props.value || ''}
        onChange={this.changeHandler}
      >
        <DropdownOptions options={this.props.options} />
      </InputControl>
    );
  }

  private changeHandler = (e: React.FormEvent<HTMLSelectElement>) => {
    this.props.onChange && this.props.onChange(e.currentTarget.value);
  };
}
