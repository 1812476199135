import styled from '@emotion/styled';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import { css } from '@emotion/core';
import IconButton from '@material-ui/core/IconButton';
import {
  ColumnProps, CompareDataProps, RowProps, ViewLinkButtonProps,
} from './TRMQuote.interfaces';

export const CompareTitle = styled.p`
  font-weight: 700;
  font-size: 20px;
`;

interface CompareLinkTitleProps {
  fontSize?: string,
  isCollapsable?: boolean
}

export const CompareLinkTitle = styled(CompareTitle)<CompareLinkTitleProps>(({ fontSize = '20px', isCollapsable = true }) => css`
  font-weight: 700;
  font-size: ${fontSize};
  text-decoration-line:${isCollapsable ? 'underline' : 'none'};
  color: ${isCollapsable ? '#0075CA;' : 'black'};
`);

export const ResetTitle = styled.p`
  font-weight: 700;
  font-size: 20px;
  color: #7F8385;
`;

export const CompareSubTitle = styled(CompareLinkTitle)`
  font-size: 16px;
`;

export const CompareTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  height: calc(100vh - 180px);
`;

export const Bold = styled.span`
  font-weight: bold;
`;

const getCompareDataBackgroundColor = (highlight: boolean, empty: boolean) => {
  if (highlight) return '#E4FBE9';
  if (empty) return '#F3F5F7';
  return '#FFF';
};

export const CompareData = styled.td<CompareDataProps>(({ empty, hidePadding, highlight }) => css`
  padding: ${!hidePadding ? '1em' : '0px'};
  outline: 0.25px solid #B1B7BA;
  width: 33.3%;
  font-size: 16px;
  background-color: ${getCompareDataBackgroundColor(highlight, empty)};
`);

export const CompareDataItem = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CompareDataBold = styled(CompareData)`
  font-weight: bold;
  font-size: 16px;
`;

export const CompareTitleRow = styled.tr<{padding?: string}>(({ padding = '0px' }) => css`
    & td:first-child {
    padding-left: ${padding};
  }
`);

export const CompareRow = styled.tr`
  & td:first-child {
    padding-left: 23px;
  }
`;

export const CompareContainer = styled.table`
  outline: 0.5px solid #FFF;
  border-spacing: 0px;
  width: 100%;
  border-bottom: 2px solid #B1B7BA;
  margin-bottom: 40px;
`;

export const LinkButton = withStyles({
  root: {
    color: '#000000',
    padding: '10px 0 0 0',
  },
})(IconButton);

const QuoteButton = withStyles({
  root: {
    height: 48,
    padding: '0 20px',
    textTransform: 'capitalize',
    borderRadius: 4,
  },
})(Button);

export const EnrollButton = withStyles({
  root: {
    background: '#0075CA',
    width: '116px',
    color: 'white',
    '&:hover': {
      background: 'rgba(0,117,202,0.8)',
    },
  },
})(QuoteButton);

export const ConfirmButton = withStyles({
  root: {
    background: '#0075CA',
    width: '124px',
    color: 'white',
    '&:hover': {
      background: 'rgba(0,117,202,0.8)',
    },
  },
})(QuoteButton);

export const PPEButton = withStyles({
  root: {
    background: 'white',
    width: '116px',
    borderColor: '#0075CA',
    color: '#0075CA',
    '&:hover': {
      background: 'rgba(255,242,255,0.7)',
    },
  },
})(QuoteButton);

export const ResetButton = withStyles({
  root: {
    background: 'white',
    width: '248px',
    borderColor: '#0075CA',
    color: '#0075CA',
    '&:hover': {
      background: 'rgba(255,242,255,0.7)',
    },
  },
})(QuoteButton);

export const Row = styled.div<RowProps>(({ justifyContent, alignItems = 'center', width }) => css`
  display: flex;
  flex-direction: row;
  align-items: ${alignItems};
  justify-content: ${justifyContent};
  width: ${width};
`);
export const Column = styled.div<ColumnProps>(({
  justifyItems = 'flex-start', width = 'auto', paddingTop = '0px', gridGap = '10px', paddingLeft = '0px',
}) => css`
  display: grid;
  grid-gap: ${gridGap};
  justify-items: ${justifyItems};
  width: ${width};
  padding-top: ${paddingTop};
  padding-left: ${paddingLeft};
`);

export const ViewLinkButton = styled.button<ViewLinkButtonProps>(({ disabled, paddingLeft }) => css`
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  text-decoration-line: underline;
  opacity: ${disabled ? 0.3 : 1};
  color: #0075CA;
  background:transparent;
  padding: 0;
  border: none;
  padding-left: ${paddingLeft};
`);

export const LinkText = styled.span`
  font-weight: 700;
  font-size: 16px;
  margin: 0px;
  text-decoration-line: underline;
  color: #0075CA;
  cursor: pointer;
`;

export const Footer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
`;

export const FooterLeft = styled.div`
  display: grid;
  justify-items: flex-start;
  grid-column: 3;
  padding: 10px;
`;
