import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import {
  FORM_FRAGMENT, QUESTION_FLOW_FRAGMENT, QUESTION_FRAGMENT,
} from '../../fragments';
import {
  QuestionFlowFromInputQueryData,
  QuestionFlowFromInputQueryVariables,
  QuestionFlowInput,
} from './useQuestionFlowFromInputQuery.interfaces';
import { parseData } from '../useQuestionFlowQuery';
import { VariationChangeEvent, useEventListener } from '../../../events';
import { logger } from '../../../services';

const QUESTION_FLOW_QUERY = gql`
  query getQuestionFlowFromInput($referenceId: String!, $input: QuestionFlowQueryInput) {
    questionFlowFromInput(referenceId: $referenceId, input: $input) {
      questionFlow {
        ...QuestionFlowFragment
      }
      questionFlows {
        ...QuestionFlowFragment
      }
      forms {
        ...FormFragment
      }
      questions {
        ...QuestionFragment
      }
    }
  }
  ${QUESTION_FLOW_FRAGMENT}
  ${FORM_FRAGMENT}
  ${QUESTION_FRAGMENT}
`;

export function useQuestionFlowFromInputQuery(referenceId: string, input: QuestionFlowInput = {}) {
  const {
    loading, error, data, refetch,
  } = useQuery<QuestionFlowFromInputQueryData, QuestionFlowFromInputQueryVariables>(QUESTION_FLOW_QUERY, {
    variables: {
      referenceId,
      input,
    },
  });

  useEventListener(VariationChangeEvent, () => {
    refetch().catch((err) => logger.crit(err));
  });

  return {
    loading,
    error,
    data: data && parseData(data?.questionFlowFromInput),
  };
}
