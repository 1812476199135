import { useLeadQuery } from '../../../../../graphql/queries/useLeadQuery';
import { useActiveLeadId } from '../../../../routers';

export const useLeadCallDirection = () => {
  const leadId = useActiveLeadId();
  const { data: leadData } = useLeadQuery(leadId);

  const leadCampaign = leadData?.campaignName;
  if (leadCampaign?.match(/.* IB (?!Transfer).*/)) return LeadCallDirection.INBOUND;
  if (leadCampaign?.match(/User IB .*/)) return LeadCallDirection.INBOUND;

  return LeadCallDirection.OUTBOUND;
};

export enum LeadCallDirection {
  INBOUND='INBOUND CALL',
  OUTBOUND='OUTBOUND CALL',
}
