import { ApolloError, gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';
import { BionicFetchPlansRecommendationQueryData, BionicFetchPlansRecommendationQueryVariables } from './useBionicFetchRecommendedPlansQuery.interfaces';
import { FetchPolicy } from '@apollo/client/core/watchQueryOptions';
import { getEventBus } from '../../../events';
import { useCallback } from 'react';
import { RecommendedPlansEventProperties } from '../../../events/MedDigitalAnalyticsEvent.interfaces';
import { MedDigitalAnalyticsEvent } from '../../../events/MedDigitalAnalyticsEvent';
import { QueryTuple } from '@apollo/client/react/types/types';

const PLAN_FRAGMENT = `
  {
  id
  contractId
  planId
  name
  planYear
  carrierName
  carrierId
  segmentId
  summaryBenefitsUrl
  hiqProviderLookupUrl
  rating
  hiqRank
  evidenceOfCoverage
  hiqDiagnosesRank
  hiqRecommended
  hiqMedCostsResults {
    hiqRank
    hiqSections {
          name
          hiqRank 
          benefits {
              code
              hiqDescription
              hiqRank
              oopCost
              deductible
              unitCost
              coins
              copay
              totalCost
              costShare
       }
    }
  }
  hiqCustomerServiceResults {
    customerServiceScore
    customerServiceRank
  }
  hiqOopCostsResults {
    totalCostsAnnual
    premiumAnnual
    premiumMonthly
    drugCostAnnual
    oopCostsRank
    buybacks
    healthCostAnnual
    inNetworkMoop
    outOfNetworkMoop
    combinedMoop
    drugDeductible
    costDetails {
      deductibles {
        innDeductible
        annualDeductible
        combinedDeductible
      }
      innMoop {
        value
      }
      pcp {
        copay
        coins
      }
      emergencyRoom {
        copay
        coins
      }
      inpatientHospital {
        detail
      }
      outpatientHospital {
        copay
        coins
      }
      coverageGap {
        tiers
      }
      specialist {
        copay
        coins
      }
      labs {
        copay
        coins
      }
      ambulatorySurgicalCenter {
        copay
        coins
      }
      diagnosticRadiology {
        copay
        coins
      }
      rxDed {
        value
      }
    }
  }
  hiqProviderResults {
    providersConsidered
    providersCovered
    providerRank
    providersMap {
      criticalDoctors {
        covered
        visits
        name
        specialty
        pcpIds
        coveredCount
        locations {
          address1
          address2
          city
          state
          zip
          covered
        }
      }
      otherDoctors {
        covered
        visits
        name
        specialty
        pcpIds
        coveredCount
        locations {
          address1
          address2
          city
          state
          zip
          covered
        }
      }
    }
  }

  hiqHomeCareResults {
    featureStarsTotal
    featureStars
    featureRank
    benefitKey
    benefitDetails {
      benefitDetailsKey
      displayName
      detail
      stars
    }
  }
  hiqSupplementalBenefitsResults {
    benefits {
      benefit
      detail
      score
    }
    featureRank
    coveredCount
  }

  hiqComprehensiveDentalResults {
    featureStarsTotal
    featureStars
    featureRank
    benefitKey
    benefitDetails {
      benefitDetailsKey
      displayName
      detail
      stars
    }
  }

  hiqTransportationResults {
    featureStarsTotal
    featureStars
    featureRank
    benefitKey
    benefitDetails {
      benefitDetailsKey
      displayName
      detail
      stars
    }
  }

  hiqDiagnosesResults {
    diagnoses {
      name
      hiqDiseaseKey
      hiqPlanId
      hiqRank
      allBenefitDetails {
        rank
        detail
        displayName
        benefitAndDetailKey
        detailSimple
        hiqContext
      }
    }
  }
}

`;

const BIONIC_FETCH_RECOMMENDED_PLANS_QUERY = gql`
 query BionicFetchRecommendedPlans($leadId: LeadId!) {
  fetchBionicRecommendedPlans(leadId: $leadId) {
    error
    message
    
    doctors {
     name
     id
     specialty
     visitsLastYear
     isCriticalDoctor
     lastVisit
     includedPlanCount
     visitsLast3Years
     visitsLast5Years
     addressLine1
     addressLine2
     city
     state
     zip
    }
    plans ${PLAN_FRAGMENT}
    currentPlan ${PLAN_FRAGMENT}
  }
}

`;

export function useBionicFetchRecommendedPlansQuery(leadId: string, fetchPolicy: FetchPolicy = 'cache-only')
  : QueryTuple<BionicFetchPlansRecommendationQueryData, BionicFetchPlansRecommendationQueryVariables> {
  const eventBus = getEventBus();

  const emitEvent = useCallback((message: string, properties: RecommendedPlansEventProperties) => {
    eventBus.emit(new MedDigitalAnalyticsEvent(message, properties));
  }, [eventBus]);

  const onCompleted = useCallback((data: BionicFetchPlansRecommendationQueryData) => emitEvent(
    'Success',
    {
      location: 'recommended_plans',
      plansCount: data.fetchBionicRecommendedPlans?.plans?.length,
    },
  ), [emitEvent]);

  const onError = useCallback((error: ApolloError) => emitEvent(
    'Error',
    {
      location: 'recommended_plans',
      error: error.message,
    },
  ), [emitEvent]);

  const [loadPlans, result] = useLazyQuery<BionicFetchPlansRecommendationQueryData, BionicFetchPlansRecommendationQueryVariables>(
    BIONIC_FETCH_RECOMMENDED_PLANS_QUERY, {
      variables: {
        leadId,
      },
      fetchPolicy,
      onError,
      onCompleted,
    },
  );

  const loadPlansWrapped = useCallback((options) => {
    emitEvent(
      'Request',
      {
        location: 'recommended_plans',
      },
    );
    loadPlans(options);
  }, [loadPlans, emitEvent]);

  return [loadPlansWrapped, result];
}
