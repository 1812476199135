import * as React from 'react';
import {
  QuestionFlow,
  Card,
  DialerTransferDispositions,
  ScriptVersionFooter,
} from '../../../common';
import { FullHeight } from '../../../layouts/FullHeight';
import { InitialContactLayout } from '../../../layouts';
import { useActiveLeadId } from '../../../routers/LeadRouter/useActiveLeadId';
import { InitialContactCustomerSummary } from '../../../panels';
import { LeftPanelContainer, PreSalesDashboardLink } from './PreSalesHome.elements';
import { useDashboardListQuery } from '../../../../graphql';

export const PreSalesHome: React.FunctionComponent = () => {
  const leadId = useActiveLeadId();
  const { data } = useDashboardListQuery();
  const PRE_SALES_DASHBOARD = 'Pre Sales Agent Dashboard';
  const preSalesDashboard = data ? data.dashboardList?.find((dl) => dl.name === PRE_SALES_DASHBOARD) : null;
  const leftSideContent = (
    <LeftPanelContainer>
      <InitialContactCustomerSummary />
      {preSalesDashboard
        ? (
          <PreSalesDashboardLink href={preSalesDashboard.url} target="_blank" rel="noreferrer noopener">
            {preSalesDashboard.name}
          </PreSalesDashboardLink>
        )
        : <></>}
    </LeftPanelContainer>
  );
  return (
    <FullHeight>
      <InitialContactLayout
        sideContent={leftSideContent}
        mainContent={(
          <Card>
            <DialerTransferDispositions />
            <QuestionFlow
              leadId={leadId}
              referenceId="initial-contact"
            />
          </Card>
        )}
      />
      <ScriptVersionFooter />
    </FullHeight>
  );
};
