import * as React from 'react';
import { SimpleTextElementProps } from './FormItemTypeSimpleText.interfaces';
import { SimpleQuestionView } from '../../Question';
import { QuestionType } from '@hiq/crm.types';
import { useConditionalRendering } from '../../../useConditionalRendering';
import { FormItemTypeSimpleTextContainer } from './FormItemTypeSimpleText.elements';

export const FormItemTypeSimpleText: React.FunctionComponent<SimpleTextElementProps> = ({
  label,
  visibleCondition,
  parentQuestionId,
  answers,
  depth,
  tags,
}) => {
  const formItemVisible = useConditionalRendering({
    questionId: 'none',
    parentQuestionId,
    answers,
    visibleCondition,
  });

  if (!formItemVisible) return null;

  return (
    <FormItemTypeSimpleTextContainer depth={depth}>
      <SimpleQuestionView type={QuestionType.Script} questionText={label} tags={tags} />
    </FormItemTypeSimpleTextContainer>
  );
};
