import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const Header = styled('h2')(css`
  margin: 20px 0 10px;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
`);

export const Paragraph = styled.p`
  margin-bottom: 10px;
  margin-top: 5px;
`;
