import * as React from 'react';
import Chip from '@material-ui/core/Chip';
import Alert from '@material-ui/lab/Alert';
import { CustomerTag } from '../../../../types';
import { CustomerTagsProps } from './CustomerTags.interfaces';
import {
  CustomerTagsContainer, TagWrapper, AlertWrapper, AlertsWrapper,
} from './CustomerTags.elements';
import { Icon } from '../../../common/Icon';
import { useCustomerQuery, useSpecialRateAvailabilityQuery } from '../../../../graphql';
import { isPresent } from '@healthiqeng/core.util.is-present';
import { useActiveLeadId } from '../../../routers';
import { useFormAnswers } from '../../../../hooks';
import { DispositionDialogSelect } from '../AgentDispositionDialoges/DispositionDialogSelect';

export const CustomerTags: React.FunctionComponent<CustomerTagsProps> = ({
  tags,
}) => {
  const leadId = useActiveLeadId();

  const [answers] = useFormAnswers(leadId);
  const consentApproved = answers['customer.person.hipaaConsentApproved'];

  const { data } = useSpecialRateAvailabilityQuery();
  const { data: customerData } = useCustomerQuery();
  const alertStyle = {
    paddingTop: 0,
    paddingBottom: 0,
  };

  return (
    <CustomerTagsContainer data-qa="customer-tags-container">
      <AlertsWrapper>
        { data?.getSpecialRateAvailability?.includes('MEDICARE_PREFERRED') ? (
          <AlertWrapper>
            <Alert severity="success" variant="standard" icon={<Icon type="hiq-torch" />} style={alertStyle}>
              <b>MedSup Preferred Rate Available</b>
            </Alert>
          </AlertWrapper>
        ) : data?.getSpecialRateAvailability?.includes('MEDICARE') && (
          <AlertWrapper>
            <Alert severity="success" variant="standard" icon={<Icon type="hiq-torch" />} style={alertStyle}>
              <b>MedSup Special Rate Available</b>
            </Alert>
          </AlertWrapper>
        ) }
        { !isPresent(customerData?.customer?.quizResult?.elite) && (
          <AlertWrapper>
            <Alert severity="info" variant="standard" icon={<Icon type="hiq-torch" />} style={alertStyle}>
              <b>Health Quiz Not Taken</b>
            </Alert>
          </AlertWrapper>
        ) }

        <DispositionDialogSelect />

        { isPresent(consentApproved) && (
          <AlertWrapper>
            <Alert severity="info" variant="standard" style={alertStyle}>
              <b>{consentApproved === true ? 'CMHR HIPAA Consent Approved' : 'CMHR HIPAA Consent Not Approved - Ask again'}</b>
            </Alert>
          </AlertWrapper>
        ) }

        { !isPresent(consentApproved) && (
          <AlertWrapper>
            <Alert severity="info" variant="standard" style={alertStyle}>
              <b>CMHR HIPAA Consent Not Yet Asked</b>
            </Alert>
          </AlertWrapper>
        ) }

        { customerData?.customer?.quizResult?.elite === true && (
          <AlertWrapper>
            <Alert severity="success" variant="standard" icon={<Icon type="hiq-torch" />} style={alertStyle}>
              <b>Health Literacy Quiz Passed</b>
            </Alert>
          </AlertWrapper>
        ) }
        { customerData?.customer?.quizResult?.elite === false && (
          <AlertWrapper>
            <Alert severity="error" variant="standard" icon={<Icon type="hiq-torch" />} style={alertStyle}>
              <b>Health Literacy Quiz Failed</b>
            </Alert>
          </AlertWrapper>
        ) }
      </AlertsWrapper>
      <>
        { tags.filter((t) => t !== CustomerTag.MaNonCompliant).map(chipProperties).map(({
          color, label, avatar, icon, variant,
        }) => (
          <TagWrapper key={label}>
            <Chip
              label={label}
              color={color}
              avatar={avatar}
              variant={variant}
              icon={icon}
              size="small"
              style={{ paddingLeft: '4px' }}
            />
          </TagWrapper>
        )) }
      </>
    </CustomerTagsContainer>
  );
};

interface ChipProps {
  label: string;
  color?: 'primary' | 'secondary';
  variant?: 'default' | 'outlined';
  avatar?: JSX.Element;
  icon?: JSX.Element;
}

function chipProperties(tag: CustomerTag): ChipProps {
  switch (tag) {
    case CustomerTag.MaCompliant:
      return {
        color: 'secondary',
        label: 'MA Compliant',
        icon: <Icon type="done" size="small" />,
      };
    default:
      return {
        label: (tag as string).replace(/_/g, ' '),
      };
  }
}
