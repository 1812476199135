import * as React from 'react';
import { DialerTransferButtonsProps } from './DialerTransferButtons.interfaces';
import {
  DialerTransferButtonsContainer,
  LoadingDialerContainer,
  DialerTransferButtonsWrapper,
  DialerTransferButtonsTextWrapper,
} from './DialerTransferButtons.elements';
import { Alert } from '@material-ui/lab';
import { PrimaryButton, PrimaryButtonProps } from '../inputs';
import { Spinner } from '@healthiqeng/core.ui';
import { useDialerTransferButtons } from './useDialerTransferButtons';
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';

export const DialerTransferButtons: React.FunctionComponent<DialerTransferButtonsProps> = ({ disabled: isInactive }) => {
  const {
    transfer,
    transferInProgress,
    handleTransferSuccess,
    cancelTransfer,
    disabled,
    loading,
    currentCallInProgress,
    error,
    afterTransferDispositions,
    handleDisposition,
    initiatedConference,
    initializedLead,
    endingCall,
  } = useDialerTransferButtons();

  if (loading) {
    return (
      <DialerTransferButtonsContainer>
        <LoadingDialerContainer>
          <Spinner />
        </LoadingDialerContainer>
        Loading Dialer
      </DialerTransferButtonsContainer>
    );
  }

  if (!initializedLead) {
    return (
      <DialerTransferButtonsContainer>
        <LoadingDialerContainer>
          <Spinner />
        </LoadingDialerContainer>
        Loading Dialer
      </DialerTransferButtonsContainer>
    );
  }

  return (
    <DialerTransferButtonsContainer>
      {(error) && (
        <Alert variant="filled" severity="error">
          Dialer Error. Please use Five9 for disposition
        </Alert>
      )}
      { !transferInProgress && (
        <DialerTransferButtonsWrapper>
          <TransferButton onClick={transfer} disabled={disabled || isInactive} variant="contained" size="medium">
            <PhoneForwardedIcon />
&nbsp;&nbsp;Transfer to Agent
          </TransferButton>
          { initiatedConference && (
            (afterTransferDispositions ?? []).map((disposition) => (
              <TransferButton
                key={disposition.value}
                disabled={disabled || endingCall || isInactive}
                variant="outlined"
                onClick={() => handleDisposition(disposition.value)}
              >
                { disposition.label }
              </TransferButton>
            ))
          )}
          {
            isInactive && (
              <DialerTransferButtonsTextWrapper>
                Transfer will be available after script is complete
              </DialerTransferButtonsTextWrapper>
            )
          }
        </DialerTransferButtonsWrapper>
      )}
      { transferInProgress && (
        <DialerTransferButtonsWrapper>
          <TransferButton onClick={handleTransferSuccess} disabled={disabled}>Transfer Success</TransferButton>
          <TransferButton onClick={cancelTransfer} disabled={disabled} variant="outlined">Cancel Transfer</TransferButton>
            { (afterTransferDispositions ?? []).map((disposition) => (
              <TransferButton
                key={disposition.value}
                disabled={disabled}
                variant="outlined"
                onClick={() => handleDisposition(disposition.value)}
              >
                { disposition.label }
              </TransferButton>
            ))}
        </DialerTransferButtonsWrapper>
      )}
      {(!currentCallInProgress) && (
        <Alert variant="outlined" severity="info">
          No Current Call
        </Alert>
      )}
    </DialerTransferButtonsContainer>
  );
};

const TransferButton: React.FC<PrimaryButtonProps> = ({
  children, disabled, onClick, variant = 'contained',
}) => (
  <PrimaryButton onClick={onClick} disabled={disabled} variant={variant} size="medium">
    {children}
  </PrimaryButton>
);
