import * as React from 'react';
import Tab from '@material-ui/core/Tab';
import { MainPanelTabProps } from './MainPanelTab.interfaces';
import { Icon } from '../../../common/Icon';
import { IconWrapper, MainPanelTabContainer, TabWrapper } from './MainPanelTab.elements';
import { AnchorTabs } from './AnchorTabs';
import { isEmpty } from '@healthiqeng/core.util.is-empty';
import { getEventBus } from '../../../../events/getEventBus';
import { PanelChangeEvent } from '../../../../events';

export const MainPanelTab: React.FunctionComponent<MainPanelTabProps> = ({
  onClick,
  active,
  id,
  icon,
  anchors,
  label,
}) => {
  const eventBus = getEventBus();
  const handleTabClick = () => {
    onClick(id);
  };

  const handleAnchorClick = (value: string) => {
    handleTabClick();
    window.location.hash = `#${value}`;
    eventBus.emit(new PanelChangeEvent());
  };

  const getActiveAnchorValue = () => {
    if (window.location.hash.charAt(0) === '#') {
      return window.location.hash.slice(1);
    }
    return window.location.hash;
  };

  return (
    <MainPanelTabContainer>
      <TabWrapper key={id} active={active}>
        <Tab
          key={id}
          icon={(
            <IconWrapper>
              <Icon
                type={icon}
                color={active ? undefined : 'disabled'}
              />
            </IconWrapper>
        )}
          label={label}
          fullWidth
          onClick={handleTabClick}
          value={id}
          data-qa={`main-page-layout-tab-${id}`}
        />
      </TabWrapper>
      { !isEmpty(anchors) && (
        <AnchorTabs
          isPanelActive={active}
          anchors={anchors}
          onAnchorClick={handleAnchorClick}
          activeAnchor={getActiveAnchorValue()}
        />
      ) }
    </MainPanelTabContainer>
  );
};
