export function normalizeMonth(value: string, isDone: boolean): string {
  if (!value) {
    return '';
  }
  if (value.length === 1 && value !== '0' && (Number.parseInt(value, 10) > 1 || isDone)) {
    return `0${value}/`;
  }
  if (value.length >= 2) {
    return `${value.substring(0, 2)}/`;
  }
  return value;
}

export function normalizeDay(value: string, isDone: boolean): string {
  if (!value) {
    return '';
  }
  if (value.length === 1 && value !== '0' && (Number.parseInt(value, 10) > 3 || isDone)) {
    return `0${value}/`;
  }
  if (value.length >= 2) {
    return `${value.substring(0, 2)}/`;
  }
  return value;
}

export function normalizeYear(value: string): string {
  if (!value) {
    return '';
  }
  if ((value.length === 2) && !['19', '20'].includes(value)) {
    if (Number.parseInt(value) < 25) {
      return `20${value}`;
    }
    return `19${value}`;
  }
  return value.substring(0, 4);
}
