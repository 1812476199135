import { gql } from 'apollo-boost';
import { useCallback } from 'react';
import { LEAD_FRAGMENT } from '../../fragments';
import { CrossSaleMutationResponse, CrossSaleMutationVariables } from './useCrossSaleMutation.interfaces';
import { eventBus } from '../../../services';
import { CrossSaleCreateBeginEvent, CrossSaleCreateFailedEvent, CrossSaleCreateSuccessEvent } from './events';
import { MutationCustomReturn, useMutationCustom } from '../useMutationCustom';

const CROSS_SALE_MUTATION = gql`
  mutation CreateCrossSaleLead($customerId: ID!, $options: CrossSaleInput!) {
    createCrossSaleLead(customerId: $customerId, options: $options) {
      code
      success
      message
      timestamp
      type
      lead {
        ...LeadFragment
      }
      customerId
      customerType
    }
  }
  
  ${LEAD_FRAGMENT}
`;

export function useCrossSaleMutation(): MutationCustomReturn<CrossSaleMutationResponse, CrossSaleMutationVariables> {
  const [crossSale, result] = useMutationCustom<CrossSaleMutationResponse, CrossSaleMutationVariables>(CROSS_SALE_MUTATION);
  const crossSaleWrapped = useCallback(async (variables: CrossSaleMutationVariables) => {
    const response = await crossSale(variables);
    eventBus.emit(new CrossSaleCreateBeginEvent());
    if (response?.data?.createCrossSaleLead?.success) {
      eventBus.emit(new CrossSaleCreateSuccessEvent(
        response?.data?.createCrossSaleLead?.lead?.id,
        response?.data?.createCrossSaleLead?.customerId,
        response?.data?.createCrossSaleLead?.customerType,
      ));
    } else {
      eventBus.emit(new CrossSaleCreateFailedEvent());
    }
    return response;
  }, [crossSale]);
  return [crossSaleWrapped, result];
}
