import * as React from 'react';
import { isPresent } from '@healthiqeng/core.util.is-present';
import Checkbox from '@material-ui/core/Checkbox';
import { ActivityLayoutProps } from './ActivityLayout.interfaces';
import {
  ActivityLayoutContainer,
  ActivityAvatar,
  ActivityAvatarContainer,
  ActivityNoteContainer,
  ActivityAvatarText,
  ActivityNoteHeader,
  ActivityNoteBody,
  ActivityNoteHeaderDetail,
  ActivityNoteSubHeader,
  ActivityNoteCheckboxContainer,
  ActivityNoteCheckbox,
  ActivityNoteCheckboxLabel,
} from './ActivityLayout.elements';
import { Icon } from '../../../../../common/Icon';

export const ActivityLayout: React.FunctionComponent<ActivityLayoutProps> = ({
  header,
  headerDetail,
  avatar,
  body,
  subHeader,
  checked,
  completedAt,
  onCheckChanged,
  isLast,
  avatarColor,
}) => (
  <ActivityLayoutContainer>
    <ActivityAvatarContainer>
      <ActivityAvatar avatarColor={avatarColor}>
        <ActivityAvatarText>
          { avatar ?? (
            <Icon type="note" size="small" />
          ) }
        </ActivityAvatarText>
      </ActivityAvatar>
    </ActivityAvatarContainer>
    <ActivityNoteContainer isLast={isLast}>
      <ActivityNoteHeader>
        { header }
        { headerDetail && (
          <ActivityNoteHeaderDetail>
            { headerDetail }
          </ActivityNoteHeaderDetail>
        ) }
      </ActivityNoteHeader>
      { subHeader && (
        <ActivityNoteSubHeader>
          { subHeader }
        </ActivityNoteSubHeader>
      ) }
      <ActivityNoteBody>
        { body }
      </ActivityNoteBody>
      { isPresent(checked) && (
        <ActivityNoteCheckboxContainer>
          <ActivityNoteCheckbox>
            <Checkbox
              color="primary"
              size="small"
              checked={checked}
              onChange={(event) => onCheckChanged && onCheckChanged(event.target.checked)}
            />
          </ActivityNoteCheckbox>
          <ActivityNoteCheckboxLabel>
            { completedAt ?? (checked ? ('Completed') : ('Completed?'))}
          </ActivityNoteCheckboxLabel>
        </ActivityNoteCheckboxContainer>
      ) }
    </ActivityNoteContainer>
  </ActivityLayoutContainer>
);
