import React from 'react';
import { Card, PrimaryButton, ScriptBlock } from '../../../../../../../common';
import { noop } from '../../../../../../../../util';
import { Link } from './SpecialRatePrompt.elements';

export const SpecialRatePrompt = () => {
  const quizLink = 'https://docs.google.com/forms/d/e/1FAIpQLSf8txyfxTNZO5oIFM2MehJsZDQrphK7OOX1cRxB_Y2W3QFl9g/viewform';
  return (
    <Card margin="20px 0" padding="20px">
      <ScriptBlock>
        Great News! I see that you qualify for several Top A rate carriers. Here at Health IQ we also have a great way
        that you can qualify for even lower premiums by taking our Health Conscious quiz. Let’s take it and apply the
        answers to get you the best rates.
      </ScriptBlock>
      <div>
        <PrimaryButton onClick={noop}>
          <Link href={quizLink} target="_blank" rel="noopener noreferrer">
            Special Rate 5 Question Quiz
          </Link>
        </PrimaryButton>
      </div>
    </Card>
  );
};
