import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const SpinnerSvg = styled('svg')(css`
  path {
    animation: rotate 1s infinite linear;
    transform-origin: 50% 50%;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`);
