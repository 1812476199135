import * as React from 'react';
import { RebuttalsProps } from './Rebuttals.interfaces';
import { REBUTTALS_LIST } from './rebuttalsList';
import { RebuttalAccordion } from './RebuttalAccordion';
import { SidePanelContentLayout } from '../../layouts';

export const Rebuttals: React.FunctionComponent<RebuttalsProps> = () => (
  <SidePanelContentLayout header="Objections/Rebuttals">
    {REBUTTALS_LIST.map((rebuttal) => (
      <RebuttalAccordion
        key={rebuttal.id}
        id={rebuttal.id}
        title={rebuttal.title}
        text={rebuttal.text}
      />
    ))}
  </SidePanelContentLayout>
);
