import {
  Box, Button, Dialog, DialogContent, DialogTitle,
} from '@material-ui/core';
import React from 'react';
import { UIToolsContext } from './UIToolsContext';
import { DialogButton, DialogProps } from './UIToolsContext.interfaces';

export const UIToolsContextProvider: React.FunctionComponent = ({ children }) => {
  const [dialogProps, setDialogProps] = React.useState<DialogProps>();

  function createDialog(dialogProps: DialogProps) {
    setDialogProps(dialogProps);
  }

  function closeDialog() {
    setDialogProps(undefined);
  }

  return (
    <UIToolsContext.Provider value={{
      createDialog,
      closeDialog,
    }}
    >
      <>
        {children}
        <DialogContentWrapper {...dialogProps} open={!!dialogProps} onClick={() => closeDialog()} />
      </>
    </UIToolsContext.Provider>
  );
};

const DialogContentWrapper: React.FunctionComponent<DialogProps & {open: boolean, onClick: (label: string) => void}> = ({
  title, content, buttons: customButtons, open, onClick,
}) => {
  function handleButtonClick(button: DialogButton) {
    button?.onClick();
    onClick(button.label);
  }

  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent style={{ minWidth: 400 }}>
        {content}
        <Box display="flex" justifyContent="flex-end">
          {customButtons
            && customButtons.map((customButton) => (
              <Button {...customButton} onClick={() => handleButtonClick(customButton)} style={{ marginLeft: 10 }}>
                {customButton.label}
              </Button>
            ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
