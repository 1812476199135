import { useCallback, useState } from 'react';
import { Markdown } from '@hiq/crm.types';
import { labelToId, shortenLabel } from '../../../../../util';
import { Anchor } from '../../../../../types';
import { logger } from '../../../../../services';

const PARTIAL_REGEXP = /`partial:([a-zA-Z0-9_-]+)(:[a-zA-Z0-9_-]+)?`/g;

const splitIntoMarkdownSections = (markdown: string): string[] => markdown
  .split(/([#]+)/g)
  .filter(Boolean)
  .reduce((acc: string[], s: string) => {
    if (s[0] === '#') {
      if (s[1] !== '#') {
        acc.push(s);
        return acc;
      }
    }

    if (acc.length === 0) acc.push('');

    const lastIndex = acc.length - 1;
    const last = acc[lastIndex];
    acc.splice(lastIndex, 1, last + s);

    return acc;
  }, []);

const replaceMarkdown = (initial: Markdown, components: Record<string, Markdown>) => {
  let current = '';
  let replaced = initial;
  while (replaced !== current) {
    current = replaced;
    replaced = current.replace(PARTIAL_REGEXP, (found: string, name: string) => (components[name] ? `${components[name]}\n` : found));
  }

  return current;
};

export const useMarkdownSections = (initial: Markdown): [string[], Anchor[], (id: string, m: Markdown) => void] => {
  const [allMarkdown, setAllMarkdown] = useState({ initial });
  const addMarkdown = useCallback((id: string, markdown: Markdown) => {
    setAllMarkdown((current) => ({ ...current, [id]: markdown }));
  }, [setAllMarkdown]);

  const total = replaceMarkdown(initial, allMarkdown);
  const sections = splitIntoMarkdownSections(total);
  const anchors = extractAnchors(total);

  if (sections.length !== anchors.length) {
    logger.warn(`sections length ${sections.length} !== anchors length ${anchors.length}`);
  }

  return [sections, anchors, addMarkdown];
};

export function extractAnchors(markdown?: Markdown): Anchor[] {
  if (!markdown) {
    return [];
  }
  const matchRegex = /^#{1}\s?(?!#)(.*)$/;
  return markdown.split('\n')
    .filter((line) => !!line)
    .filter((line) => matchRegex.test(line))
    .map((line) => line.match(matchRegex)[1])
    .map((label) => ({
      label: shortenLabel(label),
      value: labelToId(label),
    }));
}
