import * as React from 'react';
import {
  BulletsTextContainer,
  ListBullet,
  ListBulletDescription,
  ListBulletHeader,
  ListBulletNumber,
  ListContainer,
  ListContentFrame,
  ListHeader,
  ListHeaderContainer,
} from './NumberedListWithHeader.elements';
import { INumberedListWithHeaderProps } from './NumberedListWithHeader.interfaces';

export class NumberedListWithHeader extends React.Component<INumberedListWithHeaderProps> {
  public render() {
    return (
      <ListContainer>
        <ListContentFrame>
          <ListHeaderContainer>
            <ListHeader>
              {this.props.header}
            </ListHeader>
          </ListHeaderContainer>
          {this.renderListIcon()}
        </ListContentFrame>
      </ListContainer>
    );
  }

  private renderListIcon() {
    return this.props.list.map((listItemProps) => (
      <ListBullet key={listItemProps.title}>
        <ListBulletNumber>
          {listItemProps.number}
          .
        </ListBulletNumber>
        <BulletsTextContainer>
          <ListBulletHeader>
            {listItemProps.title}
          </ListBulletHeader>
          <ListBulletDescription>
            {listItemProps.info}
          </ListBulletDescription>
        </BulletsTextContainer>
      </ListBullet>
    ));
  }
}
