import * as React from 'react';
import { MedicareAdvantageQuotePanelProps } from './MedicareAdvantageQuotePanel.interfaces';
import { useSelectedQuoteDetector } from '../../../../useSelectedQuoteDetector';
import { MedicareSelectedQuotes } from '../MedicareSelectedQuotes';
import { MedicareExternalQuote } from '../MedicareExternalQuote';

export const MedicareAdvantageQuotePanel: React.FunctionComponent<MedicareAdvantageQuotePanelProps> = () => {
  const hasSelectedQuote = useSelectedQuoteDetector();
  if (hasSelectedQuote) {
    return <MedicareSelectedQuotes />;
  }
  return <MedicareExternalQuote />;
};
