import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const QuoteOptionsLayoutContainer = styled('div')(css`
  display: flex;
  flex-wrap: wrap;
`);

type QuoteOptionsLayoutColumnProps = {
  minWidth: number;
  gutter: number;
  isLast: boolean;
};
export const QuoteOptionsLayoutColumn = styled('div')<QuoteOptionsLayoutColumnProps>(({
  minWidth,
  gutter,
  isLast,
}) => css`
  flex: 1;
  min-width: ${minWidth}px;
  padding-right: ${isLast ? '0' : `${gutter}px`};
`);
