import { gql } from 'apollo-boost';
import {
  UpdateLeadMutationResponse, UpdateLeadMutationVariables,
} from './useLeadUpdateMutation.interfaces';
import { MutationCustomReturn, useMutationCustom } from '../useMutationCustom';
import { MutationHookOptions } from '@apollo/react-hooks';

const UPDATE_LEAD_MUTATION = gql`
  mutation updateLead($input: UpdateLeadInput!) {
    updateLead(input: $input) {
      code
      success
      message
      timestamp
      type
      leadId
    }
  }
`;

export function useUpdateLeadMutation(options: MutationHookOptions<UpdateLeadMutationResponse,
UpdateLeadMutationVariables>): MutationCustomReturn<UpdateLeadMutationResponse, UpdateLeadMutationVariables> {
  const result = useMutationCustom<UpdateLeadMutationResponse, UpdateLeadMutationVariables>(UPDATE_LEAD_MUTATION,
    options);
  return result;
}
