import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const QuoteOptionContainer = styled('div')(css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  max-width: 350px;
  margin: 10px 0;
`);

export const QuoteOptionLabel = styled('p')(css`
  margin: 0;
  min-width: 150px;
  flex: 1;
`);
export const QuoteOptionInputContainer = styled('div')(css`
  min-width: 200px;
  flex: 1;
`);
