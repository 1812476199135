import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const QuoteDetailsContainer = styled('div')(css``);

export const QuoteDetailsItem = styled('div')(css`
  margin: 3px 0;
`);

export const QuoteDetailsLabel = styled('span')(css`
  font-weight: 600;
  margin-right: 4px;
`);
