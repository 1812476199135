import React from 'react';
import { CompareContext } from '../../CompareContext/CompareContext';
import {
  Checkbox, FormControl, OutlinedInput, ListItemText, MenuItem, Select,
} from '@material-ui/core';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ratingOptions = [{ value: 5, label: 'Five-star only filter' }];

export const RatingFilter = () => {
  const { updateRatingFilter, ratingFilter } = React.useContext(CompareContext);

  return (
    <FormControl>
      <span>Rating</span>
      <Select
        multiple
        style={{ width: '300px' }}
        id="rating-select"
        value={ratingFilter}
        variant="outlined"
        label="Rating"
        fullWidth
        onChange={(event) => updateRatingFilter(event.target.value as Array<number>)}
        input={<OutlinedInput label="Rating" margin="dense" placeholder="Rating" />}
        renderValue={(selected: Array<string>) => selected.join(', ')}
        MenuProps={MenuProps}
      >
        {ratingOptions?.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            <Checkbox checked={ratingFilter.indexOf(value) > -1} />
            <ListItemText primary={label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
