import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const UserLeadsViewContainer = styled('div')(css`
  height: 100%;
  overflow: scroll;
`);
export const UserLeadsViewHeader = styled('h2')(css`
  padding: 10px 16px;
  margin: 0;
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
`);

export const Anchor = styled('a')(css`
  text-decoration: none;
  color: #000;
`);
