import * as React from 'react';
import { MustReadBlockProps } from './MustReadBlock.interfaces';
import { useFormAnswers } from '../../../../hooks';
import { useActiveLeadId } from '../../../routers/LeadRouter/useActiveLeadId';
import { getHashedKey } from './getHashedKey';
import { useMemo } from 'react';
import { MustReadBlockView } from './MustReadBlockView';

export const MustReadBlock: React.FunctionComponent<MustReadBlockProps> = ({
  text,
  children,
  confirmationRequired,
  confirmText,
  answerKey,
}) => {
  const leadId = useActiveLeadId();
  const [answers, saveAnswers] = useFormAnswers(leadId);
  const key = useMemo(() => answerKey ?? getHashedKey(text), [answerKey, text]);
  const isAcknowledged = answers[key] as boolean;

  const handleChange = () => {
    saveAnswers(key, isAcknowledged ? null : true);
  };

  return (
    <MustReadBlockView
      isAcknowledged={isAcknowledged ?? false}
      onChange={handleChange}
      confirmText={confirmText}
      hideIcon
      confirmationRequired={confirmationRequired}
    >
      { children }
    </MustReadBlockView>
  );
};
