import React from 'react';
import {
  HealthGridTopBarActionComponentContainer,
  HealthGridTopBarActionContainer,
  HealthGridTopBarActionLabel,
  HealthGridTopBarContainer,
  HealthGridTopBarSubtitle,
  HealthGridTopBarTitle,
} from './HealthRecordTopBar.elements';
import { SelectInput } from '../../../../inputs';
import { HealthRecordTopBarProps } from './HealthRecordTopBar.interfaces';

export const HealthRecordTopBar = ({
  title, subtitle, sortType, onSortChange, sortOptions, hideSorting,
}: HealthRecordTopBarProps) => {
  const titleComponent = title ? <HealthGridTopBarTitle>{title}</HealthGridTopBarTitle> : null;
  const subtitleComponent = subtitle ? <HealthGridTopBarSubtitle>{subtitle}</HealthGridTopBarSubtitle> : null;
  const sortComponent = !hideSorting && onSortChange && sortOptions?.length
    ? (
      <HealthGridTopBarActionContainer>
        <HealthGridTopBarActionLabel>Sort by</HealthGridTopBarActionLabel>
        <HealthGridTopBarActionComponentContainer>
          <SelectInput
            value={sortType}
            onChange={(value) => onSortChange(value as string)}
            options={sortOptions}
            size="small"
            fullWidth
            showEmptyOption={false}
            disableClearable
          />
        </HealthGridTopBarActionComponentContainer>
      </HealthGridTopBarActionContainer>
    ) : null;
  return (
    <HealthGridTopBarContainer>
      <div>
        {titleComponent}
        {subtitleComponent}
      </div>
      <div>
        {sortComponent}
      </div>
    </HealthGridTopBarContainer>
  );
};
