import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../../../themes';

export const QuestionContainer = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${theme.color.grayLight};
  flex-wrap: wrap;
  padding: 3px 0;
`);

export const QuestionLabel = styled('div')(css`
  flex: 1;
  min-width: 50px;
  text-align: left;
  margin-right: 5px;
  padding-top: 5px;
  font-weight: bold;
`);

export const QuestionValue = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  min-width: 250px;
  flex: 2;
    
  &:hover {
    background-color: ${theme.color.grayLight};
    border-radius: 5px;
    cursor: not-allowed
  }
`);
