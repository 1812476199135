import { QuestionType } from '@hiq/crm.types';
import * as React from 'react';
import { QuestionView } from '../FormGenerator/FormGeneratorQuestionFlow/Question/QuestionView';
import moment from 'moment';

const RandomDateFollowupFC: React.FunctionComponent = () => {
  const lastAepDate = moment('2022-12-07');

  const possibleDates = [];

  for (let i = 1; i <= 7; i++) {
    const date = moment().add(i, 'days');
    if (date.isBefore(lastAepDate) && date.weekday() !== 0) {
      possibleDates.push(date);
    }
  }

  let secondIndex;
  const firstIndex = Math.floor(Math.random() * possibleDates.length);

  if (possibleDates.length < 2) throw new Error('Not enough possible dates');

  while (secondIndex === undefined || secondIndex === firstIndex) {
    secondIndex = Math.floor(Math.random() * possibleDates.length);
  }

  const date1 = possibleDates[firstIndex];
  const date2 = possibleDates[secondIndex];

  const firstDate = moment.min(date1, date2).format('MM/DD/YYYY');
  const secondDate = moment.max(date1, date2).format('MM/DD/YYYY');

  const randomDateFollowupScript = `\`\`\`script \n\n Ok, no problem we have ${firstDate} and ${secondDate}`
    + ' available, which one works best for you and would you prefer morning or afternoon? \n ```';

  return (
    <QuestionView
      id=""
      label="Random Date Followup"
      questionText={randomDateFollowupScript}
      type={QuestionType.Script}
      onChange={() => null}
      value=""
    />
  );
};

export const RandomDateFollowup = React.memo(RandomDateFollowupFC);
