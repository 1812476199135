import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { AvertaPeNormal400 } from '@healthiqeng/core.fonts';
import { animated } from 'react-spring/renderprops.cjs';
import { resetButton, resetDiv } from '../resets';

export const SidebarContainer = styled('div')(resetDiv, css`
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`);

export const SidebarOverlay = styled(animated.div)(resetDiv, css`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
`);

export const SidebarBody = styled(animated.div)(resetDiv, css`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  background: white;
  height: 100%;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }
`);

export const SidebarCloseButtonContainer = styled('div')(resetDiv, css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 26px;
`);

export const SidebarCloseButton = styled('button')(resetButton, css`
  ${AvertaPeNormal400};
  position: relative;
  padding-right: 24px;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  text-transform: uppercase;
  color: #1a1c1f;
  background-color: transparent;

  &:hover {
    opacity: 0.7;
  }

  &:focus {
    outline: none;
  }

  &::before,
  &::after {
    ${resetDiv};
    content: ' ';
    position: absolute;
    display: block;
    width: 16px;
    height: 2px;
    right: 0;
    top: 8px;
    background-color: #1a1c1f;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`);

export const SidebarContentContainer = styled('div')(resetDiv);
