import { MutationHookOptions } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { useMutationCustom } from '../useMutationCustom';
import {
  AddLeadToDoNotCallMutationResponse,
  AddLeadToDoNotCallMutationVariables,
} from './useAddLeadToDoNotCallMutation.interfaces';

const ADD_LEAD_TO_DNC_MUTATION = gql`
  mutation AddLeadToDoNotCall($input: AddLeadToDncInput!) {
    addLeadToDnc(input: $input) {
      success
      message
    }
  }
`;

export function useAddLeadToDoNotCall(
  options: MutationHookOptions<AddLeadToDoNotCallMutationResponse, AddLeadToDoNotCallMutationVariables> = {},
) {
  return useMutationCustom<AddLeadToDoNotCallMutationResponse, AddLeadToDoNotCallMutationVariables>(
    ADD_LEAD_TO_DNC_MUTATION,
    options,
  );
}
