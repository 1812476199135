import { gql } from 'apollo-boost';
import { QUOTE_FRAGMENT } from '../../fragments/quote';
import { MutationCustomReturn, useMutationCustom } from '../useMutationCustom';
import { PropertyAndCasualtyQuoteMutationResponse, PropertyAndCasualtyQuoteMutationVariables } from './usePropertyAndCasualtyQuoteMutation.interfaces';
import { useCallback } from 'react';
import { eventBus } from '../../../services';
import { QuoteSelectedEvent } from '../useSelectQuoteMutation/events';

const PNC_QUOTE_MUTATION = gql`
  mutation AddExternalPropertyAndCasualtyQuote($leadId: ID!, $properties: PropertyAndCasualtyQuoteInput!) {
    addExternalPropertyAndCasualtyQuote(leadId: $leadId, properties: $properties) {
      code,
      success,
      message,
      timestamp,
      quote {
        ...QuoteFragment
      }
    }
  }
  ${QUOTE_FRAGMENT}
`;

export function usePropertyAndCasualtyQuoteMutation():
MutationCustomReturn<
PropertyAndCasualtyQuoteMutationResponse,
PropertyAndCasualtyQuoteMutationVariables
> {
  const [addQuote, result] = useMutationCustom<
  PropertyAndCasualtyQuoteMutationResponse,
  PropertyAndCasualtyQuoteMutationVariables
  >(PNC_QUOTE_MUTATION);
  const addQuoteWrapped = useCallback(async (variables: PropertyAndCasualtyQuoteMutationVariables) => {
    const response = await addQuote(variables);
    if (response?.data?.addExternalPropertyAndCasualtyQuote?.success) {
      eventBus.emit(new QuoteSelectedEvent());
    }
    return response;
  }, [addQuote]);

  return [addQuoteWrapped, result];
}
