import { NumberTransformer } from '../NumberTransformer';

export class CurrencyTransformer extends NumberTransformer {
  protected updateDraftValue(value: string) {
    super.updateDraftValue(value);
    if (this.draftValue?.includes('.') && this.draftValue.split('.')?.[1]?.length > 2) {
      const [integer, decimal] = this.draftValue.split('.');
      this.draftValue = [integer, decimal.substring(0, 2)].join('.');
    }
  }
}
