import * as React from 'react';
import { parseReference, MarkdownReferenceType } from '@hiq/crm.core.util.parse-markdown-references';
import { CustomViewRendererProps } from './CustomViewRenderer.interfaces';

export const customViewRendererFactory = (CustomElement: React.FunctionComponent<any>) => React.useMemo(() => {
  const CustomViewRenderer: React.FunctionComponent<CustomViewRendererProps> = ({
    value,
  }) => {
    const { type, referenceId, subType } = parseReference(value);
    const defaultRender = <div>{ `${type}:${referenceId} not implemented` }</div>;

    if (type !== MarkdownReferenceType.Custom) {
      return defaultRender;
    }

    return <CustomElement referenceId={referenceId} subType={subType} />;
  };
  return CustomViewRenderer;
}, [CustomElement]);
