import { gql } from 'apollo-boost';

export const PRECISION_MEDICARE_PRESCRIPTION_FRAGMENT = gql`
  fragment PrecisionMedicarePrescriptionFragment on PrecisionMedicarePrescription {
    id
    leadId
    ndc
    sunfireId
    name
    daysOfSupply
    fillQuantity
  }
`;

export const PRECISION_MEDICARE_DOCTOR_FRAGMENT = gql`
  fragment PrecisionMedicareDoctorFragment on PrecisionMedicareDoctor {
    id
    leadId
    npi
    name
    practice
    visitsLastYear
    visitsLast3Years
    visitsLast5Years
    lastVisit
    addressLine1
    addressLine2
    city
    state
    zip
  }
`;

export const PRECISION_MEDICARE_PHARMACY_FRAGMENT = gql`
  fragment PrecisionMedicarePharmacyFragment on PrecisionMedicarePharmacy {
    id
    leadId
    npi
    name
    addressLine1
    addressLine2
    city
    state
    zip
  }
`;

export const PRECISION_MEDICARE_ICD_FRAGMENT = gql`
  fragment PrecisionMedicareICDFragment on PrecisionMedicareICD {
    id
    leadId
    diseaseKey
    claimCode
    claimCodeId
    clinicalName
    description
    diagnosisDate
  }
`;

export const PRECISION_MEDICARE_NON_ICD_FRAGMENT = gql`
  fragment PrecisionMedicareNonICDFragment on PrecisionMedicareNonICD {
    id
    leadId
    description
    claimCode
    claimCodeId
    diagnosisDate
  }
`;

export const PRECISION_MEDICARE_ANTICIPATED_NEEDS_FRAGMENT = gql`
    fragment PrecisionMedicareAnticipatedNeedsFragment on PrecisionMedicareAnticipatedNeed {
        code
        hiqDescription
        quantity
    }
`;
