import * as React from 'react';
import { IndustryType } from '@hiq/crm.types';
import { LeadProps } from './Lead.interfaces';
import { useLeadQuery } from '../../../graphql';
import { QueryLoader } from '../../common/QueryLoader';
import { LifeLead } from './LifeLead';
import { MedicareLead } from './MedicareLead';
import { PropertyAndCasualtyLead } from './PropertyAndCasualtyLead';
import { ComponentName } from '../../common/Page/PageLoadDetector';

export const Lead: React.FunctionComponent<LeadProps> = () => {
  const { data, error, loading } = useLeadQuery();

  return (
    <QueryLoader loading={loading} error={error} componentName={ComponentName.Lead}>
      <>
        { data?.industry?.type === IndustryType.Life && <LifeLead /> }
        { data?.industry?.type === IndustryType.Medicare && <MedicareLead /> }
        { data?.industry?.type === IndustryType.PropertyAndCasualty && <PropertyAndCasualtyLead />}
      </>
    </QueryLoader>
  );
};
