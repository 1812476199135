import React, { useEffect } from 'react';
import { isPresent } from '@healthiqeng/core.util.is-present';
import { QuestionType } from '@hiq/crm.types';
import { QuestionCell } from '../../FormGenerator/FormGeneratorCompact/Question/QuestionCell/QuestionCell';
import { dateTransformerFactory, identityTransformerFactory, numberTransformerFactory } from '../transformers';
import { FollowUpQuestionCellProps } from './FollowUpQuestionCell.interfaces';
import { InputFactoryProps } from '../InputFactory/InputFactory.interfaces';
import { RadioYesNoInput } from '../RadioYesNoInput';
import { TextInput } from '../TextInput';

type InputFactoryParams = Omit<FollowUpQuestionCellProps, 'questionText' | 'tags' | 'onChange'> & Pick<InputFactoryProps, 'onChange'>;

const FollowUpQuestionCellInputFactory = React.memo(({
  value, defaultValue, onChange, questionId, type,
} : InputFactoryParams) => {
  const dataQa = `question-${questionId}`;
  switch (type) {
    case QuestionType.Date:
      return (
        <TextInput
          placeholder="MM/DD/YYYY"
          value={(value ?? defaultValue) as number}
          onChange={onChange}
          transformerFactory={dateTransformerFactory}
          referenceId={questionId}
          answersKey={questionId}
          dataQa={dataQa}
        />
      );
    case QuestionType.Number:
    case QuestionType.Text:
    case QuestionType.TextArea: {
      const isTextArea = type === QuestionType.TextArea;
      const transformerFactory = type === QuestionType.Number
        ? numberTransformerFactory
        : identityTransformerFactory;
      return (
        <TextInput
          value={value ?? defaultValue}
          onChange={onChange}
          multiline={isTextArea}
          rows={isTextArea ? 4 : undefined}
          transformerFactory={transformerFactory}
          referenceId={questionId}
          answersKey={questionId}
          dataQa={dataQa}
        />
      );
    }
    case QuestionType.RadioYesNo:
      return (
        <RadioYesNoInput
          value={value}
          onChange={onChange}
          dataQa={dataQa}
        />
      );
    default:
      throw new Error(`HealthConditionQuestionCell input type not expected ${type}`);
  }
});

export const FollowUpQuestionCell: React.FunctionComponent<FollowUpQuestionCellProps> = ({
  questionId, questionText, value, tags, type, onChange, defaultValue,
}) => {
  const onInputChange = React.useCallback((val) => onChange(questionId, val), [questionId, onChange]);
  useEffect(() => {
    if (isPresent(defaultValue) && !isPresent(value)) {
      onInputChange(defaultValue);
    }
  }, []);

  return (
    <QuestionCell
      tags={tags}
      label={questionText}
      input={(
        <FollowUpQuestionCellInputFactory
          value={value}
          defaultValue={defaultValue}
          onChange={onInputChange}
          questionId={questionId}
          type={type}
        />
      )}
    />
  );
};
