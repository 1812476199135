import * as React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { FormItemTag } from '@hiq/crm.types';
import { gql } from 'apollo-boost';
import { ValidForTagQueryData, ValidForTagQueryVariables } from './useValidForTagQuery.interfaces';
import { LeadIdContext } from '../../../components/contexts/LeadIdContext';
import { useEventListener } from '../../../events/useEventListener';
import { SaveCompleteEvent } from '../../mutations/useFormAnswersMutation';
import { FORM_ANSWERS_VALIDATION_ITEM_FRAGMENT } from '../../fragments/formAnswersValidationItem';

const VALID_FOR_TAG_QUERY = gql`
  query isValidForTag($leadId: ID!, $tag: FormItemTag!) {
    validForTag(leadId: $leadId, tag: $tag) {
      success
      errors {
        ...FormAnswersValidationItemFragment
      }
      warnings {
        ...FormAnswersValidationItemFragment
      }
    }
  }
  ${FORM_ANSWERS_VALIDATION_ITEM_FRAGMENT}
`;

export function useValidForTagQuery(tag: FormItemTag, leadId?: string) {
  const result = useQuery<ValidForTagQueryData, ValidForTagQueryVariables>(VALID_FOR_TAG_QUERY, {
    variables: {
      leadId: getLeadId(leadId),
      tag,
    },
    fetchPolicy: 'cache-and-network',
  });
  useEventListener(SaveCompleteEvent, () => result.refetch());
  return result;
}

function getLeadId(leadId?: string) {
  return leadId ?? React.useContext(LeadIdContext);
}
