import * as React from 'react';
import { InfoBlockProps } from './InfoBlock.interfaces';
import {
  ScriptBlockContainer,
  ScriptBlockColorDiv,
  ScriptBlockContent,
  ScriptBlockIconContainer,
  ScriptBlockIcon,
  ScriptBlockText,
} from '../elements/ScriptBlock.elements';
import { ThemeLight } from '../../../../themes';
import { useState } from 'react';
import { ScriptBlockConfirm } from '../common/ScriptBlockConfirm';

export const InfoBlock: React.FunctionComponent<InfoBlockProps> = ({
  children,
  confirmationRequired,
  confirmText,
  checked,
  onChange,
}) => {
  const [isAcknowledged, setCheckbox] = useState(checked ?? false);
  const handleChange = () => {
    if (onChange) {
      onChange(!isAcknowledged);
    }
    setCheckbox(!isAcknowledged);
  };
  return (
    <ScriptBlockContainer>
      <ScriptBlockColorDiv color={ThemeLight.color.accent} />
      <ScriptBlockContent>
        <ScriptBlockIconContainer>
          <ScriptBlockIcon src={require('../assets/icn-more-info-circle.svg')} alt="info icon" />
        </ScriptBlockIconContainer>
        <ScriptBlockText>
          { children }
          { confirmationRequired && (
            <ScriptBlockConfirm
              isConfirmed={checked ?? isAcknowledged}
              onChange={handleChange}
              confirmText={confirmText}
            />
          )}
        </ScriptBlockText>
      </ScriptBlockContent>
    </ScriptBlockContainer>
  );
};
