import * as React from 'react';
import { FormGeneratorCompactProps } from './FormGeneratorCompact.interfaces';
import { useFormQuery } from '../../../../graphql';
import { useFormAnswers } from '../../../../hooks';
import { useFormStatus } from '../useFormStatus';
import { QueryLoader } from '../../QueryLoader';
import { Question } from './Question';
import { FormGeneratorCompactButtons } from './FormGeneratorCompactButtons';
import { useLockableForm } from './useLockableForm';
import { ComponentName } from '../../Page/PageLoadDetector';

export const FormGeneratorCompact: React.FunctionComponent<FormGeneratorCompactProps> = ({
  leadId,
  referenceId,
  lockable,
  onFieldsChange,
}) => {
  const { data: formStructure, error, loading } = useFormQuery(leadId, referenceId);
  const [answers, onChange, , clearState] = useFormAnswers(leadId);
  const [editing, toggleEditing, handleSave, handleClear] = useLockableForm(clearState);
  const createFormStatus = useFormStatus(formStructure);
  const onSave = () => {
    handleSave();
    if (onFieldsChange) onFieldsChange(referenceId, createFormStatus(answers));
  };

  return (
    <QueryLoader loading={loading} error={error} variant="skeleton-small" componentName={ComponentName.FormGeneratorCompact}>
      <div>
        { formStructure?.items.map((formItem) => (
          <Question
            leadId={leadId}
            key={formItem.questionId}
            answers={answers}
            onChange={onChange}
            id={formItem.questionId}
            tags={formItem.tags}
            editing={editing}
            onEdit={toggleEditing}
            lockable={lockable}
            visibleCondition={formItem.visibleCondition}
            locked={formItem.locked}
          />
        )) }
        { editing && (
          <FormGeneratorCompactButtons
            onSave={onSave}
            onClear={handleClear}
          />
        ) }
      </div>
    </QueryLoader>
  );
};
