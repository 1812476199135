import { useContext, useMemo } from 'react';
import { isPresent } from '@healthiqeng/core.util.is-present';
import { omit } from '@healthiqeng/core.util.omit';
import { flattenObject } from '@healthiqeng/core.util.flatten-object';
import { FormAnswers, TcpaConsentStatus } from '@hiq/crm.types';
import { UseFormAnswersQueryHook } from './useFormAnswersQuery.interfaces';
import { CustomerIdContext } from '../../../components/contexts/CustomerIdContext';
import {
  CustomerQueryData, useCustomerQuery, CustomerFragment, LeadFragment, useCurrentUserQuery, UserFragment, useLocalFormAnswersQuery,
} from '../../../graphql';
import { hoistQuestionFlowAnswers } from './hoistQuestionFlowAnswers';

export const useFormAnswersQuery: UseFormAnswersQueryHook = (leadId: string) => {
  const customerId = useContext(CustomerIdContext);
  const { data } = useCustomerQuery(customerId);
  const { data: currentUserData } = useCurrentUserQuery();
  const { data: localData } = useLocalFormAnswersQuery();
  return useMemo(
    formAnswersFactory(data, localData?.localFormAnswers, leadId, currentUserData?.currentUser),
    [data, localData?.localFormAnswers, leadId, currentUserData],
  );
};

function formAnswersFactory(data: CustomerQueryData, localFormAnswers: FormAnswers, leadId: string, currentUser?: UserFragment) {
  return () => {
    const customer: CustomerFragment = data?.customer;
    const lead: LeadFragment = customer?.leads?.find((customerLead) => customerLead.id === leadId);
    const flatObject = flattenObject(hoistQuestionFlowAnswers({
      lead,
      customer: {
        ...omit(customer ?? {}, 'leads'),
        tcpaConsent: getTcpaConsentStatus(customer),
      },
      user: currentUser,
    }));
    Object.keys(localFormAnswers).forEach((key) => {
      if (!isPresent(flatObject[key])) {
        flatObject[key] = localFormAnswers[key];
      }
    });
    return flatObject;
  };
}

function getTcpaConsentStatus(customer: CustomerFragment) {
  const status = customer?.tcpaConsent?.status;
  if (!status || status === TcpaConsentStatus.Unknown) return undefined;
  return [
    TcpaConsentStatus.ManualConsent,
    TcpaConsentStatus.Consented,
    TcpaConsentStatus.ThirdPartyConsent,
  ].includes(status);
}
