import { gql } from 'apollo-boost';

export const PAYMENT_METHOD_FRAGMENT = gql`
  fragment PaymentMethodFragment on PaymentMethod {
    type
    name
    address {
      street
      apt
      city
      state {
        code
      }
      zip
    }
    cardNumber
    expiryDate
    cvv
    bankDetails {
      accountType
      bankName
      accountNumber
      routingNumber
    }
  }
`;
