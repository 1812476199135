import React from 'react';

export const CurrentDate: React.FunctionComponent<{}> = () => {
  const today = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  }).format(new Date());

  return (
    <>
      {today}
    </>
  );
};
