/* eslint max-len: 0 */
import * as React from 'react';
import { red } from '@material-ui/core/colors';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AddIcon from '@material-ui/icons/Add';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import BallotIcon from '@material-ui/icons/Ballot';
import CallEndIcon from '@material-ui/icons/CallEnd';
import CallMadeIcon from '@material-ui/icons/CallMade';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import ClearIcon from '@material-ui/icons/Clear';
import ChatIcon from '@material-ui/icons/Chat';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import EmailIcon from '@material-ui/icons/Email';
import EventNoteIcon from '@material-ui/icons/EventNote';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import InfoIcon from '@material-ui/icons/Info';
import LinkIcon from '@material-ui/icons/Link';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import NoteIcon from '@material-ui/icons/Note';
import PaymentIcon from '@material-ui/icons/Payment';
import PersonIcon from '@material-ui/icons/Person';
import SmsIcon from '@material-ui/icons/Sms';
import StarIcon from '@material-ui/icons/Star';
import StarOutlinedIcon from '@material-ui/icons/StarOutlined';
import SvgIcon from '@material-ui/core/SvgIcon';
import WarningIcon from '@material-ui/icons/Warning';
import TurnedIn from '@material-ui/icons/TurnedIn';
import TurnedInNot from '@material-ui/icons/TurnedInNot';
import LockIcon from '@material-ui/icons/Lock';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import { IconProps } from './Icon.interfaces';
import { ExpandMore, ExpandLess } from '@material-ui/icons';

export const Icon: React.FunctionComponent<IconProps> = ({
  color,
  size,
  type,
  style,
}) => {
  const commonProps = {
    fontSize: size,
    ...getColorProps(color),
    style,
  };
  switch (type) {
    case 'account-circle':
      return <AccountCircleIcon {...commonProps} />;
    case 'add':
      return <AddIcon {...commonProps} />;
    case 'assignment':
      return <AssignmentIcon {...commonProps} />;
    case 'assignment-late':
      return <AssignmentLateIcon {...commonProps} />;
    case 'assignment-ind':
      return <AssignmentIndIcon {...commonProps} />;
    case 'assignment-turned-in':
      return <AssignmentTurnedInIcon {...commonProps} />;
    case 'ballot':
      return <BallotIcon {...commonProps} />;
    case 'call-made':
      return <CallMadeIcon {...commonProps} />;
    case 'call-received':
      return <CallReceivedIcon {...commonProps} />;
    case 'call-end':
      return <CallEndIcon {...commonProps} />;
    case 'clear':
      return <ClearIcon {...commonProps} />;
    case 'clear-outlined':
      return <HighlightOffOutlinedIcon {...commonProps} />;
    case 'contact-mail':
      return <ContactMailIcon {...commonProps} />;
    case 'chat':
      return <ChatIcon {...commonProps} />;
    case 'dollar':
      return <MonetizationOnIcon {...commonProps} />;
    case 'delete':
      return <DeleteIcon {...commonProps} />;
    case 'done':
      return <DoneIcon {...commonProps} />;
    case 'done-outlined':
      return <CheckCircleOutlinedIcon {...commonProps} />;
    case 'email':
      return <EmailIcon {...commonProps} />;
    case 'favorite':
      return <FavoriteIcon {...commonProps} />;
    case 'copy':
      return <FileCopyIcon {...commonProps} />;
    case 'event-note':
      return <EventNoteIcon {...commonProps} />;
    case 'hiq-torch':
      return (
        <SvgIcon {...commonProps} width="19" height="29" viewBox="0 0 19 29" fill="none">
          <path fillRule="evenodd" clipRule="evenodd" d="M15.2331 20.5999H9.4502C10.3401 22.6233 10.8345 24.8603 10.8345 27.2129C10.8345 27.6514 10.8168 28.0858 10.7832 28.5159H13.9001C13.8665 28.0858 13.8489 27.6514 13.8489 27.2129C13.8489 24.8603 14.3432 22.6233 15.2331 20.5999Z" fill="#B1B7BA" />
          <path fillRule="evenodd" clipRule="evenodd" d="M8.55831 18.8638H16.1239C16.9042 17.5424 17.8635 16.3395 18.9693 15.289V15.259H5.71289V15.289C6.81874 16.3395 7.778 17.5424 8.55831 18.8638Z" fill="#B1B7BA" />
          <path fillRule="evenodd" clipRule="evenodd" d="M18.9674 13.5322C18.9674 13.5322 19.2884 8.30817 13.892 8.30817C11.4885 8.30817 9.91777 9.47015 6.96954 9.47015C4.28585 9.47015 1.58341 7.27248 1.9202 3.67261C1.9202 3.67261 0.242188 5.28588 0.242188 7.69598C0.242188 10.8694 2.81236 13.5158 6.01708 13.5265C12.8522 13.5491 18.9674 13.5322 18.9674 13.5322Z" fill="#5ECD82" />
          <path fillRule="evenodd" clipRule="evenodd" d="M18.9675 13.5321C18.9675 13.5321 19.3697 9.19795 14.8916 8.33942C12.3771 7.85743 9.72305 7.94635 7.98932 5.71895C6.39119 3.66579 6.95113 1.38337 7.38658 0.467041C6.26434 0.982798 4.09863 2.71056 4.09863 5.66685C4.09863 10.1993 8.66417 11.8676 10.1867 12.3674C14.0639 13.6401 18.9675 13.5321 18.9675 13.5321Z" fill="#4CA9ED" />
          <path fillRule="evenodd" clipRule="evenodd" d="M18.9669 13.5308C18.9686 13.5137 19.1066 11.9498 18.2466 10.5186C18.2465 10.5184 18.2464 10.5181 18.2464 10.518C18.2462 10.5176 18.2461 10.5173 18.2458 10.5169C18.2457 10.5167 18.2455 10.5165 18.2454 10.5162C17.763 9.67628 16.9611 8.89486 15.6353 8.52805C15.6188 8.52277 15.6037 8.51652 15.587 8.51138C15.5855 8.51096 15.586 8.51319 15.5849 8.51319C15.4631 8.48081 15.337 8.45191 15.2064 8.42676C15.2049 8.42635 15.2032 8.42607 15.2016 8.42579C15.0722 8.40106 14.9387 8.37966 14.8002 8.36243C14.7957 8.36188 14.791 8.36146 14.7865 8.36104C14.6498 8.34423 14.5089 8.33103 14.363 8.32228C14.3576 8.32186 14.3519 8.32172 14.3465 8.32144C14.1994 8.31297 14.0482 8.30811 13.8915 8.30811C11.488 8.30811 9.91728 9.46995 6.96905 9.46995C6.37826 9.46995 5.78664 9.36324 5.22656 9.15872C6.68046 11.1363 9.14476 12.0254 10.1861 12.3672C13.3097 13.3926 17.0976 13.5218 18.4528 13.5333C18.786 13.5325 18.9665 13.5321 18.9668 13.5321H18.9669V13.5308Z" fill="#12705C" />
        </SvgIcon>
      );
    case 'info':
      return <InfoIcon {...commonProps} />;
    case 'info-outlined':
      return <InfoOutlinedIcon {...commonProps} />;
    case 'link':
      return <LinkIcon {...commonProps} />;
    case 'local-offer':
      return <LocalOfferIcon {...commonProps} />;
    case 'note':
      return <NoteIcon {...commonProps} />;
    case 'payment':
      return <PaymentIcon {...commonProps} />;
    case 'person':
      return <PersonIcon {...commonProps} />;
    case 'pin':
      return (
        <SvgIcon {...commonProps}>
          <path d="M7 2h10v2l-2 1v5l3 3v3h-5v4l-1 3l-1-3v-4H6v-3l3-3V5L7 4z" fillRule="evenodd" />
        </SvgIcon>
      );
    case 'pin-outlined':
      return (
        <SvgIcon {...commonProps}>
          <path d="M7 2h10v2l-2 1v5l3 3v3h-5v4l-1 3l-1-3v-4H6v-3l3-3V5L7 4V2zm6 2h-2v6.828l-3 3V14h8v-.172l-3-3V4z" />
        </SvgIcon>
      );
    case 'quote':
      return <FormatQuoteIcon {...commonProps} />;
    case 'sms':
      return <SmsIcon {...commonProps} />;
    case 'sort':
      return <ImportExportIcon {...commonProps} />;
    case 'sort-asc':
      return <ArrowUpwardIcon {...commonProps} />;
    case 'sort-desc':
      return <ArrowDownwardIcon {...commonProps} />;
    case 'star':
      return <StarIcon {...commonProps} />;
    case 'star-outlined':
      return <StarOutlinedIcon {...commonProps} />;
    case 'warning':
      return <WarningIcon {...commonProps} />;
    case 'warning-outlined':
      return <WarningOutlinedIcon {...commonProps} />;
    case 'turned-in':
      return <TurnedIn {...commonProps} />;
    case 'turned-in-not':
      return <TurnedInNot {...commonProps} />;
    case 'lock':
      return <LockIcon {...commonProps} />;
    case 'voice-over':
      return <RecordVoiceOverIcon {...commonProps} />;
    case 'doctor':
      return (
        <SvgIcon {...commonProps} width="24" height="24" viewBox="0 0 24 24">
          <path d="M7.59668 7.31402L8.72728 4.60059L15.737 6.18342V9.57521L14.1541 12.6278L12.1191 13.1931L9.74482 12.6278L8.27504 10.8189L7.59668 7.31402Z" fill="white" />
          <path d="M7.4837 14.5488L6.12698 15.0011L4.77026 16.3578V21.3324H19.9203V18.619L17.7721 15.2272L16.0762 14.5488L11.5538 17.4884L7.4837 14.5488Z" fill="white" />
          <path fillRule="evenodd" clipRule="evenodd" d="M17.0789 6.72908C17.0789 8.60392 16.006 9.97067 15.6768 11.2364C14.7989 12.663 13.7033 13.7422 11.7515 13.7422C9.50499 13.7422 8.72273 12.9879 7.88557 11.2364C7.41344 10.2488 6.56877 5.4772 8.19336 4.16165C8.23615 4.14751 8.26265 4.03084 8.30187 3.85837C8.44993 3.20664 8.77904 1.75814 10.8532 2.03468C12.4531 2.24798 17.0789 3.07375 17.0789 6.72908ZM15.0172 7.91075C15.1084 8.16705 15.0272 8.81941 15.002 9.42516C14.9929 11.2126 13.5411 12.6587 11.7515 12.6587C9.99771 12.6587 8.56825 11.2697 8.50335 9.53172C8.54053 8.56002 8.08746 6.81286 9.04288 5.4772C11.6017 5.8718 14.1846 5.76583 15.0172 7.91075Z" />
          <path d="M5.79226 18.749C5.79226 18.1627 6.10279 17.6489 6.56837 17.363C6.55114 17.2979 6.53451 17.2299 6.51853 17.16C6.44393 16.8341 6.37621 16.4377 6.32653 16.0396C6.28054 15.6709 6.24863 15.2898 6.24245 14.953C3.95568 15.8506 2 17.415 2 19.0578V21.9996H21.503V19.0578C21.503 17.4847 19.7096 15.9834 17.5493 15.0706L17.5497 15.0855C17.559 15.4161 17.5405 15.795 17.5051 16.1602C17.4724 16.498 17.4242 16.8352 17.3664 17.1238H17.7108C17.916 17.1238 18.1035 17.2397 18.1953 17.4233L18.7371 18.5068C18.7747 18.582 18.7943 18.665 18.7943 18.749V19.8325C18.7943 20.1318 18.5517 20.3743 18.2525 20.3743H17.169V19.2908H17.7108V18.877L17.376 18.2073H15.8786L15.5438 18.877V19.2908H16.0855V20.3743H15.002C14.7028 20.3743 14.4603 20.1318 14.4603 19.8325V18.749C14.4603 18.665 14.4798 18.582 14.5175 18.5068L15.0592 17.4233C15.151 17.2397 15.3386 17.1238 15.5438 17.1238H16.2563C16.268 17.0773 16.28 17.0256 16.292 16.9691C16.3462 16.7136 16.3943 16.39 16.4267 16.0558C16.459 15.7212 16.4743 15.3891 16.4666 15.1159C16.4628 14.9791 16.4534 14.8654 16.4399 14.778C16.4281 14.7011 16.4162 14.6657 16.4137 14.6581C16.4137 14.6581 16.4137 14.6582 16.4137 14.6581C16.4132 14.6566 16.413 14.6562 16.4134 14.657C16.4135 14.6571 16.4134 14.6569 16.4134 14.657L16.4154 14.6564C16.1259 14.5666 15.8343 14.4881 15.5438 14.4219C15.2714 14.3598 14.9898 14.7348 14.8662 14.9696H12.6644C12.1822 14.9697 11.9368 14.9697 11.6956 14.9696H11.6872C11.4408 14.9696 11.1956 14.9695 10.7026 14.9696H8.50101C8.4859 14.9409 8.47046 14.9101 8.45448 14.8783C8.33984 14.6497 8.19834 14.3674 7.95926 14.4219C7.75074 14.4694 7.54173 14.5232 7.33332 14.583C7.32725 14.6633 7.32427 14.7589 7.32498 14.8688C7.32687 15.1683 7.35553 15.5353 7.40174 15.9055C7.44785 16.275 7.50977 16.6347 7.57467 16.9183C7.59352 17.0005 7.61205 17.0739 7.62982 17.1376C8.42712 17.2416 9.04276 17.9234 9.04276 18.749C9.04276 19.6467 8.31514 20.3743 7.41751 20.3743C6.51988 20.3743 5.79226 19.6467 5.79226 18.749Z" />
          <path d="M7.95923 18.7499C7.95923 19.061 7.70916 19.2996 7.41748 19.2996C7.1258 19.2996 6.87573 19.061 6.87573 18.7499C6.87573 18.4388 7.1258 18.2002 7.41748 18.2002C7.70916 18.2002 7.95923 18.4388 7.95923 18.7499Z" fill="#6F6F6F" />
        </SvgIcon>
      );
    case 'thumbs-down':
    case 'thumbs-down-disabled':
    case 'thumbs-down-enabled': {
      let rectFill; let
        fill;
      switch (type) {
        case 'thumbs-down-disabled':
          fill = '#B1B7BA';
          rectFill = '#F3F5F7';
          break;
        case 'thumbs-down-enabled':
          fill = 'white';
          rectFill = '#0075CA';
          break;
        default:
          fill = '#0075CA';
          rectFill = '#F3F5F7';
      }
      return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none">
          <rect width="24" height="24" rx="4" fill={rectFill} />
          <path
            d="M10.75 19.3333L10.1 18.8167C10.0334 18.7611 9.98335 18.6833 9.95002 18.5833C9.91669 18.4833 9.90002 18.3611 9.90002 18.2167V18.05L10.65 14.5333H5.66669C5.40002 14.5333 5.16669 14.4333 4.96669 14.2333C4.76669 14.0333 4.66669 13.8 4.66669 13.5333V12.1667C4.66669 12 4.67502 11.8611 4.69169 11.75C4.70835 11.6389 4.72224 11.5667 4.73335 11.5333L6.76669 6.85C6.86669 6.61667 7.03058 6.41667 7.25835 6.25C7.48613 6.08333 7.7278 6 7.98335 6H14.3834C14.65 6 14.8834 6.1 15.0834 6.3C15.2834 6.5 15.3834 6.73333 15.3834 7V14.1167C15.3834 14.2611 15.3611 14.3889 15.3167 14.5C15.2722 14.6111 15.1889 14.7278 15.0667 14.85L10.75 19.3333ZM16.3834 14.1167H19.3334V6H16.3834V14.1167Z"
            fill={fill}
          />
        </SvgIcon>
      );
    }
    case 'thumbs-up':
    case 'thumbs-up-disabled':
    case 'thumbs-up-enabled': {
      let rectFill; let
        fill;
      switch (type) {
        case 'thumbs-up-disabled':
          fill = '#B1B7BA';
          rectFill = '#F3F5F7';
          break;
        case 'thumbs-up-enabled':
          fill = 'white';
          rectFill = '#0075CA';
          break;
        default:
          fill = '#0075CA';
          rectFill = '#F3F5F7';
      }
      return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none">
          <rect width="24" height="24" rx="4" fill={rectFill} />
          <path
            d="M9.61669 18.0003C9.35002 18.0003 9.11669 17.9003 8.91669 17.7003C8.71669 17.5003 8.61669 17.267 8.61669 17.0003V9.88366C8.61669 9.73921 8.63891 9.61144 8.68335 9.50033C8.7278 9.38921 8.81113 9.27255 8.93335 9.15033L13.25 4.66699L13.9 5.18366C13.9667 5.23921 14.0167 5.31699 14.05 5.41699C14.0834 5.51699 14.1 5.63921 14.1 5.78366V5.95033L13.35 9.46699H18.3334C18.6 9.46699 18.8334 9.56699 19.0334 9.76699C19.2334 9.96699 19.3334 10.2003 19.3334 10.467V11.8337C19.3334 12.0003 19.325 12.1392 19.3084 12.2503C19.2917 12.3614 19.2778 12.4337 19.2667 12.467L17.2334 17.1503C17.1334 17.3837 16.9695 17.5837 16.7417 17.7503C16.5139 17.917 16.2722 18.0003 16.0167 18.0003H9.61669ZM4.66669 18.0003H7.61669V9.88366H4.66669V18.0003Z"
            fill={fill}
          />
        </SvgIcon>
      );
    }
    case 'expand':
      return <ExpandMore {...commonProps} />;
    case 'collapse':
      return <ExpandLess {...commonProps} />;
    case 'included':
      return (
        <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none">
          <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" fill="#05AB5B" stroke="#05AB5B" />
          <path d="M4 7.5L6.88285 10.3825L12.265 5" stroke="white" strokeWidth="2" />
        </SvgIcon>
      );
    case 'excluded':
      return (
        <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none">
          <rect width="16" height="16" rx="4" fill="#B1B7BA" />
          <path d="M5 11L11 5" stroke="white" strokeWidth="2" />
          <path d="M5 5L11 11" stroke="white" strokeWidth="2" />
        </SvgIcon>
      );
    case 'edit':
      return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.125 15L18 12.875L18.725 12.15C18.9083 11.9667 19.1417 11.875 19.425 11.875C19.7083 11.875 19.9417 11.9667 20.125 12.15L20.85 12.875C21.0333 13.0583 21.125 13.2917 21.125 13.575C21.125 13.8583 21.0333 14.0917 20.85 14.275L20.125 15ZM12 21V18.875L17.3 13.575L19.425 15.7L14.125 21H12ZM3 16V14H10V16H3ZM3 12V10H14V12H3ZM3 8V6H14V8H3Z" fill="black" />
        </SvgIcon>
      );
    default:
      return <></>;
  }
};

function getColorProps(color: IconProps['color']): { color?: IconProps['color']; style?: {color: any;}; } {
  switch (color) {
    case 'error':
      return { style: { color: red[500] } };
    default:
      return { color };
  }
}
