import styled from '@emotion/styled';
import { css } from '@emotion/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { Button } from '@material-ui/core';
import { SelectInput } from '../../../../common';

export const ESQFollowUpContainer = styled('div')(css`
    width: 400px;
    padding: 24px;
    height: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
`);

export const ESQFollowUpSelectInput = styled(SelectInput)(css`
    width: 100%;
    grid-template-columns: none !important;
    background-color: #red;
`);

export const ConfirmButton = withStyles({
  root: {
    background: '#0075CA',
    width: '116px',
    color: 'white',
    '&:hover': {
      background: 'rgba(0,117,202,0.8)',
    },
  },
})(Button);

export const CancelButton = withStyles({
  root: {
    background: 'white',
    width: '116px',
    borderColor: '#0075CA',
    color: '#0075CA',
    '&:hover': {
      background: 'rgba(255,242,255,0.7)',
    },
  },
})(Button);
