import * as React from 'react';
import { LeadInfoProps, LeadStage } from './LeadInfo.interfaces';
import { useLeadQuery } from '../../../../../graphql/queries/useLeadQuery';
import { QuestionCell, QuestionValueEditable, QuestionView } from '../../../../common/FormGenerator/FormGeneratorCompact';
import { QueryLoader } from '../../../../common/QueryLoader';
import { config } from '../../../../../config';
import { ContentContainer } from './LeadInfo.elements';
import {
  LeadInitialStatus,
  LeadPostSaleSubStage,
  QuestionOption,
  QuestionType,
  UserProfile,
  UserRole,
  ContactRestriction,
} from '@hiq/crm.types';
import {
  useUsersQuery,
  useCurrentUserQuery,
  useReleaseToFollowUpMutation,
  UsersQueryData,
  useSoldLeadInfoQuery,
  UserFragment,
} from '../../../../../graphql';
import { useChangeOwnerMutation } from '../../../../../graphql/mutations/useChangeOwnerMutation';
import { PrimaryButton, QuestionFlow } from '../../../../common';
import { LeadSoldInfo } from './LeadSoldInfo';
import { getFormattedStage, getFormattedStatus, getFormattedSubStage } from './Formatters';
import { arrayToLabelsValues } from '../../../../../util';
import { useLocation } from '@reach/router';
import { useFormAnswers } from '../../../../../hooks';

export const LeadInfo: React.FunctionComponent<LeadInfoProps> = ({
  leadId,
  hideFollowUp = false,
}) => {
  const [changeOwner, { loading: mutationLoading, error: mutationError }] = useChangeOwnerMutation();
  const [, onChange] = useFormAnswers(leadId);
  const { data: leadData, loading: leadLoading, error: leadError } = useLeadQuery(leadId);
  const { data: userData, loading: userLoading, error: userError } = useCurrentUserQuery();
  const allowsOwnerEdit = userData?.currentUser?.roles?.includes(UserRole.Manager);
  const { data: usersData, loading: usersLoading, error: usersError } = useUsersQuery(!allowsOwnerEdit);
  const [releaseToFollowUp, { data: followUpData, loading: followUpLoading, error: followUpError }] = useReleaseToFollowUpMutation();

  const error = leadError || usersError || userError || mutationError || followUpError;
  const loading = leadLoading || usersLoading || mutationLoading || userLoading || followUpLoading;

  const agentOptions: QuestionOption[] = React.useMemo(
    () => getAvailableAgents(usersData, leadData?.owner),
    [usersData, leadData?.owner],
  );
  const contactRestrictionValue = leadData?.questionFlowAnswers?.contactRestriction?.filter((
    restriction: ContactRestriction,
  ) => !!restriction) || [];
  const ContactRestrictionMap = [
    'No Phone',
    'No Email',
    'No SMS',
    'No Direct Mail',
    'Email Only',
  ];
  const contactRestrictionOptions = arrayToLabelsValues(ContactRestrictionMap);

  const toggleEditing = () => setState((prevState) => ({
    ...prevState,
    editing: !prevState.editing,
  }));

  const toggleContactRestrictionEditing = () => setState((prevState) => ({
    ...prevState,
    contactEditing: !prevState.contactEditing,
  }));

  const [state, setState] = React.useState({
    editing: false,
    contactEditing: false,
  });

  const handleChange = (userId: string) => {
    if (!userId) return;
    changeOwner({ leadId, ownerId: userId });
  };

  const handleContactRestrictionChange = (values: ContactRestriction[]) => {
    if (!values) return;
    onChange('lead.contactRestriction', values);
  };

  const handleReleaseToFollowUp = React.useCallback(() => {
    releaseToFollowUp({ leadId });
  }, [leadId, releaseToFollowUp]);

  // Only updates sold lead info if agent is currently navigating into the #lead-info section
  const location = useLocation();
  const { refetch } = useSoldLeadInfoQuery(leadId);
  React.useEffect(() => {
    // Should match: /customer/9da55e57-e156-4b51-ae55-92266a699a13/lead/d14ac0ad-ed20-4583-907b-dff337cc1bc9/lead-info
    const pathRegex = /^\/customer\/[\w\d-]+\/lead\/[\w\d-]+\/lead-info/;
    if (location && pathRegex.test(location.pathname)) {
      refetch();
    }
  }, [location]);

  return (
    <QueryLoader loading={loading} error={error} componentName="LeadInfo">
      <h1>Lead Details</h1>
      <QuestionView
        locked={!allowsOwnerEdit}
        id="stage"
        value={leadData?.owner?.id}
        editing={state.editing}
        onEdit={toggleEditing}
        onChange={handleChange}
        options={agentOptions}
        questionText="Lead Owner"
        label="Lead Owner"
        type={QuestionType.Select}
        leadId={leadId}
      />

      <QuestionCell
        label="Lead Created Date"
        input={<QuestionValueEditable value={leadData?.createdDate} type={QuestionType.Date} locked />}
      />
      <QuestionCell
        label="Lead UTM Source"
        input={<QuestionValueEditable value={leadData?.sfUtmData?.source} type={QuestionType.Text} locked />}
      />

      <QuestionCell
        label="TRM Stage"
        input={(
          <QuestionValueEditable
            value={getFormattedStage(leadData?.stage?.stage as LeadStage)}
            type={QuestionType.Select}
            options={[]}
            locked
          />
      )}
      />

      <QuestionCell
        label="TRM Sub Stage"
        input={(
          <QuestionValueEditable
            value={getFormattedSubStage(leadData?.stage?.subStage as LeadPostSaleSubStage)}
            type={QuestionType.Text}
            locked
          />
        )}
      />

      {
        !leadData?.isSold && (
          <QuestionCell
            label="SF Status"
            input={(
              <QuestionValueEditable
                value={getFormattedStatus(leadData?.initialStatus as LeadInitialStatus)}
                type={QuestionType.Select}
                options={[]}
                locked
              />
            )}
          />
        )
      }

      { leadData?.isSold && (
        <LeadSoldInfo
          leadId={leadId}
          isSold={leadData?.isSold}
          industryType={leadData?.industry.type}
          productType={leadData?.productType}
          quoteCarrier={leadData?.selectedQuote?.product?.carrier?.name}
          quoteProduct={leadData?.selectedQuote?.product?.name}
        />
      )}

      <QuestionView
        id="contactRestriction"
        editing={state.contactEditing}
        value={contactRestrictionValue}
        onChange={handleContactRestrictionChange}
        onEdit={toggleContactRestrictionEditing}
        options={contactRestrictionOptions}
        questionText="Contact Preference"
        label="Contact Preference"
        type={QuestionType.MultiSelect}
        leadId={leadId}
      />

      <ContentContainer>
        <PrimaryButton externalHref={`${getSalesforceBaseUrl()}/${leadData?.sfId}`}>
          Salesforce Lead/Opportunity
        </PrimaryButton>
      </ContentContainer>
      {!hideFollowUp && (
      <ContentContainer>
        <PrimaryButton onClick={handleReleaseToFollowUp}>Release to follow-up</PrimaryButton>
      </ContentContainer>
      )}
      {followUpData && <ContentContainer>Successfully released to follow-up</ContentContainer>}
      <QuestionFlow leadId={leadId} referenceId="hra-completion-track" allowExternal={false} />
    </QueryLoader>
  );
};

function getSalesforceBaseUrl() {
  return config.ENV === 'production'
    ? 'https://hiq.my.salesforce.com'
    : 'https://hiq--staging--c.visualforce.com/';
}

function getAvailableAgents(usersData: UsersQueryData, currentOwner?: UserFragment): QuestionOption[] {
  // If no usersData was fetched, creates a list with only the current owner.
  if (!usersData && currentOwner) {
    return [{
      label: currentOwner.name.fullFriendlyName,
      value: currentOwner.id,
    }];
  }
  return usersData?.users
    ?.filter((user) => (
      user.id === currentOwner.id
      || (!user.inactive && [
        UserProfile.Agent,
        UserProfile.PrecisionMedicareLiteAgent,
        UserProfile.PrecisionMedicareHeavyAgent,
      ].includes(user.profile as UserProfile))
    ))
    .map((user) => ({
      label: user.name.fullFriendlyName,
      value: user.id,
    }))
    .sort((userA, userB) => (userA.label > userB.label ? 1 : -1));
}
