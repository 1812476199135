import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../themes';
import { css } from '@emotion/core';

export const FollowUpContainer = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  padding: 5px;
  a {
    color: ${theme.color.primary};
  }
`);
