import { gql } from 'apollo-boost';
import { UserFollowUpQueryData, UserFollowUpQueryVariables } from './useUserFollowUpQuery.interfaces';
import { useQuery } from '@apollo/react-hooks';
import { CALENDAR_EVENT_FRAGMENT } from '../../../fragments';

const USER_FOLLOW_UP_QUERY = gql`
  query scheduledFollowUps($inputs: ScheduledFollowUpInput!) {
    scheduledFollowUps(inputs: $inputs) {
      ...CalendarEventFragment
    }
  }
  ${CALENDAR_EVENT_FRAGMENT}
`;

export function useUserFollowUpQuery(inputs: UserFollowUpQueryVariables['inputs']) {
  return useQuery<UserFollowUpQueryData, UserFollowUpQueryVariables>(USER_FOLLOW_UP_QUERY, {
    variables: {
      inputs,
    },
  });
}
