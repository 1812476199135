import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import { HeadingRendererProps } from './HeadingRenderer.interfaces';
import { labelToId } from '../../../../../util/labelToId';
import { HeaderRendererH1, HeaderWrapper } from './HeaderRenderer.elements';

export const HeadingRenderer: React.FunctionComponent<HeadingRendererProps> = ({
  level,
  children,
  ...otherProps
}) => {
  // @ts-ignore
  const anchorId: string = labelToId(children?.[0]?.props?.value ?? `${Date.now()}`); // eslint-disable-line

  if (anchorId === 'quote') {
    return null;
  }

  if (level === 1) {
    return (
      <HeaderRendererH1 id={anchorId}>
        { children }
      </HeaderRendererH1>
    );
  }
  const Heading = ReactMarkdown.renderers.heading;
  return (
    <HeaderWrapper>
      <Heading level={level} {...otherProps}>
        { children }
      </Heading>
    </HeaderWrapper>
  );
};
