import { gql } from 'apollo-boost';
import { useMutationCustom } from '../useMutationCustom';
import { UnderwriteMutationResponse, UnderwriteMutationVariables } from './useUnderwriteMutation.interfaces';
import { useActiveLeadId } from '../../../components/routers/LeadRouter/useActiveLeadId';
import { LEAD_FRAGMENT } from '../../fragments/lead';

const UNDERWRITE_MUTATION = gql`
  mutation Underwrite($leadId: ID!) {
    underwrite(leadId: $leadId) {
      code
      success
      message
      timestamp
      type
      errors
      approval
      lead {
        ...LeadFragment
      }
      isSpecialRateUpgradeAvailable
    }
  }
  ${LEAD_FRAGMENT}
`;

export function useUnderwriteMutation(leadIdParam?: string) {
  const leadId = useActiveLeadId(leadIdParam);
  return useMutationCustom<UnderwriteMutationResponse, UnderwriteMutationVariables>(UNDERWRITE_MUTATION, {
    variables: {
      leadId,
    },
    update: (cache, { data }) => {
      if (!data?.underwrite?.success) return;
      cache.writeFragment({
        data: data.underwrite.lead,
        fragment: LEAD_FRAGMENT,
        fragmentName: 'LeadFragment',
      });
    },
  });
}
