import * as React from 'react';
import { TcpaConsentProps } from './TcpaConsent.interfaces';
import { useFormAnswers } from '../../../../hooks';
import { RadioYesNoInput } from '../RadioYesNoInput';
import { FlexibleScript, FlexibleScriptVariationType } from '../../ScriptBlock';

export const TcpaConsent: React.FunctionComponent<TcpaConsentProps> = React.memo<TcpaConsentProps>(({
  answersKey,
}) => {
  const [answers, onChange] = useFormAnswers();
  const customerTcpaConsentQuestionId = 'customer.tcpaConsent';
  const tcpaConsentApproved = answers[customerTcpaConsentQuestionId];
  const setConsentStatus = (value: boolean) => {
    onChange(answersKey, value);
    if (answersKey !== customerTcpaConsentQuestionId) {
      onChange(customerTcpaConsentQuestionId, value);
    }
  };

  return (
    <>
      <FlexibleScript variation={FlexibleScriptVariationType.READ_FLEXIBLE_EXACT}>
        <p>
          Just in case we get disconnected,&nbsp;
          <strong>
            do you consent to Health IQ contacting you at the number you provided &lt;Verify customer’s best number&gt;
            via autodialed calls, texts, or pre-recorded messages to ensure you have the coverage you need and
            provide marketing and promotional offerings? You can opt-out at any time and consent isn’t a condition
            of purchase. Please say “yes” if you consent.
          </strong>
        </p>
      </FlexibleScript>
      <RadioYesNoInput
        value={tcpaConsentApproved}
        onChange={setConsentStatus}
        disabled={false}
      />
    </>
  );
});
