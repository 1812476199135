import React from 'react';
import {
  Note, NoteLink, NoteText, NoteTitle,
} from './CheckMyHealthRecordVerifyUserCarrier.elements';

export const VerifyWithCarrierNote = () => (
  <Note>
    <NoteTitle>
      NOTE
    </NoteTitle>
    <NoteText>
      If MARx is down, then you can verify a client via one of our Carrier websites.
    </NoteText>
    <NoteText>
      Client must have their Medicare Beneficiary Identifier (MBI)
      number or Medicare card on hand for this Carrier verification. SSN can not be used.
    </NoteText>
    <NoteText>
      For more information,
      {' '}
      <NoteLink href="https://hiq-uw.atlassian.net/wiki/spaces/Medicare/pages/2529034247/Carrier+Workaround+if+MARx+is+down">
        visit this page.
      </NoteLink>
    </NoteText>
  </Note>
);
