import * as React from 'react';
import {
  FullHeight,
  CustomerLayout,
  TabTopLayoutTabItem,
  SidePanelLayoutTab,
} from '../../../layouts';
import {
  ActivityFeed,
  CustomerSummary,
  Lead,
  LeadFollowUp,
  NavBar,
  NewCrossSale,
  Rebuttals,
  ScratchPad,
  SendEmail,
  SendSms,
  Diagnoses,
} from '../../../panels';
import { HomeProps } from './Home.interfaces';
import {
  useCustomerQuery,
  LeadFragment,
  usePullLeadMutation,
  useRefreshPrequalificationMutation,
  useCurrentUserQuery,
  useLeadQuery,
  useSoldLeadInfoQuery,
} from '../../../../graphql';
import { DialerCallRouter, LeadRouter, useLeadIdRoute } from '../../../routers';
import {
  IndustryTypeLabelMap,
  ProductTypePropertyAndCasualty,
  ProductTypeLabelShortMap,
  ProductTypeMedicare,
  UserProfile,
  IndustryType,
} from '@hiq/crm.types';
import { useCustomerId } from '../../../routers/CustomerRouter';
import Helmet from 'react-helmet';
import { QueryLoader } from '../../../common/QueryLoader';
import { WarningMessage } from './Home.elements';
import { LogPageLoad, LogTabChange } from '../../../common/LogInteraction';
import { PreSalesHome } from '../PreSalesHome';
import { ElectronicSalesQualifierHome } from '../ElectronicSalesQualifierHome';
import { InitialHome } from '../InitialHome';
import { isSalesDevelopmentRep, isElectronicSalesQualifier } from '../../../../util';
import {
  CollapseColumnsContext, CollapseContextData, DementiaContext, DementiaContextData,
} from '../../../contexts';
import { useLocation } from '@reach/router';
import { emitAnalyticsEvent } from '../../../../events/emitAnalyticsEvent';
import { AvailablePlans } from '../../../panels/AvailablePlans/AvailablePlans';
import { useCurrentUserIsBionic } from '../../../../hooks';

export const Home: React.FunctionComponent<HomeProps> = () => {
  const { data, loading, error } = useCurrentUserQuery();

  React.useEffect(() => {
    if (data?.currentUser) {
      emitAnalyticsEvent('Loaded TRM Customer Home', { userId: data.currentUser.id, profile: data?.currentUser?.profile });
    }
  }, [JSON.stringify(data)]);

  if (isSalesDevelopmentRep(data?.currentUser?.profile as UserProfile)) {
    return (
      <QueryLoader loading={loading} error={error} componentName="CustomerHome">
        <InitialHome home={<PreSalesHome />} />
      </QueryLoader>
    );
  }
  if (isElectronicSalesQualifier(data?.currentUser?.profile as UserProfile)) {
    return (
      <QueryLoader loading={loading} error={error} componentName="CustomerHome">
        <ElectronicSalesQualifierHome />
      </QueryLoader>
    );
  }

  return (
    <QueryLoader loading={loading} error={error} componentName="CustomerHome">
      <MainHome />
    </QueryLoader>
  );
};

export const MainHome: React.FunctionComponent<HomeProps> = () => {
  const { data: customerData, loading: customerLoading, error: customerError } = useCustomerQuery();
  const [activeLeadId, onLeadTabChange, isValidLead] = useLeadIdRoute(customerData?.customer?.leads?.[0]?.id);
  const customerId = useCustomerId();
  const [pullLead] = usePullLeadMutation();
  const [refreshPrequalification] = useRefreshPrequalificationMutation();
  const [collapseAll, setCollapseAll] = React.useState(false);
  const [hasDementiaFlag, setDementia] = React.useState(false);
  const [isOpenDementiaFlagWarning, setOpenDementiaFlagWarning] = React.useState(false);

  const setHasDementiaFlag = React.useCallback((dementiaFlag) => {
    setDementia(dementiaFlag);
    if (hasDementiaFlag) return;
    setOpenDementiaFlagWarning(true);
  }, [setDementia, setOpenDementiaFlagWarning, hasDementiaFlag]);

  const toggleOpenDementiaFlag = React.useCallback(() => {
    setOpenDementiaFlagWarning((flag) => !flag);
  }, [setOpenDementiaFlagWarning]);

  const dementiaContextValue: DementiaContextData = {
    isOpenDementiaFlagWarning,
    toggleOpenDementiaFlag,
    hasDementiaFlag,
    setHasDementiaFlag,
  };

  const toggle = React.useCallback(() => {
    setCollapseAll(true);

    setTimeout(() => {
      setCollapseAll(false);
    }, 3000);
  }, [setCollapseAll]);

  const collapseContextValue: CollapseContextData = {
    collapseAll,
    toggle,
  };

  const {
    loading: userLoading,
    error: userError,
    data: userData,
  } = useCurrentUserQuery();
  const isMissingUser = !userLoading && !userError && !userData?.currentUser?.id;
  const userIsInactive = !!userData?.currentUser?.inactive;
  const { data: leadData } = useLeadQuery(activeLeadId);
  const { data: soldLeadInfoData } = useSoldLeadInfoQuery(activeLeadId, !leadData?.isSold);
  const hasMedicare = customerData
    ?.customer
    ?.leads
    ?.some((lead) => lead.industry.type === IndustryType.Medicare);

  const isBionic = useCurrentUserIsBionic();

  const location = useLocation();
  const customerLayoutTabs: SidePanelLayoutTab[] = React.useMemo(() => {
    const pathRegex = /^\/customer\/[\w\d-]+\/lead\/[\w\d-]+\/interview$/;
    const isQuoteInterviewSection = location && pathRegex.test(location.pathname) && location.hash === '#quote';
    return [
      ...(!hasMedicare ? [] : [{
        id: 'rebuttals',
        tooltip: 'Rebuttals',
        icon: 'voice-over',
        content: <Rebuttals />,
      }]) as SidePanelLayoutTab[],
      {
        id: 'quick-quote',
        tooltip: 'Quick Quote',
        icon: 'dollar',
        content: <AvailablePlans />,
      },
      {
        id: 'activity-feed',
        tooltip: 'Activity Feed',
        icon: 'contact-mail',
        content: <ActivityFeed />,
      },
      {
        id: 'scratch-pad',
        tooltip: 'Scratch Pad',
        icon: 'assignment',
        content: <ScratchPad />,
      },
      {
        id: 'follow-up',
        tooltip: 'Follow-ups',
        icon: 'event-note',
        content: <LeadFollowUp />,
      },
      {
        id: 'email',
        tooltip: 'Send Email',
        icon: 'email',
        content: <SendEmail leadId={activeLeadId} />,
      },
      {
        id: 'sms',
        tooltip: 'Send SMS message',
        icon: 'sms',
        content: <SendSms leadId={activeLeadId} userId={userData?.currentUser?.id} />,
      },
      ...(isQuoteInterviewSection ? [{
        id: 'diagnoses',
        tooltip: 'Diagnoses',
        icon: 'doctor',
        content: <Diagnoses />,
        hide: !isBionic,
      }] as SidePanelLayoutTab[] : []),
    ];
  }, [hasMedicare, activeLeadId, userData?.currentUser?.id, customerData?.customer?.person?.email, isBionic, location]);

  React.useEffect(() => {
    const refreshData = async () => {
      if (isValidLead) {
        await refreshPrequalification({ customerId });
      }
    };
    refreshData();
  }, [customerId]);
  React.useEffect(() => {
    if (activeLeadId) {
      pullLead({ leadId: activeLeadId });
    }
  }, [activeLeadId]);

  const tabs = React.useMemo(() => (
    [
      ...mapLeads(customerData?.customer?.leads),
      {
        id: 'new',
        label: '+ Spouse/Cross-Sale',
        content: (
          <>
            <LogTabChange tab="New Cross Sale" />
            <NewCrossSale
              showMaAndPdpOptions={!customerData?.customer?.showMedicareAdvantageTransition}
            />
          </>
        ),
      },
    ]
  ), [leadsMemoId(customerData?.customer?.leads), customerData?.customer?.showMedicareAdvantageTransition]);

  return (
    <FullHeight>
      <LogPageLoad page="Home" detail="Loaded Home Page" />
      <Helmet title={customerData?.customer?.person?.name?.last ?? 'Customer'} />
      { isMissingUser && (
        <WarningMessage>
          Could not find your user account. Please contact IT.
        </WarningMessage>
      ) }
      { userIsInactive && (
        <WarningMessage>
          Account is inactive
        </WarningMessage>
      ) }
      { !isValidLead && (
        <WarningMessage>
          Lead ID is invalid
        </WarningMessage>
      ) }
      { !userIsInactive && (
        <QueryLoader loading={customerLoading} error={customerError} componentName="CustomerHome">
          <CollapseColumnsContext.Provider value={collapseContextValue}>
            <DementiaContext.Provider value={dementiaContextValue}>
              <CustomerLayout
                navBar={<NavBar appointment title="TRM | Customer Page" timer dementiaFlag precisionScore menu />}
                infoPanelContent={<CustomerSummary isBionic={isBionic} />}
                toolPanelProps={{
                  initialId: hasMedicare ? 'rebuttals' : 'quick-quote',
                  tabs: customerLayoutTabs,
                }}
                mainPanelProps={{
                  onTabChange: onLeadTabChange,
                  activeId: activeLeadId,
                  tabs,
                }}
                lockedDetails={{ isLocked: soldLeadInfoData?.soldLeadInfo?.isLocked, sfId: leadData?.sfId }}
              />
            </DementiaContext.Provider>
          </CollapseColumnsContext.Provider>
        </QueryLoader>
      ) }
    </FullHeight>
  );
};

function mapLead(lead: LeadFragment): TabTopLayoutTabItem {
  const label = leadLabel(lead);
  return {
    id: lead.id,
    label: label ?? '...',
    content: (
      <LeadRouter>
        <DialerCallRouter>
          <LogTabChange tab={label} />
          <Lead />
        </DialerCallRouter>
      </LeadRouter>
    ),
  };
}

function leadLabel(lead: LeadFragment): string {
  return lead?.productType
    ? ProductTypeLabelShortMap[lead?.productType as ProductTypeMedicare | ProductTypePropertyAndCasualty]
    : IndustryTypeLabelMap[lead?.industry?.type];
}

function mapLeads(leads?: LeadFragment[]): TabTopLayoutTabItem[] {
  if (!leads) {
    return [];
  }
  return leads.map(mapLead);
}

function leadsMemoId(leads?: LeadFragment[]): string {
  if (!leads) return null;
  return leads?.map((l) => (`${l.id}${leadLabel(l)}`)).join('');
}
