import { CircularProgress, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useSunfireV2DrugsByTermQuery } from '../../../../../../graphql';
import React, {
  SyntheticEvent, useEffect, useMemo, useRef, useState,
} from 'react';
import { DrugSearchPanelProps } from './DrugSearchPanel.interfaces';
import { useDebounce } from '@healthiqeng/core.hooks.use-debounce';
import { QueryLoader } from '../../../../QueryLoader';
import { PaddingBottom } from '../../CheckMyHealthRecordDataGrid.elements';

export const DrugSearchPanel = ({ onDrugSelected, defaultDrugName, readOnly = false }: DrugSearchPanelProps) => {
  const [drugName, setDrugName] = useState('');
  const [isOpen, setIsOpen] = useState(true);
  const [drugOptions, setDrugOptions] = useState([]);
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    inputRef.current.focus();
  }, []);
  const [
    findSunfireDrugsByTerm,
    {
      data: drugData, loading, error,
    },
  ] = useSunfireV2DrugsByTermQuery();

  const updateDropDown = (drugName: string) => () => {
    findSunfireDrugsByTerm({
      variables: {
        term: drugName,
      },
    });
  };

  const memoizedUpdateDropDown = useMemo(() => updateDropDown(drugName), [drugName]);

  const debouncedUpdated = useDebounce(memoizedUpdateDropDown, 2000);

  useEffect(() => {
    if (drugName && drugName.length > 1) debouncedUpdated();
  }, [drugName]);

  const handleOnDrugChange = (event: SyntheticEvent, value: string, reason: string) => {
    setDrugName(value);
    if (reason === 'input') {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  const handleOnDrugSelected = (event: SyntheticEvent, value: any, reason: string) => {
    if (reason === 'select-option') {
      onDrugSelected(value.drug);
    } else if (reason === 'clear') {
      onDrugSelected();
    }
  };

  useEffect(() => {
    if (drugData?.findSunfireDrugsByTerm?.length > 0) {
      const drugs = drugData?.findSunfireDrugsByTerm;
      const drugOptionsMap = drugs.map((drug) => ({
        label: drug.name,
        drug,
      }));
      setDrugOptions(drugOptionsMap);
    }
  }, [drugData]);

  useEffect(() => {
    if (!isOpen) setDrugOptions([]);
  }, [isOpen]);

  useEffect(() => {
    if (defaultDrugName && defaultDrugName !== '') {
      setDrugName(defaultDrugName);
    }
  }, [defaultDrugName]);

  return (
    <QueryLoader error={error} loading={false} componentName="DrugSearchPanel">
      <PaddingBottom>
        <Autocomplete
          inputValue={drugName}
          onInputChange={handleOnDrugChange}
          onChange={handleOnDrugSelected}
          options={drugOptions}
          getOptionLabel={(option) => option.label}
          style={{ width: 300 }}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Medication name"
              variant="standard"
              InputProps={{
                ...params.InputProps,
                inputRef,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          open={drugName.length > 2 && drugOptions.length > 0 && isOpen}
          disabled={readOnly}
        />
      </PaddingBottom>
    </QueryLoader>
  );
};
