import * as React from 'react';
import { Card } from '../Card';
import { Snackbar } from '@material-ui/core';
import { FileList } from './FileList';
import { isEmpty } from '@healthiqeng/core.util.is-empty';
import { Alert, AlertTitle } from '@material-ui/lab';
import { FileListItem } from './FileList/FileListItem';
import { isPresent } from '@healthiqeng/core.util.is-present';
import { useFileUploadMutation } from '../../../graphql/mutations';
import { useActiveLeadId } from '../../routers/LeadRouter/useActiveLeadId';
import { useFilesQuery } from './useFilesQuery';
import { FileUploadButtons } from './FileUploadButtons';
import { FileFragment } from '../../../graphql/fragments/file';

export const FileUploader: React.FunctionComponent = () => {
  const leadId = useActiveLeadId();
  const [selectedFile, setFile] = React.useState(null);
  const [uploading, setUploading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [message, setMessage] = React.useState(null);
  const [upload, { data }] = useFileUploadMutation();
  const existingFiles = useFilesQuery();
  const files = getAttachedFiles(existingFiles, data?.saveUploadedFile?.files);

  const onFileInputChange = (file: File) => setFile(file);
  const onUploadButtonClick = async () => {
    try {
      setUploading(true);
      await upload(leadId, selectedFile);
      setMessage(`Successfully uploaded ${selectedFile.name}`);
      setFile(null);
    } catch (e) {
      setError(e);
    } finally {
      setUploading(false);
    }
  };
  const onClearButtonClick = () => setFile(null);
  const onCloseMessageTray = React.useCallback(() => {
    setError(null);
    setMessage(null);
  }, [setError, setMessage]);

  return (
    <>
      <Card margin="20px">
        <FileUploadButtons
          isUploadDisabled={!selectedFile || uploading}
          isUploading={uploading}
          onFileInputChange={onFileInputChange}
          onUploadButtonClick={onUploadButtonClick}
        />
        { isPresent(selectedFile) && (<FileListItem allowClear name={selectedFile?.name} onClear={onClearButtonClick} />)}
      </Card>
      { !isEmpty(files) && (
        <Card margin="20px">
          <FileList files={files} />
        </Card>
      )}
      <Snackbar open={!!error || !!message} autoHideDuration={5000} onClose={onCloseMessageTray}>
        {error
          ? (
            <Alert variant="filled" severity="error" onClose={onCloseMessageTray}>
              <AlertTitle>Encountered an error</AlertTitle>
              <p>{error && error.message ? error.message : error}</p>
            </Alert>
          ) : (
            <Alert variant="filled" severity="success" onClose={onCloseMessageTray}>
              <AlertTitle>{ message }</AlertTitle>
            </Alert>
          )}
      </Snackbar>
    </>
  );
};

function getAttachedFiles(existingFiles: FileFragment[], refreshedFiles: FileFragment[]): FileFragment[] {
  if (refreshedFiles) return refreshedFiles;
  return existingFiles ?? [];
}
