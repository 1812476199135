import { JsonCompatible } from '@healthiqeng/core.types';
import React from 'react';
import { OnChangeAnswerFunction } from '../../../../../hooks';

export const useManageChanges = (
  onChange: OnChangeAnswerFunction,
  persistAnswers: () => void,
): {
    onTempValueChanged: (value: JsonCompatible) => void,
    onValueChanged: (value: JsonCompatible) => void,
    onDataChanged: (key: string, val: JsonCompatible
    ) => void,
    tempHipaaApproval: boolean,
    setTempHipaaApproval: (val: boolean
    ) => void,
    onConfirmConsentAttest: (value: JsonCompatible
    ) => void,
  } => {
  const [tempHipaaApproval, setTempHipaaApproval] = React.useState<boolean>();

  const onTempValueChanged = React.useCallback((value: JsonCompatible) => {
    if (!value) {
      onChange('customer.person.hipaaConsentApproved', value, true);
      onChange('customer.person.hipaaConsentDatetime', new Date().toISOString(), true);
      persistAnswers();
    }

    setTempHipaaApproval(!!value);
  }, [onChange, persistAnswers, setTempHipaaApproval]);

  const onValueChanged = React.useCallback((value: JsonCompatible) => {
    onChange('customer.person.hipaaConsentApproved', value, true);
    onChange('customer.person.hipaaConsentDatetime', new Date().toISOString(), true);
    persistAnswers();
  }, [onChange, persistAnswers]);

  const onConfirmConsentAttest = React.useCallback((value: JsonCompatible) => {
    onChange('customer.person.hipaaConsentAttest', value, true);
    onChange('customer.person.hipaaConsentAttestDatetime', new Date().toISOString(), true);
    persistAnswers();
  }, [onChange, persistAnswers]);

  const onDataChanged = React.useCallback((key: string, val: JsonCompatible) => {
    onChange(key, val);
  }, [onChange]);

  return {
    onTempValueChanged,
    onValueChanged,
    onDataChanged,
    tempHipaaApproval,
    setTempHipaaApproval,
    onConfirmConsentAttest,
  };
};
