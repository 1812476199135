import * as React from 'react';

export class ReactComponentStub<T> {
  public props: T = null;
  public getComponent() {
    return (props: T) => {
      this.props = props;
      return <></>;
    };
  }
}
