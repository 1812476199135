import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { TooltipInlineContainerProps } from './CompareDoctorLocations.interfaces';

export const DoctorLocationsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
`;

export const InfoIconContainer = styled.span`
  font-size: 0px;
  color: #0075CA;
  cursor: pointer;
`;

export const TooltipInlineContainer = styled.div<TooltipInlineContainerProps>(({ covered }) => css`
  display: flex;
  gap: 7px;
  align-items: center;
  min-height: 35px;
  line-height: 23px;
  padding: 0 10px;
  ${!covered ? 'color: #7F8385' : ''}
`);

export const TooltipIconContainer = styled.span`
  font-size: 18px;
  position: relative;
  top: 1px
`;
