import React from 'react';
import {
  CancelButton, SendPPEContainer, SendPPETitle, SendPPEWarning,
} from './SendPPE.elements';
import { Bold } from '../TRMQuote.elements';
import { Dialog, DialogActions } from '@material-ui/core';

interface SendPPESuccessProps {
  onClose: () => void;
  open: boolean;
  currentPlanName: string;
  firstName: string;
  lastName: string;
  email: string;
}

const SendPpeSuccess = ({
  open, onClose, currentPlanName, firstName, lastName, email,
}: SendPPESuccessProps) => (
  <Dialog open={open} onClose={onClose}>
    <SendPPEContainer>
      <SendPPETitle>PPE Sent</SendPPETitle>
      <SendPPEWarning>
        Plan proposal for&nbsp;
        <Bold>{currentPlanName}</Bold>
&nbsp;sent to&nbsp;
        <Bold>
          {firstName}
&nbsp;
          {lastName}
&nbsp;
        </Bold>
        at&nbsp;
        <Bold>{email}</Bold>
      </SendPPEWarning>
      <DialogActions>
        <CancelButton onClick={onClose}>
          Close
        </CancelButton>
      </DialogActions>
    </SendPPEContainer>
  </Dialog>
);

export default SendPpeSuccess;
