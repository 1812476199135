import * as React from 'react';
import { LifeQuoteOptionsProps } from './LifeQuoteOptions.interfaces';
import { useFormAnswers } from '../../../../../hooks';
import {
  ProductTypeLife,
  QuestionType,
  lifeProductTypeQuoteOptions,
  wholeLifeForcedUnderwritingClassOptions,
  forcedTobaccoOptions,
  wholeLifeForcedUnderwritingTypeOptions,
  UnderwritingType,
} from '@hiq/crm.types';
import { isPresent } from '@healthiqeng/core.util.is-present';
import { QuoteOptionsLayout } from '../../QuoteOptionsLayout';
import { QuoteOption } from '../QuoteOption';
import { PrimaryButton } from '../../../inputs/PrimaryButton';

export const LifeQuoteOptions: React.FunctionComponent<LifeQuoteOptionsProps> = ({
  onQuote,
  isFetching,
  defaultProductType,
}) => {
  const [answers, onChange] = useFormAnswers();

  const productType = answers['lead.quoteOptions.productTypeOverride']
    ?? defaultProductType ?? ProductTypeLife.WholeLife;
  const faceValue = answers['lead.quoteOptions.faceValue']
    ?? defaultFaceValue(productType);
  const underwritingClass = answers['lead.quoteOptions.underwritingClass'];
  const underwritingType = answers['lead.quoteOptions.underwritingType']
    ?? (answers['lead.willAnswerMedicalQuestions'] === false ? UnderwritingType.GuaranteedIssue : null);
  const tobacco = answers['lead.quoteOptions.tobacco'];

  const runQuote = () => onQuote({
    productType,
    faceValue,
    underwritingClass,
    underwritingType,
    tobacco,
  });

  const disabled = ![productType, faceValue].every(isPresent) || isFetching;

  return (
    <QuoteOptionsLayout
      leftContent={(
        <>
          <QuoteOption
            id="lead.quoteOptions.productTypeOverride"
            label="Product Type"
            value={productType}
            options={lifeProductTypeQuoteOptions}
            type={QuestionType.Select}
            handleChange={onChange}
          />
          <QuoteOption
            id="lead.quoteOptions.faceValue"
            label="Face Value"
            value={faceValue}
            type={QuestionType.Currency}
            handleChange={onChange}
          />
        </>
      )}
      rightContent={(
        <>
          <QuoteOption
            id="lead.quoteOptions.underwritingClass"
            label="Forced Underwriting Class"
            value={underwritingClass}
            options={wholeLifeForcedUnderwritingClassOptions}
            type={QuestionType.Select}
            handleChange={onChange}
          />
          <QuoteOption
            id="lead.quoteOptions.underwritingType"
            label="Forced Underwriting Type"
            value={underwritingType}
            options={wholeLifeForcedUnderwritingTypeOptions}
            type={QuestionType.Select}
            handleChange={onChange}
          />
          <QuoteOption
            id="lead.quoteOptions.tobacco"
            label="Forced Tobacco Rating"
            value={tobacco}
            options={forcedTobaccoOptions}
            type={QuestionType.Select}
            handleChange={onChange}
          />
          <PrimaryButton
            onClick={runQuote}
            disabled={disabled}
            variant="contained"
          >
            { isFetching ? 'Fetching...' : 'Get Quotes' }
          </PrimaryButton>
        </>
      )}
      minWidth={200}
      gutter={20}
    />
  );
};

function defaultFaceValue(productType: ProductTypeLife) {
  if (!productType) return 15000;
  switch (productType) {
    case ProductTypeLife.Term:
      return 500000;
    case ProductTypeLife.WholeLife:
      return 15000;
    case ProductTypeLife.Juvenile:
      return 15000;
    case ProductTypeLife.Accidental:
      return 100000;
    case ProductTypeLife.CriticalIllness:
      return 10000;
    default:
      return 100000;
  }
}
