import React from 'react';
import { Markdown } from '@hiq/crm.types';
import { MultiSectionQuestionFlowRendererProps, FormsStatusMap } from './MultiSectionQuestionFlowRenderer.interfaces';
import { QuestionFlowSection } from './QuestionFlowSection';
import { useControlledMarkdownSections, ParsedQuestionFlowSection } from './useControlledMarkdownSections';
import { LockedQuestionFlow } from '../LockedQuestionFlow';
import { useMarkdownSections } from './useMarkdownSections';

export const MultiSectionQuestionFlowRenderer: React.FunctionComponent<MultiSectionQuestionFlowRendererProps> = ({
  markdownSource,
  leadId,
  root,
  active = 0,
  onNext,
  onPrevious,
  CustomElement,
}) => {
  const [markdownArray, anchors, addMarkdown] = useMarkdownSections(markdownSource);
  const [formStatusMaps, setFormStatusMaps] = React.useState<FormsStatusMap[]>(new Array(markdownArray?.length));
  const [sections, invalidFields] = useControlledMarkdownSections(markdownArray, formStatusMaps);
  // Whenever the markdown of the page changes, i.e. when
  // we load a partial into the page, recalculate the sections
  // of the whole thing.
  const onMarkdownChange = React.useCallback((id: string, markdown: Markdown) => {
    addMarkdown(id, markdown);
  }, [addMarkdown]);

  const setFormStatusMap = (index: number, data: any) => {
    formStatusMaps[index] = data;
    setFormStatusMaps(formStatusMaps);
  };

  return (
    <>
      {sections.map(({ markdown, isLocked }: ParsedQuestionFlowSection, index: number) => {
        if (isLocked) {
          return <LockedQuestionFlow key={`section-${index * 10}`} hidden={index !== active} invalidFields={invalidFields} />;
        }
        return (
          <QuestionFlowSection
            key={`section-${index * 10}`}
            anchor={anchors[index]}
            hidden={index !== active}
            markdown={markdown}
            leadId={leadId}
            root={root}
            onMarkdownChange={onMarkdownChange}
            moveBackwards={active > 0 ? onPrevious : undefined}
            moveForward={onNext}
            formStatusMap={formStatusMaps[index] ?? new Map()}
            setFormStatusMap={(statusMap) => setFormStatusMap(index, statusMap)}
            CustomElement={CustomElement}
          />
        );
      })}
    </>
  );
};
