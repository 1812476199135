import { NetworkStatus } from 'apollo-boost';
import React, { useEffect, useState } from 'react';
import { FormFieldsStatus } from '@hiq/crm.types';
import { PrimaryButton } from '../../../inputs';
import { QuestionFlowSectionProps } from './QuestionFlowSection.interfaces';
import { QuestionFlowRenderer } from '../../QuestionFlowRenderer';
import { createQuestionFlowPartial } from '../QuestionFlowPartial';
import { PaginationContainer, QuestionSection } from './QuestionFlowSection.elements';
import { MissingFieldsWarning } from '../../../MissingFieldsWarning';
import { FormsStatusMap } from '../MultiSectionQuestionFlowRenderer.interfaces';
import { emitAnalyticsEvent } from '../../../../../events/emitAnalyticsEvent';
import { useCustomerQuery } from '../../../../../graphql';

const getInvalidFields = (statuses: FormsStatusMap): string[] => Array
  .from(statuses.values())
  .flatMap((status: FormFieldsStatus) => Object
    .values(status)
    .filter((s) => !s.valid).map((s) => s.label));

const isValid = (statuses: FormsStatusMap): boolean => Array
  .from(statuses.values())
  .every((status: FormFieldsStatus) => Object.values(status).every((s) => s.valid));

const SUNFIRE_IFRAME_SECTION = 'sunfire-iframe';

export const QuestionFlowSection: React.FunctionComponent<QuestionFlowSectionProps> = ({
  hidden = false,
  markdown,
  leadId,
  onMarkdownChange,
  moveBackwards,
  moveForward,
  CustomElement,
  formStatusMap,
  setFormStatusMap,
}) => {
  const [isErrorTrayOpen, setIsErrorTrayOpen] = React.useState(false);
  const onFieldsChange = React.useCallback((id: string, status: FormFieldsStatus) => {
    setFormStatusMap(formStatusMap.set(id, status));
  }, []);
  const { networkStatus, refetch } = useCustomerQuery(undefined, true);
  const [refetchingData, setRefetchingData] = useState(false);

  const hideNextPreviousButton = markdown.includes(SUNFIRE_IFRAME_SECTION);

  const onNextButtonClick = React.useCallback(() => {
    setRefetchingData(true);
    refetch();
  }, [refetch]);

  const onMoveForward = React.useCallback(() => {
    const isSectionValid = isValid(formStatusMap);
    emitAnalyticsEvent('Button Click', { name: 'Next Section', isSectionValid });
    if (!isSectionValid) {
      setIsErrorTrayOpen(true);
    } else { moveForward(); }
  }, [moveForward, formStatusMap]);

  useEffect(() => {
    if (refetchingData && networkStatus === NetworkStatus.ready) {
      setRefetchingData(false);
      onMoveForward();
    }
  }, [networkStatus]);

  const onCloseErrorTray = () => setIsErrorTrayOpen(false);
  const invalidFields = getInvalidFields(formStatusMap);

  return (
    <QuestionSection style={{ display: hidden ? 'none' : 'block' }}>
      <QuestionFlowRenderer
        questionFlowComponent={createQuestionFlowPartial({ onMarkdownChange })}
        leadId={leadId}
        markdownSource={markdown}
        onFieldsChange={onFieldsChange}
        CustomElement={CustomElement}
      />
      {
        !hideNextPreviousButton && (
          <PaginationContainer>
            { moveBackwards ? <PrimaryButton onClick={moveBackwards}>Previous</PrimaryButton> : <div /> }
            { moveForward ? <PrimaryButton onClick={!refetchingData ? onNextButtonClick : undefined}>Next</PrimaryButton> : <div /> }
          </PaginationContainer>
        )
      }

      <MissingFieldsWarning isOpen={isErrorTrayOpen} onClose={onCloseErrorTray} invalidFields={invalidFields} />
    </QuestionSection>
  );
};
