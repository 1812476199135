import { gql } from 'apollo-boost';
import { useCallback } from 'react';
import { MutationCustomReturn, useMutationCustom } from '../useMutationCustom';
import { QUOTE_FRAGMENT } from '../../fragments';
import { eventBus } from '../../../services';
import { QuoteSelectedEvent } from '../useSelectQuoteMutation';
import { MedicareQuoteMutationResponse, MedicareQuoteMutationVariables } from './useMedicareQuoteMutation.interfaces';

const MEDICARE_QUOTE_MUTATION = gql`
    mutation AddExternalQuote($leadId: ID!, $properties: MedicareQuoteInput!) {
      addMedicareQuote(leadId: $leadId, properties: $properties) {
        code,
        success,
        message,
        timestamp,
        quote {
        ...QuoteFragment
        }
      }
    }
  ${QUOTE_FRAGMENT}
`;

export function useMedicareQuoteMutation(): MutationCustomReturn<MedicareQuoteMutationResponse, MedicareQuoteMutationVariables> {
  const [addQuote, result] = useMutationCustom<MedicareQuoteMutationResponse, MedicareQuoteMutationVariables>(MEDICARE_QUOTE_MUTATION);
  const addQuoteWrapped = useCallback(async (variables: MedicareQuoteMutationVariables) => {
    const response = await addQuote(variables);
    if (response?.data?.addMedicareQuote?.success) {
      eventBus.emit(new QuoteSelectedEvent());
    }
    return response;
  }, [addQuote]);

  return [addQuoteWrapped, result];
}
