import { useEventInProgress } from '../../../../events/useEventInProgress';
import {
  QuoteUnselectFailedEvent,
  QuoteUnselectStartEvent,
  QuoteSelectFailedEvent,
  QuoteSelectStartEvent,
  CustomerRefetchCompletedEvent,
} from '../../../../graphql';

export function useQuoteUpdateInProgress(): boolean {
  const quoteSelectInProgress = useEventInProgress(
    QuoteSelectStartEvent, [CustomerRefetchCompletedEvent, QuoteSelectFailedEvent],
  );
  const quoteUnselectInProgress = useEventInProgress(
    QuoteUnselectStartEvent, [CustomerRefetchCompletedEvent, QuoteUnselectFailedEvent],
  );
  return !!(quoteSelectInProgress || quoteUnselectInProgress);
}
