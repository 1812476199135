import { ApolloError, gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';
import { BionicFetchHealthRecordsQueryData, BionicFetchHealthRecordsQueryVariables } from './useBionicFetchHealthRecords.interfaces';
import { FetchPolicy } from '@apollo/client/core/watchQueryOptions';
import { useCallback } from 'react';
import { getEventBus } from '../../../events';
import { MedDigitalAnalyticsEvent } from '../../../events/MedDigitalAnalyticsEvent';
import { QueryTuple } from '@apollo/client/react/types/types';
import { HealthRecordEventProperties } from '../../../events/MedDigitalAnalyticsEvent.interfaces';

export const BIONIC_FETCH_HEALTH_RECORDS_QUERY = gql`
  query BionicFetchHealthRecords($email: String, $zip: String!, $county: String!, $startDate: Date, $endDate: Date, $customerId: String!) {
    fetchBionicHealthRecords(email: $email, zip: $zip, county: $county, startDate: $startDate, endDate: $endDate, customerId: $customerId) {
      complete
      securityQuestionsFailed
      userNotFound
      drugs {
        name
        fillsLastYear
        strength
        fillQuantity
        pharmacyName
        ndc
        daysOfSupply
        isGeneric
        genericName
        fillDate
        akaNames
        form
        diagnoses {
          disease_key
          description
        }
      }
      doctors {
        name
        visitsLastYear
        visitsLast5Years
        visitsLast3Years
        lastVisit
        practice
        addressLine1
        addressLine2
        city
        state
        zip
        npi
        distanceInMiles
      }
      pharmacies {
        name
        fillsLastYear
        id
        addressLine1
        addressLine2
        city
        state
        zip
        npi
        distanceInMiles
      }
      userClaims {
        description
        claimCode
        clinicalName
        diagnosisDate
        claimCodeId
        hasICD
        diseaseKey
        drugs {
          name
        }
      }
      invalidPrescriptions {
        name
        fillsLastYear
        strength
        fillQuantity
        pharmacyName
        ndc
        daysOfSupply
        isGeneric
        genericName
        fillDate
        akaNames
        form
      }
      invalidPharmacies {
        name
        fillsLastYear
        id
        addressLine1
        addressLine2
        city
        state
        zip
        npi
      }
      invalidDoctors {
        name
        visitsLastYear
        visitsLast5Years
        visitsLast3Years
        lastVisit
        practice
        addressLine1
        addressLine2
        city
        state
        zip
        npi
      }
    }
  }
`;

export function useBionicFetchHealthRecords(fetchPolicy: FetchPolicy = 'network-only')
  :QueryTuple<BionicFetchHealthRecordsQueryData, BionicFetchHealthRecordsQueryVariables> {
  const eventBus = getEventBus();

  const emitEvent = useCallback((message: string, properties: HealthRecordEventProperties) => {
    eventBus.emit(new MedDigitalAnalyticsEvent(message, properties));
  }, [eventBus]);

  const onCompleted = useCallback((data: BionicFetchHealthRecordsQueryData) => emitEvent(
    'Success',
    {
      location: 'health_record',
      rx_count: data.fetchBionicHealthRecords.drugs.length,
      dx_count: data.fetchBionicHealthRecords.doctors.length,
      diagnostics_count: data.fetchBionicHealthRecords.userClaims.length,
      pharmacies_count: data.fetchBionicHealthRecords.pharmacies.length,
    },
  ), [emitEvent]);

  const onError = useCallback((error: ApolloError) => emitEvent(
    'Error',
    {
      location: 'health_record',
      error: error.message,
    },
  ), [emitEvent]);

  const [getCustomerHealthRecord, result] = useLazyQuery<BionicFetchHealthRecordsQueryData, BionicFetchHealthRecordsQueryVariables>(
    BIONIC_FETCH_HEALTH_RECORDS_QUERY, {
      fetchPolicy,
      onError,
      onCompleted,
    },
  );

  const getCustomerHealthRecordStatusWrapped = useCallback(({ variables }: { variables: BionicFetchHealthRecordsQueryVariables }) => {
    emitEvent(
      'Request',
      {
        location: 'health_record',
      },
    );
    getCustomerHealthRecord({ variables });
  }, [getCustomerHealthRecord, emitEvent]);

  return [getCustomerHealthRecordStatusWrapped, result];
}
