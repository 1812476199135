import * as React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import { CopyTextProps } from './CopyText.interfaces';
import { copyToClipboard } from '@healthiqeng/core.util.copy-to-clipboard';
import { Icon } from '../Icon';

export const CopyText: React.FunctionComponent<CopyTextProps> = ({
  text,
  label,
}) => (
  <TextField
    value={text}
    label={label}
    onChange={() => {}}
    fullWidth
    variant="outlined"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            onClick={() => copyToClipboard(text)}
            edge="end"
          >
            <Icon type="copy" />
          </IconButton>
        </InputAdornment>
      ),
    }}
  />
);
