import { useApolloClient } from '@apollo/react-hooks';
import { QUOTE_FRAGMENT, QuoteFragment } from '../../../../../../graphql/fragments/quote';

export function useQuote(hash: string): QuoteFragment {
  const apolloClient = useApolloClient();
  return apolloClient.readFragment({
    id: hash,
    fragment: QUOTE_FRAGMENT,
    fragmentName: 'QuoteFragment',
  });
}
