import * as React from 'react';
import { FormItemTypeFormReferenceProps } from './FormItemTypeFormReference.interfaces';
import { useConditionalRendering } from '../../../useConditionalRendering';

export const FormItemTypeFormReference: React.FunctionComponent<FormItemTypeFormReferenceProps> = ({
  leadId,
  visibleCondition,
  depth,
  FormGeneratorQuestionFlow,
  formId,
  parentQuestionId,
  answers,
  onChange,
}) => {
  const formItemVisible = useConditionalRendering({
    questionId: 'none',
    parentQuestionId,
    answers,
    visibleCondition,
  });

  if (!formItemVisible) return <></>;
  return (
    <FormGeneratorQuestionFlow
      leadId={leadId}
      referenceId={formId}
      depth={depth}
      answers={answers}
      onChange={onChange}
    />
  );
};
