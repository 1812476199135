import { gql } from 'apollo-boost';
import { MutationCustomReturn, useMutationCustom } from '../useMutationCustom';
import {
  BionicCreateSessionResult, BionicCreateSessionVariables,
} from './useBionicCreateSessionMutation.interfaces';

const BIONIC_CREATE_SESSION_MUTATION = gql`
  mutation BionicCreateSessionMutation($leadId: LeadId!) {
    bionicCreateSession(leadId: $leadId) {
      url
      baseUrl
      customerCode
      countyCode
      prospectId
      unsupportedPharmacy {
        npi
        name
        message
      }
    }
  }
`;

export function useBionicCreateSessionMutation(): MutationCustomReturn<
BionicCreateSessionResult,
BionicCreateSessionVariables> {
  return useMutationCustom<BionicCreateSessionResult, BionicCreateSessionVariables>(BIONIC_CREATE_SESSION_MUTATION, {
    refetchQueries: ['BionicFetchHealthRecords'],
  });
}
