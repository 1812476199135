import * as React from 'react';
import {
  OAuthAppsContainer,
  OAuthAppCard,
  OAuthAppIcon,
  OAuthAppLink,
  OAuthAppLabel,
} from './OAuthApps.elements';
import { ListOAuthAppsResponseItem } from '@hiq/crm.types';
import { hiqCrmService, logger } from '../../../../services';

export const OAuthApps: React.FunctionComponent = () => {
  const [apps, setApps] = React.useState<ListOAuthAppsResponseItem[]>([]);
  React.useEffect(() => {
    const fetchApps = async () => {
      const response = await hiqCrmService.listOAuthApps();
      setApps(response.apps);
    };
    fetchApps().catch((err) => logger.crit(err));
  }, []);

  return (
    <OAuthAppsContainer>
      { apps.map((app) => (
        <OAuthAppCard key={app.id}>
          <OAuthAppLink href={hiqCrmService.getOAuthAppUrl(app)}>
            <OAuthAppIcon src={app.icon} />
            <OAuthAppLabel>
              { app.label }
            </OAuthAppLabel>
          </OAuthAppLink>
        </OAuthAppCard>
      )) }
    </OAuthAppsContainer>
  );
};
