import { IValue, ILabelMapping } from './arrayToLabelsValues.interfaces';
import { isPresent } from '@healthiqeng/core.util.is-present';

export function arrayToLabelsValues(arr: IValue[], blankLabel: string = '', labelMapping: ILabelMapping = {}) {
  return arr.map((val) => {
    if (!isPresent(val)) {
      return { label: blankLabel, value: val };
    }
    if (val in labelMapping) {
      return { label: labelMapping[val], value: val };
    }
    return { label: val, value: val };
  });
}
