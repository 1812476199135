import * as React from 'react';
import { FormGeneratorQuestionFlowProps } from './FormGeneratorQuestionFlow.interfaces';
import { useFormQuery } from '../../../../graphql';
import { useFormAnswers, useFormAnswersQuery } from '../../../../hooks';
import { useFormStatus } from '../useFormStatus';
import { QueryLoader } from '../../QueryLoader';
import { FormItemFactory } from './FormItem/FormItemFactory';
import { ComponentName } from '../../Page/PageLoadDetector';

export const FormGeneratorQuestionFlowFC: React.FunctionComponent<FormGeneratorQuestionFlowProps> = ({
  leadId,
  referenceId,
  onFieldsChange,
  depth,
  arrayFormIndex,
  answers: answersOverride,
  onChange: onChangeOverride,
}) => {
  const { data: formStructure, error, loading } = useFormQuery(leadId, referenceId);
  const [answersDefault, onChangeDefault] = useFormAnswers(leadId);
  const createFormStatus = useFormStatus(formStructure);
  const answersQuery = useFormAnswersQuery(leadId);

  const answers = answersOverride ?? answersDefault;
  const onChange = onChangeOverride ?? onChangeDefault;

  // On initial mount or on an answer object change, trigger the field change
  // function so that the parent flow is aware of
  // the current state of the form. This should
  // happen only after saves
  React.useEffect(() => {
    if (onFieldsChange) onFieldsChange(referenceId, createFormStatus(answersQuery));
  }, [answersQuery]);

  return (
    <QueryLoader loading={loading} error={error} componentName={ComponentName.FormGeneratorQuestionFlow}>
      <div>
        { formStructure?.items.map((formItem) => (
          <FormItemFactory
            leadId={leadId}
            key={formItem.questionId ?? formItem.label}
            {...formItem}
            depth={depth ?? 0}
            answers={answers}
            onChange={onChange}
            FormGeneratorQuestionFlow={FormGeneratorQuestionFlow}
            arrayFormIndex={arrayFormIndex}
            formId={formItem.formId ?? referenceId}
            overrideQuestionText={formItem?.overrideQuestionText}
          />
        )) }
      </div>
    </QueryLoader>
  );
};

export const FormGeneratorQuestionFlow = React.memo(FormGeneratorQuestionFlowFC);
