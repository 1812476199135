import { isPresent } from '@healthiqeng/core.util.is-present';
import { InputTransformer } from '../InputTransformer';

export class PhoneTransformer extends InputTransformer<string> {
  constructor(initialValue: string) {
    super(initialValue);
    this.draftValue = initialValue;
  }

  public update(nextValue: string) {
    this.updateDraftValue(nextValue);
    this.updatePersistedValue();
  }

  public getDraftValue(): string {
    return formatPhone(this.draftValue);
  }

  public getPersistedValue(): string {
    return this.persistedValue;
  }

  public getFormattedValue(): string {
    return formatPhone(this.persistedValue);
  }

  private updateDraftValue(nextValue: string) {
    this.draftValue = PhoneTransformer.normalizePhone(nextValue);
  }

  private updatePersistedValue() {
    if ((this.draftValue ?? '') === '') {
      this.persistedValue = null;
    }
    if (/^[0-9]{10}$/.test(this.draftValue ?? '')) {
      this.persistedValue = this.draftValue;
    }
  }

  public static normalizePhone(value: string): string {
    if (!isPresent(value)) {
      return '';
    }

    const cleanedPhone = value.replace(/[^0-9]/g, '');
    if (cleanedPhone.length > 10 && cleanedPhone.startsWith('1')) {
      return cleanedPhone.substring(1);
    }
    return cleanedPhone.substring(0, 10);
  }
}

function formatPhone(value: string): string {
  const normalized = PhoneTransformer.normalizePhone(value);
  if (normalized.length < 10) {
    return normalized;
  }
  return `(${normalized.substring(0, 3)}) ${normalized.substring(3, 6)}-${normalized.substring(6, 10)}`;
}
