import React, { useCallback, useState } from 'react';
import { useCurrentCall } from '@healthiqeng/core.hooks.use-dialer-service';
import { UserRole } from '@hiq/crm.types';
import { VoiceSignatureProps } from './VoiceSignature.interfaces';
import { ConfirmButton } from '../ConfirmButton';
import { useCurrentUserQuery, useCustomerQuery } from '../../../../graphql';
import { Icon } from '../../Icon';
import { useFormAnswers } from '../../../../hooks';
import { ErrorMessage, MarginLeft } from './VoiceSignature.elements';
import { TextInput } from '../TextInput';
import { identityTransformerFactory } from '../transformers';

const voiceSignatureLabelFactory = (loading: boolean, error: Error): string => {
  if (loading) {
    return 'Finding call...';
  }
  if (error) {
    return 'Error communicating with dialer service';
  }
  return 'Voice Signed By Client';
};

const ALL_NON_DIGITS = /\D/g;
const isPhoneNumberEqual = (p1: string, p2: string) => p1.replace(ALL_NON_DIGITS, '') === p2.replace(ALL_NON_DIGITS, '');

export const VoiceSignature: React.FunctionComponent<VoiceSignatureProps> = React.memo<VoiceSignatureProps>(({
  answersKey,
  dataQa,
  disabled,
  onChange,
}) => {
  const {
    callData, error, loading,
  } = useCurrentCall();
  const { data, loading: customerLoading, error: customerError } = useCustomerQuery();
  const [answers] = useFormAnswers();
  const [forcedCallId, setForcedCallId] = useState('');
  const phoneNumber = callData?.phoneNumber;
  const onConfirm = useCallback(() => {
    onChange({
      callId: callData?.id,
      timestamp: new Date().getTime(),
    });
  }, [callData?.id, onChange]);
  const onConfirmForceVoiceSign = useCallback(() => {
    onChange({
      callId: forcedCallId,
      timestamp: new Date().getTime(),
    });
  }, [forcedCallId, onChange]);
  const onChangeForcedCallId = useCallback((value) => setForcedCallId(value as string), [setForcedCallId]);
  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useCurrentUserQuery();
  const canForceVoiceSign = userData?.currentUser?.roles?.includes(UserRole.SeniorLifeManager);
  const callIdQuestionId = `${answersKey}.callId`;
  const timestampQuestionId = `${answersKey}.timestamp`;
  const hasValuesDefined = answers[callIdQuestionId] && answers[timestampQuestionId];

  const forceVoiceSignButton = (
    <ConfirmButton
      cancelText="CANCEL"
      confirmText="CONFIRM"
      disabled={!!(loading || customerLoading)}
      endIcon={hasValuesDefined && <Icon type="done-outlined" color="secondary" size="small" />}
      label="Force Voice Signature"
      onConfirm={onConfirmForceVoiceSign}
      title="Force Voice Signature"
    >
      <ErrorMessage>
        Be warned, this ID will be visible to the customer and will
        <br />
        be used as a voice sign proof on the application PDF.
      </ErrorMessage>
      <div>Call ID</div>
      <TextInput
        value={forcedCallId}
        onChange={onChangeForcedCallId}
        transformerFactory={identityTransformerFactory}
      />
    </ConfirmButton>
  );

  if (!phoneNumber) {
    return canForceVoiceSign
      ? forceVoiceSignButton
      : <div>To view this information, you need to be on a call.</div>;
  }

  const voiceSigError = error || customerError || userError;
  const label = voiceSignatureLabelFactory(loading || customerLoading || userLoading, voiceSigError);
  const dialogContent = isPhoneNumberEqual(data?.customer?.person?.phone?.number || '', phoneNumber)
    ? 'Are you sure?'
    : 'Five9 call record does not match the current lead. Are you sure this is the correct customer?';
  return (
    <div>
      <ConfirmButton
        cancelText="CANCEL"
        confirmText="CONFIRM"
        dataQa={dataQa}
        disabled={!!(loading || customerLoading || voiceSigError || disabled)}
        endIcon={hasValuesDefined && <Icon type="done-outlined" color="secondary" size="small" />}
        label={label}
        onConfirm={onConfirm}
        title="Voice Signature Confirmation"
      >
        {dialogContent}
      </ConfirmButton>
      {canForceVoiceSign && <MarginLeft>{forceVoiceSignButton}</MarginLeft>}
      {voiceSigError && (
        <>
          <ErrorMessage>
            Make sure your softphone is running on the same browser as TRM.
            <br />
            You can continue, but final submission can only be done after gathering the call ID.
          </ErrorMessage>
          <ErrorMessage>
            <span>Error: </span>
            {voiceSigError.message}
          </ErrorMessage>
        </>
      )}
    </div>
  );
});
