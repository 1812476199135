import { gql } from 'apollo-boost';
import { LEAN_ACTIVITY_FRAGMENT } from '../../fragments';
import { MutationCustomReturn, useMutationCustom } from '../useMutationCustom';
import {
  CompleteActivityMutationResponse,
  CompleteActivityMutationVariables,
} from './useCompleteActivityMutation.interfaces';

const COMPLETE_ACTIVITY_MUTATION = gql`
  mutation CompleteActivity($customerId: ID!, $activityHash: ID!) {
    completeActivity(customerId: $customerId, activityHash: $activityHash) {
      code
      success
      message
      timestamp
      type
      activity {
        ...LeanActivityFragment
      }
    }
  }
  ${LEAN_ACTIVITY_FRAGMENT}
`;

export function useCompleteActivityMutation(): MutationCustomReturn<
CompleteActivityMutationResponse,
CompleteActivityMutationVariables> {
  return useMutationCustom<
  CompleteActivityMutationResponse,
  CompleteActivityMutationVariables
  >(COMPLETE_ACTIVITY_MUTATION);
}
