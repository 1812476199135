import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { Icon } from '../../../../Icon';
import {
  DoctorLocationsContainer, InfoIconContainer, TooltipIconContainer, TooltipInlineContainer,
} from './CompareDoctorLocations.elements';
import { CompareDoctorLocationProps } from './CompareDoctorLocations.interfaces';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
  tooltip: {
    backgroundColor: '#FFF',
    maxWidth: 500,
    fontSize: 16,
    fontWeight: 400,
    color: '#000',
    border: '1px solid #989898',
  },
}));

const infoIconStyle: React.CSSProperties = {
  fontSize: 20,
  bottom: 1,
  position: 'relative',
};

export const CompareDoctorLocations = ({ covered, total, locations }: CompareDoctorLocationProps) => {
  const tooltipContent = React.useMemo(() => (
    <div>
      {locations.sort((a, b) => {
        if (a.covered && !b.covered) return -1;
        if (!a.covered && b.covered) return 1;
        return 0; // original order
      }).map(({
        address1, address2, city, state, zip, covered,
      }) => (
        <TooltipInlineContainer covered={covered}>
          <TooltipIconContainer>
            <Icon type={covered ? 'done' : 'clear'} style={{ fontSize: 16 }} />
          </TooltipIconContainer>
          {[address1, address2, city, state, zip].filter((str) => str).join(', ')}
        </TooltipInlineContainer>
      ))}
    </div>
  ), [locations]);

  const toolipStyle = useStyles();

  return (
    <DoctorLocationsContainer>
      <span>
        {covered}
        /
        {total}
        {' '}
        locations in network
      </span>
      <Tooltip title={tooltipContent} classes={toolipStyle}>
        <InfoIconContainer>
          <Icon type="info" style={infoIconStyle} />
        </InfoIconContainer>
      </Tooltip>
    </DoctorLocationsContainer>
  );
};
