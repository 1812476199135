import * as React from 'react';
import { JsonCompatible } from '@healthiqeng/core.types';
import { QuestionProps } from './Question.interfaces';
import { QueryLoader } from '../../../QueryLoader';
import { useQuestionQuery } from '../../../../../graphql';
import { QuestionView } from './QuestionView';
import { useConditionalRendering } from '../../useConditionalRendering';
import { isTextInputQuestionType } from '../../../../../util';

export const Question: React.FunctionComponent<QuestionProps> = ({
  leadId,
  id,
  tags,
  answers,
  onChange,
  editing,
  onEdit,
  lockable,
  visibleCondition,
  locked,
  dataQa,
}) => {
  const { error, loading, data } = useQuestionQuery(leadId, id);

  const handleChange = React.useCallback((value: JsonCompatible) => (onChange(id, value, isTextInputQuestionType(data.type))),
    [id, onChange, data.type]);

  const questionIsVisible = useConditionalRendering({
    questionId: id,
    parentQuestionId: null,
    answers,
    visibleCondition,
  });

  if (!questionIsVisible) {
    return <></>;
  }

  const value = answers[data?.id];
  return (
    <QueryLoader loading={loading} error={error} componentName="Question">
      <QuestionView
        {...data}
        tags={tags}
        value={value}
        onChange={handleChange}
        lockable={lockable}
        editing={editing}
        onEdit={onEdit}
        locked={locked}
        dataQa={dataQa || `question-${data?.id}`}
      />
    </QueryLoader>
  );
};
