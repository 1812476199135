import React from 'react';
import { CompareMedCostsRow, MedCost } from './CompareMedCostsRow';
import { CompareSection } from '../../CompareSection';

type MedCostSection = {
  currentPlanCosts: Array<MedCost>
  comparisonPlanCosts: Array<MedCost>
  name: string
};

export const CompareMedCostSectionRow = ({
  currentPlanCosts, comparisonPlanCosts, name,
}: MedCostSection) => {
  const benefits = React.useMemo(() => {
    const benefits: Record<string, {currentComparisonPlan?: MedCost, currentPlan?: MedCost}> = {};
    currentPlanCosts?.forEach((benefit) => {
      benefits[benefit?.hiqDescription] = {
        currentPlan: benefit,
      };
    });
    comparisonPlanCosts?.forEach((benefit) => {
      benefits[benefit?.hiqDescription] = {
        ...benefits[benefit.hiqDescription],
        currentComparisonPlan: benefit,
      };
    });
    return benefits;
  }, [currentPlanCosts, comparisonPlanCosts]);

  return (
    <CompareSection
      title={name}
      padding="36px"
      fontSize="16px"
      isCollapsable={false}
      noRank
    >
      {Object.entries(benefits)?.map(([hiqDescription, { currentPlan, currentComparisonPlan }]) => (
        <CompareMedCostsRow
          cost={currentComparisonPlan}
          currentPlanCost={currentPlan}
          name={hiqDescription}
        />
      ))}
    </CompareSection>
  );
};
