import { useRef } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { ADDRESS_LOOKUP_FRAGMENT } from '../../fragments';
import { AddressLookupQueryData, AddressLookupQueryVariables } from './useAddressLookupQuery.interfaces';

const ADDRESS_LOOKUP_QUERY = gql`
  query AddressLookup($input: String!, $sessionToken: String, $customerId: String) {
    searchAddress(input: $input, sessionToken: $sessionToken, customerId: $customerId) {
      places {
        ...AddressLookupFragment
      }
    }
  }
  ${ADDRESS_LOOKUP_FRAGMENT}
`;

export function useAddressLookupQuery(customerId?: string) {
  const sessionToken = useRef<string>(`address-lookup-${Date.now()}`);

  return useLazyQuery<AddressLookupQueryData, AddressLookupQueryVariables>(ADDRESS_LOOKUP_QUERY, {
    variables: {
      sessionToken: sessionToken.current,
      customerId,
    },
  });
}
