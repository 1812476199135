import * as React from 'react';
import { ScriptBlockConfirmProps } from './ScriptBlockConfirm.interfaces';
import { Checkbox } from '@material-ui/core';
import {
  ScriptBlockCheckboxContainer,
  ScriptBlockCheckboxText,
  ScriptBlockConfirmContainer,
} from '../../elements';

export const ScriptBlockConfirm: React.FunctionComponent<ScriptBlockConfirmProps> = ({
  isConfirmed,
  onChange,
  confirmText,
}) => (
  <ScriptBlockConfirmContainer>
    <ScriptBlockCheckboxContainer>
      <Checkbox
        checked={isConfirmed}
        onChange={onChange}
      />
    </ScriptBlockCheckboxContainer>
    <ScriptBlockCheckboxText>
      { confirmText ?? 'I acknowledge that I\'ve read this' }
    </ScriptBlockCheckboxText>
  </ScriptBlockConfirmContainer>
);
