import { css } from '@emotion/core';
import styled from '@emotion/styled';
import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';

export const ComponentWrapper = styled.div<{ hidden: boolean }>(({ hidden }) => css`
  display: ${hidden ? 'none' : 'block'};
`);

export const LoadingComponentContainer = styled.div`
  display: flex;
  height: calc(100vh - 180px);
  align-items: center;
  justify-content: center;
`;

export const LoadingComponent = styled.img`
  max-width: 300px;
  max-height: 300px;
`;

export const SunfireIframeContainer = styled.div`
  display: flex;
  height: calc(100vh - 200px);
`;

export const SunfireStyledIframe = styled('iframe')(css`
  display: flex;
  width: 100%;
  height: 100%;
`);

export const SunfireIframeNavigationWrapper = styled.div`
  display: flex;
  align-self: start;
  background: #FFFFFF;
  max-height: 44px;
  align-items: center;
  margin-top: -20px;
  justify-content: space-between;
`;

export const Header = styled('h3')(css`
  font-weight: 700;
  font-size: 22px;
`);

export const MedicareTypeContainer = styled('div')(css`
  width: 300px
`);

export const LinkText = styled.p`
  font-weight: 700;
  font-size: 16px;
  margin: 0px;
  text-decoration-line: underline;
  color: #0075CA;
  cursor: pointer;
`;

export const StyledIconButton = withStyles({
  root: {
    color: '#000000',
  },
})(IconButton);

export const Hidden = styled.div`
  display: none;
`;

export const Flex = styled.div`
  display: flex;
`;

export const SelectPlanTypeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  grid-gap: 4px;
`;
