import { ArrowDropDown } from '@material-ui/icons';
import React from 'react';
import { useTheme } from '@material-ui/core';

export const CompareDropdown = ({ open }: {open: boolean}) => {
  const theme = useTheme();
  const style = React.useMemo(() => ({
    transform: !open ? 'rotate(-90deg)' : 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }
  ), [open, theme]);

  return <ArrowDropDown style={style} />;
};
