import * as React from 'react';
import { useDialerService } from '@healthiqeng/core.hooks.use-dialer-service';
import { DialerTransferContext } from './DialerTransferContext';
import { isEmpty } from '@healthiqeng/core.util.is-empty';
import { Disposition } from '@healthiqeng/core.services.dialer.base';

export const DialerTransferProvider: React.FunctionComponent = ({ children }) => {
  const { dialerService } = useDialerService();
  const [isWaitingForNextCall, setIsWaitingForNextCall] = React.useState(false);
  const [campaignId, setCampaignId] = React.useState<string>();
  const [dispositions, setDispositions] = React.useState([]);
  const [loadingDispositions, setLoadingDispositions] = React.useState(false);
  const [transferError, setTransferError] = React.useState(false);
  const [transferInProgress, setTransferInProgress] = React.useState(false);
  const [selectedDisposition, setSelectedDisposition] = React.useState<Disposition>();

  function waitForNextCall() {
    setIsWaitingForNextCall(true);
  }

  const fetchDispositions = React.useCallback(async () => {
    try {
      if (isEmpty(dispositions)) {
        setLoadingDispositions(true);
        const dispositionsResponse = await dialerService.getDispositions(campaignId);
        // Filtering negative dispositions as those are System Dispositions and shouldn't be showing
        setDispositions(dispositionsResponse.filter((disposition) => +disposition.value > 0));
        setLoadingDispositions(false);
      }
    } catch (err) {
      setTransferError(true);
      setLoadingDispositions(false);
    }
  }, [dispositions, campaignId]);

  const state = {
    isWaitingForNextCall,
    waitForNextCall,
    fetchDispositions,
    dispositions,
    loadingDispositions,
    transferError,
    transferInProgress,
    setTransferInProgress,
    setTransferError,
    setCampaignId,
    campaignId,
    selectedDisposition,
    setSelectedDisposition,
  };

  return (
    <DialerTransferContext.Provider value={state}>
      { children }
    </DialerTransferContext.Provider>
  );
};
