import { gql } from 'apollo-boost';

export const CALENDAR_EVENT_FRAGMENT = gql`
  fragment CalendarEventFragment on CalendarEvent {
      id
      type
      start
      end
      summary
      description
      timeFrame
  }
`;
