import * as React from 'react';
import { DraggableDividerProps } from './DraggableDivider.interfaces';
import {
  DraggableDividerContainer,
  DraggableDividerElement,
} from './DraggableDivider.elements';

export const DraggableDivider: React.FunctionComponent<DraggableDividerProps> = ({
  columnIndex,
  onPositionChange,
}) => {
  const relativePosition = React.useRef(0);

  const onPositionChangeThrottled = throttle((index: number, pageX: number) => {
    const deltaX = pageX - relativePosition.current;
    relativePosition.current = pageX;
    onPositionChange(index, deltaX);
  }, 50);

  const handleMouseMove = React.useMemo(() => (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    onPositionChangeThrottled(columnIndex, event.pageX);
  }, [columnIndex]);

  const handleMouseUp = React.useMemo(() => {
    const handler = (event: React.MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();
      document.removeEventListener('mouseup', handler as unknown as EventListener);
      document.removeEventListener('mousemove', handleMouseMove as unknown as EventListener);
    };
    return handler;
  }, [columnIndex]);

  const handleMouseDown = React.useMemo(() => (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    document.addEventListener('mouseup', handleMouseUp as unknown as EventListener);
    document.addEventListener('mousemove', handleMouseMove as unknown as EventListener);
    relativePosition.current = event.pageX;
  }, [columnIndex]);

  return (
    <DraggableDividerContainer>
      <DraggableDividerElement
        onMouseDown={handleMouseDown}
      />
    </DraggableDividerContainer>
  );
};

function throttle(
  func: (index: number, positionChange: number) => void,
  timeout: number,
) {
  let ready: boolean = true;
  return (index: number, positionChange: number) => {
    if (!ready) {
      return;
    }

    ready = false;
    func(index, positionChange);
    setTimeout(() => {
      ready = true;
    }, timeout);
  };
}
