import { useEffect } from 'react';
import { useDebounce } from '@healthiqeng/core.hooks.use-debounce';
import { DialerCallDirection, FormAnswers, UserProfile } from '@hiq/crm.types';
import { InitialContactCallData } from './useInitialContactRedirect.interfaces';
import { useNavigate } from '@reach/router';
import {
  useAddDialerCallMutation,
  useCustomerByExternalDataQuery,
  useFormAnswersMutation,
  useLazyCustomerQuery,
  useLazyQuestionFlowQuery,
  useLocalFormAnswersMutation,
  useLocalFormAnswersQuery,
  useCurrentUserQuery,
} from '../../../../../graphql';
import { getRedirectUrl, isSalesDevelopmentRep } from '../../../../../util';
import { useSaveAWSFormAnswers } from '../../../../common/FormGenerator/useSaveAWSFormAnswers';
import { JsonCompatible } from '@healthiqeng/core.types';
import * as Sentry from '@sentry/gatsby';
import { useLogSentry } from './useLogSentry';

export function useInitialContactRedirect(callData: InitialContactCallData) {
  const { data: userData } = useCurrentUserQuery();
  const navigate = useNavigate();

  useEffect(() => {
    Sentry.addBreadcrumb({
      message: 'Initial contact redirect mounted',
      timestamp: new Date().getTime(),
    });
    return () => {
      Sentry.addBreadcrumb({
        message: 'Initial contact redirect unmounted',
        timestamp: new Date().getTime(),
      });
    };
  }, []);

  const externalDataInput = {
    fastInsertId: callData?.fastInsertId,
    salesforceId: callData?.salesforceId,
    phoneNumber: callData?.customerPhoneNumber,
    campaignName: callData?.campaignName,
    callSkill: callData?.callSkill,
    firstName: callData?.customerFirstName,
    lastName: callData?.customerLastName,
    leadId: callData?.leadId,
    readFromPrimary: true,
  };
  const {
    data, loading, error,
  } = useCustomerByExternalDataQuery(externalDataInput);

  useLogSentry({
    name: 'useCustomerByExternalDataQuery',
    data,
    loading,
    error,
    extra: externalDataInput,
  });
  const [formAnswersMutation, { data: formAnswersData, loading: formAnswersLoading, error: formAnswersError }] = useFormAnswersMutation();
  useLogSentry({
    name: 'useFormAnswersMutation',
    data: formAnswersData,
    loading: formAnswersLoading,
    error: formAnswersError,
  });
  const saveAnswersDebounced = useDebounce(formAnswersMutation, 400);
  const [saveLocalFormAnswers] = useLocalFormAnswersMutation();
  const [addDialerCall, { data: addDialerCallData, loading: addDialerCallLoading, error: addDialerCallError }] = useAddDialerCallMutation();
  useLogSentry({
    name: 'useAddDialerCallMutation',
    data: addDialerCallData,
    loading: addDialerCallLoading,
    error: addDialerCallError,
  });
  const { customerId, leadId, matchMethod } = data?.customerByExternalData ?? {};
  const callUrlPath = addDialerCallData?.addDialerCall?.dialerCall?.urlPath;
  const userProfile = userData?.currentUser?.profile as UserProfile;
  const url = getRedirectUrl({ ...data?.customerByExternalData, callUrlPath, userProfile });
  const [loadCustomer, { data: customerData, loading: customerLoading, error: customerError }] = useLazyCustomerQuery(customerId);
  useLogSentry({
    name: 'useLazyCustomerQuery',
    data: customerData,
    loading: customerLoading,
    error: customerError,
    extra: { customerId },
  });

  const [
    loadInitialContactQuestionFlow,
    { data: questionFlowData, loading: questionFlowLoading, error: questionFlowError },
  ] = useLazyQuestionFlowQuery(leadId, 'initial-contact', { shallow: false });
  useLogSentry({
    name: 'useLazyQuestionFlowQuery',
    data: questionFlowData,
    loading: questionFlowLoading,
    error: questionFlowError,
    extra: { leadId, referenceId: 'initial-contact', shallow: false },
  });

  const { data: localFormAnswersData } = useLocalFormAnswersQuery();
  const isSDR = isSalesDevelopmentRep(userData?.currentUser?.profile as UserProfile);
  useEffect(() => {
    if (userData && !isSDR) {
      Sentry.addBreadcrumb({
        message: 'Navigating to agent home, user not SDR',
        type: 'navigation',
        data: {
          userId: userData.currentUser?.id,
          userProfile: userData.currentUser?.profile,
          userName: userData.currentUser?.name?.fullFriendlyName,
          userAbValue: userData.currentUser?.abValue,
        },
      });
      navigate(url);
    }
  }, [userData]);

  useEffect(() => {
    if (callData && userData && isSDR) {
      saveLocalFormAnswers({
        answers: {
          'customer.person.name.last': callData.customerLastName,
          'customer.person.name.first': callData.customerFirstName,
          'lead.five9State': callData.customerState,
          'customer.person.phone.number': callData.customerPhoneNumber,
        },
      });
    }
  }, [callData?.customerLastName, callData?.customerFirstName, callData?.customerState, callData?.customerPhoneNumber, userData]);

  useEffect(() => {
    if (customerId && leadId && userData) {
      const mutationVariables = {
        customerId,
        input: {
          ...getDialerCallDetailsInput(callData),
          customerMatchMethod: matchMethod,
        },
      };
      Sentry.addBreadcrumb({
        message: 'Adding DialerCall',
        data: mutationVariables,
      });
      addDialerCall(mutationVariables);
      loadCustomer();
      if (isSDR) {
        loadInitialContactQuestionFlow();
      }
    }
  }, [customerId, leadId, matchMethod, userData]);

  const saveAWSAnswersDebounced = useDebounce(useSaveAWSFormAnswers, 500);
  // As soon as we get the callUrlPath, we start saving the answers to AWS
  useEffect(() => {
    if (callUrlPath && localFormAnswersData?.localFormAnswers) {
      saveAWSAnswersDebounced(leadId, callUrlPath, localFormAnswersData.localFormAnswers, {
        callData: (callData as any) as JsonCompatible,
        timestamp: new Date().getTime(),
        userId: userData?.currentUser?.id,
      });
    }
  }, [leadId, callUrlPath, localFormAnswersData, callData, userData]);

  useEffect(() => {
    if (customerData && localFormAnswersData && (addDialerCallData || addDialerCallError)) {
      // Now that we have the leadId, we can save the local call answers before navigating
      const { localFormAnswers } = localFormAnswersData;
      const callAnswers = Object.keys(localFormAnswers).reduce((answers, key) => {
        if (key.startsWith('call.')) {
          return {
            ...answers,
            [key]: localFormAnswers[key],
          };
        }

        if (key.startsWith('lead.five9State')) {
          return {
            ...answers,
            [key]: localFormAnswers[key],
          };
        }

        return answers;
      }, {} as FormAnswers);
      if (Object.keys(callAnswers).length === 0) {
        Sentry.addBreadcrumb({
          message: 'Navigating to customer route, no answers to be saved',
        });
        navigate(url);
      } else {
        const mutationVariables = {
          leadId,
          callUrlPath,
          answers: callAnswers,
        };
        Sentry.addBreadcrumb({
          message: 'Saving local answers',
          data: mutationVariables,
        });
        saveAnswersDebounced(mutationVariables);
      }
    }
  }, [customerData, questionFlowData, addDialerCallData, localFormAnswersData]);

  useEffect(() => {
    if (formAnswersData) {
      Sentry.addBreadcrumb({
        message: 'Local answers saved, navigating to customer home',
        data: {
          url,
          saveFormAnswersResponse: formAnswersData,
        },
      });
      navigate(url);
    }
  }, [formAnswersData]);

  return {
    loading,
    customerError: error || customerError,
    error: error || addDialerCallError || questionFlowError,
  };
}

function getDialerCallDetailsInput(callData: InitialContactCallData) {
  return {
    crmId: callData.salesforceId?.trim(),
    phoneNumber: callData.customerPhoneNumber?.trim(),
    sessionId: callData.sessionId?.trim(),
    campaignName: callData.campaignName?.trim(),
    externalCallId: callData.callId?.trim(),
    timestamp: callData.startTimestamp ? callData.startTimestamp : undefined,
    direction: callData.direction as DialerCallDirection,
    type: callData.type?.trim(),
    fastInsertId: callData.fastInsertId?.trim(),
    callSkill: callData?.callSkill?.trim(),
    domain: callData?.domain?.trim(),
  };
}
