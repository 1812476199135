import styled from '@emotion/styled';
import { css } from '@emotion/core';

export const DementiaFlagContainer = styled('div')(css`
  color: black;
  display: flex;
  padding: 4px;
  margin: 10px 0px;
  background-color: #FFEA7E;
  margin-left: 10px;
  border-radius: 25px;
  min-width: 100px;
  text-align: center;
  display: flex;
  font-size: 14px !important;
  align-items: center;
  cursor: pointer
`);

export const DementiaFlagText = styled('span')(css`
  font-size: 14px;
  margin-left: 3px;
  margin-right: 3px;
`);
