import { emitAnalyticsEventNoLimit } from '../../../../../events/emitAnalyticsEvent';
import React from 'react';
import { useActiveLeadId, useCustomerId } from '../../../../routers';
import { getEventBus, SunfireV2DataChangeEvent } from '../../../../../events';

/**
* This function creates a React row with selecting option
* @param getRowId a function which return the row identifier
* @param selectedRows a list of the <D> that are currently selected
* @param onSelect a function that will be invoked when an element is selected/unselected
* @param isMultiSelect a boolean that identifies if many rows can be selected
* @param eventType a string for identifying the type of item that the user is (de)selecting for logging purposes
*/
export function useSelectedRows<D>(
  getRowId: (data: Partial<D>) => string,
  selectedRows: Partial<D>[],
  onSelect: (k: any) => void,
  isMultiSelect: boolean,
  eventType?: string,
): [string[], (data?: Partial<D> | D) => void] {
  const eventBus = getEventBus();
  const customerId = useCustomerId();
  const leadId = useActiveLeadId();

  const selectedRowIds: string[] = React.useMemo(
    () => selectedRows.map((row) => getRowId(row as D)),
    [selectedRows, getRowId],
  );

  const emitEvent = (isInclude:boolean, data:Partial<D>) => {
    const action = isInclude ? 'Selecting ' : 'Deselecting ';
    emitAnalyticsEventNoLimit(action + eventType, {
      ...data, customerId, leadId, count: selectedRows?.length,
    });
  };

  const onSelectionChange = React.useCallback((...data: Partial<D>[]) => {
    const selectionArray = [...selectedRows];
    if (isMultiSelect) {
      data.forEach((current) => {
        const rowId = getRowId(current);
        const idx = selectionArray.findIndex((selected) => getRowId(selected) === rowId);
        if (idx === -1) {
          selectionArray.push(current);
          emitEvent(true, current);
        } else {
          const [oldItem] = selectionArray.splice(idx, 1);
          emitEvent(false, oldItem);
        }
        onSelect(current);
      });
    } else {
      const isSelect = !!data[0];
      emitEvent(isSelect, data[0] || selectionArray[0]);
      onSelect(data[0]);
    }
    eventBus.emit(new SunfireV2DataChangeEvent());
  }, [getRowId, selectedRows, isMultiSelect]);

  return [selectedRowIds, onSelectionChange];
}
