import * as React from 'react';
import { AutomatedUnderwritingResultProps } from './AutomatedUnderwritingResult.interfaces';
import {
  ApprovalMessageAsApplied,
  ApprovalMessageDeclined,
  ApprovalMessageOther,
  AutomatedUnderwritingError,
  AutomatedUnderwritingResultContainer,
  QuoteContainer,
} from './AutomatedUnderwritingResult.elements';
import { ApprovalStatus } from '@healthiqeng/core.types';
import { QuoteCellFactory } from '../../../quoting/QuoteCellFactory';

export const AutomatedUnderwritingResult: React.FunctionComponent<AutomatedUnderwritingResultProps> = ({
  errors,
  approval,
  productType,
  quoteHash,
  approvedUnderwritingClass,
}) => (
  <AutomatedUnderwritingResultContainer>
    { approval !== ApprovalStatus.Declined && (
      <QuoteContainer>
        <QuoteCellFactory type={productType} hash={quoteHash} selectable={false} />
      </QuoteContainer>
    ) }
    <ApprovalMessage approval={approval} approvedUnderwritingClass={approvedUnderwritingClass} />
    { errors?.map((message) => (
      <AutomatedUnderwritingError key={message}>
        { message }
      </AutomatedUnderwritingError>
    )) }
  </AutomatedUnderwritingResultContainer>
);

function ApprovalMessage({
  approval,
  approvedUnderwritingClass,
}: {
  approval: ApprovalStatus,
  approvedUnderwritingClass: string,
}) {
  switch (approval) {
    case ApprovalStatus.Declined:
      return (
        <ApprovalMessageDeclined>
          Declined
        </ApprovalMessageDeclined>
      );
    case ApprovalStatus.ApprovedAsApplied:
      return (
        <ApprovalMessageAsApplied>
          Qualified for
          {' '}
          { approvedUnderwritingClass }
        </ApprovalMessageAsApplied>
      );
    case ApprovalStatus.ApprovedOtherThanApplied:
      return (
        <ApprovalMessageOther>
          Qualified for
          {' '}
          { approvedUnderwritingClass }
        </ApprovalMessageOther>
      );
    default:
      return <></>;
  }
}
