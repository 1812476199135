import * as React from 'react';
import { useMostRecentAppointmentsQuery } from '../../../graphql/queries/useMostRecentAppointmentsQuery';
import { ApptNotifModalContent } from './ApptNotifModalContent';
import { GenericModal } from '../GenericModal';
import { useDialerService } from '@healthiqeng/core.hooks.use-dialer-service';
import { useCurrentUserIsAgent } from '../../../hooks';

export const AppointmentNotification: React.FunctionComponent = () => {
  const { data: appointments } = useMostRecentAppointmentsQuery();
  const [isModalOpen, setIsModalOpen] = React.useState(true);
  const { dialerService } = useDialerService();
  const defaultCampaign = 'Health IQ Manual Outbound';
  const [isCallInProgress, setIsCallInProgress] = React.useState(false);
  const isAgent = useCurrentUserIsAgent();

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleSave = async () => {
    try {
      const number = appointments?.nextAgentAppointment?.lead?.customer.person?.phone?.number;
      await dialerService.makeExternalCall(number, defaultCampaign);
      setIsModalOpen(false);
    } catch (e) {
      setIsModalOpen(false);
    }
  };

  React.useEffect(() => {
    const callInProgress = dialerService?.currentCallInProgress() ?? false;
    setIsCallInProgress(callInProgress);
  }, [appointments]);

  const name = appointments?.nextAgentAppointment?.lead?.customer?.person?.name;
  const nextAppt15Min = (+(new Date(appointments?.nextAgentAppointment?.appointmentTime))
    - Date.now()) / 60000 <= 15;

  if (isAgent) {
    return (
      <GenericModal
        open={isModalOpen && !isCallInProgress && nextAppt15Min}
        headerText="Upcoming appointment"
        modalContent={(
          <ApptNotifModalContent
            onClose={handleClose}
            onClickSave={handleSave}
            clientName={`${name?.first} ${name?.last}`}
            appointmentTime={new Date(appointments?.nextAgentAppointment?.appointmentTime).toLocaleDateString('en-US',
              {
                weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric',
              })}
          />
        )}
      />
    );
  }
  return (<></>);
};
