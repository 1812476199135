import { useState } from 'react';
import { ClearAnswersFunction } from '../../../../../hooks';
import { UseLockableFormHook } from './useLockableForm.interfaces';

export const useLockableForm: UseLockableFormHook = (clearState: ClearAnswersFunction) => {
  const [editing, setEditing] = useState(false);
  const toggleEditing = () => setEditing((prevEditable) => !prevEditable);

  const handleSave = () => {
    toggleEditing();
  };

  const handleClear = () => {
    setEditing(false);
    clearState();
  };

  return [editing, toggleEditing, handleSave, handleClear];
};
