import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const QuoteDetailsInfoContainer = styled('div')(css`
   margin: 0;
`);
export const QuoteInfo = styled('p')(css`
  margin: 0;
  display: inline-block;
`);
export const QuoteInfoLabel = styled('p')(css`
  margin: 0;
  margin-right: 4px;
  display: inline-block;
  font-weight: bold;
`);
