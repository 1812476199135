export function shortenLabel(label: string): string {
  const CUTOFF = 15;
  if (!label) {
    return '';
  }
  if (label.length < CUTOFF) {
    return label;
  }
  return `${label.substr(0, CUTOFF - 3)}...`;
}
