import * as React from 'react';
import {
  NewCrossSaleContainer, NewCrossSaleHeader,
} from './NewCrossSale.elements';
import { NewCrossSaleProps } from './NewCrossSale.interfaces';
import { CrossSaleCreateSuccessEvent } from '../../../graphql';
import { useEventListener } from '../../../events/useEventListener';
import { useActiveLeadId, useCustomerId, useLeadIdRoute } from '../../routers';
import { NewCrossSaleForm } from '../../common/NewCrossSaleForm';
import { onCrossSaleSuccess } from '../../../listeners';

export const NewCrossSale: React.FunctionComponent<NewCrossSaleProps> = ({
  showMaAndPdpOptions,
}) => {
  const customerId = useCustomerId();
  const leadId = useActiveLeadId();
  const [, routeToLead] = useLeadIdRoute();
  useEventListener(CrossSaleCreateSuccessEvent, onCrossSaleSuccess(routeToLead));
  return (
    <NewCrossSaleContainer>
      <NewCrossSaleHeader>
        New Cross Sale
      </NewCrossSaleHeader>
      <NewCrossSaleForm
        customerId={customerId}
        leadId={leadId}
        showMaAndPdpOptions={showMaAndPdpOptions}
      />
    </NewCrossSaleContainer>
  );
};
