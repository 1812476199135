import { QuotePresentationType } from '@hiq/crm.types';
import { useQuote } from './useQuote';
import { useActiveLeadId } from '../../../../routers';
import { useUnselectQuoteMutation, useSelectQuoteMutation } from '../../../../../graphql';

export function useQuoteCell(hash: string) {
  const quote = useQuote(hash);
  const leadId = useActiveLeadId();
  const [
    unselectQuote,
    { loading: unselectLoading, error: unselectError },
  ] = useUnselectQuoteMutation();
  const [
    selectQuote,
    { loading: selectLoading, error: selectError },
  ] = useSelectQuoteMutation();
  const isSelected = quote?.presentationType === QuotePresentationType.Selected;
  const handleSelectClick = () => {
    if (isSelected) {
      unselectQuote({
        leadId,
        hash,
      });
      return;
    }
    selectQuote({
      leadId,
      hash,
    });
  };
  return {
    quote,
    handleSelectClick,
    isSelected,
    loading: unselectLoading || selectLoading,
    error: unselectError || selectError,
  };
}
