import { UserInteractionType } from '@hiq/crm.types';
import { MutationCustomReturn } from '../useMutationCustom';
import { useLogInteraction } from './useLogInteraction';
import {
  LogTabChangeInteractionOptions,
  LogUserInteractionMutationResponse,
} from './useLogUserInteractionMutation.interfaces';

export function useLogTabChange(): MutationCustomReturn<LogUserInteractionMutationResponse, LogTabChangeInteractionOptions> {
  const [logInteraction, result] = useLogInteraction();
  const logTabChangeInteraction = ({ tab, detail }: LogTabChangeInteractionOptions) => logInteraction({
    type: UserInteractionType.TabChange,
    target: tab,
    detail,
  });

  return [
    logTabChangeInteraction,
    result,
  ];
}
