import styled from '@emotion/styled';
import { css } from '@emotion/core';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 8px;
  border-radius: 8px;
  border: 1px solid #0075CA;
`;

export const Header = styled.div<{ collapsed?: boolean }>(({ collapsed }) => css`
  padding: 8px 12px;
  border-radius: ${collapsed ? '8px;' : '8px 8px 0px 0px;'}
  cursor: pointer;
  background: #0075CA;
  color: white;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
`);

export const Description = styled.div`
  flex-grow: 2;
`;
