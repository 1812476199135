import { isPresent } from '@healthiqeng/core.util.is-present';
import { MedicareApplicationType } from '@hiq/crm.types';
import { useLeadQuery } from '../../../../../graphql';
import { useActiveLeadId } from '../../../../routers/LeadRouter';
import { UnderwritingValidationResult } from './useUnderwritingValidation.interfaces';

const REQUIRED_FIELD_WARNINGS: Record<string, string> = {
  height: 'Missing applicant height',
  weight: 'Missing applicant weight',
  gender: 'Missing applicant gender',
  ssn: 'Missing applicant ssn',
  first_name: 'Missing applicant first name',
  last_name: 'Missing applicant last name',
  dob: 'Missing applicant date of birth',
  address_state: 'Missing applicant address state',
  zip5: 'Missing applicant address zip code',
  address_line1: 'Missing applicant address line',
  address_city: 'Missing applicant address city',
  applicant_phone: 'Missing applicant phone',
};

export const useUnderwritingValidation = (): UnderwritingValidationResult => {
  const leadId = useActiveLeadId();
  const { data: leadData, loading: loadingLeadData } = useLeadQuery(leadId);
  const answers = leadData?.questionFlowAnswers;
  const applicationType = (leadData as any)?.applicationType;

  let missingApplicationTypeWarning;
  if (
    applicationType !== MedicareApplicationType.GuaranteedIssue
    && applicationType !== MedicareApplicationType.OpenEnrollment
    && applicationType !== MedicareApplicationType.Underwritten
  ) {
    missingApplicationTypeWarning = 'Must select an application type';
  }

  const requiredFields = (leadData as any).applicationType === MedicareApplicationType.Underwritten
    ? Object.keys(REQUIRED_FIELD_WARNINGS)
    : Object.keys(REQUIRED_FIELD_WARNINGS).filter((key) => ![
      'height',
      'weight',
    ].includes(key));

  const missingFields = requiredFields
    .map((field) => [field, answers.csg?.application?.[field]])
    .filter(([, value]) => !isPresent(value));

  const warnings = [
    missingApplicationTypeWarning,
    ...missingFields.map(([field]) => REQUIRED_FIELD_WARNINGS[field]),
  ].filter(Boolean);

  return {
    loading: loadingLeadData,
    valid: missingFields.length === 0,
    warnings,
  };
};
