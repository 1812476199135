export abstract class InputTransformer<T> {
  protected persistedValue: T;
  protected draftValue: string;

  protected constructor(initialValue: T) {
    this.persistedValue = initialValue;
  }

  public abstract getPersistedValue(): T;
  public abstract getDraftValue(): string;
  public abstract update(value: string): void;
  public abstract getFormattedValue(): string;
}

export type InputTransformerFactory<T> = (value: T) => InputTransformer<T>;
