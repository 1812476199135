import * as React from 'react';
import Tab from '@material-ui/core/Tab';
import Tooltip from '@material-ui/core/Tooltip';
import { SidePanelTabProps } from './SidePanelTab.interfaces';
import { Icon } from '../../../common/Icon';

export const SidePanelTab: React.FunctionComponent<SidePanelTabProps> = ({
  id,
  tooltip,
  icon,
  index,
  onClick,
  active,
}) => {
  const handleClick = () => {
    onClick(index);
  };

  return (
    <div style={{ position: 'relative', left: '-5px' }}>
      <Tooltip title={tooltip} arrow placement="right">
        <Tab
          style={{ minWidth: '100%' }}
          key={id}
          icon={(
            <Icon
              type={icon}
              color={active ? undefined : 'disabled'}
            />
          )}
          onClick={handleClick}
          data-qa={`side-panel-layout-tab-button-${id}`}
        />
      </Tooltip>
    </div>
  );
};
