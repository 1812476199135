import { gql } from 'apollo-boost';
import { FORM_ANSWERS_VALIDATION_ITEM_FRAGMENT } from '../formAnswersValidationItem';

export const CONVERT_RESULT_FRAGMENT = gql`
  fragment ConvertResultFragment on ConvertResult {
    success
    errors {
      ...FormAnswersValidationItemFragment
    }
    warnings {
      ...FormAnswersValidationItemFragment
    }
  }
  ${FORM_ANSWERS_VALIDATION_ITEM_FRAGMENT}
`;
