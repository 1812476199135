import { Menu, MenuProps, withStyles } from '@material-ui/core';
import React from 'react';

export const StyledMenu = withStyles({
  paper: {
    border: '1px solid #cfcfcf',
    minWidth: 200,
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));
