import { UserInteractionType } from '@hiq/crm.types';
import { MutationCustomReturn } from '../useMutationCustom';
import { useLogInteraction } from './useLogInteraction';
import {
  LogPageLoadInteractionOptions,
  LogUserInteractionMutationResponse,
} from './useLogUserInteractionMutation.interfaces';

export function useLogPageLoad(): MutationCustomReturn<LogUserInteractionMutationResponse, LogPageLoadInteractionOptions> {
  const [logInteraction, result] = useLogInteraction();
  const logPageLoadInteraction = ({ page, detail }: LogPageLoadInteractionOptions) => logInteraction({
    type: UserInteractionType.PageLoad,
    target: page,
    detail,
  });

  return [
    logPageLoadInteraction,
    result,
  ];
}
