import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { ComponentWithThemeProps } from '../../../../../../../../themes';

export const NoQuoteContainer = styled('div')(css``);
export const NoQuoteHeader = styled('p')(css`
  font-weight: 600;
  margin: 0;
`);
export const ErrorsHeader = styled('p')<ComponentWithThemeProps>(({ theme }) => css`
  color: ${theme.color.error};
  font-weight: 600;
  margin: 0;
`);
