import { gql } from 'apollo-boost';
import { useMutationCustom } from '../useMutationCustom';
import {
  SendQuoteEmailMutationResponse,
  SendQuoteEmailMutationVariables,
} from './useSendQuoteEmailMutation.interfaces';

const SEND_QUOTES_EMAIL_MUTATION = gql`
  mutation SendQuotesEmail($leadId: ID!) {
    sendQuotesEmail(leadId: $leadId) {
      code
      success
      message
      timestamp
    }
  }
`;

export function useSendQuoteEmail() {
  return useMutationCustom<SendQuoteEmailMutationResponse, SendQuoteEmailMutationVariables>(SEND_QUOTES_EMAIL_MUTATION);
}
