import { gql } from 'apollo-boost';
import { useCallback } from 'react';
import { SelectQuoteMutationResponse, SelectQuoteMutationVariables } from './useSelectQuoteMutation.interfaces';
import { eventBus } from '../../../services';
import { QuoteSelectedEvent, QuoteSelectFailedEvent, QuoteSelectStartEvent } from './events';
import { MutationCustomReturn, useMutationCustom } from '../useMutationCustom';
import { LEAD_FRAGMENT, QUOTE_FRAGMENT } from '../../fragments';
import { VariationChangeEvent } from '../../../events';

const SELECT_QUOTE_MUTATION = gql`
    mutation SelectQuote($leadId: ID!, $hash: ID!) {
      selectQuote(leadId: $leadId, hash: $hash) {
        code
        success
        message
        timestamp
        quotes {
          ...QuoteFragment
        }
        lead {
          ...LeadFragment
        }
      }
    }
    ${QUOTE_FRAGMENT}
    ${LEAD_FRAGMENT}
`;

export function useSelectQuoteMutation(): MutationCustomReturn<SelectQuoteMutationResponse, SelectQuoteMutationVariables> {
  const [selectQuote, result] = useMutationCustom<SelectQuoteMutationResponse, SelectQuoteMutationVariables>(SELECT_QUOTE_MUTATION, {
    update: (cache, { data }) => {
      if (!data?.selectQuote?.success) return;
      cache.writeFragment({
        data: data.selectQuote.lead,
        fragment: LEAD_FRAGMENT,
        fragmentName: 'LeadFragment',
      });
    },
  });
  const selectQuoteWrapped = useCallback(async (variables: SelectQuoteMutationVariables) => {
    eventBus.emit(new QuoteSelectStartEvent());
    const response = await selectQuote(variables);
    if (response?.data?.selectQuote?.success) {
      eventBus.emit(new QuoteSelectedEvent());
      eventBus.emit(new VariationChangeEvent());
    } else {
      eventBus.emit(new QuoteSelectFailedEvent());
    }
    return response;
  }, [selectQuote]);

  return [selectQuoteWrapped, result];
}
