import React from 'react';
import { CellProps, Renderer } from 'react-table';
import { numberTransformerFactory } from '../../../inputs/transformers';
import { TextInput } from '../../../inputs/TextInput';
import { noop } from '../../../../../util';

export function NumberCell <V extends Object>({ value }: CellProps<V, number>): Renderer<CellProps<V, number>> {
  return (
    <TextInput
      value={value}
      onChange={noop}
      transformerFactory={numberTransformerFactory}
      disabled
    />
  );
}
