import styled from '@emotion/styled';
import { Card } from '@material-ui/core';

export const HealthRecordGridContainer = styled.div`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  background-color: #F3F5F7;
`;

export const GridTitle = styled.h4`
  font-size: 14px;
  color: #4A4A4A;
  margin: 5px 0;
`;

export const GridContent = styled(Card)`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
`;

export const GridCenteredContent = styled(GridContent)`
  justify-content: center;
`;
