import { Alert } from '@material-ui/lab';
import React from 'react';
import { HealthRecordErrorMessageProps } from './HealthRecordErrorMessage.interfaces';
import { Padding } from './HealthRecordErrorMessage.elements';

export const HealthRecordErrorMessage = ({ message, severity = 'error' }: HealthRecordErrorMessageProps) => (
  <Padding>
    <Alert severity={severity}>{message}</Alert>
  </Padding>
);
