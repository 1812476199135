import * as React from 'react';
import moment from 'moment';
import { ActivityType, requirementTypeLabelMap } from '@hiq/crm.types';
import { ActivityFactoryProps } from './ActivityFactory.interfaces';
import { ActivityLayout } from './ActivityLayout';
import { UserFragment } from '../../../../../graphql/fragments/user';
import { Icon } from '../../../../common/Icon';
import { useCompleteActivityMutation } from '../../../../../graphql';
import { useCustomerId } from '../../../../routers/CustomerRouter';
import { FormSkeleton } from '../../../../common/FormSkeleton';

export const ActivityFactory: React.FunctionComponent<ActivityFactoryProps> = React.memo(({
  type,
  timestamp,
  createdBy,
  hash,
  subject,
  body,
  requirementType,
  completedAt,
  isCompleted,
  assignedTo,
  appointmentTime,
  isLast,
}) => {
  const [completeActivity, { loading }] = useCompleteActivityMutation();
  const customerId = useCustomerId();
  if (loading) {
    return <FormSkeleton />;
  }
  switch (type) {
    case ActivityType.Note:
      return (
        <ActivityLayout
          header={createdBy?.name?.fullFriendlyName}
          headerDetail={formatTime(timestamp)}
          avatar={getInitials(createdBy)}
          body={body}
          isLast={isLast}
        />
      );
    case ActivityType.Call:
      return (
        <ActivityLayout
          header={body && getCallHeader(body)}
          headerDetail={formatTime(timestamp)}
          avatar={<Icon type="call-end" size="small" />}
          body={body && getCallBody(body)}
          isLast={isLast}
        />
      );
    case ActivityType.Requirement:
      return (
        <ActivityLayout
          header={createdBy?.name?.fullFriendlyName}
          headerDetail={formatTime(timestamp)}
          avatar={isCompleted
            ? <Icon type="assignment-turned-in" size="small" />
            : <Icon type="assignment-late" size="small" />}
          avatarColor={isCompleted ? 'accent' : 'warning'}
          subHeader={mapLabel(requirementTypeLabelMap, requirementType)}
          body={body}
          checked={isCompleted}
          completedAt={completedAt && formatFullDate(completedAt)}
          onCheckChanged={() => completeActivity({ customerId, activityHash: hash })}
          isLast={isLast}
        />
      );
    case ActivityType.Task:
      return (
        <ActivityLayout
          header={createdBy?.name?.fullFriendlyName}
          headerDetail={formatTime(timestamp)}
          subHeader={`@${assignedTo?.name?.fullFriendlyName ?? 'anybody'}`}
          avatar={isCompleted
            ? <Icon type="assignment-turned-in" size="small" />
            : <Icon type="assignment-ind" size="small" />}
          avatarColor={isCompleted ? 'accent' : 'warningAccent'}
          body={body}
          checked={isCompleted}
          completedAt={completedAt && formatFullDate(completedAt)}
          onCheckChanged={() => completeActivity({ customerId, activityHash: hash })}
          isLast={isLast}
        />
      );
    case ActivityType.EmailMessage:
      return (
        <ActivityLayout
          header={createdBy?.name?.fullFriendlyName}
          headerDetail={formatTime(timestamp)}
          avatar={<Icon type="email" size="small" />}
          subHeader={subject}
          body={body}
          isLast={isLast}
        />
      );
    case ActivityType.TextMessage:
      return (
        <ActivityLayout
          header={createdBy?.name?.fullFriendlyName}
          headerDetail={formatTime(timestamp)}
          avatar={<Icon type="sms" size="small" />}
          body={body}
          isLast={isLast}
        />
      );
    case ActivityType.FollowUp:
      return (
        <ActivityLayout
          header={createdBy?.name?.fullFriendlyName}
          headerDetail={formatTime(timestamp)}
          avatar={<Icon type="event-note" size="small" />}
          body={`Follow-up scheduled for ${formatFullDate(appointmentTime)}`}
          isLast={isLast}
        />
      );
    case ActivityType.ScratchPad:
      return (
        <ActivityLayout
          header={createdBy?.name?.fullFriendlyName}
          headerDetail={formatTime(timestamp)}
          avatar={<Icon type="assignment" size="small" />}
          body={body}
          isLast={isLast}
        />
      );
    default:
      return null;
  }
});

function getInitials(user: UserFragment) {
  const firstInitial = user?.name?.first?.[0] ?? '';
  const lastInitial = user?.name?.last?.[0] ?? '';
  return `${firstInitial}${lastInitial}`;
}

function formatTime(timestamp: string) {
  return moment(timestamp).format('h:mma');
}

function formatFullDate(timestamp: string) {
  return moment(timestamp).format('ddd, MMM Do YYYY h:mma');
}

function mapLabel(map: any, label: string) {
  if (label in map) {
    return map[label];
  }
  return label;
}

function getCallBody(body: string) {
  const bodySections: string [] = body.split(' | ');
  const newBodySections: string[] = [];
  bodySections.forEach((section) => {
    if (section.startsWith('Call Duration:')) {
      newBodySections.push(section);
    }
  });
  return newBodySections.join(' | ');
}

function getCallHeader(body: string) {
  const bodySections: string [] = body.split(' | ');
  const header: string[] = [];
  bodySections.forEach((section) => {
    if (section.startsWith('Call Agent Name:')) {
      header.push(section.split(':')[1].trim());
    }
  });
  return header.length ? header.join(' | ') : 'Dialer Call';
}
