import React from 'react';
import { QuotePresentationType } from '@hiq/crm.types';
import { QuoteSelectorProps } from './QuoteSelector.interfaces';
import { QuoteCard } from '../QuoteCard';
import { PinnedQuotesHeaderContainer } from './QuoteSelector.elements';
import { Card } from '../../Card';
import { useAddQuoteHistoryMutation, usePinnedQuotesQuery, useRemoveQuoteHistoryMutation } from '../../../../graphql';
import { useActiveLeadId } from '../../../routers';
import { QueryLoader } from '../../QueryLoader';
import { SendQuoteEmailButton } from './SendQuoteEmailButton';

export const QuoteSelector = ({
  quoteResults,
  isHighlight,
  showSendEmailButton = false,
  preQuotesComponent,
}: QuoteSelectorProps) => {
  const leadId = useActiveLeadId();
  const { loading, error, data: pinnedQuotes } = usePinnedQuotesQuery(leadId);
  const [addQuoteHistory, { loading: addQuoteLoading, error: addQuoteError }] = useAddQuoteHistoryMutation();
  const [removeQuoteHistory, { loading: removeQuoteLoading, error: removeQuoteError }] = useRemoveQuoteHistoryMutation();
  return (
    <QueryLoader
      loading={loading || addQuoteLoading || removeQuoteLoading}
      error={error || addQuoteError || removeQuoteError}
      componentName="QuoteSelector"
    >
      { preQuotesComponent }
      {pinnedQuotes?.length > 0 && (
        <Card margin="20px 0" padding="20px">
          <PinnedQuotesHeaderContainer>
            <h2>Pinned Quotes</h2>
            { showSendEmailButton && <SendQuoteEmailButton />}
          </PinnedQuotesHeaderContainer>
          { pinnedQuotes.map((pinnedQuote) => (
            <QuoteCard
              key={pinnedQuote.hash}
              quote={pinnedQuote}
              notes={[]}
              highlight={isHighlight(pinnedQuote)}
              onPin={() => {
                removeQuoteHistory({ leadId, hash: pinnedQuote.hash, type: QuotePresentationType.Pinned });
              }}
              isPinned
            />
          ))}
        </Card>
      )}
      { quoteResults?.map(({ quote, notes }) => (
        <QuoteCard
          quote={quote}
          notes={notes}
          highlight={isHighlight(quote)}
          onPin={() => {
            addQuoteHistory({ leadId, hash: quote.hash, type: QuotePresentationType.Pinned });
          }}
          isPinned={false}
        />
      ))}
    </QueryLoader>
  );
};
