import * as React from 'react';
import { FlexibleScriptProps, FlexibleScriptVariationType } from './FlexibleScript.interfaces';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import Box from '@material-ui/core/Box';
import { ThemeLight } from '../../../../themes';
import {
  FlexibleScriptInnerContainer,
  FlexibleScriptTextContainer,
  GrayText,
  PurpleText,
  RedText,
} from './FlexibleScript.elements';

export const FlexibleScript: React.FunctionComponent<FlexibleScriptProps> = ({ children, variation }) => (
  <FlexibleScriptContainer variation={variation}>
    <FlexibleScriptInnerContainer>
      <ChatBubble variation={variation} />
      <FlexibleScriptHeader variation={variation} />
    </FlexibleScriptInnerContainer>
    <FlexibleScriptTextContainer variation={variation}>{children}</FlexibleScriptTextContainer>
  </FlexibleScriptContainer>
);

type FlexibleScriptComponentProps = {
  variation: FlexibleScriptVariationType;
};

const ChatBubble: React.FC<FlexibleScriptComponentProps> = ({ variation }) => {
  let color = 'gray';

  switch (variation) {
    case FlexibleScriptVariationType.REBUTTAL:
      color = ThemeLight.color.purpleLight;
      break;
    case FlexibleScriptVariationType.READ_HIGHLIGHTED_EXACT:
    case FlexibleScriptVariationType.READ_EXACTLY:
      color = ThemeLight.color.darkRed;
      break;
    default:
      color = 'gray';
      break;
  }

  return (
    <ChatBubbleIcon
      style={{
        color,
        marginRight: 10,
      }}
    />
  );
};

const FlexibleScriptHeader: React.FC<{ variation: FlexibleScriptVariationType }> = ({ variation }) => {
  switch (variation) {
    case FlexibleScriptVariationType.REBUTTAL:
      return <PurpleText>REBUTTAL</PurpleText>;
    case FlexibleScriptVariationType.READ_HIGHLIGHTED_EXACT:
      return <RedText>READ HIGHLIGHTED PART EXACTLY</RedText>;
    case FlexibleScriptVariationType.READ_EXACTLY:
      return <RedText>READ EXACTLY</RedText>;
    case FlexibleScriptVariationType.READ_FLEXIBLE:
      return <GrayText>READ FLEXIBLE </GrayText>;
    case FlexibleScriptVariationType.READ_FLEXIBLE_EXACT:
      return (
        <>
          <GrayText>READ FLEXIBLE </GrayText>
          &nbsp;/&nbsp;
          <RedText> READ EXACTLY</RedText>
        </>
      );
    default:
      return null;
  }
};

const FlexibleScriptContainer: React.FC<{ variation: FlexibleScriptVariationType }> = ({ children, variation }) => {
  let borderColor;

  switch (variation) {
    case FlexibleScriptVariationType.REBUTTAL:
      borderColor = ThemeLight.color.purpleLight;
      break;
    case FlexibleScriptVariationType.READ_HIGHLIGHTED_EXACT:
    case FlexibleScriptVariationType.READ_EXACTLY:
      borderColor = ThemeLight.color.darkRed;
      break;
    default:
      borderColor = 'gray';
      break;
  }

  return (
    <Box
      style={{
        padding: 10,
        borderLeft: `2px solid ${borderColor}`,
        marginBottom: 14,
      }}
    >
      {children}
    </Box>
  );
};
