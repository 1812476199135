/* eslint-disable react/no-array-index-key */
import * as React from 'react';
import { HumanaReportType, EP10ResultItem } from '@hiq/crm.types';
import {
  Button,
  Select,
  MenuItem,
  InputLabel,
  Snackbar,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Clear, CheckCircle, Error } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import { graphqlClient, internalAuthService } from '../../../services';
import { MuiThemeLight, ThemeLight } from '../../../themes';
import { AuthenticatedPage } from '../../common';
import { FullHeight } from '../../layouts';
import { useValidateReport } from './useValidateReport';
import {
  Container,
  Title,
  UploadButtonSection,
  Action,
  FileList,
  FileListItem,
  FileSize,
  RemoveFile,
  EmptyFileListItem,
  FileListTitle,
  FileVerificationAction,
  FileVerificationPicker,
  FileVerificationDescription,
  FileVerificationSection,
  ResultsSection,
  ResultsTitle,
} from './CarrierUpload.elements';
import { useCarrierFileUpload } from './useCarrierFileUpload';

const KB = 1024;
const MB = 1024 * KB;
const GB = 1024 * MB;

const round = (n: number) => Math.round(n * 100) / 100;

const fileSize = (size: number) => {
  if (!size) return '';
  if (size < MB) return `${round(size / KB)} KB`;
  if (size < GB) return `${round(size / MB)} MB`;
  return `${round(size / GB)} GB`;
};

export const CarrierUpload: React.FunctionComponent = () => {
  const [fileInputRef, setFileInputRef] = React.useState(null);
  const [file, setFile] = React.useState(null);
  const [reportType, setReportType] = React.useState(HumanaReportType.EP10);
  const [uploading, setUploading] = React.useState(false);
  const [validating, setValidating] = React.useState(false);
  const [uploaded, setUploaded] = React.useState(false);
  const [validationResult, setValidationResult] = React.useState(undefined);
  const [error, setError] = React.useState(null);
  const [message, setMessage] = React.useState(null);
  const upload = useCarrierFileUpload();
  const validate = useValidateReport();

  const onFileInputChange = React.useCallback(() => {
    setFile(fileInputRef.files?.[0]);
  }, [fileInputRef, setFile]);

  const onClearButtonClick = React.useCallback(() => {
    setFile(null);
  }, [setFile]);

  const onUploadButtonClick = React.useCallback(async () => {
    try {
      setUploading(true);
      await upload(file);
      setMessage(`Successfully uploaded ${file.name}`);
      setUploaded(true);
    } catch (e) {
      setError(e);
    } finally {
      setUploading(false);
    }
  }, [upload, file, setMessage, setError, setUploading]);

  const onCloseErrorTray = React.useCallback(() => {
    setError(null);
    setMessage(null);
  }, [setError, setMessage]);

  const onReportTypeSelectChange = React.useCallback((event) => {
    setReportType(event.target.value);
  }, [setReportType]);

  const onValidateButtonClick = React.useCallback(async () => {
    setValidating(true);
    const response = await validate(file.name, reportType);
    setValidationResult(response.result);
    setValidating(false);
  }, [validate, file, reportType]);

  return (
    <AuthenticatedPage
      title="HIQ CRM | Carrier Uploads"
      theme={ThemeLight}
      muiTheme={MuiThemeLight}
      graphqlClient={graphqlClient}
      internalAuthService={internalAuthService}
    >
      <FullHeight>
        <Container>
          <Title>Carrier Uploads</Title>
          <UploadButtonSection>
            <Action>
              <Button color="primary" component="label">
                Select File
                <input
                  type="file"
                  style={{ display: 'none' }}
                  ref={(i) => setFileInputRef(i)}
                  onChange={onFileInputChange}
                />
              </Button>
            </Action>
            <Action>
              <Button color="primary" variant="contained" disabled={!file || uploading} onClick={onUploadButtonClick}>
                Upload
                {uploading && <CircularProgress size={16} style={{ marginLeft: 10 }} />}
              </Button>
            </Action>
          </UploadButtonSection>
          <FileListTitle>File to upload</FileListTitle>
          <FileList>
            {file
              ? (
                <FileListItem>
                  { file.name }
                  <FileSize>
                    { fileSize(file.size) }
                  </FileSize>
                </FileListItem>
              )
              : <EmptyFileListItem>No file chosen yet</EmptyFileListItem>}
          </FileList>
          <RemoveFile>
            <Button startIcon={<Clear />} disabled={!file} onClick={onClearButtonClick}>
              Clear
            </Button>
          </RemoveFile>
          <FileVerificationSection>
            <FileVerificationDescription>
              Once your file has been successfully uploaded you can
              verify it by clicking this button below.
            </FileVerificationDescription>
            <FileVerificationPicker>
              <InputLabel id="report-type">Validate as</InputLabel>
              <Select
                fullWidth
                displayEmpty
                disabled={!uploaded}
                value={reportType}
                labelId="report-type"
                onChange={onReportTypeSelectChange}
              >
                <MenuItem value={HumanaReportType.EP10}>Humana - EP10</MenuItem>
              </Select>
            </FileVerificationPicker>
            <FileVerificationAction>
              <Button variant="contained" color="primary" disabled={!uploaded || validating} onClick={onValidateButtonClick}>
                Validate
                {validating && <CircularProgress size={16} style={{ marginLeft: 10 }} />}
              </Button>
            </FileVerificationAction>
          </FileVerificationSection>
          <Snackbar open={!!error || !!message} autoHideDuration={5000} onClose={onCloseErrorTray}>
            {error
              ? (
                <Alert variant="filled" severity="error" onClose={onCloseErrorTray}>
                  <AlertTitle>Encountered an error</AlertTitle>
                  <p>{error && error.message ? error.message : error}</p>
                </Alert>
              ) : (
                <Alert variant="filled" severity="success" onClose={onCloseErrorTray}>
                  <AlertTitle>{ message }</AlertTitle>
                </Alert>
              )}
          </Snackbar>
        </Container>
        {validationResult && (
        <ResultsSection>
          <ResultsTitle>Results</ResultsTitle>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="left">Medicare ID</TableCell>
                  <TableCell align="left">Product</TableCell>
                  <TableCell align="left">Lead</TableCell>
                  <TableCell align="left">Customer</TableCell>
                  <TableCell align="left">Stage</TableCell>
                  <TableCell align="left">Sub Stage</TableCell>
                  <TableCell align="left">Error</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {validationResult.items.map((row: EP10ResultItem, index: number) => (
                  <TableRow key={index}>
                    <TableCell align="left">{row.success ? <CheckCircle color="primary" /> : <Error color="error" />}</TableCell>
                    <TableCell align="left">{row.medicareId}</TableCell>
                    <TableCell align="left">{row.product}</TableCell>
                    <TableCell align="left">{row.leadId}</TableCell>
                    <TableCell align="left">{row.customerId}</TableCell>
                    <TableCell align="left">{row.stage}</TableCell>
                    <TableCell align="left">{row.substage}</TableCell>
                    <TableCell component="th" scope="row" align="left">{row.error}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ResultsSection>
        )}
      </FullHeight>
    </AuthenticatedPage>
  );
};
