import { MustReadBlockViewProps } from './MustReadBlockView.interfaces';
import {
  ScriptBlockColorDiv,
  ScriptBlockContainer,
  ScriptBlockContent,
  ScriptBlockIcon,
  ScriptBlockIconContainer,
  ScriptBlockText,
} from '../../elements';
import { ThemeLight } from '../../../../../themes';
import { ScriptBlockConfirm } from '../../common/ScriptBlockConfirm';
import * as React from 'react';

export const MustReadBlockView: React.FunctionComponent<MustReadBlockViewProps> = ({
  children,
  confirmationRequired,
  confirmText,
  isAcknowledged,
  onChange,
  hideIcon,
}) => {
  const icon = isAcknowledged ? require('../../assets/quote-left-gray.svg') : require('../../assets/quote-left-red.svg');

  return (
    <ScriptBlockContainer>
      <ScriptBlockColorDiv color={isAcknowledged ? ThemeLight.color.gray : ThemeLight.color.error} />
      <ScriptBlockContent>
        {!hideIcon && (
        <ScriptBlockIconContainer>
          <ScriptBlockIcon src={icon} alt="must read info icon" />
        </ScriptBlockIconContainer>
        )}
        <ScriptBlockText>
          { children }
          { confirmationRequired && (
            <ScriptBlockConfirm
              isConfirmed={isAcknowledged}
              onChange={() => onChange(!isAcknowledged)}
              confirmText={confirmText}
            />
          )}
        </ScriptBlockText>
      </ScriptBlockContent>
    </ScriptBlockContainer>
  );
};
