import * as React from 'react';
import { ScratchPadInput } from './ScratchPad.elements';
import { SidePanelContentLayout } from '../../layouts/SidePanelContentLayout';
import { useFormAnswers } from '../../../hooks';

const SCRATCH_PAD_KEY = 'lead.scratchPad';
export const ScratchPad: React.FunctionComponent = () => {
  const [answers] = useFormAnswers();
  const deprecationNote = 'This section is being moved, please use activity feed';
  return (
    <SidePanelContentLayout header="Scratch Pad">
      <ScratchPadInput
        value={`${deprecationNote}\n${answers[SCRATCH_PAD_KEY]}` as string}
      />
    </SidePanelContentLayout>
  );
};
