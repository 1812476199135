import * as React from 'react';
import { QuestionType } from '@hiq/crm.types';
import { PropertyAndsCasualtyQuoteOptionsProps } from './PropertyAndCasualtyQuoteOptions.interfaces';
import {
  PropertyAndCasualtyQuoteOptionsContainer,
  ActionsWrapper,
} from './PropertyAndCasualtyQuoteOptions.elements';
import { useActiveLeadId } from '../../../../routers/LeadRouter/useActiveLeadId';
import { useFormAnswers } from '../../../../../hooks';
import { QuoteOption } from '../QuoteOption';
import { PrimaryButton } from '../../../inputs';
import { BoltPrefill } from '../../../BoltPrefill';
import { QuoteOptionsLayout } from '../../QuoteOptionsLayout';

export const PropertyAndCasualtyQuoteOptions: React.FunctionComponent<PropertyAndsCasualtyQuoteOptionsProps> = ({
  onQuote,
  isDisabled,
  isFetching,
}) => {
  const leadId = useActiveLeadId();
  const [answers, onChange] = useFormAnswers(leadId);

  const disabled = isFetching || isDisabled; // add required fields

  const runQuote = () => {
    onQuote({});
  };

  return (
    <PropertyAndCasualtyQuoteOptionsContainer>
      <QuoteOptionsLayout
        leftContent={(
          <>
            <QuoteOption
              id="lead.generalCoverages.bodilyInjury"
              label="Bodily Injury"
              value={answers['lead.generalCoverages.bodilyInjury'] ?? '100/300'}
              type={QuestionType.Text}
              handleChange={onChange}
              disabled
            />
            <QuoteOption
              id="lead.generalCoverages.propertyDamage"
              label="Property Damage"
              value={answers['lead.generalCoverages.propertyDamage'] ?? '100000'}
              type={QuestionType.Currency}
              handleChange={onChange}
              disabled
            />
          </>
        )}
        rightContent={(
          <>
            <QuoteOption
              id="lead.generalCoverages.uninsuredMotorist"
              label="Uninsured Motorist"
              value={answers['lead.generalCoverages.uninsuredMotorist'] ?? '100/300'}
              type={QuestionType.Text}
              handleChange={onChange}
              disabled
            />
            <QuoteOption
              id="lead.generalCoverages.underinsuredMotorist"
              label="Underinsured Motorist"
              value={answers['lead.generalCoverages.underinsuredMotorist'] ?? '100/300'}
              type={QuestionType.Text}
              handleChange={onChange}
              disabled
            />
            <QuoteOption
              id="lead.generalCoverages.medicalPayments"
              label="Medical Payments"
              value={answers['lead.generalCoverages.medicalPayments'] ?? '0'}
              type={QuestionType.Currency}
              handleChange={onChange}
              disabled
            />
            <ActionsWrapper>
              <PrimaryButton
                onClick={runQuote}
                disabled={disabled}
                variant="contained"
              >
                { isFetching ? 'Fetching...' : 'Get Quotes' }
              </PrimaryButton>

              <BoltPrefill />
            </ActionsWrapper>
          </>
        )}
        minWidth={200}
        gutter={20}
      />
    </PropertyAndCasualtyQuoteOptionsContainer>
  );
};
