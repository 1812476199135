import * as React from 'react';
import { CustomElementProps } from './FormItemTypeCustom.interfaces';
import {
  FormItemTypeCustomContainer,
} from './FormItemTypeCustom.elements';
import { CustomElement } from '../../../../CustomElement';

export const FormItemTypeCustom: React.FunctionComponent<CustomElementProps> = ({
  referenceId, defaultRender,
  // eslint-disable-next-line max-len
}) => (<FormItemTypeCustomContainer><CustomElement referenceId={referenceId} defaultRender={defaultRender} /></FormItemTypeCustomContainer>);
