import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { AvertaPeNormal400, AvertaPeNormal500 } from '@healthiqeng/core.fonts';
import { resetDiv, resetH2, resetP } from '../resets';

export const ListContainer = styled('div')(resetDiv, css`
  display: flex;
  justify-content: center;
  background-color: #fff;
  min-width: 320px;
  max-width: 700px;
`);

export const ListContentFrame = styled('div')(resetDiv, css`
  max-width: 600px;
  padding: 20px 0 20px 0;
`);

export const ListHeaderContainer = styled('div')(resetDiv, css`
  padding: 8px 28px 8px 18px;
`);

export const ListHeader = styled('h2')(resetH2, css`
  ${AvertaPeNormal500};
  font-size: 20px;
  text-align: left;
  color: #000;
  padding: 0 14px;
`);

export const ListBullet = styled('div')(resetDiv, css`
  display: flex;
  align-items: flex-start;
  margin: 4px 0 10px 0;
  padding: 0 28px;
`);

export const ListBulletNumber = styled('p')(resetP, css`
  ${AvertaPeNormal500};
  margin-right: 7px;
  color: #4ca9ed;
`);

export const BulletsTextContainer = styled('div')(resetDiv);

export const ListBulletHeader = styled('p')(resetP, css`
  ${AvertaPeNormal500};
  margin: 0;
  font-size: 16px;
  color: #000;
`);

export const ListBulletDescription = styled('p')(resetP, css`
  ${AvertaPeNormal400};
  margin-top: 5px;
  font-size: 14px;
  line-height: 18px;
  color: #666;
`);
