import styled from '@emotion/styled';

export const Panel = styled.div`
  display: flex;
  justify-content: flex-start;
  background-color: #F8F9FA;
  padding: 20px;
  width: 100%;
`;

export const SearchBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-right: 20px;
`;

export const MatchedAddress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
`;

export const AddressTitle = styled.div`
  font-weight: bold;
`;

export const LocationButton = styled.button`
  border: none;
  background-color: inherit;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  display: inline-block;
  color: #207FD4;

/* On mouse-over */
  &:hover {background: #eee;}
`;
