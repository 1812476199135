import { UserProfile } from '@hiq/crm.types';
import { useCurrentUserQuery } from '../../graphql';

export function useCurrentUserIsPrecisionMedicareHeavy(): boolean {
  const { data } = useCurrentUserQuery();
  return [UserProfile.PrecisionMedicareHeavyAgent, UserProfile.BionicAgent]
    .includes(data?.currentUser?.profile as UserProfile);
}

export function useCurrentUserIsBionic(): boolean {
  const { data } = useCurrentUserQuery();
  return [UserProfile.BionicAgent, UserProfile.BionicAgentLite, UserProfile.BionicAgentThin]
    .includes(data?.currentUser?.profile as UserProfile);
}

export function useCurrentUserIsBionicLite(): boolean {
  const { data } = useCurrentUserQuery();
  return [UserProfile.BionicAgentLite]
    .includes(data?.currentUser?.profile as UserProfile);
}

export function useCurrentUserIsBionicThin(): boolean {
  const { data } = useCurrentUserQuery();
  return [UserProfile.BionicAgentThin]
    .includes(data?.currentUser?.profile as UserProfile);
}
