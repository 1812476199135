import { isPresent } from '@healthiqeng/core.util.is-present';
import { InputTransformer } from '../InputTransformer';

export class ZipCodeTransformer extends InputTransformer<string> {
  constructor(initialValue: string) {
    super(initialValue);
    this.draftValue = initialValue;
  }

  public update(nextValue: string) {
    this.updateDraftValue(nextValue);
    this.updatePersistedValue();
  }

  public getDraftValue(): string {
    return ZipCodeTransformer.normalizeZipCode(this.draftValue);
  }

  public getPersistedValue(): string {
    return this.persistedValue;
  }

  public getFormattedValue(): string {
    return ZipCodeTransformer.normalizeZipCode(this.getPersistedValue());
  }

  private updateDraftValue(nextValue: string) {
    this.draftValue = ZipCodeTransformer.normalizeZipCode(nextValue);
  }

  private updatePersistedValue() {
    if ((this.draftValue ?? '') === '') {
      this.persistedValue = null;
    }
    if (/^[0-9]{5}$/.test(this.draftValue ?? '')) {
      this.persistedValue = this.draftValue;
    }
  }

  public static normalizeZipCode(value: string): string {
    if (!isPresent(value)) {
      return '';
    }

    return value
      .replace(/[^0-9]/g, '')
      .substring(0, 5);
  }
}
