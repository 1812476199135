import { UserInteractionType } from '@hiq/crm.types';
import { MutationCustomReturn } from '../useMutationCustom';
import { useLogInteraction } from './useLogInteraction';
import {
  LogPanelChangeInteractionOptions,
  LogUserInteractionMutationResponse,
} from './useLogUserInteractionMutation.interfaces';

export function useLogPanelChange(): MutationCustomReturn<LogUserInteractionMutationResponse, LogPanelChangeInteractionOptions> {
  const [logInteraction, result] = useLogInteraction();
  const logPanelChangeInteraction = ({ panel, detail }: LogPanelChangeInteractionOptions) => logInteraction({
    type: UserInteractionType.PanelChange,
    target: panel,
    detail,
  });

  return [
    logPanelChangeInteraction,
    result,
  ];
}
