import { gql } from 'apollo-boost';
import { MutationCustomReturn, useMutationCustom } from '../useMutationCustom';
import {
  PrefillSunfireMutationResponse,
  PrefillSunfireMutationVariables,
} from './usePrefillSunfireMutation.interfaces';

const PREFILL_SUNFIRE_MUTATION = gql`
  mutation PrefillSunfire($leadId: ID!, $useTrainingEnv: Boolean) {
    prefillSunfire(leadId: $leadId, useTrainingEnv: $useTrainingEnv) {
      code
      success
      message
      timestamp
      sunfireReference {
        id
        url
      }
    }
  }
`;

export function usePrefillSunfireMutation(): MutationCustomReturn<
PrefillSunfireMutationResponse,
PrefillSunfireMutationVariables> {
  return useMutationCustom<
  PrefillSunfireMutationResponse,
  PrefillSunfireMutationVariables>(PREFILL_SUNFIRE_MUTATION);
}
