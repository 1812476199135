import {
  useCallback, ChangeEvent, useMemo,
} from 'react';
import { useForceUpdate } from '@healthiqeng/core.hooks.use-force-update';
import {
  OnChangeFunction,
  TransformerFactory,
  HandleChangeFunction,
} from './useInputOnChange.interfaces';

export function useInputOnChange<ElementType extends HTMLInputElement, InputValueTypeGeneric>(
  value: InputValueTypeGeneric,
  onChange: OnChangeFunction<InputValueTypeGeneric>,
  transformerFactory: TransformerFactory<InputValueTypeGeneric>,
): [string, HandleChangeFunction<InputValueTypeGeneric, ElementType>] {
  const transformer = useMemo(() => transformerFactory(value), [value]);
  const forceUpdate = useForceUpdate();
  const handleChange = useCallback((event: ChangeEvent<ElementType>) => {
    transformer.update(event.target.value);
    forceUpdate();
    if (transformer.getPersistedValue() !== value) {
      onChange(transformer.getPersistedValue());
    }
  }, [onChange, transformer]);
  return [transformer.getDraftValue(), handleChange];
}
