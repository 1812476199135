import React from 'react';

export const getCMHRHipaaConstants = () => {
  const declineReasons = React.useMemo(() => [
    {
      label: 'Not Interested',
      value: 'Not Interested',
    },
    {
      label: 'Need more time to think about it',
      value: 'Need more time to think about it',
    },
    {
      label: 'Don\'t want to give personal information',
      value: 'Don\'t want to give personal information',
    },
    {
      label: 'Didn\'t understand the IVR',
      value: 'Didn]\t understand the IVR',
    },
    {
      label: 'No e-mail',
      value: 'No e-mail',
    },
  ], []);

  const declineQuestionTextRetentionSpecialist = `No problem. Just want to confirm your selection of No to enroll?
    Can I get feedback as to why so that we can improve the experience in the future?
    This does not affect your policy or your enrollment in any way.
    `;

  const declineQuestionText = 'That’s fine, we can proceed without using your health record to find a plan based on '
    + 'your unique health needs.'
                              + ' Are you sure you want to skip using your health record?';

  const tempHipaaApprovedOptions = React.useMemo(() => [{ label: 'Yes', value: true }, { label: 'No', value: false }], []);

  return {
    declineReasons,
    declineQuestionText,
    declineQuestionTextRetentionSpecialist,
    tempHipaaApprovedOptions,
  };
};
