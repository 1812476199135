import * as React from 'react';

import {
  Dialog, DialogTitle, DialogContent,
} from '@material-ui/core';
import { GenericModalProps } from './GenericModal.interfaces';

export const GenericModal: React.FC<GenericModalProps> = ({
  headerText,
  open,
  modalContent,
}) => (
  <Dialog open={open}>
    <DialogTitle>{headerText}</DialogTitle>
    <DialogContent>
      {modalContent}
    </DialogContent>
  </Dialog>
);
