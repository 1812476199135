import { gql } from 'apollo-boost';
import { useCallback } from 'react';
import { UnselectQuoteMutationResponse, UnselectQuoteMutationVariables } from './useUnselectQuoteMutation.interfaces';
import { eventBus } from '../../../services';
import { QuoteUnselectedEvent, QuoteUnselectStartEvent } from './events';
import { MutationCustomReturn, useMutationCustom } from '../useMutationCustom';
import { QuoteSelectFailedEvent } from '../useSelectQuoteMutation/events';
import { LEAD_FRAGMENT } from '../../fragments/lead';

const UNSELECT_QUOTE_MUTATION = gql`
    mutation UnselectQuote($leadId: ID!, $hash: ID!) {
      unselectQuote(leadId: $leadId, hash: $hash) {
        code
        success
        message
        timestamp
        lead {
          ...LeadFragment
        }
      }
    }
    ${LEAD_FRAGMENT}
`;

export function useUnselectQuoteMutation(): MutationCustomReturn<UnselectQuoteMutationResponse, UnselectQuoteMutationVariables> {
  const [unselectQuote, result] = useMutationCustom<
  UnselectQuoteMutationResponse,
  UnselectQuoteMutationVariables
  >(UNSELECT_QUOTE_MUTATION, {
    update: (cache, { data }) => {
      if (!data?.unselectQuote?.success) return;
      cache.writeFragment({
        data: data.unselectQuote.lead,
        fragment: LEAD_FRAGMENT,
        fragmentName: 'LeadFragment',
      });
    },
  });
  const unselectQuoteWrapped = useCallback(async (variables: UnselectQuoteMutationVariables) => {
    eventBus.emit(new QuoteUnselectStartEvent());
    const response = await unselectQuote(variables);
    if (response?.data?.unselectQuote?.success) {
      eventBus.emit(new QuoteUnselectedEvent());
    } else {
      eventBus.emit(new QuoteSelectFailedEvent());
    }
    return response;
  }, [unselectQuote]);

  return [unselectQuoteWrapped, result];
}
