export enum PrimitiveType {
  String = 'STRING',
  Int = 'INT',
  Boolean = 'BOOLEAN',
  Float = 'FLOAT',
}

export interface Primitive {
  value: string;
  type: PrimitiveType;
}

export type PrimitiveValue = number | string | boolean;

export enum QuestionFlowType {
  Interview = 'INTERVIEW',
  AtApply = 'AT_APPLY',
  Application = 'APPLICATION',
  ProducerStatement = 'PRODUCER_STATEMENT',
  Supplement = 'SUPPLEMENT',
  Backend = 'BACKEND',
}

export enum FormItemType {
  Radio = 'RADIO',
  RadioYesNo = 'RADIO_YES_NO',
  Select = 'SELECT',
  Date = 'DATE',
  DateTime = 'DATE_TIME',
  Time = 'TIME',
  Text = 'TEXT',
  TextArea = 'TEXT_AREA',
  Number = 'NUMBER',
  Currency = 'CURRENCY',
  SSN = 'SSN',
  Phone = 'PHONE',
  Email = 'EMAIL',
  MultiSelect = 'MULTI_SELECT',
  Percent = 'PERCENT',
  Address = 'ADDRESS',
  HiddenText = 'HIDDEN',
}

export enum QuestionFlowItemTag {
  RequiredToQuote = 'REQUIRED_TO_QUOTE',
  RequiredToNavigate = 'REQUIRED_TO_NAVIGATE',
  RequiredToConvert = 'REQUIRED_TO_CONVERT',
  IsImpairment = 'IS_IMPAIRMENT',
  UnderwritingSupported = 'UNDERWRITING_SUPPORTED'
}

export type StateCode = string;
export type ZipCode = string;
export enum AddressType {
  Home = 'HOME',
  Work = 'WORK',
}

export interface AddressAnswer {
  street: string;
  apt?: string;
  city: string;
  state: StateCode;
  zip: ZipCode;
  country: string;
  type?: AddressType;
}

export type QuestionFlowAnswer = string | number | boolean | Date | AddressAnswer;
export interface QuestionFlowAnswers {
  [index: string]: QuestionFlowAnswer;
}

export type FieldGroupItemValue = QuestionFlowAnswer;

export enum CustomerTag {
  MaCompliant = 'MA_COMPLIANT',
  MaNonCompliant = 'MA_NON_COMPLIANT',
  V1Verified = 'V1_VERIFIED',
  V2Verified = 'V2_VERIFIED',
}

export enum NameSalutation {
  Mr = 'MR',
  Mrs = 'MRS',
  Ms = 'MS',
  Dr = 'DR',
  Prof = 'PROF',
}

export enum CompareRuleField {
  Value = 'VALUE',
  Parent = 'PARENT',
}

export enum CompareRuleOperator {
  Equals = 'EQUALS',
  NotEquals = 'NOT_EQUALS',
  IsPresent = 'IS_PRESENT',
  IsNotPresent = 'IS_NOT_PRESENT',
  Includes = 'INCLUDES',
  IsOneOf = 'IS_ONE_OF',
}

export interface SelectOption {
  label: string;
  value: PrimitiveValue;
}

export enum ResponseCode {
  Ok = 200,
  ServerError = 500,
  ClientError = 400,
  NotFound = 404,
}

export interface MutationResponse {
  code: ResponseCode;
  success: boolean;
  message: string;
  timestamp: number;
}

export * from './Anchor';
