import * as React from 'react';
import { MultiSelectInputProps } from './MultiSelectInput.interfaces';
import { Autocomplete } from '@material-ui/lab';
import { InputValidationStatus } from '@hiq/crm.types';
import { TextField } from '../TextInput/TextInput.elements';

export const MultiSelectInput: React.FunctionComponent<MultiSelectInputProps> = ({
  value,
  placeholder,
  onChange,
  options,
  label,
  size,
  disabled,
  validationStatus,
  validationMessage,
  fullWidth,
  variant,
  dataQa,
  tags,
}) => {
  const getOptionLabel = React.useCallback((val: string) => options
    .find(({ value: optionValue }) => val === optionValue)?.label,
  [options]);

  const getTextInput = (params: any) => (
    <TextField
      {...params}
      tags={tags}
      label={label}
      size={size}
      placeholder={placeholder}
      fullWidth={fullWidth}
      variant={variant ?? 'outlined'}
      error={validationStatus === InputValidationStatus.Error}
      helperText={validationMessage}
      disabled={disabled}
      inputProps={params.inputProps}
    />
  );
  return (
    <Autocomplete
      multiple
      disabled={disabled}
      disableCloseOnSelect
      value={(value ?? []) as any[]}
      onChange={(event: any, newValue: string[]) => onChange(newValue)}
      autoHighlight
      options={options.map(({ value: val }) => val)}
      getOptionLabel={getOptionLabel}
      renderInput={getTextInput}
      fullWidth={fullWidth}
      data-qa={dataQa}
    />
  );
};
