import * as React from 'react';
import { LifeSelectedQuoteContainer } from './LifeSelectedQuotes.elements';
import { QueryLoader } from '../../../../../../common/QueryLoader';
import { useLeadQuery } from '../../../../../../../graphql';
import { QuoteCellFactory } from '../../../../../../common/quoting/QuoteCellFactory';
import { Card } from '../../../../../../common/Card';
import { QuestionFlow } from '../../../../../../common/QuestionFlow';
import { useActiveLeadId } from '../../../../../../routers/LeadRouter/useActiveLeadId';

export const LifeSelectedQuotes: React.FunctionComponent = () => {
  const leadId = useActiveLeadId();
  const { data, loading, error } = useLeadQuery();

  return (
    <QueryLoader loading={loading} error={error} componentName="LifeSelectedQuotes">
      <>
        { data?.selectedQuotes?.map((quote) => (
          <LifeSelectedQuoteContainer key={quote?.hash}>
            <Card>
              <QuoteCellFactory type={quote?.product?.type} hash={quote?.hash} />
            </Card>
          </LifeSelectedQuoteContainer>
        )) }
        <LifeSelectedQuoteContainer>
          <Card>
            <QuestionFlow leadId={leadId} referenceId="post-quote" />
          </Card>
        </LifeSelectedQuoteContainer>
      </>
    </QueryLoader>
  );
};
