import * as React from 'react';
import PhoneInTalk from '@material-ui/icons/PhoneInTalk';
import IconButton from '@material-ui/core/IconButton';
import { useDialerService } from '@healthiqeng/core.hooks.use-dialer-service';
import { ClickToDialProps } from './ClickToDialButton.interfaces';
import {
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
} from '@material-ui/core';
import { PrimaryButton } from '../inputs/PrimaryButton';

export const ClickToDialButton: React.FunctionComponent<ClickToDialProps> = ({ number }) => {
  const { dialerService } = useDialerService();
  const defaultCampaign = 'Health IQ Manual Outbound';
  const [open, setOpen] = React.useState(false);

  const handleClose = async () => {
    setOpen(false);
  };

  const clickToDial = async () => {
    try {
      await dialerService.makeExternalCall(number, defaultCampaign);
    } catch (e) {
      setOpen(true);
    }
  };
  return (
    <>
      <IconButton size="small" onClick={() => clickToDial()}><PhoneInTalk /></IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          There was an error with the dialer
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Unable to call. Make sure to disposition current call and confirm you are logged into five 9
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <PrimaryButton onClick={handleClose} variant="outlined">OK</PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
