import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from '@material-ui/core';
import { FormItemTag } from '@hiq/crm.types';

export const TextInputContainer = styled('div')(css`
  position: relative;
`);

const FloatingLabelContainerRight = styled('div')(css`
  position: absolute;
  top: -0.5rem;
  line-height: 1rem;
  font-size: 0.8rem;
  font-weight: bold;
  right: 1.5rem;
  padding: 0 0.3rem 0 0.3rem;
  background-color: #FFF;
  border-radius: 0.3rem;
  color: #999;
`);

export type TextFieldInputProps = MuiTextFieldProps & {
  rightLabel?: string;
  tags?: FormItemTag[]
};

export const TextField: React.FC<TextFieldInputProps> = (props) => {
  const { tags, ...rest } = props;
  const floatingLabels: string[] = [];

  if (tags?.some((tag) => tag.startsWith('REQUIRED'))) {
    floatingLabels.push('REQUIRED');
  }

  if (tags?.some((tag) => tag === FormItemTag.Hipaa)) {
    floatingLabels.push('HIPAA');
  }

  const rightLabel = floatingLabels.join(' / ');
  return (
    <TextInputContainer>
      <MuiTextField {...rest} />
      {rightLabel && <FloatingLabelContainerRight>{rightLabel}</FloatingLabelContainerRight>}
    </TextInputContainer>
  );
};
