import { isPresent } from '@healthiqeng/core.util.is-present';
import { InputTransformer } from '../../InputTransformer';
import {
  normalizeYear,
  normalizeMonth,
} from '../util';

export class DateMonthTransformer extends InputTransformer<string> {
  constructor(initialValue: string) {
    super(initialValue);
    if (initialValue) {
      const date = new Date(initialValue);
      this.draftValue = Number.isNaN(date.getTime())
        ? ''
        : DateMonthTransformer.normalizeDate(
          `${date.getUTCMonth() + 1}/${date.getUTCFullYear()}`,
        );
      return;
    }
    this.draftValue = '';
  }

  public update(nextValue: string) {
    this.updateDraftValue(nextValue);
    this.updatePersistedValue();
  }

  public getDraftValue(): string {
    return DateMonthTransformer.normalizeDate(this.draftValue);
  }

  public getPersistedValue(): string {
    return this.persistedValue;
  }

  public getFormattedValue(): string {
    if (!this.getPersistedValue()) return '';
    const date = new Date(this.getPersistedValue());
    return DateMonthTransformer.normalizeDate(
      `${date.getUTCMonth() + 1}/01/${date.getUTCFullYear()}`,
    );
  }

  private updateDraftValue(nextValue: string) {
    if ((nextValue.length === this.draftValue.length - 1) && this.draftValue.endsWith('/')) {
      this.draftValue = this.draftValue.slice(0, -2);
      return;
    }
    this.draftValue = DateMonthTransformer.normalizeDate(nextValue);
  }

  private updatePersistedValue() {
    if ((this.draftValue ?? '') === '') {
      this.persistedValue = null;
    }
    if (/^[0-9]{2}\/[0-9]{4}$/.test(this.draftValue ?? '')) {
      const [month, year] = this.draftValue.split('/');
      this.persistedValue = (new Date(Date.UTC(
        Number.parseInt(year),
        Number.parseInt(month) - 1,
        1,
      ))).toISOString();
    }
  }

  public static normalizeDate(value: string): string {
    if (!isPresent(value)) {
      return '';
    }

    const cleanedDate = value.replace(/[^0-9/]/g, '');
    const [month, year] = cleanedDate.split('/');
    const normalizedMonth = normalizeMonth(month, typeof year !== 'undefined');
    const normalizedYear = normalizeYear(year);

    const parts = [normalizedMonth];
    if (typeof year !== 'undefined') parts.push(normalizedYear);
    return parts.join('');
  }
}
