import * as React from 'react';
import { PropertyAndCasualtyFinalQuoteProps } from './PropertyAndCasualtyFinalQuote.interfaces';
import { PNCBaseExternalQuote, Card } from '../../../../../../../common';

export const PropertyAndCasualtyFinalQuote: React.FunctionComponent<PropertyAndCasualtyFinalQuoteProps> = ({
  productType,
}) => (
  <Card
    key="final-quote"
    margin="40px 0"
    padding="20px"
  >
    <h3>Update Final Quote</h3>
    <p>If the currently selected quote is a preliminary quote, use this to update to the final value.</p>
    <PNCBaseExternalQuote
      isUpdate
      hasSpecialRate
      productType={productType}
    />
  </Card>
);
