import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import {
  GridCenteredContent, GridContent, GridTitle, HealthRecordGridContainer,
} from './HealthRecordGrid.elements';
import { Datagrid, DatagridProps } from '../../../Datagrid';
import { HealthRecordGridProps } from './HealthRecordGrid.interfaces';
import { HealthRecordTopBar } from './HealthRecordTopBar';
import { useSort } from './useSort';
import HealthRecordSection from './HealthRecordSection/HealthRecordSection';

export function HealthRecordGrid<D>({
  data,
  otherData,
  title,
  subtitle,
  overlays,
  getRowId,
  enableSelection,
  selectedRowIds,
  onSelectionChange,
  onSelectToDelete,
  selectedToDelete,
  sortFns,
  columns,
  selectionMultiselect,
  selectionLabel,
  emptyRowsMessage,
  emptyOverlaysMessage,
  isRemoving,
  hideOverlays,
  loading,
  overlayToolbar,
  unsupportedData,
  sections,
  hideSorting,
  unsupportedGridTitle,
  hideGridTitle,
  alwaysIncluded = false,
  disableNewSelections = false,
}: HealthRecordGridProps<D>) {
  const [mergedData, addedData] = React.useMemo(() => {
    const editOverlayIds: string[] = [];
    const mergedData = data.reduce((merged, current) => {
      const rowOverlay = hideOverlays ? undefined : overlays?.find(
        (overlay) => getRowId(current) === overlay.externalId,
      );
      if (rowOverlay) {
        editOverlayIds.push(rowOverlay.id);
        merged.push({
          ...current,
          ...rowOverlay.data,
        });
      } else {
        merged.push(current);
      }
      return merged;
    }, [] as D[]);

    const addedData = hideOverlays ? [] : overlays
      ?.filter((overlay) => !editOverlayIds.includes(overlay.id))
      ?.map(({ data }) => data) ?? [];
    return [mergedData, addedData];
  }, [data, overlays, hideOverlays]);

  const sortOptions = React.useMemo(
    () => sortFns && Object.entries(sortFns)
      .map(([key, { label }]) => ({
        label,
        value: key,
      })),
    [sortFns],
  );

  const [mergedSortedData, sortType, setSortBy] = useSort(mergedData, sortFns);
  const [addedSortedData, addedSortType, setAddedSortBy] = useSort(addedData, sortFns);
  const onSortChange = React.useCallback((value: string) => {
    setSortBy(value);
    setAddedSortBy(value);
  }, [setSortBy, setAddedSortBy]);

  const colors = React.useMemo(() => {
    if (isRemoving) {
      return {
        selectedColor: '#E14747',
        selectedBackground: '#fff',
        selectedLabelColor: '#E14747',
      };
    }
    return {
      selectedColor: '#05AB5B',
      selectedBackground: '#F6FFFB',
    };
  }, [isRemoving]);

  const onSelection = React.useCallback((selected: D) => {
    if (isRemoving) return onSelectToDelete(selected);
    return onSelectionChange(selected);
  }, [isRemoving, onSelectToDelete, onSelectionChange]);

  const rowIds = React.useMemo(() => {
    if (isRemoving) return selectedToDelete;
    return selectedRowIds;
  }, [selectedToDelete, selectedRowIds, isRemoving]);

  const addedDatagridProps = React.useMemo(() => ({
    data: addedSortedData,
    enableSelection,
    getRowId,
    customColumns: columns,
    fullWidth: true,
    selectionLabel: isRemoving ? 'Remove?' : selectionLabel || 'Include?',
    selectionMultiselect: isRemoving ? true : selectionMultiselect,
    selectedRowIds: rowIds,
    onSelectionChange: onSelection,
    ...colors,
    alwaysIncluded,
    disableNewSelections,
  }) as DatagridProps<D>, [isRemoving, columns, addedSortedData, rowIds,
    onSelection, colors, onSelection, selectionMultiselect, alwaysIncluded]);

  let datagridContent;
  if (loading) {
    datagridContent = <GridCenteredContent><CircularProgress color="inherit" size={40} /></GridCenteredContent>;
  } else if (sections?.length && data?.length) {
    const datagridProps = {
      enableSelection,
      addStubSelectionColumn: isRemoving,
      selectedRowIds: rowIds,
      onSelectionChange,
      selectionLabel: selectionLabel ?? 'Include?',
      getRowId,
      customColumns: columns,
      fullWidth: true,
      selectionMultiselect,
      selectedColor: '#05AB5B',
      selectedBackground: '#F6FFFB',
      disableNewSelections,
    };

    const unsupportedDatagridProps = {
      enableSelection: false,
      getRowId,
      customColumns: columns,
      fullWidth: true,
    };
    datagridContent = sections.map((section) => {
      const data = mergedSortedData.filter(section.filter);
      const unsupportedDataFiltered = unsupportedData?.filter(section.filter);

      if (section?.hideIfEmpty && data?.length === 0) return null;

      return (
        <HealthRecordSection key={section.name} {...{ ...section }}>
          {data?.length ? <Datagrid {...datagridProps} data={data} /> : <GridContent>{section.emptyRowsMessage}</GridContent>}
          {unsupportedDataFiltered?.length ? (
            <>
              <GridTitle>{unsupportedGridTitle ?? 'UNSUPPORTED VIA AUTO-IMPORT (MANUALLY ENTER)'}</GridTitle>
              <Datagrid {...unsupportedDatagridProps} data={unsupportedDataFiltered} />
            </>
          ) : null}
        </HealthRecordSection>
      );
    });
  } else if (data?.length) {
    const datagridProps = {
      data: mergedSortedData,
      enableSelection,
      addStubSelectionColumn: isRemoving,
      selectedRowIds: rowIds,
      onSelectionChange,
      selectionLabel: selectionLabel ?? 'Include?',
      getRowId,
      customColumns: columns,
      fullWidth: true,
      selectionMultiselect,
      selectedColor: '#05AB5B',
      selectedBackground: '#F6FFFB',
      alwaysIncluded,
      disableNewSelections,
    };
    datagridContent = <Datagrid {...datagridProps} />;
  } else {
    datagridContent = emptyRowsMessage ? <GridContent>{emptyRowsMessage}</GridContent> : null;
  }

  const otherDatagridProps = {
    data: otherData,
    enableSelection,
    addStubSelectionColumn: isRemoving,
    selectedRowIds: rowIds,
    onSelectionChange,
    selectionLabel: selectionLabel ?? 'Include?',
    getRowId,
    customColumns: columns,
    fullWidth: true,
    selectionMultiselect,
    selectedColor: '#05AB5B',
    selectedBackground: '#F6FFFB',
    alwaysIncluded,
    disableNewSelections,
  };

  const unsupportedDatagridProps = {
    data: unsupportedData,
    enableSelection: false,
    getRowId,
    customColumns: columns,
    fullWidth: true,
  };

  return (
    <HealthRecordGridContainer>
      <HealthRecordTopBar
        title={title}
        subtitle={subtitle}
        sortType={sortType ?? addedSortType}
        sortOptions={sortOptions}
        onSortChange={onSortChange}
        hideSorting={hideSorting}
      />
      {!hideGridTitle && <GridTitle>IMPORTED VIA HEALTH RECORD</GridTitle>}
      {datagridContent}
      {unsupportedData?.length && !sections ? (
        <>
          <GridTitle>{unsupportedGridTitle ?? 'UNSUPPORTED VIA AUTO-IMPORT (MANUALLY ENTER)'}</GridTitle>
          <Datagrid {...unsupportedDatagridProps} />
        </>
      ) : null}
      {!hideOverlays
        ? (
          <>
            <GridTitle>ADDED BY AGENT:</GridTitle>
            {addedData?.length ? <Datagrid {...addedDatagridProps} /> : <GridContent>{emptyOverlaysMessage}</GridContent>}
          </>
        ) : null}
      {otherData?.length
        ? (
          <>
            <GridTitle>OTHER:</GridTitle>
            <Datagrid {...otherDatagridProps} />
          </>
        ) : null}
      {overlayToolbar}
    </HealthRecordGridContainer>
  );
}
