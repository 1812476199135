import * as React from 'react';
import * as ReactDOM from 'react-dom';

export function hydratePage(Element: JSX.Element, mountingPointId: string) {
  ReactDOM.hydrate(
    Element,
    document.getElementById(mountingPointId),
  );
}

export function renderPageClient(Element: JSX.Element, mountingPointId: string) {
  ReactDOM.render(
    Element,
    document.getElementById(mountingPointId),
  );
}
