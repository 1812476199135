import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProps } from './Theme.interfaces';

export const ThemeLight: ThemeProps = {
  color: {
    background: '#FFFFFF',
    backgroundBackdrop: '#EAEAEA',
    backgroundInverse: '#1A1C1F',
    text: '#1A1C1F',
    textInverse: '#FFFFFF',
    primary: '#4CA9ED',
    primaryLight: '#C7E7FF',
    primaryLighter: '#f3f9fe',
    primaryTransparent: 'rgba(76, 169, 237, 0.5)',
    primaryDark: '#0075CA',
    primaryDarkHover: '#254673',
    accentDark: '#35aa5b',
    accent: '#5ECD82',
    accentLight: '#C7F2D9',
    grayBackground: '#FAFAFA',
    grayLighter: '#F5F5F5',
    grayLight: '#DEE2E5',
    gray: '#B1B7BA',
    grayDark: '#7F8385',
    grayDarker: '#434647',
    black: '#1A1C1F',
    white: '#FFFFFF',
    error: '#E14747',
    warning: '#FF8D4A',
    warningAccent: '#FFE700',
    darkRed: '#7E0000',
    darkRedLight: '#ffe8e6',
    darkRedLighter: '#f7eceb',
    lightYellow: '#fffdf1',
    purpleDark: '#183BB7',
    purple: '#400fce',
    purpleLight: '#889DE7',
    purpleLighter: '#eceaf8',
  },
};

export const MuiThemeLight = createMuiTheme({
  palette: {
    primary: {
      main: ThemeLight.color.primary,
    },
    secondary: {
      main: ThemeLight.color.accent,
    },
    success: {
      main: ThemeLight.color.accent,
    },
    error: {
      main: ThemeLight.color.error,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        padding: '10px',
        border: `1px solid ${ThemeLight.color.primaryDark}`,
        borderRadius: '10px',
      },
      textPrimary: {
        color: ThemeLight.color.primaryDark,
      },
      containedPrimary: {
        backgroundColor: ThemeLight.color.primaryDark,
        color: ThemeLight.color.white,
      },
    },
    MuiCheckbox: {
      checked: {
        color: ThemeLight.color.primaryDark,
      },
      colorPrimary: {
        color: ThemeLight.color.primaryDark,
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: 'transparent',
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiTab: {
      wrapper: {
        flexDirection: 'row',
        justifyContent: 'left',
      },
    },
  },
});
