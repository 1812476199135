import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../../../../themes';

export const QuestionCellContainer = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${theme.color.grayLight};
  flex-wrap: wrap;
  padding: 3px 0;
`);

export const QuestionText = styled('div')(css`
  flex: 1;
  min-width: 50px;
  text-align: left;
  margin-right: 5px;
  padding-top: 5px;
  font-weight: bold;
`);

export const QuestionInputContainer = styled('div')(css`
  min-width: 250px;
  flex: 2;
`);
