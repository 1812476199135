import { useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { PAYMENT_METHOD_FRAGMENT } from '../../fragments';
import { PaymentMethodQueryData, PaymentMethodQueryVariables } from './usePaymentMethodLazyQuery.interfaces';

const PAYMENT_METHOD_QUERY = gql`
  query getPaymentMethod($leadId: ID!) {
    paymentMethod(leadId: $leadId) {
      visibility {
        visible
        message
      }
      paymentMethod {
        ...PaymentMethodFragment
      }
    }
  }
  ${PAYMENT_METHOD_FRAGMENT}
`;

export function usePaymentMethodLazyQuery() {
  return useLazyQuery<PaymentMethodQueryData, PaymentMethodQueryVariables>(PAYMENT_METHOD_QUERY);
}
