import * as React from 'react';
import { useState } from 'react';
import { BionicFetchHealthRecordsQueryData, useBionicFetchHealthRecords, useGetCustomerHealthRecordStatusQuery } from '../../../../graphql';
import { useCustomerId } from '../../../routers';
import { CustomerHealthRecordPullStatus } from '@hiq/crm.types';

const MAX_TRIES = 120; // 10 minutes trying
const INTERVAL = 5000; // 5 seconds

export function usePreLoadCheckMyHealthRecord(email: string, zip: string, county: string) {
  const [numberOfTries, setNumberOfTries] = useState(0);
  const customerId = useCustomerId();

  const emptyResponse: BionicFetchHealthRecordsQueryData = React.useMemo(() => ({
    fetchBionicHealthRecords: {
      complete: true,
      drugs: [],
      doctors: [],
      pharmacies: [],
      userClaims: [],
    },
  }), []);

  const [getHealthRecordQuery,
    {
      data: queryData,
      error: queryError,
      loading: queryLoading,

    }] = useBionicFetchHealthRecords('network-only');

  const [getCustomerHealthRecordStatus, {
    data: customerPullStatus, networkStatus, loading: customerPullStatusLoading,
    refetch,
  }] = useGetCustomerHealthRecordStatusQuery('network-only');

  const updatePullStatus = React.useCallback(() => {
    getCustomerHealthRecordStatus({ variables: { customerId } });
  }, [customerId, getCustomerHealthRecordStatus]);

  const fetchData = React.useCallback((email: string, zip: string, county: string, customerId: string) => {
    getHealthRecordQuery({
      variables: {
        email, zip, county, customerId,
      },
    });
  }, [getHealthRecordQuery]);

  React.useEffect(() => {
    if (customerPullStatus?.customerHealthRecordStatus?.recordPullStatus === CustomerHealthRecordPullStatus.PROCESSING
      && numberOfTries < MAX_TRIES && networkStatus === 7) {
      // eslint-disable-next-line
      setTimeout(() => {
        setNumberOfTries((numberOfTries) => numberOfTries + 1);
        refetch();
      }, INTERVAL);
    }
  }, [customerPullStatus?.customerHealthRecordStatus?.recordPullStatus, networkStatus, numberOfTries]);

  const isHRPullAllowed = customerPullStatus?.customerHealthRecordStatus?.success;

  React.useEffect(() => {
    if (zip && county) {
      updatePullStatus();
    }
  }, [zip, county]);

  React.useEffect(() => {
    if (isHRPullAllowed && zip && county && customerId) fetchData(email, zip, county, customerId);
  }, [isHRPullAllowed, email, zip, county, customerId]);

  let data: BionicFetchHealthRecordsQueryData;

  if (isHRPullAllowed) {
    data = queryData ?? emptyResponse;
  } else {
    data = emptyResponse;
  }
  let loading: boolean;
  if (isHRPullAllowed === false) loading = false;
  else loading = queryLoading || customerPullStatusLoading;
  // If it's was not MARx verified, we should pretend nothing happened
  return {
    data,
    error: queryError,
    loading,
    pullStatus: customerPullStatus?.customerHealthRecordStatus,
  };
}
