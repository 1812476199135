import React from 'react';
import { useCurrentUserQuery } from '../../../graphql';
import { QueryLoader } from '../QueryLoader';
import { UserProfile } from '@hiq/crm.types';
import { Card } from '../Card';
import { isSalesDevelopmentRep } from '../../../util';

export const NonSDROnly: React.FunctionComponent = ({ children }) => {
  const { data, loading, error } = useCurrentUserQuery();
  const profile = data?.currentUser?.profile as UserProfile;
  if (!isSalesDevelopmentRep(profile)) {
    return (
      <QueryLoader loading={loading} error={error} componentName="NonSDROnly">
        { children }
      </QueryLoader>
    );
  }
  return (
    <>
      <h1 style={{ margin: '10px', textAlign: 'center' }}>TRM Home</h1>
      <Card>
        <p>
          Logged in user:
          <br />
          { data?.currentUser?.name?.fullFriendlyName }
        </p>
      </Card>
    </>
  );
};
