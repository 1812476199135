import { useEffect, useState } from 'react';
import { CallDirection } from '@healthiqeng/core.services.dialer.base';
import { CurrentTransferQueryData, useCurrentTransferQuery, useCustomerQuery } from '../../../../../graphql';
import { useActiveLeadId } from '../../../../routers';
import { getEventBus, VariationChangeEvent } from '../../../../../events';

export function useCurrentTransfer(): CurrentTransferQueryData['currentTransfer'] {
  const eventBus = getEventBus();
  const { data: customerData } = useCustomerQuery();
  const leadId = useActiveLeadId();
  const dialerActivityId = customerData?.customer?.dialerActivityId;
  const [pollInterval, setPollInterval] = useState(1000);
  const [fetchCurrentTransfer, {
    data, loading, refetch, called,
  }] = useCurrentTransferQuery(dialerActivityId, leadId);
  const [previousCallDirection, setPreviousCallDirection] = useState<CallDirection>();

  useEffect(() => {
    if (dialerActivityId) {
      fetchCurrentTransfer();
    }
  }, [dialerActivityId]);

  // Incremental polling, just like PollingTimer
  useEffect(() => {
    if (!loading && called) {
      setTimeout(() => {
        setPollInterval((pollInterval) => pollInterval * 2);
        refetch({ refreshContactData: true });
      }, pollInterval);
    }
  }, [called, loading]);

  useEffect(() => {
    if (data?.currentTransfer?.callDirection && data.currentTransfer.callDirection !== previousCallDirection) {
      setPreviousCallDirection(data.currentTransfer.callDirection);
      eventBus.emit(new VariationChangeEvent());
    }
  }, [data?.currentTransfer?.callDirection]);

  return data?.currentTransfer;
}
