export function encrypt(value: string) {
  let hash = 0;
  let chr;
  for (let i = 0; i < value.length; i++) {
    chr = value.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = ((hash << 5) - hash) + chr;
    // eslint-disable-next-line no-bitwise
    hash |= 0;
  }
  return hash;
}
