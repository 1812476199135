import { FormItemType } from '@hiq/crm.types';
import * as React from 'react';
import { FormItemFactoryProps } from './FormItemFactory.interfaces';
import { FormItemTypeForm } from '../FormItemTypeForm';
import { FormItemTypeGroup } from '../FormItemTypeGroup';
import { FormItemTypeArray } from '../FormItemTypeArray';
import { FormItemTypeFormReference } from '../FormItemTypeFormReference';
import { FormItemTypeCustom } from '../FormItemTypeCustom';
import { FormItemTypeSimpleText } from '../FormItemTypeSimpleText';

export const FormItemFactory: React.FunctionComponent<FormItemFactoryProps> = ({
  questionId,
  leadId,
  type,
  tags,
  visibleCondition,
  childrenVisibleCondition,
  children,
  depth,
  parentQuestionId,
  answers,
  onChange,
  locked,
  FormGeneratorQuestionFlow,
  arrayFormIndex,
  formId,
  minItems,
  maxItems,
  label,
  helpText,
  customElementId,
  overrideQuestionText,
}) => {
  switch (type) {
    case FormItemType.Form:
      return (
        <FormItemTypeForm
          leadId={leadId}
          questionId={questionId}
          arrayFormIndex={arrayFormIndex}
          formId={formId}
          tags={tags}
          helpText={helpText}
          visibleCondition={visibleCondition}
          childrenVisibleCondition={childrenVisibleCondition}
          FormItemFactory={FormItemFactory}
          FormGeneratorQuestionFlow={FormGeneratorQuestionFlow}
          depth={depth}
          parentQuestionId={parentQuestionId}
          answers={answers}
          onChange={onChange}
          locked={locked}
          overrideQuestionText={overrideQuestionText}
        >
          { children }
        </FormItemTypeForm>
      );
    case FormItemType.Group:
      return (
        <FormItemTypeGroup
          leadId={leadId}
          visibleCondition={visibleCondition}
          childrenVisibleCondition={childrenVisibleCondition}
          FormItemFactory={FormItemFactory}
          FormGeneratorQuestionFlow={FormGeneratorQuestionFlow}
          depth={depth}
          parentQuestionId={parentQuestionId}
          answers={answers}
          onChange={onChange}
        >
          { children }
        </FormItemTypeGroup>
      );
    case FormItemType.Array:
      return (
        <FormItemTypeArray
          label={label}
          leadId={leadId}
          depth={depth}
          FormGeneratorQuestionFlow={FormGeneratorQuestionFlow}
          formId={formId}
          answers={answers}
          onChange={onChange}
          minItems={minItems}
          maxItems={maxItems}
        />
      );
    case FormItemType.FormReference:
      return (
        <FormItemTypeFormReference
          label={label}
          leadId={leadId}
          depth={depth}
          FormGeneratorQuestionFlow={FormGeneratorQuestionFlow}
          formId={formId}
          answers={answers}
          onChange={onChange}
          visibleCondition={visibleCondition}
        />
      );
    case FormItemType.Custom:
      return (
        <FormItemTypeCustom
          referenceId={customElementId}
        />
      );
    case FormItemType.SimpleText:
      return (
        <FormItemTypeSimpleText
          tags={tags}
          depth={depth}
          answers={answers}
          parentQuestionId={parentQuestionId}
          label={label}
          visibleCondition={visibleCondition}
        />
      );
    default:
      return <div>{`${type} not implemented` }</div>;
  }
};
