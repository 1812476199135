import { useSunfireV2DrugDosagesQuery } from '../../../../../../graphql';
import React, { useEffect, useState } from 'react';
import { DosageSearchPanelProps } from './DosageSearchPanel.interfaces';
import { CheckboxInput } from '../../../../inputs';
import { SunfireDrugDosage } from '@hiq/crm.types';

import { QueryLoader } from '../../../../QueryLoader';
import { PaddingBottom } from '../../CheckMyHealthRecordDataGrid.elements';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { DosageLabel, DosageSearchPanelControlsContainer } from './DosageSearchPanel.elements';

export const DosageSearchPanel = ({
  onDosageSelected, drug, dosage, dosages: defaultDosages,
}: DosageSearchPanelProps) => {
  const [drugDosageOptions, setDrugDosageOptions] = useState(
    defaultDosages ? dosagesToCheckboxOptions(defaultDosages, dosage, dosage?.isGeneric) : [],
  );
  const [drugDosage, setDrugDosage] = useState<string>(dosage?.id);
  const [showGeneric, setShowGeneric] = useState(dosage?.isGeneric ?? true);

  const [
    findSunfireDrugDosages,
    {
      data: drugDosageData, loading, error,
    },
  ] = useSunfireV2DrugDosagesQuery(drug.id.toString());
  const dosages = drugDosageData?.findSunfireDrugDosages?.drugDosage?.dosages ?? defaultDosages;

  useEffect(() => {
    if (!defaultDosages) {
      findSunfireDrugDosages();
    }
  }, [drug, defaultDosages]);

  useEffect(() => {
    if (dosages?.length > 0) {
      const dosagesToSet = dosagesToCheckboxOptions(dosages, dosage, showGeneric);
      setDrugDosageOptions(dosagesToSet);
    }
  }, [dosages, dosage, showGeneric]);

  const handleOnChange = (values: string[]) => {
    const value = values[0];
    setDrugDosage(value);
    if (value) {
      const dosage: SunfireDrugDosage = dosages.find(({ id }) => id === value);
      onDosageSelected(dosage);
    } else {
      onDosageSelected();
    }
  };

  return (
    <QueryLoader loading={loading} error={error} componentName="DosageSearchPanel">
      <PaddingBottom>
        <DosageSearchPanelControlsContainer>
          <DosageLabel>Dosage</DosageLabel>
          {!dosage && (
            <FormControlLabel
              control={<Checkbox checked={showGeneric} onChange={(e) => setShowGeneric(e.target.checked)} />}
              label="Show Generic medications"
            />
          )}
        </DosageSearchPanelControlsContainer>
        <CheckboxInput
          options={drugDosageOptions}
          onChange={handleOnChange}
          value={drugDosage ? [drugDosage] : []}
        />
      </PaddingBottom>
    </QueryLoader>
  );
};

function dosagesToCheckboxOptions(dosages: SunfireDrugDosage[], dosage?: SunfireDrugDosage, showGeneric: boolean = false) {
  const dosagesToSet = dosages
    .filter((current) => {
      if (dosage) return current.id === dosage.id;
      return showGeneric ? true : !current.isGeneric;
    })
    .map((dosage: SunfireDrugDosage) => {
      const labelAppendix = dosage.isCommonDosage
        ? '&nbsp;&nbsp;&nbsp;&nbsp;* This is the most common dosage' : '';
      const label = `${dosage.name}${labelAppendix}`;
      return {
        label,
        value: dosage.id,
      };
    });
  dosagesToSet.sort((a, b) => (a.label < b.label ? -1 : 1));
  return dosagesToSet;
}
