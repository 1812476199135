import * as React from 'react';
import { AvailabilityBlockProps } from './AvailabilityBlock.interfaces';
import { AvailabilityBlockContainer } from './AvailabilityBlock.elements';
import moment from 'moment-timezone';
import { PrimaryButton } from '../../../../common/inputs/PrimaryButton';

export const AvailabilityBlock: React.FunctionComponent<AvailabilityBlockProps> = ({
  start,
  end,
  onClick,
}) => (
  <AvailabilityBlockContainer>
    <PrimaryButton onClick={() => onClick(start)} variant="outlined" fullWidth>
      { formatDateRange(start, end) }
    </PrimaryButton>
  </AvailabilityBlockContainer>
);

function formatDateRange(start: string, end: string) {
  const startLocal = moment(start).local().format('h:mm a');
  const endLocal = moment(end).local().format('h:mm a');
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const formattedTimezone = moment.tz(timeZone).format('z');
  return `${startLocal} - ${endLocal} ${formattedTimezone}`;
}
