import * as React from 'react';
import { logger } from '../../../services';
import { captureEvent } from '@sentry/gatsby';

export class ErrorBoundary extends React.Component<{}, { hasError: boolean; message: string; }> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false, message: '' };
  }

  static getDerivedStateFromError(error: Error) {
    return {
      hasError: true,
      message: error.message,
    };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    captureEvent({
      level: 'error',
      message: `[React ErrorBoundary] ${error.message}`,
      extra: {
        ...error,
        errorInfo,
      },
      tags: {
        type: 'react',
      },
    });
    logger.crit(error);
  }

  render() {
    const { hasError, message } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <div>
          <p>
            Something went wrong...
          </p>
          <pre>
            { message }
          </pre>
        </div>
      );
    }
    return children;
  }
}
