import * as React from 'react';
import { IEventBusEvent } from '@healthiqeng/core.services.event-bus';
import { UseEventInProgressHook, EventMatcher } from './useEventInProgress.interfaces';
import { getEventBus } from '../getEventBus';

export const useEventInProgress: UseEventInProgressHook = (startTrigger, endTrigger, deps = []) => {
  const [inProgress, setInProgress] = React.useState(false);
  const eventBus = getEventBus();

  React.useEffect(() => {
    const handleEvent = (event: IEventBusEvent) => {
      if (matchesEvent(startTrigger, event)) {
        setInProgress(true);
      }

      if (matchesEvent(endTrigger, event)) {
        setInProgress(false);
      }
    };
    eventBus.subscribe(handleEvent);
    return () => eventBus.unsubscribe(handleEvent);
  }, deps);

  return inProgress;
};

function matchesEvent(trigger: EventMatcher, event: IEventBusEvent): boolean {
  if (typeof trigger === 'function' && isCallable(trigger, event)) {
    return trigger(event);
  }

  if (Array.isArray(trigger)) {
    return trigger.some((t) => event instanceof t);
  }
  return event instanceof trigger;
}

function isCallable(fn: any, event: IEventBusEvent) {
  try {
    fn(event);
    return true;
  } catch (error) {
    return false;
  }
}
