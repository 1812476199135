import { InputTransformer } from '../InputTransformer';
import { SelectOption } from '../../../../../types';

export class SelectTransformer extends InputTransformer<any> {
  private options: SelectOption[];
  constructor(initialValue: any, options: SelectOption[]) {
    super(initialValue);
    this.draftValue = initialValue;
    this.options = options;
  }

  public update(nextValue: any) {
    this.draftValue = nextValue;
    this.persistedValue = nextValue;
  }

  public getDraftValue(): any {
    return this.draftValue;
  }

  public getPersistedValue(): any {
    return this.persistedValue;
  }

  public getFormattedValue(): any {
    if (!this.options) {
      return this.persistedValue;
    }
    return this.options
      .find(({ value }) => value === this.persistedValue)?.label
      ?? this.persistedValue;
  }
}
