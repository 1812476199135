import { gql } from 'apollo-boost';

export const COMPARE_RULE_FRAGMENT = gql`
  fragment SimpleCompareRuleFragment on SimpleCompareRule {
    field
    operator
    value
    compareQuestionId
  }

  fragment CompositeCompareRuleFragment on CompositeCompareRule {
    composableOperator
    operands {
      ...SimpleCompareRuleFragment
    }
  }
`;
