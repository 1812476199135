import React, { useCallback } from 'react';
import { HealthRecordGrid } from '../../HealthRecordGrid';
import { OverlayToolbar } from '../../OverlayToolbar';
import {
  useDeleteOverlayMutation, useGetFutureBenefitOptions, useUpsertOverlayMutation,
} from '../../../../../../graphql';
import { useActiveLeadId, useCustomerId } from '../../../../../routers';
import { useDeleteRows } from '../../useSelectToDeleteRows';
import { AddAnticipatedNeedPanel } from '../AddAnticipatedNeeds/AddAnticipatedNeedPanel';
import { HealthRecordAnticipatedNeed } from '@hiq/crm.types';
import { useToggle } from '../../useToggle';
import { AnticipatedNeedsGridProps } from './AnticipatedNeedsGrid.interfaces';
import { useAnticipatedNeedsColumnsConfig } from './useAnticipatedNeedsColumnsConfig';
import { useDebounce } from '@healthiqeng/core.hooks.use-debounce';
import { InfoBlock } from '../../../../ScriptBlock';
import { useFormAnswers } from '../../../../../../hooks';
import { RadioYesNoInput } from '../../../../inputs/RadioYesNoInput';
import { QuestionContainer } from './AnticipatedNeedsGrid.elements';
import { getEventBus, SunfireV2DataChangeEvent } from '../../../../../../events';

const HAS_ANTICIPATED_NEEDS_KEY = 'lead.sunfire.v2.hasAnticipatedNeeds';

export const AnticipatedNeedsGrid = ({ anticipatedNeedsOverlays, cellWidth, isReduceViewPort }: AnticipatedNeedsGridProps) => {
  const leadId = useActiveLeadId();
  const [deleteFromOverlay] = useDeleteOverlayMutation();
  const [isAdding, toggleIsAdding] = useToggle();
  const [upsertOverlayAnticipatedNeed] = useUpsertOverlayMutation();
  const futureBenefitOptions = useGetFutureBenefitOptions();
  const eventBus = getEventBus();

  const customerId = useCustomerId();
  const [answers, onChange] = useFormAnswers(leadId, true);
  const hasAnticipatedNeeds = answers[HAS_ANTICIPATED_NEEDS_KEY];
  const getAnticipatedNeedRowId = React.useCallback(
    (data: Partial<HealthRecordAnticipatedNeed>) => data.code, [],
  );

  const [selectedToDelete, onAnticipatedNeedSelection, isRemoving, toggleRemoving] = useDeleteRows<HealthRecordAnticipatedNeed>(
    getAnticipatedNeedRowId,
  );

  const onDelete = useCallback(() => {
    selectedToDelete.forEach((code) => {
      const overlay = anticipatedNeedsOverlays?.find((overlay) => getAnticipatedNeedRowId(overlay.data) === code);
      deleteFromOverlay({ id: overlay.id });
      onAnticipatedNeedSelection(overlay?.data);
    });
    eventBus.emit(new SunfireV2DataChangeEvent());
    toggleRemoving();
  }, [selectedToDelete, anticipatedNeedsOverlays]);

  const onChangeQuantity = useCallback((code: string, quantity: number) => {
    const overlay = anticipatedNeedsOverlays?.find((overlay) => getAnticipatedNeedRowId(overlay.data) === code);
    upsertOverlayAnticipatedNeed({
      input: {
        id: overlay.id,
        customerId,
        externalId: code,
        externalType: 'HealthRecordAnticipatedNeed',
        data: {
          ...overlay.data, quantity,
        },
      },
    });
    eventBus.emit(new SunfireV2DataChangeEvent());
  }, [customerId, anticipatedNeedsOverlays]);

  const debouncedOnChangeQuantity = useDebounce(onChangeQuantity, 500);

  const columns = useAnticipatedNeedsColumnsConfig(
    { cellWidth, onChangeQuantity: debouncedOnChangeQuantity, isReduceViewPort },
  );

  const futureBenefit = React.useMemo(() => {
    const ids = anticipatedNeedsOverlays?.map((overlay) => getAnticipatedNeedRowId(overlay.data)) ?? [];
    return futureBenefitOptions?.data?.getFutureBenefitOptions?.filter((option) => !ids.includes(option.code)) ?? [];
  }, [anticipatedNeedsOverlays, futureBenefitOptions]);

  const updateHasAnticipatedNeeds = useCallback((value: boolean) => {
    if (value) toggleIsAdding();
    onChange(HAS_ANTICIPATED_NEEDS_KEY, value);
    eventBus.emit(new SunfireV2DataChangeEvent());
  }, [onChange]);

  const needsGrid = React.useMemo(() => (
    <HealthRecordGrid
      getRowId={getAnticipatedNeedRowId}
      enableSelection
      selectedToDelete={selectedToDelete}
      onSelectToDelete={onAnticipatedNeedSelection}
      data={[]}
      overlays={anticipatedNeedsOverlays}
      columns={columns}
      emptyOverlaysMessage="No Anticipated Needs"
      isRemoving={isRemoving}
      hideGridTitle
      loading={false}
      alwaysIncluded={!isRemoving}
      overlayToolbar={(
        <OverlayToolbar
          panel={<AddAnticipatedNeedPanel setPanelClosed={toggleIsAdding} futureBenefits={futureBenefit} />}
          showPanel={isAdding}
          toggleShowPanel={toggleIsAdding}
          isRemoving={isRemoving}
          addButtonText="Add Anticipated Need"
          removeButtonText="Remove Anticipated Need"
          toggleRemove={toggleRemoving}
          onConfirmRemove={onDelete}
          removeDisabled={!selectedToDelete?.length}
        />
            )}
    />
  ), [getAnticipatedNeedRowId, selectedToDelete,
    onAnticipatedNeedSelection, isRemoving, isAdding, toggleIsAdding,
    toggleRemoving, onDelete, anticipatedNeedsOverlays, columns, futureBenefit]);
  return (
    <div>
      <QuestionContainer>
        <InfoBlock>
          Are there any medical procedures, services,
          or products that you anticipate needing in the next 12 months?
          This might be something like CT scans, acupuncture, or walkers.
        </InfoBlock>
        <RadioYesNoInput
          value={hasAnticipatedNeeds}
          onChange={updateHasAnticipatedNeeds}
        />
      </QuestionContainer>
      {hasAnticipatedNeeds && needsGrid}
    </div>
  );
};
