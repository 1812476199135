import * as React from 'react';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';

import { NavBarAppointmentProps, NavBarContentVariation } from './NavBarAppointment.interface';
import {
  NavBarAppointmentContainer,
  NavBarAppointmentContent,
  NavBarAppointmentTitle,
} from './NavBarAppointment.elements';

export const NavBarAppointment: React.FunctionComponent<NavBarAppointmentProps> = (
  { type, title, appointment },
) => {
  if (!appointment) return null;

  let variation = NavBarContentVariation.Primary;

  const appointmentTime = moment(appointment.appointmentTime);
  const { callAttemptNum } = appointment.detail;

  switch (type) {
    case 'last':
      if (!callAttemptNum || callAttemptNum === 0) {
        variation = NavBarContentVariation.Critical;
      }
      break;
    case 'next':
      if (appointmentTime.isBefore(moment().add(1, 'minute'))) {
        variation = NavBarContentVariation.Critical;
      } else if (appointmentTime.isBefore(moment().add(5, 'minute'))) {
        variation = NavBarContentVariation.Warning;
      }
      break;
    default:
      break;
  }

  const name = appointment.lead?.customer?.person?.name;

  return (
    <NavBarAppointmentContainer>
      <NavBarAppointmentTitle>
        { title }
      </NavBarAppointmentTitle>
      <Tooltip title={`${name?.first} ${name?.last}`} arrow placement="bottom">
        <NavBarAppointmentContent
          variation={variation}
          onClick={() => {
            const customer = appointment?.lead?.customer;
            if (!customer?.id) return;

            window.open(`/customer/${customer.id}`, '_blank');
          }}
        >
          {
            `${name?.first?.length > 6 ? `${name?.first?.slice(0, 6)}...` : name?.first || ''} 
             ${name?.last?.[0] || ''}.`
          }
          &nbsp;
          {
            appointment?.appointmentTime
            && moment.utc(appointment.appointmentTime).local()
              .format('M/D/YYYY h:mm a')
          }
        </NavBarAppointmentContent>
      </Tooltip>
    </NavBarAppointmentContainer>
  );
};
