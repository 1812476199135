import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const UnderwritingResultContaner = styled('div')(css`
  padding: 10px;
  margin: 30px 10px 0;
`);

export const UnderwritingResultRow = styled('h4')(css`
  margin: 0 0 5px;
  font-weight: normal;
`);

export const UnderwritingValidationWarningContainer = styled('section')(css`
  padding: 20px 10px;
  margin: 0;
`);

export const UnderwritingValidationWarning = styled('div')(css`
  padding: 2px 0;
`);
