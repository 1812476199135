import {
  Bold,
  ButtonsContainer,
  DiagnosisContent,
  DiagnosisContentWrapper,
  Divider,
  Feature,
  SpaceBetween,
  ButtonContainer,
} from '../Diagnoses.elements';
import { Checkbox } from '@material-ui/core';
import { Icon, IconProps } from '../../../common';
import React from 'react';
import { encrypt } from '../../../../util';
import { useFormAnswers } from '../../../../hooks';

export const DiseaseBenefit = (
  { diseaseKey, benefit, context }:
  { diseaseKey: string, benefit: string, context: string },
) => {
  const hashedKey = React.useMemo(
    () => encrypt(`${diseaseKey}-${context}-${benefit}`),
    [diseaseKey, benefit, context],
  );
  const IS_DONE_ANSWER_KEY = `lead.sunfire.v2.disease-benefit-${hashedKey}.done`;
  const THUMBS_UP_ANSWER_KEY = `lead.sunfire.v2.disease-benefit-${hashedKey}.thumbsUp`;

  const [answers, saveFormAnswers] = useFormAnswers();
  const isDone = answers[IS_DONE_ANSWER_KEY] ?? false;
  const thumbsUp = answers[THUMBS_UP_ANSWER_KEY];

  let thumbsUpIcon: IconProps['type'];
  let thumbsDownIcon: IconProps['type'];
  if (thumbsUp) {
    thumbsDownIcon = 'thumbs-down-disabled';
    thumbsUpIcon = 'thumbs-up-enabled';
  } else if (thumbsUp === false) {
    thumbsDownIcon = 'thumbs-down-enabled';
    thumbsUpIcon = 'thumbs-up-disabled';
  } else { // thumbsUp === undefined
    thumbsDownIcon = 'thumbs-down';
    thumbsUpIcon = 'thumbs-up';
  }
  return (
    <DiagnosisContentWrapper>
      <DiagnosisContent>
        <Bold>Context:</Bold>
        &nbsp;
        {context}
      </DiagnosisContent>
      <DiagnosisContent>
        <Bold><Feature>Feature:</Feature></Bold>
        &nbsp;
        <Feature>{benefit}</Feature>
      </DiagnosisContent>
      <Divider />
      <SpaceBetween>
        <ButtonContainer onClick={() => saveFormAnswers(IS_DONE_ANSWER_KEY, !isDone)}>
          <Checkbox checked={isDone} />
          <Bold>Done</Bold>
        </ButtonContainer>
        <ButtonsContainer>
          <ButtonContainer onClick={() => saveFormAnswers(THUMBS_UP_ANSWER_KEY, true)}>
            <Icon type={thumbsUpIcon} />
          </ButtonContainer>
          <ButtonContainer onClick={() => saveFormAnswers(THUMBS_UP_ANSWER_KEY, false)}>
            <Icon type={thumbsDownIcon} />
          </ButtonContainer>
        </ButtonsContainer>
      </SpaceBetween>

    </DiagnosisContentWrapper>
  );
};
