import * as React from 'react';
import { Box, Button, Radio } from '@material-ui/core';
import { DispositionModalProps } from './DispositionModalContent.interfaces';
import { Paragraph, Header } from './DispositionModalContent.elements';
import { ChangeEvent } from 'react';
import { useDialerService } from '@healthiqeng/core.hooks.use-dialer-service';
import { AppointmentsList } from './AppointmentsList';
import { DividerContainer } from '../Divider/Divider.elements';

export const DispositionModalContent: React.FC<DispositionModalProps> = ({
  contentText,
  onClose,
  onClickSave,
  upcomingAppointments,
  missedAppointments,
}) => {
  const { dialerService } = useDialerService();
  const defaultCampaign = 'Health IQ Manual Outbound';
  const NO_CALL_LABEL = 'Save and close';
  const CALL_LABEL = 'Save and call';
  const [saveButtonLabel, setSaveButtonLabel] = React.useState(NO_CALL_LABEL);
  const NUMBER_INDEX = 0;
  const LEAD_INDEX = 2;
  const CUSTOMER_INDEX = 3;
  const [appointmentNumberToCall, setAppointmentNumberToCall] = React.useState(null);
  const [appointmentSelected, setAppointmentSelected] = React.useState(null);
  const [customerId, setCustomerId] = React.useState(null);
  const [leadId, setLeadId] = React.useState(null);
  const [noCallSelected, setNoCallSelected] = React.useState(false);

  const handleNumberSelection = React.useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const selections = value.split('_');
    setAppointmentNumberToCall(selections[NUMBER_INDEX]);
    setCustomerId(selections[CUSTOMER_INDEX]);
    setLeadId(selections[LEAD_INDEX]);
    setAppointmentSelected(value);
    setSaveButtonLabel(CALL_LABEL);
  }, []);

  const redirectToCustomerBeingCalled = () => {
    window.location.href = `/customer/${customerId}/lead/${leadId}`;
  };

  const handleDispositionAndCallNextAppointment = async () => {
    onClickSave();
    try {
      await dialerService.makeExternalCall(appointmentNumberToCall, defaultCampaign);
      onClose();
      redirectToCustomerBeingCalled();
    } catch (e) {
      onClose();
    }
  };

  const handleSetNoCallSelected = () => {
    setAppointmentNumberToCall(null);
    setAppointmentSelected(null);
    setNoCallSelected(true);
    setCustomerId(null);
    setLeadId(null);
    setSaveButtonLabel(NO_CALL_LABEL);
  };

  return (
    <>
      <Paragraph>
        {contentText}
      </Paragraph>
      {
        ((upcomingAppointments && upcomingAppointments.length > 0)
          || (missedAppointments && missedAppointments.length > 0))
        && (
        <>
          <DividerContainer />
          <Header>Up Next</Header>
          <Box display="flex" justifyContent="flex-start" alignItems="flex-end">
            <Radio
              value={null}
              color="primary"
              checked={noCallSelected && !appointmentSelected}
              onChange={handleSetNoCallSelected}
            />
            <Paragraph>
              Don’t call appointment
            </Paragraph>
          </Box>
        </>
        )
      }
      {
        upcomingAppointments && upcomingAppointments.length > 0
        && (
        <AppointmentsList
          headerText="Upcoming appointment, click to call"
          appointments={upcomingAppointments}
          handleNumberSelection={handleNumberSelection}
          appointmentSelected={appointmentSelected}
        />
        )
      }
      {
        missedAppointments && missedAppointments.length > 0
        && (
        <AppointmentsList
          headerText="Missed appointments for today, click to call"
          appointments={missedAppointments}
          handleNumberSelection={handleNumberSelection}
          appointmentSelected={appointmentSelected}
        />
        )
      }

      <Box display="flex" justifyContent="flex-end" paddingTop="10px">
        <Button
          variant="outlined"
          style={{ marginRight: 5 }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={appointmentSelected === null && !noCallSelected
            && (missedAppointments.length > 0 || upcomingAppointments.length > 0)}
          style={{ color: 'white' }}
          onClick={saveButtonLabel === CALL_LABEL
            ? handleDispositionAndCallNextAppointment
            : onClickSave}
        >
          {saveButtonLabel}
        </Button>
      </Box>
    </>
  );
};
