import * as React from 'react';
import { useCustomerQuery, ConvertSuccessEvent, useLeadQuery } from '../../../graphql';
import { QueryLoader } from '../../common';
import { CustomerName } from './CustomerName';
import { CustomerSummaryProps } from './CustomerSummary.interfaces';
import { CustomerSummaryContainer } from './CustomerSummary.elements';
import { CustomerTags } from './CustomerTags';
import { TransferNotes } from './TransferNotes';
import { CustomerDemographic } from './CustomerDemographic';
import { useEventListener } from '../../../events';
import { useActiveLeadId } from '../../routers';
import { RelatedCustomers } from './RelatedCustomers';
import { CustomerCallCompliance } from './CustomerCallCompliance';
import { ComponentName } from '../../common/Page/PageLoadDetector';
import { LeadOwner } from './LeadOwner';
import { ReportCallQuality } from './ReportCallQuality/ReportCallQuality';

export const CustomerSummary: React.FunctionComponent<CustomerSummaryProps> = ({ isBionic }) => {
  const {
    loading,
    error,
    data,
    refetch,
  } = useCustomerQuery();
  const leadId = useActiveLeadId();
  useEventListener(ConvertSuccessEvent, () => refetch());
  const { data: leadData } = useLeadQuery(leadId);

  return React.useMemo(() => (
    <QueryLoader loading={loading} error={error} componentName={ComponentName.CustomerSummary}>
      <CustomerSummaryContainer data-qa="customer-summary-panel">
        <CustomerName name={data?.customer?.person?.name} />
        <CustomerCallCompliance />
        <ReportCallQuality />
        <CustomerTags
          tags={data?.customer?.tags || []}
          stateCode={data?.customer?.person?.homeAddress?.state?.code}
        />
        <LeadOwner leadId={leadId} />
        <CustomerDemographic leadId={leadId} isBionic={isBionic} />
        <RelatedCustomers />
        <TransferNotes />
      </CustomerSummaryContainer>
    </QueryLoader>
  ), [
        data?.customer?.person?.name,
        JSON.stringify(data?.customer?.tags),
        data?.customer?.person?.homeAddress?.state?.code,
        loading,
        error,
        leadId,
        leadData?.owner,
        isBionic,
  ]);
};
