import * as React from 'react';
import { Router } from '@reach/router';
import { DialerServiceProvider } from '@healthiqeng/core.hooks.use-dialer-service';
import { CustomerRouterProps } from './CustomerRouter.interfaces';
import { CustomerContextContainer } from './CustomerContextContainer';
import { CustomerSalesforceRedirect } from './CustomerSalesforceRedirect';
import { CustomerNotFound } from './CustomerNotFound';
import { CustomerInitialContactRedirect } from './CustomerInitialContactRedirect';
import { DialerTransferProvider, UIToolsContextProvider } from '../../contexts';
import { eventBus } from '../../../services';
import { config } from '../../../config';

export const CustomerRouter: React.FunctionComponent<CustomerRouterProps> = ({
  children,
  initialContactContent,
}) => {
  const style = React.useMemo(() => ({ height: '100%' }), []);
  return (
    <UIToolsContextProvider>
      <DialerTransferContextWrapper>
        <Router style={style} basepath="/customer">
          <CustomerSalesforceRedirect path="/sf/:salesforceId" />
          <CustomerInitialContactRedirect path="/initial" content={initialContactContent} />
          <CustomerContextContainer path="/:customerId/*" content={children} />
          <CustomerNotFound default />
        </Router>
      </DialerTransferContextWrapper>
    </UIToolsContextProvider>
  );
};

const DialerTransferContextWrapper: React.FC = ({ children }) => (
  <DialerServiceProvider useMock={config.USE_MOCK.DIALER_SERVICE} eventBus={eventBus}>
    <DialerTransferProvider>
      {children}
    </DialerTransferProvider>
  </DialerServiceProvider>
);
