import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const CollapsedColumnWrapper = styled('div')(css`
  display: flex;
  align-items: center;
  background: #DEE2E5;
  height: 100%;
  place-content: center;
  flex-direction: column;
  width: 100%;
  padding: 8px 4px;
  border: 1px solid #ABABAB;
  justify-content: end;
  z-index: 2;
  cursor: pointer;
`);

export const CollapsedColumnExpandButton = styled('div')(css`
  background: #F3F5F7;
  box-sizing: border-box;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  width: 36px;
  height: 160px;
  text-align: center;
  place-content: start;
  cursor: pointer;
`);

export const CollapsedColumnTextWrapper = styled('div')(css`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
`);

export const CollapsedColumnText = styled('div')(css`
  writing-mode: vertical-lr;
  text-orientation: mixed;
  transform: rotate(180deg);
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #4A4A4A;
`);

export const CollapsedColumnTextArrows = styled('div')(css`
  margin-top: 20px;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #4A4A4A;
`);

export const CollapseRow = styled('div')(css`
  display: flex;
  background: #DEE2E5;
  color: white;
  cursor: pointer;
  justify-content: center;
  bottom: 0;
  width: 100%;
  border: 1px solid #ABABAB;
  z-index: 2;
  position: sticky;
`);

export const CollapseRowButton = styled('div')(css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #F3F5F7;
  border: 1px solid #B1B7BA;
  box-sizing: border-box;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  width: 220px;
  margin: 6px 0;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #4A4A4A;
  height: 36px;
`);
