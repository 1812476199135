import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { ComponentWithThemeProps } from '../../../../themes';
import CallMadeIcon from '@material-ui/icons/CallMade';
import CloseIcon from '@material-ui/icons/Close';

export const InlineTextContainer = styled('div')<ComponentWithThemeProps>(
  () => css`
  font-size: 16px!important;
  `,
);

export const InlineTextButtonContainer = styled('div')<ComponentWithThemeProps>(
  () => css`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    row-gap: 10px;
  `,
);

export const InlineTextButton = styled('div')<ComponentWithThemeProps>(
  ({ theme }) => css`
    border-radius: 10px;
    padding: 10px;
    background-color: ${theme.color.lightYellow};
    border: 1px solid ${theme.color.grayDark};
    margin-right: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
  `,
);

export const InlineTextContent = styled('div')<ComponentWithThemeProps>(
  ({ theme }) => css`
    border-radius: 5px;
    padding: 10px;
    background-color: ${theme.color.grayBackground};
  `,
);

export const InlineTextButonIconOpen = styled(CallMadeIcon)<ComponentWithThemeProps>(
  ({ theme }) => css`
    color: ${theme.color.primary};
    margin-left: 10px;
  `,
);

export const InlineTextButonIconClose = styled(CloseIcon)<ComponentWithThemeProps>(
  ({ theme }) => css`
    color: ${theme.color.primary};
    margin-left: 10px;
  `,
);
