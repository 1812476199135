import styled from '@emotion/styled';

export const HealthGridTopBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HealthGridTopBarTitle = styled.h2`
  font-weight: 500;
`;

export const HealthGridTopBarSubtitle = styled.h4`
  font-size: 18px;
  color: #4A4A4A;
  margin: 5px 0;
`;

export const HealthGridTopBarActionContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: right;
`;

export const HealthGridTopBarActionLabel = styled.span`
  min-width: 100px;
  padding-right: 10px;
`;

export const HealthGridTopBarActionComponentContainer = styled.div`
  width: 190px;
`;
