import * as React from 'react';
import { RadioGroup, Radio } from '@material-ui/core';
import { RadioInputProps } from './RadioInput.interfaces';
import { ChangeEvent } from 'react';
import { FormControlLabel } from './RadioInput.elements';
import { InputWidthLimiterContainer } from '../../InputWidthLimiterContainer';

export const RadioInput: React.FunctionComponent<RadioInputProps> = ({
  value,
  onChange,
  options,
  disabled,
  dataQa,
}) => {
  const handleChange = React.useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onChange(options[Number.parseInt(event.target.value, 10)]?.value);
  }, [onChange, options]);

  return (
    <RadioGroup row data-qa={dataQa}>
      <InputWidthLimiterContainer>
        { options.map((option, index) => (
          <FormControlLabel
            labelPlacement="end"
            key={`${option.value}` || ''}
            control={(
              <Radio
                value={index}
                onChange={handleChange}
                color="primary"
              />
          )}
            label={option.label}
            checked={value === option.value}
            disabled={disabled}
            data-qa={option.label}
          />
        )) }
      </InputWidthLimiterContainer>
    </RadioGroup>
  );
};
