import { SoldLeadInfoQueryData } from '../../../../graphql';

export function isFormLocked(soldLeadInfo: SoldLeadInfoQueryData, referenceId: string): boolean {
  return soldLeadInfo?.soldLeadInfo?.isLocked && !NON_LOCKABLE_FORMS.includes(referenceId);
}

const NON_LOCKABLE_FORMS = [
  'retention-welcome-details',
  'senior-life-retention-pending-lapse-cuna-details',
  'senior-life-retention-pending-lapse-gerber-details',
  'medicare-retention-day-thirty-details',
  'medicare-retention-day-sixty-plus-details',
  'medicare-retention-humana-survey-details',
  'medicare-advantage-closing',
  'customer-summary-panel',
  'health-record-digital-product-consent',
  'health-record-digital-product-track',
];
