import * as React from 'react';
import { FormGeneratorCompactButtonsProps } from './FormGeneratorCompactButtons.interfaces';
import {
  FormGeneratorCompactButtonsContainer,
  ButtonContainer,
} from './FormGeneratorCompactButtons.elements';
import { PrimaryButton } from '../../../inputs/PrimaryButton';
import { SecondaryButton } from '../../../inputs/SecondaryButton';

export const FormGeneratorCompactButtons: React.FunctionComponent<FormGeneratorCompactButtonsProps> = ({
  onSave,
  onClear,
}) => (
  <FormGeneratorCompactButtonsContainer>
    <ButtonContainer>
      <PrimaryButton
        onClick={onSave}
        variant="contained"
        fullWidth
      >
        Save
      </PrimaryButton>
    </ButtonContainer>
    <ButtonContainer>
      <SecondaryButton
        onClick={onClear}
        variant="contained"
        fullWidth
      >
        Cancel
      </SecondaryButton>
    </ButtonContainer>
  </FormGeneratorCompactButtonsContainer>
);
