import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  SunfireV2FindDrugsByTermQueryData,
  SunfireV2FindDrugsByTermQueryVariables,
} from './useSunfireV2DrugsByTermQuery.interfaces';

const SUNFIRE_DRUGS_QUERY = gql`
  query FindSunfireV2DrugsByTerm($term: String!) {
    findSunfireDrugsByTerm(term: $term) {
      id
      name
      genericId
    }
  }
`;

export function useSunfireV2DrugsByTermQuery() {
  return useLazyQuery<SunfireV2FindDrugsByTermQueryData, SunfireV2FindDrugsByTermQueryVariables>(SUNFIRE_DRUGS_QUERY);
}
