import * as React from 'react';
import { useCustomerQuery } from '../../../../graphql';
import { useCustomerId } from '../../../routers';
import { useDialerTransferButtons } from '../../../common/DialerTransferButtons/useDialerTransferButtons';
import { DNCPhoneNumberEditor, useGetLeadDNCPhoneNumbers } from '../../../common/DNCPhoneNumberEditor';
import { useAddNumbersToDoNotCall } from '../../../../graphql/mutations/useAddNumbersToDoNotCallMutation';
import {
  DNCDialogWrapperContainer,
} from './DispostionDialogeSelect.elements';
import { Snackbar, Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { WaitingToEndLoader } from './WaitingToEndLoader';
import { emitAnalyticsEvent } from '../../../../events/emitAnalyticsEvent';
import { useDialerService, captureDialerException } from '@healthiqeng/core.hooks.use-dialer-service';
import { useDialerTransfer } from '../../../common/DialerTransferButtons/useDialerTransferButtons/useDialerTransfer';
import { DispositionModalContent } from '../../../common/DispositionModal';
import { GenericModal } from '../../../common/GenericModal';
import { useMostRecentAppointmentsQuery } from '../../../../graphql/queries/useMostRecentAppointmentsQuery';

type DNCModalWrapperProps = {
  open: boolean;
  onClose?: () => void;
};

export const DNCModalWrapper: React.FC<DNCModalWrapperProps> = ({ open, onClose }) => {
  const customerId = useCustomerId();
  const { data } = useCustomerQuery(customerId);
  const leadState = data?.customer?.person?.homeAddress?.state?.code;
  const [dncPhoneNumbers] = useGetLeadDNCPhoneNumbers();
  const [addNumbers] = useAddNumbersToDoNotCall({});
  const { dialerService } = useDialerService();
  const { currentCallInProgress, endingCall } = useDialerTransferButtons();
  const { selectedDisposition } = useDialerTransfer();
  const { data: appointments } = useMostRecentAppointmentsQuery();
  const [isLoader, setLoader] = React.useState(false);
  const [dncSaved, setDncSave] = React.useState(false);
  if (!isLoader && !currentCallInProgress && endingCall) {
    setLoader(true);
  }

  function handleClose() {
    onClose();
  }

  async function onSave() {
    try {
      await addNumbers({ input: { phoneNumbers: dncPhoneNumbers } });
      await dialerService.endCall(selectedDisposition);
      emitAnalyticsEvent('Disposition Call', { disposition: selectedDisposition });
      setLoader(false);
      setDncSave(true);
      onClose();
    } catch (err) {
      captureDialerException(err, 'endCall', dialerService.getCallData());
    }
  }

  function handleCloseAlert() {
    setDncSave(false);
  }

  if (!currentCallInProgress && endingCall) return <WaitingToEndLoader />;

  function getStateDoNotCallDisclaimerText(state: string): string | null {
    switch (state) {
      case 'WA':
        // eslint-disable-next-line max-len
        return 'Per WA Law, your contact information will be removed from Health IQ call lists for at least one year. Can you confirm which numbers you would like added in our Do Not Call list?';
      default:
        return null;
    }
  }

  return (
    <DNCDialogWrapperContainer>
      <Snackbar
        open={dncSaved}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="success"> Saved DNC Numbers </Alert>
      </Snackbar>
      {isLoader && <WaitingToEndLoader />}

      <GenericModal
        open={open}
        headerText="DNC Customer"
        modalContent={(
          <>
            { getStateDoNotCallDisclaimerText(leadState)
              && <p style={{ color: 'red' }}>{ getStateDoNotCallDisclaimerText(leadState) }</p> }
            <Box padding={1} marginY={2} border={1} borderRadius={10} borderColor="lightgrey">
              <DNCPhoneNumberEditor />
            </Box>
            <DispositionModalContent
              onClose={handleClose}
              onClickSave={onSave}
              upcomingAppointments={appointments?.upcomingAgentAppointments?.filter(Boolean)}
              missedAppointments={appointments?.missedAgentAppointments?.filter(Boolean)}
            />
          </>
        )}
      />
    </DNCDialogWrapperContainer>
  );
};
