import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../themes';

export const ManagerHomeLayoutContainer = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  background-color: ${theme.color.white};
`);

export const NavBarContainer = styled('div')(css`
  width: 100%;
`);

export const MainPanelContainer = styled('div')(css`
  width: 100%;
  height: 100%;
`);
