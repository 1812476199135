import styled from '@emotion/styled';
import { DialogContent } from '@material-ui/core';

export const DetailsContent = styled(DialogContent)`
  padding: 16px; !important
`;

export const DetailsTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;
