import * as React from 'react';
import { QuestionTagsProps } from './QuestionTags.interfaces';
import { QuestionTagAsterisk } from './QuestionTags.elements';

export const QuestionTags: React.FunctionComponent<QuestionTagsProps> = ({
  tags,
}) => {
  const isRequired = tags?.some((tag) => tag.startsWith('REQUIRED'));
  if (!isRequired) return null;
  return (
    <QuestionTagAsterisk>*</QuestionTagAsterisk>
  );
};
