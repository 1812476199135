import { useEffect } from 'react';
import { IEventBusEvent } from '@healthiqeng/core.services.event-bus';
import { eventBus } from '../../services';

export function useEventListener<T extends IEventBusEvent>(
  EventClass: any,
  onEvent: (event: T) => void,
  deps: any[] = [],
) {
  useEffect(() => {
    const listener = (event: T) => {
      if (event instanceof EventClass) {
        onEvent(event);
      }
    };
    eventBus.subscribe(listener);
    return () => eventBus.unsubscribe(listener);
  }, deps);
}
