import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../../themes';
import { css } from '@emotion/core';

export const ElectronicSalesQualifierHomeContainer = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  height: 100%;
  width: 100%;
  padding: 0 10px;
  background-color: ${theme.color.grayBackground};
  display: flex;
  flex-direction: row;
  overflow: hidden;
`);

export const ElectronicSalesQualifierHomeCard = styled('div')<ComponentWithThemeProps & {width?: string}>(({ width, theme }) => css`
  height: 100%;
  padding: 0 10px;
  background-color: ${theme.color.grayBackground};
  display: flex;
  width: ${width};
  flex-direction: column;
  overflow: auto;
  grid-gap: 10px;
`);

export const HomeLinkContainer = styled('div')(css`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 10px;
  width: 100%;
`);

export const HomeLinkTitle = styled('span')<ComponentWithThemeProps>(({ theme }) => css`
  color: ${theme.color.text};
  font-weight: 600;
  font-size: 18px;
  margin-left: 10px;
`);
