import React, { FC } from 'react';
import { ProductTypePropertyAndCasualty } from '@hiq/crm.types';
import { useActiveLeadId } from '../../../../../routers';
import {
  PropertyAndCasualtyLeadStages,
  FormGenerator,
  FormGeneratorStyle,
  QuestionFlow,
} from '../../../../../common';

type Props = {
  converted?: boolean;
  productType: ProductTypePropertyAndCasualty;
};

export const PropertyAndCasualtyProducerStatementPanel: FC<Props> = ({ productType, converted }) => {
  const leadId = useActiveLeadId();
  const isAuto = productType === ProductTypePropertyAndCasualty.Auto;

  if (converted) {
    return (
      <>
        <FormGenerator
          leadId={leadId}
          referenceId="pnc-backend-producer-statement"
          style={FormGeneratorStyle.Compact}
          lockable
        />
        <PropertyAndCasualtyLeadStages />
      </>
    );
  }

  return (
    <>
      <QuestionFlow
        leadId={leadId}
        referenceId={`pnc-${isAuto ? 'auto-' : ''}producer-statement`}
        root
      />
      <PropertyAndCasualtyLeadStages />
    </>
  );
};
