import * as React from 'react';
import { DiReactContext } from './DiReactContext';
import { IComponentWithDiProps } from './IComponentWithDiProps';

export function withDi<T extends IComponentWithDiProps>(Component: React.ComponentType<T>) {
  return ((props: T) => (
    <DiReactContext.Consumer>
      {(di) => (
        <Component di={di} {...props} />
      )}
    </DiReactContext.Consumer>
  )) as any as React.ComponentType<Omit<T, 'di'>>;
}
