import * as React from 'react';
import { QuoteSelectButtonProps } from './QuoteSelectButton.interfaces';
import { Button } from '@material-ui/core';

export const QuoteSelectButton: React.FunctionComponent<QuoteSelectButtonProps> = ({
  allowSelect,
  isSelected,
  onClick,
}) => {
  if (!allowSelect) {
    return <></>;
  }
  return (
    <Button
      color={isSelected ? 'secondary' : 'primary'}
      onClick={onClick}
      variant="contained"
      fullWidth
    >
      { isSelected ? 'Unselect' : 'Select' }
    </Button>
  );
};
