import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@material-ui/core';
import React from 'react';

type CloseSunfireReminderProps = {
  open: boolean;
  handleAccept: () => void;
};

export const CloseSunfireReminder: React.FunctionComponent<CloseSunfireReminderProps> = (props) => {
  const { open, handleAccept } = props;
  return (
    <div>
      <Dialog open={open} onClose={handleAccept} aria-labelledby="dialog-title">
        <DialogTitle id="dialog-title">REMINDER !!!</DialogTitle>
        <DialogContent color="red">
          <DialogContentText>Reminder: Don&apos;t forget to close any previous Sunfire window.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleAccept} color="primary">
            I have closed !
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
