import * as React from 'react';
import { CustomerSalesforceRedirectProps } from './CustomerSalesforceRedirect.interfaces';
import { useSalesforceRedirect } from './useSalesforceRedirect';
import { QueryLoader } from '../../../common/QueryLoader';

export const CustomerSalesforceRedirect: React.FunctionComponent<CustomerSalesforceRedirectProps> = ({
  salesforceId,
}) => {
  const { loading, error } = useSalesforceRedirect(salesforceId);
  return (
    <QueryLoader loading={loading} error={error} componentName="CustomerSalesforceRedirect">
      <></>
    </QueryLoader>
  );
};
