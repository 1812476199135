import * as React from 'react';
import { useLogPanelChange } from '../../../../graphql';
import { emitAnalyticsEvent } from '../../../../events/emitAnalyticsEvent';
import { LogPanelChangeProps } from './LogPanelChange.interfaces';

export const LogPanelChange: React.FunctionComponent<LogPanelChangeProps> = ({ panel, detail }: LogPanelChangeProps) => {
  const [logPanelChange] = useLogPanelChange();
  React.useEffect(() => {
    logPanelChange({ panel, detail });
    emitAnalyticsEvent('Panel Change', { panel, detail });
  }, []);
  return null;
};
