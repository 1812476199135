import * as React from 'react';
import { ScriptBlockRendererProps } from './ScriptBlockRenderer.interfaces';
import {
  BlockQuote,
  InfoBlock,
  MustReadBlock,
  ScriptBlock,
  WarningBlock,
  TextBlock,
  DirectionalCopy,
  FlexibleScript,
  InlineText,
  FlexibleScriptVariationType,
} from '../../../ScriptBlock';
import { Box } from '@material-ui/core';
import { Note } from '../../../Note';

export const ScriptBlockRenderer: React.FunctionComponent<ScriptBlockRendererProps> = ({
  leadId,
  questionFlowComponent,
  QuestionFlowRenderer,
  value,
  language,
}) => {
  const body = (
    <QuestionFlowRenderer
      markdownSource={value}
      leadId={leadId}
      questionFlowComponent={questionFlowComponent}
    />
  );
  switch (language) {
    case 'verbatim':
      return (
        <MustReadBlock
          confirmationRequired
          confirmText="I acknowledge that I've read this"
          text={value}
        >
          { body }
        </MustReadBlock>
      );
    case 'text':
      return (
        <TextBlock>
          { body }
        </TextBlock>
      );
    case 'info':
      return (
        <InfoBlock>
          { body }
        </InfoBlock>
      );
    case 'info-confirm':
      return (
        <InfoBlock confirmationRequired confirmText="I have confirmed this with the client">
          { body }
        </InfoBlock>
      );
    case 'script':
      return (
        <ScriptBlock>
          { body }
        </ScriptBlock>
      );
    case 'warning':
      return (
        <WarningBlock
          text={value}
        >
          { body }
        </WarningBlock>
      );
    case 'directional-copy':
      return (
        <DirectionalCopy>
          { body }
        </DirectionalCopy>
      );
    case 'read-exactly':
      return (
        <FlexibleScript variation={FlexibleScriptVariationType.READ_EXACTLY}>
          { body }
        </FlexibleScript>
      );
    case 'read-flexible':
      return (
        <FlexibleScript variation={FlexibleScriptVariationType.READ_FLEXIBLE}>
          { body }
        </FlexibleScript>
      );
    case 'read-flexible-exact':
      return (
        <FlexibleScript variation={FlexibleScriptVariationType.READ_FLEXIBLE_EXACT}>
          { body }
        </FlexibleScript>
      );
    case 'quote-script':
      return (
        <FlexibleScript variation={FlexibleScriptVariationType.READ_FREE}>
          { body }
        </FlexibleScript>
      );
    case 'read-highlighted-exactly':
      return (
        <FlexibleScript variation={FlexibleScriptVariationType.READ_HIGHLIGHTED_EXACT}>
          { body }
        </FlexibleScript>
      );
    case 'rebuttal':
      return (
        <FlexibleScript variation={FlexibleScriptVariationType.REBUTTAL}>
          { body }
        </FlexibleScript>
      );
    case 'note':
      return (
        <Note value={value}>
          {body}
        </Note>
      );
    case 'inline-text':
      return (
        <InlineText
          value={value}
        />
      );
    case 'footer':
      return (
        <Box width={1} height="400px" />
      );
    default:
      return (
        <BlockQuote>
          { body }
        </BlockQuote>
      );
  }
};
