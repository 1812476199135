import * as React from 'react';
import { QuoteMainInfoProps } from './QuoteCarrierInfo.interfaces';
import {
  QuoteCarrierInfoContainer,
  QuoteCompanyName,
  QuoteParentCompanyName,
} from './QuoteCarrierInfo.elements';
import { QuoteDetailsInfo } from './QuoteDetailsInfo';

export const QuoteCarrierInfo: React.FunctionComponent<QuoteMainInfoProps> = ({
  companyName,
  parentCompanyName,
  amBestRating,
  spRating,
  yearsInMarket,
}) => (
  <QuoteCarrierInfoContainer>
    <QuoteParentCompanyName>
      { parentCompanyName }
    </QuoteParentCompanyName>
    <QuoteCompanyName>
      { companyName }
    </QuoteCompanyName>
    { amBestRating && <QuoteDetailsInfo label="AM Rating" value={amBestRating} /> }
    { spRating && <QuoteDetailsInfo label="S&P Rating" value={spRating} /> }
    { yearsInMarket && <QuoteDetailsInfo label="Years in Market" value={yearsInMarket} /> }
  </QuoteCarrierInfoContainer>
);
