import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  GetDiseasesFromDrugsData,
  GetDiseasesFromDrugsVariables,
} from './useGetDiseasesFromDrugs.interfaces';

const GET_DISEASES_FROM_DRUGS = gql`
  query GetDiseasesFromDrugs($drugs: [String!]!) {
    getDiseasesFromDrugs(drugs: $drugs) {
        description
        claimCode
        clinicalName
        diagnosisDate
        claimCodeId
        hasICD
        diseaseKey
        drugs {
          name
        }
      }
  }
`;

export function useGetDiseasesFromDrugs() {
  return useLazyQuery<GetDiseasesFromDrugsData, GetDiseasesFromDrugsVariables>(GET_DISEASES_FROM_DRUGS);
}
