import { gql } from 'apollo-boost';
import { MutationCustomReturn, useMutationCustom } from '../useMutationCustom';
import {
  UpdateContactDataMutationResponse,
  UpdateContactDataMutationVariables,
} from './useUpdateContactDataMutation.interfaces';

const UPDATE_CONTACT_DATA_MUTATION = gql`
  mutation UpdateContactData($leadId: ID!, $input: UpdateContactDataInput!, $callUrlPath: String) {
    updateContactData(leadId: $leadId, input: $input, callUrlPath: $callUrlPath) {
      code
      success
      message
      timestamp
    }
  }
`;

export function useUpdateContactDataMutation(): MutationCustomReturn<
UpdateContactDataMutationResponse,
UpdateContactDataMutationVariables> {
  return useMutationCustom<
  UpdateContactDataMutationResponse,
  UpdateContactDataMutationVariables>(UPDATE_CONTACT_DATA_MUTATION);
}
