import { EventBusToken } from '@healthiqeng/core.infrastructure';
import * as React from 'react';
import { withDi } from '../DiReactContext';
import { ReactTreeMountedEvent } from './ReactTreeMountedEvent';
import { IReactTreeMountListenerProps } from './ReactTreeMountListener.interfaces';

class ReactTreeMountListener extends React.Component<IReactTreeMountListenerProps> {
  private eventBus = this.props.di.get(EventBusToken);

  public componentDidMount() {
    this.eventBus.emit(new ReactTreeMountedEvent());
  }

  public render() {
    return this.props.children;
  }
}
const ReactTreeMountListenerWithDi = withDi(ReactTreeMountListener);

export { ReactTreeMountListenerWithDi as ReactTreeMountListener };
