import * as React from 'react';
import { useCurrentUserProfile } from '../../../hooks';
import { UserRole } from '@hiq/crm.types';
import { ManagerHome } from '../../panels/ManagerHome/ManagerHome';
import { AgentHomeLayout } from '../AgentHomeLayout';
import { ManagerHomeLayout } from '../ManagerHomeLayout';
import { NavBar, AgentHome } from '../../panels';

export const UserHomeLayout: React.FunctionComponent = () => {
  const userProfile = useCurrentUserProfile();
  if (userProfile === UserRole.Manager as string) {
    return (
      <ManagerHomeLayout
        navBar={<NavBar newLead search menu title="TRM | Manager Home" />}
        mainPanel={<ManagerHome />}
      />
    );
  }
  return (
    <AgentHomeLayout
      navBar={<NavBar newLead search menu title="TRM | Agent Home" />}
      mainPanel={<AgentHome />}
    />
  );
};
