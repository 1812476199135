import { useGetAddressFromZipQuery } from '../../../../../graphql/queries/useGetAddressFromZipQuery';
import React, { useEffect, useRef, useState } from 'react';
import { zipCodeTransformerFactory } from '../../../inputs';
import { TextInput } from '../../../inputs/TextInput';
import {
  AddressTitle, LocationButton, MatchedAddress, Panel, SearchBox,
} from './LocationSearchPanel.elements';
import { AddressFromZip, LocationSearchPanelProps } from './LocationSearchPanel.interfaces';

export const LocationSearchPanel = ({ onLocationChanged, type, zipcode }: LocationSearchPanelProps) => {
  const [zip, setZip] = useState('');
  const [locations, setLocations] = useState<AddressFromZip[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    inputRef.current.focus();
  }, []);
  const [
    getAddressFromZipQuery,
    {
      data: addressData,
    },
  ] = useGetAddressFromZipQuery();

  useEffect(() => {
    if (addressData?.getAddress?.address?.length > 0) {
      const { address } = addressData.getAddress;
      const locationsToSet = address.map(({ city, state, county }) => {
        const address: AddressFromZip = {
          city,
          state,
          county,
          zip,
        };

        return address;
      });

      setLocations(locationsToSet);
    }
  }, [addressData]);

  const locationsToRender = locations.map((location) => (
    <LocationButton
      onClick={() => onLocationChanged(location)}
    >
      {location.county}
      {' '}
      County,
      {' '}
      {location.city}
      ,
      {' '}
      {location.state}
    </LocationButton>
  ));

  React.useEffect(() => {
    if (zipcode) onZipChange(zipcode);
  }, [zipcode]);

  const onZipChange = (value: string) => {
    setZip(value);
    getAddressFromZipQuery({ variables: { zip: value } });
  };

  return (
    <Panel>
      <SearchBox>
        <div>
          Where is your
          {' '}
          {type}
          {' '}
          located?
        </div>
        <TextInput
          label="Search by Zip Code"
          value={zip}
          onChange={onZipChange}
          inputRef={inputRef}
          transformerFactory={zipCodeTransformerFactory}
        />
        {locations.length > 0 && <div>Pick a location from the adjacent list</div>}
      </SearchBox>
      {
        locations.length > 0
        && (
        <MatchedAddress>
          <AddressTitle>
            (County, City, State) matching
            {' '}
            {zip}
            :
          </AddressTitle>
          {locationsToRender}
        </MatchedAddress>
        )
      }
    </Panel>
  );
};
