import * as React from 'react';
import { UserAbValues } from '@hiq/crm.types';
import { SalesTimerProps } from './SalesTimer.interfaces';
import { SalesTimerContainer, SalesTimerText } from './SalesTimer.elements';
import { useSalesTimer } from './useSalesTimer';
import { CallTimeLimitAlert } from '../CallTimeLimitAlert';
import { useCurrentUserABValue } from '../../../hooks';
import { useLeadQuery } from '../../../graphql';
import { useActiveLeadId } from '../../routers';

export const SalesTimer: React.FunctionComponent<SalesTimerProps> = () => {
  const [timer, status, seconds] = useSalesTimer();
  const userABValue = useCurrentUserABValue();
  const { data: leadData } = useLeadQuery(useActiveLeadId());

  // Only visible if user AB is "URGENCY" and lead is not sold
  const isCallTimeLimitAlertVisible = userABValue === UserAbValues.URGENCY && leadData && !leadData.isSold;
  return (
    <>
      <SalesTimerContainer status={status}>
        <SalesTimerText>
          { timer }
        </SalesTimerText>
      </SalesTimerContainer>
      {isCallTimeLimitAlertVisible && <CallTimeLimitAlert callTimeInSeconds={seconds} />}
    </>
  );
};
