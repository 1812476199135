import { DatagridCustomColumn } from '../../../../Datagrid';
import { HealthRecordAnticipatedNeed } from '@hiq/crm.types';
import { memoize } from '../../memoizeRenderer';
import {
  HealthRecordCell, Name, Paragraph, Bold, Text,
} from '../../CheckMyHealthRecordDataGrid.elements';
import React from 'react';
import { UseAnticipatedNeedsColumnsConfigProps } from './AnticipatedNeedsGrid.interfaces';
import { numberTransformerFactory, TextInput } from '../../../../inputs';
import { QuantityInputContainer, Row } from './AnticipatedNeedsGrid.elements';

export const useAnticipatedNeedsColumnsConfig = ({ cellWidth, onChangeQuantity, isReduceViewPort }:
UseAnticipatedNeedsColumnsConfigProps): DatagridCustomColumn<HealthRecordAnticipatedNeed>[] => {
  const anticipatedNeedsColumns = React.useMemo<DatagridCustomColumn<HealthRecordAnticipatedNeed>[]>(() => {
    if (isReduceViewPort) {
      return [{
        id: '1',
        renderer: memoize((({ row: { original: { hiqDescription, code } } }) => (
          <HealthRecordCell>
            <Name smaller>{hiqDescription}</Name>
            <Paragraph>
              <Text smaller={isReduceViewPort}> Code: </Text>
              <Bold>{code}</Bold>
            </Paragraph>
          </HealthRecordCell>
        )), 'hiqDescription'),
        width: cellWidth,
      },
      {
        id: '2',
        renderer: memoize((({ row: { original: { code, quantity, quantityApplicable } } }) => (
          <HealthRecordCell>
            {quantityApplicable && (
            <Row>
              <Bold>Quantity &nbsp;</Bold>
              <QuantityInputContainer>
                <TextInput
                  value={quantity}
                  onChange={(quantity) => onChangeQuantity(code, Number(quantity))}
                  transformerFactory={numberTransformerFactory}
                  size="small"
                />
              </QuantityInputContainer>
            </Row>

            )}
          </HealthRecordCell>
        )),
        'code', 'quantity', 'quantityApplicable'),
        width: cellWidth - 30,
      }];
    }
    return [{
      id: '1',
      renderer: memoize((({ row: { original: { hiqDescription } } }) => (
        <HealthRecordCell>
          <Name smaller={isReduceViewPort}>{hiqDescription}</Name>
        </HealthRecordCell>
      )), 'hiqDescription'),
      width: cellWidth,
    },
    {
      id: '2',
      renderer: memoize((({ row: { original: { code } } }) => (
        <HealthRecordCell>
          <Paragraph>
            <Text smaller={isReduceViewPort}> Code: </Text>
            <Bold>{code}</Bold>
          </Paragraph>
        </HealthRecordCell>
      )),
      'code'),
      width: cellWidth,
    },
    {
      id: '3',
      renderer: memoize((({ row: { original: { code, quantity, quantityApplicable } } }) => (
        <HealthRecordCell>
          {quantityApplicable && (
          <Row>
            <Bold>Quantity &nbsp;</Bold>
            <QuantityInputContainer>
              <TextInput
                value={quantity}
                onChange={(quantity) => onChangeQuantity(code, Number(quantity))}
                transformerFactory={numberTransformerFactory}
                size="small"
              />
            </QuantityInputContainer>
          </Row>

          )}
        </HealthRecordCell>
      )),
      'code', 'quantity', 'quantityApplicable'),
      width: cellWidth - 30,
    }];
  }, [isReduceViewPort, onChangeQuantity, cellWidth]);

  return anticipatedNeedsColumns;
};
