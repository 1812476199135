import * as React from 'react';
import { LeadPostSaleStage, LeadPostSaleSubStage, QuestionType } from '@hiq/crm.types';
import { useActiveLeadId } from '../../routers';
import {
  useLeadQuery,
  useLeadStageOptionsQuery,
  useLeadSubStageOptionsQuery,
  useChangeLeadStageMutation,
} from '../../../graphql';
import { FormGeneratorCompactButtons, QuestionView } from '../FormGenerator/FormGeneratorCompact';
import { CreateActivityFormWidget } from '../CreateActivityFormWidget';
import { QueryLoader } from '../QueryLoader';

export const MedicareLeadStages: React.FunctionComponent = () => {
  const leadId = useActiveLeadId();
  const { data: lead } = useLeadQuery(leadId);
  const [state, setState] = React.useState({
    stage: lead?.stage?.stage,
    subStage: lead?.stage?.subStage,
    editing: false,
    activityFormVisible: false,
  });
  const [saveStage] = useChangeLeadStageMutation();
  const { data: stageOptionsData } = useLeadStageOptionsQuery(leadId);
  const toggleEditing = () => setState((prevState) => ({
    ...prevState,
    editing: !prevState.editing,
  }));
  const toggleActivityFormWidget = () => setState((prevState) => ({
    ...prevState,
    activityFormVisible: !prevState.activityFormVisible,
  }));
  const handleSaveClicked = async () => {
    await saveStage({
      leadId,
      stageInput: {
        stage: state.stage as string,
        subStage: state.subStage as string,
      },
    });

    if (state.subStage === LeadPostSaleSubStage.PendingAgentAction
      || state.stage === LeadPostSaleStage.PendingAgentAction) {
      toggleActivityFormWidget();
    }
    toggleEditing();
  };

  const handleClear = () => {
    setState({
      ...state,
      stage: lead?.stage?.stage,
      subStage: lead?.stage?.subStage,
    });
    toggleEditing();
  };

  const handleChange = (key: string, value: string) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const handleStageChange = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      stage: value,
      subStage: '',
    }));
  };
  const { data: subStageOptionsData, loading, error } = useLeadSubStageOptionsQuery(leadId, state.stage);
  const { editing, activityFormVisible } = state;
  return (
    <QueryLoader loading={loading} error={error} componentName="MedicareLeadStages">
      <>
        <QuestionView
          lockable
          id="stage"
          value={state.stage}
          editing={editing}
          onEdit={toggleEditing}
          onChange={handleStageChange}
          options={stageOptionsData?.leadStageOptions ?? []}
          questionText="Stage"
          label="Stage"
          type={QuestionType.Select}
          leadId={leadId}
        />
        <QuestionView
          lockable
          id="subStage"
          value={state.subStage}
          editing={editing}
          options={subStageOptionsData?.leadSubStageOptions ?? []}
          onEdit={toggleEditing}
          onChange={(val) => handleChange('subStage', val as string)}
          questionText="Sub Stage"
          label="Sub Stage"
          type={QuestionType.Select}
          leadId={leadId}
        />
        { editing && (
        <FormGeneratorCompactButtons
          onSave={handleSaveClicked}
          onClear={handleClear}
        />
        ) }
        <CreateActivityFormWidget visible={activityFormVisible} onClose={toggleActivityFormWidget} />
      </>
    </QueryLoader>
  );
};
