import * as React from 'react';
import { QuoteNoteType } from '@hiq/crm.types';
import { QuoteNotesProps } from './QuoteNotes.interfaces';
import {
  QuoteNotesContainer,
  QuoteNoteIcon,
  QuoteNoteMessage,
  QuoteNoteRow,
  QuoteNoteEmphasizedMessage,
} from './QuoteNotes.elements';
import { Icon } from '../../Icon';

export const QuoteNotes: React.FunctionComponent<QuoteNotesProps> = ({
  notes,
  isSpecialRate,
}) => (
  <QuoteNotesContainer>
    { isSpecialRate && (
      <QuoteNoteRow key="hiq">
        <QuoteNoteIcon>
          <Icon type="hiq-torch" />
        </QuoteNoteIcon>
        <QuoteNoteEmphasizedMessage>
          HealthIQ Special Rate
        </QuoteNoteEmphasizedMessage>
      </QuoteNoteRow>
    ) }
    { notes.map(({ type, message }) => (
      <QuoteNoteRow key={message}>
        <QuoteNoteIcon>
          <QuoteNoteIconFactory type={type} />
        </QuoteNoteIcon>
        <QuoteNoteMessage>
          { message }
        </QuoteNoteMessage>
      </QuoteNoteRow>
    )) }
  </QuoteNotesContainer>
);

function QuoteNoteIconFactory({ type }: { type: QuoteNoteType }) {
  switch (type) {
    case 'error':
      return <Icon type="clear" color="error" />;
    case 'success':
      return <Icon type="done" color="secondary" />;
    case 'warning':
      return <Icon type="warning" color="primary" />;
    default:
      return <Icon type="info" />;
  }
}
