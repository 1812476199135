import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Card } from '../../Card';

export const Container = styled(Card)(css`
  position: sticky;
  top: 10px;
  z-index: 100;
`);

export const LoadingWrapper = styled('div')(css`
  min-height: 80px;
`);
export const DialerTransferDispositionsContainer = styled('div')(css`
  display: flex;
  justify-content: space-between;
  button {
    width: 32%;
  }
`);
