import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../../themes';

export const LeadPanelCard = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  margin: 10px;
  padding: 15px;
  background-color: white;
  border: 1px solid ${theme.color.grayLight};
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
`);

export const LeadPanelStickyCard = styled(LeadPanelCard)(css`
  position: sticky;
  top: 0;
`);

export const LeadPanelContainerCard = styled(LeadPanelCard)(css`
  margin: 10px 10px;
`);

export const LeadPanelContainer = styled('div')(css`
  padding: 10px 10px 300px;
`);
