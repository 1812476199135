import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { PrimaryButton } from '../inputs';
import { AlertDialogProps } from './AlertDialog.interfaces';

export const AlertDialog = React.memo(({
  open,
  handleClose,
  title,
  message,
  confirmText,
  onConfirm,
}: AlertDialogProps) => (
  <Dialog open={open} onClose={handleClose}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{message}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <PrimaryButton onClick={onConfirm}>
        {confirmText}
      </PrimaryButton>
    </DialogActions>
  </Dialog>
));
