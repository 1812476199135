import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../../../themes';

type ResultLabelProps = ComponentWithThemeProps & {
  type: 'success' | 'error';
};
export const ResultLabel = styled('div')<ResultLabelProps>(({ theme, type }) => css`
  color: ${type === 'error' ? theme.color.error : theme.color.accent};
`);
