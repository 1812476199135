import { IEventBus, IEventBusEvent } from '@healthiqeng/core.services.event-bus';
import { AnalyticsEvent, SetPropsEvent } from '@healthiqeng/core.services.fe-analytics';

interface PageLoad {
  componentLoadMap: Map<string, boolean>;
}

export enum PageName {
  CustomerHome = 'HIQ CRM | Customer Home',
  AgentHome = 'HIQ CRM | Agent Home'
}

export enum ComponentName {
  UserLeads = 'UserLeads',
  UserReports = 'UserReports',
  UserCalendar = 'UserCalendar',
  UserActivity = 'UserActivity',
  CustomerSummary = 'CustomerSummary',
  Lead = 'Lead',
  Activities = 'Activities',
  CustomerHome = 'CustomerHome',
  MedicareLead = 'MedicareLead',
  LifeLead = 'LifeLead',
  PropertyAndCasualtyLead = 'PropertyAndCasualtyLead',
  FormGeneratorCompact = 'FormGeneratorCompact',
  FormGeneratorQuestionFlow = 'FormGeneratorQuestionFlow',
  FormGeneratorReadOnly = 'FormGeneratorReadOnly',
  FormGeneratorStandard = 'FormGeneratorStandard',
}

/**
 * PageLoadDetector fires an amplitude event when the page loads, to try to know when the page loads it keeps track of when some of the
 * main components are loaded, the page is considered loaded after this
 */
export class PageLoadDetector {
  // this map saves for each component (some of them) to which page it belongs and if it was loaded or not
  private pageLoadMap = new Map<string, PageLoad>([
    [PageName.AgentHome, {
      componentLoadMap: new Map([
        [ComponentName.UserLeads, false],
        [ComponentName.UserReports, false],
        [ComponentName.UserCalendar, false],
        [ComponentName.UserActivity, false],
      ]),
    }],
    [PageName.CustomerHome, {
      componentLoadMap: new Map([
        [ComponentName.CustomerSummary, false],
        [ComponentName.Lead, false],
        [ComponentName.Activities, false],
        [ComponentName.CustomerHome, false],
        [ComponentName.MedicareLead, false],
        [ComponentName.LifeLead, false],
        [ComponentName.PropertyAndCasualtyLead, false],
        [ComponentName.FormGeneratorCompact, false],
        [ComponentName.FormGeneratorQuestionFlow, false],
        [ComponentName.FormGeneratorReadOnly, false],
        [ComponentName.FormGeneratorStandard, false],
      ]),
    }],
  ]);

  private startTime: number;

  private properties: Record<string, any>;

  constructor(
    private eventBus: IEventBus,
  ) {}

  public init() {
    this.startTime = performance.now();
    this.eventBus.subscribe(this.onEvent);
  }

  private onEvent = async (event: IEventBusEvent) => {
    try {
      if (event instanceof SetPropsEvent) {
        this.properties = { ...this.properties, ...event.properties };
        return;
      }
      if (this.isRelevantEvent(event)) {
        const { componentName } = (event as AnalyticsEvent).properties;
        const { pageName } = this.properties;
        const { componentLoadMap } = this.pageLoadMap.get(pageName);
        componentLoadMap.set(componentName, true);
        if (this.pageIsLoaded(pageName)) {
          this.eventBus.unsubscribe(this.onEvent);
        }
      }
    } catch (e) {
      // eslint-disable-next-line
      console.error('Failed to measure page load time: ', e.message);
      this.eventBus.unsubscribe(this.onEvent);
    }
  };

  private pageIsLoaded(pageName: string): boolean {
    const { componentLoadMap } = this.pageLoadMap.get(pageName);
    const componentLoads = Array.from(componentLoadMap, ([name, loaded]) => ({ name, loaded }));
    if (pageName === PageName.AgentHome) {
      return componentLoads.every((component) => component.loaded);
    }
    if (pageName === PageName.CustomerHome) {
      const formLoaded = componentLoads.some((component) => component.name.includes('Form') && component.loaded);
      const leadSpecificComponentLoaded = componentLoads.some((component) => isLeadSpecificComponent(component.name) && component.loaded);
      const restOfComponentsLoaded = !componentLoads.some(
        (component) => !component.name.includes('Form') && !isLeadSpecificComponent(component.name) && !component.loaded,
      );
      return formLoaded && leadSpecificComponentLoaded && !restOfComponentsLoaded;
    }
    return true;
  }

  private isRelevantEvent(event: IEventBusEvent): boolean {
    if (event instanceof AnalyticsEvent) {
      if (event.properties) {
        const { componentName } = event.properties;
        const { pageName } = this.properties;
        return isRelevantMetric(pageName, componentName);
      }
    }
    return false;
  }
}

function isLeadSpecificComponent(componentName:string): boolean {
  if (
    componentName === ComponentName.MedicareLead
    || componentName === ComponentName.PropertyAndCasualtyLead
    || componentName === ComponentName.LifeLead
  ) {
    return true;
  }
  return false;
}

function isRelevantMetric(pageName:string, componentName: string): boolean {
  return pageName
    && componentName
    && Object.values(PageName).includes(pageName as PageName)
    && Object.values(ComponentName).includes(componentName as ComponentName);
}
