import { useEffect, useRef, useState } from 'react';
import { CallDirection } from '@healthiqeng/core.services.dialer.base';
import { useFormAnswers } from '../../../../hooks';
import { useCustomerQuery } from '../../../../graphql/queries/useCustomerQuery';

export function useCallDirectionCompliance(leadId: string) {
  const [formAnswers, onChange] = useFormAnswers(leadId);
  const { data } = useCustomerQuery();
  const lastCall = data?.customer?.calls?.slice(-1)?.[0] ?? null;
  const referenceId = 'lead.outboundCallWarningId.';
  const [callReferenceId, setCallReferenceId] = useState(null);
  const uniqueId = useRef(() => Date.now());
  const onAcknowledge = (value: boolean) => onChange(callReferenceId, value);
  const uniqueReferenceId = `${referenceId}t${uniqueId.current}`;

  useEffect(() => {
    if (lastCall?.id) {
      setCallReferenceId(`${referenceId}${lastCall?.id}`);
    }
  }, [lastCall?.id]);

  return {
    callReferenceId: callReferenceId ?? uniqueReferenceId,
    isInboundCall: lastCall?.direction === CallDirection.Inbound,
    isAcknowledged: formAnswers[callReferenceId] ?? false,
    onAcknowledge,
  };
}
