import React from 'react';
import { Application } from './Application';
import { HealthQuestions } from './HealthQuestions';
import { AutomatedUnderwriting } from './AutomatedUnderwriting';
import { Payment } from './Payment';
import { QuizVerify } from './QuizVerify';
import { Producer } from './Producer';
import { VoiceSignature } from './VoiceSignature';
import { Submit } from './Submit';
import { MedicareEnrollmentPanelInterface } from './MedicareEnrollmentPanel.interfaces';
import { useSubsectionRoute } from '../../../../../routers/useSubsectionRoute';
import { useActiveLeadId } from '../../../../../routers/LeadRouter/useActiveLeadId';
import { QuestionFlow } from '../../../../../common/QuestionFlow';

export const MedicareEnrollmentPanel: React.FunctionComponent<MedicareEnrollmentPanelInterface> = ({
  hasQuoteSelected,
  split,
}) => {
  const leadId = useActiveLeadId();
  const subsection = useSubsectionRoute();
  if (!hasQuoteSelected) {
    return <div>Please select a quote</div>;
  }

  if (!split) {
    return <QuestionFlow leadId={leadId} referenceId="medicare-enrollment" root />;
  }

  switch (subsection) {
    case 'health': return <HealthQuestions />;
    case 'underwriting': return <AutomatedUnderwriting />;
    case 'payment': return <Payment />;
    case 'quiz': return <QuizVerify />;
    case 'producer': return <Producer />;
    case 'signature': return <VoiceSignature />;
    case 'submit': return <Submit />;
    case 'app':
    default:
      return <Application />;
  }
};
