import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../../themes';

export const NewLeadContainer = styled('div')(css`
  margin-right: 10px;
`);

export const QuestionContainer = styled('div')(css`
  margin: 10px 0;
`);

export const ErrorContainer = styled('p')<ComponentWithThemeProps>(({ theme }) => css`
  font-weight: bold;
  color: ${theme.color.error};
`);
