export interface FlexibleScriptProps {
  children: React.ReactNode;
  variation: FlexibleScriptVariationType;
}

export enum FlexibleScriptVariationType {
  READ_FLEXIBLE = 'READ_FLEXIBLE',
  READ_EXACTLY = 'READ_EXACTLY',
  READ_FLEXIBLE_EXACT = 'READ_FLEXIBLE_EXACT',
  READ_HIGHLIGHTED_EXACT = 'READ_HIGHLIGHTED_EXACT',
  READ_FREE = 'READ_FREE',
  REBUTTAL = 'REBUTTAL',
}
