import { GetRedirectOptions } from './getRedirectUrl.interfaces';
import { isRetentionSpecialist, isSalesDevelopmentRep } from '../user';

export function getRedirectUrl(result: GetRedirectOptions): string {
  let url: string;
  if (result?.customerId) {
    url = `/customer/${result.customerId}`;

    if (result?.leadId) {
      url = `${url}/lead/${result.leadId}`;

      if (result?.callUrlPath
        && (isSalesDevelopmentRep(result?.userProfile) || isRetentionSpecialist(result?.userProfile))
      ) {
        url = `${url}/call/${result.callUrlPath}`;

        if (result?.userProfile && isRetentionSpecialist(result?.userProfile)) {
          url = `${url}/retention-specialist`;
        }
      }
    }
  }
  return url;
}
