import * as React from 'react';
import { MissingFieldsWarningProps } from './MissingFieldsWarning.interfaces';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';
import { ErrorTrayMissingFieldList, ErrorTrayMissingFieldListItem } from './MissingFieldsWarning.elements';

export const MissingFieldsWarning: React.FunctionComponent<MissingFieldsWarningProps> = ({
  isOpen,
  onClose,
  invalidFields,
}) => (
  <Snackbar open={isOpen} autoHideDuration={10000} onClose={onClose}>
    <Alert variant="filled" severity="error" onClose={onClose}>
      <AlertTitle>Please fill in all required fields to move forward, missing:</AlertTitle>
      <ErrorTrayMissingFieldList>
        {(invalidFields ?? []).map((i) => (
          <ErrorTrayMissingFieldListItem key={i}>
            <strong>{i}</strong>
          </ErrorTrayMissingFieldListItem>
        ))}
      </ErrorTrayMissingFieldList>
    </Alert>
  </Snackbar>
);
