import { capitalCase } from 'change-case';
import moment from 'moment';
import { DatagridCustomColumn } from '../../../Datagrid';
import {
  HealthRecordDrug, HealthRecordPharmacy, HealthRecordUserClaim,
} from '@hiq/crm.types';
import React from 'react';
import {
  Bold, Green, HealthRecordCell, TextLink, Name, Paragraph, ParagraphWrap, Text, UnorderedList,
} from '../CheckMyHealthRecordDataGrid.elements';
import {
  EditButtonContainer, Flex, HorizontalDivider, WarningMessage,
} from './useColumnsConfig.elements';
import { SunfireDrug } from '../../../../../graphql';
import { Icon } from '../../../Icon';
import { memoize } from '../memoizeRenderer';

export const useColumnsConfig = (
  cellWidth: number,
  isReducedViewport: boolean,
  onEditDrug: (drug: HealthRecordDrug | SunfireDrug) => void,
  onEasyUnsupportedPharmacy: (zipcode: string) => void,
):
[
  DatagridCustomColumn<HealthRecordDrug>[],
  DatagridCustomColumn<HealthRecordPharmacy>[],
  DatagridCustomColumn<HealthRecordUserClaim>[],
  DatagridCustomColumn<HealthRecordUserClaim>[],
] => {
  const drugColumns = React.useMemo<DatagridCustomColumn<HealthRecordDrug & SunfireDrug>[]>(() => {
    if (isReducedViewport) {
      return [
        {
          id: '0',
          renderer: memoize((({ row: { original } }) => (
            <Flex>
              <EditButtonContainer onClick={() => onEditDrug(original)}>
                <Icon type="edit" />
              </EditButtonContainer>
              <HorizontalDivider />
            </Flex>
          )), 'id', 'ndc', 'name', 'daysOfSupply', 'fillQuantity'),
          width: 60,
        },
        {
          id: '1',
          renderer: (({
            row: {
              original: {
                name, isGeneric, genericName, akaNames, diagnoses,
              },
            },
          }) => (
            <HealthRecordCell>
              <Name smaller>{name}</Name>
              <Paragraph>
                Version:&nbsp;
                <Bold>{isGeneric ? 'Generic' : 'Brand Name'}</Bold>
              </Paragraph>
              <Paragraph>
                {isGeneric ? 'Brand Name' : 'Generic'}
&nbsp;Version:&nbsp;
                <Bold>{isGeneric ? akaNames || 'N/A' : genericName || 'N/A'}</Bold>
              </Paragraph>
              {diagnoses?.length
                ? (
                  <ParagraphWrap>
                    Uses include:
                    {' '}
                    <Bold>
                      {diagnoses?.map((diagnose, index) => `${diagnose.description}${index + 1 === diagnoses?.length ? '' : ', '}`)}
                    </Bold>
                  </ParagraphWrap>
) : null}
            </HealthRecordCell>
          )),
          width: cellWidth - 20,
        },
        {
          id: '2',
          renderer: memoize((({
            row: {
              original: {
                strength, fillQuantity, daysOfSupply, fillsLastYear, pharmacyName, form, fillDate,
              },
            },
          }) => (
            <HealthRecordCell smaller>
              <Paragraph>
                Dosage strength:&nbsp;
                <Bold>{strength}</Bold>
              </Paragraph>
              <Paragraph>
                Fill quantity:&nbsp;
                <Bold>{fillQuantity || 'N/A'}</Bold>
              </Paragraph>
              <Paragraph>
                Days of Supply:&nbsp;
                <Bold>{daysOfSupply || 'N/A'}</Bold>
              </Paragraph>
              <Paragraph>
                Last fill on file:&nbsp;
                <Bold>{fillDate ? moment(fillDate).format('M/D/yyyy') : 'N/A'}</Bold>
              </Paragraph>
              <Paragraph>
                Fills in last 12 months:&nbsp;
                <Green>{fillsLastYear || '0'}</Green>
              </Paragraph>
              <Paragraph>
                Form:&nbsp;
                <Bold>{form ? capitalCase(form) : 'N/A'}</Bold>
              </Paragraph>
              <Paragraph>
                Pharmacy:&nbsp;
                <Bold>{pharmacyName || 'N/A'}</Bold>
              </Paragraph>
            </HealthRecordCell>
          )), 'strength', 'fillQuantity', 'daysOfSupply', 'fillsLastYear', 'pharmacyName', 'form', 'fillDate'),
          width: cellWidth + 20,
        },
      ];
    }
    return [
      {
        id: '0',
        renderer: memoize((({ row: { original } }) => (
          <Flex>
            <EditButtonContainer onClick={() => onEditDrug(original)}>
              <Icon type="edit" />
            </EditButtonContainer>
            <HorizontalDivider />
          </Flex>
        )), 'id', 'ndc', 'name', 'daysOfSupply', 'fillQuantity'),
        width: 60,
      },
      {
        id: '1',
        renderer: memoize((({
          row: {
            original: {
              name, isGeneric, genericName, akaNames, diagnoses,
            },
          },
        }) => (
          <HealthRecordCell>
            <Name>{name}</Name>
            <Paragraph>
              Version:&nbsp;
              <Bold>{isGeneric ? 'Generic' : 'Brand Name'}</Bold>
            </Paragraph>
            <Paragraph>
              {isGeneric ? 'Brand Name' : 'Generic'}
&nbsp;Version:&nbsp;
              <Bold>{isGeneric ? akaNames || 'N/A' : genericName || 'N/A'}</Bold>
            </Paragraph>
            {diagnoses?.length
              ? (
                <ParagraphWrap>
                  Uses include:
                  {' '}
                  <Bold>
                    {diagnoses?.map((diagnose, index) => `${diagnose.description}${index + 1 === diagnoses?.length ? '' : ', '}`)}
                  </Bold>
                </ParagraphWrap>
) : null}
          </HealthRecordCell>
        )), 'name', 'isGeneric', 'genericName', 'akaNames', 'diagnoses'),
        width: cellWidth - 20,
      },
      {
        id: '2',
        renderer: memoize((({
          row: {
            original: {
              strength, fillQuantity, daysOfSupply, fillDate,
            },
          },
        }) => (
          <HealthRecordCell>
            <Paragraph>
              Dosage strength:&nbsp;
              <Bold>{strength}</Bold>
            </Paragraph>
            <Paragraph>
              Fill quantity:&nbsp;
              <Bold>{fillQuantity || 'N/A'}</Bold>
            </Paragraph>
            <Paragraph>
              Days of Supply:&nbsp;
              <Bold>{daysOfSupply || 'N/A'}</Bold>
            </Paragraph>
            <Paragraph>
              Last fill on file:&nbsp;
              <Bold>{fillDate ? moment(fillDate).format('M/D/yyyy') : 'N/A'}</Bold>
            </Paragraph>
          </HealthRecordCell>
        )), 'strength', 'fillQuantity', 'daysOfSupply', 'fillDate'),
        width: cellWidth - 20,
      },
      {
        id: '3',
        renderer: memoize((({ row: { original: { fillsLastYear, pharmacyName, form } } }) => (
          <HealthRecordCell>
            <Paragraph>
              Fills in last 12 months:&nbsp;
              <Green>{fillsLastYear || '0'}</Green>
            </Paragraph>
            <Paragraph>
              Form:&nbsp;
              <Bold>{form ? capitalCase(form) : 'N/A'}</Bold>
            </Paragraph>
            <Paragraph>
              Pharmacy:&nbsp;
              <Bold>{pharmacyName || 'N/A'}</Bold>
            </Paragraph>
          </HealthRecordCell>
        )), 'fillsLastYear', 'pharmacyName', 'form'),
        width: cellWidth - 20,
      },
    ];
  }, [cellWidth, isReducedViewport, onEditDrug]);

  const pharmaciesColumns = React.useMemo<DatagridCustomColumn<HealthRecordPharmacy>[]>(() => {
    if (isReducedViewport) {
      return [
        {
          id: '1',
          renderer: memoize((({ row: { original: { npi, name, distanceInMiles } } }) => (
            <HealthRecordCell>
              <Name smaller>{name}</Name>
              {npi !== 'MAIL_ORDER_PHARMACY' && distanceInMiles > 50
                ? <WarningMessage severity="warning">Distance is greater than 50 mi</WarningMessage>
                : null}
            </HealthRecordCell>
          )), 'npi', 'name', 'distanceInMiles'),
          width: cellWidth,
        },
        {
          id: '2',
          renderer: memoize((({
            row: {
              original: {
                fillsLastYear, npi, addressLine1, addressLine2, city, state, zip, invalid,
              },
            },
          }) => (npi && npi !== 'MAIL_ORDER_PHARMACY'
            ? (
              <HealthRecordCell smaller>
                <Paragraph>
                  <Green>{fillsLastYear || 'N/A'}</Green>
                  {' '}
                  fills in the last 12 months
                </Paragraph>
                <Paragraph>
                  Pharmacy ID:&nbsp;
                  <Bold>{npi}</Bold>
                </Paragraph>
                <Paragraph>
                  {[addressLine1, addressLine2, city, state, !invalid ? zip : ''].filter((str) => str).join(', ')}
                  {zip && invalid && (
                  <>
                    ,&nbsp;
                    <TextLink onClick={() => onEasyUnsupportedPharmacy(zip)}>{zip}</TextLink>
                  </>
                  )}
                </Paragraph>
              </HealthRecordCell>
            ) : null)
          ), 'fillsLastYear', 'npi', 'addressLine1', 'addressLine2', 'city', 'state', 'zip', 'invalid'),
          width: cellWidth + 40,
        },
      ];
    }
    return [
      {
        id: '1',
        renderer: memoize((({ row: { original: { npi, name, distanceInMiles } } }) => (
          <HealthRecordCell>
            <Name>{name}</Name>
            {npi !== 'MAIL_ORDER_PHARMACY' && distanceInMiles > 50
              ? <WarningMessage severity="warning">Distance is greater than 50 miles</WarningMessage>
              : null}
          </HealthRecordCell>
        )), 'npi', 'name', 'distanceInMiles'),
        width: cellWidth,
      },
      {
        id: '2',
        renderer: memoize((({ row: { original: { fillsLastYear, npi } } }) => (npi && npi !== 'MAIL_ORDER_PHARMACY'
          ? (
            <HealthRecordCell>
              <Paragraph>
                <Green>{fillsLastYear || 'N/A'}</Green>
                {' '}
                fills in the last 12 months
              </Paragraph>
              <Paragraph>
                Pharmacy ID:&nbsp;
                <Bold>{npi}</Bold>
              </Paragraph>
            </HealthRecordCell>
          ) : null)
        ), 'fillsLastYear', 'npi'),
        width: cellWidth,
      },
      {
        id: '3',
        renderer: memoize((({
          row: {
            original: {
              npi, addressLine1, addressLine2, city, state, zip, invalid,
            },
          },
        }) => (npi && npi !== 'MAIL_ORDER_PHARMACY'
          ? (
            <HealthRecordCell>
              <Paragraph>
                {[addressLine1, addressLine2, city, state, !invalid ? zip : ''].filter((str) => str).join(', ')}
                {zip && invalid && (
                <>
                  ,&nbsp;
                  <TextLink onClick={() => onEasyUnsupportedPharmacy(zip)}>{zip}</TextLink>
                </>
                )}
              </Paragraph>
            </HealthRecordCell>
          ) : null)
        ), 'npi', 'addressLine1', 'addressLine2', 'city', 'state', 'zip', 'invalid'),
        width: cellWidth,
      },
    ];
  }, [cellWidth, isReducedViewport]);

  const userClaimsColumns = React.useMemo<DatagridCustomColumn<HealthRecordUserClaim>[]>(() => {
    if (isReducedViewport) {
      return [
        {
          id: '1',
          renderer: memoize((({ row: { original: { description, diagnosisDate } } }) => (
            <HealthRecordCell>
              <Name smaller>{description}</Name>
              {diagnosisDate && (
                <Text smaller>
                  Last diagnosed (in record):&nbsp;
                  <Bold>{moment(diagnosisDate).format('M/D/yyyy')}</Bold>
                </Text>
              )}
            </HealthRecordCell>
          )), 'description', 'diagnosisDate'),
          width: cellWidth,
        },
        {
          id: '2',
          renderer: memoize((({ row: { original: { claimCode, clinicalName } } }) => (
            <HealthRecordCell smaller>
              {claimCode && (
                <Paragraph>
                  Code:&nbsp;
                  <Bold>{claimCode}</Bold>
                </Paragraph>
              )}
              {clinicalName && (
                <Paragraph>
                  Clinical name:&nbsp;
                  {clinicalName}
                </Paragraph>
              )}
            </HealthRecordCell>
          )), 'claimCode', 'clinicalName'),
          width: cellWidth + 40,
        },
      ];
    }
    return [
      {
        id: '1',
        renderer: memoize((({ row: { original: { description, diagnosisDate } } }) => (
          <HealthRecordCell>
            <Name>{description}</Name>
            {diagnosisDate && (
              <Text>
                Last diagnosed (in record):&nbsp;
                <Bold>{moment(diagnosisDate).format('M/D/yyyy')}</Bold>
              </Text>
            )}
          </HealthRecordCell>
        )), 'description', 'diagnosisDate'),
        width: cellWidth,
      },
      {
        id: '2',
        renderer: memoize((({ row: { original: { claimCode, clinicalName } } }) => (
          <HealthRecordCell>
            {claimCode && (
              <Paragraph>
                Code:&nbsp;
                <Bold>{claimCode}</Bold>
              </Paragraph>
            )}
            {clinicalName && (
              <Paragraph>
                Clinical name:&nbsp;
                {clinicalName}
              </Paragraph>
            )}
          </HealthRecordCell>
        )), 'claimCode', 'clinicalName'),
        width: cellWidth,
      },
    ];
  }, [cellWidth, isReducedViewport]);

  const impliedUserClaimsColumns = React.useMemo<DatagridCustomColumn<HealthRecordUserClaim>[]>(() => {
    if (isReducedViewport) {
      return [
        {
          id: '1',
          renderer: memoize((({ row: { original: { description, diagnosisDate } } }) => (
            <HealthRecordCell>
              <Name smaller>{description}</Name>
              {diagnosisDate && (
                <Text smaller>
                  Prescription last filled (in record):&nbsp;
                  <Bold>{moment(diagnosisDate).format('M/D/yyyy')}</Bold>
                </Text>
              )}
            </HealthRecordCell>
          )), 'description', 'diagnosisDate'),
          width: cellWidth,
        },
        {
          id: '2',
          renderer: memoize((({ row: { original: { drugs, addedByAgent } } }) => (
            <HealthRecordCell smaller>
              <Paragraph>Rx that informed this:</Paragraph>
              <UnorderedList>
                {drugs.map((drug) => (
                  <li>
                    <Bold>{drug.name}</Bold>
                    {drug.akaNames ? ` (${drug.akaNames})` : null}
                    {addedByAgent && ' (Added by agent)'}
                  </li>
                ))}
              </UnorderedList>
            </HealthRecordCell>
          )), 'drugs', 'addedByAgent'),
          width: cellWidth + 40,
        },
      ];
    }
    return [
      {
        id: '1',
        renderer: memoize((({ row: { original: { description, diagnosisDate } } }) => (
          <HealthRecordCell>
            <HealthRecordCell>
              <Name>{description}</Name>
              {diagnosisDate && (
                <Text>
                  Prescription last filled (in record):&nbsp;
                  <Bold>{moment(diagnosisDate).format('M/D/yyyy')}</Bold>
                </Text>
              )}
            </HealthRecordCell>
          </HealthRecordCell>
        )), 'description', 'diagnosisDate'),
        width: cellWidth,
      },
      {
        id: '2',
        renderer: memoize((({ row: { original: { drugs, addedByAgent } } }) => (
          <HealthRecordCell>
            <Paragraph>Rx that informed this:</Paragraph>
            <UnorderedList>
              {drugs.map((drug) => (
                <li key={drug.name}>
                  <Bold>{drug.name}</Bold>
                  {drug.akaNames ? ` (${drug.akaNames})` : null}
                  {addedByAgent && ' (Added by agent)'}
                </li>
              ))}
            </UnorderedList>
          </HealthRecordCell>
        )), 'drugs', 'addedByAgent'),
        width: cellWidth,
      },
    ];
  }, [cellWidth, isReducedViewport]);

  return [drugColumns, pharmaciesColumns, userClaimsColumns, impliedUserClaimsColumns];
};
