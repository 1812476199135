import React from 'react';
import { CompareContext } from '../../CompareContext/CompareContext';
import { Column, EnrollButton, LinkText } from '../../TRMQuote.elements';
import { CompareHeaderProps } from '../../TRMQuote.interfaces';
import {
  CompareRow,
  SmallHeaderContainer,
  SmallHeaderPlanName,
  SmallHeaderFilters,
  SmallHeaderText,
  CompareData, Row, CompareSmallContainer,
} from '../CompareHeader.elements';
import { MedicareType } from '../../../SunfirePlanType/SunfirePlanType';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';
import { CyclePlansArrow } from './CyclePlansArrow';

export const SmallHeader = ({ onEnroll, scrollToTop }: CompareHeaderProps) => {
  const {
    currentPlan,
    currentComparisonPlan,
    ratingFilter,
    carrierFilter,
    planType,
    previousPlan,
    nextPlan,
    totalRecommendedPlans,
    currentComparisonPlanIndex,
  } = React.useContext(CompareContext);

  const composeFilters = React.useMemo(() => {
    const filters = [];
    if (ratingFilter?.length) {
      filters.push(ratingFilter);
    }
    if (carrierFilter?.length) {
      filters.push(...carrierFilter);
    }
    filters.push(MedicareType.find((type) => type.value === planType)?.label);
    return filters.join(', ');
  }, [ratingFilter, carrierFilter, planType]);

  return (
    <CompareSmallContainer>
      <SmallHeaderContainer>
        <CompareRow>
          <CompareData>
            <Column>
              <SmallHeaderText>
                Filter By
                {' '}
                <LinkText onClick={scrollToTop}>Change</LinkText>
              </SmallHeaderText>
              <SmallHeaderFilters>
                {composeFilters}
              </SmallHeaderFilters>
            </Column>
          </CompareData>
          <CompareData>
            <Row>
              <SmallHeaderPlanName>{currentPlan?.name}</SmallHeaderPlanName>
            </Row>
          </CompareData>
          <CompareData>
            <Row>
              <CyclePlansArrow onClick={previousPlan} disabled={currentComparisonPlanIndex === 0}>
                <ArrowLeft />
              </CyclePlansArrow>
              <SmallHeaderPlanName>{currentComparisonPlan?.name}</SmallHeaderPlanName>
              <CyclePlansArrow onClick={nextPlan} disabled={currentComparisonPlanIndex === totalRecommendedPlans - 1}>
                <ArrowRight />
              </CyclePlansArrow>
              <EnrollButton onClick={onEnroll}>
                Enroll
              </EnrollButton>
            </Row>
          </CompareData>
        </CompareRow>
      </SmallHeaderContainer>
    </CompareSmallContainer>
  );
};
