import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';
import { CustomerSearchQueryData, CustomerSearchQueryVariables } from './useCustomerSearchQuery.interfaces';
import { QueryResult } from '@apollo/react-common';

export const CUSTOMER_SEARCH_QUERY = gql`
    query searchCustomers($name: String, $email: String, $phone: String) {
      search(name: $name, email: $email, phone: $phone) {
        id
        person {
          id
          email
          name {
            first
            last
          }
          phone {
            number
          }
        }
      }
    }
`;

export function useCustomerSearchQuery(): [(term: string) => void, QueryResult<CustomerSearchQueryData>] {
  const [search, result] = useLazyQuery<CustomerSearchQueryData, CustomerSearchQueryVariables>(CUSTOMER_SEARCH_QUERY);
  return [
    (term: string) => {
      // If term is a valid phone number, don't search by name and email
      const phone = Number.isNaN(Number.parseInt(term.replaceAll('+', ''))) ? undefined : term;
      const name = phone ? undefined : term;
      const email = phone ? undefined : term;
      search({
        variables: {
          name,
          email,
          phone,
        },
      });
    },
    // @ts-ignore
    result,
  ];
}
