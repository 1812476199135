import { JsonCompatible } from '@healthiqeng/core.types';
import { FormAnswers } from '@hiq/crm.types';
import { identity } from '../../../../util';
import { getItemsArray } from './getItemsArray';

export function getDrivers(answers: FormAnswers): JsonCompatible[] {
  const additionalDrivers = (getItemsArray('lead.drivers.', answers) || []) as JsonCompatible[];
  const addMultipleDrivers = answers['customer.person.addMultipleDrivers'] as boolean;
  const customer: JsonCompatible = {
    name: {
      first: answers['customer.person.name.first'] || '',
      last: answers['customer.person.name.last'] || '',
    },
  };

  if (!addMultipleDrivers) {
    return [
      customer,
    ];
  }

  return [
    customer,
    ...additionalDrivers,
  ].filter(identity);
}
