import { gql } from 'apollo-boost';
import { QUOTE_FRAGMENT } from '../quote';
import { USER_FRAGMENT } from '../user';

export const PRECISION_MEDICARE_CRITICAL_DOCTOR_FRAGMENT = gql`
  fragment PrecisionMedicareCriticalDoctorFragment on PrecisionMedicareCriticalDoctor {
    npi
    name
  }
`;

export const MEDICARE_LEAD_FRAGMENT = gql`
  fragment MedicareLeadFragment on MedicareLead {
    applicationType
    isMedicareAdvantageCompliant
  }
`;

export const LEAD_FRAGMENT = gql`
  fragment LeadFragment on Lead {
    id
    productType
    billingFrequency
    isSold
    sfOpportunityId
    sfId
    policyEffectiveDate
    policyNumber
    paymentCollected
    paymentCollectedType
    createdDate
    initialStatus
    customer {
      id
      person {
        id
        name {
          first
          last
        }
      }
    }
    stage {
      stage
      subStage
    }
    industry {
      type
    }
    owner {
      ...UserFragment
    }
    sfUtmData {
      source
    }
    questionFlowAnswers
    selectedQuotes {
      ...QuoteFragment
    }
    pinnedQuotes {
      ...QuoteFragment
    }
    selectedQuote {
      ...QuoteFragment
    }
    appSubmissions {
      quoteHash
      policyNumber
      filePath
      createdDate
      status
      appStatus
    }
    automatedUnderwritingResults {
      quoteHash
      result
      details
      createdDate
      status
    }
    ... on MedicareLead {
      ...MedicareLeadFragment
    }
    files {
      id
      name
    }
    campaignName
    callSkill
    isDuplicate
    nrnReason
    nfcReason
    nextFollowupTime
    __typename
  }
  ${QUOTE_FRAGMENT}
  ${USER_FRAGMENT}
  ${MEDICARE_LEAD_FRAGMENT}
`;
