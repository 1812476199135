import moment from 'moment';
import { QuestionOption } from '@hiq/crm.types';

export function useQuoteEffectiveDateOptions(date?: Date): [string, QuestionOption[]] {
  const dateFormat = 'MMMM D YYYY';
  const now = date ? moment.utc(date) : moment.utc();
  const monthSpan = Array(12).fill(0).map((_, index) => index + 1);
  const effectiveDates = monthSpan.map((n) => moment.utc(now).add(n, 'months').startOf('month'));
  const effectiveDateOptions: QuestionOption[] = effectiveDates.map((d) => ({
    label: d.format(dateFormat),
    value: d.toISOString(),
  }));

  return [effectiveDateOptions[0].value as string, effectiveDateOptions];
}
