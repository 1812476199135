import * as React from 'react';
import { useActiveLeadId } from '../../routers/LeadRouter/useActiveLeadId';
import { usePrefillSunfireMutation } from '../../../graphql';
import { PrimaryButton } from '../inputs/PrimaryButton';

export const SunfireInventory: React.FunctionComponent = () => {
  const leadId = useActiveLeadId();
  const [prefillSunfire, { loading }] = usePrefillSunfireMutation();

  return (
    <PrimaryButton
      onClick={async () => {
        const result = await prefillSunfire({ leadId, useTrainingEnv: true });
        const url = result?.data?.prefillSunfire?.sunfireReference?.url || 'http://training.sunfirematrix.com';
        window.open(url as string, '_blank');
      }}
      variant="contained"
      disabled={loading}
      size="small"
    >
      { loading ? 'Opening...' : 'Open Plan Inventory' }
    </PrimaryButton>
  );
};
