import * as React from 'react';
import { ProductTypeLife } from '@hiq/crm.types';
import { LifeExternalQuote } from '../../../../../../common';
import { LifeSelectedQuotes } from '../LifeSelectedQuotes';
import { QuotePanel } from '../../../../common';
import { LifeQuoter } from '../LifeQuoter';

export const WholeLifeQuotePanel: React.FunctionComponent = () => (
  <QuotePanel
    selectedQuotes={<LifeSelectedQuotes />}
    externalQuote={<LifeExternalQuote type={ProductTypeLife.WholeLife} />}
    quoter={<LifeQuoter defaultProductType={ProductTypeLife.WholeLife} />}
  />
);
