import React from 'react';
import { ReportCallQualityButton, ReportCallQualityContainer, ReportCallQualityLabel } from './ReportCallQuality.elements';
import {
  useUpdateCallQualityMutation,
  UpdateCallQualityMutationResponse,
} from '../../../../graphql/mutations';
import { useCustomerQuery } from '../../../../graphql';
import { Snackbar, SnackbarOrigin } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useToggle } from '../../../common/CheckMyHealthRecord/CheckMyHealthRecordDataGrid/useToggle';
import { emitAnalyticsEvent } from '../../../../events/emitAnalyticsEvent';
import { ApolloError } from 'apollo-boost';

const logOnCompleted = (success: boolean) => emitAnalyticsEvent('Updating Quality Dialer Call', { success });
const logOnError = (error: ApolloError) => emitAnalyticsEvent('Error Updating Quality Dialer Call', { error: error?.message });

const SNACKBAR_POSITION: SnackbarOrigin = {
  vertical: 'top',
  horizontal: 'left',
};

enum CallQuality {
  SOUND_ISSUES = 'Sound Issues',
}

export const ReportCallQuality = () => {
  const { data: customerData } = useCustomerQuery();
  const [openCallQualityLog, toggleOpenCallQualityLog] = useToggle();

  const onCompleted = React.useCallback((data: UpdateCallQualityMutationResponse) => {
    if (data?.updateCallQuality?.success) toggleOpenCallQualityLog();
    logOnCompleted(data?.updateCallQuality?.success);
  }, [toggleOpenCallQualityLog]);

  const [updateCallQuality, updateCallQualityData] = useUpdateCallQualityMutation({ onError: logOnError, onCompleted });
  const hasReportedWithSuccess = updateCallQualityData?.data?.updateCallQuality?.success;

  const handleReportCallQuality = React.useCallback(() => {
    if (!hasReportedWithSuccess) {
      updateCallQuality({
        customerId: customerData?.customer?.id,
        input:
            { callQuality: CallQuality.SOUND_ISSUES },
      });
    }
  }, [updateCallQuality, hasReportedWithSuccess]);

  const reportMessage = hasReportedWithSuccess ? 'Reported' : 'Report';

  return (
    <ReportCallQualityContainer>
      <ReportCallQualityLabel>
        Audio Issues
      </ReportCallQualityLabel>
      <ReportCallQualityButton onClick={handleReportCallQuality} disabled={hasReportedWithSuccess}>
        [
        {reportMessage}
        ]
      </ReportCallQualityButton>
      <Snackbar
        open={openCallQualityLog}
        autoHideDuration={4000}
        onClose={toggleOpenCallQualityLog}
        anchorOrigin={SNACKBAR_POSITION}
      >
        <Alert onClose={toggleOpenCallQualityLog} severity="success">
          Call Quality Reported
        </Alert>
      </Snackbar>
    </ReportCallQualityContainer>
  );
};
