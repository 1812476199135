import React from 'react';
import { DiseaseBenefit } from './DiseaseBenefit';
import { DiagnosisItemProps } from './DiagnosisItem.interfaces';
import { Card, Description, Header } from './DiagnosisItem.elements';
import { Icon } from '../../../common';

export const DiagnosisItem = ({ diseaseKey, description, benefits }: DiagnosisItemProps) => {
  const [collapsed, setCollapsed] = React.useState(true);
  if (collapsed) {
    return (
      <Header collapsed onClick={() => setCollapsed(false)}>
        <Description>{description}</Description>
        <Icon type="expand" />
      </Header>
    );
  }
  return (
    <Card>
      <Header onClick={() => setCollapsed(true)}>
        <Description>{description}</Description>
        <Icon type="collapse" />
      </Header>
      {benefits.map(({ benefit, context }) => (
        <DiseaseBenefit diseaseKey={diseaseKey} benefit={benefit} context={context} />
      ))}
    </Card>
  );
};
