import * as React from 'react';
import { AnchorTabsProps } from './AnchorTabs.interfaces';
import {
  AnchorTabWrapper,
} from './AnchorTabs.elements';
import Tab from '@material-ui/core/Tab';
import { Icon } from '../../../../common/Icon';

export const AnchorTabs: React.FunctionComponent<AnchorTabsProps> = ({
  isPanelActive,
  anchors,
  onAnchorClick,
  activeAnchor,
}) => {
  const handleAnchorClick = (value: string) => {
    onAnchorClick(value);
  };

  return (
    <>
      { (anchors ?? []).map(({ label, value, locked }) => (
        <AnchorTabWrapper key={value} active={isPanelActive && activeAnchor === value}>
          <Tab
            label={label}
            onClick={() => handleAnchorClick(value)}
            fullWidth
            data-qa={`main-page-layout-tab-anchor-${value}`}
            icon={locked ? <Icon type="lock" /> : undefined}
          />
        </AnchorTabWrapper>
      )) }
    </>
  );
};
