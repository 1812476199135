import styled from '@emotion/styled';
import { Alert } from '@material-ui/lab';

export const WarningMessage = styled(Alert)`
  align-items: center;
  margin-top: 10px
  margin-bottom: 10px
  border-bottom-left-radius: 0px
  border-bottom-right-radius: 0px 
`;

export const Bold = styled.span`
  font-weight: bold;
`;

export const Text = styled.span`
  font-weight: 500;
`;
