import * as React from 'react';
import { QuoteDetailsInfoProps } from './QuoteDetailsInfo.interfaces';
import {
  QuoteDetailsInfoContainer,
  QuoteInfoLabel,
  QuoteInfo,
} from './QuoteDetailsInfo.elements';

export const QuoteDetailsInfo: React.FunctionComponent<QuoteDetailsInfoProps> = ({
  label,
  value,
}) => (
  <QuoteDetailsInfoContainer>
    <QuoteInfoLabel>
      { `${label}:`}
    </QuoteInfoLabel>
    <QuoteInfo>
      { value }
    </QuoteInfo>
  </QuoteDetailsInfoContainer>
);
