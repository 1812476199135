import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../../themes';

export const SendQuoteEmailButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
`;

export const FeedbackMessage = styled.div<ComponentWithThemeProps & { error?: boolean }>(({ theme, error }) => `
  min-width: 100px;
  vertical-align: center;
  ${error ? `color: ${theme.color.error}` : ''};
`);
