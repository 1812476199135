import * as React from 'react';
import { FormGeneratorStandardProps } from './FormGeneratorStandard.interfaces';
import { useFormQuery } from '../../../../graphql/queries/useFormQuery';
import { useFormAnswers } from '../../../../hooks';
import { QueryLoader } from '../../QueryLoader';
import { Question } from './Question';
import { QuestionContainer } from './FormGeneratorStandard.elements';
import { ComponentName } from '../../Page/PageLoadDetector';

export const FormGeneratorStandard: React.FunctionComponent<FormGeneratorStandardProps> = ({
  leadId,
  referenceId,
}) => {
  const { data: formStructure, error, loading } = useFormQuery(leadId, referenceId);
  const [answers, onChange] = useFormAnswers(leadId);
  return (
    <QueryLoader loading={loading} error={error} componentName={ComponentName.FormGeneratorStandard}>
      <>
        { formStructure?.items.map((formItem) => (
          <QuestionContainer key={formItem.questionId}>
            <Question
              leadId={leadId}
              id={formItem.questionId}
              answers={answers}
              onChange={onChange}
              visibleCondition={formItem.visibleCondition}
            />
          </QuestionContainer>
        )) }
      </>
    </QueryLoader>
  );
};
