import * as React from 'react';
import { QuoteCreateProperties } from '@hiq/crm.types';

export function useExternalQuoteInput<T extends Partial<QuoteCreateProperties>>(
  defaults: T,
): [T, (key: string, value: any) => void] {
  const [state, setState] = React.useState<T>(defaults);
  const setInput = (key: string, value: any) => {
    if (key.startsWith('rate.')) {
      setState({
        ...state,
        rate: {
          ...(state.rate ?? {}),
          [key.replace('rate.', '')]: value,
        },
      });
      return;
    }
    setState({
      ...state,
      [key]: value,
    });
  };

  return [state, setInput];
}
