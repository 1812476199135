import * as React from 'react';
import { QuotePanelProps } from './QuotePanel.interfaces';
import { useSelectedQuoteDetector } from '../../useSelectedQuoteDetector';
import { useQuoteUpdateInProgress } from '../../useQuoteUpdateInProgress';
import { QueryLoader } from '../../../../common/QueryLoader';
import { PrimaryButton } from '../../../../common/inputs/PrimaryButton';

export const QuotePanel: React.FunctionComponent<QuotePanelProps> = ({
  selectedQuotes,
  externalQuote,
  quoter,
}) => {
  const hasSelectedQuote = useSelectedQuoteDetector();
  const [useExternal, setUseExternal] = React.useState(!quoter);
  const quoteUpdateInProgress = useQuoteUpdateInProgress();
  if (hasSelectedQuote) {
    return selectedQuotes;
  }

  return (
    <QueryLoader loading={quoteUpdateInProgress} error={null} componentName="QuotePanel">
      <>
        { quoter && (
          <PrimaryButton onClick={() => setUseExternal(!useExternal)}>
            { useExternal ? 'Use HealthIQ Quote' : 'Use External Quote' }
          </PrimaryButton>
        )}
        { useExternal && externalQuote }
        { !useExternal && quoter }
      </>
    </QueryLoader>
  );
};
