import { UserAbValues } from '@hiq/crm.types';

type ColorMapType = {
  [key in UserAbValues]?: string;
};

export const ScriptVersionColorMap: ColorMapType = {
  [UserAbValues.A]: '#FF8D4A',
  [UserAbValues.B]: '#E3D015',
  [UserAbValues.C]: '#C14141',
  [UserAbValues.D]: '#A9D204',
  [UserAbValues.E]: '#0D9E24',
  [UserAbValues.F]: '#00C2DD',
  [UserAbValues.G]: '#1646F0',
  [UserAbValues.H]: '#C626D3',
  [UserAbValues.J]: '#A85B01',
  [UserAbValues.K]: '#949494',
  [UserAbValues.L]: '#000000',
  [UserAbValues.M]: '#FFB6D4',
  [UserAbValues.N]: '#FF8D4A',
  [UserAbValues.P]: '#E3D015',
  [UserAbValues.Q]: '#C14141',
  [UserAbValues.R]: '#A9D204',
  [UserAbValues.S]: '#0D9E24',
  [UserAbValues.T]: '#00C2DD',
  [UserAbValues.U]: '#1646F0',
  [UserAbValues.V]: '#C626D3',
  [UserAbValues.W]: '#A85B01',
  [UserAbValues.X]: '#949494',
  [UserAbValues.Y]: '#000000',
  [UserAbValues.Z]: '#FFB6D4',
};
