// eslint-disable-next-line import/no-extraneous-dependencies,@typescript-eslint/no-unused-vars
import React from 'react';

import { useUITools } from '../../components/contexts/UIToolsContext';

export function useCreateDialog() {
  const { createDialog, closeDialog } = useUITools();

  return {
    createDialog,
    closeDialog,
  };
}
