import { Alert, AlertTitle } from '@material-ui/lab';
import React from 'react';
import { useFormAnswers } from '../../../../hooks';

export const CheckMyHealthRecordVerifyUserCheck: React.FunctionComponent = () => {
  const [answers] = useFormAnswers();
  const verificationRequired = answers['customer.person.cmhrVerificationRequired'];
  const verificationRequiredText = verificationRequired
    ? 'User Verification required to pull health records' : 'User verification not required for health records';

  return (
    <>
      <Alert severity="info">
        <AlertTitle>User Verification Check</AlertTitle>
        {verificationRequiredText}
      </Alert>
    </>
  );
};
