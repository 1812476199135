import * as React from 'react';
import { DispositionFive9Label, DispositionLabelMap } from '../useDialerTransferButtons/constants';
import { DialerDispositionButton, DialerDispositionButtonProps } from './DialerDispositionButton';
import { DialerDispositionButtonDNC } from './DialerDispositionButtonDNC';

export const DialerDispositionButtonFactory: React.FC<DialerDispositionButtonProps> = (props) => {
  const { label } = props;
  switch (label) {
    case DispositionLabelMap[DispositionFive9Label.DoNotCall]:
      return <DialerDispositionButtonDNC {...props} />;
    default:
      return <DialerDispositionButton {...props} />;
  }
};
