import { InputFactory, InputFactoryProps } from '../../inputs';
import { QuestionType } from '@hiq/crm.types';
import { InputContainer } from '../CreateActivityForm.elements';
import * as React from 'react';
import { useUsersFriendlyNameQuery } from '../../../../graphql';

type TaskInputProps = Pick<InputFactoryProps, 'onChange'> & {
  value: string
};

export const TaskInput = ({ value, onChange }: TaskInputProps) => {
  const { data } = useUsersFriendlyNameQuery();
  const userOptions = React.useMemo(() => data?.users?.map((user) => ({
    label: user.name.fullFriendlyName,
    value: user.id,
  })) ?? [], [data]);
  return (
    <InputContainer>
      <InputFactory
        type={QuestionType.Select}
        value={value}
        onChange={onChange}
        options={userOptions}
        fullWidth
        size="small"
      />
    </InputContainer>
  );
};
