import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { MQ } from '../constants';

interface IResponsiveBrProps {
  visibleOn: 'mobile_xs' | 'mobile' | 'tablet' | 'desktop';
}

export const ResponsiveBr = styled('br')<IResponsiveBrProps>((props) => css`
  display: none;
  ${MQ.MOBILE_XS} {
    display: ${props.visibleOn === 'mobile_xs' ? 'inline' : 'none'};
  }
  ${MQ.MOBILE} {
    display: ${props.visibleOn === 'mobile' ? 'inline' : 'none'};
  }
  ${MQ.TABLET} {
    display: ${props.visibleOn === 'tablet' ? 'inline' : 'none'};
  }
  ${MQ.DESKTOP} {
    display: ${props.visibleOn === 'desktop' ? 'inline' : 'none'};
  }
`);
