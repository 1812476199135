import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps, ThemeLight } from '../../../themes';
import Tabs from '@material-ui/core/Tabs';
import withStyles from '@material-ui/core/styles/withStyles';

export const TabTopLayoutContainer = styled('div')(css`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`);

export const TabRowContainer = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  width: 100%;
  background-color: ${theme.color.white};
  border-bottom: 1px solid ${theme.color.grayLight};
  box-shadow: 0px 10px 20px -10px rgba(0,0,0,0.1);
  z-index: 10;
  display: flex;
  justify-content: space-between;
`);

export const ContentRowContainer = styled('div')(css`
  flex: 1;
`);

export const ContentContainer = styled('div')(css`
  height: 100%;
`);

export const StyledTabsContainer = styled('div')(css`
  display: flex;
`);

export const ActionsContainer = styled('div')(css`
  display: flex;
  padding: 5px;
  min-width: 220px;
`);

export const StyledTabs = withStyles({
  indicator: {
    backgroundColor: ThemeLight.color.primary,
  },
})(Tabs);
