import {
  CompareData, CompareDataBold, CompareRow, Row,
} from '../TRMQuote.elements';
import React from 'react';
import { formatCurrencyTruncated } from '@healthiqeng/core.ui';
import { NoPlanPlaceHolder } from '../NoPlanPlaceHolder';
import { CompareContext } from '../CompareContext/CompareContext';

export const ComparePremium = () => {
  const {
    currentPlan, currentComparisonPlan,
  } = React.useContext(CompareContext);
  return (
    <CompareRow>
      <CompareDataBold>
        Premium
      </CompareDataBold>
      <CompareData
        empty={!currentPlan}
        highlight={currentPlan?.hiqOopCostsResults?.premiumAnnual < currentComparisonPlan?.hiqOopCostsResults?.premiumAnnual}
      >
        <Row justifyContent="space-between">
          {currentPlan ? (
            <>
              Monthly:
              {formatCurrency(currentPlan?.hiqOopCostsResults.premiumMonthly)}
              <br />
              Annual:
              {formatCurrency(currentPlan?.hiqOopCostsResults.premiumAnnual)}
            </>
          ) : <NoPlanPlaceHolder />}
        </Row>
      </CompareData>
      <CompareData highlight={currentComparisonPlan?.hiqOopCostsResults?.premiumAnnual < currentPlan?.hiqOopCostsResults?.premiumAnnual}>
        <Row justifyContent="space-between">
          Monthly:
          {formatCurrency(currentComparisonPlan?.hiqOopCostsResults.premiumMonthly)}
          <br />
          Annual:
          {formatCurrency(currentComparisonPlan?.hiqOopCostsResults.premiumAnnual)}
        </Row>
      </CompareData>
    </CompareRow>
  );
};

function formatCurrency(currency: number) {
  if (currency < 0) return 'N/A';
  const formatted = ` ${formatCurrencyTruncated(currency, false, false)}`;
  if (!formatted) return 'N/A';
  return formatted;
}
