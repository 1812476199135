import styled from '@emotion/styled';

export const OptionLabel = styled.div`
  font-size: 15px;
`;

export const OptionLabelSubtitle = styled.div`
  font-size: 14px;
  color: #0075CA;
`;
