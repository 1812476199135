import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../../themes';

export const LeftPanelContainer = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  height: 100%;
  width: 100%;
  padding: 0 10px;
  background-color: ${theme.color.grayBackground};
  display: flex;
  flex-direction: column;
  overflow: hidden;
`);

export const PreSalesDashboardLink = styled('a')<ComponentWithThemeProps>(({ theme }) => css`
  text-align: center;
  height: 40px;
  color: ${theme.color.black};
  font-weight: 600;
`);
