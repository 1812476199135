import React from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useActiveLeadId } from '../../../../../../routers/LeadRouter/useActiveLeadId';
import { useApplicationHasImpairmentQuery } from '../../../../../../../graphql';
import { QuestionFlow } from '../../../../../../common/QuestionFlow';

const REFERENCE_ID = 'medicare-enrollment-health-questions';

export const HealthQuestions: React.FunctionComponent<any> = () => {
  const leadId = useActiveLeadId();
  const hasImpairment = useApplicationHasImpairmentQuery(leadId);

  return (
    <div>
      <QuestionFlow leadId={leadId} referenceId={REFERENCE_ID} root />
      <br />
      <br />
      <Snackbar open={hasImpairment}>
        <Alert severity="warning">
          Any &lsquo;Yes&lsquo; answer to an underwriting question is an automatic decline. If the answer is accurate,
          please return to the Quote tab to re-quote your customer with another carrier if appropriate
        </Alert>
      </Snackbar>
    </div>
  );
};
