import { useContext } from 'react';
import { LeadIdContext } from '../../../contexts/LeadIdContext';
import { useLeadIdRoute } from '../useLeadIdRoute';
import { useCustomerQuery } from '../../../../graphql/queries/useCustomerQuery';
import { isPresent } from '@healthiqeng/core.util.is-present';
import { SetPropsEvent } from '@healthiqeng/core.services.fe-analytics';
import { getEventBus } from '../../../../events';

export function useActiveLeadId(leadIdParam?: string) {
  const leadIdFromContext = useContext(LeadIdContext);
  const routeLeadId = useLeadIdRoute()[0];
  const { data } = useCustomerQuery();
  const eventBus = getEventBus();
  const leadId = leadIdParam ?? leadIdFromContext ?? routeLeadId;
  if (isPresent(leadId) && !['new', 'ma-conversion'].includes(leadId)) {
    eventBus.emit(new SetPropsEvent({ leadId }));
    return leadId;
  }
  const customerLeadId = data?.customer?.leads?.[0]?.id;
  eventBus.emit(new SetPropsEvent({ leadId: customerLeadId }));
  return customerLeadId;
}
