import { NumberTransformer } from '../NumberTransformer';

export class PercentTransformer extends NumberTransformer {
  constructor(initialValue: number) {
    super(initialValue);
    this.draftValue = NumberTransformer.normalizeNumberString(`${initialValue * 100}`);
  }

  protected updatePersistedValue() {
    super.updatePersistedValue();
    this.persistedValue /= 100;
  }
}
