import * as React from 'react';
import { SecondaryButtonProps } from './SecondaryButton.interfaces';
import { Button } from '@material-ui/core';

export const SecondaryButton: React.FunctionComponent<SecondaryButtonProps> = ({
  children,
  disabled,
  variant,
  size,
  fullWidth,
  onClick,
}) => (
  <Button
    color="default"
    disabled={disabled}
    variant={variant}
    size={size}
    fullWidth={fullWidth}
    onClick={onClick}
  >
    { children }
  </Button>
);
