import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ColumnsCSSGridContainerProps, ResizableColumnsCSSGridContainerProps } from './ResizableColumns.interfaces';

export const ResizableColumnsContainer = styled('div')<ResizableColumnsCSSGridContainerProps>((props) => {
  let heightCss;
  if (props.height) {
    heightCss = props.height;
  } else if (props.navBarPresent) {
    heightCss = 'calc(100vh - 50px)';
  } else {
    heightCss = '100vh';
  }

  return css`
    display: grid;
    grid-template-columns: ${props.columnWidths.join(' ')};
    height: ${heightCss};
    overflow: hidden;
  `;
});

export const Column = styled('div')<ColumnsCSSGridContainerProps>(({ columnVerticalOverflow }) => css`
  overflow-x: hidden;
  overflow-y: ${columnVerticalOverflow};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`);
