import { EventBusToken } from '@healthiqeng/core.infrastructure';
import { debounce } from 'throttle-debounce';
import { withDi } from '../../DiReactContext';
import { AbstractAnchorDetector } from '../AbstractAnchorDetector';
import { AnchorReadEvent } from './AnchorReadEvent';
import { IReadAnchorDetectorProps } from './ReadAnchorDetector.interfaces';

class ReadAnchorDetector extends AbstractAnchorDetector<IReadAnchorDetectorProps, {}> {
  private static READING_TIMEOUT = 150;
  public eventBus = this.props.di.get(EventBusToken);

  protected onScroll = debounce(ReadAnchorDetector.READING_TIMEOUT, () => {
    this.forEachVisibleAnchor((anchorName) => {
      if (!this.readAnchors[anchorName]) {
        this.readAnchors[anchorName] = true;
        this.eventBus.emit(new AnchorReadEvent(anchorName));
      }
    });
  });

  private readAnchors: {[anchorName: string]: boolean} = {};
}
const ReadAnchorDetectorWithBus = withDi(ReadAnchorDetector);

export { ReadAnchorDetectorWithBus as ReadAnchorDetector };
