import React from 'react';
import { HealthRecordTopBar } from '../../CheckMyHealthRecordDataGrid/HealthRecordGrid/HealthRecordTopBar';
import {
  GridTitle,
  HealthRecordGridContainer,
} from '../../CheckMyHealthRecordDataGrid/HealthRecordGrid/HealthRecordGrid.elements';
import { Datagrid } from '../../../Datagrid';
import { HealthRecordLockedGridProps } from './HealthRecordLockedGrid.interfaces';
import HealthRecordSection
  from '../../CheckMyHealthRecordDataGrid/HealthRecordGrid/HealthRecordSection/HealthRecordSection';

export function HealthRecordLockedGrid<D>({
  data,
  overlayData,
  selectedRowIds,
  title,
  getRowId,
  columns,
}: HealthRecordLockedGridProps<D>) {
  const overlay = overlayData.map((overlay) => overlay?.data);

  const dataIncluded = React.useMemo(() => data.filter((d) => selectedRowIds.includes(getRowId(d))),
    [data, selectedRowIds]);
  const otherDataIncluded = React.useMemo(() => overlay.filter((d) => selectedRowIds.includes(getRowId(d))),
    [overlayData, selectedRowIds]);
  const notIncluded = React.useMemo(() => [...data, ...overlay].filter((d) => !selectedRowIds.includes(getRowId(d))),
    [data, selectedRowIds]);

  const datagridProps = {
    enableSelection: false,
    selectedRowIds,
    getRowId,
    customColumns: columns,
    fullWidth: true,
    selectionMultiselect: false,
    selectedColor: 'rgb(5, 171, 91, 0.3)',
    selectedBackground: 'rgb(246, 255, 251, 0.3)',
  };

  return (
    <HealthRecordGridContainer>
      <HealthRecordTopBar
        title={title}
        hideSorting
      />
      {dataIncluded?.length ? (
        <>
          <GridTitle>IMPORTED VIA HEALTH RECORD</GridTitle>
          <Datagrid {...datagridProps} data={dataIncluded} />
        </>
) : null}

      {otherDataIncluded?.length ? (
        <>
          <GridTitle>Manually Added</GridTitle>
          <Datagrid {...datagridProps} data={otherDataIncluded} />
        </>
) : null }

      {notIncluded?.length ? (
        <HealthRecordSection name="Show non-included">
          <Datagrid {...datagridProps} data={notIncluded} />
        </HealthRecordSection>
) : null}
    </HealthRecordGridContainer>
  );
}
