import { gql } from 'apollo-boost';
import { MutationCustomReturn, useMutationCustom } from '../useMutationCustom';
import { CUSTOMER_FRAGMENT } from '../../fragments';
import {
  LogCurrentCallMutationResponse,
  LogCurrentCallMutationVariables,
} from './useLogCurrentCallMutation.interfaces';

const LOG_CURRENT_CALL_MUTATION = gql`
  mutation LogCurrentCall($customerId: ID!, $input: LogCallDataInput!) {
    logCurrentCall(customerId: $customerId, input: $input) {
      code
      success
      message
      timestamp
      customer {
        ...CustomerFragment
      }
    }
  }
  ${CUSTOMER_FRAGMENT}
`;

export function useLogCurrentCallMutation(): MutationCustomReturn<
LogCurrentCallMutationResponse,
LogCurrentCallMutationVariables> {
  return useMutationCustom<
  LogCurrentCallMutationResponse,
  LogCurrentCallMutationVariables>(LOG_CURRENT_CALL_MUTATION);
}
