import * as React from 'react';
import { Box } from '../Box';
import { CtaButtonContainer } from './CtaButton.elements';
import { ICtaButtonProps } from './CtaButton.interfaces';

export function CtaButton(props: ICtaButtonProps) {
  return (
    <Box {...props}>
      <CtaButtonContainer
        className={props.className}
        size={props.size || 'large'}
        colorTheme={props.colorTheme || 'green'}
        fillingTheme={props.fillingTheme || 'full'}
        fullWidth={!!props.fullWidth}
        disabled={props.disabled}
        onClick={(e) => onClickHandler(props, e)}
      >
        {props.children}
      </CtaButtonContainer>
    </Box>
  );
}

function onClickHandler(props: ICtaButtonProps, event: React.SyntheticEvent) {
  if (!props.disabled) {
    props.onClick(event);
  }
}
