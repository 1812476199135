import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { getEventBus } from '../../../events/getEventBus';
import { USER_FRAGMENT } from '../../fragments';
import { CurrentUsersQueryData, CurrentUsersQueryVariables } from './useCurrentUserQuery.interfaces';
import { LoggedInEvent, SetPropsEvent } from '@healthiqeng/core.services.fe-analytics';

const CURRENT_USER_QUERY = gql`
    query User {
      currentUser {
        ...UserFragment
      }
    }
    ${USER_FRAGMENT}
`;

export function useCurrentUserQuery() {
  return useQuery<CurrentUsersQueryData, CurrentUsersQueryVariables>(CURRENT_USER_QUERY, {
    onCompleted: (userData) => {
      if (userData?.currentUser?.id) {
        const eventBus = getEventBus();
        eventBus.emit(new LoggedInEvent(userData.currentUser.id));
        eventBus.emit(new SetPropsEvent({ profile: userData?.currentUser?.profile, roles: userData?.currentUser?.roles }));
      }
    },
  });
}
