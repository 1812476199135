import { gql } from 'apollo-boost';
import { MutationCustomReturn, useMutationCustom } from '../useMutationCustom';
import {
  SubmitEappMutationResponse,
  SubmitEappMutationVariables,
} from './useSubmitEappMutation.interfaces';

// @deprecated For CSG only
const SUBMIT_EAPP_MUTATION = gql`
  mutation SubmitEapp($leadId: ID!) {
    submitEapp(leadId: $leadId) {
      code
      success
      message
      timestamp
      csgEappReference {
        id
      }
    }
  }
`;

export function useSubmitEappMutation(): MutationCustomReturn<
SubmitEappMutationResponse,
SubmitEappMutationVariables> {
  return useMutationCustom<
  SubmitEappMutationResponse,
  SubmitEappMutationVariables>(SUBMIT_EAPP_MUTATION);
}
