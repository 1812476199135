import { gql } from 'apollo-boost';

export const ADDRESS_LOOKUP_FRAGMENT = gql`
  fragment AddressLookupFragment on AddressPlace {
    description
    placeId
    mainText
    secondaryText
    mainTextMatchings {
      offset
      length
    }
  }
`;
