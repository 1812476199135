import { gql } from 'apollo-boost';

export const OVERLAY_FRAGMENT = gql`
  fragment OverlayFragment on Overlay {
    id
    customerId
    externalId
    externalType
    data
  }
`;
