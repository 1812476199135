import * as React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

export const FormSkeleton: React.FunctionComponent<{ rows?: number }> = ({
  rows,
}) => {
  const height = 30;
  const rowElements = [
    <Skeleton key="1" width="95%" height={height} animation="wave" />,
    <Skeleton key="2" width="91%" height={height} animation="wave" />,
    <Skeleton key="3" width="98%" height={height} animation="wave" />,
    <Skeleton key="4" width="40%" height={height} animation="wave" />,
    <Skeleton key="5" width="40%" height={height} animation="wave" />,
    <Skeleton key="6" width="90%" height={height} animation="wave" />,
    <Skeleton key="7" width="93%" height={height} animation="wave" />,
  ];
  return (
    <>
      { rowElements.slice(0, rows ?? 7) }
    </>
  );
};
