import * as React from 'react';
import { AgentHomeLayoutProps } from './ManagerHomeLayout.interfaces';
import {
  ManagerHomeLayoutContainer, MainPanelContainer,
  NavBarContainer,
} from './ManagerHomeLayout.elements';

export const ManagerHomeLayout: React.FunctionComponent<AgentHomeLayoutProps> = ({
  navBar,
  mainPanel,
}) => (
  <ManagerHomeLayoutContainer>
    <NavBarContainer>
      {navBar}
    </NavBarContainer>
    <MainPanelContainer>
      {mainPanel}
    </MainPanelContainer>
  </ManagerHomeLayoutContainer>
);
