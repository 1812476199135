import { IEventBusEvent } from '@healthiqeng/core.services.event-bus';
import { OverlayFragment } from '../../../fragments';

export class OverlayUpsertEvent<D> implements IEventBusEvent {
  public readonly type = 'OverlayUpsertEvent';
  constructor(
    public readonly externalType: OverlayFragment<D>['externalType'],
    public readonly data: Partial<D>,
  ) {}
}
