import styled from '@emotion/styled';
import { css } from '@emotion/core';

export const DementiaFlagTextBold = styled('span')(css`
  font-size: 24px;
  font-weight: 700;
`);

export const DementiaFlagText = styled('p')(css`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
`);
