import { useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { GetPlaceDetailsQueryData, GetPlaceDetailsQueryVariables } from './useGetPlaceDetailsQuery.interfaces';

const GET_PLACE_DETAILS_QUERY = gql`
  query GetPlaceDetails($placeId: String!, $sessionToken: String!) {
    getPlaceDetails(placeId: $placeId, sessionToken: $sessionToken) {
      state
      city
      zip
    }
  }
`;

export function useGetPlaceDetailsQuery() {
  return useLazyQuery<GetPlaceDetailsQueryData, GetPlaceDetailsQueryVariables>(GET_PLACE_DETAILS_QUERY);
}
