import { RadioInput, Row } from './CheckMyHealthRecordVerifyUserCarrier.elements';
import { Radio } from '@material-ui/core';
import React from 'react';
import { CarrierLink } from './CarrierLink';
import { CarrierVerificationAlternativeProps } from './CheckMyHealthRecordVerifyUserCarrier.interfaces';

export const CarrierVerificationAlternative = ({
  name, value, url, handleChange, checked,
}: CarrierVerificationAlternativeProps) => (
  <Row>
    <CarrierLink name={name} url={url} />
    <RadioInput
      labelPlacement="end"
      key={name}
      control={(
        <Radio
          value={value}
          onChange={handleChange}
          color="primary"
        />
      )}
      label={url ? `Verified via ${name}` : name}
      checked={checked}
    />
  </Row>
);
