import * as React from 'react';
import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useLicensingQuery } from '../../../graphql/queries/useLicensingQuery';
import { useActiveLeadId } from '../../routers/LeadRouter/useActiveLeadId';
import { noop } from '../../../util/noop';

const LicenseWarningInner: React.FunctionComponent<{ leadId: string }> = ({ leadId }) => {
  const { data, loading, error } = useLicensingQuery(leadId);
  if (loading || error || data?.licensing?.isLicensed !== false) return null;
  const anchorOrigin: SnackbarOrigin = React.useMemo(() => ({ vertical: 'bottom', horizontal: 'left' }), []);
  return (
    <Snackbar
      open={data?.licensing?.isLicensed === false}
      onClose={noop}
      anchorOrigin={anchorOrigin}
    >
      <MuiAlert severity="error" variant="filled">
        { data?.licensing?.message }
      </MuiAlert>
    </Snackbar>
  );
};

export const LicenseWarning: React.FunctionComponent = () => {
  const leadId = useActiveLeadId();
  if (!leadId) return null;
  return <LicenseWarningInner leadId={leadId} />;
};
