import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const UserReportsContainer = styled('div')(css`
  height: 100%;
  overflow: scroll;
`);

export const UserReportsHeader = styled('h2')(css`
  padding: 10px 16px;
  margin: 0;
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
`);

export const UserReportsContentContainer = styled('div')(css`
  padding: 0 20px;
`);
