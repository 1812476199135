import * as React from 'react';
import { ActivityFragment, useCustomerQuery } from '../../../../graphql';
import {
  ActivitiesQueryVariables,
  AllActivitiesQueryData,
  useActivitiesQuery,
} from '../../../../graphql/queries/useActivitiesQuery';
import { FetchMoreOptions } from 'apollo-boost';
import { ACTIVITIES_LIMIT, Activity, groupActivities } from './Activities';
import { useFormAnswers } from '../../../../hooks';
import { ActivityType } from '@hiq/crm.types';

export const AllActivities: React.FunctionComponent = () => {
  const { loading: customerLoading, error: customerError, data: customerData } = useCustomerQuery();
  const { activityFeedId, id: customerId } = customerData?.customer ?? {};
  const [fetchActivities, {
    data, error, loading, fetchMore,
  }] = useActivitiesQuery();
  const [isFetchMoreVisible, setIsFetchMoreVisible] = React.useState(true);
  const [answers] = useFormAnswers();

  React.useEffect(() => {
    if (activityFeedId) {
      fetchActivities({
        variables: {
          customerId,
          activityFeedId,
          offset: 0,
          limit: ACTIVITIES_LIMIT,
          pullTasks: true,
        },
      });
    }
  }, [customerId, activityFeedId]);

  React.useEffect(() => {
    handleFetchMoreVisibility();
  }, [data?.activities?.length]);

  const handleFetchMoreVisibility = () => {
    if (data?.activities?.length < ACTIVITIES_LIMIT) {
      setIsFetchMoreVisible(false);
    } else if (data?.activities?.length === ACTIVITIES_LIMIT) {
      setIsFetchMoreVisible(true);
    }
  };

  const onFetchMoreHandler = React.useCallback(async () => {
    fetchMore({
      variables: {
        offset: data?.activities?.length,
        pullTasks: false,
      },
      updateQuery: (previousData, { fetchMoreResult }) => {
        if (!fetchMoreResult?.activities?.length || fetchMoreResult?.activities?.length < ACTIVITIES_LIMIT) {
          setIsFetchMoreVisible(false);
        }
        return ({
          activities: [
            ...(previousData?.activities ?? []),
            ...(fetchMoreResult?.activities ?? []),
          ],
        });
      },
    } as FetchMoreOptions<AllActivitiesQueryData, ActivitiesQueryVariables>);
  }, [fetchMore, data?.activities?.length]);

  const { activities } = data ?? {};

  const addScratchPadAsTopActivity = (activities: ActivityFragment[]) => {
    if (Array.isArray(activities)) {
      const isAlreadyAdded = activities.find((a) => a.type === ActivityType.ScratchPad);
      const scratchPad = answers['lead.scratchPad'];
      if (!isAlreadyAdded && scratchPad) {
        activities.push({
          type: ActivityType.ScratchPad,
          timestamp: new Date().toISOString(),
          body: scratchPad,
          createdBy: null,
          activityFeed: null,
          hash: null,
        });
      }
    }

    return activities;
  };

  const groupedActivities = React.useMemo(
    () => groupActivities(addScratchPadAsTopActivity(activities) ?? []),
    [activities],
  );

  return (
    <Activity
      groupedActivities={groupedActivities}
      customerLoading={customerLoading}
      loading={loading}
      customerError={customerError}
      error={error}
      isFetchMoreVisible={isFetchMoreVisible}
      onFetchMoreHandler={onFetchMoreHandler}
    />
  );
};
