import * as React from 'react';
import { useFormAnswersQuery } from '../../../hooks';
import { useActiveLeadId } from '../../routers/LeadRouter/useActiveLeadId';
import { useLeadQuery, usePrefillSunfireMutation } from '../../../graphql';
import { useCallDirectionCompliance } from './useCallDirectionCompliance';
import { PrimaryButton } from '../inputs/PrimaryButton';
import { QueryLoader } from '../QueryLoader';
import { WarningBlockView } from '../ScriptBlock/WarningBlock/WarningBlockView';
import { CloseSunfireReminder } from './CloseSunfireDialog';
import { navigateToTrmSection, openLinkInNewWindow } from '../../../util';

export const Sunfire: React.FunctionComponent = () => {
  const leadId = useActiveLeadId();
  const formAnswers = useFormAnswersQuery(leadId);
  const [prefillSunfire, { data, loading, error }] = usePrefillSunfireMutation();
  const url = data?.prefillSunfire?.sunfireReference?.url ?? formAnswers['lead.sunfire.url'];
  const { isInboundCall, isAcknowledged, onAcknowledge } = useCallDirectionCompliance(leadId);
  const { data: leadData } = useLeadQuery(leadId);
  const [openSunfireReminder, setSunfireReminderOpen] = React.useState(false);

  function closeSunfireReminderDialog() {
    setSunfireReminderOpen(false);
  }

  function openSunfireWindow(url: string) {
    openLinkInNewWindow(url);
  }

  function goToMaPresentationSection() {
    navigateToTrmSection('ma-presentation');
  }

  function handleOpenSunfire() {
    setSunfireReminderOpen(true);
  }

  function handleCloseSunfireDialogReminder(url: string) {
    closeSunfireReminderDialog();
    openSunfireWindow(url);
    goToMaPresentationSection();
  }

  return (
    <QueryLoader loading={loading} error={error} componentName="Sunfire">
      <>
        { !isInboundCall && (
          <WarningBlockView
            value={isAcknowledged}
            onChange={onAcknowledge}
            confirmationRequired
            confirmText="I acknowledge that I won't perform an enrollment on this phone call."
          >
            Warning! This is an outbound call; please have the customer call you back on your direct line before starting the enrollment.
          </WarningBlockView>
        ) }
        { !!leadData?.isSold && (
          <WarningBlockView>
            This lead has already been sold. If you are selling a new policy to this customer, you must create
            a new record.
          </WarningBlockView>
        ) }
        { !url && (
          <PrimaryButton
            onClick={() => prefillSunfire({ leadId })}
            variant="outlined"
            disabled={isDisabled(isInboundCall, isAcknowledged, leadData?.isSold)}
          >
            Prefill Sunfire
          </PrimaryButton>
        ) }
        { url && (
          <>
            <PrimaryButton
              onClick={handleOpenSunfire}
              variant="contained"
              disabled={isDisabled(isInboundCall, isAcknowledged)}
            >
              Open Sunfire
            </PrimaryButton>
            <CloseSunfireReminder open={openSunfireReminder} handleAccept={() => handleCloseSunfireDialogReminder(url)} />
          </>
        ) }
      </>
    </QueryLoader>
  );
};

function isDisabled(isInboundCall: boolean, isAcknowledged: boolean, isSold?: boolean) {
  return !(isInboundCall || isAcknowledged) || !!isSold;
}
