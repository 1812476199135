import * as React from 'react';
import { CustomerCommunication } from '../CustomerCommunication';
import { CommunicationTemplate, IndustryType } from '@hiq/crm.types';
import { useCustomerQuery } from '../../../graphql/queries/useCustomerQuery';
import { useCurrentUserIsBionic } from '../../../hooks';

export const SendPDPSelfEnroll: React.FunctionComponent = () => {
  const { data } = useCustomerQuery();
  const hasMedicare = data
    ?.customer
    ?.leads
    ?.some((lead) => lead.industry.type === IndustryType.Medicare);
  const isBionic = useCurrentUserIsBionic();
  if (!hasMedicare) return <></>;
  return (
    <>
      <span style={{ marginRight: 10 }}>
        {!isBionic && (
        <CustomerCommunication
          communicationTemplate={CommunicationTemplate.MaSelfEnrollLink}
          buttonText="Send MA Self Enrollment Link"
          buttonSize="small"
          title="MA Self Enrollment Link"
        />
        )}
      </span>
      <span>
        <CustomerCommunication
          communicationTemplate={CommunicationTemplate.PdpSelfEnrollLink}
          buttonText="Send PDP Self Enrollment Link"
          buttonSize="small"
          title="PDP Self Enrollment Link"
        />
      </span>
    </>
  );
};
