import * as React from 'react';
import { Disposition } from '@healthiqeng/core.services.dialer.base';
import { isEmpty } from '@healthiqeng/core.util.is-empty';
import { useDialerService, captureDialerException } from '@healthiqeng/core.hooks.use-dialer-service';
import { useLeadQuery } from '../../../../graphql';
import { getSpeedDialFromCampaign } from './getSpeedDialFromCampaign';
import {
  ALLOWED_DISPOSITIONS,
  DISPOSITION_RULES,
  DispositionLabelMap,
  DispositionVisibility,
  DispositionFive9Label,
} from './constants';
import { useDialerTransfer } from './useDialerTransfer';
import { emitAnalyticsEvent } from '../../../../events/emitAnalyticsEvent';

export function useDialerTransferButtons() {
  const { loading: dialerLoading, error, dialerService } = useDialerService();
  const {
    waitForNextCall,
    dispositions,
    fetchDispositions,
    loadingDispositions,
    transferError,
    transferInProgress,
    setTransferInProgress,
    setTransferError,
    campaignId,
  } = useDialerTransfer();
  const initialized = dialerService?.getIsInitialized() ?? false;
  const initiatedConference = dialerService?.getInitiatedConference() ?? false;
  const currentCallInProgress = dialerService?.currentCallInProgress() ?? false;
  const [endingCall, setIsEndingCall] = React.useState(false);
  const [loading, setLoading] = React.useState(false); // flag to prevent agents from spamming
  const disabled = transferError || !initialized || loading;
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const lead = useLeadQuery();

  React.useEffect(() => {
    setErrorMessage(error?.message);
  }, [error]);

  const transfer = async () => {
    try {
      setLoading(true);
      const speedDial = await getSpeedDialFromCampaign(lead?.data?.campaignName, lead?.data?.callSkill);
      await dialerService.initiateConference(speedDial);
      setTransferInProgress(true);
      emitAnalyticsEvent('Initiated Agent Transfer', { campaignName: lead?.data?.campaignName });
    } catch (err) {
      setTransferError(true);
      setErrorMessage(err.message);
      captureDialerException(err, 'initiateConference', dialerService.getCallData());
      emitAnalyticsEvent('Five9 Error', { campaignName: lead?.data?.campaignName, error: err });
    } finally {
      setLoading(false);
    }
  };

  const handleTransferSuccess = async () => {
    if (endingCall) return;
    try {
      setLoading(true);
      setIsEndingCall(true);
      const disposition = getTransferSuccessDisposition(dispositions);
      await dialerService.leaveConference(disposition);
      emitAnalyticsEvent('Transfer Success');
      waitForNextCall();
    } catch (err) {
      setTransferError(true);
      setErrorMessage(err.message);
      captureDialerException(err, 'leaveConference', dialerService.getCallData());
      emitAnalyticsEvent('Five9 Error', { campaignName: lead?.data?.campaignName, error: err });
    } finally {
      setLoading(false);
    }
  };

  const cancelTransfer = async () => {
    try {
      setLoading(true);
      await dialerService.cancelTransfer();
      setTransferInProgress(false);
      emitAnalyticsEvent('Cancel Transfer');
    } catch (err) {
      setTransferError(true);
      setErrorMessage(err.message);
      captureDialerException(err, 'cancelTransfer', dialerService.getCallData());
      emitAnalyticsEvent('Five9 Error', { campaignName: lead?.data?.campaignName, error: err });
    } finally {
      setLoading(false);
    }
  };

  const handleDisposition = async (value: string) => {
    if (endingCall) return;
    try {
      setLoading(true);
      setIsEndingCall(true);
      const disposition = getDisposition(dispositions, value);
      await dialerService.endCall(disposition);
      emitAnalyticsEvent('Dispositioned Call', { disposition: disposition.label });
      waitForNextCall();
    } catch (err) {
      setTransferError(true);
      setErrorMessage(err.message);
      captureDialerException(err, 'endCall', dialerService.getCallData());
      emitAnalyticsEvent('Five9 Error', { campaignName: lead?.data?.campaignName, error: err });
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (currentCallInProgress || campaignId) {
      fetchDispositions();
    }
  }, [currentCallInProgress, campaignId]);

  return {
    transfer,
    transferInProgress,
    handleTransferSuccess,
    cancelTransfer,
    handleDisposition,
    disabled,
    initiatedConference,
    loading: dialerLoading,
    loadingDispositions,
    currentCallInProgress,
    error: error || transferError,
    errorMessage,
    endingCall,
    initializedLead: !isEmpty(lead?.data),
    beforeTransferDispositions: getDispositionOptions(dispositions, DispositionVisibility.BeforeTransferInitiated),
    afterTransferDispositions: getDispositionOptions(dispositions, DispositionVisibility.AfterTransferInitiated),
    alwaysVisibleDispositions: getDispositionOptions(dispositions, DispositionVisibility.Always),
  };
}

function getTransferSuccessDisposition(dispositions: Disposition[]): Disposition {
  return dispositions?.find((disposition) => disposition.label === 'Transfer: Success');
}

function getDisposition(dispositions: Disposition[], value: string): Disposition {
  return dispositions?.find((disposition) => disposition.value === value);
}

function getDispositionOptions(dispositions: Disposition[], visibility: DispositionVisibility) {
  if (!dispositions) return [];
  return dispositions
    .filter((disposition) => filterDisposition(disposition))
    .filter((disposition) => DISPOSITION_RULES[disposition.label]?.visibility === visibility)
    .map((disposition) => ({
      label: DispositionLabelMap[disposition.label as DispositionFive9Label],
      value: disposition.value,
    }));
}

function filterDisposition(disposition: Disposition) {
  return ALLOWED_DISPOSITIONS.includes(disposition.label as DispositionFive9Label);
}
