import * as React from 'react';
import { QuestionProps } from './Question.interfaces';
import { useQuestionQuery } from '../../../../../graphql/queries/useQuestionQuery';
import { QueryLoader } from '../../../QueryLoader';
import { useConditionalRendering } from '../../useConditionalRendering';
import { QuestionContainer, QuestionLabel, QuestionValue } from './Question.elements';
import { useInputFormatter } from '../../../inputs';
import { CustomElement } from '../../../CustomElement';

export const Question: React.FunctionComponent<QuestionProps> = ({
  leadId,
  id,
  visibleCondition,
  answers,
}) => {
  const { error, loading, data: question } = useQuestionQuery(leadId, id);
  const questionIsVisible = useConditionalRendering({
    questionId: id,
    parentQuestionId: null,
    answers,
    visibleCondition,
  });

  if (!questionIsVisible) return <></>;

  if (question?.lockedCustomComponent) return <CustomElement referenceId={question.lockedCustomComponent} />;

  const value = answers[question?.id];
  const formattedValue = useInputFormatter(value, question?.type, question?.options);
  return (
    <QueryLoader loading={loading} error={error} variant="skeleton-small" componentName="Question">
      <QuestionContainer>
        <QuestionLabel>
          { question?.label }
        </QuestionLabel>
        <QuestionValue>
          { formattedValue }
        </QuestionValue>
      </QuestionContainer>
    </QueryLoader>
  );
};
