import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  SunfireV2FindDoctorsByTermQueryData,
  SunfireV2FindDoctorsByTermQueryVariables,
} from './useSunfireV2DoctorsByTermQuery.interfaces';

const SUNFIRE_DOCTORS_QUERY = gql`
  query FindSunfireV2DoctorsByTerm(
    $term: String!,
    $leadId: ID!,
    $zipcode: String,
    $city: String,
    $state: String
  ) {
    findSunfireDoctorsByTerm(
      term: $term,
      leadId: $leadId,
      zipcode: $zipcode,
      city: $city,
      state: $state
    ) {
      aliasName
      firstName
      id
      name
      sortName
    }
  }
`;

export function useSunfireV2DoctorsByTermQuery() {
  return useLazyQuery<SunfireV2FindDoctorsByTermQueryData, SunfireV2FindDoctorsByTermQueryVariables>(SUNFIRE_DOCTORS_QUERY);
}
