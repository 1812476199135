import * as React from 'react';
import {
  CommunicationTemplate, IndustryType, QuestionType, UserProfile,
} from '@hiq/crm.types';
import { QuestionView } from '../../FormGenerator/FormGeneratorQuestionFlow/Question/QuestionView';
import { useFormAnswers } from '../../../../hooks';
import { useActiveLeadId, useCustomerId } from '../../../routers';
import { useCurrentUserQuery, useLeadQuery, useSendCommunication } from '../../../../graphql';
import Alert from '@material-ui/lab/Alert/Alert';
import { useDebounce } from '@healthiqeng/core.hooks.use-debounce';
import { isPresent } from '@healthiqeng/core.util.is-present';
import { emitAnalyticsEventNoLimit } from '../../../../events/emitAnalyticsEvent';

interface CheckMyHealthRecordHipaaConsentProps {
  hideQuestionText?: boolean;
}

export const CheckMyHealthRecordHipaaConsent: React.FunctionComponent<CheckMyHealthRecordHipaaConsentProps> = ({ hideQuestionText }) => {
  const customerId = useCustomerId();
  const leadId = useActiveLeadId();
  const { data: userData } = useCurrentUserQuery();
  const { data: leadData } = useLeadQuery();
  const trackingId = React.useMemo(() => `CustomerCommunicationHipaaConsent-${Date.now()}`, []);
  const [answers, onChange, persistAnswers] = useFormAnswers(leadId, false, trackingId);
  const checkMyHealthRecordHipaaConsentQuestionId = 'lead.digitalProductHipaaConsent';
  const customerHipaaApprovedId = 'customer.person.hipaaConsentApproved';
  const customerHipaaDateTimeId = 'customer.person.hipaaConsentDatetime';
  const [sendCommunication, { data, error }] = useSendCommunication();

  const leadIndustryType = leadData.industry.type;
  const userProfileType = userData.currentUser.profile;
  let campaign = 'NULL';

  if (leadIndustryType === IndustryType.Life) campaign = 'life';
  if (leadIndustryType === IndustryType.PropertyAndCasualty) campaign = 'pnc';
  if (leadIndustryType === IndustryType.Medicare) campaign = 'medicare';
  if (userProfileType === UserProfile.RetentionSpecialist) campaign = 'retention';

  const utmCampaign = `verbal_auth_${campaign}`;

  const sendMessage = () => {
    sendCommunication({
      customerId,
      options: {
        communicationTemplate: CommunicationTemplate.CheckMyHealthRecordLink,
        utmCampaign,
      },
    });
  };

  const debouncedCommunicationSend = useDebounce(sendMessage, 500);

  const handleHipaaConsentChange = async (consentReceived: boolean) => {
    if (consentReceived !== answers[checkMyHealthRecordHipaaConsentQuestionId]) {
      onChange(checkMyHealthRecordHipaaConsentQuestionId, consentReceived, true);
      onChange(customerHipaaApprovedId, consentReceived, true);
      if (!consentReceived) {
        emitAnalyticsEventNoLimit('HIPAA Rejected', { customerId, leadId });
        onChange(customerHipaaDateTimeId, null, true);
      } else {
        emitAnalyticsEventNoLimit('HIPAA Consented', { customerId, leadId });
        onChange(customerHipaaDateTimeId, new Date().toISOString(), true);
      }
      persistAnswers();

      debouncedCommunicationSend();
    }
  };

  const checkAllAnswers = (
    firstName: string,
    lastName: string,
    gender: string,
    dateOfBirth: string,
    zip: string,
    email: string,
    phone: string,
    contactPreference: string,
  ) => {
    const errors = [];

    if (!firstName || firstName === '') {
      errors.push('Please verify the first name in the verification form above');
    }

    if (!lastName || lastName === '') {
      errors.push('Please verify the last name in the verification form above');
    }

    if (!gender || gender === '') {
      errors.push('Please verify the gender in the verification form above');
    }

    if (!dateOfBirth || dateOfBirth === '') {
      errors.push('Please verify the date of birth in the verification form above');
    }

    if (!zip || zip === '') {
      errors.push('Please verify the zip in the verification form above');
    }

    if (!email || email === '') {
      errors.push('Please verify the email in the verification form above');
    }

    if (!phone || phone === '') {
      errors.push('Please verify the phone in the verification form above');
    }

    if (!contactPreference || contactPreference === '') {
      errors.push('Please select at least one contact preference in the verification form above');
    }

    return { hasErrors: errors.length > 0, errors };
  };

  const firstName = answers['customer.person.name.first'];
  const lastName = answers['customer.person.name.last'];
  const gender = answers['customer.person.gender'];
  const dateOfBirth = answers['customer.person.dateOfBirth'];
  const zip = answers['customer.person.homeAddress.zip'];
  const email = answers['customer.person.email'];
  const phone = answers['customer.person.phone.number'];
  const contactPreference = answers['customer.person.contactPreference'];

  const { hasErrors, errors } = React.useMemo(
    () => checkAllAnswers(
      firstName,
      lastName,
      gender,
      dateOfBirth,
      zip,
      email,
      phone,
      contactPreference,
    ), [
      firstName,
      lastName,
      gender,
      dateOfBirth,
      zip,
      email,
      phone,
      contactPreference,
    ],
  );

  const questionText = hideQuestionText ? '' : 'Digital Product HIPAA consent received';

  return (
    <>
      { hasErrors && (
        <Alert severity="error">
          <div>
            Please verify the following answers if you are going to click Yes as the answer below.
          </div>
          <div>
            Please ensure that all the fields incidated below are filled in:
          </div>
          {errors.map((error) => <div>{error}</div>)}
        </Alert>
      )}
      <QuestionView
        id={checkMyHealthRecordHipaaConsentQuestionId}
        leadId={leadId}
        onChange={handleHipaaConsentChange}
        value={answers[checkMyHealthRecordHipaaConsentQuestionId]}
        questionText={questionText}
        label="CMHR Digital Product HIPAA consent"
        type={QuestionType.RadioYesNo}
      />
      { (isPresent(data?.sendCommunication?.success)) && (
        <Alert severity={data?.sendCommunication?.success ? 'success' : 'error'}>
          { data?.sendCommunication?.success ? data?.sendCommunication?.message : error.message}
        </Alert>
      ) }
    </>
  );
};
