import * as React from 'react';
import { MedicareSelectedQuotesProps } from './MedicareSelectedQuotes.interfaces';
import { MedicareSelectedQuoteContainer } from './MedicareSelectedQuotes.elements';
import { QueryLoader } from '../../../../../../common/QueryLoader';
import { useLeadQuery } from '../../../../../../../graphql';
import { QuoteCellFactory } from '../../../../../../common/quoting/QuoteCellFactory';
import { Card } from '../../../../../../common/Card';

export const MedicareSelectedQuotes: React.FunctionComponent<MedicareSelectedQuotesProps> = () => {
  const { data, loading, error } = useLeadQuery();

  return (
    <QueryLoader loading={loading} error={error} componentName="MedicareSelectedQuotes">
      <>
        { data?.selectedQuotes?.map((quote) => (
          <MedicareSelectedQuoteContainer key={quote?.hash}>
            <Card>
              <QuoteCellFactory
                type={quote?.product?.type}
                hash={quote?.hash}
              />
            </Card>
          </MedicareSelectedQuoteContainer>
        )) }
      </>
    </QueryLoader>
  );
};
