import * as React from 'react';
import { Box, Radio, RadioGroup } from '@material-ui/core';
import { Paragraph, Header } from './AppointmentsList.elements';
import { AppointmentsListProps } from './AppointmentsList.interfaces';
import { Appointment } from '../../../graphql/queries/useMostRecentAppointmentsQuery';

export const AppointmentsList: React.FC<AppointmentsListProps> = ({
  headerText,
  appointments,
  handleNumberSelection,
  appointmentSelected,
}) => (
  <>
    <Header>
      {headerText}
    </Header>
    <RadioGroup>
      {
          appointments.map((appointment: Appointment) => {
            const name = appointment.lead?.customer?.person?.name;
            const apptTime = new Date(appointment.appointmentTime).toLocaleDateString('en-US',
              {
                weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric',
              });
            const number = appointment.lead?.customer?.person?.phone?.number;
            const appointmentId = `${number}_${appointment.appointmentTime}_${
              appointment.lead.id}_${appointment.lead.customer.id}` as string;
            return (
              <>
                <Box display="flex" justifyContent="flex-start" alignItems="flex-end">
                  <Radio
                    value={appointmentId}
                    color="primary"
                    checked={appointmentId === appointmentSelected}
                    onChange={handleNumberSelection}
                  />
                  <Paragraph>
                    {name.first}
                    {' '}
                    {name.last}
                    {' '}
                    |
                    {' '}
                    {apptTime}
                  </Paragraph>
                </Box>
              </>
            );
          })
        }
    </RadioGroup>
  </>
);
