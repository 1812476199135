import { css, keyframes } from '@emotion/core';
import styled from '@emotion/styled';

const pulse = keyframes`
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
`;

export const LoaderContainer = styled('div')(css`
  display: block;
  margin: 20px 0;
  max-width: 500px;
`);

export const LoaderContent = styled('div')(css`
  width: 100%;
  min-height: 39px;
  background-image: url(https://s3-us-west-2.amazonaws.com/hiq-web-images/cdn_media/marketing/logo/logo-healthiq-color.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  animation: ${pulse} 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
`);
