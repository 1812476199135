import { Checkbox, Typography } from '@material-ui/core';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { FormControlLabel } from './CheckboxInput.elements';
import { CheckboxItemProps } from './CheckboxInput.interfaces';
import { ThemeLight } from '../../../../themes';

export const CheckboxItem = React.memo(({
  checked,
  disabled,
  onChange,
  label,
  name,
  variant,
}: CheckboxItemProps) => (
  <FormControlLabel
    backgroundColor={getBackgroundColor(checked, variant)}
    label={(
      <Typography style={{
        minWidth: 'fit-content',
        paddingRight: '10px',
        lineHeight: '16px',
        color: getColor(checked, variant),
        fontStyle: variant === 'compliance' ? 'italic' : 'normal',
      }}
      >
        <ReactMarkdown source={label} linkTarget="_blank" />
      </Typography>
    )}
    checked={checked}
    disabled={disabled}
    control={(
      <Checkbox
        name={name}
        onChange={onChange}
        style={{
          color: getBoxColor(checked, variant),
        }}
        checked={checked}
      />
    )}
  />
));

function getColor(checked: boolean, variant?: string) : string {
  if (variant === 'compliance') return ThemeLight.color.purple;
  return checked ? ThemeLight.color.primaryDark : ThemeLight.color.black;
}

function getBoxColor(checked: boolean, variant?: string) : string {
  if (variant === 'compliance') return ThemeLight.color.purple;
  return checked ? ThemeLight.color.primary : ThemeLight.color.black;
}

function getBackgroundColor(checked: boolean, variant?: string) : string {
  if (variant === 'compliance') return ThemeLight.color.purpleLighter;
  return checked ? ThemeLight.color.primaryLighter : ThemeLight.color.white;
}
