import * as React from 'react';
import { RunAutomatedUnderwritingProps } from './RunAutomatedUnderwriting.interfaces';
import { ValidationError } from './RunAutomatedUnderwriting.elements';
import { PrimaryButton } from '../../PrimaryButton';

export const RunAutomatedUnderwriting: React.FunctionComponent<RunAutomatedUnderwritingProps> = ({
  onUnderwrite,
  loading,
  validationSuccess,
  validationErrors,
}) => (
  <>
    <PrimaryButton
      onClick={() => onUnderwrite()}
      variant="contained"
      fullWidth
      disabled={loading || !validationSuccess}
    >
      Run Automated Underwriting
    </PrimaryButton>
    { !!validationErrors?.length && (
      <ul>
        { validationErrors.map(({ message }) => (
          <ValidationError key={message}>
            { message }
          </ValidationError>
        )) }
      </ul>
    ) }
  </>
);
