import * as React from 'react';
import { FormItemTypeGroupProps } from './FormItemTypeGroup.interfaces';
import { useConditionalRendering } from '../../../useConditionalRendering';

export const FormItemTypeGroup: React.FunctionComponent<FormItemTypeGroupProps> = ({
  parentQuestionId,
  leadId,
  visibleCondition,
  childrenVisibleCondition,
  children,
  FormItemFactory,
  depth,
  answers,
  onChange,
  FormGeneratorQuestionFlow,
}) => {
  const formItemVisible = useConditionalRendering({
    questionId: null,
    parentQuestionId,
    answers,
    visibleCondition,
  });

  const childrenVisible = useConditionalRendering({
    questionId: null,
    parentQuestionId,
    answers,
    visibleCondition: childrenVisibleCondition,
  });

  if (!formItemVisible) {
    return <></>;
  }

  return (
    <>
      { childrenVisible && children.map((child) => (
        <FormItemFactory
          key={child.questionId}
          leadId={leadId}
          depth={depth}
          answers={answers}
          onChange={onChange}
          parentQuestionId={parentQuestionId}
          FormGeneratorQuestionFlow={FormGeneratorQuestionFlow}
          {...child}
        />
      )) }
    </>
  );
};
