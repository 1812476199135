import { useLeadQuery } from '../useLeadQuery';

export function usePinnedQuotesQuery(leadId: string) {
  const result = useLeadQuery(leadId);
  return {
    error: result?.error,
    loading: result?.loading,
    data: result?.data?.pinnedQuotes ?? [],
  };
}
