import { useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import {
  PropertyAndCasualtyQuoteQueryData,
  PropertyAndCasualtyQuoteQueryVariables,
} from './usePropertyAndCasualtyQuotesLazyQuery.interfaces';
import { QUOTE_FRAGMENT } from '../../fragments';

const PROPERTY_AND_CASUALTY_AUTO_QUOTE_QUERY = gql`
  query getPropertyAndCasualtyQuotes($leadId: ID!, $inputs: PropertyAndCasualtyFetchQuoteInput) {
    getPropertyAndCasualtyAutoQuotes(leadId: $leadId, inputs: $inputs) {
      quoteResults {
        quote {
          ...QuoteFragment
        }
        notes {
          type
          message
        }
      }
     errors
    }
  }
  ${QUOTE_FRAGMENT}
`;

const PROPERTY_AND_CASUALTY_HOME_QUOTE_QUERY = gql`
  query getPropertyAndCasualtyHomeQuotes($leadId: ID!, $inputs: PropertyAndCasualtyFetchQuoteInput) {
    getPropertyAndCasualtyHomeQuotes(leadId: $leadId, inputs: $inputs) {
      quoteResults {
        quote {
          ...QuoteFragment
        }
        notes {
          type
          message
        }
      }
     errors
    }
  }
  ${QUOTE_FRAGMENT}
`;

export function usePropertyAndCasualtyAutoQuoteLazyQuery() {
  return useLazyQuery<PropertyAndCasualtyQuoteQueryData, PropertyAndCasualtyQuoteQueryVariables>(
    PROPERTY_AND_CASUALTY_AUTO_QUOTE_QUERY,
    { fetchPolicy: 'network-only' },
  );
}

export function usePropertyAndCasualtyHomeQuoteLazyQuery() {
  return useLazyQuery<PropertyAndCasualtyQuoteQueryData, PropertyAndCasualtyQuoteQueryVariables>(
    PROPERTY_AND_CASUALTY_HOME_QUOTE_QUERY,
    { fetchPolicy: 'network-only' },
  );
}
