import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const QuoteRatesContainer = styled('div')(css`
  margin: 5px 0;
`);
export const QuoteRateAmount = styled('h3')<{large?: boolean}>(({ large }) => css`
  margin: 5px 0;
  font-weight: bold;
  font-size: ${large ? '20px' : '16px'};
`);
