import { HealthRecordUserClaim, QuestionOption } from '@hiq/crm.types';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import { AddICDPanelContainer, SelectInputContainer } from './AddICDPanel.elements';
import { AddICDPanelProps } from './AddICDPanel.interfaces';
import { useUpsertOverlayMutation } from '../../../../../graphql';
import { useActiveLeadId, useCustomerId } from '../../../../routers';
import {
  CTA,
  PanelInnerWrapper,
  RemoveItemButton,
  SaveItemButton,
  SectionTitle,
} from '../CheckMyHealthRecordDataGrid.elements';
import { SelectInput } from '../../../inputs';
import { emitAnalyticsEventNoLimit } from '../../../../../events/emitAnalyticsEvent';

const ICD_OPTIONS = [
  ['allergies', 'Allergies'],
  ['asthma', 'Asthma'],
  ['atrial_fibrillation', 'Atrial fibrillation (A-fib)'],
  ['bph', 'BPH (enlarged prostate)'],
  ['cataract', 'Cataracts'],
  ['chronic_kidney_disease', 'Chronic kidney disease (CKD)'],
  ['chronic_pain', 'Chronic pain'],
  ['chronic_sinusitis', 'Chronic sinusitis (nasal congestion)'],
  ['copd', 'Chronic obstructive pulmonary disease (COPD), including emphysema and chronic bronchitis'],
  ['disc_disease_spine', 'Disc disease in the spine'],
  ['diverticulosis', 'Diverticulosis'],
  ['dry_eyes', 'Dry eyes'],
  ['falls', 'History of falls'],
  ['family_diabetes', 'Family history of diabetes'],
  ['farsightedness', 'Farsightedness'],
  ['fatty_liver', 'Fatty liver'],
  ['fibromyalgia', 'Fibromyalgia'],
  ['gerd', 'Acid reflux (GERD)'],
  ['glaucoma', 'Glaucoma'],
  ['gout', 'Gout'],
  ['hearing_loss', 'Hearing loss'],
  ['heart_disease_blood_pressure', 'Heart disease due to high blood pressure'],
  ['heart_disease_cholesterol', 'Heart disease due to cholesterol buildup'],
  ['heart_failure', 'Heart failure'],
  ['high_blood_pressure', 'High blood pressure'],
  ['high_cholesterol', 'High cholesterol'],
  ['history_stroke', 'History of mini-stroke or stroke'],
  ['hypothyroidism', 'Hypothyroidism'],
  ['kidney_disease_blood_pressure', 'Kidney disease due to high blood pressure'],
  ['kidney_failure', 'Kidney failure'],
  ['migraine', 'Migraine'],
  ['obstructive_sleep_apnea', 'Sleep apnea (trouble breathing when asleep)'],
  ['osteoarthritis', 'Arthritis (osteoarthritis)'],
  ['osteoporosis', 'Osteoporosis'],
  ['past_heart_attack', 'Past heart attack'],
  ['peripheral_vascular_disease', 'Peripheral vascular disease'],
  ['plaque_vessels', 'Plaque buildup in vessels'],
  ['prediabetes', 'Prediabetes'],
  ['smoking', 'Smoking'],
  ['spinal_stenosis', 'Spinal stenosis (narrowing of spinal canal)'],
  ['stroke', 'Stroke'],
  ['type_2_diabetes', 'Type 2 diabetes'],
  ['urine_incontinence', 'Urine incontinence or overactive bladder'],
  ['obesity', 'Obesity'],
  ['blood_clot', 'Blood clot'],
  ['seizures', 'Seizures'],
  ['muscle_spasm', 'Muscle spasm'],
  ['nerve_pain', 'Nerve pain'],
  ['insomnia', 'Insomnia'],
  ['anemia', 'Anemia'],
  ['overactive_bladder', 'Overactive bladder'],
  ['dementia', 'Dementia and cognitive impairment'],
  ['lupus', 'Lupus'],
  ['parkinson', 'Parkinson\'s disease'],
  ['restless_legs', 'Restless legs syndrome'],
  ['vertigo', 'Vertigo'],
  ['vitamin_b12_deficiency', 'Vitamin B12 deficiency'],
  ['irritable_bowel', 'Irritable bowel syndrome (IBS)'],
  ['hyperthyroidism', 'Hyperthyroidism (high thyroid)'],
  ['ulcerative_colitis', 'Ulcerative colitis'],
  ['multiple_sclerosis', 'Multiple sclerosis'],
  ['nearsightedness', 'Nearsightedness'],
  ['urine_retention', 'Urine retention (difficulty urinating and emptying bladder)'],
  ['anemia_kidney_disease', 'Anemia (low red blood cells) from kidney disease'],
  ['orthostatic_hypotension', 'Low blood pressure when standing'],
  ['enlarged_heart', 'Enlarged heart'],
  ['sacroiliitis', 'Inflammation of sacrum'],
  ['thrombocytopenia', 'Low platelet count'],
  ['heart_murmur', 'Heart murmur'],
  ['hemorrhoids', 'Hemorrhoids (swollen veins in buttock region)'],
  ['arrhythmia', 'Irregular heart rhythm'],
  ['blockage_esophagus', 'Blockage in esophagus'],
  ['thyroid_nodule', 'Thyroid nodule'],
  ['thyroid_goiter', 'Thyroid goiter'],
  ['benign_colon_tumor', 'Benign colon tumor'],
  ['gallbladder_stone', 'Gallbladder stone,'],
  ['prostate_cancer', 'Prostate cancer'],
  ['viral_hepatitis', 'Viral hepatitis'],
  ['tachycardia', 'Fast heart rate'],
  [
    'pulmonary_hypertension',
    'Pulmonary hypertension (high blood pressure in lung vessels)',
  ],
  ['cardiomyopathy', 'Heart muscle disease'],
  ['breast_cancer', 'Breast cancer'],
  ['skin_cancer', 'Skin cancer'],
  ['hernia', 'Hernia (bulging of body organ)'],
  ['liver_fibrosis', 'Liver fibrosis (scar tissue in liver)'],
  ['cirrhosis', 'Cirrhosis (permanent scarring of liver)'],
  ['gingivitis', 'Gum inflammation'],
  ['tooth_cavities', 'Tooth cavities'],
  ['leukemia', 'Leukemia (blood cancer)'],
  ['lymphoma', 'Lymphoma (cancer of lymph system)'],
  [
    'subclinical_hypothyroidism',
    'Borderline hypothyroidism (borderline low thyroid)',
  ],
  ['vitamin_d_deficiency', 'Vitamin D deficiency'],
  ['type_1_diabetes', 'Type 1 diabetes'],
  ['lung_cancer', 'Lung cancer'],
  ['heart_block', 'Heart block'],
  [
    'nephrotic_syndrome',
    'Nephrotic syndrome (type of kidney disease)',
  ],
  ['hepatomegaly', 'Hepatomegaly (enlarged liver)'],
  ['breast_lump', 'Breast lump'],
  ['gallbladder_disease', 'Gallbladder disease'],
  ['esophagitis', 'Esophagitis (esophagus inflammation)'],
  ['roseacea', 'Rosacea'],
  ['varicose_veins', 'Varicose veins (enlarged, twisted leg veins)'],
  ['sarcoidosis', 'Sarcoidosis'],
  ['thyroid_cancer', 'Thyroid cancer'],
  ['colon_cancer', 'Colon cancer'],
  ['cushings_syndrome', 'Cushing\'s syndrome'],
  ['headaches', 'Headaches'],
  [
    'trigeminal_neuralgia',
    'Trigeminal neuralgia (chronic face pain)',
  ],
  ['myasthenia_gravis', 'Myasthenia gravis'],
  ['crohns_disease', 'Crohn\'s disease'],
  ['keratitis', 'Keratitis (inflammation of eye cornea)'],
  ['cervical_disc_disease', 'Cervical disc disease (neck pain)'],
  ['shoulder_lesions', 'Shoulder disease'],
  ['tinnitus', 'Tinnitus (ringing in ear)'],
  [
    'esophageal_varices',
    'Esophageal varices (abnormal veins in esophagus)',
  ],
  [
    'polyarteritis_nodosa',
    'Polyarteritis nodosa (inflammation and damage to blood vessels)',
  ],
  [
    'dermatomyositis',
    'Dermatomyositis (muscle weakness and skin rash)',
  ],
  ['polymyositis', 'Polymyositis (muscle weakness)'],
  [
    'systemic_sclerosis',
    'Systemic sclerosis (tightening and skin and tissue)',
  ],
  ['sjogren_syndrome', 'Sjogren syndrome (dry eyes and mouth)'],
  [
    'polymyalgia_rheumatica',
    'Polymyalgia rheumatica (PMR; muscle pain and stiffness around shoulders and hips)',
  ],
  [
    'giant_cell_arteritis',
    'Giant cell arteritis (inflammation of blood vessels in temple region)',
  ],
  ['psoriasis', 'Psoriasis (itchy, scaly skin)'],
  ['inguinal_hernia', 'Inguinal hernia (groin hernia)'],
  ['femoral_hernia', 'Femoral hernia (groin hernia)'],
  ['umbilical_hernia', 'Umbilical hernia (abdominal hernia)'],
  ['ventral_hernia', 'Ventral hernia (abdominal hernia)'],
  ['nail_fungus', 'Nail fungus'],
  ['tinea_pedis', 'Athlete\'s foot'],
  [
    'bronchiectasis',
    'Bronchiectasis (lung condition causing cough with mucous)',
  ],
  ['ulcer', 'Stomach and intestinal ulcer'],
  ['blindness_low_vision', 'Blindness and low vision'],
  ['coagulation_defects', 'Blood clotting defects'],
  ['knee_disorder', 'Knee disorder'],
  ['nerve_disorder', 'Nerve disorder'],
  ['walking_difficulty', 'Walking difficulty'],
  ['colon_polyp', 'Colon polyp'],
  ['past_heart_attack', 'Past heart attack'],
  ['gastritis', 'Gastritis (stomach inflammation)'],
  ['vitamin_b_deficiency', 'Vitamin B deficiency'],
  ['seborrheic_keratosis', 'Seborrheic keratosis (skin condition)'],
  ['vein_disorder', 'Vein disorder'],
  ['dermatitis', 'Dermatitis (skin inflammation)'],
  ['astigmatism', 'Astigmatism (eye condition)'],
  ['lung_nodule', 'Lung nodule'],
  ['actinic_keratosis', 'Actinic keratosis (skin condition)'],
  ['skin_neoplasm', 'Skin growth'],
  ['vitreous_body_disorder', 'Vitreous body disorder (eye condition)'],
  ['corns_calluses', 'Corns and calluses (skin condition)'],
  ['nail_condition', 'Nail condition'],
  ['heart_valve_condition', 'Heart valve condition'],
  ['impacted_cerumen', 'Earwax buildup'],
  ['precerebral_artery_occlusion', 'Neck artery blockage'],
  ['transient_ischemic_attack', 'Stroke-like symptoms'],
  ['pruritis', 'Pruritis (itchy skin)'],
  ['plantar_fascial_fibromatosis', 'Plantar fascia condition'],
];

export const AddICDPanel = ({ setPanelClosed }: AddICDPanelProps) => {
  const [upsertOverlayUserClaim] = useUpsertOverlayMutation();
  const customerId = useCustomerId();
  const leadId = useActiveLeadId();
  const [diseaseKey, setDiseaseKey] = useState<string>();
  const selectOptions: QuestionOption[] = React.useMemo(
    () => ICD_OPTIONS.map((([diseaseKey, description]) => ({ label: description, value: diseaseKey }))),
    [],
  );
  const handleDiagnosisChange = (diseaseKey?: string) => setDiseaseKey(diseaseKey);
  const onSave = () => {
    const option = selectOptions.find((current) => current.value === diseaseKey);
    if (option) {
      const userClaim: Partial<HealthRecordUserClaim> = {
        description: option.label,
        hasICD: true,
        diseaseKey,
      };
      upsertOverlayUserClaim({
        input: {
          customerId,
          externalId: userClaim.diseaseKey,
          externalType: 'HealthRecordUserClaim',
          data: userClaim,
        },
      });
      emitAnalyticsEventNoLimit('New ICD Added', { ...userClaim, customerId, leadId });
    } else {
      const userClaim: Partial<HealthRecordUserClaim> = {
        description: diseaseKey,
        hasICD: false,
      };
      upsertOverlayUserClaim({
        input: {
          customerId,
          externalId: userClaim.description,
          externalType: 'HealthRecordUserClaim',
          data: userClaim,
        },
      });
      emitAnalyticsEventNoLimit('New Non-ICD Added', { ...userClaim, customerId, leadId });
    }
    setPanelClosed();
  };
  const onCancel = () => setPanelClosed();

  return (
    <AddICDPanelContainer>
      <SectionTitle>
        Add Diagnoses
      </SectionTitle>
      <PanelInnerWrapper>
        <SelectInputContainer>
          <SelectInput
            fullWidth
            value={diseaseKey}
            onChange={handleDiagnosisChange}
            options={selectOptions}
            showEmptyOption={false}
            freeSolo
            label="Diagnosis"
          />
        </SelectInputContainer>
      </PanelInnerWrapper>
      <CTA>
        <RemoveItemButton variant="contained" startIcon={<CloseIcon />} onClick={onCancel}>
          Cancel
        </RemoveItemButton>
        <SaveItemButton variant="contained" startIcon={<CheckIcon />} onClick={onSave} disabled={!diseaseKey}>
          Save
        </SaveItemButton>
      </CTA>
    </AddICDPanelContainer>
  );
};
