import React from 'react';
import { CompareRow, CompareDataBold, CompareData } from '../../TRMQuote.elements';

interface CompareCoverageGapInterface {
  currentPlanTier: string[]
  comparisonPlanTier: string[]
}

export const CompareCoverageGap = ({ currentPlanTier, comparisonPlanTier }: CompareCoverageGapInterface) => (
  <CompareRow>
    <CompareDataBold>
      Coverage in the gap
    </CompareDataBold>
    <CompareData empty={currentPlanTier === undefined}>
      <GapTiers tiers={currentPlanTier} />
    </CompareData>
    <CompareData>
      <GapTiers tiers={comparisonPlanTier} />
    </CompareData>
  </CompareRow>
);

const GapTiers = ({ tiers }: {tiers: string[]}) => {
  if (tiers?.length === 0) return <span>None</span>;
  return (
    <div>
      {tiers?.map((tier) => (
        <div>{tier}</div>
      ))}
    </div>
  );
};
