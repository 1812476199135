import * as React from 'react';
import { graphqlClient, internalAuthService } from '../../../services';
import { MuiThemeLight, ThemeLight } from '../../../themes';
import { AuthenticatedPage } from '../../common';
import { FullHeight } from '../../layouts';
import { NonSDROnly } from '../../common/NonSDROnly/NonSDROnly';
import { UserHomeLayout } from '../../layouts/UserHomeLayout';

export const UserHome: React.FunctionComponent = () => (
  <AuthenticatedPage
    title="HIQ CRM | Agent Home"
    theme={ThemeLight}
    muiTheme={MuiThemeLight}
    graphqlClient={graphqlClient}
    internalAuthService={internalAuthService}
  >
    <NonSDROnly>
      <FullHeight>
        <UserHomeLayout />
      </FullHeight>
    </NonSDROnly>
  </AuthenticatedPage>
);
