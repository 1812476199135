import { useFormAnswers } from '../../../../hooks';

export function useConfirmedFinalQuote(): [boolean, () => void] {
  const [answers, onChange] = useFormAnswers();
  const confirmed = answers['lead.confirmedFinalQuote'];
  const handleChange = () => {
    onChange('lead.confirmedFinalQuote', !confirmed);
  };
  return [confirmed, handleChange];
}
