import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const UserCalendarContainer = styled('div')(css`
  height: 100%;
  overflow: scroll;
`);

export const UserCalendarHeader = styled('h2')(css`
  padding: 10px 16px;
  margin: 0;
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
`);

export const UserCalendarContentContainer = styled('div')(css`
  padding: 0 20px;
`);

export const UserCalendarNoFollowUps = styled('p')(css`
  padding: 0 16px;
`);
