import { gql } from 'apollo-boost';
import { COMPARE_RULE_FRAGMENT } from '../compareRule';

export const FORM_FRAGMENT = gql`
  fragment FormFragment on Form {
    title
    description
    referenceId
    leadId
    items {
      type
      label
      questionId
      tags
      visibleCondition {
        ...SimpleCompareRuleFragment
        ...CompositeCompareRuleFragment
      }
      childrenVisibleCondition {
        ...SimpleCompareRuleFragment
        ...CompositeCompareRuleFragment
      }
      internalId
      root
      children
      locked
      formId
      minItems
      maxItems
      helpText
      customElementId
      overrideQuestionText
    }
  }
  ${COMPARE_RULE_FRAGMENT}
`;
