import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';
import { GetUserClaimsQueryData, GetUserClaimsQueryVariables } from './useGetUserClaimsQuery.interfaces';

const GET_USER_CLAIMS_QUERY = gql`
  query GetUserClaims($email: String!, $startDate: Date, $endDate: Date) {
    userClaims(email: $email, startDate: $startDate, endDate: $endDate) {
      description
      claimCode
      clinicalName
      diagnosisDate
    }
  }
`;

export function useGetUserClaimsQuery(email: string) {
  return useLazyQuery<GetUserClaimsQueryData, GetUserClaimsQueryVariables>(GET_USER_CLAIMS_QUERY, {
    variables: {
      email,
    },
  });
}
