import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';
import { FileUploadQueryData, FileUploadQueryVariables } from './useFileUrlQuery.interfaces';

const FILE_URL_QUERY = gql`
  query fileUrl($id: ID!) {
    fileUrl(id: $id) {
      id
      name
      url
    }
  }
`;

export const useFileUrlQuery = () => useLazyQuery<FileUploadQueryData, FileUploadQueryVariables>(FILE_URL_QUERY);
