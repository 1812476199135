import React from 'react';
import { useActiveLeadId } from '../../../routers/LeadRouter/useActiveLeadId';
import { useFormAnswers } from '../../../../hooks';
// import { useDialerService } from '@healthiqeng/core.hooks.use-dialer-service';
// import { SpeedDialName } from '@healthiqeng/core.services.dialer.base';
// import { useLeadQuery } from '../../../../graphql/queries/useLeadQuery';
import { PrimaryButton } from '../../inputs';
import { QueryLoader } from '../../QueryLoader/QueryLoader';
// import { emitAnalyticsEventNoLimit } from '../../../../events/emitAnalyticsEvent';
import { Text } from './CheckMyHealthRecordPlaybackHipaaRecording.elements';
// import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { CheckMyHealthRecordHipaaAnswer } from '../CheckMyHealthRecordHipaaAnswer';
import { useCheckMyHealthRecordConsent } from '../useCheckMyHealthRecordConsent';

const HIPAA_PLAYBACK_KEY = 'lead.startedCMHRHipaaPlayback';

export const CheckMyHealthRecordPlaybackHipaaRecording: React.FunctionComponent = () => {
  const leadId = useActiveLeadId();
  const trackingId = React.useMemo(() => `CheckMyHealthRecordPlaybackHipaaRecording-${Date.now()}`, []);
  const [answers, onChange, persistAnswers] = useFormAnswers(leadId, false, trackingId);
  const { consentGiven } = useCheckMyHealthRecordConsent();
  const pressedHipaaBefore = answers[HIPAA_PLAYBACK_KEY];
  const [playClicked, setPlayClicked] = React.useState<boolean>(pressedHipaaBefore);
  const [playing, setPlaying] = React.useState<boolean>(false);

  const [serviceError] = React.useState<Error>();

  // const { loading, error } = useDialerService();
  // const lead = useLeadQuery();

  const handleInitiateRecording = async () => {
    // try {
    //   const speedDial = SpeedDialName.HipaaCMHR;
    //   await dialerService.initiateConference(speedDial);
    //   emitAnalyticsEventNoLimit('Initiated Hipaa Consent Recording', { campaignName: lead?.data?.campaignName });
    // } catch (err) {
    //   setServiceError(err);
    // }

    onChange(HIPAA_PLAYBACK_KEY, true);
    onChange('lead.cmhrHipaaPlaybackStartTime', new Date().toISOString());
    persistAnswers();
    setPlayClicked(true);
    setPlaying(true);
    setTimeout(() => {
      setPlaying(false);
    }, 2000);
  };

  return (
    <QueryLoader
      loading={false}
      error={serviceError}
      componentName="Five9 Dialer Service CMHR Health Record Playback"
    >
      <PrimaryButton
        onClick={() => handleInitiateRecording()}
        variant="contained"
        disabled={playing}
      >
        Continue
      </PrimaryButton>
      <Text>
        To enable HealthIQ to provide you with its services and to market its services to you,
        you authorize pharmacies, pharmacy benefit managers, and laboratories for four years,
        to disclose your health information, including information on mental illness, alcohol,
        and drug use, HIV, AIDS, and sexually transmitted diseases to HealthIQ and its vendors.
        Your data could be redisclosed only if law permits and may no longer be protected under
        federal privacy rules.
        To revoke this authorization, send written notice to complianceteam@healthiq.com.
        You understand that agreeing to this authorization is voluntary.
        Your treatment, payment, enrollment in a health plan, or eligibility for benefits are
        not dependent on your authorization of this disclosure.
      </Text>
      {(playClicked || consentGiven) && <CheckMyHealthRecordHipaaAnswer />}
    </QueryLoader>
  );
};
