import { CellProps, Renderer } from 'react-table';
import React, { ComponentType } from 'react';

export function memoize<D extends Object>(renderer: Renderer<CellProps<D>>, ...attrs: (keyof D)[]) {
  return React.memo(renderer as ComponentType<CellProps<D>>, (prevProps, nextProps) => {
    const a = prevProps.row.original;
    const b = nextProps.row.original;
    return attrs.every((attr) => a[attr] === b[attr]);
  });
}
