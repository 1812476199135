import * as React from 'react';
import { TabTopLayoutProps, TabTopLayoutTabItem } from './TabTopLayout.interfaces';
import {
  TabTopLayoutContainer,
  TabRowContainer,
  ContentRowContainer,
  ContentContainer,
  StyledTabs,
  StyledTabsContainer,
  ActionsContainer,
} from './TabTopLayout.elements';
import { noop } from '../../../util/noop';
import { TabTopLayoutTab } from './TabTopLayoutTab';
import { SendPDPSelfEnroll } from '../../common';

export const TabTopLayout: React.FunctionComponent<TabTopLayoutProps> = ({
  activeId,
  onTabChange,
  tabs,
}) => (
  <TabTopLayoutContainer>
    <TabRowContainer>
      <StyledTabsContainer data-qa="top-layout-tabs">
        <StyledTabs
          value={getActiveIndex(tabs, activeId)}
          onChange={noop}
          scrollButtons="auto"
          variant="scrollable"
        >
          { tabs.map(({ id, label }) => (
            <TabTopLayoutTab
              key={id}
              id={id}
              label={label}
              onClick={onTabChange}
            />
          )) }
        </StyledTabs>
      </StyledTabsContainer>
      <ActionsContainer>
        <SendPDPSelfEnroll />
      </ActionsContainer>
    </TabRowContainer>
    <ContentRowContainer>
      { tabs.map(({ id, content }) => (
        <ContentContainer
          key={id}
          role="tabpanel"
          hidden={activeId !== id}
        >
          { activeId === id && content }
        </ContentContainer>
      )) }
    </ContentRowContainer>
  </TabTopLayoutContainer>
);

function getActiveIndex(tabs: TabTopLayoutTabItem[], activeId: string): number {
  const index = tabs.map((tab) => tab.id).indexOf(activeId);
  return Math.max(index, 0);
}
