import { useState } from 'react';
import { useDebounce } from '@healthiqeng/core.hooks.use-debounce';
import { useCustomerSearchQuery, CustomerFragment } from '../../../../../graphql';

export type UseCustomerSearchResult = [
  CustomerFragment[],
  boolean,
  string,
  (term: string) => void,
  () => void,
];

export const useCustomerSearch = (debounceDelay: number = 750): UseCustomerSearchResult => {
  const [searchTerm, setSearchTerm] = useState('');
  const [search, result] = useCustomerSearchQuery();
  const searchImmediately = (term?: string) => search(term ?? searchTerm);
  const searchDebounced = useDebounce(searchImmediately, debounceDelay);

  const setSearchTermAndSearch = (term: string) => {
    setSearchTerm(term);
    searchDebounced(term);
  };

  return [
    result?.data?.search ?? [],
    result?.loading,
    searchTerm,
    setSearchTermAndSearch,
    searchImmediately,
  ];
};
