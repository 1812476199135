import * as React from 'react';
import {
  useCustomerQuery,
} from '../../../../graphql';
import {
  ActivitiesQueryVariables,
  SmsQueryData,
  useSmsQuery,
} from '../../../../graphql/queries/useActivitiesQuery';
import { FetchMoreOptions } from 'apollo-boost';

import { ACTIVITIES_LIMIT, Activity, groupActivities } from '../../ActivityFeed/Activities/Activities';

export const Sms: React.FunctionComponent = () => {
  const { loading: customerLoading, error: customerError, data: customerData } = useCustomerQuery();
  const { activityFeedId, id: customerId } = customerData?.customer ?? {};
  const [fetchSmsHistory, {
    data, error, loading, fetchMore,
  }] = useSmsQuery();
  const [isFetchMoreVisible, setIsFetchMoreVisible] = React.useState(true);

  React.useEffect(() => {
    if (activityFeedId) {
      fetchSmsHistory({
        variables: {
          customerId,
          activityFeedId,
          offset: 0,
          limit: ACTIVITIES_LIMIT,
          pullTasks: true,
        },
      });
    }
  }, [customerId, activityFeedId]);

  React.useEffect(() => {
    handleFetchMoreVisibility();
  }, [data?.sms?.length]);

  const handleFetchMoreVisibility = () => {
    if (data?.sms?.length < ACTIVITIES_LIMIT) {
      setIsFetchMoreVisible(false);
    } else if (data?.sms?.length === ACTIVITIES_LIMIT) {
      setIsFetchMoreVisible(true);
    }
  };

  const onFetchMoreHandler = React.useCallback(async () => {
    fetchMore({
      variables: {
        offset: data?.sms?.length,
        pullTasks: false,
      },
      updateQuery: (previousData, { fetchMoreResult }) => {
        if (!fetchMoreResult?.sms?.length || fetchMoreResult?.sms?.length < ACTIVITIES_LIMIT) {
          setIsFetchMoreVisible(false);
        }
        return ({
          sms: [
            ...(previousData?.sms ?? []),
            ...(fetchMoreResult?.sms ?? []),
          ],
        });
      },
    } as FetchMoreOptions<SmsQueryData, ActivitiesQueryVariables>);
  }, [fetchMore, data?.sms?.length]);

  const { sms } = data ?? {};
  const groupedActivities = React.useMemo(
    () => groupActivities(sms ?? []),
    [sms],
  );
  return (
    <Activity
      groupedActivities={groupedActivities}
      customerLoading={customerLoading}
      loading={loading}
      customerError={customerError}
      error={error}
      isFetchMoreVisible={isFetchMoreVisible}
      onFetchMoreHandler={onFetchMoreHandler}
    />
  );
};
