import { useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import {
  AllActivitiesQueryData,
  ActivitiesQueryVariables,
  MyActivitiesQueryData,
  EmailsQueryData, SmsQueryData, MySmsQueryData, MyEmailsQueryData,
} from './useActivitiesQuery.interfaces';
import { LEAN_ACTIVITY_FRAGMENT } from '../../fragments';

export const ACTIVITIES_QUERY = gql`
    query getActivities($customerId: ID!, $activityFeedId: ID!, $offset: Int, $limit: Int, $pullTasks: Boolean) {
      activities(customerId: $customerId, activityFeedId: $activityFeedId, offset: $offset, limit: $limit, pullTasks: $pullTasks) {
        ... LeanActivityFragment
      }
    }
    ${LEAN_ACTIVITY_FRAGMENT}
`;

export const MY_ACTIVITIES_QUERY = gql`
    query getMyActivities($customerId: ID!, $activityFeedId: ID!, $offset: Int, $limit: Int, $pullTasks: Boolean) {
      myActivities(customerId: $customerId, activityFeedId: $activityFeedId, offset: $offset, limit: $limit, pullTasks: $pullTasks) {
        ... LeanActivityFragment
      }
    }
    ${LEAN_ACTIVITY_FRAGMENT}
`;

export const EMAILS_QUERY = gql`
    query getEmails($customerId: ID!, $activityFeedId: ID!, $offset: Int, $limit: Int, $pullTasks: Boolean) {
        emails(customerId: $customerId, activityFeedId: $activityFeedId, offset: $offset, limit: $limit, pullTasks: $pullTasks) {
            ... LeanActivityFragment
        }
    }
    ${LEAN_ACTIVITY_FRAGMENT}
`;

export const MY_EMAILS_QUERY = gql`
    query getMyEmails($customerId: ID!, $activityFeedId: ID!, $offset: Int, $limit: Int, $pullTasks: Boolean) {
        myEmails(customerId: $customerId, activityFeedId: $activityFeedId, offset: $offset, limit: $limit, pullTasks: $pullTasks) {
            ... LeanActivityFragment
        }
    }
    ${LEAN_ACTIVITY_FRAGMENT}
`;

export const SMS_QUERY = gql`
    query getSms($customerId: ID!, $activityFeedId: ID!, $offset: Int, $limit: Int, $pullTasks: Boolean) {
        sms(customerId: $customerId, activityFeedId: $activityFeedId, offset: $offset, limit: $limit, pullTasks: $pullTasks) {
            ... LeanActivityFragment
        }
    }
    ${LEAN_ACTIVITY_FRAGMENT}
`;

export const MY_SMS_QUERY = gql`
    query getMySms($customerId: ID!, $activityFeedId: ID!, $offset: Int, $limit: Int, $pullTasks: Boolean) {
        mySms(customerId: $customerId, activityFeedId: $activityFeedId, offset: $offset, limit: $limit, pullTasks: $pullTasks) {
            ... LeanActivityFragment
        }
    }
    ${LEAN_ACTIVITY_FRAGMENT}
`;

export function useActivitiesQuery() {
  return useLazyQuery<AllActivitiesQueryData, ActivitiesQueryVariables>(ACTIVITIES_QUERY);
}

export function useMyActivitiesQuery() {
  return useLazyQuery<MyActivitiesQueryData, ActivitiesQueryVariables>(MY_ACTIVITIES_QUERY);
}

export function useEmailQuery() {
  return useLazyQuery<EmailsQueryData, ActivitiesQueryVariables>(EMAILS_QUERY);
}

export function useMyEmailsQuery() {
  return useLazyQuery<MyEmailsQueryData, ActivitiesQueryVariables>(MY_EMAILS_QUERY);
}

export function useSmsQuery() {
  return useLazyQuery<SmsQueryData, ActivitiesQueryVariables>(SMS_QUERY);
}

export function useMySmsQuery() {
  return useLazyQuery<MySmsQueryData, ActivitiesQueryVariables>(MY_SMS_QUERY);
}
