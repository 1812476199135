import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../../themes';

export const FollowUpSchedulerContainer = styled('div')(css``);

export const FollowUpSchedulerHeader = styled('p')(css`
  font-weight: bold;
`);

export const FollowUpInputContainer = styled('div')(css`
  margin-top: 10px;
`);

export const FollowUpListHeader = styled('h3')(css``);

export const FollowUpNoTimeAvailableHeader = styled('h3')<ComponentWithThemeProps>(({ theme }) => css`
  color: ${theme.color.error};
`);
