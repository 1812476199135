import * as React from 'react';

export function useEffectOnce(effect: () => void, staticDeps: any[], dynamicDeps?: any[]) {
  const [hasRan, setHasRan] = React.useState(false);

  // Only runs once if all staticDeps are not either null or undefined
  React.useEffect(() => {
    if (hasRan) return;

    if (staticDeps.every((value) => value !== null && value !== undefined)) {
      effect();
      setHasRan(true);
    }
  }, [...staticDeps, setHasRan]);

  // Reruns the callback function whenever a dynamic property changes
  React.useEffect(() => {
    if (!dynamicDeps || dynamicDeps.length === 0) return;

    setHasRan(false);
  }, [dynamicDeps]);
}
