import * as React from 'react';
import { TabTopLayoutTabProps } from './TabTopLayoutTab.interfaces';
import { StyledTab } from './TabTopLayoutTab.elements';

export const TabTopLayoutTab: React.FunctionComponent<TabTopLayoutTabProps> = ({
  id,
  label,
  onClick,
}) => {
  const handleClick = () => {
    onClick(id);
  };
  return (
    <StyledTab
      key={id}
      onClick={handleClick}
      label={label}
      wrapped
      data-qa={`tab-top-layout-tab-${id}`}
    />
  );
};
