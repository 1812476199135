import React, { FC } from 'react';
import {
  QuotePresentationType,
  PropertyAndCasualtyBaseQuoteCreateProperties,
  QuestionType,
  BillingFrequency, TweakTypeEnum,
} from '@hiq/crm.types';
import { useLogClick } from '../../../../../graphql/mutations/useLogUserInteractionMutation';
import { isPresent } from '@healthiqeng/core.util.is-present';
import { QueryLoader } from '../../../QueryLoader';
import { QuoteOptionsLayout } from '../../QuoteOptionsLayout';
import { QuoteOption } from '../../QuoteOptions/QuoteOption';
import { PrimaryButton } from '../../../inputs/PrimaryButton';
import { PropertyAndCasualtyPolicyTermLengthOptions, BillingFrequencyOptions } from '../../../../../constants';
import { PNCBaseExternalQuoteProps } from './PNCBaseExternalQuote.interfaces';
import { ExternalQuoteState, useExternalPropertyAndCasualtyQuote } from '../useExternalQuote';
import { ResultLabel } from './PNCBaseExternalQuote.elements';
import { useFormAnswers } from '../../../../../hooks';
import { emitAnalyticsEvent } from '../../../../../events/emitAnalyticsEvent/emitAnalyticsEvent';

export const PNCBaseExternalQuote: FC<PNCBaseExternalQuoteProps> = ({
  productType,
  isUpdate,
  hasSpecialRate,
}) => {
  const {
    quoteInput,
    setQuoteInput,
    carrierOptions,
    submitQuote,
    loading,
    error,
    addQuoteData,
  } = useExternalPropertyAndCasualtyQuote<PropertyAndCasualtyBaseQuoteCreateProperties>({
    type: productType,
    presentationType: QuotePresentationType.Selected,
    rate: {
      monthly: null,
      quarterly: null,
      semiAnnually: null,
      annually: null,
      policyFee: 0,
    },
    effectiveDate: null,
    billingFrequency: null,
    policyTermLength: null,
    tweakType: null,
  });
  const [logClick] = useLogClick();
  const [, onChange] = useFormAnswers();
  const onAddQuoteButtonClick = React.useCallback(() => {
    logClick({ control: 'Add Quote', detail: 'PNC quote' });
    emitAnalyticsEvent('Add Quote', { detail: 'PNC quote' });
    if (isUpdate) onChange('lead.confirmedFinalQuote', true);
    submitQuote();
  }, [logClick, submitQuote]);
  const canSubmit = [
    quoteInput.carrierId,
    quoteInput.effectiveDate,
    quoteInput.billingFrequency,
    quoteInput.policyTermLength,
    !hasSpecialRate || quoteInput.tweakType,
  ].every(isPresent) && !!(
    quoteInput.rate.monthly
    || quoteInput.rate.quarterly
    || quoteInput.rate.semiAnnually
    || quoteInput.rate.annually
  );
  return (
    <QueryLoader loading={loading} error={error} componentName="PNCBaseExternalQuote">
      <QuoteOptionsLayout
        leftContent={(
          <>
            <QuoteOption
              id="carrierId"
              value={quoteInput.carrierId ?? ''}
              handleChange={setQuoteInput}
              label="Carrier"
              type={QuestionType.Select}
              options={carrierOptions || []}
            />
            <QuoteOption
              id="policyTermLength"
              value={quoteInput.policyTermLength ?? ''}
              handleChange={setQuoteInput}
              label="Policy Term Length"
              type={QuestionType.Select}
              options={PropertyAndCasualtyPolicyTermLengthOptions}
            />
            { hasSpecialRate && (
              <QuoteOption
                id="tweakType"
                label="Tweak Type"
                value={quoteInput.tweakType}
                type={QuestionType.Radio}
                handleChange={setQuoteInput}
                options={[
                  { value: TweakTypeEnum.Retail, label: 'Retail' },
                  { value: TweakTypeEnum.V1, label: 'Special Rate' },
                ]}
              />
            ) }
          </>
        )}
        rightContent={(
          <>
            <QuoteOption
              id="effectiveDate"
              value={quoteInput.effectiveDate}
              handleChange={setQuoteInput}
              label="Effective Date"
              type={QuestionType.Date}
            />
            <QuoteOption
              id="billingFrequency"
              label="Payment Plan"
              value={quoteInput.billingFrequency}
              type={QuestionType.Select}
              handleChange={setQuoteInput}
              options={BillingFrequencyOptions}
            />
            <QuotePremium
              quoteInput={quoteInput}
              setQuoteInput={setQuoteInput}
            />
            <PrimaryButton
              onClick={onAddQuoteButtonClick}
              disabled={!canSubmit}
              variant="contained"
            >
              { getButtonText(loading, isUpdate) }
            </PrimaryButton>
            { error && <ResultLabel type="error">{ error.message }</ResultLabel> }
            { addQuoteData && <ResultLabel type="success">Success</ResultLabel> }
          </>
        )}
        minWidth={200}
        gutter={20}
      />
    </QueryLoader>
  );
};

interface QuoteInputProps {
  quoteInput: ExternalQuoteState<any>;
  setQuoteInput: (key: string, value: string) => void;
}

function QuotePremium({ quoteInput, setQuoteInput }: QuoteInputProps) {
  const { billingFrequency } = quoteInput;

  if (billingFrequency === BillingFrequency.Quarterly) {
    return (
      <QuoteOption
        id="rate.quarterly"
        label="Quarterly Premium"
        value={quoteInput.rate.quarterly}
        type={QuestionType.Currency}
        handleChange={setQuoteInput}
      />
    );
  }
  if (billingFrequency === BillingFrequency.SemiAnnually) {
    return (
      <QuoteOption
        id="rate.semiAnnually"
        label="Semi-Annual Premium"
        value={quoteInput.rate.semiAnnually}
        type={QuestionType.Currency}
        handleChange={setQuoteInput}
      />
    );
  }
  if (billingFrequency === BillingFrequency.Annually) {
    return (
      <QuoteOption
        id="rate.annually"
        label="Yearly Premium"
        value={quoteInput.rate.annually}
        type={QuestionType.Currency}
        handleChange={setQuoteInput}
      />
    );
  }
  return (
    <QuoteOption
      id="rate.monthly"
      label="Monthly Premium"
      value={quoteInput.rate.monthly}
      type={QuestionType.Currency}
      handleChange={setQuoteInput}
    />
  );
}

function getButtonText(loading: boolean, isUpdate: boolean): string {
  if (loading) return 'Submitting...';
  return isUpdate ? 'Update Quote' : 'Add Quote';
}
