import * as React from 'react';
import { MedicareExternalQuoteProps } from './MedicareExternalQuote.interfaces';
import { ExternalQuoteFactory } from '../../../../../../common/quoting/ExternalQuoteFactory';
import { useLeadQuery } from '../../../../../../../graphql';
import { QueryLoader } from '../../../../../../common/QueryLoader';
import { Card } from '../../../../../../common/Card';
import { getProductType } from './MedicareExternalQuote.util';

export const MedicareExternalQuote: React.FunctionComponent<MedicareExternalQuoteProps> = () => {
  const { data, loading, error } = useLeadQuery();

  return (
    <Card>
      <QueryLoader loading={loading} error={error} componentName="MedicareExternalQuote">
        <ExternalQuoteFactory type={getProductType(data)} />
      </QueryLoader>
    </Card>
  );
};
