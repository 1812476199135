import { useLazyQuery } from '@apollo/react-hooks';
import { CustomerQueryData, CustomerQueryVariables } from './useCustomerQuery.interfaces';
import { CUSTOMER_QUERY } from './useCustomerQuery';

export function useLazyCustomerQuery(customerId?: string) {
  const result = useLazyQuery<CustomerQueryData, CustomerQueryVariables>(CUSTOMER_QUERY, {
    variables: {
      customerId,
    },
  });
  return result;
}
