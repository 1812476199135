import { isPresent } from '@healthiqeng/core.util.is-present';
import { InputTransformer } from '../InputTransformer';

export class RoutingNumberTransformer extends InputTransformer<string> {
  constructor(initialValue: string) {
    super(initialValue);
    this.draftValue = RoutingNumberTransformer.normalizeRoutingNumberString(`${initialValue}`);
  }

  public update(nextValue: string) {
    this.updateDraftValue(nextValue);
    this.updatePersistedValue();
  }

  public getDraftValue() {
    if (!isPresent(this.draftValue)) {
      return '';
    }

    return this.draftValue;
  }

  public getPersistedValue() {
    return this.persistedValue;
  }

  public getFormattedValue() {
    return this.getPersistedValue();
  }

  protected updateDraftValue(value: string) {
    this.draftValue = RoutingNumberTransformer.normalizeRoutingNumberString(value);
  }

  protected updatePersistedValue() {
    if (!isPresent(this.draftValue)) {
      this.persistedValue = null;
      return;
    }

    if (this.draftValue.includes('.')) {
      this.persistedValue = this.draftValue;
      return;
    }
    this.persistedValue = this.draftValue;
  }

  public static normalizeRoutingNumberString(value: string): string {
    if (!isPresent(value)) {
      return null;
    }
    let cleanedValue = value
      .replace(/[^0-9.]/g, '')
      .replace(/\.+/g, '.')
      .substring(0, 9);

    if ((cleanedValue.match(/\./g) ?? []).length > 1) {
      cleanedValue = cleanedValue.replace(/\./, '_')
        .replace(/\./g, '')
        .replace(/_/, '.');
    }

    return cleanedValue;
  }
}
