import { gql } from 'apollo-boost';
import { MutationCustomReturn, useMutationCustom } from '../useMutationCustom';
import { CUSTOMER_FRAGMENT } from '../../fragments';
import {
  ReleaseToFollowUpMutationResponse,
  ReleaseToFollowUpMutationVariables,
} from './useReleaseToFollowUpMutation.interfaces';

const RELEASE_TO_FOLLOW_UP_MUTATION = gql`
  mutation ReleaseToFollowUp($leadId: ID!) {
    releaseToFollowUp(leadId: $leadId) {
      code
      success
      message
      timestamp
      customer {
        ...CustomerFragment
      }
    }
  }
  ${CUSTOMER_FRAGMENT}
`;

type ReleaseToFollowUpReturnType = MutationCustomReturn<ReleaseToFollowUpMutationResponse, ReleaseToFollowUpMutationVariables>;

export function useReleaseToFollowUpMutation(): ReleaseToFollowUpReturnType {
  return useMutationCustom<
  ReleaseToFollowUpMutationResponse,
  ReleaseToFollowUpMutationVariables>(RELEASE_TO_FOLLOW_UP_MUTATION);
}
