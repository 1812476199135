import { AnalyticsEvent } from '@healthiqeng/core.services.fe-analytics';
import { getEventBus } from '../getEventBus/getEventBus';
import { config } from '../../config';

export function emitAnalyticsEvent(message:string, props?: Record<string, any>): void {
  if (config.AMPLITUDE.ENABLED) {
    getEventBus().emit(new AnalyticsEvent(message, props));
  }
}

export function emitAnalyticsEventNoLimit(message:string, props?: Record<string, any>): void {
  emitAnalyticsEvent(message, { ...props, overrideRateLimit: true });
}
