import * as React from 'react';
import { MissingFieldsWarning } from '../../MissingFieldsWarning';
import { Container, LockedMessage } from './LockedQuestionFlow.elements';

export const LockedQuestionFlow: React.FunctionComponent<{ hidden: boolean, invalidFields: string[] }> = ({
  hidden,
  invalidFields,
}) => {
  if (hidden) return null;
  return (
    <Container style={{ display: hidden ? 'none' : 'block' }}>
      <LockedMessage>
        Go back and answer required question(s).  See dialog below.
      </LockedMessage>
      <MissingFieldsWarning isOpen invalidFields={invalidFields} />
    </Container>
  );
};
