import { CrossSaleCreateSuccessEvent } from '../../graphql';
import { CrossSaleCustomer } from '@hiq/crm.types';

export function onCrossSaleSuccess(routeToLead: (id: string) => void) {
  return (event: CrossSaleCreateSuccessEvent) => {
    if (event.customerType !== CrossSaleCustomer.Current) {
      const { host } = window.location;
      const path = `customer/${event.customerId}`;
      const { protocol } = window.location;
      const url = `${protocol}//${host}/${path}`;
      window.open(url, '_blank');
    }
    if (event.customerType === CrossSaleCustomer.Current) {
      routeToLead(event.leadId);
    }
  };
}
