import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';
import { GetAddressFromZipQueryData, GetAddressFromZipQueryVariables } from './useGetAddressFromZipQuery.interface';

const GET_ADRESS_FROM_ZIP_QUERY = gql`
  query GetAddressFromZipQuery($zip: String!) {
    getAddress(zip: $zip) {
      complete
      address {
        city
        county
        state
      }
    }
  }
`;

export function useGetAddressFromZipQuery() {
  return useLazyQuery<GetAddressFromZipQueryData, GetAddressFromZipQueryVariables>(GET_ADRESS_FROM_ZIP_QUERY);
}
