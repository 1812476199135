import React, { useState } from 'react';
import { AddPanelProps } from './AddAnticipatedNeedPanel.interfaces';
import { useUpsertOverlayMutation } from '../../../../../../graphql';
import { useActiveLeadId, useCustomerId } from '../../../../../routers';
import { emitAnalyticsEventNoLimit } from '../../../../../../events/emitAnalyticsEvent';
import {
  AddAnticipatedNeedsPanelContainer, SelectInputContainer, QuantityInputContainer, Row,
} from './AddAnticipatedNeedsPanel.elements';
import {
  Bold,
  CTA, PanelInnerWrapper, RemoveItemButton, SaveItemButton, SectionTitle,
} from '../../CheckMyHealthRecordDataGrid.elements';
import { numberTransformerFactory, SelectInput, TextInput } from '../../../../inputs';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { FutureBenefitOption, HealthRecordAnticipatedNeed, QuestionOption } from '@hiq/crm.types';
import { getEventBus, SunfireV2DataChangeEvent } from '../../../../../../events';

export const AddAnticipatedNeedPanel = ({ setPanelClosed, futureBenefits }: AddPanelProps) => {
  const [upsertOverlayAnticipatedNeed] = useUpsertOverlayMutation();
  const eventBus = getEventBus();
  const customerId = useCustomerId();
  const leadId = useActiveLeadId();
  const [anticipatedNeedKey, setAnticipatedNeedKey] = useState<string>();
  const [selectedAnticipatedNeed, setSelectedAnticipatedNeed] = useState<FutureBenefitOption>();
  const [quantity, setQuantity] = useState<number>();
  const selectOptions: QuestionOption[] = React.useMemo(
    () => futureBenefits?.map(
      (futureOption) => ({ label: futureOption.hiqDescription, value: futureOption.code }),
    ) || [],
    [futureBenefits],
  );
  const handleAnticipatedNeedKey = React.useCallback((anticipatedNeedKey?: string) => {
    setAnticipatedNeedKey(anticipatedNeedKey);
    const selectedOption = futureBenefits.find((option) => option.code === anticipatedNeedKey);
    if (selectedOption) {
      setSelectedAnticipatedNeed(selectedOption);
      setQuantity(selectedOption?.quantityDefault);
    }
  }, [futureBenefits]);

  const onSave = React.useCallback(() => {
    const anticipatedNeed: Partial<HealthRecordAnticipatedNeed> = {
      code: selectedAnticipatedNeed.code,
      codeType: selectedAnticipatedNeed.codeType,
      codeSubtype: selectedAnticipatedNeed.codeSubtype,
      hiqDescription: selectedAnticipatedNeed.hiqDescription,
      quantity,
      quantityApplicable: selectedAnticipatedNeed.quantityApplicable,
    };
    upsertOverlayAnticipatedNeed({
      input: {
        customerId,
        externalId: anticipatedNeed.code,
        externalType: 'HealthRecordAnticipatedNeed',
        data: anticipatedNeed,
      },
    });
    eventBus.emit(new SunfireV2DataChangeEvent());
    emitAnalyticsEventNoLimit('New AnticipatedNeed Added', { ...anticipatedNeed, customerId, leadId });
    setPanelClosed();
  }, [anticipatedNeedKey, quantity, selectedAnticipatedNeed]);
  const onCancel = () => setPanelClosed();

  return (
    <AddAnticipatedNeedsPanelContainer>
      <SectionTitle>
        Add Anticipated Need
      </SectionTitle>
      <PanelInnerWrapper>
        <Bold>
          What does this Client anticipate needing in the next 12 months?
        </Bold>
        <Row>
          <SelectInputContainer>
            <SelectInput
              fullWidth
              value={anticipatedNeedKey}
              onChange={handleAnticipatedNeedKey}
              options={selectOptions}
              showEmptyOption={false}
            />
          </SelectInputContainer>
          {selectedAnticipatedNeed && selectedAnticipatedNeed.quantityApplicable && (
          <Row>
            <Bold>Quantity &nbsp;</Bold>
            <QuantityInputContainer>
              <TextInput
                value={selectedAnticipatedNeed?.quantityDefault}
                onChange={(quantity) => setQuantity(Number(quantity))}
                transformerFactory={numberTransformerFactory}
                size="small"
              />
            </QuantityInputContainer>
          </Row>
          )}

        </Row>
      </PanelInnerWrapper>
      <CTA>
        <RemoveItemButton variant="contained" startIcon={<CloseIcon />} onClick={onCancel}>
          Cancel
        </RemoveItemButton>
        <SaveItemButton variant="contained" startIcon={<CheckIcon />} onClick={onSave} disabled={!anticipatedNeedKey}>
          Save
        </SaveItemButton>
      </CTA>
    </AddAnticipatedNeedsPanelContainer>
  );
};
