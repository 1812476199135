import { LeadFragment } from '../../../../../../../graphql';
import { MedicareAdvantagePlanCategory, ProductTypeMedicare } from '@hiq/crm.types';

export function getProductType(data: LeadFragment): ProductTypeMedicare {
  const isMaPlanCategoryPdp = data?.questionFlowAnswers?.medicareAdvantagePlanCategory === MedicareAdvantagePlanCategory.PDP;
  const isProductypeMaorPdp = data?.productType === ProductTypeMedicare.MedicareAdvantage
    || data?.productType === ProductTypeMedicare.PrescriptionDrug;
  if (isProductypeMaorPdp && isMaPlanCategoryPdp) {
    return ProductTypeMedicare.PrescriptionDrug;
  }
  return data?.productType as ProductTypeMedicare;
}
