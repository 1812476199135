import { useCurrentUserQuery } from '../../../../../graphql/queries/useCurrentUserQuery';
import { useLeadQuery } from '../../../../../graphql/queries/useLeadQuery';
import {
  IndustryType,
  UserProfile,
} from '@hiq/crm.types';

export const useCampaign = () => {
  const { data: userData } = useCurrentUserQuery();
  const { data: leadData } = useLeadQuery();

  const leadIndustryType = leadData?.industry?.type;
  const userProfileType = userData?.currentUser?.profile;
  let campaign = 'NULL';

  if (leadIndustryType === IndustryType.Life) campaign = 'life';
  if (leadIndustryType === IndustryType.PropertyAndCasualty) campaign = 'pnc';
  if (leadIndustryType === IndustryType.Medicare) campaign = 'medicare';
  if (userProfileType === UserProfile.RetentionSpecialist) campaign = 'retention';
  if (userProfileType === UserProfile.SalesDevelopmentRepPM) campaign = 'presales';

  const utmCampaign = `verbal_auth_${campaign}`;

  return utmCampaign;
};
