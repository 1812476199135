import { encodeBase64Url } from '@healthiqeng/core.util.base64-url';

import { config } from '../../config';
import { internalAuthService } from '../../services';

export const logout = async () => {
  try {
    internalAuthService.logout();
    redirect();
  } catch (e) {
    console.error('Unable to logout', e);
  }
};

const redirect = () => {
  const currentUrl = window.location.origin;
  const oauthState = encodeBase64Url(currentUrl);
  const scope = ['email', 'openid'].join('+');
  const logoutUrl = `https://${config.AWS.COGNITO.DOMAIN}.auth.${config.AWS.COGNITO.REGION}.amazoncognito.com/logout`;
  const queryParams = [
    `state=${oauthState}`,
    `client_id=${config.AWS.COGNITO.CLIENT_ID}`,
    'response_type=code',
    `scope=${scope}`,
    `redirect_uri=${config.AWS.COGNITO.REDIRECT_URI}`,
  ].join('&');
  window.location.href = `${logoutUrl}?${queryParams}`;
};
