import * as React from 'react';
import { Disposition } from '@healthiqeng/core.services.dialer.base';

interface DialerTransferContextInterface {
  dispositions: Disposition[];
  fetchDispositions: (campaignId?: string) => void;
  loadingDispositions: boolean;
  transferError: boolean;
  setTransferError: (val: boolean) => void;
  transferInProgress: boolean;
  setTransferInProgress: (val: boolean) => void;
  isWaitingForNextCall: boolean;
  waitForNextCall: () => void;
  campaignId: string;
  setCampaignId: (val: string) => void;
  selectedDisposition: Disposition;
  setSelectedDisposition: (val: Disposition) => void;
}

export const DialerTransferContext = React.createContext<DialerTransferContextInterface>({} as DialerTransferContextInterface);
