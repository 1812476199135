import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../../../../themes';

export const ArrayFormHeader = styled('h3')(css``);

export const ArrayItemContainer = styled('div')<ComponentWithThemeProps & { isLast: boolean }>(({ theme, isLast }) => css`
  border: 1px solid ${theme.color.grayLight};
  border-radius: 4px;
  padding: 5px 15px;
  margin-bottom: ${isLast ? '0' : '15px'};
`);

export const ArrayItemHeaderContainer = styled('div')(css`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
`);

export const ArrayItemHeaderItem = styled('div')(css`
  display: flex;
`);

export const AddAnotherContainer = styled('div')(css`
  display: flex;
  justify-content: flex-end;
`);
