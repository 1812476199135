import { ApolloError } from 'apollo-boost';
import { useCallback } from 'react';
import { useSubmitEappMutation, LeadFragment } from '../../../../../graphql';

export const useCsgApplicationSubmit = (leadData: LeadFragment): [Function, string | undefined, boolean, ApolloError] => {
  const [submitEappMutation, result] = useSubmitEappMutation();

  const submit = useCallback(() => submitEappMutation({ leadId: leadData.id }), [leadData]);

  const id = result?.data?.submitEapp.csgEappReference.id;

  const loading = result?.loading;

  return [submit, id, loading, result?.error];
};
