import { gql } from 'apollo-boost';
import { PRODUCT_FRAGMENT } from '../product';

export const QUOTE_FRAGMENT = gql`
  fragment QuoteFragment on Quote {
    hash
    presentationType
    riders {
      id
      name
      cost {
        monthly {
          value
        }
        annually{
          value
        }
        quarterly {
          value
        }
        semiAnnually {
          value
        }
      }
    }
    discounts {
      id
      name
      percent
    }
    product {
      ...ProductFragment
    }
    premium {
      monthly {
        value
        formatted
      }
      quarterly {
        value
        formatted
      }
      annually {
        value
        formatted
      }
      semiAnnually {
        value
        formatted
      }
      policyFee {
        value
        formatted
      }
    }
    quoteDate
    ... on MedicareSupplementQuote {
      effectiveDate
      householdDiscount
      ratingClass
      plan
      isSelectPlan
      rateType
      eAppLink
    }
    ... on MedicareAdvantageQuote {
      effectiveDate
      planType
      planCategory
    }
    ... on MedicareVisionQuote {
      effectiveDate
      planName
    }
    ... on MedicareDentalQuote {
      effectiveDate
      planName
    }
    ... on MedicarePrescriptionDrugPlanQuote {
      effectiveDate
      planName
    }
    ... on MedicareHospitalIndemnityQuote {
      effectiveDate
      planName
    }

    ... on PropertyAndCasualtyBaseQuote {
      effectiveDate
      billingFrequency
      policyTermLength
      carrierLink
    }
    ... on PropertyAndCasualtyAutoQuote {
      effectiveDate
      billingFrequency
      policyTermLength
      carrierLink
    }
    ... on LifeQuote {
      policyTermLength
      underwritingType
      underwritingClass
      underwritingClassGeneric
      faceAmount {
        value
        formatted
      }
      eAppLink
    }
  }
  ${PRODUCT_FRAGMENT}
`;
