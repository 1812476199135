import { useState, useCallback, useEffect } from 'react';
import { useSubsectionRoute } from '../../../../routers/useSubsectionRoute';
import { getEventBus } from '../../../../../events/getEventBus';
import { PanelChangeEvent } from '../../../../../events';

export const useSectionNavigation = (anchors: string[] = [], afterLast: () => void): [number, () => void, () => void] => {
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const subsection = useSubsectionRoute();
  const eventBus = getEventBus();

  useEffect(() => {
    const anchor = anchors.indexOf(subsection);
    if (anchor > -1) setCurrentSectionIndex(anchor);
  }, [subsection, anchors.join()]);

  const changeSectionHash = (index: number) => {
    const anchor = anchors[index];
    window.location.hash = `#${anchor}`;
  };

  const moveSectionForward = useCallback(() => {
    const len = anchors.length;
    // If we're on the last section go to quoting
    if (currentSectionIndex === len - 1) afterLast();
    else {
      const next = currentSectionIndex + 1;
      setCurrentSectionIndex(next);
      changeSectionHash(next);
      eventBus.emit(new PanelChangeEvent());
    }
  }, [currentSectionIndex, anchors.join()]);

  const moveSectionBackwards = useCallback(() => {
    if (currentSectionIndex > 0) {
      const previous = currentSectionIndex - 1;
      setCurrentSectionIndex(previous);
      changeSectionHash(previous);
      eventBus.emit(new PanelChangeEvent());
    }
  }, [currentSectionIndex]);

  return [
    currentSectionIndex,
    moveSectionBackwards,
    moveSectionForward,
  ];
};
