import React from 'react';
import { useLocation } from '@reach/router';
import { CallTimeLimitAlertProps } from './CallTimeLimitAlert.interfaces';
import { AlertDialog } from '../AlertDialog';

export const CallTimeLimitAlert = ({ callTimeInSeconds }: CallTimeLimitAlertProps) => {
  const [open, setOpen] = React.useState(false);
  const [isCoolingDown, setCoolDown] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [firstAlertTriggered, setFirstAlertTriggered] = React.useState(false);
  // Timer settings
  const introductionReminderMinutes = 10; // Reminder timeline for introdution page
  const quoteReminderMinutes = 20; // Reminder throughout interview before reaching "Quote" page
  const quoteRepeatReminderMinutes = 5; // Repeat reminder if still not reached "Quote" page

  const triggerAlert = React.useCallback((message: string, isFirstAlert?: boolean) => {
    setMessage(message);
    setOpen(true);

    if (isFirstAlert) {
      setFirstAlertTriggered(true);
    } else {
      // After triggering the alert, we have a "cooldownMs" cool down time
      setCoolDown(true);
      setTimeout(() => {
        setCoolDown(false);
      }, quoteRepeatReminderMinutes * 60 * 1000);
    }
  }, [setMessage, setOpen, setCoolDown, setFirstAlertTriggered]);

  const { hash, pathname } = useLocation();
  React.useEffect(() => {
    if (isCoolingDown || !pathname.includes('/interview')) return;

    if (callTimeInSeconds > quoteReminderMinutes * 60 && !['#quote', '#call-wrap'].includes(hash)) {
      // If more than 30 mins and still not in #quote or #call-wrap section
      triggerAlert(`This call has reached ${quoteReminderMinutes} minutes.`
      + ' If you are not in the process of closing the sale please either move forward or disposition the call as pipeline.');
    } else if (!firstAlertTriggered && callTimeInSeconds > introductionReminderMinutes * 60 && ['', '#introduction'].includes(hash)) {
      // If more than 10 minutes and still in #introduction section
      triggerAlert(`It has been ${introductionReminderMinutes} minutes, `
      + 'please make sure you are moving the call forward to scope of appointment.', true);
    }
  }, [isCoolingDown, callTimeInSeconds, hash, pathname]);

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <AlertDialog
      open={open}
      handleClose={handleClose}
      confirmText="OK"
      message={message}
      title="Alert! Call Volume is High"
      onConfirm={handleClose}
    />
  );
};
