import { useApolloClient, useLazyQuery } from '@apollo/react-hooks';
import { gql, ApolloError } from 'apollo-boost';
import { FORM_FRAGMENT, QUESTION_FRAGMENT } from '../../fragments';
import { buildRecursiveStructure } from './useFormQuery.util';
import { FormQueryData, FormQueryVariables } from './useFormQuery.interfaces';
import { VariationChangeEvent, useEventListener } from '../../../events';
import { logger } from '../../../services';
import { getFormDataId } from '../../../util';

const FORM_QUERY = gql`
  query getForm($leadId: ID!, $referenceId: String!) {
    form(leadId: $leadId, referenceId: $referenceId) {
      form {
        ...FormFragment
      }
      questions {
        ...QuestionFragment
      }
    }
  }
  ${FORM_FRAGMENT}
  ${QUESTION_FRAGMENT}
`;

export function useFormQuery(leadId: string, referenceId: string) {
  const dataId = getFormDataId({ leadId, referenceId });
  const apolloClient = useApolloClient();
  const form = apolloClient.readFragment({
    id: dataId,
    fragment: FORM_FRAGMENT,
    fragmentName: 'FormFragment',
  });

  const [
    fetchForm,
    {
      loading, error, data, refetch,
    },
  ] = useLazyQuery<FormQueryData, FormQueryVariables>(FORM_QUERY, {
    variables: {
      leadId,
      referenceId,
    },
  });

  useEventListener(VariationChangeEvent, () => {
    if (data) {
      refetch().catch((err) => logger.crit(err));
    }
  });

  if (!form && !loading && !error && !data) {
    fetchForm();
  }

  if (form && (loading || error || !data?.form)) {
    return {
      loading: false,
      error: null as ApolloError,
      data: buildRecursiveStructure(form),
    };
  }

  return {
    loading,
    error,
    data: data?.form?.form && buildRecursiveStructure(data.form.form),
  };
}
