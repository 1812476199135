import * as React from 'react';
import { CustomerLayoutProps } from './CustomerLayout.interfaces';
import {
  CustomerLayoutContainer,
  InfoPanelContainer,
  MainTabPanelContainer,
  ToolPanelContainer,
} from './CustomerLayout.elements';
import { ResizableColumns } from '../ResizableColumns';
import { SidePanelLayout } from '../SidePanelLayout';
import { TabTopLayout } from '../TabTopLayout';
import { LockedInfo } from '../LockedInfo';

export const CustomerLayout: React.FunctionComponent<CustomerLayoutProps> = ({
  navBar,
  infoPanelContent,
  mainPanelProps,
  toolPanelProps,
  lockedDetails,
}) => (
  <CustomerLayoutContainer>
    { navBar }
    <ResizableColumns
      navBarPresent
      initialColumnWidths={['300px', 'auto', '300px']}
      columnContents={[
        (
          <InfoPanelContainer key="Personal Information">
            { infoPanelContent }
          </InfoPanelContainer>
        ),
        (
          <MainTabPanelContainer>
            { lockedDetails.isLocked && <LockedInfo sfId={lockedDetails.sfId} /> }
            <TabTopLayout {...mainPanelProps} />
          </MainTabPanelContainer>
        ),
        (
          <ToolPanelContainer>
            <SidePanelLayout {...toolPanelProps} />
          </ToolPanelContainer>
        ),
      ]}
      collapsableColumnIndexes={[0, 4]}
    />
  </CustomerLayoutContainer>
);
