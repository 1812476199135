import { gql } from 'apollo-boost';
import { MutationCustomReturn, useMutationCustom } from '../useMutationCustom';
import { RevokeHipaaConsentMutationResponse, RevokeHipaaConsentMutationVariables } from './useRevokeHipaaConsentMutation.interfaces';

const REVOKE_HIPAA_CONSENT_MUTATION = gql`
  mutation RevokeHipaaConsentMutation($customerId: ID!) {
    revokeHipaaConsent(customerId: $customerId) {
       success
       message
       digitalMedUserId
       hipaaStatus
       hipaaRevokedDate
    }
  }
`;

export function useRevokeHipaaConsentMutation()
: MutationCustomReturn<RevokeHipaaConsentMutationResponse, RevokeHipaaConsentMutationVariables> {
  return useMutationCustom<RevokeHipaaConsentMutationResponse, RevokeHipaaConsentMutationVariables>(REVOKE_HIPAA_CONSENT_MUTATION,
    { refetchQueries: ['GetCustomerHealthRecordStatusQuery', 'BionicFetchHealthRecords'] });
}
