import * as React from 'react';
import { ParagraphRendererProps } from './ParagraphRenderer.interfaces';
import {
  ParagraphRendererContainer,
} from './ParagraphRenderer.elements';

export const ParagraphRenderer: React.FunctionComponent<ParagraphRendererProps> = ({ children }) => (
  <ParagraphRendererContainer>
    { children }
  </ParagraphRendererContainer>
);
