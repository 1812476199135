import moment from 'moment';
import { useContext, useMemo } from 'react';
import { FormAnswers } from '@hiq/crm.types';
import { useFormAnswersQuery } from '../../../../../hooks';
import { formatDate } from '@healthiqeng/core.util.format-date';
import { QUESTION_IDS_DATE_FORMATTING } from './constants';
import { LeadIdContext } from '../../../../contexts';

export function useTemplateReplacer(markdown: string): string {
  const leadId = useContext(LeadIdContext);
  const formAnswers = useFormAnswersQuery(leadId);
  return useMemo(
    () => renderTemplate(markdown, formAnswers),
    [formAnswers, markdown],
  );
}

function renderTemplate(markdown: string, formAnswers: FormAnswers): string {
  const matches = markdown?.match(/\{\{(dayofweek:|time:)?[a-zA-Z0-9_.]+\}\}/g);
  if (!matches?.length) {
    return markdown;
  }

  let output = markdown;
  for (const stringMatch of matches) {
    let key = stringMatch.replace(/\{|\}/g, '');
    let dateFormat: string;
    if (key.startsWith('dayofweek:') || key.startsWith('time:')) {
      dateFormat = key.startsWith('dayofweek') ? 'dddd' : 'hh:mm A';
      key = key.replace(/dayofweek:|time:/g, '');
    }
    let value = formAnswers[key];

    if (QUESTION_IDS_DATE_FORMATTING.includes(key)) {
      value = formatDate(value);
    } else if (dateFormat) {
      value = moment(value).format(dateFormat);
    }
    if (value) {
      output = output.replace(stringMatch, `__${value}__`);
    }
  }
  return output;
}
