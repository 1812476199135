import React from 'react';
import { SortFunctionsDefinition } from './useSort.interfaces';

export function useSort<D>(rows: D[], sortFns: SortFunctionsDefinition<D>): [D[], string, (sortType: string) => void] {
  const [sortedRows, setSortedRows] = React.useState([...rows]);
  const initialSortFn = sortFns && Object.keys(sortFns).find((key) => sortFns[key].isDefault);
  const [sortType, setSortType] = React.useState(initialSortFn);

  React.useEffect(() => {
    const sortFn = sortFns?.[sortType]?.fn;
    if (sortFn) setSortedRows([...rows].sort(sortFn));
    else setSortedRows([...rows]);
  }, [rows, sortFns]);

  const setSortBy = React.useCallback((sortType: string) => {
    setSortType(sortType);
    const sortFn = sortFns[sortType].fn;
    setSortedRows([...sortedRows].sort(sortFn));
  }, [rows, sortedRows, sortFns]);
  return [sortedRows, sortType, setSortBy];
}
