import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../../themes';

export const BlockQuoteContainer = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  padding: 24px;
  background-color: ${theme.color.grayBackground};
  border: 1px solid ${theme.color.grayLight};
  border-radius: 2px;
  display: flex;
  flex-direction: row;
`);

export const BlockQuoteIconContainer = styled('div')(css`
  margin-right: 20px;
  width: 45px;
  height: 35px;
`);

export const BlockQuoteIcon = styled('img')(css`
  position: relative;
  top: 5px;
`);
export const BlockQuoteText = styled('div')(css`
  margin: -10px 0;
`);
