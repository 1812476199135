import { QuestionType } from '@hiq/crm.types';
import * as React from 'react';
import { useFormAnswers } from '../../../hooks';
import { SimpleQuestionView } from '../FormGenerator/FormGeneratorQuestionFlow/Question/SimpleQuestionView';
import { ScriptBlock } from '../ScriptBlock/ScriptBlock/ScriptBlock';

const AETNA_NON_HRA_ELEGIBLE_PLANS: string[] = [
  'Aetna Medicare Assure Plan (HMO D-SNP)', // H3312-073
  'Aetna Assure Premier Plus (HMO D-SNP)', // H6399-001
  'Aetna Better Health of Virginia (HMO D-SNP)', // H1610-001
  'Aetna Medicare Longevity Plan (HMO I-SNP)', // H3959-066
  'Aetna Medicare Preferred Plan (HMO D-SNP)', // H4982-016
  'Aetna Medicare Preferred Plan (HMO D-SNP)', // H4982-008
];

export const MedicareRetentionSpecialistAetnaHRA: React.FunctionComponent = () => {
  const [answers] = useFormAnswers();
  if (!answers) return null;

  const clientAgreedToTakeHRAQuestionId = 'lead.aetna.clientAgreedToTakeHRA';
  const completedHRAWithClientQuestionId = 'lead.hraCompletedWithClient';
  const enrolledPlanNameId = 'lead.sunfire.enrolledPlan.name';
  const sunfireEnrollmentCodeId = 'lead.sunfire.enrollmentCode';

  const enrolledPlanName = answers[enrolledPlanNameId];
  const sunfireEnrollmentCode = answers[sunfireEnrollmentCodeId];
  const [showScript, setShowScript] = React.useState(false);

  React.useEffect(() => {
    setShowScript(AETNA_NON_HRA_ELEGIBLE_PLANS.indexOf(enrolledPlanName) === -1);
  }, [enrolledPlanName]);

  if (!showScript) return null;

  return (
    <>
      <ScriptBlock>
        Health IQ and Aetna are committed to improving the health of our members beyond the doctor
        visits and prescriptions that most of us think of when we hear “healthcare”.
        Aetna offers a voluntary survey that focuses on Health, access to food, and other needs to identify additional resources
        that may be available to you.
      </ScriptBlock>

      <p>Would you like to complete your Member Care Assessment with me now?”</p>
      <p>Did the Client Agree to take the HRA ?</p>
      <SimpleQuestionView
        id={clientAgreedToTakeHRAQuestionId}
        type={QuestionType.RadioYesNo}
      />

      <p>Did you complete the entire HRA on the carrier site?</p>
      <SimpleQuestionView
        id={completedHRAWithClientQuestionId}
        type={QuestionType.RadioYesNo}
      />

      <p>
        <b>Enrollment#</b>
        {' '}
        {sunfireEnrollmentCode}
      </p>
    </>
  );
};
