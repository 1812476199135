import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  Box, Button, Divider, Typography,
} from '@material-ui/core';
import { useGetLeadDNCPhoneNumbers } from './useGetLeadDNCPhoneNumbers';
import { DNCAddPhoneNumberInput } from './DNCAddPhoneNumberInput';
import { useCurrentCall } from '@healthiqeng/core.hooks.use-dialer-service';
import { formatPhoneNumber } from '@healthiqeng/core.infrastructure';
import { Alert } from '@material-ui/lab';

export const DNCPhoneNumberEditor: React.FC = () => {
  const [savedPhoneNumbers, onChangeDNCPhoneNumbers] = useGetLeadDNCPhoneNumbers();
  const [dncPhoneNumbers, setDncPhoneNumbers] = React.useState<string[]>(savedPhoneNumbers || []);
  const { callData } = useCurrentCall();
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (!dncPhoneNumbers.includes(callData?.phoneNumber) || !savedPhoneNumbers.includes(callData?.phoneNumber)) {
      handleNewNumber(callData?.phoneNumber);
    }
  }, [callData?.phoneNumber]);

  function handleNewNumber(phoneNumber: string) {
    if (dncPhoneNumbers.indexOf(phoneNumber) >= 0) {
      setErrorMessage('Phone number already exist in DNC list');
      setTimeout(() => {
        setErrorMessage(null);
      }, 5000);
      return;
    }
    const newNumbers = [...dncPhoneNumbers, phoneNumber];
    setDncPhoneNumbers(newNumbers);
    onChangeDNCPhoneNumbers(newNumbers);
  }

  function handleDeleteNumber(phoneNumber: string) {
    const newNumbers = dncPhoneNumbers.filter((number) => number !== phoneNumber);
    updateDncNumbers(newNumbers);
  }

  function updateDncNumbers(dncPhoneNumbers: string[]) {
    setDncPhoneNumbers(dncPhoneNumbers);
    onChangeDNCPhoneNumbers(dncPhoneNumbers);
  }

  return (
    <Box>
      <Typography align="center" paragraph variant="subtitle1">Phone Numbers to DNC</Typography>
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <ul>
        { dncPhoneNumbers && dncPhoneNumbers.map((phoneNumber:string) => (
          <li key={`${phoneNumber}`}>
            { formatPhoneNumber(phoneNumber) }
            <Button onClick={() => handleDeleteNumber(phoneNumber)} style={{ padding: 0 }}>
              <DeleteIcon />
            </Button>
          </li>
        ))}
      </ul>

      <Divider style={{ marginBottom: 10 }} />
      <DNCAddPhoneNumberInput onSave={handleNewNumber} />
    </Box>
  );
};
