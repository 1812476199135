import React, { useCallback } from 'react';
import { isPresent } from '@healthiqeng/core.util.is-present';
import {
  BillingFrequency,
  ProductTypeLife,
  QuestionOption,
  QuestionType,
  QuotePresentationType,
  TermLength,
  TermLengthLabelMap,
  UnderwritingType,
  UnderwritingTypeLabelMap,
} from '@hiq/crm.types';
import { useExternalLifeQuote, LifeExternalQuoteState } from '../useExternalQuote/useExternalLifeQuote';
import { QueryLoader } from '../../../QueryLoader';
import { QuoteOptionsLayout } from '../../QuoteOptionsLayout';
import { QuoteOption } from '../../QuoteOptions/QuoteOption';
import { PrimaryButton } from '../../../inputs/PrimaryButton';
import { useLogClick } from '../../../../../graphql';
import { LifeExternalQuoteProps } from './LifeExternalQuote.interfaces';
import { BillingFrequencyOptions } from '../../../../../constants';
import { emitAnalyticsEvent } from '../../../../../events/emitAnalyticsEvent/emitAnalyticsEvent';

export const LifeExternalQuote: React.FunctionComponent<LifeExternalQuoteProps> = ({ type }) => {
  const {
    quoteInput,
    setQuoteInput,
    carrierOptions,
    productOptions,
    submitQuote,
    loading,
    error,
    addQuoteData,
  } = useExternalLifeQuote({
    type,
    presentationType: QuotePresentationType.Selected,
    carrierId: null,
    productId: null,
    rate: {
      monthly: null,
      quarterly: null,
      semiAnnually: null,
      annually: null,
      policyFee: 0,
    },
    billingFrequency: null,
    faceAmount: 0,
    policyTermLength: getDefaultTermLength(type),
    underwritingType: getDefaultUnderwritingType(type),
  });

  const [logClick] = useLogClick();
  const onAddQuoteButtonClick = useCallback(() => {
    logClick({ control: 'Add Quote', detail: type });
    emitAnalyticsEvent('Add Quote', { detail: type });
    submitQuote();
  }, [useLogClick, submitQuote]);

  const canSubmit = [
    quoteInput.carrierId,
    quoteInput.productId,
    quoteInput.faceAmount,
    quoteInput.policyTermLength,
    quoteInput.underwritingType,
  ].every(isPresent) && !!(
    quoteInput.rate.monthly
    || quoteInput.rate.quarterly
    || quoteInput.rate.semiAnnually
    || quoteInput.rate.annually
  );

  const termLengthOptions: QuestionOption[] = Object.entries(TermLengthLabelMap).map(([value, label]) => ({ label, value }));
  const underwritingTypeOptions: QuestionOption[] = Object.entries(UnderwritingTypeLabelMap).map(([value, label]) => ({ label, value }));

  return (
    <QueryLoader loading={loading} error={error} componentName="LifeExternalQuote">
      <QuoteOptionsLayout
        leftContent={(
          <>
            <QuoteOption
              id="carrierId"
              value={quoteInput.carrierId ?? ''}
              handleChange={setQuoteInput}
              label="Carrier"
              type={QuestionType.Select}
              options={carrierOptions || []}
            />
            <QuoteOption
              id="productId"
              value={quoteInput.productId && productOptions?.some(
                (option) => option.value === quoteInput.productId,
              ) ? quoteInput.productId : ''}
              handleChange={setQuoteInput}
              label="Product"
              type={QuestionType.Select}
              options={productOptions || []}
            />
            <QuoteOption
              id="faceAmount"
              value={quoteInput.faceAmount}
              handleChange={setQuoteInput}
              label="Face Amount"
              type={QuestionType.Currency}
            />
          </>
        )}
        rightContent={(
          <>
            <QuoteOption
              id="billingFrequency"
              label="Payment Plan"
              value={quoteInput.billingFrequency}
              type={QuestionType.Select}
              handleChange={setQuoteInput}
              options={BillingFrequencyOptions}
            />
            <QuotePremium
              quoteInput={quoteInput}
              setQuoteInput={setQuoteInput}
            />
            { (type === ProductTypeLife.Term || type === ProductTypeLife.CriticalIllness) && (
              <QuoteOption
                id="policyTermLength"
                value={quoteInput.policyTermLength}
                handleChange={setQuoteInput}
                label="Term Length"
                type={QuestionType.Select}
                options={termLengthOptions}
              />
            ) }
            { type === ProductTypeLife.WholeLife && (
              <QuoteOption
                id="underwritingType"
                value={quoteInput.underwritingType}
                handleChange={setQuoteInput}
                label="Underwriting Type"
                type={QuestionType.Select}
                options={underwritingTypeOptions}
              />
            ) }
            <PrimaryButton
              onClick={onAddQuoteButtonClick}
              disabled={!canSubmit}
              variant="contained"
            >
              { loading ? 'Submitting...' : 'Add Quote' }
            </PrimaryButton>
            { error && <div>{ error.message }</div> }
            { addQuoteData && <div>Success</div> }
          </>
        )}
        minWidth={200}
        gutter={20}
      />
    </QueryLoader>
  );
};

interface QuoteInputProps {
  quoteInput: LifeExternalQuoteState;
  setQuoteInput: (key: string, value: string) => void;
}

function QuotePremium({ quoteInput, setQuoteInput }: QuoteInputProps) {
  const { billingFrequency } = quoteInput;

  if (billingFrequency === BillingFrequency.Quarterly) {
    return (
      <QuoteOption
        id="rate.quarterly"
        label="Modal Premium (Quarterly)"
        value={quoteInput.rate.quarterly}
        type={QuestionType.Currency}
        handleChange={setQuoteInput}
      />
    );
  }
  if (billingFrequency === BillingFrequency.SemiAnnually) {
    return (
      <QuoteOption
        id="rate.semiAnnually"
        label="Modal Premium (Semi-Annually)"
        value={quoteInput.rate.semiAnnually}
        type={QuestionType.Currency}
        handleChange={setQuoteInput}
      />
    );
  }
  if (billingFrequency === BillingFrequency.Annually) {
    return (
      <QuoteOption
        id="rate.annually"
        label="Modal Premium (Yearly)"
        value={quoteInput.rate.annually}
        type={QuestionType.Currency}
        handleChange={setQuoteInput}
      />
    );
  }
  return (
    <QuoteOption
      id="rate.monthly"
      label="Modal Premium (Monthly)"
      value={quoteInput.rate.monthly}
      type={QuestionType.Currency}
      handleChange={setQuoteInput}
    />
  );
}

function getDefaultTermLength(type: ProductTypeLife) {
  if (type === ProductTypeLife.Term) return null;
  return TermLength.Permanent;
}

function getDefaultUnderwritingType(type: ProductTypeLife) {
  if ([ProductTypeLife.WholeLife, ProductTypeLife.Term].includes(type)) return null;
  return UnderwritingType.GuaranteedIssue;
}
