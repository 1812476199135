import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { resetImg } from '../resets';

interface ISingleImageContainerProps {
  maxWidth: number;
  maxHeight: number;
}

export const SingleImageContainer = styled('img')<ISingleImageContainerProps>(resetImg, (props) => css`
  width: 100%;
  height: auto;
  max-width: ${props.maxWidth}px;
  max-height: ${props.maxHeight}px;
`);
