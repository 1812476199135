/* eslint react/require-default-props: 0 */
import React from 'react';
import { useFormAnswers } from '../../../hooks';
import { useGetSunfireCountiesFromZipQuery } from '../../../graphql';
import { SelectInput } from '../inputs/SelectInput/SelectInput';
import { QuestionOption } from '@hiq/crm.types';
import { useActiveLeadId } from '../../routers';
import Alert from '@material-ui/lab/Alert/Alert';
import { Snackbar } from '@material-ui/core';

const ZIPCODE_QUESTION_ID = 'customer.person.homeAddress.zip';
const SUNFIRE_COUNTY_QUESTION_ID = 'lead.sunfire.v2.countyCode';

type SunfireCountyProps = {
  label?: string
};

const SunfireCounty = ({ label }: SunfireCountyProps) => {
  const leadId = useActiveLeadId();
  const [answers, onChange] = useFormAnswers(leadId, true);
  const zip = answers[ZIPCODE_QUESTION_ID];
  const [getCounties, getCountiesData] = useGetSunfireCountiesFromZipQuery();
  const county = answers[SUNFIRE_COUNTY_QUESTION_ID];
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (zip) getCounties({ variables: { zip } });
  }, [zip]);

  const selectOptions: QuestionOption[] = React.useMemo(
    () => getCountiesData.data?.findSunfireCountiesByZip.counties.map((county) => ({ label: county.name, value: county.code })),
    [getCountiesData.data?.findSunfireCountiesByZip],
  );

  const selectCounty = React.useCallback(
    (value: string) => onChange(SUNFIRE_COUNTY_QUESTION_ID, value),
    [onChange],
  );

  React.useEffect(() => {
    if (selectOptions?.length === 0) selectCounty(null);
    else if (selectOptions?.length === 1 && county !== selectOptions[0]?.value) selectCounty(selectOptions[0]?.value as string);
    else if (selectOptions?.length && !selectOptions.find(
      (option) => option.value === answers[SUNFIRE_COUNTY_QUESTION_ID],
    )
    ) selectCounty(null);
  }, [selectOptions, county]);

  React.useEffect(() => {
    if (getCountiesData?.data?.findSunfireCountiesByZip?.success === false) {
      selectCounty(null);
      setOpen(true);
    }
  }, [getCountiesData?.data?.findSunfireCountiesByZip?.success]);

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <>
      <SelectInput
        value={county}
        onChange={selectCounty}
        options={selectOptions}
        label={label}
        disabled={!getCountiesData.data?.findSunfireCountiesByZip.counties?.length}
        fullWidth
        answersKey={SUNFIRE_COUNTY_QUESTION_ID}
      />
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert severity="error" onClose={handleClose}>
          Zipcode not found
        </Alert>
      </Snackbar>
    </>
  );
};

export default SunfireCounty;
