import * as React from 'react';
import { IDropdownOptionsProps } from './DropdownOptions.interfaces';

export class DropdownOptions extends React.Component<IDropdownOptionsProps> {
  public render() {
    return (
      <>
        {this.props.options.map((option, index) => (
          <option key={`${option.name}-${index}`} value={option.value}>{option.name}</option>
        ))}
      </>
    );
  }
}
