import React from 'react';
import { Helmet } from 'react-helmet';

export function AppIcons() {
  return (
    <Helmet>
      <link rel="apple-touch-icon" sizes="57x57" href="https://media.healthiq.com/favicon/apple-touch-icon-57x57.png?v=2" />
      <link rel="apple-touch-icon" sizes="60x60" href="https://media.healthiq.com/favicon/apple-touch-icon-60x60.png?v=2" />
      <link rel="apple-touch-icon" sizes="72x72" href="https://media.healthiq.com/favicon/apple-touch-icon-72x72.png?v=2" />
      <link rel="apple-touch-icon" sizes="76x76" href="https://media.healthiq.com/favicon/apple-touch-icon-76x76.png?v=2" />
      <link rel="apple-touch-icon" sizes="114x114" href="https://media.healthiq.com/favicon/apple-touch-icon-114x114.png?v=2" />
      <link rel="apple-touch-icon" sizes="120x120" href="https://media.healthiq.com/favicon/apple-touch-icon-120x120.png?v=2" />
      <link rel="apple-touch-icon" sizes="144x144" href="https://media.healthiq.com/favicon/apple-touch-icon-144x144.png?v=2" />
      <link rel="apple-touch-icon" sizes="152x152" href="https://media.healthiq.com/favicon/apple-touch-icon-152x152.png?v=2" />
      <link rel="apple-touch-icon" sizes="180x180" href="https://media.healthiq.com/favicon/apple-touch-icon-180x180.png?v=2" />
      <link rel="icon" type="image/png" href="https://media.healthiq.com/favicon/favicon-32x32.png?v=2" sizes="32x32" />
      <link rel="icon" type="image/png" href="https://media.healthiq.com/favicon/android-chrome-192x192.png?v=2" sizes="192x192" />
      <link rel="icon" type="image/png" href="https://media.healthiq.com/favicon/favicon-96x96.png?v=2" sizes="96x96" />
      <link rel="icon" type="image/png" href="https://media.healthiq.com/favicon/favicon-16x16.png?v=2" sizes="16x16" />
      <link rel="manifest" href="https://media.healthiq.com/manifest.json" />
      <link rel="mask-icon" href="https://media.healthiq.com/favicon/safari-pinned-tab.svg?v=2" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="msapplication-TileImage" content="https://media.healthiq.com/favicon/mstile-144x144.png?v=2" />
      <meta name="theme-color" content="#ffffff" />
    </Helmet>
  );
}
