import * as React from 'react';
import { useCallback } from 'react';
import { emitAnalyticsEvent } from '../../../../../../events/emitAnalyticsEvent/emitAnalyticsEvent';
import { useValidForConversionQuery, useConvertLeadMutation, useLogClick } from '../../../../../../graphql';
import {
  QueryLoader,
  PrimaryButton,
} from '../../../../../common';
import { useActiveLeadId } from '../../../../../routers';
import { ConversionMessage } from '../../../common';
import { MedicareConversionHeader } from './MedicareConversionPanel.elements';
import { useFormAnswers } from '../../../../../../hooks';

export const MedicareConversionPanel: React.FunctionComponent = () => {
  const {
    data, error, loading,
  } = useValidForConversionQuery();
  const [convertLead, { loading: converting, data: mutationData }] = useConvertLeadMutation();
  const leadId = useActiveLeadId();
  const [answers, onChange] = useFormAnswers(leadId);
  const [logClick] = useLogClick();
  const errors = [
    ...(data?.validForConversion?.errors ?? []),
    ...(mutationData?.convertLead?.convertResult?.errors ?? []),
  ];

  const onConvertButtonClick = useCallback(() => {
    logClick({ control: 'Convert Button' });
    emitAnalyticsEvent('Convert Button');
    convertLead({ leadId });
  }, [logClick, convertLead]);

  return (
    <QueryLoader loading={loading} error={error} componentName="MedicareConversionPanel">
      <>
        <MedicareConversionHeader>
          { data?.validForConversion?.success ? 'Validation Passed' : 'Before You Can Convert' }
        </MedicareConversionHeader>
        { errors.map((err) => (
          <ConversionMessage
            key={err.questionId || err.message}
            type="error"
            leadId={leadId}
            answers={answers}
            onChange={onChange}
            {...err}
          />
        )) }
        { data?.validForConversion?.warnings?.map((warning) => (
          <ConversionMessage
            key={warning.questionId || warning.message}
            type="warning"
            leadId={leadId}
            answers={answers}
            onChange={onChange}
            {...warning}
          />
        )) }
        <PrimaryButton
          onClick={onConvertButtonClick}
          disabled={!data?.validForConversion?.success || converting}
          variant="contained"
          size="large"
          fullWidth
        >
          { converting ? 'Converting...' : 'Convert' }
        </PrimaryButton>
      </>
    </QueryLoader>
  );
};
