import React from 'react';
import {
  Column,
  AlternativeOptionsTitle,
  RadioGroup,
} from './CheckMyHealthRecordVerifyUserCarrier.elements';
import { VerifyWithCarrierNote } from './VerifyWithCarrierNote';
import { useFormAnswers } from '../../../../hooks';
import { useVerifyCmhrUserMutationByMarx } from '../../../../graphql/mutations/useVerifyCmhrUserMutation';
import { useActiveLeadId, useCustomerId } from '../../../routers';
import { HealthRecordErrorMessage } from '../CheckMyHealthRecordDataGrid/HealthRecordErrorMessage';
import { CarrierVerificationAlternative } from './CarrierVerificationAlternative';

const CARRIER_OPTIONS = [
  {
    name: 'UHC (Jarvis)',
    url: 'https://www.uhcjarvis.com/content/jarvis/en/sign_in.html#/sign_in',
    value: 'uhc',
  },
  {
    name: 'Anthem',
    url: 'https://mproducer.anthem.com/mproducer/public/login',
    value: 'anthem',
  },
  {
    name: 'Humana',
    url: 'https://account.humana.com/',
    value: 'humana',
  },
  {
    name: 'Aetna',
    url: 'https://app.thinkagent.com/sign-in',
    value: 'aetna',
  },
  {
    name: 'Cigna',
    url: 'https://cignaforbrokers.com/web/login',
    value: 'cigna',
  },
  {
    name: 'Could not verify',
    value: 'could_not_verify',
  },
];

const CARRIER_VERIFIED_QUESTION_ID = 'lead.digitalProductCustomerCarrierVerified';

export const CheckMyHealthRecordVerifyUserCarrier = () => {
  const customerId = useCustomerId();
  const leadId = useActiveLeadId();
  const [answers, saveAnswer] = useFormAnswers(leadId);

  const currentAnswer = answers[CARRIER_VERIFIED_QUESTION_ID];
  const [
    verifyChmrUserByMarxMutation,
    { data: mutationData },
  ] = useVerifyCmhrUserMutationByMarx({ variables: { customerId }, refetchQueries: ['GetCustomerHealthRecordStatusQuery'] });
  const { success } = mutationData?.verifyCmhrUserByMarx ?? {};

  const handleVerifyUser = React.useCallback(() => {
    verifyChmrUserByMarxMutation({ variables: { customerId, verifiedWithCarrier: true } });
  }, [verifyChmrUserByMarxMutation, customerId]);

  const onChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    saveAnswer(CARRIER_VERIFIED_QUESTION_ID, event.target.value);
    if (event.target.value !== 'could_not_verify') {
      handleVerifyUser();
    }
  }, [saveAnswer, handleVerifyUser]);

  return (
    <Column>
      <VerifyWithCarrierNote />
      <AlternativeOptionsTitle>Alternative verification options </AlternativeOptionsTitle>
      <RadioGroup>
        {CARRIER_OPTIONS.map((option) => (
          <CarrierVerificationAlternative
            name={option.name}
            value={option.value}
            url={option.url}
            handleChange={onChange}
            checked={currentAnswer === option.value}
          />
        ))}
      </RadioGroup>
      {success === false && (
        <HealthRecordErrorMessage
          message="Unexpected error. Failed to verify user."
        />
      )}
      {success === true && (
        <HealthRecordErrorMessage severity="info" message="User Verified" />
      )}
    </Column>
  );
};
