import styled from '@emotion/styled';
import { FormControlLabel as MuiFormControlLabel, RadioGroup as RadioGroupMui } from '@material-ui/core';
import CallMadeIcon from '@material-ui/icons/CallMade';
import { ComponentWithThemeProps } from '../../../../themes';
import { css } from '@emotion/core';

export const Column = styled.div`
  display: grid;
  grid-gap: 10px;
  justify-items: flex-start;
`;

export const Row = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-between;
`;

export const RadioGroup = styled(RadioGroupMui)`
   width: 70%;
   grid-gap: 10px;
`;

export const RadioInput = styled(MuiFormControlLabel)<ComponentWithThemeProps>(
  ({ theme }) => css`
    color: ${theme.color.text};
    background: ${theme.color.primaryLighter};
    border-radius: 7px;
    padding: 2px 15px 2px 5px;
    border: 1px solid ${theme.color.grayDark};
    width: 300px;
  `,
);

export const RedirectIcon = styled(CallMadeIcon)`
    margin-left: 10px;
    color: #0075CA;
    font-size: 14px;
`;

export const CarrierTextLink = styled.a`
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
    color: #0075CA;
`;

export const AlternativeOptionsTitle = styled.div`
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
`;
export const Note = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 24px;
    gap: 8px;
    background: #F5F5F5;
    border-radius: 2px;
`;

export const NoteText = styled.span`
    font-size: 16px;
    line-height: 20px;
    color: #000000;
`;

export const NoteTitle = styled.span`
    font-size: 12px;
    color: #0075CA;
`;
export const NoteLink = styled.a`
    size: 16px;
    color: #0075CA;
`;
