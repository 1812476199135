import {
  IndustryType,
  IndustryTypeLabelMap,
  LeadInitialStatus,
  LeadInitialStatusLabelMap,
  LeadPostSaleStage,
  LeadPostSaleStageLabelMap,
  LeadPostSaleSubStage,
  LeadPostSaleSubStageLabelMap,
  LeadPreSaleStage,
  LeadPreSaleStageLabelMap,
  ProductType,
  ProductTypeLabelMap,
} from '@hiq/crm.types';

import { LeadStage } from './LeadInfo.interfaces';

export function getFormattedStage(stage: LeadStage): string {
  if (Object.values(LeadPreSaleStage).includes(stage as LeadPreSaleStage)) {
    return LeadPreSaleStageLabelMap[stage as LeadPreSaleStage];
  }
  if (Object.values(LeadPostSaleStage).includes(stage as LeadPostSaleStage)) {
    return LeadPostSaleStageLabelMap[stage as LeadPostSaleStage];
  }
  return stage;
}

export function getFormattedSubStage(subStage: LeadPostSaleSubStage): string {
  if (subStage in LeadPostSaleSubStageLabelMap) {
    return LeadPostSaleSubStageLabelMap[subStage];
  }
  return subStage;
}

export function getFormattedStatus(status: LeadInitialStatus): string {
  if (status in LeadInitialStatusLabelMap) {
    return LeadInitialStatusLabelMap[status];
  }
  return status;
}

export function getFormattedIndustryType(industryType: IndustryType): string {
  if (industryType in IndustryTypeLabelMap) {
    return IndustryTypeLabelMap[industryType];
  }
  return industryType;
}

export function getFormattedIsSold(isSold: boolean): string {
  if (isSold) {
    return LeadInitialStatusLabelMap.SOLD;
  }
  return 'Not Sold';
}

export function getFormattedProductType(productType: ProductType): string {
  if (productType in ProductTypeLabelMap) {
    return ProductTypeLabelMap[productType];
  }
  return productType;
}
