import * as React from 'react';
import { useReleaseToFollowUpMutation } from '../../../../graphql';
import { LeadOwnerProps } from './LeadOwner.interfaces';
import {
  LeadOwnerContainer,
  LeadOwnerEmptyContainer,
  LeadOwnerLabel,
  LeadOwnerReleaseButton,
} from './LeadOwner.elements';
import {
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
} from '@material-ui/core';
import { PrimaryButton } from '../../../common';
import { ReleaseOwnerErrorMessage } from './ReleaseOwnerError/ReleaseOwnerErrorMessage';
import { useCurrentUserIsLeadOwner } from '../../Lead/useCurrentUserIsLeadOwner';

export const LeadOwner: React.FunctionComponent<LeadOwnerProps> = ({ leadId }) => {
  const { isOwner, owner } = useCurrentUserIsLeadOwner(leadId);
  const [releaseToFollowUp, {
    data: followUpData,
    error: followUpError,
  }] = useReleaseToFollowUpMutation();
  const [open, setOpen] = React.useState(false);
  const [sending, setSending] = React.useState(false);

  const handleReleaseToFollowUp = React.useCallback(async () => {
    setSending(true);
    const result = await releaseToFollowUp({ leadId });
    if (result.data.releaseToFollowUp.success) {
      setSending(false);
    }
    await handleClose();
  }, [leadId, releaseToFollowUp]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async () => {
    setOpen(false);
  };

  const leadOwnerName = owner ? `${owner.name.first} ${owner.name.last}` : 'None';
  if (isOwner) {
    return (
      <LeadOwnerContainer>
        <LeadOwnerLabel>Owner</LeadOwnerLabel>
        <LeadOwnerLabel>{leadOwnerName}</LeadOwnerLabel>
        <LeadOwnerReleaseButton
          onClick={handleClickOpen}
        >
          [Release]
        </LeadOwnerReleaseButton>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Release Lead back to the dialer
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you would like to release this lead back to the dialer?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <PrimaryButton onClick={handleClose} variant="outlined">No</PrimaryButton>
            <PrimaryButton
              onClick={handleReleaseToFollowUp}
              variant="contained"
              disabled={sending || followUpData?.releaseToFollowUp?.success}
            >
              {sending ? 'Releasing...' : 'Yes'}
            </PrimaryButton>
          </DialogActions>
          {followUpError && (
          <ReleaseOwnerErrorMessage
            message={`Failed to release lead. ${followUpError.message}`}
            severity="error"
          />
          )}
        </Dialog>
      </LeadOwnerContainer>
    );
  }
  return (
    <LeadOwnerContainer>
      <LeadOwnerLabel>Owner</LeadOwnerLabel>
      <LeadOwnerLabel>{leadOwnerName}</LeadOwnerLabel>
      <LeadOwnerEmptyContainer />
    </LeadOwnerContainer>
  );
};
