import React from 'react';
import {
  QuestionContainer, QuestionContent, Column,
} from '../ValidateAdditionalDoctors.elements';
import { useFormAnswers } from '../../../../../../hooks';
import { QuestionView } from '../../../../FormGenerator/FormGeneratorQuestionFlow/Question/QuestionView';
import { QuestionOption, QuestionType } from '@hiq/crm.types';
import { useActiveLeadId } from '../../../../../routers';
import { OtherTextQuestion } from './OtherTextQuestion';

const QUESTION_OPTIONS: QuestionOption[] = [
  {
    value: 'no_pcp',
    label: 'Client does not have a PCP',
  },
  {
    label: 'Client does not have a specialist',
    value: 'no_specialist',
  },
  {
    value: 'has_va_benefits',
    label: 'Client has VA benefits',
  },
  {
    label: 'Client does not want to disclose doctors',
    value: 'no_disclose_doctors',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

const HAS_MINIMUM_RECOMMENDED_DOCTORS_QUESTION_REASON_ID = 'lead.sunfire.v2.hasMinimumRecommendedDoctorsReason';
const HAS_MINIMUM_RECOMMENDED_DOCTORS_QUESTION_REASON_OTHER_ID = 'lead.sunfire.v2.hasMinimumRecommendedDoctorsReasonOther';
const HAS_MINIMUM_RECOMMENDED_DOCTORS_QUESTION_ID = 'lead.sunfire.v2.hasMinimumRecommendedDoctors';

export const HasMinimumRecommendedDoctors = () => {
  const leadId = useActiveLeadId();
  const [answers, onChange] = useFormAnswers(leadId, true);

  const updateAnswer = React.useCallback((value) => {
    const hasReasons = checkHasReasons(value);
    onChange(HAS_MINIMUM_RECOMMENDED_DOCTORS_QUESTION_ID, hasReasons);
    onChange(HAS_MINIMUM_RECOMMENDED_DOCTORS_QUESTION_REASON_ID, value);
  }, [onChange, HAS_MINIMUM_RECOMMENDED_DOCTORS_QUESTION_REASON_ID]);

  const checkHasReasons = React.useCallback((reasons) => {
    if (!reasons?.length) return null;
    if (reasons?.length === 1 && reasons[0] === 'other' && !answers[HAS_MINIMUM_RECOMMENDED_DOCTORS_QUESTION_REASON_OTHER_ID]) return null;
    return true;
  }, []);

  return (
    <QuestionContainer>
      <QuestionContent>
        <Column>
          <QuestionView
            id={HAS_MINIMUM_RECOMMENDED_DOCTORS_QUESTION_REASON_ID}
            questionText="You selected less than 2 doctors, we recommend both a PCP and a specialist. Please state the reason why"
            type={QuestionType.Checkbox}
            label="Select all that apply"
            leadId={leadId}
            options={QUESTION_OPTIONS}
            value={answers[HAS_MINIMUM_RECOMMENDED_DOCTORS_QUESTION_REASON_ID]}
            onChange={updateAnswer}
          />
          {answers[HAS_MINIMUM_RECOMMENDED_DOCTORS_QUESTION_REASON_ID]?.includes('other') && <OtherTextQuestion /> }
        </Column>
      </QuestionContent>
    </QuestionContainer>
  );
};
