import * as React from 'react';
import { ApolloError } from 'apollo-boost';
import { SidePanelContentLayout } from '../../layouts/SidePanelContentLayout';
import { useLeadFollowUpQuery } from '../../../graphql/queries/followUp/useLeadFollowUpQuery';
import { QueryLoader } from '../../common/QueryLoader';
import { FollowUpList } from './FollowUpList';
import { useDeleteFollowUpMutation } from '../../../graphql/mutations/followUp/useDeleteFollowUpMutation';
import { FollowUpScheduler } from './FollowUpScheduler';
import { FollowUpContainer } from './LeadFollowUp.elements';
import { useActiveLeadId } from '../../routers/LeadRouter/useActiveLeadId';
import { hasErrorType } from '../../../graphql/util';
import { OAuthRequired } from '../../common/OAuthRequired';
import Alert from '@material-ui/lab/Alert/Alert';
import { useDialerTransfer } from '../../common/DialerTransferButtons/useDialerTransferButtons/useDialerTransfer';
import { useCreateDialog, useHighlightSection } from '../../../hooks';

export const LeadFollowUp: React.FunctionComponent = () => {
  const { data, loading, error } = useLeadFollowUpQuery();
  const leadId = useActiveLeadId();
  const { selectedDisposition } = useDialerTransfer();
  const [deleteFollowUp, { loading: deleting }] = useDeleteFollowUpMutation(leadId);
  const { highlightSection, cancelSectionHighlight } = useHighlightSection();

  const { createDialog, closeDialog } = useCreateDialog();

  const dataQa = 'side-panel-layout-tab-content-follow-up';

  React.useEffect(() => {
    if (selectedDisposition?.label !== 'Pipeline') return;
    if (!loading) {
      highlightSection({
        dataQa,
        duration: 0,
        onClickOutside: () => {
          createDialog({
            title: 'Cancel Disposition?',
            content: 'Are you sure you want to cancel the disposition?',
            buttons: [{
              label: 'Yes',
              onClick: () => {
                closeDialog();
                cancelSectionHighlight(dataQa);
              },
            },
            {
              label: 'No',
              onClick: () => {
                closeDialog();
              },
            }],
          });
        },
      });
    }
  }, [selectedDisposition, loading]);

  return (
    <SidePanelContentLayout header="Follow-ups">
      <QueryLoader
        loading={loading || deleting}
        error={error}
        componentName="LeadFollowUp"
        renderError={(err) => {
          if (!hasErrorType(err as ApolloError, 'TokensNotFoundError')) {
            return (
              <div>
                {' '}
                <Alert severity="error">Please login to your Google Account to schedule a follow up</Alert>
                <OAuthRequired appId="google" />
              </div>
            );
          }
          return <OAuthRequired appId="google" />;
        }}
      >
        <FollowUpContainer>
          <FollowUpList
            followUps={data?.scheduledLeadFollowUps}
            onDelete={(id) => deleteFollowUp({ id })}
          />
          <FollowUpScheduler />
        </FollowUpContainer>
      </QueryLoader>
    </SidePanelContentLayout>
  );
};
