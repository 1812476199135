import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const CreateActivityFormContainer = styled('div')(css`
  margin: 5px;
`);

export const InputContainer = styled('div')(css`
  margin: 5px 0;
`);
