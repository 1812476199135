import * as React from 'react';
import { QuestionType } from '@hiq/crm.types';
import { QuestionView } from '../FormGenerator/FormGeneratorQuestionFlow/Question/QuestionView';
import { useActiveLeadId } from '../../routers';
import {
  useSelectedDoctorsQuery,
} from '../../../graphql';
import { JsonCompatible } from '@healthiqeng/core.types';
import { useFormAnswers } from '../../../hooks';

export const AnnualWellnessExamDoctors: React.FunctionComponent = () => {
  const leadId = useActiveLeadId();
  const selectedDoctors = useSelectedDoctorsQuery(leadId)?.data?.selectedDoctors;
  const [answers, onChange] = useFormAnswers();
  const wellnessExamDoctorQuestionId = 'lead.annualWellnessExamDoctor';
  const wellnessExamDoctor = answers[wellnessExamDoctorQuestionId];

  const doctorOptions = selectedDoctors?.map((doctor) => ({ label: doctor.name, value: doctor.id }));

  const handleChange = React.useCallback((key: string, value: JsonCompatible) => {
    onChange(key, value);
  }, [onChange]);

  const onDoctorChange = React.useCallback((value) => {
    handleChange(wellnessExamDoctorQuestionId, value);
  }, [handleChange]);

  return (
    <QuestionView
      id={wellnessExamDoctorQuestionId}
      leadId={leadId}
      value={wellnessExamDoctor as string}
      questionText=""
      label=""
      type={QuestionType.Select}
      options={doctorOptions ?? []}
      onChange={onDoctorChange}
    />
  );
};
