import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { RelatedCustomersQueryData, RelatedCustomersQueryVariables } from './useRelatedCustomersQuery.interfaces';

export const RELATED_CUSTOMERS_QUERY = gql`
  query getRelatedCustomers($customerId: ID!) {
    relatedCustomers(customerId: $customerId) {
      id
      person {
        name {
          salutation
          first
          middle
          last
          suffix
          preferredFirstName
          fullFriendlyName
        }
      }
      leads {
        productType
      }
    }
  }
`;

export function useRelatedCustomersQuery(customerId: string) {
  const result = useQuery<RelatedCustomersQueryData, RelatedCustomersQueryVariables>(RELATED_CUSTOMERS_QUERY, {
    variables: { customerId },
    skip: !customerId,
  });
  return {
    error: result?.error,
    loading: result?.loading,
    relatedCustomers: result?.data?.relatedCustomers ?? [],
  };
}
