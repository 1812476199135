import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  SunfireV2DoctorLocationsQueryData,
  SunfireV2DoctorLocationsQueryVariables,
} from './useSunfireV2DoctorLocationsQuery.interfaces';

const SUNFIRE_DOCTOR_LOCATIONS_QUERY = gql`
  query FindSunfireV2DoctorLocations($doctorId: String!, $city: String!, $state: String!) {
    findSunfireDoctorLocations(doctorId: $doctorId, city: $city, state: $state) {
      address1
      address2
      city
      state
      zip
      lat
      lng
    }
  }
`;

export function useSunfireV2DoctorLocationsQuery() {
  return useLazyQuery<SunfireV2DoctorLocationsQueryData, SunfireV2DoctorLocationsQueryVariables>(SUNFIRE_DOCTOR_LOCATIONS_QUERY);
}
