import * as React from 'react';
import {
  DialerDomain,
  DialerSalesCampaign,
  DialerSalesSkill,
  UserProfile,
} from '@hiq/crm.types';
import { InitialContactReadOnlyViewProps } from './InitialContactReadOnlyView.interfaces';
import { WarningMessage } from './InitialContactReadOnlyView.elements';
import { FullHeight, InitialContactLayout } from '../../layouts';
import { CustomerSummaryHeader } from '../CustomerSummary/CustomerSummaryHeader';
import {
  Card,
  CustomElement,
  DialerTransferDispositions,
  QueryLoader,
  QuestionFlow,
  QuestionFlowRenderer,
  useWaitingForNextCall,
  WaitingNextCallHome,
} from '../../common';
import { useQuestionFlowFromInputQuery } from '../../../graphql/queries/useQuestionFlowFromInputQuery';
import { useTemplateReplacer } from '../../common/QuestionFlow/QuestionFlowRenderer/useTemplateReplacer';
import { useCurrentUserQuery } from '../../../graphql';
import { isSalesDevelopmentRep } from '../../../util';

export const InitialContactReadOnlyView: React.FunctionComponent<InitialContactReadOnlyViewProps> = ({
  customerName,
  campaignName,
  customerState,
  customerPhoneNumber,
  callSkill,
  domain,
}) => {
  const { isWaitingForNextCall } = useWaitingForNextCall();

  if (isWaitingForNextCall) return <WaitingNextCallHome />;

  return (
    <InitialContactReadOnlyViewInner
      customerPhoneNumber={customerPhoneNumber}
      customerName={customerName}
      customerState={customerState}
      campaignName={campaignName}
      callSkill={callSkill}
      domain={domain}
    />
  );
};

const InitialContactReadOnlyViewInner: React.FunctionComponent<InitialContactReadOnlyViewProps> = ({
  customerName,
  campaignName,
  customerPhoneNumber,
  callSkill,
  customerState,
  domain,
}) => {
  const { data: userData, loading: userLoading, error: userError } = useCurrentUserQuery();
  const { data, loading, error } = useQuestionFlowFromInputQuery('initial-contact', {
    phoneNumber: customerPhoneNumber,
    campaignName: campaignName as DialerSalesCampaign,
    callSkill: callSkill as DialerSalesSkill,
    domain: domain as DialerDomain,
  });
  const isSDR = isSalesDevelopmentRep(userData?.currentUser?.profile as UserProfile);
  const isMissingUser = !userLoading && !userError && !userData?.currentUser?.id;
  return (
    <QueryLoader loading={userLoading || !isSDR} error={userError} componentName="InitialContactUserQuery">
      <FullHeight>
        {isMissingUser && (
          <WarningMessage>
            Could not find your user account. Please contact IT.
          </WarningMessage>
        )}
        <InitialContactLayout
          sideContent={(
            <>
              <CustomerSummaryHeader value={customerName} />
              <CustomerSummaryHeader value={campaignName} />
              { callSkill && (
                <CustomerSummaryHeader value={callSkill} />
              )}
              <CustomerSummaryHeader value={`State: ${customerState}`} />
            </>
          )}
          mainContent={(
            <Card>
              <DialerTransferDispositions />
              <QueryLoader
                loading={loading}
                error={error || userError}
                componentName="InitialContactQuestionFlow"
              >
                <QuestionFlowRenderer
                  leadId={null}
                  questionFlowComponent={QuestionFlow}
                  CustomElement={CustomElement}
                  markdownSource={useTemplateReplacer(data?.questionFlow?.body)}
                />
              </QueryLoader>
            </Card>
          )}
        />
      </FullHeight>
    </QueryLoader>
  );
};
