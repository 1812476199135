import React, { useEffect } from 'react';
import { QuestionView } from '../../FormGenerator/FormGeneratorQuestionFlow/Question/QuestionView';
import { useActiveLeadId } from '../../../routers/LeadRouter/useActiveLeadId';
import {
  QuestionType,
} from '@hiq/crm.types';
import { useFormAnswers } from '../../../../hooks';
import { useCustomerId } from '../../../routers/CustomerRouter/useCustomerId';

import { isPresent } from '@healthiqeng/core.util.is-present';
import { useManageChanges } from './hooks/useManageChanges';
import { JsonCompatible } from '@healthiqeng/core.types';
import { emitAnalyticsEventNoLimit } from '../../../../events/emitAnalyticsEvent';
import { WarningBlockView } from '../../ScriptBlock/WarningBlock/WarningBlockView';
import { CheckMyRecordPullRecordButton } from './CheckMyRecordPullRecordButton';

export const CheckMyHealthRecordHipaaAnswerPresales: React.FunctionComponent = () => {
  const leadId = useActiveLeadId();
  const trackingId = React.useMemo(() => `CheckMyHealthRecordHipaaAnswer-${Date.now()}`, []);
  const [answers, onChange, persistAnswers] = useFormAnswers(leadId, false, trackingId);
  const customerId = useCustomerId();
  const { setTempHipaaApproval, onConfirmConsentAttest } = useManageChanges(onChange, persistAnswers);

  useEffect(() => {
    if (isPresent(answers['customer.person.hipaaConsentApproved'])) {
      setTempHipaaApproval(answers['customer.person.hipaaConsentApproved']);
    }
  }, []);

  const hipaaApproved = answers['customer.person.hipaaConsentApproved'];
  const hipaaConsentAttestOptions = React.useMemo(() => [{ label: 'Confirm', value: true }], []);
  const onHipaaConsentAttestChange = React.useCallback(
    (value: JsonCompatible) => {
      if (value) emitAnalyticsEventNoLimit('HIPAA Consented', { customerId, leadId });
      onConfirmConsentAttest(value);
    },
    [onConfirmConsentAttest, customerId, leadId],
  );

  return (
    <>
      {hipaaApproved === true && (
        <>
          <QuestionView
            id="customer.person.hipaaConsentAttest"
            questionText="As a HealthIQ employee, I attest that the client consented to the HIPAA disclosure.
              Failure to gather proper consent will result in disciplinary action"
            label="Select Yes or No"
            type={QuestionType.Radio}
            options={hipaaConsentAttestOptions}
            leadId={leadId}
            value={answers['customer.person.hipaaConsentAttest']}
            onChange={onHipaaConsentAttestChange}
          />
          <CheckMyRecordPullRecordButton />
        </>
      )}
      {answers['customer.person.hipaaConsentApproved'] === false && (
        <WarningBlockView>
          At this time I don&apos;t have any licensed insurance agents available. If you are still interested in reviewing your Medicare
          options please call our Precision Medicare VIP team at 800 950 6706 to speak to a licensed insurance agent
        </WarningBlockView>
      )}
    </>
  );
};
