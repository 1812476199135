import * as React from 'react';
import { CircularProgress } from '@material-ui/core';
import { useCsgApplicationSubmit } from './useCsgApplicationSubmit';
import { PrimaryButton } from '../../inputs/PrimaryButton';
import { CsgSubmitProps } from './Submit.interfaces';
import { useLeadIdRoute } from '../../../routers/LeadRouter/useLeadIdRoute';
import { useLeadQuery, useCanSubmitEappQuery } from '../../../../graphql';
import { config } from '../../../../config';
import { Alert } from '@material-ui/lab';
import { logger } from '../../../../services';

export const CsgSubmit: React.FunctionComponent<CsgSubmitProps> = () => {
  const [leadId] = useLeadIdRoute();
  const { data: leadData } = useLeadQuery();
  const { data: canSubmitData } = useCanSubmitEappQuery(leadId);

  const [submit, id, loading, error] = useCsgApplicationSubmit(leadData);

  const applicationId = leadData?.questionFlowAnswers?.csg?.application_id ?? id;
  const submitted = leadData?.questionFlowAnswers?.csg?.submitted ?? !!id;
  const url = applicationId && `${config.CSG.EAPP_HOST}/applications/${applicationId}`;
  const disabled = !(canSubmitData?.canSubmit?.canSubmit ?? false);

  const redirectToCsg = React.useCallback(() => {
    if (url) {
      window.open(url, '_blank');
    }
  }, [url]);
  let alertMessage:string;
  if (error) {
    logger.error('Error while submitting CSG application', { error });
    alertMessage = 'Error while submitting CSG application';
  } else if (id) {
    alertMessage = 'CSG application submitted successfully';
  }

  return (
    <div>
      {
        alertMessage && <Alert severity={error ? 'error' : 'success'}>{alertMessage}</Alert>
      }
      <PrimaryButton
        disabled={loading || disabled}
        onClick={() => (!submitted ? submit() : redirectToCsg())}
        variant={submitted ? 'outlined' : 'contained'}
        fullWidth
      >
        { !url ? 'Submit' : 'Continue on CSG' }
        { loading && <CircularProgress color="secondary" size={16} style={{ marginLeft: 10 }} /> }
      </PrimaryButton>
    </div>
  );
};
