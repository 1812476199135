import * as React from 'react';
import { PrimaryButtonProps } from './PrimaryButton.interfaces';
import { Button } from '@material-ui/core';
import { PrimaryButtonLink } from './PrimaryButton.elements';

export const PrimaryButton: React.FunctionComponent<PrimaryButtonProps> = ({
  children,
  disabled,
  variant,
  size,
  fullWidth,
  onClick,
  externalHref,
  ...extraProps
}) => (
  <Button
    color="primary"
    disabled={disabled}
    variant={variant}
    size={size}
    fullWidth={fullWidth}
    onClick={onClick}
    {...extraProps}
  >
    { externalHref ? <PrimaryButtonLink href={externalHref} target="_blank" rel="noreferrer">{children}</PrimaryButtonLink> : children }
  </Button>
);
