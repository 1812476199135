import { useCallback } from 'react';
import { JsonCompatible } from '@healthiqeng/core.types';
import {
  FormAnswers,
  FormRecord,
  FormRecordItem,
  FormFieldsStatus,
  SimpleCompareRuleProperties,
  CompositeCompareRuleProperties,
  CompareRuleProperties,
  ComposableOperator,
  FormItemTag,
} from '@hiq/crm.types';
import { isVisible } from '@hiq/crm.core.util.is-visible';

const isRequired = (tags: string[]): boolean => tags.some((tag) => tag?.startsWith('REQUIRE'));

const isRequiredForNavigation = (tags: string[]): boolean => tags.some((tag) => tag === FormItemTag.RequiredToNavigate);

const hasBeenFilled = (value: JsonCompatible) => {
  switch (typeof value) {
    case 'string':
      return value.trim() !== '';
    case 'undefined':
      return false;
    case 'object':
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return value !== null;
    default:
      return true;
  }
};

// The visibility value can be a boolean value. In these cases
// the value is often defined as 'Yes' or 'No' in the form definition
// so we need to convert it to be able to determine visibility.
// We need to be careful about this, since it may prove to be a fragile
// logic to determine the type of value for the field.
const normalizeValue = (value: any): any => {
  if (typeof value === 'string') {
    if (value.toLowerCase() === 'yes') return true;
    if (value.toLowerCase() === 'no') return false;
  }

  return value;
};

const isElementVisible = (
  answers: FormAnswers,
  questionId: string,
  compareRule?: CompareRuleProperties,
  parentQuestionId?: string,
): boolean => {
  if (!compareRule) {
    return true;
  }

  // If visibility condition is a composite rule...
  if (compareRule.hasOwnProperty('operands')) { // eslint-disable-line no-prototype-builtins
    const { operands, composableOperator } = compareRule as CompositeCompareRuleProperties;
    const results = operands.map((cmp) => isElementVisible(
      answers, questionId, cmp, parentQuestionId,
    ));
    switch (composableOperator) {
      case ComposableOperator.And:
        return results.every(Boolean);
      case ComposableOperator.Or:
        return results.some(Boolean);
      default:
        return true;
    }
  }

  const simpleCompareRule = compareRule as SimpleCompareRuleProperties;
  const value: any = normalizeValue(answers[questionId]);
  const compareQuestionValue: any = normalizeValue(answers[simpleCompareRule?.compareQuestionId]);
  const parentValue: any = normalizeValue(answers[parentQuestionId]);

  return isVisible(
    value,
    parentValue,
    simpleCompareRule,
    compareQuestionValue,
  );
};

export const reduceIntoFormStatus = (
  answers: FormAnswers,
  items: FormRecordItem[] = [],
  parentQuestionId?: string,
  isParentVisible = true,
): FormFieldsStatus => items.reduce((
  reduction: Partial<FormFieldsStatus>,
  field: FormRecordItem,
) => {
  const value = answers[field.questionId];
  const visible = isParentVisible && isElementVisible(answers, field.questionId, field?.visibleCondition, parentQuestionId);
  const valid = !isParentVisible || getIsFieldValid(field, visible, answers);
  const validNav = !isParentVisible || getIsFieldValidForNavigation(field, visible, answers);
  const isChildrenVisible = isParentVisible
    && isElementVisible(answers, field.questionId, field?.childrenVisibleCondition, parentQuestionId);
  const children = reduceIntoFormStatus(answers, field.children, field.questionId, visible && isChildrenVisible);

  return {
    ...reduction,
    [field.questionId]: {
      value, valid, validNav, label: field.label,
    },
    ...children,
  };
}, {});

const createFormStatus = (answers: FormAnswers, form?: Partial<FormRecord>): FormFieldsStatus => reduceIntoFormStatus(answers, form?.items);

export const useFormStatus = (form?: Partial<FormRecord>) => useCallback((answers: FormAnswers) => createFormStatus(answers, form), [form]);

function getIsFieldValid(field: FormRecordItem, visible: boolean, answers: FormAnswers): boolean {
  if (!visible) return true;
  if (field.tags.includes(FormItemTag.ApplicationDenied)) return false;
  if (isRequired(field.tags)) return hasBeenFilled(answers[field.questionId]);
  return true;
}

function getIsFieldValidForNavigation(field: FormRecordItem, visible: boolean, answers: FormAnswers): boolean {
  if (!visible) return true;
  if (field.tags.includes(FormItemTag.ApplicationDenied)) return false;
  if (isRequiredForNavigation(field.tags)) return hasBeenFilled(answers[field.questionId]);
  return true;
}
