import React from 'react';
import { CyclePlansProps } from '../TRMQuote.interfaces';
import { LinkText } from '../../SunfireIframe.elements';

const CyclePlans = ({ children, disabled, onClick }: CyclePlansProps) => (
  <LinkText onClick={!disabled ? onClick : () => {}} style={{ opacity: disabled ? 0.3 : 1 }}>
    {children}
  </LinkText>
);

export default CyclePlans;
