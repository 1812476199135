import { useCurrentUserQuery } from '../../../../graphql/queries/useCurrentUserQuery';
import { useLeadQuery } from '../../../../graphql';

export function useCurrentUserIsLeadOwner(leadId: string) {
  const { data: leadData } = useLeadQuery(leadId);
  const { data: userData } = useCurrentUserQuery();

  return {
    isOwner: leadData?.owner && leadData?.owner.id === userData?.currentUser?.id,
    owner: leadData?.owner,
  };
}
