import * as React from 'react';
import { QuotePresentationType } from '@hiq/crm.types';
import { formatCurrencyTruncated } from '@healthiqeng/core.util.format-currency-truncated';
import { MedicareDentalQuoteCellProps } from './MedicareDentalQuoteCell.interfaces';
import { QuoteCellLayout } from '../QuoteCellLayout';
import { QuoteCarrierInfo } from '../QuoteCarrierInfo';
import { QuoteDetails } from '../QuoteDetails';
import { QuoteRates } from '../QuoteRates';
import { QuoteSelectButton } from '../QuoteSelectButton';
import { QueryLoader } from '../../../QueryLoader';
import { useQuoteCell } from '../useQuoteCell';
import { QuotePin } from '../QuotePin';

export const MedicareDentalQuoteCell: React.FunctionComponent<MedicareDentalQuoteCellProps> = ({
  hash,
  onPin,
  isPinned,
}) => {
  const {
    quote,
    handleSelectClick,
    isSelected,
    loading,
    error,
  } = useQuoteCell(hash);
  return (
    <QueryLoader loading={loading} error={error} componentName="MedicareDentalQuoteCell">
      <QuoteCellLayout
        pinColumn={onPin && <QuotePin onPin={onPin} isPinned={isPinned} />}
        carrierColumn={(
          <QuoteCarrierInfo
            parentCompanyName={quote?.product?.carrier?.name}
            companyName={quote?.product?.carrierSubsidiary?.name}
            amBestRating={quote?.product?.carrierSubsidiary?.details?.amBestRating}
            spRating={quote?.product?.carrierSubsidiary?.details?.spRating}
            yearsInMarket={quote?.product?.carrierSubsidiary?.details?.yearsInMarket}
          />
        )}
        detailColumn={(
          <QuoteDetails
            items={[
              { label: 'Plan Name', value: quote?.planName },
              { label: 'Policy Fee', value: formatCurrencyTruncated(quote?.premium?.policyFee?.value, false, false) },
            ]}
          />
        )}
        premiumColumn={(
          <QuoteRates
            rateMonthly={quote?.premium?.monthly?.value}
            rateAnnually={quote?.premium?.annually?.value}
          />
        )}
        selectColumn={(
          <QuoteSelectButton
            allowSelect={quote?.presentationType !== QuotePresentationType.Competition}
            isSelected={isSelected}
            onClick={handleSelectClick}
          />
        )}
      />
    </QueryLoader>
  );
};
