import { useLocation } from '@reach/router';
import { parse } from 'query-string';
import moment from 'moment';
import { InitialContactCallData } from '../useInitialContactRedirect.interfaces';

export function useUrlCallData(): InitialContactCallData {
  const location = useLocation();
  return parseCallDetailsFromUrl(location.search);
}

function parseCallDetailsFromUrl(urlString: string): InitialContactCallData {
  const urlParams = parse(urlString, { parseBooleans: true });
  return {
    ...urlParams,
    startTimestamp: parseFive9CallStartTimestamp(urlParams?.startTimestamp as string),
  } as any as InitialContactCallData;
}

function parseFive9CallStartTimestamp(timestamp: string): Date | undefined {
  if (!timestamp) return undefined;
  const m = moment(timestamp, 'YYYYMMDDHHmmss');
  return m.isValid() ? new Date(m?.toISOString()) : undefined;
}
