import * as React from 'react';
import fetch from 'isomorphic-fetch';
import { UrlService, urlService } from '@healthiqeng/core.services.url';
import { HiqCrmClientRest } from '@healthiqeng/core.clients.hiq-crm-rest';
import { decodeBase64Url } from '@healthiqeng/core.util.base64-url';
import { logger } from '../../../../services';
import { config } from '../../../../config';
import { OAuthCallbackHandlerProps } from './OAuthCallbackHandler.interfaces';

export const OAuthCallbackHandler: React.FunctionComponent<OAuthCallbackHandlerProps> = ({
  internalAuthService,
  appId,
}) => {
  const url = urlService.getUrl();
  const oauthError = url.getParam('error');
  const [message, setMessage] = React.useState<string>(oauthError
    ? `Could not authorize: ${oauthError}`
    : 'Redirecting...');

  const state = url.getParam('state') && decodeBase64Url(url.getParam('state'));
  const code = url.getParam('code');
  const scope = url.getParam('scope');

  React.useEffect(() => {
    const authorize = async () => {
      const token = await internalAuthService.getIdToken();
      const hiqCrmClientRest = new HiqCrmClientRest(config.HIQ_CRM.HOST, fetch, token);
      await hiqCrmClientRest.authorize({ appId, code, scope });
      if (UrlService.isValid(state)) {
        window.location.href = state;
      } else {
        setMessage('Success');
      }
    };
    if (oauthError) return;
    authorize().catch((err) => {
      setMessage(err.message);
      logger.crit(err);
    });
  }, []);

  return (
    <>
      <div>
        { message }
      </div>

      { UrlService.isValid(state) && (
        <a href={state}>Return to original page</a>
      ) }
    </>
  );
};
