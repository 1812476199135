import * as React from 'react';
import { FormFieldsOnChangeHandler } from '@hiq/crm.types';
import { MarkdownReferenceType, parseReference, MarkdownReferenceSubType } from '@hiq/crm.core.util.parse-markdown-references';
import { ReferenceRendererProps } from './ReferenceRenderer.interfaces';
import { FormGenerator, FormGeneratorStyle } from '../../../FormGenerator';
import { QuestionFlowComponent } from '../../QuestionFlow.interfaces';

// Need to use dependency injection on QuestionFlow to prevent dependency cycle
export const referenceRendererFactory = (
  leadId: string,
  QuestionFlow: QuestionFlowComponent,
  CustomElement: React.FunctionComponent<any>,
  onAnswersChange?: FormFieldsOnChangeHandler,
) => {
  const ReferenceRenderer: React.FunctionComponent<ReferenceRendererProps> = ({ value }) => {
    const { type, referenceId, subType } = parseReference(value);
    const defaultRender = <div>{ `${type}:${referenceId} not implemented` }</div>;
    switch (type) {
      case MarkdownReferenceType.Partial:
        return <QuestionFlow leadId={leadId} referenceId={referenceId} />;
      case MarkdownReferenceType.Form:
        return (
          <FormGenerator
            leadId={leadId}
            referenceId={referenceId}
            style={getFormGeneratorStyle(subType)}
            lockable={getLockable(subType)}
            onFieldsChange={onAnswersChange}
          />
        );
      case MarkdownReferenceType.Custom:
        return (
          <CustomElement
            referenceId={referenceId}
            subType={subType}
            defaultRender={defaultRender}
          />
        );
      default:
        return defaultRender;
    }
  };
  return ReferenceRenderer;
};

function getFormGeneratorStyle(subType: MarkdownReferenceSubType) {
  switch (subType) {
    case MarkdownReferenceSubType.Compact:
      return FormGeneratorStyle.Compact;
    case MarkdownReferenceSubType.ReadOnly:
      return FormGeneratorStyle.ReadOnly;
    default:
      return FormGeneratorStyle.QuestionFlow;
  }
}

function getLockable(subType: MarkdownReferenceSubType) {
  return subType === MarkdownReferenceSubType.Compact;
}
