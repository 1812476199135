import * as React from 'react';
import { LinkRendererProps } from './LinkRenderer.interfaces';
import {
  Anchor,
} from './LinkRenderer.elements';

export const LinkRenderer: React.FunctionComponent<LinkRendererProps> = ({
  href,
  children,
}) => (
  <Anchor href={href} target="_blank" rel="nofollow noreferrer noopener">
    { children }
  </Anchor>
);
