import { EventBusToken } from '@healthiqeng/core.infrastructure';
import { throttle } from 'throttle-debounce';
import { withDi } from '../../DiReactContext';
import { AbstractAnchorDetector } from '../AbstractAnchorDetector';
import { AnchorReachedEvent } from './AnchorReachedEvent';
import { IReachAnchorDetectorProps } from './ReachAnchorDetector.interfaces';

class ReachAnchorDetector extends AbstractAnchorDetector<IReachAnchorDetectorProps, {}> {
  public REACHING_THROTTLE_DELAY = 150;
  public reachedAnchors: {[anchorName: string]: boolean} = {};
  public eventBus = this.props.di.get(EventBusToken);

  protected onScroll = throttle(this.REACHING_THROTTLE_DELAY, () => {
    this.forEachVisibleAnchor((anchorName) => {
      if (!this.reachedAnchors[anchorName]) {
        this.reachedAnchors[anchorName] = true;
        this.eventBus.emit(new AnchorReachedEvent(anchorName));
      }
    });
  });
}
const ReachAnchorDetectorWithDi = withDi(ReachAnchorDetector);

export { ReachAnchorDetectorWithDi as ReachAnchorDetector };
