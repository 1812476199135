import styled from '@emotion/styled';
import StarIcon from '@material-ui/icons/Star';

export const AvailablePlansContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 90%;
  border-radius: 10px;
  padding: 15px;
`;

export const Paragraph = styled.p`
  margin-bottom: 10px;
  margin-top: 0;
`;

export const Bold = styled.span`
  font-weight: bold;
  font-size: 14px;

`;

export const PlanStarIcon = styled(StarIcon)`
  color: #7F8385 !important;
`;

export const RowContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

export const VerticalCenterContainer = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: row;
`;
