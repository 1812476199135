import { useEffect } from 'react';
import { ApolloError } from '@apollo/react-hooks';
import { addBreadcrumb } from '@sentry/gatsby';

interface LogSentryParams<T = any> {
  name: string;
  data: T;
  loading: boolean;
  error?: ApolloError;
  extra?: Object;
}

export function useLogSentry<T = any>({
  name, data, loading, error, extra = {},
}: LogSentryParams<T>) {
  useEffect(() => {
    if (!loading) {
      if (error) {
        addBreadcrumb({
          message: `Query/mutation error: ${name}`,
          data: {
            extra,
            error: {
              ...error,
            },
          },
          timestamp: new Date().getTime(),
        });
      } else if (data) {
        addBreadcrumb({
          message: `Query/mutation data: ${name}`,
          data: {
            extra,
            data,
          },
          timestamp: new Date().getTime(),
        });
      }
    } else {
      addBreadcrumb({
        message: `Query/mutation ${name} loading`,
        timestamp: new Date().getTime(),
      });
    }
  }, [data, loading, error, JSON.stringify(extra)]);
}
