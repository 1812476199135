import * as React from 'react';
import { RadioYesNoInputProps } from './RadioYesNoInput.interfaces';
import { RadioInput } from '../RadioInput';
import { CLIENT_REFUSED_OPTION, NOT_SPECIFIED_OPTION } from '../../../../constants';
import { QuestionOption } from '@hiq/crm.types';

export const RadioYesNoInput: React.FunctionComponent<RadioYesNoInputProps> = ({
  value,
  onChange,
  disabled,
  dataQa,
  allowClientRefused,
}) => {
  const options = React.useMemo(() => {
    let options: QuestionOption[] = [{ label: 'Yes', value: true }, { label: 'No', value: false }];
    if (allowClientRefused) {
      options = [...options, CLIENT_REFUSED_OPTION, NOT_SPECIFIED_OPTION];
    }
    return options;
  }, [allowClientRefused]);
  return (
    <RadioInput
      dataQa={dataQa}
      disabled={disabled}
      onChange={onChange}
      options={options}
      value={value}
    />
  );
};
