import { gql } from 'apollo-boost';

export const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    id
    profile
    roles
    email
    name {
      first
      last
      preferredFirstName
      fullFriendlyName
    }
    phone {
      number
    }
    inactive
    abTests
    salesforceId
    abValue
  }
`;
