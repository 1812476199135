import { useDebounce } from '@healthiqeng/core.hooks.use-debounce';
import React, { useCallback } from 'react';
import { Divider } from '../Divider';
import { useFormAnswers } from '../../../hooks';
import { CheckboxInput, identityTransformerFactory, TextInput } from '../inputs';

const questions = [
  'If I had a chance to pass through your state, where should I start?',
  'Are you taking any trips soon',
  'What are your weekend plans?',
  'Is that your grandkids in the background? How many do you have?',
  'What kind of exercises do you do to stay healthy?',
  'What\'s your advice on living a long healthy life?',
  'Since Part B premiums have increased this year, did you want to hear about plans that can help save you some money?',
  'What do you like most about your doctor?',
  'Would you like to learn how to save money on your healthcare costs?',
  'If you hear the TV, any good shows you\'ve been watching lately',
  'If they make some sort of military reference you can ask about what part they served in.',
  'What is your favorite recipe?',
  'What would you do differently if you knew back then what you know now?',
  'Other',
];
const options = questions.map((question) => ({ label: question, value: question }));
const checkboxesAnswerKey = 'call.medicarePreSaleQualifyingQuestions';
const inputAnswerKey = 'call.medicarePreSaleQualifyingQuestionsNotes';

export const MedicarePreSaleQualifyingQuestions = () => {
  const [answers, saveFormAnswers] = useFormAnswers();

  const onCheckboxesChange = useCallback((answers) => {
    saveFormAnswers(checkboxesAnswerKey, answers);
  }, [saveFormAnswers]);
  const onNotesChange = useCallback((content) => {
    saveFormAnswers(inputAnswerKey, content);
  }, [saveFormAnswers]);

  const onCheckboxesChangeDebounced = useDebounce(onCheckboxesChange, 800);
  const onNotesChangeDebounced = useDebounce(onNotesChange, 800);

  return (
    <div>
      <Divider />
      <h2>Rapport Building Suggestions</h2>
      <p>Please select at least one that you used on the call.</p>
      <CheckboxInput
        options={options}
        onChange={onCheckboxesChangeDebounced}
        value={answers[checkboxesAnswerKey] ?? []}
      />
      <TextInput
        label="Notes/Writein"
        value={answers[inputAnswerKey]}
        onChange={onNotesChangeDebounced}
        transformerFactory={identityTransformerFactory}
        multiline
        fullWidth
        rows={4}
      />
    </div>
  );
};
