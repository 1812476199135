import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../../../../../themes';

export const QuestionViewContainer = styled('div')(css``);

export const QuestionTextContainer = styled('div')(css`
  display: flex;
`);
export const QuestionText = styled('div')(css`
  flex: 1;
  font-size: 16px;
  max-width: 100%;
`);
export const QuestionTagsContainer = styled('div')(css`
  width: 15px;
`);

export const QuestionHelpText = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  margin: 0;
  font-size: 12px;
  color: ${theme.color.grayDark};
  & > p {
    margin: -12px 0 0 0;
  }
`);
