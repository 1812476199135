import { gql } from 'apollo-boost';
import { CUSTOMER_FRAGMENT } from '../../fragments';
import { MutationCustomReturn, useMutationCustom } from '../useMutationCustom';
import { RefreshPrequalificationMutationResponse, RefreshPrequalificationMutationVariables } from './useRefreshPrequalificationMutation.interfaces';

export const REFRESH_PREQUALIFICATION_MUTATION = gql`
  mutation RefreshPrequalification($customerId: ID!) {
    refreshPrequalification(customerId: $customerId) {
      code
      success
      message
      timestamp
      customer {
        ...CustomerFragment
      }
    }
  }
  ${CUSTOMER_FRAGMENT}
`;

export function useRefreshPrequalificationMutation(): MutationCustomReturn<
RefreshPrequalificationMutationResponse,
RefreshPrequalificationMutationVariables> {
  return useMutationCustom<
  RefreshPrequalificationMutationResponse,
  RefreshPrequalificationMutationVariables>(REFRESH_PREQUALIFICATION_MUTATION);
}
