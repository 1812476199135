import React from 'react';
import { CompareContext } from '../../CompareContext/CompareContext';
import {
  ResetButton, Column, ResetTitle,
} from '../../TRMQuote.elements';

export const ResetFilters = () => {
  const { resetFilters } = React.useContext(CompareContext);
  return (
    <Column>
      <ResetTitle>
        No plans match your filters

      </ResetTitle>
      <span>
        Remove one or more filters to see available plans
      </span>
      <ResetButton onClick={resetFilters}>
        Reset Filters
      </ResetButton>
    </Column>
  );
};
