import React from 'react';
import { CompareSection } from '../../CompareSection';
import { PlanSelectionPlan } from '@hiq/crm.types';
import { ComparePrice } from '../ComparePrice';
import { CompareCoinCopay } from '../CompareCoinCopay';
import { CompareCoverageGap } from '../CompareCoverageGap/CompareCoverageGap';

type RequiredBenefitsProps = {
  currentPlan: PlanSelectionPlan;
  currentComparisonPlan: PlanSelectionPlan;
  onToggle: () => void;
};
export const RequiredBenefits = ({ currentPlan, currentComparisonPlan, onToggle }: RequiredBenefitsProps) => (
  <CompareSection
    title="Required Benefits"
    currentPlanRank={currentPlan?.hiqOopCostsResults?.oopCostsRank}
    comparisonPlanRank={currentComparisonPlan?.hiqOopCostsResults?.oopCostsRank}
    onToggle={onToggle}
    isOpen={false}
    padding="18px"
    fontSize="16px"
  >
    <ComparePrice
      benefitName="Medical Deductible"
      comparisonValue={currentComparisonPlan?.hiqOopCostsResults?.drugDeductible}
      currentValue={currentPlan?.hiqOopCostsResults?.drugDeductible}
    />
    <ComparePrice
      benefitName="Annual Deductible"
      comparisonValue={currentComparisonPlan?.hiqOopCostsResults?.costDetails?.deductibles?.annualDeductible}
      currentValue={currentPlan?.hiqOopCostsResults?.costDetails?.deductibles?.annualDeductible}
    />
    <ComparePrice
      benefitName="In Network Deductible"
      comparisonValue={currentComparisonPlan?.hiqOopCostsResults?.costDetails?.deductibles?.innDeductible}
      currentValue={currentPlan?.hiqOopCostsResults?.costDetails?.deductibles?.innDeductible}
    />
    <ComparePrice
      benefitName="Combined Deductible"
      comparisonValue={currentComparisonPlan?.hiqOopCostsResults?.costDetails?.deductibles?.combinedDeductible}
      currentValue={currentPlan?.hiqOopCostsResults?.costDetails?.deductibles?.combinedDeductible}
    />
    <ComparePrice
      benefitName="Out-of-network maximum out-of-pocket"
      comparisonValue={currentComparisonPlan?.hiqOopCostsResults?.outOfNetworkMoop}
      currentValue={currentPlan?.hiqOopCostsResults?.outOfNetworkMoop}
    />
    <ComparePrice
      benefitName="In-network maximum out-of-pocket"
      currentValue={currentPlan?.hiqOopCostsResults?.costDetails?.innMoop?.value}
      comparisonValue={currentComparisonPlan?.hiqOopCostsResults?.costDetails?.innMoop?.value}
    />
    <ComparePrice
      benefitName="Combined maximum out-of-pocket"
      comparisonValue={currentComparisonPlan?.hiqOopCostsResults?.combinedMoop}
      currentValue={currentPlan?.hiqOopCostsResults?.combinedMoop}
    />
    <CompareCoinCopay
      name="PCP"
      currentComparisonPlan={currentComparisonPlan?.hiqOopCostsResults?.costDetails?.pcp}
      currentPlan={currentPlan?.hiqOopCostsResults?.costDetails?.pcp}
    />
    <CompareCoinCopay
      name="Specialist"
      currentComparisonPlan={currentComparisonPlan?.hiqOopCostsResults?.costDetails?.specialist}
      currentPlan={currentPlan?.hiqOopCostsResults?.costDetails?.specialist}
    />
    <CompareCoinCopay
      name="Labs"
      currentComparisonPlan={currentComparisonPlan?.hiqOopCostsResults?.costDetails?.labs}
      currentPlan={currentPlan?.hiqOopCostsResults?.costDetails?.labs}
    />
    <CompareCoinCopay
      name="Diagnostic Radiology"
      currentComparisonPlan={currentComparisonPlan?.hiqOopCostsResults?.costDetails?.diagnosticRadiology}
      currentPlan={currentPlan?.hiqOopCostsResults?.costDetails?.diagnosticRadiology}
    />
    <ComparePrice
      benefitName="Inpatient Hospital"
      avoidHighlight
      comparisonValue={currentComparisonPlan?.hiqOopCostsResults?.costDetails?.inpatientHospital?.detail}
      currentValue={currentPlan?.hiqOopCostsResults?.costDetails?.inpatientHospital?.detail}
      avoidFormat
    />
    <CompareCoinCopay
      name="Outpatient"
      currentComparisonPlan={currentComparisonPlan?.hiqOopCostsResults?.costDetails?.outpatientHospital}
      currentPlan={currentPlan?.hiqOopCostsResults?.costDetails?.outpatientHospital}
    />
    <CompareCoinCopay
      name="Ambulatory Surgical Center"
      currentComparisonPlan={currentComparisonPlan?.hiqOopCostsResults?.costDetails?.ambulatorySurgicalCenter}
      currentPlan={currentPlan?.hiqOopCostsResults?.costDetails?.ambulatorySurgicalCenter}
    />
    <CompareCoinCopay
      name="Emergency Room"
      currentComparisonPlan={currentComparisonPlan?.hiqOopCostsResults?.costDetails?.emergencyRoom}
      currentPlan={currentPlan?.hiqOopCostsResults?.costDetails?.emergencyRoom}
    />
    <ComparePrice
      benefitName="Rx DED"
      currentValue={currentPlan?.hiqOopCostsResults?.costDetails?.rxDed?.value}
      comparisonValue={currentComparisonPlan?.hiqOopCostsResults?.costDetails?.rxDed?.value}
    />
    <CompareCoverageGap
      comparisonPlanTier={currentComparisonPlan?.hiqOopCostsResults?.costDetails?.coverageGap?.tiers}
      currentPlanTier={currentPlan?.hiqOopCostsResults?.costDetails?.coverageGap?.tiers}
    />
  </CompareSection>
);
