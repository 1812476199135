import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import {
  SelectedPharmaciesQueryData,
  SelectedPharmaciesQueryVariables,
} from './useSelectedPharmaciesQuery.interfaces';
import { PRECISION_MEDICARE_PHARMACY_FRAGMENT } from '../../fragments';

export const SELECTED_PHARMACIES_QUERY = gql`
  query SelectedPharmacies($leadId: LeadId!) {
    selectedPharmacies(leadId: $leadId) {
      ...PrecisionMedicarePharmacyFragment
    }
  }
  ${PRECISION_MEDICARE_PHARMACY_FRAGMENT}
`;

export function useSelectedPharmaciesQuery(leadId: string) {
  return useQuery<SelectedPharmaciesQueryData, SelectedPharmaciesQueryVariables>(SELECTED_PHARMACIES_QUERY, {
    variables: { leadId },
  });
}
