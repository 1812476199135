import React, {
  SyntheticEvent, useEffect, useMemo, useRef, useState,
} from 'react';
import { useDebounce } from '@healthiqeng/core.hooks.use-debounce';
import { Autocomplete } from '@material-ui/lab';
import { CircularProgress, TextField } from '@material-ui/core';
import { useSunfireV2PharmaciesByTermQuery } from '../../../../../../graphql';
import { useActiveLeadId } from '../../../../../routers';
import { PharmacySearchPanelProps } from './PharmacySearchPanel.interfaces';
import { LocationDetail, Panel } from '../../AddDoctor/DoctorSearchPanel/DoctorSearchPanel.elements';
import { LocationButton } from '../../LocationSearchPanel/LocationSearchPanel.elements';
import { QueryLoader } from '../../../../QueryLoader';
import { OptionLabel, OptionLabelSubtitle } from './PharmacySearchPanel.elements';

export const PharmacySearchPanel = ({ onPharmacyChanged, location, onLocationChanged }: PharmacySearchPanelProps) => {
  const locationString = ` ${location.county} County, ${location.city}, ${location.state} `;
  const [pharmacyName, setPharmacyName] = useState('');
  const [pharmacyOptions, setPharmacyOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const leadId = useActiveLeadId();
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    inputRef.current.focus();
  }, []);
  const [
    findSunfirePharmaciesByTerm,
    {
      data: pharmacyData, loading, error,
    },
  ] = useSunfireV2PharmaciesByTermQuery();

  const updateDropDown = (pharmacyName: string) => () => {
    findSunfirePharmaciesByTerm({
      variables: {
        leadId,
        term: pharmacyName,
        zipcode: location.zip,
      },
    });
  };

  const memoizedUpdateDropDown = useMemo(() => updateDropDown(pharmacyName), [pharmacyName]);

  const debouncedUpdated = useDebounce(memoizedUpdateDropDown, 2000);

  useEffect(() => {
    if (pharmacyName && pharmacyName.length > 1) debouncedUpdated();
  }, [pharmacyName]);

  const handleOnPharmacyChange = (event: SyntheticEvent, value: string, reason: string) => {
    setPharmacyName(value);
    debouncedUpdated();

    if (reason === 'input') {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  const handlePharmacySelected = (event: SyntheticEvent, value: any, reason: string) => {
    if (reason === 'select-option') {
      onPharmacyChanged(value.pharmacy);
    }
  };

  useEffect(() => {
    if (pharmacyData?.findSunfirePharmaciesByTerm?.length > 0) {
      const pharmacies = pharmacyData?.findSunfirePharmaciesByTerm;
      const pharmacyOptionsMap = pharmacies.map((pharmacy) => {
        const {
          name, address1, address2, city, state, zip, distance,
        } = pharmacy;
        const fullAddress = [address1, address2, city, state, zip].filter(((str) => str)).join(', ');
        return {
          label: name,
          pharmacy,
          address: `${fullAddress}. Distance: ${distance}mi`,
        };
      });
      setPharmacyOptions(pharmacyOptionsMap);
    }
  }, [pharmacyData]);

  return (
    <Panel>
      <div>
        Pharmacies near
        <LocationDetail>{locationString}</LocationDetail>
        <LocationButton
          onClick={() => onLocationChanged(undefined)}
        >
          Change Location
        </LocationButton>
      </div>
      <div>What is your pharmacy&apos;s name</div>
      <QueryLoader loading={false} componentName="PharmacySearchPanel" error={error}>
        <Autocomplete
          inputValue={pharmacyName}
          onInputChange={handleOnPharmacyChange}
          onChange={handlePharmacySelected}
          options={pharmacyOptions}
          getOptionLabel={(option) => option.label}
          style={{ width: 500 }}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Pharmacy"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                inputRef,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          renderOption={(option) => (
            <div>
              <OptionLabel>{option.label}</OptionLabel>
              <OptionLabelSubtitle>{option.address}</OptionLabelSubtitle>
            </div>
          )}
          open={pharmacyName.length > 2 && pharmacyOptions.length > 0 && isOpen}
        />
      </QueryLoader>
    </Panel>
  );
};
