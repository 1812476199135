import React from 'react';
import { CompareContext } from '../CompareContext/CompareContext';
import { Compare } from '../Compare';
import { PlanAllBenefitDetails } from '@hiq/crm.types';
import { CompareSection } from '../CompareSection';
import { CompareContainer } from '../TRMQuote.elements';

const DETAIL_BLACKLIST = ['[', 'N/A', 'Max Coverage'];

const CompareGrid = ({ featureKey, title }: { featureKey: string, title: string }) => {
  const {
    currentPlan,
    currentComparisonPlan,
    totalPlans,
    emitPlanComparisonEvent,
  } = React.useContext(CompareContext);

  const onToggle = React.useCallback(() => emitPlanComparisonEvent('Opened Disease', { featureKey }),
    [emitPlanComparisonEvent, featureKey]);

  const currentPlanBenefit = currentPlan?.hiqDiagnosesResults?.diagnoses?.find(
    ({ hiqDiseaseKey }) => hiqDiseaseKey === featureKey,
  );

  const currentComparisonPlanBenefit = currentComparisonPlan?.hiqDiagnosesResults?.diagnoses.find(
    ({ hiqDiseaseKey }) => hiqDiseaseKey === featureKey,
  );

  return (
    <CompareContainer>
      <CompareSection
        title={`${title}-Related Benefits`}
        currentPlanRank={currentPlanBenefit?.hiqRank}
        comparisonPlanRank={currentComparisonPlanBenefit?.hiqRank}
        onToggle={onToggle}
      >
        {currentComparisonPlanBenefit?.allBenefitDetails.map((feature) => {
          const currentBenefit = currentPlanBenefit?.allBenefitDetails.find(
            (currentFeature) => currentFeature.benefitAndDetailKey === feature.benefitAndDetailKey,
          );
          const getFeatureText = (feature: PlanAllBenefitDetails) => {
            if (feature && feature.detailSimple && !DETAIL_BLACKLIST.some(
              (detail) => feature.detailSimple?.includes(detail),
            )) {
              return feature.detailSimple;
            }
            return feature?.detail;
          };
          // if coverage period is not defined on the data, it's going to return as [coverage-period], so this should catch that
          if (DETAIL_BLACKLIST.some((detail) => feature.detailSimple?.includes(detail))) return <></>;
          return (
            <Compare
              benefitName={feature.displayName}
              currentValue={getFeatureText(currentBenefit)}
              currentValueRank={currentBenefit?.rank}
              comparisonValue={getFeatureText(feature)}
              comparisonValueRank={feature?.rank}
              hiqContext={feature.hiqContext}
              totalPlans={totalPlans}
              key={feature?.benefitAndDetailKey}
            />
          );
        })}

      </CompareSection>
    </CompareContainer>
  );
};

export default CompareGrid;
