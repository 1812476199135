import * as React from 'react';
import { useDialerService, captureDialerException } from '@healthiqeng/core.hooks.use-dialer-service';
import {
  FollowUpSchedulerContainer,
  FollowUpSchedulerHeader,
  FollowUpInputContainer,
  FollowUpListHeader, FollowUpNoTimeAvailableHeader,
} from './FollowUpScheduler.elements';
import { InputFactory } from '../../../common/inputs/InputFactory';
import { FollowUpTimeFrame, QuestionType, DispositionTypes } from '@hiq/crm.types';
import { useFollowUpAvailabilityLazyQuery, useLeadFollowUpQuery, useScheduleFollowUpMutation } from '../../../../graphql';
import { followUpDurationOptions } from './FollowUpScheduler.constants';
import { QueryLoader } from '../../../common/QueryLoader';
import { AvailabilityBlock } from './AvailabilityBlock';
import { useActiveLeadId } from '../../../routers';
import { emitAnalyticsEvent } from '../../../../events/emitAnalyticsEvent';
import { useDialerTransfer } from '../../../common/DialerTransferButtons/useDialerTransferButtons/useDialerTransfer';
import { useFormAnswers, useHighlightSection } from '../../../../hooks';
import moment from 'moment';
import { GenericModal } from '../../../common/GenericModal';
import { DispositionModalContent } from '../../../common/DispositionModal';
import { useMostRecentAppointmentsQuery } from '../../../../graphql/queries/useMostRecentAppointmentsQuery';

export const FollowUpScheduler: React.FunctionComponent = () => {
  const leadId = useActiveLeadId();
  const [date, setDate] = React.useState(null);
  const [time, setTime] = React.useState(null);
  const [followUpDuration, setFollowUpDuration] = React.useState<number>(followUpDurationOptions[2].value);
  const [calendarEventNotes, setCalendarEventNotes] = React.useState('');
  const [queryAvailability, { data, loading, error }] = useFollowUpAvailabilityLazyQuery();
  const [scheduleFollowUp, { loading: scheduling }] = useScheduleFollowUpMutation(leadId);
  const [answers, onChange] = useFormAnswers(leadId);
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [isExistingFollowUp, setIsExistingFollowUp] = React.useState(false);
  const { dialerService } = useDialerService();
  const {
    setTransferError,
    selectedDisposition,
  } = useDialerTransfer();
  const { data: followUpsInfo } = useLeadFollowUpQuery();
  const { cancelSectionHighlight } = useHighlightSection();
  const { data: appointments } = useMostRecentAppointmentsQuery();

  React.useEffect(() => {
    if (selectedDisposition) {
      const isExist = !!followUpsInfo.scheduledLeadFollowUps.length;
      const nextFollowUp = followUpsInfo.scheduledLeadFollowUps[0]?.start;
      if (isExist) {
        const tomorrowOrLater = moment(nextFollowUp).isAfter(moment(), 'D');
        if (tomorrowOrLater) {
          setModalOpen(true);
          setDate(nextFollowUp);
          setTime(nextFollowUp);
          setIsExistingFollowUp(true);
        }
      }
    }
  }, [selectedDisposition]);

  function removeSectionHighlight() {
    cancelSectionHighlight('side-panel-layout-tab-content-follow-up');
  }

  const maybeQueryAvailability = (currentDate: string, currentDuration: number) => {
    if (!currentDate || !currentDuration) return;
    queryAvailability({
      variables: {
        inputs: {
          date: currentDate,
          timezoneOffsetMinutes: (new Date()).getTimezoneOffset(),
          eventDuration: currentDuration,
        },
      },
    });
  };

  const handleDateChange = (value: string) => {
    setDate(value);
    maybeQueryAvailability(value, followUpDuration);
  };

  const handleDurationChange = (value: number) => {
    setFollowUpDuration(value);
    maybeQueryAvailability(date, value);
  };

  const handleAvailabilityClick = async (time: string) => {
    if (selectedDisposition) {
      setTime(time);
      setCalendarEventNotes('');
      setModalOpen(true);
    } else {
      await scheduleFollowUp({
        leadId,
        options: {
          time,
          eventDuration: followUpDuration,
          notes: calendarEventNotes,
          timeFrame: FollowUpTimeFrame.Exact,
        },
      });
      setDate(null);
    }
  };

  function handleClose() {
    setModalOpen(false);
    setDate(null);
    removeSectionHighlight();
  }

  async function handleSave() {
    if (!isExistingFollowUp) {
      await scheduleFollowUp({
        leadId,
        options: {
          time,
          eventDuration: followUpDuration,
          notes: calendarEventNotes,
          timeFrame: FollowUpTimeFrame.Exact,
        },
      });
    }
    await handleAgentDisposition();
    setModalOpen(false);
    setDate(null);
    removeSectionHighlight();
  }

  async function handleAgentDisposition() {
    try {
      await dialerService.endCall(selectedDisposition);
      emitAnalyticsEvent('Dispositioned Call', { disposition: selectedDisposition });
    } catch (err) {
      setTransferError(true);
      captureDialerException(err, 'endCall', dialerService.getCallData());
    }
  }

  return (
    <FollowUpSchedulerContainer>
      <FollowUpSchedulerHeader>
        Schedule Follow-up
      </FollowUpSchedulerHeader>
      <FollowUpInputContainer>
        <InputFactory
          type={QuestionType.DatePicker}
          value={date}
          onChange={handleDateChange}
          label="Follow-up Date"
          size="small"
          fullWidth
        />
      </FollowUpInputContainer>
      <FollowUpInputContainer>
        <InputFactory
          type={QuestionType.Select}
          value={followUpDuration}
          onChange={handleDurationChange}
          options={followUpDurationOptions}
          label="Meeting Duration"
          size="small"
          fullWidth
        />
      </FollowUpInputContainer>
      <FollowUpInputContainer>
        <InputFactory
          type={QuestionType.Email}
          value={answers['customer.person.email'] || ''}
          onChange={(val: string) => onChange('customer.person.email', val)}
          label="Email Address"
          size="small"
          fullWidth
        />
      </FollowUpInputContainer>
      <QueryLoader loading={loading || scheduling} error={error} componentName="FollowUpScheduler">
        {(!!data?.followUpAvailability?.length) && (
          <FollowUpListHeader>
            Available Calendar Times
          </FollowUpListHeader>
        )}
        {(date && !data?.followUpAvailability?.length) && (
          <FollowUpNoTimeAvailableHeader>
            There are no times available on your google calendar, check your google calendar for accuracy
          </FollowUpNoTimeAvailableHeader>
        )}
        {(date && data?.followUpAvailability
          ?.filter((block) => new Date(block.start) > new Date())
          .map((block) => (
            <AvailabilityBlock
              key={block.start}
              start={block.start}
              end={block.end}
              onClick={handleAvailabilityClick}
            />
          ))) ?? null}
      </QueryLoader>

      <GenericModal
        open={!!isModalOpen && !!selectedDisposition && selectedDisposition?.label === DispositionTypes.PIPELINE}
        headerText={selectedDisposition?.label}
        modalContent={(
          <DispositionModalContent
            contentText={`Appointment scheduled for ${date
              ? new Date(time).toLocaleDateString('en-US',
                {
                  weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric',
                })
              : ''}`}
            onClose={handleClose}
            onClickSave={handleSave}
            upcomingAppointments={appointments?.upcomingAgentAppointments?.filter(Boolean)}
            missedAppointments={appointments?.missedAgentAppointments?.filter(Boolean)}
          />
        )}
      />
    </FollowUpSchedulerContainer>
  );
};
