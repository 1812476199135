// eslint-disable-next-line import/no-extraneous-dependencies,@typescript-eslint/no-unused-vars
import React from 'react';

let backdropElement: Element | null = null;

export function useHighlightSection() {
  function cancelSectionHighlight(dataQa: string) {
    const cancelHighlightElement = getElementByDataQa(dataQa);
    removeBackdrop();
    cancelHighlightElement.classList.remove('highlightSection');
  }

  function getElementByDataQa(dataQa: string): Element {
    return document.querySelector(`[data-qa='${dataQa}']`);
  }

  function highlightSection({ dataQa, duration, onClickOutside } : HighlightSectionInterface) {
    const hightlightSectionSelector = getElementByDataQa(dataQa);

    addBackdrop(onClickOutside);

    hightlightSectionSelector.classList.add('highlightSection');
    hightlightSectionSelector.classList.remove('highlight');
    hightlightSectionSelector.classList.add('highlight');

    if (duration !== 0) {
      setTimeout(() => {
        cancelSectionHighlight(dataQa);
      }, duration);
    }
  }

  function removeBackdrop() {
    backdropElement?.remove();
    backdropElement = null;
  }

  function addBackdrop(onClickOutside?: () => void) {
    const backdrop = document.createElement('div');
    // eslint-disable-next-line
    onClickOutside && backdrop.addEventListener('click', onClickOutside);
    backdropElement = backdrop;
    backdrop.classList.add('highlightSectionBackdrop');
    document.body.appendChild(backdrop);
  }

  return {
    highlightSection,
    cancelSectionHighlight,
  };
}

interface HighlightSectionInterface {
  dataQa: string;
  duration?: number;
  onClickOutside?: () => void;
}
