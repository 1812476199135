import { ApolloError, gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  GetCustomerHealthRecordStatusQueryData, GetCustomerHealthRecordStatusQueryVariables,
} from './useGetCustomerHealthRecordStatusQuery.interface';
import { FetchPolicy } from '@apollo/client/core/watchQueryOptions';
import { useCallback } from 'react';
import { QueryTuple } from '@apollo/client/react/types/types';
import { getEventBus } from '../../../events';
import { MedDigitalAnalyticsEvent } from '../../../events/MedDigitalAnalyticsEvent';
import { HealthRecordStatusEventProperties } from '../../../events/MedDigitalAnalyticsEvent.interfaces';

const GET_CUSTOMER_HEALTH_RECORD_STATUS_QUERY = gql`
  query GetCustomerHealthRecordStatusQuery($customerId: CustomerId!) {
    customerHealthRecordStatus(customerId: $customerId) {
        success
        message
        errorKey
        recordPullStatus
        totalPrescriptions
        totalDoctors
        totalPharmacies
        totalDiagnoses
        totalProcedures
    }
  }
`;

export function useGetCustomerHealthRecordStatusQuery(fetchPolicy: FetchPolicy = 'network-only'):
QueryTuple<GetCustomerHealthRecordStatusQueryData, GetCustomerHealthRecordStatusQueryVariables> {
  const eventBus = getEventBus();

  const emitEvent = useCallback((message: string, properties: HealthRecordStatusEventProperties) => {
    eventBus.emit(new MedDigitalAnalyticsEvent(message, properties));
  }, [eventBus]);

  const onCompleted = useCallback((data: GetCustomerHealthRecordStatusQueryData) => emitEvent(
    'Success',
    {
      location: 'health_record_status',
      message: data.customerHealthRecordStatus.message,
      success: data.customerHealthRecordStatus.success,
    },
  ), [emitEvent]);

  const onError = useCallback((error: ApolloError) => emitEvent(
    'Error',
    {
      location: 'health_record_status',
      error: error.message,
    },
  ), [emitEvent]);

  const [getCustomerHealthRecordStatus, result] = useLazyQuery<GetCustomerHealthRecordStatusQueryData,
  GetCustomerHealthRecordStatusQueryVariables>(GET_CUSTOMER_HEALTH_RECORD_STATUS_QUERY, {
    fetchPolicy,
    onError,
    onCompleted,
  });

  const getCustomerHealthRecordStatusWrapped = useCallback(({ variables }: { variables: GetCustomerHealthRecordStatusQueryVariables }) => {
    emitEvent(
      'Request',
      {
        location: 'health_record_status',
      },
    );
    getCustomerHealthRecordStatus({ variables });
  }, [getCustomerHealthRecordStatus, emitEvent]);

  return [getCustomerHealthRecordStatusWrapped, result];
}
