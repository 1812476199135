import * as React from 'react';
import { useLeadAudiencesQuery } from '../../../graphql';
import { useActiveLeadId } from '../../routers';
import { ScriptBlock } from '../ScriptBlock';

export const AudienceDynamicScripts: React.FC = () => {
  const activeLeadId = useActiveLeadId();
  const leadAudiencesQueryResult = useLeadAudiencesQuery(activeLeadId);

  const audiences = leadAudiencesQueryResult?.data?.getAllAudiencesScriptsByLeadId;

  return <>{audiences?.length > 0 && audiences.map((audience) => <ScriptBlock key={audience.id}>{audience.script}</ScriptBlock>)}</>;
};
