import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../../themes';

interface CustomerCallComplianceContainerProps extends ComponentWithThemeProps {
  backgroundColor: string;
}

export const CustomerCallComplianceContainer = styled('div')<CustomerCallComplianceContainerProps>(({ backgroundColor }) => css`
  color: white;
  display: flex;
  padding: 4px;
  margin: 10px 0px;
  background-color: ${backgroundColor};
  border-radius: 25px;
`);

export const CustomerCallComplianceText = styled('span')(css`
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 14px;
  text-align: center;
  margin-left: calc(50% - 80px);
  margin-top: 2px;
`);
