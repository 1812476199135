import { IEventBusEvent } from '@healthiqeng/core.infrastructure/src/EventBus';
import { KEYBOARD_KEYS } from '../keys/KEYBOARD_KEYS';

export class KeyUpEvent implements IEventBusEvent {
  public type = 'KEY_UP';
  public timeStamp = Date.now();
  constructor(
    public readonly key: KEYBOARD_KEYS,
  ) { }
}
