import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { NewLeadContainer, QuestionContainer, ErrorContainer } from './NewLead.elements';
import { PrimaryButton } from '../../../common/inputs/PrimaryButton';
import { QuestionView } from '../../../common/FormGenerator/FormGeneratorQuestionFlow/Question/QuestionView';
import {
  FormItemTag,
  ProductType,
  ProductTypePropertyAndCasualty,
  ProductTypeMedicare,
  QuestionType,
  ProductTypeLife,
} from '@hiq/crm.types';
import { useCreateLeadMutation } from '../../../../graphql/mutations/useCreateLeadMutation';

export const NewLead: React.FunctionComponent = () => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [productType, setProductType] = React.useState<ProductType>(null);
  const [reason, setReason] = React.useState('');
  const [createLead, { data, loading, error }] = useCreateLeadMutation();
  React.useEffect(() => {
    if (data?.createLead?.leadId && data?.createLead?.customerId) {
      window.location.href = `/customer/${data?.createLead?.customerId}/lead/${data?.createLead?.leadId}`;
    }
  }, [data]);
  return (
    <>
      <NewLeadContainer>
        <PrimaryButton
          onClick={() => setModalOpen(true)}
          variant="contained"
          data-qa="create-lead-btn"
        >
          Create New Lead
        </PrimaryButton>
      </NewLeadContainer>
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        fullWidth
        data-qa="create-lead-modal"
      >
        <DialogTitle>
          Create New Lead
        </DialogTitle>
        <DialogContent>
          <QuestionContainer>
            <QuestionView
              id="phoneNumber"
              questionText="Phone Number"
              label="Phone Number"
              type={QuestionType.Phone}
              tags={[FormItemTag.RequiredToConvert]}
              leadId={null}
              value={phoneNumber}
              onChange={(value: string) => setPhoneNumber(value)}
              dataQa="create-lead-modal-phone"
            />
          </QuestionContainer>
          <QuestionContainer>
            <QuestionView
              id="productType"
              questionText="Product Type"
              label="Product Type"
              type={QuestionType.Select}
              tags={[FormItemTag.RequiredToConvert]}
              leadId={null}
              value={productType}
              onChange={(value: ProductType) => setProductType(value)}
              options={[
                { label: 'Medicare', value: ProductTypeMedicare.MedicareSupplement },
                { label: 'Vision', value: ProductTypeMedicare.Vision },
                { label: 'Dental', value: ProductTypeMedicare.Dental },
                { label: 'Auto', value: ProductTypePropertyAndCasualty.Auto },
                { label: 'Home', value: ProductTypePropertyAndCasualty.Home },
                { label: 'Renters', value: ProductTypePropertyAndCasualty.Renters },
                { label: 'Motorcycle', value: ProductTypePropertyAndCasualty.Motorcycle },
                { label: 'Umbrella', value: ProductTypePropertyAndCasualty.Umbrella },
                { label: 'Watercraft', value: ProductTypePropertyAndCasualty.Watercraft },
                { label: 'Condo', value: ProductTypePropertyAndCasualty.Condo },
                { label: 'Powersports', value: ProductTypePropertyAndCasualty.Powersports },
                { label: 'Landlord', value: ProductTypePropertyAndCasualty.Landlord },
                { label: 'Dwelling Fire', value: ProductTypePropertyAndCasualty.DwellingFire },
                { label: 'Earthquake', value: ProductTypePropertyAndCasualty.Earthquake },
                { label: 'Flood', value: ProductTypePropertyAndCasualty.Flood },
                { label: 'Mobile Home', value: ProductTypePropertyAndCasualty.MobileHome },
                { label: 'InlandMarine', value: ProductTypePropertyAndCasualty.InlandMarine },
                { label: 'Off-Road', value: ProductTypePropertyAndCasualty.OffRoad },
                { label: 'Motorhome/RV', value: ProductTypePropertyAndCasualty.MotorhomeRV },
                { label: 'Accidental Death', value: ProductTypeLife.Accidental },
                { label: 'Juvenile', value: ProductTypeLife.Juvenile },
                { label: 'Whole Life', value: ProductTypeLife.WholeLife },
                { label: 'Critical Illness', value: ProductTypeLife.CriticalIllness },
              ]}
              dataQa="create-lead-modal-product-type"
            />
          </QuestionContainer>
          <QuestionContainer>
            <QuestionView
              id="reason"
              questionText="Reason"
              label="Reason"
              type={QuestionType.Select}
              tags={[FormItemTag.RequiredToConvert]}
              leadId={null}
              value={reason}
              onChange={(value: string) => setReason(value)}
              options={[
                { label: 'Screen pop-up did not work', value: 'NO_SCREEN_POP' },
                { label: 'Test lead creation', value: 'TEST_LEAD_CREATION' },
                { label: 'Referral Customer', value: 'REFERRAL_CUSTOMER' },
                { label: 'Other', value: 'OTHER' },
              ]}
              dataQa="create-lead-modal-reason"
            />
          </QuestionContainer>
          <PrimaryButton
            onClick={() => createLead({ input: { phoneNumber, productType, reason } })}
            variant="contained"
            fullWidth
            disabled={!phoneNumber || !reason || !productType || loading || !!data?.createLead?.customerId}
            data-qa="create-lead-modal-button"
          >
            { loading || data?.createLead?.customerId ? 'Creating...' : 'Create New Lead'}
          </PrimaryButton>
          { error && (
            <ErrorContainer>
              { error.message }
            </ErrorContainer>
          ) }
        </DialogContent>
      </Dialog>
    </>
  );
};
