import { useEffect, useState } from 'react';
import { SalesTimerStatus } from './useSalesTimer.interfaces';

const TWENTY_MINUTES = 60 * 20;
const THIRTY_FIVE_MINUTES = 60 * 35;

export function useSalesTimer(): [string, SalesTimerStatus, number] {
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return [formatTimer(seconds), getTimerStatus(seconds), seconds];
}

export function formatTimer(totalSeconds: number): string {
  const hours: any = Math.floor(totalSeconds / 3600);
  let minutes: any = Math.floor((totalSeconds % 3600) / 60);
  let seconds: any = Math.round(totalSeconds % 60);

  if (minutes <= 9) {
    if (hours) {
      minutes = `0${minutes}`;
    } else {
      minutes = minutes || '0';
    }
  }

  if (seconds <= 9) {
    seconds = `0${seconds}`;
  }

  return [
    hours,
    minutes,
    seconds,
  ].filter(Boolean).join(':');
}

export function getTimerStatus(seconds: number): SalesTimerStatus {
  if (seconds >= THIRTY_FIVE_MINUTES) {
    return SalesTimerStatus.Error;
  }
  if (seconds >= TWENTY_MINUTES) {
    return SalesTimerStatus.Warning;
  }
  return SalesTimerStatus.Success;
}
