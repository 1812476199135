import { gql } from 'apollo-boost';
import { LEAD_FRAGMENT } from '../lead';
import { PERSON_FRAGMENT } from '../person';
import { QUIZ_RESULT_FRAGMENT } from '../quizResult';

export const CUSTOMER_FRAGMENT = gql`
  fragment CustomerFragment on Customer {
    id
    customerId
    hiqUserId
    tags
    showMedicareAdvantageTransition
    customerReferenceId
    tcpaConsent {
      status
    }
    quizResult {
      ...QuizResultFragment
    }
    calls {
      id
      direction
      timestamp
    }
    activityFeedId
    leads {
      ...LeadFragment
    }
    person {
      ...PersonFragment
    }
    dialerActivityId
    __typename
  }
  ${LEAD_FRAGMENT}
  ${PERSON_FRAGMENT}
  ${QUIZ_RESULT_FRAGMENT}
`;
