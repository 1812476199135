import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const QuoteNotesContainer = styled('div')(css`
  margin-top: 10px;
`);

export const QuoteNoteRow = styled('div')(css`
  display: flex;
  flex-direction: row;
`);

export const QuoteNoteIcon = styled('div')(css`
  width: 32px;
  margin-right: 10px;
  position: relative;
  bottom: 5px;
`);

export const QuoteNoteMessage = styled('div')(css`
  flex: 1;
`);

export const QuoteNoteEmphasizedMessage = styled(QuoteNoteMessage)(css`
  font-weight: 600;
  font-size: 16px;
`);
