import { useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import {
  MedicareSupplementQuoteQueryData,
  MedicareSupplementQuoteQueryVariables,
} from './useMedicareSupplementQuoteLazyQuery.interfaces';
import { QUOTE_FRAGMENT } from '../../fragments';

const MEDICARE_SUPPLEMENT_QUOTE_QUERY = gql`
  query getMedicareSupplementQuotes($leadId: ID!, $inputs: MedicareSupplementQuoteInput) {
    medicareSupplementQuote(leadId: $leadId, inputs: $inputs) {
      quoteResults {
        quote {
          ...QuoteFragment
        }
        notes {
          type
          message
        }
      }
      availableRiders
    }
  }
  ${QUOTE_FRAGMENT}
`;

export function useMedicareSupplementQuoteLazyQuery() {
  return useLazyQuery<MedicareSupplementQuoteQueryData, MedicareSupplementQuoteQueryVariables>(
    MEDICARE_SUPPLEMENT_QUOTE_QUERY,
  );
}
