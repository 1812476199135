import * as React from 'react';
import { Box, Button } from '@material-ui/core';
import { ApptNotifModalContentProps, Paragraph } from './ApptNotifModalContent.interfaces';

export const ApptNotifModalContent: React.FC<ApptNotifModalContentProps> = ({
  onClose,
  onClickSave,
  clientName,
  appointmentTime,
}) => (
  <>
    <Paragraph>
      Upcoming appointment with
      {' '}
      {clientName}
      {' '}
      at
      {' '}
      {appointmentTime}
    </Paragraph>
    <Box display="flex" justifyContent="flex-end">
      <Button variant="outlined" style={{ marginRight: 5 }} onClick={onClose}>
        Dismiss
      </Button>
      <Button variant="contained" color="primary" style={{ color: 'white' }} onClick={onClickSave}>
        Call now
      </Button>
    </Box>
  </>
);
