import * as React from 'react';
import { MedicareSoaFormItemContainer } from './MedicareSoa.elements';
import { TextBlock } from '../ScriptBlock';
import { useFormAnswers } from '../../../hooks';

export const MedicareSoa: React.FunctionComponent = () => {
  const [answers] = useFormAnswers();
  const productType = answers['lead.medicarePlanOfInterest'] === 'PDP'
    ? 'PDP'
    : 'Medicare Advantage';

  return (
    <MedicareSoaFormItemContainer>
      <TextBlock>
        Now we are going to look at&nbsp;
        {productType}
        &nbsp;plans in your area. Please note this does not affect your current or future Medicare enrollment status.
        You are not obligated to enroll in a Medicare plan, you will not be automatically enrolled, and you are not
        required to provide any health information unless needed for enrollment.
      </TextBlock>
    </MedicareSoaFormItemContainer>
  );
};
