import * as React from 'react';
import { CreateActivityFormWidgetProps } from './CreateActivityFormWidget.interfaces';
import { CreateTaskWidgetContainer } from './CreateActivityFormWidget.elements';
import { SecondaryButton } from '../inputs/SecondaryButton';
import { CreateActivityForm } from '../CreateActivityForm';
import { ActivityType } from '@hiq/crm.types';
import Modal from '@material-ui/core/Modal';

export const CreateActivityFormWidget: React.FunctionComponent<CreateActivityFormWidgetProps> = ({
  visible,
  onClose,
}) => {
  if (!visible) {
    return <></>;
  }
  return (
    <Modal open={visible} onClose={onClose}>
      <CreateTaskWidgetContainer>
        <SecondaryButton onClick={onClose}>X</SecondaryButton>
        <CreateActivityForm
          displayFullFormOnStart
          defaultActivityType={ActivityType.Task}
          onSaveSuccess={onClose}
          placeholder="New Task..."
        />
      </CreateTaskWidgetContainer>
    </Modal>
  );
};
