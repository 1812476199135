import React from 'react';
import { PrimaryButton } from '../../inputs';

export type DialerDispositionButtonProps = {
  disabled: boolean;
  value: string;
  onClick: Function;
  label: string;
};

export const DialerDispositionButton: React.FC<DialerDispositionButtonProps> = ({
  disabled,
  value,
  onClick,
  label,
}) => (
  <PrimaryButton
    key={value}
    disabled={disabled}
    variant="outlined"
    onClick={() => { onClick(value); }}
  >
    { label }
  </PrimaryButton>
);
