import { gql } from 'apollo-boost';
import { QUOTE_FRAGMENT } from '../../fragments/quote';
import { MutationCustomReturn, useMutationCustom } from '../useMutationCustom';
import { AddExternalLifeQuoteMutationResponse, AddExternalLifeQuoteMutationVariables } from './useAddExternalLifeQuoteMutation.interfaces';
import { useCallback } from 'react';
import { eventBus } from '../../../services';
import { QuoteSelectedEvent } from '../useSelectQuoteMutation/events';

const LIFE_QUOTE_MUTATION = gql`
  mutation AddExternalLifeQuote($leadId: ID!, $properties: LifeQuoteInput!) {
    addExternalLifeQuote(leadId: $leadId, properties: $properties) {
      code,
      success,
      message,
      timestamp,
      quote {
        ...QuoteFragment
      }
    }
  }
  ${QUOTE_FRAGMENT}
`;

type LifeQuoteMutationReturn = MutationCustomReturn<AddExternalLifeQuoteMutationResponse, AddExternalLifeQuoteMutationVariables>;

export function useAddExternalLifeQuoteMutation(): LifeQuoteMutationReturn {
  const [addQuote, result] = useMutationCustom<
  AddExternalLifeQuoteMutationResponse, AddExternalLifeQuoteMutationVariables
  >(LIFE_QUOTE_MUTATION);

  const addQuoteWrapped = useCallback(async (variables: AddExternalLifeQuoteMutationVariables) => {
    const response = await addQuote(variables);
    if (response?.data?.addExternalLifeQuote?.success) {
      eventBus.emit(new QuoteSelectedEvent());
    }
    return response;
  }, [addQuote]);

  return [addQuoteWrapped, result];
}
