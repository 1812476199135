import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const HeaderRendererH1 = styled('h1')(css`
  margin: 40px 0 10px;
`);

export const HeaderWrapper = styled('span')(css`
  h3 {
    margin-top: 40px;
  }
`);
