import * as React from 'react';
import { QuestionOption, QuestionType } from '@hiq/crm.types';
import { InputFactoryProps } from './InputFactory.interfaces';
import { TextInput } from '../TextInput';
import { RadioInput } from '../RadioInput';
import { SelectInput } from '../SelectInput';
import { MultiSelectInput } from '../MultiSelectInput';
import {
  identityTransformerFactory,
  numberTransformerFactory,
  currencyTransformerFactory,
  percentTransformerFactory,
  zipCodeTransformerFactory,
  emailTransformerFactory,
  ssnTransformerFactory,
  phoneTransformerFactory,
  dateTransformerFactory,
  medicareIdTransformerFactory,
  dateMonthTransformerFactory,
  routingNumberTransformerFactory,
  vinTransformerFactory,
} from '../transformers';
import { DatePickerInput } from '../DatePickerInput';
import { AddressLookupInput } from '../AddressLookupInput';
import { CheckboxInput, CheckboxOption } from '../CheckboxInput';
import { creditCardNumberTransformerFactory } from '../transformers/CreditCardNumberTransformer';
import { CustomInputElement } from '../CustomInputElement';
import { AutomatedUnderwriting } from '../AutomatedUnderwriting';
import { VoiceSignature } from '../VoiceSignature';
import { RadioYesNoInput } from '../RadioYesNoInput';
import { BankersHealthCondition } from '../BankersHealthCondition';
import { AppSubmission } from '../AppSubmission';
import { TcpaConsent } from '../TcpaConsent';
import { DateTimeInput } from '../DateTimeInput';
import { IconButton } from '@material-ui/core';
import { ClickToDialButton } from '../../ClickToDialButton';

const InputFactoryFC: React.FunctionComponent<InputFactoryProps> = ({
  id,
  type,
  placeholder,
  options,
  optionsId,
  value,
  onChange,
  validationStatus,
  validationMessage,
  label,
  size,
  disabled,
  defaultValue,
  fullWidth,
  variant,
  rows,
  onBlur,
  onFocus,
  onKeyUp,
  loading,
  dataQa,
  referenceId,
  answersKey,
  dependencies,
  arrayFormIndex,
  minLength,
  maxLength,
  allowClientRefused,
  endIcon,
  maxItems,
  tags,
}) => {
  switch (type) {
    case QuestionType.Text:
      return (
        <TextInput
          placeholder={placeholder}
          value={(value ?? defaultValue ?? '') as string}
          onChange={onChange}
          transformerFactory={identityTransformerFactory}
          validationStatus={validationStatus}
          validationMessage={validationMessage}
          label={label}
          size={size}
          disabled={disabled}
          fullWidth={fullWidth}
          variant={variant}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyUp={onKeyUp}
          dataQa={dataQa}
          referenceId={referenceId}
          answersKey={answersKey}
          inputProps={{ minLength, maxLength }}
          tags={tags}
        />
      );
    case QuestionType.Currency:
      return (
        <TextInput
          placeholder={placeholder}
          value={(value ?? defaultValue) as number}
          onChange={onChange}
          transformerFactory={currencyTransformerFactory}
          validationStatus={validationStatus}
          validationMessage={validationMessage}
          label={label}
          size={size}
          disabled={disabled}
          fullWidth={fullWidth}
          variant={variant}
          prefix="$"
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyUp={onKeyUp}
          dataQa={dataQa}
          referenceId={referenceId}
          answersKey={answersKey}
          tags={tags}
        />
      );
    case QuestionType.Date:
      return (
        <TextInput
          placeholder={placeholder ?? 'MM/DD/YYYY'}
          value={(value ?? defaultValue) as number}
          onChange={onChange}
          transformerFactory={dateTransformerFactory}
          validationStatus={validationStatus}
          validationMessage={validationMessage}
          label={label}
          size={size}
          disabled={disabled}
          fullWidth={fullWidth}
          variant={variant}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyUp={onKeyUp}
          dataQa={dataQa}
          referenceId={referenceId}
          answersKey={answersKey}
          tags={tags}
        />
      );
    case QuestionType.DateMonth:
      return (
        <TextInput
          placeholder={placeholder ?? 'MM/YYYY'}
          value={(value ?? defaultValue) as number}
          onChange={onChange}
          transformerFactory={dateMonthTransformerFactory}
          validationStatus={validationStatus}
          validationMessage={validationMessage}
          label={label}
          size={size}
          disabled={disabled}
          fullWidth={fullWidth}
          variant={variant}
          onBlur={onBlur}
          dataQa={dataQa}
          referenceId={referenceId}
          answersKey={answersKey}
          tags={tags}
        />
      );
    case QuestionType.DatePicker:
      return (
        <DatePickerInput
          value={value}
          onChange={onChange}
          label={label}
          fullWidth={fullWidth}
          size={size}
          referenceId={referenceId}
          answersKey={answersKey}
        />
      );
    case QuestionType.DateTime:
      return (
        <DateTimeInput
          value={value}
          onChange={onChange}
          label={label}
          fullWidth={fullWidth}
          size="small"
        />
      );
    case QuestionType.MultiSelect:
      return (
        <MultiSelectInput
          value={(value ?? []) as any[]}
          onChange={onChange}
          options={options as QuestionOption[]}
          size={size}
          disabled={disabled}
          placeholder={placeholder}
          validationStatus={validationStatus}
          validationMessage={validationMessage}
          label={label}
          fullWidth={fullWidth}
          variant={variant}
          onBlur={onBlur}
          onFocus={onFocus}
          dataQa={dataQa}
          referenceId={referenceId}
          answersKey={answersKey}
          tags={tags}
        />
      );
    case QuestionType.Checkbox:
      return (
        <CheckboxInput
          variant="default"
          value={(value ?? []) as any[]}
          onChange={onChange}
          options={options as CheckboxOption[]}
          disabled={disabled}
          dataQa={dataQa}
          referenceId={referenceId}
          answersKey={answersKey}
          max={maxItems}
          tags={tags}
        />
      );
    case QuestionType.CheckboxCompliance:
      return (
        <CheckboxInput
          variant="compliance"
          value={(value ?? []) as any[]}
          onChange={onChange}
          options={options as CheckboxOption[]}
          disabled={disabled}
          dataQa={dataQa}
          referenceId={referenceId}
          answersKey={answersKey}
          max={maxItems}
        />
      );
    case QuestionType.Number:
      return (
        <TextInput
          placeholder={placeholder}
          value={(value ?? defaultValue) as number}
          onChange={onChange}
          transformerFactory={numberTransformerFactory}
          validationStatus={validationStatus}
          validationMessage={validationMessage}
          label={label}
          size={size}
          disabled={disabled}
          fullWidth={fullWidth}
          variant={variant}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyUp={onKeyUp}
          dataQa={dataQa}
          referenceId={referenceId}
          answersKey={answersKey}
          tags={tags}
        />
      );
    case QuestionType.Phone:
      return (
        <TextInput
          placeholder={placeholder}
          value={(value ?? defaultValue) as string}
          onChange={onChange}
          transformerFactory={phoneTransformerFactory}
          validationStatus={validationStatus}
          validationMessage={validationMessage}
          label={label}
          size={size}
          disabled={disabled}
          fullWidth={fullWidth}
          variant={variant}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyUp={onKeyUp}
          dataQa={dataQa}
          referenceId={referenceId}
          answersKey={answersKey}
          endIcon={value && <ClickToDialButton number={value as string} />}
          tags={tags}
        />
      );
    case QuestionType.Radio:
      return (
        <RadioInput
          value={value}
          onChange={onChange}
          options={options}
          disabled={disabled}
          dataQa={dataQa}
        />
      );
    case QuestionType.RadioYesNo:
      return (
        <RadioYesNoInput
          value={value}
          onChange={onChange}
          disabled={disabled}
          dataQa={dataQa}
          allowClientRefused={allowClientRefused}
        />
      );
    case QuestionType.Select:
      return (
        <SelectInput
          value={value ?? null}
          onChange={onChange}
          options={options}
          optionsId={optionsId}
          dependencies={dependencies}
          size={size}
          disabled={disabled}
          fullWidth={fullWidth}
          variant={variant}
          label={label}
          loading={loading}
          dataQa={dataQa}
          referenceId={referenceId}
          answersKey={answersKey}
          arrayFormIndex={arrayFormIndex}
          tags={tags}
        />
      );
    case QuestionType.ZipCode:
      return (
        <TextInput
          placeholder={placeholder}
          value={(value ?? defaultValue) as number}
          onChange={onChange}
          transformerFactory={zipCodeTransformerFactory}
          validationStatus={validationStatus}
          validationMessage={validationMessage}
          label={label}
          size={size}
          disabled={disabled}
          fullWidth={fullWidth}
          variant={variant}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyUp={onKeyUp}
          dataQa={dataQa}
          referenceId={referenceId}
          answersKey={answersKey}
          tags={tags}
        />
      );
    case QuestionType.Percent:
      return (
        <TextInput
          placeholder={placeholder}
          value={(value ?? defaultValue) as number}
          onChange={onChange}
          transformerFactory={percentTransformerFactory}
          validationStatus={validationStatus}
          validationMessage={validationMessage}
          label={label}
          size={size}
          disabled={disabled}
          fullWidth={fullWidth}
          variant={variant}
          suffix="%"
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyUp={onKeyUp}
          dataQa={dataQa}
          referenceId={referenceId}
          answersKey={answersKey}
          tags={tags}
        />
      );
    case QuestionType.Email:
      return (
        <TextInput
          placeholder={placeholder}
          value={(value ?? defaultValue ?? '') as string}
          onChange={onChange}
          transformerFactory={emailTransformerFactory}
          validationStatus={validationStatus}
          validationMessage={validationMessage}
          label={label}
          size={size}
          disabled={disabled}
          fullWidth={fullWidth}
          variant={variant}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyUp={onKeyUp}
          dataQa={dataQa}
          referenceId={referenceId}
          answersKey={answersKey}
          tags={tags}
        />
      );
    case QuestionType.SSN:
      return (
        <TextInput
          placeholder={placeholder}
          value={(value ?? defaultValue ?? '') as string}
          onChange={onChange}
          transformerFactory={ssnTransformerFactory}
          validationStatus={validationStatus}
          validationMessage={validationMessage}
          label={label}
          size={size}
          disabled={disabled}
          fullWidth={fullWidth}
          variant={variant}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyUp={onKeyUp}
          dataQa={dataQa}
          referenceId={referenceId}
          answersKey={answersKey}
          tags={tags}
        />
      );
    case QuestionType.Script:
      return <></>;
    case QuestionType.TextArea:
      return (
        <TextInput
          placeholder={placeholder}
          value={(value ?? defaultValue ?? '') as string}
          onChange={onChange}
          transformerFactory={identityTransformerFactory}
          validationStatus={validationStatus}
          validationMessage={validationMessage}
          label={label}
          size={size}
          disabled={disabled}
          fullWidth={fullWidth}
          variant={variant}
          multiline
          rows={rows ?? 4}
          dataQa={dataQa}
          referenceId={referenceId}
          answersKey={answersKey}
          tags={tags}
        />
      );
    case QuestionType.MedicareId:
      return (
        <TextInput
          placeholder={placeholder}
          value={(value ?? defaultValue ?? '') as string}
          onChange={onChange}
          transformerFactory={medicareIdTransformerFactory}
          validationStatus={validationStatus}
          validationMessage={validationMessage}
          label={label}
          size={size}
          disabled={disabled}
          fullWidth={fullWidth}
          variant={variant}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyUp={onKeyUp}
          dataQa={dataQa}
          referenceId={referenceId}
          answersKey={answersKey}
          endIcon={<IconButton size="small">{endIcon}</IconButton>}
          tags={tags}
        />
      );
    case QuestionType.CreditCardNumber:
      return (
        <TextInput
          placeholder={placeholder}
          value={(value ?? defaultValue ?? '') as string}
          onChange={onChange}
          transformerFactory={creditCardNumberTransformerFactory}
          validationStatus={validationStatus}
          validationMessage={validationMessage}
          label={label}
          size={size}
          disabled={disabled}
          fullWidth={fullWidth}
          variant={variant}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyUp={onKeyUp}
          mask
          dataQa={dataQa}
          referenceId={referenceId}
          answersKey={answersKey}
        />
      );
    case QuestionType.CreditCardCVV:
      return (
        <TextInput
          placeholder={placeholder}
          value={(value ?? defaultValue ?? '') as string}
          onChange={onChange}
          transformerFactory={identityTransformerFactory}
          validationStatus={validationStatus}
          validationMessage={validationMessage}
          label={label}
          size={size}
          disabled={disabled}
          fullWidth={fullWidth}
          variant={variant}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyUp={onKeyUp}
          mask
          dataQa={dataQa}
          referenceId={referenceId}
          answersKey={answersKey}
        />
      );
    case QuestionType.RoutingNumber:
      return (
        <TextInput
          placeholder={placeholder}
          value={(value ?? defaultValue ?? '') as string}
          onChange={onChange}
          transformerFactory={routingNumberTransformerFactory}
          validationStatus={validationStatus}
          validationMessage={validationMessage}
          label={label}
          size={size}
          disabled={disabled}
          fullWidth={fullWidth}
          variant={variant}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyUp={onKeyUp}
          referenceId={referenceId}
          answersKey={answersKey}
        />
      );
    case QuestionType.AddressLookup:
      return (
        <AddressLookupInput
          placeholder={placeholder}
          onChange={onChange}
          validationStatus={validationStatus}
          validationMessage={validationMessage}
          label={label}
          size={size}
          disabled={disabled}
          fullWidth={fullWidth}
          variant={variant}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyUp={onKeyUp}
          dataQa={dataQa}
        />
      );
    case QuestionType.Custom:
      return (
        <CustomInputElement referenceId={id} />
      );
    case QuestionType.Vin:
      return (
        <TextInput
          placeholder={placeholder}
          value={(value ?? defaultValue ?? '') as string}
          onChange={onChange}
          transformerFactory={vinTransformerFactory}
          validationStatus={validationStatus}
          validationMessage={validationMessage}
          label={label}
          size={size}
          disabled={disabled}
          fullWidth={fullWidth}
          variant={variant}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyUp={onKeyUp}
          dataQa={dataQa}
          referenceId={referenceId}
          answersKey={answersKey}
          inputProps={{ maxLength: 17 }}
        />
      );
    case QuestionType.AutomatedUnderwriting:
      return (
        <AutomatedUnderwriting
          dataQa={dataQa}
        />
      );
    case QuestionType.AppSubmission:
      return (
        <AppSubmission dataQa={dataQa} />
      );
    case QuestionType.VoiceSignature:
      return (
        <VoiceSignature
          disabled={disabled}
          dataQa={dataQa}
          answersKey={answersKey}
          onChange={onChange}
        />
      );
    case QuestionType.BankersHealthCondition:
      return (
        <BankersHealthCondition answersKey={answersKey} defaultValue={defaultValue} disabled={disabled} />
      );
    case QuestionType.TcpaConsent:
      return (
        <TcpaConsent answersKey={answersKey} />
      );
    case null:
    case undefined:
      return null;
    default:
      throw new Error(`Question input type ${type} not implemented`);
  }
};

export const InputFactory = React.memo(InputFactoryFC);
