import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';
import { OverlayQueryData, OverlayQueryVariables } from './useOverlayQuery.interfaces';
import { OVERLAY_FRAGMENT } from '../../fragments';

export const OVERLAY_QUERY = gql`
  query FindOverlays($customerId: CustomerId!) {
    doctorsOverlays: findOverlays(customerId: $customerId, externalType: ["HealthRecordDoctor"]) {
      ...OverlayFragment
    }
    drugsOverlays: findOverlays(customerId: $customerId, externalType: ["HealthRecordDrug", "SunfireDrug"]) {
      ...OverlayFragment
    }
    pharmaciesOverlays: findOverlays(customerId: $customerId, externalType: ["HealthRecordPharmacy"]) {
      ...OverlayFragment
    }
    userClaimsOverlays: findOverlays(customerId: $customerId, externalType: ["HealthRecordUserClaim"]) {
      ...OverlayFragment
    }
    anticipatedNeedsOverlays: findOverlays(customerId: $customerId, externalType: ["HealthRecordAnticipatedNeed"]) {
      ...OverlayFragment
    }
  }
  ${OVERLAY_FRAGMENT}
`;

export function useOverlayQuery(customerId: string) {
  return useLazyQuery<OverlayQueryData, OverlayQueryVariables>(OVERLAY_QUERY, {
    variables: {
      customerId,
    },
  });
}
