import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { ComponentWithThemeProps } from '../../../../../../../themes';

type QuestionValueEditableContainerProps = ComponentWithThemeProps & { locked?: boolean; };
export const QuestionValueEditableContainer = styled('div')<QuestionValueEditableContainerProps>(({
  theme,
  locked,
}) => css`
  font-size: 16px;
  height: 40px;
  padding: 10.5px 0;
  min-width: 50px;
  width: 100%;
  overflow-wrap: break-word;
  transition: all 200ms;
  
  &:hover {
    background-color: ${theme.color.grayLight};
    border-radius: 5px;
    cursor: ${locked ? 'not-allowed' : 'text'};
  }
`);
