import { useCallback } from 'react';
import { gql } from 'apollo-boost';
import { useMutationCustom } from '../useMutationCustom';
import { FileUploadMutationResponse, FileUploadMutationVariables } from './useFileUploadMutation.interfaces';
import { FILE_FRAGMENT } from '../../fragments/file';
import { hiqCrmService } from '../../../services';
import { upload } from '../../../components/common/FileUploader/useFileUpload';

export const FILE_UPLOAD_MUTATION = gql`
  mutation FileUploadMutation($leadId: ID!, $input: FileUploadInput!) {
    saveUploadedFile(leadId: $leadId, input: $input) {
      code
      success
      message
      timestamp

      files {
        ...FileFragment
      }
    }
  }
  ${FILE_FRAGMENT}
`;

type FileUploadFunction = (leadId: string, file: File) => Promise<any>;

export function useFileUploadMutation(): [FileUploadFunction, any] {
  const [saveUploadedFile, result] = useMutationCustom<FileUploadMutationResponse, FileUploadMutationVariables>(FILE_UPLOAD_MUTATION);
  const uploadFileWrapped = useCallback(async (leadId: string, file: File) => {
    const url = await hiqCrmService.createSignedFileUploadUrl(file.name, file.type, leadId);
    await upload(url, file);
    const response = await saveUploadedFile({
      leadId,
      input: {
        name: file.name,
        type: file.type,
      },
    });

    return response;
  }, [saveUploadedFile]);
  return [uploadFileWrapped, result];
}
