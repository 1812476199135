import { IEventBusEvent } from '@healthiqeng/core.services.event-bus';
import { CrossSaleCustomer } from '@hiq/crm.types';

export class CrossSaleCreateSuccessEvent implements IEventBusEvent {
  public readonly type = 'CrossSaleCreateSuccessEvent';
  constructor(
    public readonly leadId: string,
    public readonly customerId: string,
    public readonly customerType: CrossSaleCustomer,
  ) {}
}
