import { gql } from 'apollo-boost';
import { useCallback } from 'react';
import { useMutationCustom } from '../useMutationCustom';
import { CUSTOMER_FRAGMENT, QUESTION_FRAGMENT } from '../../fragments';
import { FormAnswersMutationResponse, FormAnswersMutationVariables } from './useFormAnswersMutation.interfaces';
import { eventBus } from '../../../services';
import { SaveBeginEvent, SaveCompleteEvent } from './events';
import { emitAnalyticsEventNoLimit } from '../../../events/emitAnalyticsEvent';
import { addBreadcrumb } from '@sentry/gatsby';
import { SaveFailureEvent } from './events/SaveFailureEvent';

const FORM_ANSWERS_MUTATION = gql`
  mutation SaveFormAnswers($leadId: ID!, $answers: JSON!, $callUrlPath: String) {
    saveFormAnswers(leadId: $leadId, answers: $answers, callUrlPath: $callUrlPath) {
      code
      success
      message
      timestamp
      customers {
        ...CustomerFragment
      }
      questions {
        ...QuestionFragment
      }
    }
  }
  ${CUSTOMER_FRAGMENT}
  ${QUESTION_FRAGMENT}
`;

export function useFormAnswersMutation(trackingId?: string, onError?: () => void): ReturnType<typeof useMutationCustom> {
  const [saveFormAnswers, result] = useMutationCustom<FormAnswersMutationResponse, FormAnswersMutationVariables>(FORM_ANSWERS_MUTATION, {
    onError,
  });
  const saveFormAnswersWrapped = useCallback(async (variables: FormAnswersMutationVariables) => {
    const keys = new Set(Object.keys(variables.answers));
    eventBus.emit(new SaveBeginEvent(trackingId, keys));
    try {
      const response = await saveFormAnswers(variables);
      if (!response || response?.errors?.length) throw new Error('Failed to save answers');

      addBreadcrumb({
        level: 'info',
        message: 'Successfully saved form answers',
        data: variables,
      });
      eventBus.emit(new SaveCompleteEvent(trackingId, keys));
      return response;
    } catch (err) {
      try {
        // Capture error and retry
        emitAnalyticsEventNoLimit('Answer Save Failure', { keys });
        const response = await saveFormAnswers(variables);
        if (!response || response?.errors?.length) throw new Error('Failed to save answers');

        addBreadcrumb({
          level: 'info',
          message: 'Successfully saved form answers',
          data: variables,
        });
        eventBus.emit(new SaveCompleteEvent(trackingId, keys));
        return response;
      } catch (err) {
        eventBus.emit(new SaveFailureEvent(trackingId, keys));
        emitAnalyticsEventNoLimit('Answer Save Failure (Retry #1)', { keys });
        throw err;
      }
    }
  }, [saveFormAnswers]);
  return [saveFormAnswersWrapped, result];
}
