import * as React from 'react';
import { Box } from '../Box';
import { InputControl } from './Input.elements';
import { IInputProps } from './Input.interfaces';

export class Input extends React.Component<IInputProps> {
  public render() {
    return (
      <Box {...this.props}>
        <InputControl
          value={this.props.value || ''}
          controlSize={this.props.controlSize || 'large'}
          name={this.props.name}
          placeholder={this.props.placeholder}
          autoComplete={this.props.autoComplete}
          type={this.props.type}
          onChange={this.changeHandler}
        />
      </Box>
    );
  }

  private changeHandler = (e: React.SyntheticEvent) => {
    this.props.onChange && this.props.onChange((e.target as HTMLInputElement).value, this.props.name);
  };
}
