/* eslint-disable */

interface Rebuttal {
  id: string;
  title: string;
  text: string;
}

export const REBUTTALS_LIST: Rebuttal[] = [
  {
    id: '1',
    title: 'Let me think about it…',
    text: 'I totally understand, this is an important decision. Earlier in the call you told me you needed (benefits identified in need’s analysis), this plan is giving you those benefits. Is there anything else you are looking for that we need to include to get you enrolled today?',
  },
  {
    id: '2',
    title: 'Send me information',
    text: 'a. Sure, we can actually send it via email to you right now for us to review together. What\'s a good email to send it to?’'
      + '\n'
      + 'b. I know we’ve gone over a lot. That’s why after we submit your application you will receive your welcome packet in the mail with all the information we’ve gone over. You can call me with any questions.',
  },
  {
    id: '3',
    title: 'How/Is this plan zero dollars? Is this a scam/legit?',
    text: 'Funny you ask. This plan is federally contracted, so the federal government actually covers the portion of this premium for you. You already pay for your Part B premium. The plan does cost money in reality, but it’s just not coming out of your pocket. I am a licensed medicare agent,would you like me to provide you my license number? Ok great,  let’s enroll you in this plan so you can start saving money for the next year',
  },
  {
    id: '4',
    title: 'Happy with my current plan',
    text: 'Yes sir/mam I understand it is simply my job to get you the information especially with all these new programs that are better than all of the old ones so tell me this how long have you been with your current carrier?'
      + '\n'
      + 'Has it been this long since you’ve compared benefits with other carriers or with your same carrier? Got it. Assuming I could improve your current plan and maximize your benefits could you see any reason not to better your current coverage?',
  },
  {
    id: '5',
    title: 'Doctor out of network',
    text: 'I do understand however I know that this benefit was really important to you and want to ensure you maximize your benefits, I do have some highly rated drs in your area that accept this plan. Let me have you write down the contact information.',
  },
  {
    id: '6',
    title: 'I have to speak with my kids/significant other',
    text: 'Do they make your healthcare decisions?\n'
      + '\n'
      + '- Yes: Great, are they around to discuss your healthcare needs?\n'
      + '\n'
      + '- No: Well I’m sure they will be happy about you upgrading your plan today!  After we finalize, you will be mailed all the documents and details we discussed to share with them. So- what’s the best address to mail your cards to?',
  },
  {
    id: '7',
    title: 'What company did you say you are with?',
    text: 'I’m with Health IQ, we are a nationwide broker. What we do is offer a third party comparison of your coverage options to help you make an informed decision for yourself. I’m happy to help you find a plan that is suitable for your needs.',
  },
];
