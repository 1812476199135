import React from 'react';
import { useActiveLeadId, useCustomerId } from '../../../routers';
import {
  SunfireDrug,
  useCustomerQuery,
  useOverlayQuery,
  useSelectedDoctorsQuery,
  useSelectedICDsQuery,
  useSelectedPharmaciesQuery,
  useSelectedPrescriptionsQuery,
} from '../../../../graphql';
import moment from 'moment/moment';
import { usePreLoadCheckMyHealthRecord } from '../usePreLoadCheckMyHealthRecord';
import { useFormAnswers } from '../../../../hooks';
import {
  HealthRecordDoctor, HealthRecordDrug, HealthRecordPharmacy, HealthRecordUserClaim,
} from '@hiq/crm.types';
import { useSelectedRows } from '../CheckMyHealthRecordDataGrid/useSelectedRows';
import { useColumnsConfig } from '../CheckMyHealthRecordDataGrid/useColumnsConfig';
import { useCellConfig } from '../CheckMyHealthRecordDataGrid/useCellConfig';
import { withContentRect } from 'react-measure';
import { HealthRecordLockedGrid } from './HealthRecordLockedGrid/HealthRecordLockedGrid';
import { useDoctorColumnConfig } from '../CheckMyHealthRecordDataGrid/useDoctorColumnsConfig';

const SUNFIRE_COUNTY_QUESTION_ID = 'lead.sunfire.v2.countyCode';

export const CheckMyHealthRecordLockedGrid = withContentRect('bounds')(({ contentRect }) => {
  const customerId = useCustomerId();
  const leadId = useActiveLeadId();
  const [answers] = useFormAnswers(leadId, true);
  const { data: customerData } = useCustomerQuery();
  const email = customerData?.customer?.person?.email;
  const { zip } = customerData?.customer?.person?.homeAddress ?? {};
  const countyCode = answers[SUNFIRE_COUNTY_QUESTION_ID];
  const selectedPrescriptions = useSelectedPrescriptionsQuery(leadId)?.data?.selectedPrescriptions?.map(
    (data) => ({ ...data, id: data.sunfireId }),
  ) ?? [];
  const selectedDoctors = useSelectedDoctorsQuery(leadId)?.data?.selectedDoctors?.map(
    (doc) => ({ ...doc, lastVisit: doc.lastVisit ? moment(doc.lastVisit).format('M/D/yyyy') : undefined }),
  ) ?? [];
  const selectedPharmacies = useSelectedPharmaciesQuery(leadId)?.data?.selectedPharmacies ?? [];
  const selectedIcds = useSelectedICDsQuery(leadId)?.data?.selectedICDs ?? [];
  const {
    data: queryData,
  } = usePreLoadCheckMyHealthRecord(email, zip, countyCode);

  const {
    doctors, drugs, pharmacies, userClaims: healthRecordClaims,
  } = queryData?.fetchBionicHealthRecords ?? {};

  const [
    fetchOverlays,
    { data: overlayData },
  ] = useOverlayQuery(customerId);

  React.useEffect(() => {
    fetchOverlays();
  }, []);

  const getDrugRowId = React.useCallback(
    (data: Partial<HealthRecordDrug & SunfireDrug>) => (data.ndc ? data.ndc : data.id),
    [],
  );
  const getDoctorRowId = React.useCallback(
    (data: Partial<HealthRecordDoctor>) => data.npi,
    [],
  );
  const getPharmacyRowId = React.useCallback(
    (data: Partial<HealthRecordPharmacy>) => data.npi,
    [],
  );
  const getIcdRowId = React.useCallback(
    (data: Partial<HealthRecordUserClaim>) => data.diseaseKey || data.claimCodeId || data.description, [],
  );

  const stubFunc = React.useCallback(() => {}, []);

  const [selectedDrugsIds] = useSelectedRows<HealthRecordDrug | SunfireDrug>(
    getDrugRowId, selectedPrescriptions, stubFunc, true, 'Drug',
  );

  const [selectedDoctorsIds] = useSelectedRows<HealthRecordDoctor>(
    getDoctorRowId, selectedDoctors, stubFunc, true, 'Doctor',
  );
  const [selectedPharmaciesIds] = useSelectedRows<HealthRecordPharmacy>(
    getPharmacyRowId, selectedPharmacies, stubFunc, false, 'Pharmacy',
  );
  const [selectedIcdIds] = useSelectedRows<HealthRecordUserClaim>(
    getIcdRowId, selectedIcds, stubFunc, true, 'ICD',
  );

  const [cellWidth, isReducedViewport] = useCellConfig(contentRect.bounds.width);

  const [
    drugColumns,
    pharmaciesColumns,
    userClaimsColumns,
  ] = useColumnsConfig(cellWidth, isReducedViewport, stubFunc, stubFunc);

  const doctorsColumns = useDoctorColumnConfig({
    cellWidth,
    isReducedViewport,
    onSelect: stubFunc,
    onCriticalSelect: stubFunc,
    isRemoving: false,
    hideInclude: true,
  });

  const otherPharmacyData = React.useMemo(() => [{ name: 'Mail Order Pharmacy', npi: 'MAIL_ORDER_PHARMACY' }], []);

  const pharmacyGrid = React.useMemo(() => (
    <HealthRecordLockedGrid
      data={[...pharmacies, ...otherPharmacyData]}
      title="Pharmacy"
      getRowId={getPharmacyRowId}
      overlayData={overlayData?.pharmaciesOverlays ?? []}
      selectedRowIds={selectedPharmaciesIds}
      columns={pharmaciesColumns}
    />
  ), [pharmacies, selectedPharmaciesIds, pharmaciesColumns, overlayData?.pharmaciesOverlays]);

  const drugsGrid = React.useMemo(() => (
    <HealthRecordLockedGrid
      data={drugs}
      title="Prescriptions"
      getRowId={getDrugRowId}
      overlayData={overlayData?.drugsOverlays ?? []}
      selectedRowIds={selectedDrugsIds}
      columns={drugColumns}
    />
  ), [drugs, selectedDrugsIds, drugColumns, overlayData?.drugsOverlays]);

  const doctorsGrid = React.useMemo(() => (
    <HealthRecordLockedGrid
      data={doctors}
      title="Doctor"
      getRowId={getDoctorRowId}
      overlayData={overlayData?.doctorsOverlays ?? []}
      selectedRowIds={selectedDoctorsIds}
      columns={doctorsColumns}
    />
  ), [doctors, selectedDoctorsIds, doctorsColumns, overlayData?.doctorsOverlays]);

  const userClaimsGrid = React.useMemo(() => (
    <HealthRecordLockedGrid
      data={healthRecordClaims}
      title="Diagnoses"
      getRowId={getIcdRowId}
      overlayData={overlayData?.userClaimsOverlays ?? []}
      selectedRowIds={selectedIcdIds}
      columns={userClaimsColumns}
    />
  ), [healthRecordClaims, selectedIcdIds, userClaimsColumns, overlayData?.userClaimsOverlays]);

  return (
    <div>
      {pharmacyGrid}
      {drugsGrid}
      {doctorsGrid}
      {userClaimsGrid}
    </div>
  );
});
