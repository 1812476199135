import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../themes';

export const ValidationMessage = styled('h4')<ComponentWithThemeProps>(({ theme }) => css`
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  text-decoration: underline;
  color: ${theme.color.error};
`);
