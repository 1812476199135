import React from 'react';
import { QuestionView } from '../../FormGenerator/FormGeneratorQuestionFlow/Question/QuestionView';
import { useActiveLeadId } from '../../../routers/LeadRouter/useActiveLeadId';
import { QuestionType } from '@hiq/crm.types';
import { useFormAnswers } from '../../../../hooks';
import { JsonCompatible } from '@healthiqeng/core.types';
import { useManageChanges } from '../CheckMyHealthRecordHipaaAnswer/hooks/useManageChanges';
import { getCMHRHipaaConstants } from '../CheckMyHealthRecordHipaaAnswer/hooks/useCMHRHipaaConstants';

export const CheckMyHealthRecordHipaaConsentApprove: React.FunctionComponent = () => {
  const leadId = useActiveLeadId();
  const trackingId = React.useMemo(() => `CheckMyHealthRecordHipaaAnswer-${Date.now()}`, []);
  const [answers, onChange, persistAnswers] = useFormAnswers(leadId, false, trackingId);
  const { tempHipaaApprovedOptions } = getCMHRHipaaConstants();
  const hipaaApproved = answers['customer.person.hipaaConsentApproved'];
  const firstName = answers['customer.person.name.first'];
  const lastName = answers['customer.person.name.last'];
  const name = `${firstName} ${lastName}`;

  const { onValueChanged } = useManageChanges(onChange, persistAnswers);
  const onHipaaApprovedChange = React.useCallback((value: JsonCompatible) => onValueChanged(value), [onValueChanged]);

  const currentDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  }).format(new Date());

  const questionText = `\`\`\`read-exactly\n ** To finish the process I need to capture your verbal
   permission. ** \n\n ** If you agree, ${name} on ${currentDate} please say Yes. **\n\`\`\` \n
   \`\`\`directional-copy\n Answer to the above recorded message: \n\`\`\``;

  return (
    <div style={{ marginBottom: 10 }}>
      <QuestionView
        id="customer.person.hipaaConsentApproved"
        questionText={questionText}
        label="Select Yes or No"
        type={QuestionType.Radio}
        options={tempHipaaApprovedOptions}
        leadId={leadId}
        value={hipaaApproved}
        onChange={onHipaaApprovedChange}
      />
    </div>
  );
};
