import React, { useState } from 'react';
import {
  FeedbackMessage, Label, MessageInput, SubjectInput,
} from './SendEmail.elements';
import { SendEmailProps } from './SendEmail.interfaces';
import { PrimaryButton, QueryLoader } from '../../common';
import { useSendEmail } from '../../../graphql/mutations/useSendEmailMutation';
import { SidePanelContentLayout } from '../../layouts/SidePanelContentLayout';
import { Emails, MyEmails } from './Emails';
import {
  ContentContainer,
  ContentRowContainer,
  StyledTabs,
  StyledTabsContainer,
  TabRowContainer, TabTopLayoutContainer,
} from '../../layouts/TabTopLayout/TabTopLayout.elements';
import { noop } from '../../../util';
import { TabTopLayoutTab } from '../../layouts/TabTopLayout/TabTopLayoutTab';
import { MainPanelLayoutTabItem, TabTopLayoutTabItem } from '../../layouts';

export const SendEmail: React.FunctionComponent<SendEmailProps> = ({ leadId }: SendEmailProps) => {
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [activeTab, setActiveTab] = React.useState('my-emails');

  const [sendEmail, { loading, data, error }] = useSendEmail({
    onCompleted: () => {
      setSubject('');
      setContent('');
    },
  });
  const onSend = () => {
    sendEmail({
      leadId,
      content: content.replaceAll('\n', '<br />'),
      subject,
    });
  };

  const isSendButtonDisabled = !content || !subject || content.trim().length === 0 || subject.trim().length === 0;

  const tabs: MainPanelLayoutTabItem[] = [
    {
      id: 'my-emails',
      label: 'MY',
      icon: 'note',
      content: (
        <MyEmails />
      ),
    },
    {
      id: 'all-emails',
      label: 'ALL',
      icon: 'note',
      content: (
        <Emails />
      ),
    },
  ];

  function getActiveIndex(tabs: TabTopLayoutTabItem[], activeId: string): number {
    const index = tabs.map((tab) => tab.id).indexOf(activeId);
    return Math.max(index, 0);
  }

  return (
    <SidePanelContentLayout header="Send Email">
      <QueryLoader loading={loading} error={error} componentName="SendEmail">
        <Label>Subject:</Label>
        <SubjectInput
          onChange={(event) => setSubject(event.target.value)}
          value={subject}
        />
        <Label>Content:</Label>
        <MessageInput
          onChange={(event) => setContent(event.target.value)}
          value={content}
        />
        <PrimaryButton onClick={onSend} disabled={isSendButtonDisabled}>Send</PrimaryButton>
        {data?.sendEmail?.message && <FeedbackMessage>{data.sendEmail.message}</FeedbackMessage>}
      </QueryLoader>
      <TabTopLayoutContainer>
        <TabRowContainer>
          <StyledTabsContainer data-qa="top-layout-tabs">
            <StyledTabs
              value={getActiveIndex(tabs, activeTab)}
              onChange={noop}
              scrollButtons="auto"
              variant="scrollable"
            >
              {tabs.map(({ id, label }) => (
                <TabTopLayoutTab
                  key={id}
                  id={id}
                  label={label}
                  onClick={setActiveTab}
                />
              ))}
            </StyledTabs>
          </StyledTabsContainer>
        </TabRowContainer>
        <ContentRowContainer>
          {tabs.map(({ id, content }) => (
            <ContentContainer
              key={id}
              role="tabpanel"
              hidden={activeTab !== id}
            >
              {activeTab === id && content}
            </ContentContainer>
          ))}
        </ContentRowContainer>
      </TabTopLayoutContainer>
    </SidePanelContentLayout>
  );
};
