/* eslint react/require-default-props: 0 */

import React from 'react';
import {
  Column, CompareData, CompareDataBold, Row,
} from '../../TRMQuote.elements';
import { formatCurrencyTruncated } from '@healthiqeng/core.ui';
import styled from '@emotion/styled';
import { createStyles, makeStyles, Tooltip } from '@material-ui/core';
import { InfoIconContainer } from '../../CompareDoctor/CompareDoctorLocations/CompareDoctorLocations.elements';
import { Icon } from '../../../../Icon';

export type MedCost = {
  code: string
  hiqDescription: string
  hiqRank: number
  oopCost: number
  deductible: number
  unitCost: number
  coins: number
  copay: number
  costShare: number
  totalCost: number
};

interface ComparePriceInterface {
  cost: MedCost
  currentPlanCost: MedCost
  name: string
}

export const CompareRow = styled.tr`
  & td:first-child {
    padding-left: 60px;
  }
`;

export const CompareMedCostsRow = ({ cost, currentPlanCost, name }: ComparePriceInterface) => (
  <CompareRow>
    <CompareDataBold>
      {name}
    </CompareDataBold>
    <CompareData highlight={currentPlanCost?.hiqRank < cost?.hiqRank} empty={!currentPlanCost}>
      {currentPlanCost && (
        <Column>
          <MedCosts cost={currentPlanCost} />
        </Column>
      )}
    </CompareData>
    <CompareData highlight={cost?.hiqRank < currentPlanCost?.hiqRank}>
      <Column>
        <MedCosts cost={cost} />
      </Column>
    </CompareData>
  </CompareRow>
);

const useStyles = makeStyles(() => createStyles({
  tooltip: {
    backgroundColor: '#FFF',
    maxWidth: 500,
    fontSize: 16,
    fontWeight: 400,
    color: '#000',
    border: '1px solid #989898',
  },
}));

const infoIconStyle: React.CSSProperties = {
  fontSize: 20,
  bottom: 1,
  position: 'relative',
  marginBottom: 2,
  marginLeft: 10,
};

const MedCosts = ({ cost }: {cost: MedCost}) => {
  const toolipStyle = useStyles();
  const showTooltip = checkHasValue(cost?.copay) || checkHasValue(cost?.coins);

  const tooltipContent = (
    <Column>
      <CostRow value={cost?.copay} label="Copay" />
      <CostRow value={cost?.coins} label="Coinsurance" percentage />
    </Column>
  );

  return (
    <Row alignItems="flex-end">
      <CostRow value={cost?.costShare} label="OOP Estimate" />
      {
        showTooltip ? (
          <Tooltip title={tooltipContent} classes={toolipStyle}>
            <InfoIconContainer>
              <Icon type="info" style={infoIconStyle} />
            </InfoIconContainer>
          </Tooltip>
        ) : null
      }
    </Row>
  );
};

const CostRow = ({ value, label, percentage = false }: {value: number, label: string, percentage?: boolean}) => {
  if (value === undefined || value == null) return null;
  if (percentage) return <span>{`${value}% ${label}`}</span>;
  return <span>{`${label}: ${formatCurrency(value)}`}</span>;
};

const checkHasValue = (value: number) => value !== undefined && value !== null;

function formatCurrency(currency: number) {
  const formatted = formatCurrencyTruncated(currency, false, false);
  if (!formatted) return '-';
  return formatted;
}
