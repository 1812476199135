import * as React from 'react';
import { CustomerNameProps } from './CustomerName.interfaces';
import {
  CustomerNameContainer,
} from './CustomerName.elements';

export const CustomerName: React.FunctionComponent<CustomerNameProps> = ({
  name,
  altName,
}) => (
  <CustomerNameContainer data-qa="customer-name">
    { (name && formatName(name)) || altName }
  </CustomerNameContainer>
);

function formatName(name: CustomerNameProps['name']): string {
  return [
    formatSalutation(name.salutation),
    name.first,
    name.last,
    name.preferredFirstName ? `(${name.preferredFirstName})` : null,
  ].filter((part) => !!part).join(' ');
}

function formatSalutation(salutation: string): string {
  if (!salutation) {
    return null;
  }
  return `${salutation.toLowerCase().replace(/^\w/, (c) => c.toUpperCase())}.`;
}
