import * as React from 'react';
import { isPresent } from '@healthiqeng/core.util.is-present';
import { QuestionType } from '@hiq/crm.types';
import { useActiveLeadId } from '../../routers/LeadRouter/useActiveLeadId';
import { useFormAnswers } from '../../../hooks';
import { QuestionCell } from '../FormGenerator/FormGeneratorCompact/Question/QuestionCell';
import { InputFactory } from '../inputs/InputFactory';
import { noop } from '../../../util/noop';

export const PncHomeCoverages: React.FunctionComponent = () => {
  const leadId = useActiveLeadId();
  const [answers] = useFormAnswers(leadId);
  const squareFootage = answers['lead.dwelling.squareFootage'] as number;
  const DWELLING_COVERAGE_ID = 'lead.dwelling.coverage';
  const DWELLING_REPLACEMENT_COST_ID = 'lead.dwelling.replacementCost';

  if (!isPresent(squareFootage)) {
    return <>Please fill out Dwelling Sq. Footage</>;
  }

  return (
    <>
      <QuestionCell
        label="Dwelling"
        input={(
          <InputFactory
            type={QuestionType.Currency}
            value={answers[DWELLING_COVERAGE_ID]}
            onChange={noop}
            referenceId={DWELLING_COVERAGE_ID}
            answersKey={DWELLING_COVERAGE_ID}
            disabled
          />
        )}
      />
      <QuestionCell
        label="Estimated Replacement Cost"
        input={(
          <InputFactory
            type={QuestionType.Currency}
            value={answers[DWELLING_REPLACEMENT_COST_ID]}
            onChange={noop}
            referenceId={DWELLING_REPLACEMENT_COST_ID}
            answersKey={DWELLING_REPLACEMENT_COST_ID}
            disabled
          />
        )}
      />
    </>
  );
};
