export const MQ = {
  MOBILE_XS: '@media (max-width: 320px)',
  MOBILE: '@media (min-width: 321px) and (max-width: 414px)',
  TABLET: '@media (min-width: 415px) and (max-width: 768px)',
  DESKTOP: '@media (min-width: 769px)',
};

export const MQ_CASCADE = {
  MOBILE_XS: '@media (max-width: 320px)',
  MOBILE: '@media (max-width: 414px)',
  TABLET: '@media (max-width: 768px)',
};

export const COLOR = {
  GREEN: '#5ECD82',
  GREEN_SELECTED: '#3f8d59',
  BLUE: '#173A68',
  BLUE_SELECTED: '#174775',
  ORANGE: '#FF8D4A',
  RED: '#E14747',
  RED_SELECTED: '#e15353',
  WHITE: '#fff',
  WHITE_SELECTED: '#fff',
  PLACEHOLDER_TEXT: '#a4a4a4',
};
