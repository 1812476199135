import * as React from 'react';
import { MedicarePlanInformationPanelProps } from './MedicarePlanInformationPanel.interfaces';
import { MedicarePlanInformationPanelContainer } from './MedicarePlanInformationPanel.elements';
import { FormGenerator, FormGeneratorStyle } from '../../../../../common/FormGenerator';

export const MedicarePlanInformationPanel: React.FunctionComponent<MedicarePlanInformationPanelProps> = ({
  leadId,
}) => (
  <MedicarePlanInformationPanelContainer>
    <FormGenerator
      lockable
      style={FormGeneratorStyle.Compact}
      leadId={leadId}
      referenceId="medicare-plan"
    />
  </MedicarePlanInformationPanelContainer>
);
