import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { AudienceQuery } from './useAudienceQuery.interfaces';

const AUDIENCE_QUERY = gql`
  query getAllAudiencesScriptsByLeadId($leadId: String) {
    getAllAudiencesScriptsByLeadId(leadId: $leadId) {
      id
      name
      script
    }
  }
`;

export function useLeadAudiencesQuery(leadId?: string) {
  const result = useQuery<AudienceQuery>(AUDIENCE_QUERY, { variables: { leadId } });
  return result;
}
