import { useCallback } from 'react';
import { ExecutionResult } from '@apollo/react-common';
import { useCustomerId } from '../../../components/routers/CustomerRouter/useCustomerId';
import { useActiveLeadId } from '../../../components/routers/LeadRouter/useActiveLeadId';
import { useLogUserInteractionMutation } from './useLogUserInteractionMutation';
import { MutationCustomReturn } from '../useMutationCustom';
import { LogInteractionOptions, LogUserInteractionMutationResponse } from './useLogUserInteractionMutation.interfaces';

export function useLogInteraction(): MutationCustomReturn<LogUserInteractionMutationResponse, LogInteractionOptions> {
  const customerId = useCustomerId();
  const leadId = useActiveLeadId();
  const [logUserInteractionMutationFunction, result] = useLogUserInteractionMutation();
  const partialLogUserInteractionMutationFunction = useCallback(({ type, target, detail }: LogInteractionOptions) => {
    // Don't send an event if both values are present. There are
    // cases where a user may access a path in the application where
    // customerId is present but leadId isn't (momentarily, the router
    // should redirect them to a lead soon).
    if (!customerId || !leadId) { return Promise.resolve({} as ExecutionResult<LogUserInteractionMutationResponse>); }

    const timestamp = new Date().toISOString();
    return logUserInteractionMutationFunction({
      interaction: {
        type,
        target,
        detail,
        timestamp,
        leadId,
        customerId,
      },
    });
  }, [logUserInteractionMutationFunction]);

  return [
    partialLogUserInteractionMutationFunction,
    result,
  ];
}
