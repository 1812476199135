import * as React from 'react';
import { QuoteOptionProps } from './QuoteOption.interfaces';
import {
  QuoteOptionContainer,
  QuoteOptionLabel,
  QuoteOptionInputContainer,
} from './QuoteOption.elements';
import { InputFactory } from '../../../inputs/InputFactory';
import { isTextInputQuestionType } from '../../../../../util';

export const QuoteOption: React.FunctionComponent<QuoteOptionProps> = ({
  id,
  label,
  value,
  type,
  handleChange,
  helperText,
  options,
  disabled,
  loading,
}) => (
  <QuoteOptionContainer>
    <QuoteOptionLabel>
      { label }
    </QuoteOptionLabel>
    <QuoteOptionInputContainer>
      <InputFactory
        type={type}
        options={options}
        value={value}
        onChange={(val) => handleChange(id, val, isTextInputQuestionType(type))}
        validationMessage={helperText}
        size="small"
        disabled={disabled}
        loading={loading}
        fullWidth
        dataQa={`quote-option-${id}`}
      />
    </QuoteOptionInputContainer>
  </QuoteOptionContainer>
);
