import {
  Column,
  CompareData,
  CompareDataBold,
  CompareRow, CompareSubTitle, Row,
} from './TRMQuote.elements';
import React from 'react';
import { CompareBenefitProps } from './TRMQuote.interfaces';
import { useToggle } from '../../CheckMyHealthRecord/CheckMyHealthRecordDataGrid/useToggle';
import { DetailsDialog } from './CompareGrid/DetailsDialog';

export const Compare = ({
  benefitName, currentValue, comparisonValue, currentValueRank, comparisonValueRank, totalPlans, hiqContext,
}: CompareBenefitProps) => {
  const [open, toggle] = useToggle();

  return (
    <>
      <CompareRow>
        <CompareDataBold>
          <Row width="100%" justifyContent="space-between">
            {benefitName}
            <CompareSubTitle onClick={toggle}>
              Details
            </CompareSubTitle>
          </Row>
        </CompareDataBold>
        <CompareData empty={currentValue === undefined} highlight={currentValueRank < comparisonValueRank}>
          {currentValue ? (
            <Column>
              <b>{`Ranked #${currentValueRank} / ${totalPlans}`}</b>
              {currentValue}
            </Column>
          ) : undefined}
        </CompareData>
        <CompareData highlight={comparisonValueRank < currentValueRank}>
          <Column>
            <b>{`Ranked #${comparisonValueRank} / ${totalPlans}`}</b>
            {comparisonValue}
          </Column>
        </CompareData>
      </CompareRow>
      <DetailsDialog
        title={benefitName}
        message={hiqContext}
        handleClose={toggle}
        open={open}
      />
    </>
  );
};
