import * as React from 'react';
import { QuestionFlowProps } from './QuestionFlow.interfaces';
import { useQuestionFlowQuery } from '../../../graphql';
import { QueryLoader } from '../QueryLoader';
import { QuestionFlowRenderer } from './QuestionFlowRenderer';
import { MultiSectionQuestionFlowRenderer } from './MultiSectionQuestionFlowRenderer';
import { CustomElement } from '../CustomElement';
import { useDialerCallContext } from '../../routers';
import { getPageLoadTime } from '../../../util';
import { useEffectOnce } from '../../../hooks';
import { emitAnalyticsEvent } from '../../../events/emitAnalyticsEvent';

export const QuestionFlow: React.FunctionComponent<QuestionFlowProps> = React.memo(({
  leadId,
  referenceId,
  root,
  split = false,
  activeSectionIndex,
  onNext,
  onPrevious,
  allowExternal,
}) => {
  const callUrlPath = useDialerCallContext();
  const {
    data, loading, error,
  } = useQuestionFlowQuery(leadId, referenceId, { root, allowExternal, callUrlPath });

  useEffectOnce(() => {
    if (referenceId === 'interview') {
      emitAnalyticsEvent('Agent Interview Question Flow Displayed', { loadTime: getPageLoadTime() });
    }
  }, [data, referenceId]);

  return (
    <QueryLoader loading={loading} error={error} componentName="QuestionFlow">
      {!split
        ? (
          <QuestionFlowRenderer
            questionFlowComponent={QuestionFlow}
            CustomElement={CustomElement}
            leadId={leadId}
            markdownSource={data?.questionFlow?.body || ''}
          />
        ) : (
          <MultiSectionQuestionFlowRenderer
            leadId={leadId}
            markdownSource={data?.questionFlow?.body || ''}
            active={activeSectionIndex}
            onNext={onNext}
            onPrevious={onPrevious}
            CustomElement={CustomElement}
          />
        )}
    </QueryLoader>
  );
});
