import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../themes';

export const CreateTaskWidgetContainer = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  background-color: ${theme.color.white};
  padding: 20px;
  max-width: 400px;
  position: absolute;
  top: 45%;
  left: 55%;
  box-shadow: 0px 8px 16px -4px;
  border-radius: 3px;
`);
