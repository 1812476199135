import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const SubjectInput = styled.input`
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
`;

export const MessageInput = styled('textarea')(css`
  min-height: 300px;
  width: 100%;
  resize: vertical;
  outline: none;
  padding: 5px;
  font: inherit;
  margin-bottom: 10px;
`);

export const FeedbackMessage = styled.div`
  font-weight: bold;
  text-align: center;
`;

export const Label = styled.label`
  font-size: 12px;
`;

export const AccordionTitle = styled('h4')(css``);
