import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const Container = styled('div')(css`
  padding: 10px;
  margin: 0;
  max-width: 700px;
`);

export const Title = styled('h1')(css`
  padding: 0;
`);

export const UploadButtonSection = styled('section')(css`
  padding: 10px 0 50px;
`);

export const Action = styled('div')(css`
  display: inline-block;
  padding-right: 20px;
`);

export const FileListTitle = styled('h4')(css`
  padding: 0 20px;
`);

export const FileList = styled('ul')(css`
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ddd;
  border-radius: 4px;
`);

export const FileListItem = styled('li')(css`
  padding: 10px 20px;
  width: 100%;
`);

export const FileSize = styled('span')(css`
  float: right;
`);

export const RemoveFile = styled('div')(css`
  padding: 10px 0;
  text-align: right;
`);

export const EmptyFileListItem = styled('li')(css`
  padding: 10px 20px;
`);

export const FileVerificationSection = styled('section')(css`
  margin-top: 40px;
`);

export const FileVerificationAction = styled('div')(css`
  padding-right: 20px;
`);

export const FileVerificationDescription = styled('p')(css`
  padding: 0;
  margin: 0 0 20px;
  color: #bbb;
  font-style: italic;
`);

export const FileVerificationPicker = styled('div')(css`
  width: 100%;
  margin: 20px 0;
`);

export const ResultsSection = styled('section')(css`
  padding-top: 60px;
`);

export const ResultsTitle = styled('h1')(css`
  padding: 10px;
`);
