import React from 'react';
import ReactMarkdown from 'react-markdown';
import {
  InlineTextButonIconOpen,
  InlineTextButonIconClose,
  InlineTextButton,
  InlineTextContainer,
  InlineTextButtonContainer,
  InlineTextContent,
} from './InlineText.elements';
import { InlineTextElement, InlineTextProps } from './InlineText.interfaces';

export const InlineText: React.FC<InlineTextProps> = ({ value }) => {
  const [activeElement, setActiveElement] = React.useState<InlineTextElement | undefined>();

  const lines = value.split('\n\n');

  const elements: InlineTextElement[] = lines.map((line) => {
    const [title, content] = line.split('::');
    return {
      title,
      content,
    };
  });

  function handleClickButton(element: InlineTextElement) {
    if (element.title === activeElement?.title) {
      setActiveElement(undefined);
    } else {
      setActiveElement(element);
    }
  }

  return (
    <InlineTextContainer>
      <InlineTextButtonContainer>
        {elements.map((element) => (
          <InlineTextButton key={element.title} onClick={() => handleClickButton(element)}>
            More Info:
            {element.title}
            {activeElement?.title === element.title ? <InlineTextButonIconClose /> : <InlineTextButonIconOpen />}
          </InlineTextButton>
        ))}
      </InlineTextButtonContainer>
      {activeElement && (
        <InlineTextContent>
          <b>
            {activeElement.title}
            :
          </b>
          <ReactMarkdown linkTarget="_blank">{activeElement.content}</ReactMarkdown>
        </InlineTextContent>
      )}
    </InlineTextContainer>
  );
};
