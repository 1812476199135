import * as React from 'react';
import { QuestionViewProps } from './QuestionView.interfaces';
import { InputFactory } from '../../../../inputs/InputFactory';
import { QuestionCell } from '../QuestionCell';
import { QuestionValueEditable } from './QuestionValueEditable';

export const QuestionView: React.FunctionComponent<QuestionViewProps> = ({
  label,
  tags,
  type,
  placeholder,
  options,
  optionsId,
  value,
  onChange,
  lockable,
  editing,
  onEdit,
  locked,
  dataQa,
  referenceId,
  answersKey,
}) => (
  <QuestionCell
    label={label}
    tags={tags}
    input={(
      ((!lockable || editing) && !locked) ? (
        <InputFactory
          type={type}
          placeholder={placeholder}
          options={options}
          optionsId={optionsId}
          value={value}
          onChange={onChange}
          size="small"
          variant="outlined"
          fullWidth
          dataQa={dataQa}
          referenceId={referenceId}
          answersKey={answersKey}
        />
      ) : (
        <QuestionValueEditable
          type={type}
          value={value}
          onEdit={onEdit}
          options={options}
          locked={locked}
        />
      )
    )}
  />
);
