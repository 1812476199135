import { HealthRecordDoctor } from '@hiq/crm.types';
import { noop } from '../../../../../util';
import React, { ChangeEvent } from 'react';
import { Row, UseRowSelectRowProps } from 'react-table';
import { SelectionLabel, SwitchContainer } from '../../../Datagrid/Datagrid.elements';
import { RowSelectionCheckbox } from '../../../Datagrid/inputs';
import { DoctorSelectionColumnProps } from './useDoctorColumnConfig.interfaces';

export const DoctorRemovalSelectionColumn = ({ row, onSelect, onCriticalSelect }: DoctorSelectionColumnProps) => {
  const toggleRowSelectedProps = (row as Row<HealthRecordDoctor> & UseRowSelectRowProps<HealthRecordDoctor>).getToggleRowSelectedProps();
  const onContainerClick = () => {
    toggleRowSelectedProps.onChange({ target: { value: toggleRowSelectedProps.checked } } as any as ChangeEvent);
    if ((row.original as any).selected && (row.original as any).criticalSelected) {
      onCriticalSelect({ ...row.original, ...row.values });
    }
    onSelect({ ...row.original, ...row.values });
  };
  return (
    <SwitchContainer onClick={onContainerClick}>
      <SelectionLabel color="#E14747">Remove?</SelectionLabel>
      <RowSelectionCheckbox color="#E14747" {...toggleRowSelectedProps} onChange={noop} />
    </SwitchContainer>
  );
};
