import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  SunfireV2DrugDosagesQueryData, SunfireV2DrugDosagesQueryVariables,
} from './useSunfireV2DrugDosagesQuery.interfaces';

const SUNFIRE_DRUG_DOSAGES_QUERY = gql`
  query FindSunfireV2DrugDosages($drugId: String!) {
    findSunfireDrugDosages(drugId: $drugId) {
      drugDosage {
        id
        dosages {
          id
          name
          quantity
          strength
          strengthUnitOfMeasure
          form
          isGeneric
          packages {
            packageId
            packageDescription
            priceCategoryId
            proxyNationalDrugCode
            isCommonPackage
          }
          isCommonDosage
          defaultFrequency
        }
      }
      drugs {
        id
        name
        drugType
      }
    }
  }
`;

export function useSunfireV2DrugDosagesQuery(drugId: string) {
  return useLazyQuery<SunfireV2DrugDosagesQueryData, SunfireV2DrugDosagesQueryVariables>(SUNFIRE_DRUG_DOSAGES_QUERY, {
    variables: { drugId },
  });
}
