import * as React from 'react';
import { AutomatedUnderwritingResultStatus, FormItemTag } from '@hiq/crm.types';
import { AutomatedUnderwritingProps } from './AutomatedUnderwriting.interfaces';
import { AutomatedUnderwritingContainer, AutomatedUnderwritingError } from './AutomatedUnderwriting.elements';
import {
  useLeadQuery,
  useUnderwriteMutation,
  useValidForTagQuery,
} from '../../../../graphql';
import { QueryLoader } from '../../QueryLoader';
import { AutomatedUnderwritingResult } from './AutomatedUnderwritingResult';
import { RunAutomatedUnderwriting } from './RunAutomatedUnderwriting';
import { ApprovalStatus, UnderwritingClassGeneric } from '@healthiqeng/core.types';
import { SpecialRatePrompt } from './SpecialRatePrompt';

export const AutomatedUnderwriting: React.FunctionComponent<AutomatedUnderwritingProps> = ({
  dataQa,
}) => {
  const {
    data: validationData,
    loading: validationLoading,
    error: validationError,
  } = useValidForTagQuery(FormItemTag.RequiredForAutomatedUnderwriting);
  const [underwrite, {
    data: underwriteData,
    loading: underwriteLoading,
    error: underwriteError,
  }] = useUnderwriteMutation();
  const {
    data: leadData,
    loading: leadLoading,
    error: leadError,
  } = useLeadQuery();
  const selectedQuoteHash = leadData?.selectedQuote?.hash;
  const underwritingResult = leadData?.automatedUnderwritingResults
    ?.find(({ quoteHash, status }) => quoteHash === selectedQuoteHash
      && (!status || status === AutomatedUnderwritingResultStatus.Success));

  const loading = validationLoading || underwriteLoading || leadLoading;
  const error = validationError || underwriteError || leadError;
  const isDeclined = underwritingResult?.result === UnderwritingClassGeneric.Decline;
  const isSpecialRateUpgradeAvailable = underwriteData?.underwrite?.isSpecialRateUpgradeAvailable;

  const declinedRef = React.useRef(false);
  React.useEffect(() => {
    declinedRef.current = false;
  }, [selectedQuoteHash]);
  const onPromptClose = React.useCallback(() => {
    declinedRef.current = true;
  }, []);
  return (
    <QueryLoader loading={loading} error={error} componentName="AutomatedUnderwriting">
      <AutomatedUnderwritingContainer data-qa={dataQa}>
        { !!underwriteData?.underwrite?.errors?.length && (
          <AutomatedUnderwritingError>
            { underwriteData?.underwrite?.message }
          </AutomatedUnderwritingError>
        ) }
        { underwriteData?.underwrite?.errors?.map((message) => (
          <AutomatedUnderwritingError key={message}>
            { message }
          </AutomatedUnderwritingError>
        )) }
        { (underwritingResult) && (
          <AutomatedUnderwritingResult
            errors={underwriteData?.underwrite?.errors}
            approval={isDeclined ? ApprovalStatus.Declined : underwriteData?.underwrite?.approval}
            productType={leadData?.selectedQuote?.product?.type}
            quoteHash={selectedQuoteHash}
            approvedUnderwritingClass={leadData?.selectedQuote?.underwritingClass}
          />
        ) }
        { !underwritingResult && !underwriteData?.underwrite?.errors?.length && (
          <RunAutomatedUnderwriting
            onUnderwrite={() => underwrite()}
            loading={loading}
            validationSuccess={validationData?.validForTag?.success}
            validationErrors={validationData?.validForTag?.errors}
          />
        ) }
        { !declinedRef.current && isSpecialRateUpgradeAvailable && <SpecialRatePrompt onClose={onPromptClose} />}
      </AutomatedUnderwritingContainer>
    </QueryLoader>
  );
};
