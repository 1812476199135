import styled from '@emotion/styled';
import { css } from '@emotion/core';

type ContainerProps = {
  variation: string;
};

export const NavBarAppointmentContainer = styled('div')(css`
  display: flex;
  color: white;
  width: 290px;
  margin-right: 10px;
`);

export const NavBarAppointmentTitle = styled('div')(css`
  text-transform: uppercase;
  text-align: center;
  font-size: 15px;
  margin: auto 8px auto 0;  
`);

export const NavBarAppointmentContent = styled('div')<ContainerProps>((props) => css`
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  background-color: ${props.variation};
  padding: 8px 0 8px 0;
  width: 85%;
`);
