import * as React from 'react';
import { injectGlobal } from 'emotion';
import Helmet from 'react-helmet';
import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { ApolloProvider } from '@apollo/react-hooks';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import CssBaseline from '@material-ui/core/CssBaseline';
import { AnalyticsEvent, SetPropsEvent } from '@healthiqeng/core.services.fe-analytics';
import { PageProps } from './Page.interfaces';
import { logger, eventBus } from '../../../services';
import { getEventBus } from '../../../events';
import { AmplitudeService } from '@healthiqeng/core.services.amplitude';
import {
  UrlService,
} from '@healthiqeng/core.infrastructure';
import { PageLoadDetector } from './PageLoadDetector';
import { config } from '../../../config';

// eslint-disable-next-line
injectGlobal`
  * {
    box-sizing: border-box;
  }
  body {
    margin: 0;
  }
  html {
    overflow-y: auto;
  }
`;

export const Page: React.FunctionComponent<PageProps> = ({
  title,
  theme,
  muiTheme,
  graphqlClient,
  children,
}) => {
  const eventBus = getEventBus();
  React.useEffect(() => {
    initAmplitudeService();
    const pageLoadDetector = new PageLoadDetector(getEventBus());
    pageLoadDetector.init();
    eventBus.emit(new SetPropsEvent({ pageName: title }));
    eventBus.emit(new AnalyticsEvent('Page Loaded', { loadTime: getPageLoadTime(), pageName: title }));
    if (process.env.NODE_ENV === 'production') {
      logger.info(`HIQ CRM Build ${process.env.HIQ_CRM_BUILD_VERSION}`);
    }
  }, []);

  return (
    <>
      <Helmet title={title}>
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${getGoogleTagId()}`} />
        <script>{getGoogleAnalyticsSnippet()}</script>
        <link rel="icon" href="https://media.healthiq.com/favicon/favicon-32x32.png?v=2" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      </Helmet>
      <CssBaseline />
      <ApolloProvider client={graphqlClient as any}>
        <EmotionThemeProvider theme={theme}>
          <MuiThemeProvider theme={muiTheme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              { children }
            </MuiPickersUtilsProvider>
          </MuiThemeProvider>
        </EmotionThemeProvider>
      </ApolloProvider>
    </>
  );
};

function initAmplitudeService(): AmplitudeService {
  const amplitudeKey = process.env.AMPLITUDE_KEY;
  logger.info('Amplitude Key', amplitudeKey);
  return new AmplitudeService(
    amplitudeKey,
    eventBus,
    new UrlService(),
    'trm',
    '1.0.0',
    config.AMPLITUDE.RATE_LIMIT,
    logger,
  );
}

function getPageLoadTime(): number {
  const perfEntries = performance.getEntriesByType('navigation');
  const entry = perfEntries[0] as PerformanceNavigationTiming;
  const now = performance.now();
  const loadTime = (now - entry.startTime) / 1000;
  return loadTime;
}

function getGoogleTagId(): string {
  if (config.ENV === 'production') return 'G-L8DKY3P4R5';
  if (['staging', 'uat'].includes(config.ENV)) return 'G-HMH23S83WH';
  return '';
}

function getGoogleAnalyticsSnippet(): string {
  return `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', '${getGoogleTagId()}');
  `;
}
