import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../themes';
import { CardProps } from './Card.interfaces';

type AllProps = ComponentWithThemeProps & CardProps;
export const Card = styled('div')<AllProps>(({
  theme, highlight, margin, padding,
}) => css`
  background-color: ${theme.color.white};
  border: 1px solid ${theme.color.grayLight};
  box-shadow:
    ${highlight ? '0px 0px 13px 0px' : '0px 0px 10px 0px'}
    ${highlight ? `${theme.color.primary}A0` : '#00000033'};
  transform: scale(${highlight ? '1.01' : '1'});
  margin: ${margin ?? 0};
  padding: ${padding ?? '15px'};
`);
