import * as React from 'react';
import { SidePanelContentLayoutProps } from './SidePanelContentLayout.interfaces';
import {
  SidePanelContentBodyContainer,
  SidePanelContentContainer,
  SidePanelContentHeader,
} from './SidePanelContentLayout.elements';

export const SidePanelContentLayout: React.FunctionComponent<SidePanelContentLayoutProps> = ({
  header,
  children,
}) => (
  <SidePanelContentContainer>
    <SidePanelContentHeader>
      { header }
    </SidePanelContentHeader>
    <SidePanelContentBodyContainer>
      { children }
    </SidePanelContentBodyContainer>
  </SidePanelContentContainer>
);
