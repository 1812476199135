import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../../themes';

export const TransferNotesContainer = styled('div')<ComponentWithThemeProps>(css``);

export const TransferNotesRow = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  border-top: 1px solid ${theme.color.grayLight};
  padding: 2px 0;
`);

export const TransferNotesLabel = styled('div')(css`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 4px;
`);

export const TransferNotesValue = styled('div')(css`
  font-size: 14px;
  line-height: 1.2
`);

export const TransferNotesValueLine = styled('div')(css``);
