import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { CustomerLatestCallQueryData, CustomerLatestCallQueryVariables } from './useCustomerLatestCallQuery.interfaces';

export const CUSTOMER_LATEST_CALL_QUERY = gql`
    query getCustomerLatestCall($customerId: ID) {
      customer(id: $customerId) {
        id
        latestCallEvent {
          campaignName
          callSkill
        }
      }
    }
`;

export function useCustomerLatestCallQuery(customerId: string) {
  const result = useQuery<CustomerLatestCallQueryData, CustomerLatestCallQueryVariables>(CUSTOMER_LATEST_CALL_QUERY, {
    variables: {
      customerId,
    },
  });
  return result;
}
