import * as React from 'react';
import { ChangeEvent } from 'react';
import { RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import { useFormAnswers } from '../../../../hooks';
import { useActiveLeadId } from '../../../routers/LeadRouter';
import { Question } from '../Question';
import { PaymentMethodRadioProps } from './PaymentMethodRadio.interfaces';

const FREQUENCY_BASED_ON_METHOD: Record<string, string> = {
  BILL: 'annual',
  EFT: 'monthly',
};

export const PaymentMethodRadio: React.FunctionComponent<PaymentMethodRadioProps> = () => {
  const leadId = useActiveLeadId();
  const [answers, onChange, persistAnswers] = useFormAnswers(leadId);
  const handlePaymentMethodChange = React.useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onChange('lead.csg.application.payment_mode', value, true);
    onChange('lead.csg.application.payment_modal', FREQUENCY_BASED_ON_METHOD[value], true);
    persistAnswers();
  }, [onChange, persistAnswers]);

  const paymentMethod = answers?.['lead.csg.application.payment_mode'];

  return (
    <Question questionText="Payment Method">
      <RadioGroup row>
        <FormControlLabel
          label="Direct Bill"
          checked={paymentMethod === 'BILL'}
          control={(
            <Radio
              value="BILL"
              onChange={handlePaymentMethodChange}
              color="primary"
            />
            )}
        />
        <FormControlLabel
          label="Automated Bank Account Withdrawal"
          checked={paymentMethod === 'EFT'}
          control={(
            <Radio
              value="EFT"
              onChange={handlePaymentMethodChange}
              color="primary"
            />
            )}
        />
      </RadioGroup>
    </Question>
  );
};
