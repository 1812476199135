import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import {
  SelectedICDsQueryData,
  SelectedICDsQueryVariables,
} from './useSelectedICDsQuery.interfaces';
import { PRECISION_MEDICARE_ICD_FRAGMENT } from '../../fragments';

export const SELECTED_ICDS_QUERY = gql`
  query SelectedICDs($leadId: LeadId!) {
    selectedICDs(leadId: $leadId) {
      ...PrecisionMedicareICDFragment
    }
  }
  ${PRECISION_MEDICARE_ICD_FRAGMENT}
`;

export function useSelectedICDsQuery(leadId: string) {
  return useQuery<SelectedICDsQueryData, SelectedICDsQueryVariables>(SELECTED_ICDS_QUERY, {
    variables: { leadId },
  });
}
