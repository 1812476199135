import { css } from '@emotion/core';
import {
  baseNormalizeA,
  baseNormalizeButton,
  baseNormalizeFieldset,
  baseNormalizeH1,
  baseNormalizeHr,
  baseNormalizeImg,
  baseNormalizeInput,
  baseNormalizeInputTypeCheckbox,
  baseNormalizeInputTypeNumber,
  baseNormalizeInputTypeRadio,
  baseNormalizeInputTypeSearch,
  baseNormalizeOptGroup,
  baseNormalizeSelect,
  baseNormalizeSub,
  baseNormalizeSup,
  baseNormalizeTextArea,
} from './baseNormalizers';
import {
  hiqNormalizeA,
  hiqNormalizeArticle,
  hiqNormalizeButton,
  hiqNormalizeDiv,
  hiqNormalizeFooter,
  hiqNormalizeForm,
  hiqNormalizeH1,
  hiqNormalizeH2,
  hiqNormalizeH3,
  hiqNormalizeH4,
  hiqNormalizeH5,
  hiqNormalizeH6,
  hiqNormalizeHeader,
  hiqNormalizeIframe,
  hiqNormalizeImg,
  hiqNormalizeInput,
  hiqNormalizeLi,
  hiqNormalizeOl,
  hiqNormalizeP,
  hiqNormalizeSpan,
  hiqNormalizeUl,
} from './hiqNormalizers';

export const resetHr = baseNormalizeHr;
export const resetSub = baseNormalizeSub;
export const resetSup = baseNormalizeSup;
export const resetSpan = hiqNormalizeSpan;
export const resetOptGroup = baseNormalizeOptGroup;
export const resetTextArea = baseNormalizeTextArea;
export const resetInput = css`
  ${baseNormalizeInput}
  ${hiqNormalizeInput}
`;
export const resetSelect = baseNormalizeSelect;
export const resetFieldset = baseNormalizeFieldset;
export const resetInputTypeCheckbox = baseNormalizeInputTypeCheckbox;
export const resetInputTypeRadio = baseNormalizeInputTypeRadio;
export const resetInputTypeNumber = baseNormalizeInputTypeNumber;
export const resetInputTypeSearch = baseNormalizeInputTypeSearch;

export const resetDiv = hiqNormalizeDiv;
export const resetArticle = hiqNormalizeArticle;
export const resetHeader = hiqNormalizeHeader;
export const resetFooter = hiqNormalizeFooter;
export const resetNav = hiqNormalizeDiv;
export const resetUl = hiqNormalizeUl;
export const resetOl = hiqNormalizeOl;
export const resetForm = hiqNormalizeForm;
export const resetIframe = hiqNormalizeIframe;
export const resetImg = css`
  ${baseNormalizeImg};
  ${hiqNormalizeImg};
`;

export const resetH1 = css`
  ${baseNormalizeH1};
  ${hiqNormalizeH1};
`;

export const resetP = hiqNormalizeP;
export const resetLi = hiqNormalizeLi;
export const resetH2 = hiqNormalizeH2;
export const resetH3 = hiqNormalizeH3;
export const resetH4 = hiqNormalizeH4;
export const resetH5 = hiqNormalizeH5;
export const resetH6 = hiqNormalizeH6;

export const resetA = css`
  ${baseNormalizeA};
  ${hiqNormalizeA};
`;

export const resetButton = css`
  ${baseNormalizeButton};
  ${hiqNormalizeButton};
`;
