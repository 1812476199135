import * as React from 'react';
import { CallDirection, DialerEvent } from '@healthiqeng/core.services.dialer.base';
import { useCurrentCall } from '@healthiqeng/core.hooks.use-dialer-service';
import { currentCallPollingTimer, PollingTimerEvent, logger } from '../../../services';
import { getEventBus, useEventListener, VariationChangeEvent } from '../../../events';
import { useCurrentUserQuery, useLogCurrentCallMutation } from '../../../graphql';
import { useCustomerId } from '../../routers';
import { ErrorBoundary } from '../ErrorBoundary';

const DialerListenerInner: React.FunctionComponent = () => {
  const {
    loading: currentCallLoading,
    error: currentCallError,
    callData,
    refreshCallData,
  } = useCurrentCall();
  const [previousCallDirection, setPreviousCallDirection] = React.useState<CallDirection>();
  const eventBus = getEventBus();
  const [logCurrentCall] = useLogCurrentCallMutation();
  const customerId = useCustomerId();
  const [persistedCallId, setPersistedCallId] = React.useState<string>(null);
  const { data: userData } = useCurrentUserQuery();

  useEventListener(PollingTimerEvent, (event: PollingTimerEvent) => {
    if (event.tag !== 'CURRENT_CALL') return;
    refreshCallData();
  }, [refreshCallData, logCurrentCall]);

  useEventListener(DialerEvent, (event: DialerEvent) => {
    const newRelic = (window as any).newrelic;
    if (!newRelic) return;

    newRelic.addPageAction(event.message, {
      ...event.properties,
      agentTrmId: userData?.currentUser?.id,
      agentName: userData?.currentUser?.name,
      agentEmail: userData?.currentUser?.email,
      agentProfile: userData?.currentUser?.profile,
    });
  });

  // Start the polling timer on the first render only. Will poll dialer status
  // at a fixed interval
  React.useEffect(() => {
    currentCallPollingTimer.start();
  }, []);

  React.useEffect(() => {
    if (callData?.direction && callData.direction !== previousCallDirection) {
      setPreviousCallDirection(callData.direction);
      eventBus.emit(new VariationChangeEvent());
    }
  }, [callData?.direction]);

  React.useEffect(() => {
    if (callData?.id === persistedCallId) return;
    if (currentCallError || currentCallLoading || !callData?.id) return;
    logCurrentCall({
      customerId,
      input: callData,
    })
      .then(() => setPersistedCallId(callData?.id))
      .catch((err) => logger.crit(err));
  }, [callData?.id]);

  React.useEffect(() => {
    if (currentCallError) {
      logger.crit(currentCallError);
      currentCallPollingTimer.stop();
    }
  }, [currentCallError]);

  return null;
};

export const DialerListener: React.FunctionComponent = () => (
  <ErrorBoundary>
    <DialerListenerInner />
  </ErrorBoundary>
);
