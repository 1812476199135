import * as React from 'react';
import { DialerTransferDispositionsProps } from './DialerTransferDispositions.interfaces';
import {
  DialerTransferDispositionsContainer,
  Container,
  LoadingWrapper,
} from './DialerTransferDispositions.elements';
import { useDialerTransferButtons } from '../useDialerTransferButtons';
import { LoadingDialerContainer } from '../DialerTransferButtons.elements';
import { Spinner } from '@healthiqeng/core.ui';
import { Alert } from '@material-ui/lab';
import { DialerDispositionButtonFactory } from './DialerDispositionFactory';

export const DialerTransferDispositions: React.FunctionComponent<DialerTransferDispositionsProps> = () => {
  const {
    error,
    errorMessage,
    loadingDispositions,
    beforeTransferDispositions,
    alwaysVisibleDispositions,
    initiatedConference,
    disabled,
    currentCallInProgress,
    handleDisposition,
    endingCall,
  } = useDialerTransferButtons();

  if (loadingDispositions) {
    return (
      <Container>
        <LoadingWrapper>
          <LoadingDialerContainer>
            <Spinner />
          </LoadingDialerContainer>
          Loading Dialer Dispositions
        </LoadingWrapper>
      </Container>
    );
  }

  return (
    <Container>
      {(error) && (
        <Alert variant="filled" severity="error">
          Dialer Error. Please use Five9 for disposition
          <br />
          { errorMessage }
        </Alert>
      )}
      <DialerTransferDispositionsContainer>
        {
          alwaysVisibleDispositions?.map((disposition) => (
            <DialerDispositionButtonFactory
              key={disposition.value}
              label={disposition.label}
              onClick={() => handleDisposition(disposition.value)}
              disabled={disabled || endingCall}
              value={disposition.value}
            />
          ))
        }
      </DialerTransferDispositionsContainer>
      { !initiatedConference && (
        <DialerTransferDispositionsContainer>
          {
            beforeTransferDispositions?.map((disposition) => (
              <DialerDispositionButtonFactory
                value={disposition.value}
                label={disposition.label}
                onClick={() => handleDisposition(disposition.value)}
                disabled={disabled || endingCall}
                key={disposition.value}
              />
            ))
          }
        </DialerTransferDispositionsContainer>
      )}
      {(!currentCallInProgress) && (
        <Alert variant="outlined" severity="info">
          No Current Call
        </Alert>
      )}
    </Container>
  );
};
