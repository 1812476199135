import * as React from 'react';
import { RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import { useFormAnswers } from '../../../../hooks';
import { useActiveLeadId } from '../../../routers/LeadRouter';
import { Question } from '../Question';
import { PaymentModalRadioProps } from './PaymentModalRadio.interfaces';

export const PaymentModalRadio: React.FunctionComponent<PaymentModalRadioProps> = () => {
  const leadId = useActiveLeadId();
  const [answers] = useFormAnswers(leadId);
  const paymentFrequency = answers?.['lead.csg.application.payment_modal'];

  return (
    <Question questionText="Select payment frequency">
      <RadioGroup row>
        <FormControlLabel
          label="Annual"
          checked={paymentFrequency === 'annual'}
          disabled={paymentFrequency !== 'annual'}
          control={(
            <Radio
              value="annual"
              color="primary"
            />
          )}
        />
        <FormControlLabel
          label="Monthly"
          checked={paymentFrequency === 'monthly'}
          disabled={paymentFrequency !== 'monthly'}
          control={(
            <Radio
              value="monthly"
              color="primary"
            />
          )}
        />
      </RadioGroup>
    </Question>
  );
};
