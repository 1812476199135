import { BillingFrequency, LifeQuoteCreateProperties, QuotePresentationType } from '@hiq/crm.types';
import { useAddExternalLifeQuoteMutation } from '../../../../../../graphql/mutations/useAddExternalLifeQuoteMutation';
import { useCallback } from 'react';
import { useActiveLeadId } from '../../../../../routers';
import { useExternalQuoteInput } from '../useExternalQuoteInput';
import { useLifeCarriers } from './useLifeCarriers';
import { useLifeProducts } from './useLifeProducts';

interface LifeQuoteBaseProperties {
  rate: {
    monthly?: number;
    annually?: number;
    policyFee: number;
  },
  presentationType: QuotePresentationType;
  billingFrequency?: BillingFrequency;
}

export type LifeExternalQuoteState = Partial<LifeQuoteCreateProperties> & LifeQuoteBaseProperties;

export function useExternalLifeQuote(defaults: LifeExternalQuoteState) {
  const leadId = useActiveLeadId();
  const [addQuote, { loading, error, data: addQuoteData }] = useAddExternalLifeQuoteMutation();
  const [quoteInput, setQuoteInput] = useExternalQuoteInput<LifeExternalQuoteState>(defaults);
  const { carrierOptions, loading: carriersLoading, error: carriersError } = useLifeCarriers(defaults.type);
  const { productOptions, loading: productsLoading, error: productsError } = useLifeProducts(defaults.type, quoteInput.carrierId);

  const submitQuote = useCallback(() => {
    addQuote({ leadId, properties: quoteInput as LifeQuoteCreateProperties });
  }, [leadId, quoteInput]);

  return {
    quoteInput,
    setQuoteInput,
    carrierOptions,
    productOptions,
    submitQuote,
    loading: loading || carriersLoading || productsLoading,
    error: error || carriersError || productsError,
    addQuoteData,
  };
}
