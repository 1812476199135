import { gql } from 'apollo-boost';
import { useCallback } from 'react';
import { RemoveQuoteHistoryMutationResponse, RemoveQuoteHistoryMutationVariables } from './useRemoveQuoteHistoryMutation.interfaces';
import { MutationCustomReturn, useMutationCustom } from '../useMutationCustom';
import { LEAD_FRAGMENT } from '../../fragments/lead';

const REMOVE_QUOTE_HISTORY_MUTATION = gql`
    mutation RemoveQuoteHistory($leadId: ID!, $hash: ID!, $type: QuotePresentationType!) {
      removeQuoteHistory(leadId: $leadId, hash: $hash, type: $type) {
        code
        success
        message
        timestamp
        lead {
          ...LeadFragment
        }
      }
    }
    ${LEAD_FRAGMENT}
`;

export function useRemoveQuoteHistoryMutation():
MutationCustomReturn<RemoveQuoteHistoryMutationResponse, RemoveQuoteHistoryMutationVariables> {
  const [removeQuoteHistory, result] = useMutationCustom<RemoveQuoteHistoryMutationResponse, RemoveQuoteHistoryMutationVariables>(
    REMOVE_QUOTE_HISTORY_MUTATION,
    {
      update: (cache, { data }) => {
        if (!data?.removeQuoteHistory?.success) return;
        cache.writeFragment({
          data: data.removeQuoteHistory.lead,
          fragment: LEAD_FRAGMENT,
          fragmentName: 'LeadFragment',
        });
      },
    },
  );
  const removeQuoteHistoryWrapped = useCallback(
    (variables: RemoveQuoteHistoryMutationVariables) => removeQuoteHistory(variables), [removeQuoteHistory],
  );

  return [removeQuoteHistoryWrapped, result];
}
