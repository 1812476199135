import { gql } from 'apollo-boost';
import { MutationCustomReturn, useMutationCustom } from '../useMutationCustom';
import {
  AutomatedUnderwritingMutationResponse,
  AutomatedUnderwritingMutationVariables,
} from './useAutomatedUnderwritingMutation.interfaces';

// @deprecated this is for Medsup 2.0 with CSG only
const AUTOMATED_UNDERWRITING_MUTATION = gql`
  mutation RunAutomatedUnderwriting($leadId: ID!) {
    automatedUnderwriting(leadId: $leadId) {
      code
      success
      message
      timestamp
      type
      result {
        decision
        appliedHouseholdDiscount
        appliedTobacco
        approved
        approvedPremium
        plan
      }
    }
  }
`;

export function useAutomatedUnderwritingMutation(): MutationCustomReturn<
AutomatedUnderwritingMutationResponse,
AutomatedUnderwritingMutationVariables> {
  return useMutationCustom<
  AutomatedUnderwritingMutationResponse,
  AutomatedUnderwritingMutationVariables
  >(AUTOMATED_UNDERWRITING_MUTATION);
}
