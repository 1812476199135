import React from 'react';
import { QuestionContainer } from './ValidateAdditionalPrescriptions.elements';
import { MustReadBlock } from '../../../ScriptBlock';

export const ValidateAdditionalPrescriptions = () => {
  const validatedAdditionalPrescriptionsQuestionId = 'lead.sunfire.v2.validateAdditionalPrescriptions';
  return (
    <QuestionContainer>
      <MustReadBlock
        answerKey={validatedAdditionalPrescriptionsQuestionId}
        confirmationRequired
        confirmText="I confirmed that all prescriptions have been added, including those not populated from the health record."
      >
        <p>
          Did you ask the consumer if they had any prescriptions they wanted covered that were not populated in the health record?
        </p>
      </MustReadBlock>
    </QuestionContainer>
  );
};
