import * as React from 'react';
import { useCurrentUserQuery, useCustomerQuery } from '../../../../graphql';
import {
  FormGenerator,
  FormGeneratorStyle,
  QueryLoader,
} from '../../../common';
import { CustomerName } from '../CustomerName';
import { CustomerSummaryContainer } from '../CustomerSummary.elements';
import { useActiveLeadId } from '../../../routers';
import { DialerCallHeader } from './DialerCallHeader';
import { UserProfile } from '@hiq/crm.types';
import { useFormAnswers } from '../../../../hooks';
import { ReportCallQuality } from '../ReportCallQuality/ReportCallQuality';
import { InitialContactCustomerSummaryProps } from './InitialContactCustomerSummary.interfaces';

export const InitialContactCustomerSummary: React.FunctionComponent<InitialContactCustomerSummaryProps> = ({ locked }) => {
  const { loading, error, data } = useCustomerQuery();
  const { data: userData } = useCurrentUserQuery();
  const leadId = useActiveLeadId();
  const [answers] = useFormAnswers();
  const altName = [answers['customer.person.name.first'], answers['customer.person.name.last']]
    .map((str) => str?.replace(/(\W|_)+/g, '')?.trim())
    .filter((str) => str)
    .join(' ');
  return (
    <QueryLoader loading={loading} error={error} componentName="InitialContactCustomerSummary">
      <CustomerSummaryContainer data-qa="customer-panel-initial-contact">
        <CustomerName name={data?.customer?.person?.name} altName={altName} />
        <DialerCallHeader />
        <FormGenerator
          lockable
          locked={locked}
          leadId={leadId}
          referenceId="customer-panel-initial-contact"
          style={isOffshoreSDR(userData?.currentUser?.profile) ? FormGeneratorStyle.ReadOnly : FormGeneratorStyle.Compact}
        />
        <ReportCallQuality />
      </CustomerSummaryContainer>
    </QueryLoader>
  );
};

function isOffshoreSDR(userProfile: UserProfile | string): boolean {
  return [
    UserProfile.OffshoreSalesDevelopmentRep,
    UserProfile.OffshorePresaleSDR,
  ].includes(userProfile as UserProfile);
}
