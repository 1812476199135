import {
  HealthRecordDoctor,
  HealthRecordDrug,
  HealthRecordPharmacy,
  HealthRecordUserClaim,
} from '@hiq/crm.types';
import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { withContentRect } from 'react-measure';
import { QueryLoader } from '../../QueryLoader';
import {
  OverlayUpsertEvent,
  SunfireDrug,
  useCriticalDoctorsQuery,
  useCustomerQuery,
  useDeleteOverlayMutation,
  useGetDementiaFlag,
  useGetDiseasesFromDrugs,
  useOverlayQuery,
  useSelectedDoctorsQuery,
  useSelectedICDsQuery,
  useSelectedNonICDsQuery,
  useSelectedPharmaciesQuery,
  useSelectedPrescriptionsQuery,
  useSunfireV2EditDrugContextQuery,
  useToggleCriticalDoctor,
  useToggleDoctorMutation,
  useToggleICDMutation,
  useToggleNonICDMutation,
  useTogglePharmacyMutation,
  useTogglePrescriptionMutation,
} from '../../../../graphql';
import { HealthRecordErrorMessage } from './HealthRecordErrorMessage';
import { HealthRecordGrid, HealthRecordGridProps } from './HealthRecordGrid';
import { useActiveLeadId, useCustomerId } from '../../../routers';
import { useSelectedRows } from './useSelectedRows';
import { useCellConfig } from './useCellConfig';
import { useColumnsConfig } from './useColumnsConfig';
import { AddDoctorPanel } from './AddDoctor';
import { AddDrugPanel } from './AddDrug';
import { AddPharmacyPanel } from './AddPharmacy';
import { SunfireV2DataChangeEvent, useEventListener } from '../../../../events';
import { usePreLoadCheckMyHealthRecord } from '../usePreLoadCheckMyHealthRecord';
import { OverlayToolbar } from './OverlayToolbar';
import { useDeleteRows } from './useSelectToDeleteRows';
import {
  Background, Divider, EditPanelPlaceholder, GridElementContainer,
} from './CheckMyHealthRecordDataGrid.elements';
import { useToggle } from './useToggle';
import { AddICDPanel } from './AddICD';
import { HealthRecordPullStatusWarning } from './HealthRecordPullStatus';
import moment from 'moment';
import { ValidateAdditionalPrescriptions } from './ValidateAdditionalPrescriptions';
import { HasMinimumRecommendedDoctors, ValidateAdditionalDoctors } from './ValidateAdditionalDoctors';
import { DementiaContext } from '../../../contexts';
import { emitAnalyticsEventNoLimit } from '../../../../events/emitAnalyticsEvent';
import { useDoctorColumnConfig } from './useDoctorColumnsConfig';
import {
  useFormAnswers, useCurrentUserIsPrecisionMedicareHeavy, useCurrentUserIsBionicLite, useCurrentUserIsBionicThin,
} from '../../../../hooks';
import { eventBus } from '../../../../services';
import { AnticipatedNeedsGrid } from './AnticipatedNeeds';

const PM_GRID_VALID = 'lead.sunfire.v2.isGridValid';
const AUTO_SELECTED_ICDS_QUESTION_ID = 'lead.sunfire.v2.autoSelectedIcds';
const HAS_MINIMUM_RECOMMENDED_DOCTORS_QUESTION_ID = 'lead.sunfire.v2.hasMinimumRecommendedDoctors';
const SUNFIRE_COUNTY_QUESTION_ID = 'lead.sunfire.v2.countyCode';
const DIAGNOSES_HARD_LIMIT = 20;
const REQUIRED_DIAGNOSES_AMOUNT = 3;
export const CheckMyHealthRecordDataGrid: React.ComponentType = withContentRect('bounds')(({ measureRef, contentRect }) => {
  const customerId = useCustomerId();
  const leadId = useActiveLeadId();
  const [answers, saveFormAnswers] = useFormAnswers(leadId, true);
  const { data: customerData } = useCustomerQuery();
  const { setHasDementiaFlag } = React.useContext(DementiaContext);
  const isPrecisionMedicareHeavy = useCurrentUserIsPrecisionMedicareHeavy();
  const isBionicLite = useCurrentUserIsBionicLite();
  const isBionicThin = useCurrentUserIsBionicThin();
  const email = customerData?.customer?.person?.email;
  const { zip } = customerData?.customer?.person?.homeAddress ?? {};
  const countyCode = answers[SUNFIRE_COUNTY_QUESTION_ID];
  const selectedPrescriptions = useSelectedPrescriptionsQuery(leadId)?.data?.selectedPrescriptions?.map(
    (data) => ({ ...data, id: data.sunfireId }),
  ) ?? [];
  const selectedDoctors = useSelectedDoctorsQuery(leadId)?.data?.selectedDoctors?.map(
    (doc) => ({ ...doc, lastVisit: doc.lastVisit ? moment(doc.lastVisit).format('M/D/yyyy') : undefined }),
  ) ?? [];
  const selectedPharmacies = useSelectedPharmaciesQuery(leadId)?.data?.selectedPharmacies ?? [];
  const selectedIcds = useSelectedICDsQuery(leadId)?.data?.selectedICDs ?? [];
  const selectedNonIcds = useSelectedNonICDsQuery(leadId)?.data?.selectedNonICDs ?? [];
  const {
    data: queryData, loading: healthRecordLoading, pullStatus,
  } = usePreLoadCheckMyHealthRecord(email, zip, countyCode);

  const [getDiseaseFromDrugs, { data: diseaseFromDrugsUserClaims }] = useGetDiseasesFromDrugs();
  const [deleteFromOverlay] = useDeleteOverlayMutation();
  const [
    fetchOverlays,
    { data: overlayData, error: overlayError, loading: overlayLoading },
  ] = useOverlayQuery(customerId);

  const [fetchDementiaFlag, { data: dementiaFlagData }] = useGetDementiaFlag();

  const {
    doctors: importedDoctors, drugs, pharmacies, userClaims: healthRecordClaims, invalidPrescriptions, invalidPharmacies, invalidDoctors,
  } = queryData?.fetchBionicHealthRecords ?? {};

  const invalidPharmaciesMapped = useMemo(
    () => invalidPharmacies?.map((pharmacy) => ({ ...pharmacy, invalid: true })),
    [invalidPharmacies],
  );

  const overlayDrugsClaims = useMemo(
    () => diseaseFromDrugsUserClaims?.getDiseasesFromDrugs?.map((claim) => ({
      ...claim,
      addedByAgent: true,
    })) ?? [],
    [diseaseFromDrugsUserClaims],
  );

  const inverseIcdMapping = useMemo(() => {
    const mappings = new Map();
    diseaseFromDrugsUserClaims?.getDiseasesFromDrugs.forEach((disease) => disease.drugs.forEach((drug) => {
      const key = drug?.name?.toLowerCase();
      if (mappings.has(key)) mappings.get(key).push(disease);
      else mappings.set(key, [disease]);
    }));
    return mappings;
  }, [diseaseFromDrugsUserClaims?.getDiseasesFromDrugs, overlayData?.drugsOverlays]);

  const overlayDrugsWithMappedDiseases = useMemo(() => overlayData?.drugsOverlays.map((overlay) => ({
    ...overlay,
    data: {
      ...overlay.data,
      diagnoses: inverseIcdMapping.get(overlay.data?.drugName?.toLowerCase()),
    },
  })), [inverseIcdMapping, overlayData?.drugsOverlays]);

  const userClaims = useMemo(() => [...healthRecordClaims, ...overlayDrugsClaims],
    [healthRecordClaims, overlayDrugsClaims]);

  const otherPharmacyData = React.useMemo(() => [{ name: 'Mail Order Pharmacy', npi: 'MAIL_ORDER_PHARMACY' }], []);
  const icdUserClaims = React.useMemo(() => userClaims?.filter(({ hasICD, drugs }) => hasICD && !drugs?.length)
    .sort((a, b) => {
      if (a.diagnosisDate && b.diagnosisDate) return a.diagnosisDate > b.diagnosisDate ? -1 : 1;
      return 0;
    }) ?? [], [userClaims]);
  const impliedUserClaims = React.useMemo(() => userClaims?.filter(({ hasICD, drugs }) => hasICD && drugs?.length) ?? [], [userClaims]);
  const nonIcdUserClaims = React.useMemo(() => userClaims?.filter(({ hasICD }) => !hasICD) ?? [], [userClaims]);

  React.useEffect(() => {
    fetchOverlays();
  }, []);

  const drugOverlayGenericNames = React.useMemo(() => overlayData?.drugsOverlays
    .filter((drug) => drug?.data?.drugName)
    .map((drug) => drug?.data?.drugName), [overlayData?.drugsOverlays]);

  React.useEffect(() => {
    if (overlayData?.drugsOverlays) {
      getDiseaseFromDrugs({
        variables: {
          drugs: drugOverlayGenericNames,
        },
      });
    }
  }, [drugOverlayGenericNames]);

  React.useEffect(() => {
    if (drugOverlayGenericNames) fetchDementiaFlag({ variables: { customerId, drugs: drugOverlayGenericNames } });
  }, [drugOverlayGenericNames, customerId, pullStatus]);

  React.useEffect(() => {
    if (dementiaFlagData?.getDementiaFlag?.dementiaFlag) setHasDementiaFlag(true);
  }, [dementiaFlagData]);

  const getDrugRowId = React.useCallback(
    (data: Partial<HealthRecordDrug & SunfireDrug>) => (data.ndc ? data.ndc : data.id),
    [],
  );
  const getDoctorRowId = React.useCallback(
    (data: Partial<HealthRecordDoctor>) => data.npi,
    [],
  );
  const getPharmacyRowId = React.useCallback(
    (data: Partial<HealthRecordPharmacy>) => data.npi,
    [],
  );
  const getIcdRowId = React.useCallback(
    (data: Partial<HealthRecordUserClaim>) => data.diseaseKey || data.claimCodeId || data.description, [],
  );
  const getNonIcdRowId = React.useCallback(
    (data: Partial<HealthRecordUserClaim>) => data.description, [],
  );

  const [isAddingDrugs, toggleAddingDrugs] = useToggle();
  const [isEditingDrugs, toggleEditingDrugs] = useToggle();
  const [isAddingDoctors, toggleAddingDoctors] = useToggle();
  const [isAddingPharmacies, toggleAddingPharmacies, setIsOpenAddingPharmacies] = useToggle();
  const [isAddingIcdDiagnoses, toggleAddingIcdDiagnoses] = useToggle();

  const [togglePrescription] = useTogglePrescriptionMutation();
  const [toggleDoctor] = useToggleDoctorMutation();
  const [togglePharmacy] = useTogglePharmacyMutation();
  const [toggleICD] = useToggleICDMutation();
  const [toggleNonICD] = useToggleNonICDMutation();

  const onPrescriptionToggle = useCallback((data: HealthRecordDrug | SunfireDrug) => {
    togglePrescription({
      input: {
        sunfireId: (data as SunfireDrug).id,
        ndc: (data as HealthRecordDrug).ndc,
        leadId,
        name: data.name,
        daysOfSupply: data.daysOfSupply,
        fillQuantity: data.fillQuantity,
      },
    });
  }, [togglePrescription]);
  const onDoctorToggle = useCallback((data: HealthRecordDoctor) => {
    toggleDoctor({
      input: {
        npi: data.npi,
        name: data.name,
        practice: data.practice,
        visitsLastYear: data.visitsLastYear,
        visitsLast3Years: data.visitsLast3Years,
        visitsLast5Years: data.visitsLast5Years,
        lastVisit: data.lastVisit ? moment.utc(data.lastVisit, 'M/D/yyyy', true).toDate() : undefined,
        addressLine1: data.addressLine1,
        addressLine2: data.addressLine2,
        city: data.city,
        state: data.state,
        zip: data.zip,
        leadId,
      },
    });
  }, [toggleDoctor]);
  const onPharmacyToggle = useCallback((data: HealthRecordPharmacy) => {
    if (selectedPharmacies?.length === 0) saveFormAnswers(PM_GRID_VALID, true);
    togglePharmacy({
      input: {
        npi: data.npi,
        name: data.name,
        addressLine1: data.addressLine1,
        addressLine2: data.addressLine2,
        city: data.city,
        state: data.state,
        zip: data.zip,
        leadId,
      },
    });
  }, [togglePharmacy, selectedPharmacies]);
  const onICDToggle = useCallback((data: HealthRecordUserClaim) => {
    toggleICD({
      input: {
        diseaseKey: data.diseaseKey,
        diagnosisDate: data.diagnosisDate,
        claimCode: data.claimCode,
        claimCodeId: data.claimCodeId,
        clinicalName: data.clinicalName,
        description: data.description,
        leadId,
      },
    });
  }, [toggleICD]);
  const onNonICDToggle = useCallback((data: HealthRecordUserClaim) => {
    toggleNonICD({
      input: {
        description: data.description,
        claimCode: data.claimCode,
        claimCodeId: data.claimCodeId,
        diagnosisDate: data.diagnosisDate,
        leadId,
      },
    });
  }, [toggleNonICD]);

  const [selectedDrugsIds, onDrugSelection] = useSelectedRows<HealthRecordDrug | SunfireDrug>(
    getDrugRowId, selectedPrescriptions, onPrescriptionToggle, true, 'Drug',
  );
  const [selectedDoctorsIds, onDoctorSelection] = useSelectedRows<HealthRecordDoctor>(
    getDoctorRowId, selectedDoctors, onDoctorToggle, true, 'Doctor',
  );
  const [selectedPharmaciesIds, onPharmacySelection] = useSelectedRows<HealthRecordPharmacy>(
    getPharmacyRowId, selectedPharmacies, onPharmacyToggle, false, 'Pharmacy',
  );
  const [selectedIcdIds, onIcdSelection] = useSelectedRows<HealthRecordUserClaim>(
    getIcdRowId, selectedIcds, onICDToggle, true, 'ICD',
  );
  const [selectedNonIcdIds, onNonIcdSelection] = useSelectedRows<HealthRecordUserClaim>(
    getNonIcdRowId, selectedNonIcds, onNonICDToggle, true, 'Non-ICD',
  );

  const [selectedToDeleteDrugs, onDrugDeleteSelection, isRemovingDrugs, toggleRemovingDrugs] = useDeleteRows<HealthRecordDrug>(
    getDrugRowId,
  );

  const icdSelectionsCount = React.useMemo(() => selectedIcdIds?.length + selectedNonIcdIds?.length, [selectedIcdIds, selectedNonIcdIds]);

  const deleteOverlayDrugs = React.useCallback(() => {
    const drugs = overlayData.drugsOverlays
      .filter((drugOverLay) => selectedToDeleteDrugs.includes(drugOverLay.externalId));
    drugs.forEach((drug) => {
      const selected = selectedDrugsIds.find((selectedId) => selectedId === getDrugRowId(drug.data));
      if (selected) onDrugSelection(drug.data);
      onDrugDeleteSelection(drug.data);
      deleteFromOverlay({ id: drug.id });
    });
    toggleRemovingDrugs();
  }, [selectedToDeleteDrugs, overlayData?.drugsOverlays, selectedDrugsIds, onDrugDeleteSelection, onDrugSelection]);

  const [selectedToDeleteDoctors, onDoctorDeleteSelection, isRemovingDoctors, toggleRemovingDoctors] = useDeleteRows<HealthRecordDoctor>(
    getDoctorRowId,
  );

  const criticalDoctorsQueryResult = useCriticalDoctorsQuery(leadId);
  const criticalDoctors = criticalDoctorsQueryResult?.data?.criticalDoctors ?? [];
  const doctors = React.useMemo(() => (
    importedDoctors.map((doc) => ({
      ...doc,
      selected: isRemovingDoctors ? selectedToDeleteDoctors.includes(doc.npi) : selectedDoctorsIds.includes(doc.npi),
      criticalSelected: criticalDoctors.some((current) => current.npi === doc.npi),
    }))
  ), [importedDoctors, selectedDoctorsIds, selectedToDeleteDoctors, isRemovingDoctors, criticalDoctors]);
  const addedDoctors = React.useMemo(() => (
    overlayData?.doctorsOverlays.map((doc) => ({
      ...doc,
      data: {
        ...doc.data,
        selected: isRemovingDoctors ? selectedToDeleteDoctors.includes(doc.data.npi) : selectedDoctorsIds.includes(doc.data.npi),
        criticalSelected: criticalDoctors.some((current) => current.npi === doc.data.npi),
      },
    }))
  ), [overlayData?.doctorsOverlays, selectedDoctorsIds, selectedToDeleteDoctors, isRemovingDoctors, criticalDoctors]);

  const deleteOverlayDoctors = React.useCallback(() => {
    const doctors = overlayData.doctorsOverlays
      .filter((doctorOverlay) => selectedToDeleteDoctors.includes(doctorOverlay.externalId));
    doctors.forEach((doctor) => {
      const selected = selectedDoctorsIds.some((selectedId) => selectedId === getDoctorRowId(doctor.data));
      if (selected) onDoctorSelection(doctor.data);

      const isCriticalDoctorSelected = criticalDoctors.some((doc) => doc.npi === doctor.data.npi);
      if (isCriticalDoctorSelected) onCriticalDoctorSelect(doctor.data as HealthRecordDoctor);
      onDoctorDeleteSelection(doctor.data);
      deleteFromOverlay({ id: doctor.id });
    });
    toggleRemovingDoctors();
  }, [
    overlayData?.doctorsOverlays,
    selectedToDeleteDoctors,
    selectedDoctorsIds,
    onDoctorSelection,
    onDoctorDeleteSelection,
    criticalDoctors,
  ]);

  const [
    selectedToDeletePharmacies,
    onPharmacyDeleteSelection,
    isRemovingPharmacies,
    toggleRemovePharmacies,
  ] = useDeleteRows<HealthRecordPharmacy>(
    getPharmacyRowId,
  );

  const deleteOverlayPharmacies = React.useCallback(() => {
    const pharmacies = overlayData.pharmaciesOverlays
      .filter((pharmacyOverlay) => selectedToDeletePharmacies.includes(pharmacyOverlay.externalId));
    pharmacies.forEach((pharmacy) => {
      const selected = selectedPharmaciesIds.find((selectedId) => selectedId === getPharmacyRowId(pharmacy.data));
      if (selected) onPharmacySelection(); // unselects it
      onPharmacyDeleteSelection(pharmacy.data);
      deleteFromOverlay({ id: pharmacy.id });
    });
    toggleRemovePharmacies();
  }, [overlayData?.pharmaciesOverlays, selectedToDeletePharmacies, selectedPharmaciesIds, onPharmacyDeleteSelection, onPharmacySelection]);

  const [
    selectedToDeleteIcd,
    onIcdDeleteSelection,
    isRemovingIcd,
    toggleRemovingIcd,
  ] = useDeleteRows<HealthRecordUserClaim>(getIcdRowId);

  const deleteOverlayIcd = React.useCallback(() => {
    const overlays = overlayData.userClaimsOverlays
      .filter((overlay) => selectedToDeleteIcd.includes(overlay.externalId));
    overlays.forEach((overlay) => {
      const selected = selectedIcdIds.find((selectedId) => selectedId === getIcdRowId(overlay.data));
      if (selected) onIcdSelection(overlay.data);
      onIcdDeleteSelection(overlay.data);
      deleteFromOverlay({ id: overlay.id });
    });
    toggleRemovingIcd();
  }, [overlayData?.userClaimsOverlays, selectedToDeleteIcd, selectedIcdIds, onIcdSelection, onIcdDeleteSelection,
    toggleRemovingIcd]);

  const [
    selectedToDeleteNonIcd,
    onNonIcdDeleteSelection,
    isRemovingNonIcd,
    toggleRemovingNonIcd,
  ] = useDeleteRows<HealthRecordUserClaim>(getNonIcdRowId);

  const deleteOverlayNonIcd = React.useCallback(() => {
    const overlays = overlayData.userClaimsOverlays
      .filter((overlay) => selectedToDeleteNonIcd.includes(overlay.externalId));
    overlays.forEach((overlay) => {
      const selected = selectedNonIcdIds.find((selectedId) => selectedId === getNonIcdRowId(overlay.data));
      if (selected) onNonIcdSelection(overlay.data);
      onNonIcdDeleteSelection(overlay.data);
      deleteFromOverlay({ id: overlay.id });
    });
    toggleRemovingNonIcd();
  }, [overlayData?.userClaimsOverlays, selectedToDeleteNonIcd, selectedNonIcdIds, onNonIcdSelection,
    onNonIcdDeleteSelection, toggleRemovingNonIcd]);

  const deleteMergedIcdOverlay = React.useCallback(() => {
    deleteOverlayIcd();
    deleteOverlayNonIcd();
  }, [deleteOverlayIcd, deleteOverlayNonIcd]);

  // Sets flag to valid if pharmacy is selected
  React.useEffect(() => {
    if (selectedPharmacies?.length > 0) saveFormAnswers(PM_GRID_VALID, true);
  }, [selectedPharmacies?.length]);

  // Sets flag to valid if doctor is selected
  React.useEffect(() => {
    if (selectedDoctors?.length > 1) saveFormAnswers(HAS_MINIMUM_RECOMMENDED_DOCTORS_QUESTION_ID, true);
  }, [selectedDoctors?.length]);

  // Selects the pharmacy automatically if it's the only option available
  React.useEffect(() => {
    // Only do this once the CMHR and overlay data are loaded
    if (pharmacies && overlayData && !selectedPharmaciesIds.length && !isRemovingPharmacies) {
      const pharmacyOptions = [
        ...pharmacies,
        ...(overlayData?.pharmaciesOverlays?.map(({ data }) => data) ?? []),
      ];
      if (pharmacyOptions.length === 1) {
        const {
          name, addressLine1, addressLine2, city, state, zip, npi,
        } = pharmacyOptions[0];
        onPharmacySelection({
          name, npi, addressLine1, addressLine2, city, state, zip,
        });
      }
    }
  }, [pharmacies, overlayData, selectedPharmaciesIds, onPharmacySelection]);

  // Select all the diagnoses from section 1 by default
  React.useEffect(() => {
    if (!answers[AUTO_SELECTED_ICDS_QUESTION_ID] && icdUserClaims?.length && selectedIcds?.length === 0) {
      onIcdSelection(...icdUserClaims.splice(0, REQUIRED_DIAGNOSES_AMOUNT));
    }
    if (selectedIcds?.length) {
      saveFormAnswers(AUTO_SELECTED_ICDS_QUESTION_ID, true);
    }
  }, [
    icdUserClaims?.length,
    onIcdSelection,
    answers[AUTO_SELECTED_ICDS_QUESTION_ID],
    selectedIcds?.length,
    saveFormAnswers,
  ]);

  useEventListener(OverlayUpsertEvent, async (event: OverlayUpsertEvent<any>) => {
    const { data } = event;
    switch (event.externalType) {
      case 'HealthRecordDoctor': {
        const { name, npi } = data;
        onDoctorSelection({ name, npi });
        break;
      }
      case 'HealthRecordDrug':
      case 'SunfireDrug': {
        const {
          id, ndc, name, daysOfSupply, fillQuantity,
        } = data;
        onDrugSelection({
          id, ndc, name, daysOfSupply, fillQuantity,
        });
        break;
      }
      case 'HealthRecordPharmacy': {
        const {
          name, addressLine1, addressLine2, city, state, zip, npi,
        } = data;
        onPharmacySelection({
          name, npi, addressLine1, addressLine2, city, state, zip,
        });
        break;
      }
      case 'HealthRecordUserClaim': {
        const { description, hasICD, diseaseKey } = data;
        if (icdSelectionsCount < DIAGNOSES_HARD_LIMIT) {
          if (hasICD) {
            onIcdSelection({
              description,
              diseaseKey,
            });
          } else {
            onNonIcdSelection({ description });
          }
        }
        break;
      }
      case 'HealthRecordAnticipatedNeed':
        break;
      default:
        throw new Error(`Overlay external type unexpected: ${event.externalType}`);
    }
  }, [onDrugSelection, onPharmacySelection, onDoctorSelection, onIcdSelection, onNonIcdSelection]);

  const drugsSortFns: HealthRecordGridProps<HealthRecordDrug>['sortFns'] = React.useMemo(() => ({
    FILL_DATE: {
      label: 'Prescription Date',
      fn: ((a, b) => (new Date(a.fillDate) < new Date(b.fillDate) ? 1 : -1)),
      isDefault: true,
    },
    FILLS: {
      label: '# of fills',
      fn: ((a, b) => (a.fillsLastYear < b.fillsLastYear ? 1 : -1)),
    },
    NAME_ASC: {
      label: 'Name (A-Z)',
      fn: ((a, b) => (a.name > b.name ? 1 : -1)),
    },
    NAME_DESC: {
      label: 'Name (Z-A)',
      fn: ((a, b) => (a.name > b.name ? -1 : 1)),
    },
  }), []);

  const getNamePart = React.useCallback((name: string, part: number) => {
    const splits = name?.split(' ');
    return splits?.[part] ?? name;
  }, []);

  const doctorsSortFns: HealthRecordGridProps<HealthRecordDoctor>['sortFns'] = React.useMemo(() => ({
    LAST_VISITS: {
      label: 'Last Visited',
      fn: ((a, b) => (new Date(a.lastVisit) < new Date(b.lastVisit) ? 1 : -1)),
      isDefault: true,
    },
    VISITS: {
      label: '# of visits',
      fn: ((a, b) => (a.visitsLastYear < b.visitsLastYear ? 1 : -1)),
    },
    FIRST_NAME: {
      label: 'First Name (A-Z)',
      fn: ((a, b) => (getNamePart(a.name, 0) > getNamePart(b.name, 0) ? 1 : -1)),
    },
    LAST_NAME: {
      label: 'Last Name (A-Z)',
      fn: ((a, b) => (getNamePart(a.name, 1) > getNamePart(b.name, 1) ? 1 : -1)),
    },
    SPECIALTY: {
      label: 'Specialty (A-Z)',
      fn: ((a, b) => (a.practice > b.practice ? 1 : -1)),
    },
  }), [getNamePart]);

  const pharmacySortFns: HealthRecordGridProps<HealthRecordPharmacy>['sortFns'] = React.useMemo(() => ({
    FILLS: {
      label: '# Fills',
      fn: ((a, b) => (a.fillsLastYear < b.fillsLastYear ? 1 : -1)),
      isDefault: true,
    },
    NAME_ASC: {
      label: 'Name (A-Z)',
      fn: ((a, b) => (a.name > b.name ? 1 : -1)),
    },
    NAME_DESC: {
      label: 'Name (Z-A)',
      fn: ((a, b) => (a.name > b.name ? -1 : 1)),
    },
  }), []);

  const [cellWidth, isReducedViewport] = useCellConfig(contentRect.bounds.width);

  const [selectedDrugId, setSelectedDrugId] = useState<string>();
  const [selectedDrug, setSelectedDrug] = useState<HealthRecordDrug | SunfireDrug>();
  const [
    fetchEditDrugContext,
    { data: editDrugContextData, loading: editDrugContextLoading, error: editDrugContextError },
  ] = useSunfireV2EditDrugContextQuery();
  const [editDrugErrorMessageVisible, setEditDrugErrorMessageVisible] = useState(false);
  const onEditDrugSnackbarClose = useCallback(() => {
    setEditDrugErrorMessageVisible(false);
  }, []);
  useEffect(() => {
    setEditDrugErrorMessageVisible(!!editDrugContextError?.message);
  }, [editDrugContextError?.message]);

  const onEditDrug = React.useCallback((drug: (HealthRecordDrug | SunfireDrug)) => {
    const drugExternalId = (drug as SunfireDrug).id ?? (drug as HealthRecordDrug).ndc;
    const overlay = overlayData?.drugsOverlays?.find(
      (overlay) => drugExternalId === overlay.externalId,
    );
    fetchEditDrugContext({
      variables: {
        dosageName: drug.name,
        ndc: (drug as HealthRecordDrug).ndc,
        trustDosageName: !!overlay?.id, // if it's an overlay, source is Sunfire, so we can trust it
      },
    });
    setSelectedDrugId(overlay?.id);
    setSelectedDrug(drug);

    // Opens edit drug panel if not already open
    if (!isEditingDrugs) {
      toggleEditingDrugs();
    }
  }, [fetchEditDrugContext, overlayData?.drugsOverlays, setSelectedDrugId, isEditingDrugs, toggleEditingDrugs]);

  const onCloseEditDrugPanel = React.useCallback((canceled: boolean) => {
    toggleEditingDrugs();

    if (!canceled) {
      // Unselects drug
      const selected = selectedDrugsIds.find((selectedId) => selectedId === getDrugRowId(selectedDrug));
      if (selected) onDrugSelection(selectedDrug);
    }

    setSelectedDrugId(undefined);
    setSelectedDrug(undefined);
  }, [toggleEditingDrugs, selectedDrugId, setSelectedDrugId, selectedDrug, setSelectedDrug, onDrugSelection,
    selectedDrugsIds, getDrugRowId]);

  const [easyPharmacyZipCode, setEasyPharmacyZipCode] = React.useState<string>();

  const onEasyUnsupportedPharmacy = React.useCallback((zipcode: string) => {
    setIsOpenAddingPharmacies(true);
    if (zipcode?.length > 5) setEasyPharmacyZipCode(zipcode.substring(0, 5));
    else setEasyPharmacyZipCode(zipcode);
  }, [setEasyPharmacyZipCode]);

  const onClosePharmacyPanel = React.useCallback(() => {
    toggleAddingPharmacies();
    setEasyPharmacyZipCode(undefined);
  }, [setEasyPharmacyZipCode, toggleAddingPharmacies]);

  const [toggleCriticalDoctor] = useToggleCriticalDoctor();
  const [
    drugColumns,
    pharmaciesColumns,
    userClaimsColumns,
    impliedUserClaimsColumns,
  ] = useColumnsConfig(cellWidth, isReducedViewport, onEditDrug, onEasyUnsupportedPharmacy);

  const onCriticalDoctorSelect = React.useCallback((data: HealthRecordDoctor) => {
    const isUnselect = criticalDoctors.some((current) => current.npi === data.npi);
    const action = isUnselect ? 'Deselecting ' : 'Selecting ';

    emitAnalyticsEventNoLimit(`${action} Must Have Doctor`);
    eventBus.emit(new SunfireV2DataChangeEvent());
    toggleCriticalDoctor({
      input: {
        leadId,
        npi: data.npi,
        name: data.name,
      },
    });
  }, [toggleCriticalDoctor, criticalDoctors]);

  const doctorsColumns = useDoctorColumnConfig({
    cellWidth,
    isReducedViewport,
    onSelect: isRemovingDoctors ? onDoctorDeleteSelection : onDoctorSelection,
    onCriticalSelect: onCriticalDoctorSelect,
    isRemoving: isRemovingDoctors,
    hideCritical: !isPrecisionMedicareHeavy,
  });

  const recentDrugBreakpoint = useMemo(() => moment().startOf('day').subtract(1, 'year'), []);

  const drugSections = useMemo(() => {
    const lastYearDrugs = drugs.filter((drug) => moment(drug.fillDate).isSameOrBefore(recentDrugBreakpoint));
    const hasSelectedOlder = !!lastYearDrugs.find((drug) => selectedDrugsIds.includes(getDrugRowId(drug)));
    const hasLastYearDrugs = !!lastYearDrugs?.length;
    return [
      {
        name: 'Recent Prescriptions (REQUIRED)',
        filter: (drug: HealthRecordDrug) => moment(drug.fillDate)
          .isSameOrAfter(recentDrugBreakpoint),
        defaultOpen: hasLastYearDrugs || !drugs.length,
        emptyRowsMessage: 'No prescriptions imported on last year',
        onToggle: () => emitAnalyticsEventNoLimit('Toggling recent prescriptions', { customerId, leadId }),
      },
      {
        name: 'Older Prescriptions (not required)',
        filter: (drug: HealthRecordDrug) => moment(drug.fillDate)
          .isBefore(recentDrugBreakpoint),
        defaultOpen: hasSelectedOlder || (!hasLastYearDrugs || !drugs.length),
        emptyRowsMessage: 'No prescriptions imported',
        onToggle: () => emitAnalyticsEventNoLimit('Toggling older prescriptions', { customerId, leadId }),
      },
    ];
  }, [drugs]);

  const drugsGrid = React.useMemo(() => (
    <HealthRecordGrid
      sections={drugSections}
      data={drugs as (HealthRecordDrug | SunfireDrug)[]}
      title="Select Prescriptions"
      overlays={overlayDrugsWithMappedDiseases}
      getRowId={getDrugRowId}
      enableSelection
      selectedRowIds={selectedDrugsIds}
      selectedToDelete={selectedToDeleteDrugs}
      onSelectionChange={({
        id, ndc, name, daysOfSupply, fillQuantity,
      }: HealthRecordDrug & SunfireDrug) => {
        onDrugSelection({
          id, ndc, name, daysOfSupply, fillQuantity,
        });
      }}
      onSelectToDelete={onDrugDeleteSelection}
      hideSorting
      sortFns={drugsSortFns}
      isRemoving={isRemovingDrugs}
      columns={drugColumns}
      emptyRowsMessage="No prescriptions imported"
      emptyOverlaysMessage="No prescriptions added"
      loading={healthRecordLoading}
      overlayToolbar={!isEditingDrugs && (
        <OverlayToolbar
          panel={<AddDrugPanel setPanelClosed={toggleAddingDrugs} />}
          showPanel={isAddingDrugs}
          toggleShowPanel={toggleAddingDrugs}
          isRemoving={isRemovingDrugs}
          addButtonText="Add Prescriptions"
          removeButtonText="Remove Prescriptions"
          toggleRemove={toggleRemovingDrugs}
          onConfirmRemove={deleteOverlayDrugs}
          removeDisabled={!selectedToDeleteDrugs.length}
        />
      )}
      unsupportedData={invalidPrescriptions}
      unsupportedGridTitle={'UNSUPPORTED VIA AUTO-IMPORT (PRESS "EDIT" TO INCLUDE)'}
    />
  ), [drugs, selectedDrugsIds, overlayData?.drugsOverlays, drugColumns, selectedDrugId, editDrugContextLoading,
    editDrugContextData?.getEditDrugContext, isRemovingDrugs, selectedToDeleteDrugs, drugSections,
    onDrugDeleteSelection, isAddingDrugs, toggleAddingDrugs, selectedDrug, healthRecordLoading, overlayDrugsWithMappedDiseases]);

  const unsupportedDoctors = React.useMemo(() => invalidDoctors?.map((doctor) => ({
    ...doctor,
    unsupported: true,
  })), [invalidDoctors]);

  const doctorsGrid = React.useMemo(() => (
    <HealthRecordGrid
      data={doctors}
      title="Select Doctors"
      overlays={addedDoctors}
      getRowId={getDoctorRowId}
      selectedRowIds={selectedDoctorsIds}
      selectedToDelete={selectedToDeleteDoctors}
      sortFns={doctorsSortFns}
      isRemoving={isRemovingDoctors}
      columns={doctorsColumns}
      emptyRowsMessage="No doctors imported"
      emptyOverlaysMessage="No doctors added"
      loading={healthRecordLoading}
      overlayToolbar={(
        <OverlayToolbar
          panel={<AddDoctorPanel setPanelClosed={toggleAddingDoctors} zip={zip} />}
          showPanel={isAddingDoctors}
          toggleShowPanel={toggleAddingDoctors}
          isRemoving={isRemovingDoctors}
          addButtonText="Add Doctors"
          removeButtonText="Remove Doctors"
          toggleRemove={toggleRemovingDoctors}
          onConfirmRemove={deleteOverlayDoctors}
          removeDisabled={!selectedToDeleteDoctors.length}
        />
      )}
      unsupportedData={unsupportedDoctors}
    />
  ), [doctors, selectedDoctorsIds, addedDoctors, doctorsColumns,
    isRemovingDoctors, selectedToDeleteDoctors, isAddingDoctors, toggleAddingDoctors,
    invalidDoctors, healthRecordLoading]);

  const pharmacyGrid = React.useMemo(() => (
    <HealthRecordGrid
      data={pharmacies}
      title="Select Pharmacy"
      overlays={overlayData?.pharmaciesOverlays}
      getRowId={getPharmacyRowId}
      enableSelection
      selectedRowIds={selectedPharmaciesIds}
      selectedToDelete={selectedToDeletePharmacies}
      onSelectionChange={({
        name, addressLine1, addressLine2, city, state, zip, npi,
      }: HealthRecordPharmacy) => {
        onPharmacySelection({
          name, npi, addressLine1, addressLine2, city, state, zip,
        });
      }}
      onSelectToDelete={onPharmacyDeleteSelection}
      sortFns={pharmacySortFns}
      isRemoving={isRemovingPharmacies}
      columns={pharmaciesColumns}
      selectionMultiselect={false}
      selectionLabel="Select"
      emptyRowsMessage="No pharmacies imported"
      emptyOverlaysMessage="No pharmacies added"
      loading={healthRecordLoading}
      overlayToolbar={(
        <OverlayToolbar
          panel={<AddPharmacyPanel setPanelClosed={onClosePharmacyPanel} easyPharmacyZip={easyPharmacyZipCode} />}
          showPanel={isAddingPharmacies}
          toggleShowPanel={toggleAddingPharmacies}
          isRemoving={isRemovingPharmacies}
          addButtonText="Add Pharmacies"
          removeButtonText="Remove Pharmacies"
          toggleRemove={toggleRemovePharmacies}
          onConfirmRemove={deleteOverlayPharmacies}
          removeDisabled={!selectedToDeletePharmacies.length}
        />
      )}
      otherData={otherPharmacyData}
      unsupportedData={invalidPharmaciesMapped}
      unsupportedGridTitle="Unsupported via auto-import. Click ZIP to manually enter"
    />
  ), [pharmacies, selectedPharmaciesIds, overlayData?.pharmaciesOverlays, pharmaciesColumns,
    isRemovingPharmacies, selectedToDeletePharmacies, onPharmacyDeleteSelection, isAddingPharmacies,
    toggleAddingPharmacies, invalidPharmaciesMapped, healthRecordLoading, easyPharmacyZipCode]);

  const icdSections = React.useMemo(() => {
    const nonIcdCount = nonIcdUserClaims?.length;
    return [
      {
        // if we have icds, they are always going to be top 3, but we need to check if it's icd to avoid showing nonIcds in the top 3
        filter: (icd: HealthRecordUserClaim, index: number) => icd.hasICD && index < REQUIRED_DIAGNOSES_AMOUNT,
        emptyRowsMessage: 'No diagnoses imported',
        name: 'Required supported diseases',
        defaultOpen: true,
        onToggle: () => emitAnalyticsEventNoLimit('Toggling required diagnoses', { customerId, leadId }),
      },
      {
        name: 'Non-required supported diseases',
        filter: (icd: HealthRecordUserClaim, index:number) => icd.hasICD && index >= REQUIRED_DIAGNOSES_AMOUNT,
        hideIfEmpty: true,
        defaultOpen: true,
        onToggle: () => emitAnalyticsEventNoLimit('Toggling non required diagnoses', { customerId, leadId }),
      },
      {
        name: `More (${nonIcdCount})`,
        filter: (icd: HealthRecordUserClaim) => !icd.hasICD,
        hideIfEmpty: true,
        onToggle: () => emitAnalyticsEventNoLimit('Toggling more diagnoses', { customerId, leadId }),
      },
    ];
  }, [nonIcdUserClaims]);

  const mergedIcs = React.useMemo(() => [...icdUserClaims, ...nonIcdUserClaims], [icdUserClaims, nonIcdUserClaims]);

  const icdUserClaimsGrid = React.useMemo(() => (
    <HealthRecordGrid
      getRowId={getIcdRowId}
      enableSelection
      selectedRowIds={selectedIcdIds}
      sections={icdSections}
      onSelectionChange={({
        clinicalName, description, diseaseKey, claimCodeId, claimCode, diagnosisDate,
      }: HealthRecordUserClaim) => {
        onIcdSelection({
          clinicalName, description, diseaseKey, claimCodeId, claimCode, diagnosisDate,
        });
      }}
      data={mergedIcs}
      title="Select Diagnoses"
      disableNewSelections={icdSelectionsCount >= DIAGNOSES_HARD_LIMIT}
      columns={userClaimsColumns}
      isRemoving={isRemovingIcd}
      emptyRowsMessage="No diagnoses imported"
      hideOverlays
      loading={healthRecordLoading}
    />
  ), [icdUserClaims, selectedIcdIds, userClaimsColumns, healthRecordLoading, isRemovingIcd, icdSelectionsCount]);

  const impliedUserClaimsGrid = React.useMemo(() => (
    <HealthRecordGrid
      subtitle="2. Implied based on Rx"
      getRowId={getIcdRowId}
      enableSelection
      selectedRowIds={selectedIcdIds}
      onSelectionChange={({
        description, diseaseKey, diagnosisDate, claimCode, claimCodeId, clinicalName,
      }: HealthRecordUserClaim) => {
        onIcdSelection({
          description, diseaseKey, diagnosisDate, claimCode, claimCodeId, clinicalName,
        });
      }}
      data={impliedUserClaims}
      columns={impliedUserClaimsColumns}
      emptyRowsMessage="No diagnoses imported"
      loading={healthRecordLoading}
      disableNewSelections={icdSelectionsCount >= DIAGNOSES_HARD_LIMIT}
      isRemoving={isRemovingIcd}
      hideOverlays
    />
  ), [impliedUserClaims, selectedIcdIds, userClaimsColumns, healthRecordLoading, isRemovingIcd, icdSelectionsCount]);

  const mergedIcdOverlaySelections = React.useMemo(() => [...selectedIcdIds, ...selectedNonIcdIds],
    [selectedIcdIds, selectedNonIcdIds]);

  const mergedIcdOverlayDeleteSelections = React.useMemo(() => [...selectedToDeleteIcd, ...selectedToDeleteNonIcd],
    [selectedToDeleteIcd, selectedToDeleteNonIcd]);

  const mergedClaimsOverlayGrid = React.useMemo(() => (
    <HealthRecordGrid
      getRowId={getIcdRowId}
      enableSelection
      selectedRowIds={mergedIcdOverlaySelections}
      selectedToDelete={mergedIcdOverlayDeleteSelections}
      onSelectionChange={({ hasICD, ...claim }: HealthRecordUserClaim) => {
        if (hasICD) onIcdSelection({ hasICD, ...claim });
        else onNonIcdSelection({ hasICD, ...claim });
      }}
      onSelectToDelete={({ hasICD, ...claim }: HealthRecordUserClaim) => {
        if (hasICD) onIcdDeleteSelection({ hasICD, ...claim });
        else onNonIcdDeleteSelection({ hasICD, ...claim });
      }}
      data={[]}
      overlays={overlayData?.userClaimsOverlays}
      columns={userClaimsColumns}
      emptyOverlaysMessage="No diagnoses added"
      isRemoving={isRemovingIcd}
      hideGridTitle
      loading={healthRecordLoading}
      disableNewSelections={icdSelectionsCount >= DIAGNOSES_HARD_LIMIT}
      overlayToolbar={(
        <OverlayToolbar
          panel={<AddICDPanel setPanelClosed={toggleAddingIcdDiagnoses} />}
          showPanel={isAddingIcdDiagnoses}
          toggleShowPanel={toggleAddingIcdDiagnoses}
          isRemoving={isRemovingIcd}
          addButtonText="Add Diagnosis"
          removeButtonText="Remove Diagnosis"
          toggleRemove={toggleRemovingIcd}
          onConfirmRemove={deleteMergedIcdOverlay}
          removeDisabled={!mergedIcdOverlayDeleteSelections.length}
        />
      )}
    />
  ), [mergedIcdOverlayDeleteSelections,
    userClaimsColumns, healthRecordLoading,
    isAddingIcdDiagnoses, toggleAddingIcdDiagnoses, overlayData?.userClaimsOverlays,
    mergedIcdOverlaySelections, deleteMergedIcdOverlay, isRemovingIcd, isRemovingNonIcd,
  ]);

  const editDrugPanel = editDrugContextLoading
    ? (
      <EditPanelPlaceholder>
        <QueryLoader loading={editDrugContextLoading} componentName="EditDrugPanel" />
      </EditPanelPlaceholder>
    )
    : (
      <AddDrugPanel
        setPanelClosed={onCloseEditDrugPanel}
        selectedOverlayId={selectedDrugId}
        {...(selectedDrug ? editDrugContextData?.getEditDrugContext : {})}
        selectedFillQuantity={editDrugContextData?.getEditDrugContext?.selectedFillQuantity ?? selectedDrug?.fillQuantity}
        selectedDaysOfSupply={editDrugContextData?.getEditDrugContext?.selectedDaysOfSupply ?? selectedDrug?.daysOfSupply}
      />
    );

  const content = !overlayError
    ? (
      <Background>
        {doctorsGrid}
        {!isBionicLite && (
        <>
          <ValidateAdditionalDoctors />
          {selectedDoctors?.length < 2 && (
          <HasMinimumRecommendedDoctors />
        )}
        </>
        )}
        <Divider />
        {pharmacyGrid}
        <Divider />
        {drugsGrid}
        {isEditingDrugs && (
          <GridElementContainer>
            {editDrugPanel}
          </GridElementContainer>
        )}
        <ValidateAdditionalPrescriptions />
        <Divider />
        {
          !isBionicThin
          && (
          <>
            {icdUserClaimsGrid}
            <Divider />
            {impliedUserClaimsGrid}
            {mergedClaimsOverlayGrid}
            <AnticipatedNeedsGrid
              anticipatedNeedsOverlays={overlayData?.anticipatedNeedsOverlays}
              cellWidth={cellWidth}
              isReduceViewPort={isReducedViewport}
            />
          </>
          )
        }

        <Snackbar open={editDrugErrorMessageVisible} autoHideDuration={6000} onClose={onEditDrugSnackbarClose}>
          <Alert severity="error">
            Failed to edit selected drug. Please add it manually.
          </Alert>
        </Snackbar>
      </Background>
    )
    : (
      <HealthRecordErrorMessage
        message={
          `Failed to retrieve health records.${overlayError ? ` ${overlayError}` : ''}`
        }
        severity="error"
      />
    );

  return (
    <div ref={measureRef}>
      <QueryLoader loading={overlayLoading} componentName="CheckMyHealthRecord">
        <HealthRecordPullStatusWarning
          pullStatus={pullStatus}
        />
        {content}
      </QueryLoader>
    </div>
  );
});
