import React, { FC } from 'react';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import {
  allProductTypes,
  ProductType,
  QuizStatus,
} from '@hiq/crm.types';
import { urlService } from '@healthiqeng/core.services.url';
import {
  graphqlClient,
  internalAuthService,
} from '../../../services';
import { MuiThemeLight, ThemeLight } from '../../../themes';
import { AuthenticatedPage } from '../Page/AuthenticatedPage';
import { QALeadCreatorPageProps } from './QALeadCreatorPage.interfaces';

const CREATE_TEST_LEAD = gql`
  mutation CreateTestLead($input: TestLeadInput!) {
    createTestLead(input: $input) {
      id
    }
  }
`;

interface CreateTestLeadResponse {
  createTestLead: {
    id: string;
  };
}

interface CreateTestLeadRequest {
  input: {
    productType: ProductType;
    isInbound?: boolean;
    quizStatus?: QuizStatus;
  }
}

const QALeadCreatorContent: FC<QALeadCreatorPageProps> = ({ productType }) => {
  const [createTestLead, { data, error }] = useMutation<CreateTestLeadResponse, CreateTestLeadRequest>(CREATE_TEST_LEAD);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const inboundProp = urlService.getUrl().getParam('inbound');
  const quizStatus = urlService.getUrl().getParam('quizStatus') as QuizStatus;
  const isInbound: boolean = (inboundProp && inboundProp === 'true') ?? false;

  React.useEffect(() => {
    if (!allProductTypes.includes(productType)) {
      setErrorMessage(
        `Invalid Product type "${productType}".\nList of possible values: \n${allProductTypes.join('\n')}`,
      );
      return;
    }

    if (quizStatus && !Object.values(QuizStatus).includes(quizStatus)) {
      setErrorMessage(
        `Invalid Quiz Status value: "${quizStatus}".\nList of possible values: ${Object.values(QuizStatus).join(', ')}`,
      );
      return;
    }

    createTestLead({
      variables: {
        input: {
          productType,
          isInbound,
          quizStatus: quizStatus ?? undefined,
        },
      },
    });
  }, []);

  React.useEffect(() => {
    if (data?.createTestLead?.id) {
      window.location.href = `/customer/${data?.createTestLead?.id}`;
    }
  }, [data?.createTestLead?.id]);

  return (
    <>
      {errorMessage && (
        <div>
          <p>There was an error creating the Lead.</p>
          <pre>{ errorMessage }</pre>
        </div>
      )}
      {!errorMessage && error && (
        <div>
          <p>Uh Oh! Something went wrong...</p>
          <pre>{ error.message }</pre>
        </div>
      )}
      {!errorMessage && !error && (
        <p>
          <b>
            Creating Test Lead for the Product-Type = &quot;
            {productType}
            &ldquo;...
          </b>
        </p>
      )}
    </>
  );
};

export const QALeadCreatorPage: FC<QALeadCreatorPageProps> = ({ productType }) => (
  <AuthenticatedPage
    title="HIQ CRM | OAuth Apps"
    theme={ThemeLight}
    muiTheme={MuiThemeLight}
    graphqlClient={graphqlClient}
    internalAuthService={internalAuthService}
  >
    <QALeadCreatorContent productType={productType} />
  </AuthenticatedPage>
);
