import { UserInteractionType } from '@hiq/crm.types';
import { MutationCustomReturn } from '../useMutationCustom';
import { useLogInteraction } from './useLogInteraction';
import {
  LogClickInteractionOptions,
  LogUserInteractionMutationResponse,
} from './useLogUserInteractionMutation.interfaces';

export function useLogClick(): MutationCustomReturn<LogUserInteractionMutationResponse, LogClickInteractionOptions> {
  const [logInteraction, result] = useLogInteraction();
  const logClickInteraction = ({ control, detail }: LogClickInteractionOptions) => logInteraction({
    type: UserInteractionType.Click,
    target: control,
    detail,
  });

  return [
    logClickInteraction,
    result,
  ];
}
