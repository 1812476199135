import * as React from 'react';
import { ProductTypePropertyAndCasualty } from '@hiq/crm.types';
import { useActiveLeadId, useLeadSectionRoute } from '../../../../routers';
import {
  useLeadQuery,
  ConvertSuccessEvent,
} from '../../../../../graphql';
import { useEventListener } from '../../../../../events/useEventListener';
import { useQuestionFlowAnchorQuery } from '../../useQuestionFlowAnchorQuery';
import {
  LogPanelChange,
  QuestionFlow,
} from '../../../../common';
import { MainPanelLayout, MainPanelLayoutTabItem } from '../../../../layouts';
import { PropertyAndCasualtyLeadPanelCard } from '../elements';
import {
  PropertyAndCasualtyConversionPanel,
  PropertyAndCasualtyProducerStatementPanel,
  PropertyAndCasualtyQuotePanelFactory,
} from '../panels';
import { LeadPanelContainer } from '../../elements';
import { LeadInfo } from '../../common/LeadInfo';

export const PropertyAndCasualtyPostConversionLead: React.FunctionComponent = () => {
  const leadId = useActiveLeadId();
  const { data: leadData, refetch } = useLeadQuery();
  useEventListener(ConvertSuccessEvent, () => refetch());

  const productType: ProductTypePropertyAndCasualty = leadData?.productType as ProductTypePropertyAndCasualty;
  const isAuto = productType === ProductTypePropertyAndCasualty.Auto;
  const interviewAnchors = useQuestionFlowAnchorQuery(leadId, isAuto ? 'interview' : 'quoting');
  const [activeTab, setActiveTab] = useLeadSectionRoute(isAuto ? 'interview' : 'quoting');

  const tabs: MainPanelLayoutTabItem[] = [
    {
      id: 'lead-info',
      label: 'Lead Info',
      icon: 'person',
      content: (
        <PropertyAndCasualtyLeadPanelCard>
          <LeadInfo leadId={leadId} />
        </PropertyAndCasualtyLeadPanelCard>
      ),
    },
    {
      id: 'conversion',
      label: 'Conversion',
      icon: 'done',
      content: (
        <>
          <LogPanelChange panel="conversion" />
          <PropertyAndCasualtyConversionPanel converted />
        </>
      ),
    },
    {
      id: 'quoting',
      label: 'Quote',
      icon: 'local-offer',
      content: (
        <LeadPanelContainer>
          <LogPanelChange panel="quoting" />
          <PropertyAndCasualtyQuotePanelFactory
            productType={productType}
          />
        </LeadPanelContainer>
      ),
    },
    {
      id: 'producer-statement',
      label: 'Producer Statement',
      icon: 'person',
      content: (
        <PropertyAndCasualtyLeadPanelCard>
          <LogPanelChange panel="producer-statement" />
          <PropertyAndCasualtyProducerStatementPanel
            productType={productType}
            converted
          />
        </PropertyAndCasualtyLeadPanelCard>
      ),
    },
  ];

  if (isAuto) {
    tabs.splice(1, 0, {
      id: 'interview',
      label: 'Interview',
      icon: 'assignment',
      anchors: interviewAnchors,
      content: (
        <PropertyAndCasualtyLeadPanelCard>
          <LogPanelChange panel="interview" />
          <QuestionFlow
            leadId={leadId}
            referenceId="interview"
            root
          />
        </PropertyAndCasualtyLeadPanelCard>
      ),
    });
  }

  return (
    <MainPanelLayout
      tabs={tabs}
      activeId={activeTab}
      onTabChange={setActiveTab}
    />
  );
};
