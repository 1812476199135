import React from 'react';
import { useActiveLeadId } from '../../../../../../routers/LeadRouter/useActiveLeadId';
import { QuestionFlow } from '../../../../../../common/QuestionFlow';

export const Payment: React.FunctionComponent<any> = () => {
  const leadId = useActiveLeadId();
  return (
    <div>
      <QuestionFlow leadId={leadId} referenceId="medicare-enrollment-payment" root />
    </div>
  );
};
