import * as React from 'react';
import { AgentHomeLayoutProps } from './AgentHomeLayout.interfaces';
import {
  AgentHomeLayoutContainer, MainPanelContainer,
  NavBarContainer,
} from './AgentHomeLayout.elements';

export const AgentHomeLayout: React.FunctionComponent<AgentHomeLayoutProps> = ({
  navBar,
  mainPanel,
}) => (
  <AgentHomeLayoutContainer>
    <NavBarContainer>
      {navBar}
    </NavBarContainer>
    <MainPanelContainer>
      {mainPanel}
    </MainPanelContainer>
  </AgentHomeLayoutContainer>
);
