import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { AvertaPeNormal400 } from '@healthiqeng/core.fonts';
import { resetInput } from '../resets';

interface IInputProps {
  controlSize: 'small' | 'large';
}

const sizeMap = {
  small: css`
    line-height: 20px;
    font-size: 16px;
    height: 48px;
  `,
  large: css`
    line-height: 16px;
    font-size: 16px;
    height: 56px;
  `,
};

export const InputControl = styled('input')<IInputProps>(resetInput, (props) => css`
  ${AvertaPeNormal400};
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 0 20px;
  background: #fff;
  border: 1px solid #dee2e5;
  border-radius: 2px;
  box-shadow: none;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #1a1c1f;

  ::placeholder {
    color: #b1b7ba;
  }

  ::-ms-clear {
    display: none;
  }

  ${sizeMap[props.controlSize]}
`);
