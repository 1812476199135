import React from 'react';
import { CompareContext } from '../../CompareContext/CompareContext';
import { CompareMedCostSectionRow } from './CompareMedCostSectionRow';
import { CompareSection } from '../../CompareSection';
import { PlanMedSection } from '@hiq/crm.types';

export const CompareMedCosts = () => {
  const {
    emitPlanComparisonEvent,
    currentPlan,
    currentComparisonPlan,
  } = React.useContext(CompareContext);

  const onToggle = React.useCallback(() => emitPlanComparisonEvent('Opened Med costs'),
    [emitPlanComparisonEvent]);

  const sections = React.useMemo(() => {
    const sections: Record<string, {currentComparisonPlan?: PlanMedSection, currentPlan?: PlanMedSection}> = {};
    currentComparisonPlan?.hiqMedCostsResults?.hiqSections?.forEach((section) => {
      sections[section.name] = {
        currentComparisonPlan: section,
      };
    });
    currentPlan?.hiqMedCostsResults?.hiqSections?.forEach((section) => {
      sections[section.name] = {
        ...sections[section.name],
        currentPlan: section,
      };
    });
    return sections;
  }, [currentPlan, currentComparisonPlan]);

  const currentPlanTotalCost = React.useMemo(() => {
    let currentPlanTotalCost = 0;
    currentPlan?.hiqMedCostsResults.hiqSections.forEach(
      (section) => section.benefits.forEach(
        (benefit) => {
          currentPlanTotalCost += benefit.costShare;
        },
      ),
    );
    return currentPlanTotalCost;
  }, [currentPlan]);

  const comparisonPlanTotalCost = React.useMemo(() => {
    let comparisonPlanTotalCost = 0;
    currentComparisonPlan?.hiqMedCostsResults.hiqSections.forEach(
      (section) => section.benefits.forEach(
        (benefit) => {
          comparisonPlanTotalCost += benefit.costShare;
        },
      ),
    );
    return comparisonPlanTotalCost;
  }, [currentComparisonPlan]);

  return (
    <CompareSection
      title="Patients like you tend to spend (BETA)"
      currentPlanRank={currentPlan?.hiqMedCostsResults?.hiqRank}
      comparisonPlanRank={currentComparisonPlan?.hiqMedCostsResults?.hiqRank}
      currentPlanTotalCost={currentPlanTotalCost}
      comparisonPlanTotalCost={comparisonPlanTotalCost}
      onToggle={onToggle}
      padding="18px"
      fontSize="16px"
      isOpen={false}
    >
      {Object.entries(sections)?.map(([name, { currentPlan, currentComparisonPlan }]) => (
        <CompareMedCostSectionRow
          currentPlanCosts={currentPlan?.benefits}
          comparisonPlanCosts={currentComparisonPlan?.benefits}
          name={name}
        />
      ))}
    </CompareSection>
  );
};
