import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  SunfireV2EditDrugContextQueryData,
  SunfireV2EditDrugContextQueryVariables,
} from './useSunfireV2EditDrugContextQuery.interfaces';

const SUNFIRE_EDIT_DRUG_CONTEXT_QUERY = gql`
  query GetSunfireV2EditDrugContext($dosageName: String!, $ndc: String, $trustDosageName: Boolean) {
    getEditDrugContext(dosageName: $dosageName, ndc: $ndc, trustDosageName: $trustDosageName) {
      dosages {
        id
        name
        quantity
        strength
        strengthUnitOfMeasure
        form
        isGeneric
        packages {
          packageId
          packageDescription
          priceCategoryId
          proxyNationalDrugCode
          isCommonPackage
        }
        isCommonDosage
        defaultFrequency
      }
      selectedDrug {
        id
        name
      }
      selectedDosage {
        id
        name
        quantity
        strength
        strengthUnitOfMeasure
        form
        isGeneric
        packages {
          packageId
          packageDescription
          priceCategoryId
          proxyNationalDrugCode
          isCommonPackage
        }
        isCommonDosage
        defaultFrequency
      }
      selectedPackage {
        packageId
        packageDescription
        priceCategoryId
        proxyNationalDrugCode
        isCommonPackage
      }
      selectedFillQuantity
      selectedDaysOfSupply
    }
  }
`;

export function useSunfireV2EditDrugContextQuery() {
  return useLazyQuery<SunfireV2EditDrugContextQueryData, SunfireV2EditDrugContextQueryVariables>(SUNFIRE_EDIT_DRUG_CONTEXT_QUERY, {
    fetchPolicy: 'network-only',
  });
}
