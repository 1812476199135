import { ApolloError, gql } from 'apollo-boost';
import { MutationCustomReturn, useMutationCustom } from '../useMutationCustom';
import { AddDialerCallMutationResponse, AddDialerCallMutationVariables } from './useAddDialerCallMutation.interfaces';
import { useCallback } from 'react';
import { emitAnalyticsEvent } from '../../../events/emitAnalyticsEvent';

const ADD_DIALER_CALL_MUTATION = gql`
  mutation AddDialerCall($customerId: ID!, $input: AddDialerCallDataInput!) {
    addDialerCall(customerId: $customerId, input: $input) {
      code
      success
      message
      timestamp
      dialerCall {
        urlPath
      }
    }
  }
`;

const onCompleted = () => emitAnalyticsEvent('Success Adding Dialer Call');
const onError = (error: ApolloError) => emitAnalyticsEvent('Error Adding Dialer Call', { error: error?.message });

export function useAddDialerCallMutation(): MutationCustomReturn<
AddDialerCallMutationResponse,
AddDialerCallMutationVariables> {
  const [addDialerCall, result] = useMutationCustom<
  AddDialerCallMutationResponse,
  AddDialerCallMutationVariables>(ADD_DIALER_CALL_MUTATION, { onCompleted, onError });
  const addDialerCallWrapped = useCallback(async (variables: AddDialerCallMutationVariables) => {
    emitAnalyticsEvent('Adding Dialer Call', { dialerCallInput: variables.input, customerId: variables.customerId });
    const response = await addDialerCall(variables);
    return response;
  }, [addDialerCall]);

  return [addDialerCallWrapped, result];
}
