import * as React from 'react';
import { ScriptBlockProps } from './ScriptBlock.interfaces';
import {
  ScriptBlockContainer,
  ScriptBlockColorDiv,
  ScriptBlockContent,
  ScriptBlockIcon,
  ScriptBlockIconContainer,
  ScriptBlockText,
} from '../elements';
import { ThemeLight } from '../../../../themes';

export const ScriptBlock: React.FunctionComponent<ScriptBlockProps> = ({
  children,
}) => (
  <ScriptBlockContainer>
    <ScriptBlockColorDiv color={ThemeLight.color.primary} />
    <ScriptBlockContent>
      <ScriptBlockIconContainer>
        <ScriptBlockIcon src={require('../assets/chat-bubble.svg')} alt="info icon" />
      </ScriptBlockIconContainer>
      <ScriptBlockText>
        { children }
      </ScriptBlockText>
    </ScriptBlockContent>
  </ScriptBlockContainer>
);
