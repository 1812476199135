import React from 'react';
import { SelectInput } from '../../inputs';
import { useFormAnswers } from '../../../../hooks';
import { useDirtySunfireData } from '../useDirtySunfireData';
import { JsonValue } from '@healthiqeng/core.types';
import { MedicareTypeContainer } from '../SunfireIframe.elements';

export const MedicareType = [
  {
    label: 'Medicare Advantage with Part D',
    value: 'MAPD',
  },
  {
    label: 'Medicare Advantage only',
    value: 'MA',
  },
  {
    label: 'Special Needs plan',
    value: 'SN',
  },
];

export const SELECTED_MEDICARE_TYPE = 'lead.sunfire.v2.medicareType';

const SunfirePlanType = () => {
  const [, setSunfireDataDirty] = useDirtySunfireData();

  const [answers, saveAnswer] = useFormAnswers();

  const saveMedicareType = (value: JsonValue) => {
    saveAnswer(SELECTED_MEDICARE_TYPE, value);
    setSunfireDataDirty(true);
  };

  return (
    <MedicareTypeContainer>
      <SelectInput
        fullWidth
        size="small"
        value={answers[SELECTED_MEDICARE_TYPE]}
        onChange={saveMedicareType}
        options={MedicareType}
        disableClearable
        showEmptyOption={false}
      />
    </MedicareTypeContainer>

  );
};

export default SunfirePlanType;
