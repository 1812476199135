import styled from '@emotion/styled';
import { Alert } from '@material-ui/lab';

export const WarningMessage = styled(Alert)`
  align-items: center;
`;

export const EditButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  cursor: pointer;
`;

export const HorizontalDivider = styled.div`
  height: 100%;
  border: 1px solid #DEE2E5;
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 60px;
  width: 100%;
  height: 100%;
  margin-right: 10px;
`;
