import * as React from 'react';
import { QuestionCellProps } from './QuestionCell.interfaces';
import {
  QuestionHelpText,
  QuestionText,
  QuestionTextContainer,
  QuestionViewContainer,
} from './QuestionCell.elements';
import ReactMarkdown from 'react-markdown';
import { LinkRenderer } from '../../../../../QuestionFlow/QuestionFlowRenderer/LinkRenderer';
import { BlockQuoteRenderer } from '../../../../../QuestionFlow/QuestionFlowRenderer/BlockQuoteRenderer';
import { ScriptBlock } from '../../../../../ScriptBlock/ScriptBlock';
import { MustReadBlock } from '../../../../../ScriptBlock/MustReadBlock';
import { InfoBlock } from '../../../../../ScriptBlock/InfoBlock';
import { useTemplateReplacer } from '../../../../../QuestionFlow/QuestionFlowRenderer/useTemplateReplacer';
import { TextRenderer } from '../../../../../QuestionFlow/QuestionFlowRenderer/TextRenderer';
import { ParagraphRenderer } from '../../../../../QuestionFlow/QuestionFlowRenderer/ParagraphRenderer';
import { TextBlock } from '../../../../../ScriptBlock/TextBlock';
import { WarningBlock } from '../../../../../ScriptBlock/WarningBlock';
import {
  DirectionalCopy, FlexibleScript, FlexibleScriptVariationType, InlineText,
} from '../../../../../ScriptBlock';
import { FormItemTag } from '@hiq/crm.types';
import { Note } from '../../../../../Note';

export const QuestionCellComponent: React.FunctionComponent<QuestionCellProps> = (({
  children,
  questionText,
  helpText,
  CustomViewRenderer,
  overrideQuestionText,
  tags,
}) => {
  const questionTextRenderers = React.useMemo(() => ({
    blockquote: BlockQuoteRenderer,
    code: ScriptBlockRenderer,
    link: LinkRenderer,
    inlineCode: CustomViewRenderer,
    text: TextRenderer,
  }), [CustomViewRenderer]);
  const helpTextRenderers = React.useMemo(() => ({
    text: TextRenderer,
  }), []);

  let questionTextToRender = questionText;

  if (tags && tags?.includes(FormItemTag.Script)) {
    questionTextToRender = `\`\`\`script\n\n${questionText}\n\n\`\`\``;
  }

  const questionMarkdown = useTemplateReplacer(questionTextToRender);
  const helpTextMarkdown = useTemplateReplacer(helpText);
  return (
    <QuestionViewContainer>
      <QuestionTextContainer>
        <QuestionText>
          <ReactMarkdown source={overrideQuestionText ?? questionMarkdown} renderers={questionTextRenderers} linkTarget="_blank" />
        </QuestionText>
      </QuestionTextContainer>
      { helpText && (
      <QuestionHelpText>
        <ReactMarkdown source={helpTextMarkdown} renderers={helpTextRenderers} linkTarget="_blank" />
      </QuestionHelpText>
      ) }
      { children }
    </QuestionViewContainer>
  );
});

function ScriptBlockRenderer({ value, language }: { value: string; language: string }) {
  switch (language) {
    case 'verbatim':
      return (
        <MustReadBlock confirmationRequired confirmText="I acknowledge that I've read this" text={value}>
          <MarkdownWrapper value={value} />
        </MustReadBlock>
      );
    case 'text':
      return (
        <TextBlock>
          <MarkdownWrapper value={value} />
        </TextBlock>
      );
    case 'info':
      return (
        <InfoBlock>
          <MarkdownWrapper value={value} />
        </InfoBlock>
      );
    case 'warning':
      return (
        <WarningBlock text={value}>
          <MarkdownWrapper value={value} />
        </WarningBlock>
      );

    case 'directional-copy':
      return (
        <DirectionalCopy>
          <MarkdownWrapper value={value} />
        </DirectionalCopy>
      );

    case 'read-exactly':
      return (
        <FlexibleScript variation={FlexibleScriptVariationType.READ_EXACTLY}>
          <MarkdownWrapper value={value} />
        </FlexibleScript>
      );
    case 'read-flexible':
      return (
        <FlexibleScript variation={FlexibleScriptVariationType.READ_FLEXIBLE}>
          <MarkdownWrapper value={value} />
        </FlexibleScript>
      );
    case 'read-flexible-exact':
      return (
        <FlexibleScript variation={FlexibleScriptVariationType.READ_FLEXIBLE_EXACT}>
          <MarkdownWrapper value={value} />
        </FlexibleScript>
      );
    case 'script':
      return (
        <FlexibleScript variation={FlexibleScriptVariationType.READ_FREE}>
          <MarkdownWrapper value={value} />
        </FlexibleScript>
      );
    case 'read-highlighted-exactly':
      return (
        <FlexibleScript variation={FlexibleScriptVariationType.READ_HIGHLIGHTED_EXACT}>
          <MarkdownWrapper value={value} />
        </FlexibleScript>
      );
    case 'rebuttal':
      return (
        <FlexibleScript variation={FlexibleScriptVariationType.REBUTTAL}>
          <MarkdownWrapper value={value} />
        </FlexibleScript>
      );
    case 'note':
      return (
        <Note value={value}>
          <MarkdownWrapper value={value} />
        </Note>
      );
    case 'inline-text':
      return <InlineText value={value} />;
    default:
      return (
        <ScriptBlock>
          <MarkdownWrapper value={value} />
        </ScriptBlock>
      );
  }
}

type MarkdownWapperProps = {
  value: string;
};

const MarkdownWrapper: React.FC<MarkdownWapperProps> = ({ value }) => (
  <ReactMarkdown
    linkTarget="_blank"
    source={value}
    renderers={{
      text: TextRenderer,
      paragraph: ParagraphRenderer,
    }}
  />
);

export const QuestionCell = React.memo(QuestionCellComponent);
