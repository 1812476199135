import { useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import {
  LifeQuoteQueryData,
  LifeQuoteQueryVariables,
} from './useLifeQuoteLazyQuery.interfaces';
import { QUOTE_FRAGMENT } from '../../fragments';

const LIFE_QUOTE_QUERY = gql`
  query getLifeQuotes($leadId: ID!, $inputs: LifeQuoteQueryInput!) {
    lifeQuote(leadId: $leadId, inputs: $inputs) {
      quoteResults {
        quote {
          ...QuoteFragment
        }
        notes {
          type
          message
        }
      }
    }
  }
  ${QUOTE_FRAGMENT}
`;

export function useLifeQuoteLazyQuery() {
  return useLazyQuery<LifeQuoteQueryData, LifeQuoteQueryVariables>(
    LIFE_QUOTE_QUERY,
    { fetchPolicy: 'network-only' },
  );
}
