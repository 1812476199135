import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../themes';

export const SidePanelContentContainer = styled('div')(css`
  height: 100%;
  display: flex;
  flex-direction: column;
`);

export const SidePanelContentHeader = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  margin: 0;
  padding: 5px;
  border-bottom: 1px solid ${theme.color.grayLight};
  font-size: 16px;
  font-weight: 500;
`);

export const SidePanelContentBodyContainer = styled('div')(css`
  flex: 1;
  overflow-y: scroll;
`);
