import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../../themes';

export const CustomerTagsContainer = styled('div')(css`
  margin-right: -5px;
  margin-bottom: -5px;
`);

export const TagWrapper = styled('div')(css`
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
`);

export const AlertWrapper = styled('div')(css`
  padding-top: 3px;
  padding-bottom: 3px;
  margin-right: 5px;
  font-weight: bold;
`);

export const AlertsWrapper = styled('div')(css`
  margin-top: -5px;
`);

export const CustomAlertText = styled('span')<ComponentWithThemeProps>(({ theme }) => css`
  font-weight: bold;
  color: ${theme.color.black};
`);
