import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import {
  SelectedPrescriptionsQueryData,
  SelectedPrescriptionsQueryVariables,
} from './useSelectedPrescriptionsQuery.interfaces';
import { PRECISION_MEDICARE_PRESCRIPTION_FRAGMENT } from '../../fragments';

export const SELECTED_PRESCRIPTIONS_QUERY = gql`
  query SelectedPrescriptions($leadId: LeadId!) {
    selectedPrescriptions(leadId: $leadId) {
      ...PrecisionMedicarePrescriptionFragment
    }
  }
  ${PRECISION_MEDICARE_PRESCRIPTION_FRAGMENT}
`;

export function useSelectedPrescriptionsQuery(leadId: string) {
  return useQuery<SelectedPrescriptionsQueryData, SelectedPrescriptionsQueryVariables>(SELECTED_PRESCRIPTIONS_QUERY, {
    variables: { leadId },
  });
}
