import { numberTransformerFactory } from '../../../../inputs';
import React from 'react';
import { FillQuantitySelectionProps } from './FillQuantitySelection.interfaces';
import { RefillFrequencyContainer } from './FillQuantitySelection.elements';
import { TextInput } from '../../../../inputs/TextInput';
import { PaddingBottom } from '../../CheckMyHealthRecordDataGrid.elements';
import {
  FormControl, InputLabel, MenuItem, Select,
} from '@material-ui/core';

export const FillQuantitySelection = ({
  onRefillQuantityChanged,
  refillQuantity,
  daysOfSupply,
  onDaysOfSupplyChanged,
}: FillQuantitySelectionProps) => {
  const options = React.useMemo(() => [
    { label: 'Every month', value: 30 },
    { label: 'Every 2 months', value: 60 },
    { label: 'Every 3 months', value: 90 },
    { label: 'Every 6 months', value: 180 },
    { label: 'Every 12 months', value: 365 },
  ].map((opt) => <MenuItem value={opt.value}>{opt.label}</MenuItem>), []);

  return (
    <PaddingBottom>
      <TextInput
        variant="standard"
        label="Quantity per refill"
        value={refillQuantity}
        onChange={onRefillQuantityChanged}
        transformerFactory={numberTransformerFactory}
      />
      <RefillFrequencyContainer>
        <FormControl>
          <InputLabel>Refill frequency</InputLabel>
          <Select
            style={{ width: 160 }}
            value={daysOfSupply}
            onChange={((event) => onDaysOfSupplyChanged(event.target.value as number))}
          >
            {options}
          </Select>
        </FormControl>
      </RefillFrequencyContainer>
    </PaddingBottom>
  );
};
