import React, { MouseEvent, useState } from 'react';
import { IconButton, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

import { StyledMenu } from './NavBarMenu.elements';
import { logout } from '../../../../util';

export const NavBarMenu: React.FC<{}> = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVert style={{ fill: 'white' }} />
      </IconButton>
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {menuList.map((menu) => (
          <MenuItem key={menu.label} onClick={menu.onClick}>Logout</MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
};

const menuList = [
  {
    label: 'Logout',
    onClick: async () => logout(),
  },
];
