import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { logger } from '../../../services';
import { VariationChangeEvent, useEventListener } from '../../../events';
import {
  ApplicationHasImpairmentQueryData,
  ApplicationHasImpairmentQueryVariables,
} from './useApplicationHasImpairmentQuery.interfaces';

export const APPLICATION_HAS_IMPAIRMENT_QUERY = gql`
    query applicationHasImpairmentQuery($leadId: ID!) {
      hasImpairment(leadId: $leadId) {
        hasImpairment
      }
    }
`;

export function useApplicationHasImpairmentQuery(leadId: string): boolean {
  const result = useQuery<ApplicationHasImpairmentQueryData, ApplicationHasImpairmentQueryVariables>(
    APPLICATION_HAS_IMPAIRMENT_QUERY,
    {
      variables: {
        leadId,
      },
    },
  );

  const { data, refetch } = result;

  useEventListener(VariationChangeEvent, () => {
    refetch().catch((err) => logger.crit(err));
  }, [data]);

  return result?.data?.hasImpairment?.hasImpairment ?? false;
}
