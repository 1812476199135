import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import {
  SunfireV2CitiesByZipQueryData,
  SunfireV2CitiesByZipQueryVariables,
} from './useSunfireV2CitiesByZipQuery.interfaces';

const SUNFIRE_CITIES_BY_ZIP_QUERY = gql`
  query FindSunfireV2CitiesByZip($zip: String!) {
    findSunfireCitiesByZip(zip: $zip) {
      city
      state
      zip
    }
  }
`;

export function useSunfireV2CitiesByZipQuery(zip: string) {
  return useQuery<SunfireV2CitiesByZipQueryData, SunfireV2CitiesByZipQueryVariables>(SUNFIRE_CITIES_BY_ZIP_QUERY, {
    variables: { zip },
  });
}
