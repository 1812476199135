import React, { useEffect, ChangeEvent } from 'react';

import {
  Divider, SelectInput, TextInput, zipCodeTransformerFactory,
} from '../../common';
import {
  useSunfireV2PlansByZipAndCountyQueryLazy,
} from '../../../graphql/queries/useSunfireV2PlansByZipAndCountyQuery/useSunfireV2PlansByZipAndCountyQuery';
import {
  RadioGroup, Radio, Box, Button, Checkbox, CircularProgress,
} from '@material-ui/core';
import { useGetAddressFromZipQuery } from '../../../graphql';
import { SidePanelContentLayout } from '../../layouts/SidePanelContentLayout';
import {
  AvailablePlansContainer,
  Bold,
  RowContainer,
  Paragraph,
  PlanStarIcon,
  VerticalCenterContainer,
} from './AvailablePlans.elements';
import { AddressFromZip } from '../../common/CheckMyHealthRecord/CheckMyHealthRecordDataGrid/LocationSearchPanel';
import { JsonValue } from '@healthiqeng/core.types';
import { YearFilter } from '../../common/SunfireIframe/TRMQuote/CompareHeader/YearFilter';
import { useFormAnswers } from '../../../hooks';

enum MedicarePlanType {
  MAPD = 'MAPD',
  PD = 'PD',
  MA = 'MA',
  SN = 'SN',
}

export const EFFECTIVE_DATE_QUESTION_ID = 'lead.sunfire.v2.effectiveDate';

export const AvailablePlans: React.FunctionComponent = () => {
  const MedicareTypeOptions = [
    {
      label: 'Medicare Advantage with Part D',
      value: MedicarePlanType.MAPD,
    },
    {
      label: 'Part D only',
      value: 'PD',
    },
    {
      label: 'Medicare Advantage only',
      value: MedicarePlanType.MA,
    },
    {
      label: 'Special Needs plan',
      value: MedicarePlanType.SN,
    },
  ];

  const [zipCode, setZipCode] = React.useState(null);
  const [county, setCounty] = React.useState(null);
  const [locations, setLocations] = React.useState<AddressFromZip[]>([]);
  const [findPlansByZipAndCounty, { data: availablePlansData, loading }] = useSunfireV2PlansByZipAndCountyQueryLazy();
  const [plans, setPlans] = React.useState<any[]>([]);
  const [medicareType, setMedicareType] = React.useState(MedicarePlanType.MAPD);
  const [filterByFiveStar, setFilterByFiveStar] = React.useState<boolean>(false);
  const [filteredPlans, setFilteredPlans] = React.useState<any[]>([]);
  const [hasSearched, setHasSearched] = React.useState<boolean>(false);
  const [answers] = useFormAnswers();
  const year = answers[EFFECTIVE_DATE_QUESTION_ID] ?? 2023;
  const [
    getAddressFromZipQuery,
    {
      data: addressData,
    },
  ] = useGetAddressFromZipQuery();

  React.useEffect(() => {
    if (addressData?.getAddress?.address?.length > 0) {
      const { address } = addressData.getAddress;
      const locationsToSet = address.map(({ city, state, county }) => {
        const address: AddressFromZip = {
          city,
          state,
          county,
          zip: zipCode,
        };

        return address;
      });

      setLocations(locationsToSet);
    }
  }, [addressData]);

  function resetSearch() {
    setLocations([]);
    setCounty(null);
    setHasSearched(false);
    resetFilteredPlans();
  }

  function resetFilteredPlans() {
    setFilteredPlans([]);
  }

  function resetPlans() {
    setPlans([]);
  }

  const onZipChange = (value?: string) => {
    setZipCode(value);

    resetSearch();
    resetFilteredPlans();
    resetPlans();

    if (!value) return;
    getAddressFromZipQuery({ variables: { zip: value } });
  };

  const handleCountySelection = React.useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    resetFilteredPlans();
    setCounty(value);
  }, []);

  const onClickSearch = React.useCallback(() => {
    findPlansByZipAndCounty({ variables: { zipCode, county, year } });
  }, [findPlansByZipAndCounty, zipCode, county, year]);

  useEffect(() => {
    if (!availablePlansData) return;
    const availablePlansResult = availablePlansData.findSunfirePlansByZipAndCounty;
    setPlans(availablePlansResult);
    setHasSearched(true);
  }, [availablePlansData]);

  useEffect(() => {
    if (!plans) return;

    if (medicareType === MedicarePlanType.SN) {
      setFilteredPlans(plans?.filter((plan) => plan.snp !== null));
    } else {
      setFilteredPlans(plans?.filter((plan) => plan.type === medicareType && !plan.snp));
    }
  }, [plans, medicareType]);

  const handleMedicareTypeChanged = (value: JsonValue) => {
    setMedicareType(value as MedicarePlanType);
  };

  const handleFiveStarFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setFilterByFiveStar(event.target.checked);
  };

  useEffect(() => {
    if (!filterByFiveStar) {
      setFilteredPlans(plans);
    } else {
      setFilteredPlans(plans?.filter((plan) => plan.rating === '5 out of 5 stars'));
    }
  }, [filterByFiveStar]);

  return (
    <SidePanelContentLayout header="Quick Quote">
      <AvailablePlansContainer>
        <TextInput label="Zip Code" value={zipCode} onChange={onZipChange} transformerFactory={zipCodeTransformerFactory} />
        <YearFilter />

        {locations.length > 0 && (
          <>
            <Bold>County</Bold>
            <RadioGroup>
              {locations.map((location) => (
                <Box display="flex" justifyContent="flex-start" alignItems="flex-end">
                  <Radio value={location.county} color="primary" onChange={handleCountySelection} />
                  <Paragraph>{location.county}</Paragraph>
                </Box>
              ))}
            </RadioGroup>
            <Box>
              <Paragraph>Plan type:</Paragraph>
              <SelectInput
                fullWidth
                size="small"
                value={medicareType}
                onChange={handleMedicareTypeChanged}
                options={MedicareTypeOptions}
                disableClearable
                showEmptyOption={false}
              />
            </Box>
            <Box display="flex" justifyContent="center" paddingTop="10px">
              {loading ? (
                <CircularProgress />
              ) : (
                <Button variant="contained" color="primary" style={{ color: 'white' }} onClick={onClickSearch} disabled={!county}>
                  Search
                </Button>
              )}
            </Box>
          </>
        )}

        {plans?.length > 0 && (
          <>
            <Divider />
            <Paragraph>
              <Bold>Results</Bold>
            </Paragraph>
            <Paragraph>
              {plans.length}
              {' '}
              plans available
            </Paragraph>
            <Paragraph>
              {plans.filter((plan) => plan.rating === '5 out of 5 stars').length}
              {' '}
              Five star plans
            </Paragraph>
            <Paragraph>
              {plans.filter((plan) => plan.snp !== null)?.length}
              {' '}
              snp plans
            </Paragraph>
            <Divider />
          </>
        )}

        {hasSearched && plans && filteredPlans?.length === 0 && (
          <p style={{ textAlign: 'center', color: 'red' }}>No available plans for the selected filter</p>
        )}

        {filteredPlans && filteredPlans.length > 0 && (
          <>
            <>
              <Box>
                <Paragraph>Filter by:</Paragraph>
                <VerticalCenterContainer>
                  <Checkbox
                    name="Five star rating"
                    onChange={handleFiveStarFilterChanged}
                    disabled={!plans.some((plan) => plan.rating === '5 out of 5 stars')}
                  />
                  Five star rating
                </VerticalCenterContainer>
              </Box>
              <Divider />
              {filteredPlans.map((plan) => (
                <>
                  <Bold>{plan?.carrierName}</Bold>
                  <Bold>
                    {plan?.name}
                    {' '}
                    (id:
                    {plan.contractId}
                    -
                    {plan.planId}
                    -
                    {plan.segmentId}
                    )
                  </Bold>
                  <RowContainer style={{ alignItems: 'center' }}>
                    <PlanStarIcon />
                    {' '}
                    <div>{plan?.rating}</div>
                  </RowContainer>
                  <Divider />
                </>
              ))}
            </>
          </>
        )}
      </AvailablePlansContainer>
    </SidePanelContentLayout>
  );
};
