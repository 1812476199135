import * as React from 'react';
import moment from 'moment';
import { InfoBlock } from '../ScriptBlock/InfoBlock';
import { useFormAnswers } from '../../../hooks';
import { useActiveLeadId } from '../../routers/LeadRouter/useActiveLeadId';
import { QuestionView } from '../FormGenerator/FormGeneratorQuestionFlow/Question/QuestionView';
import { QuestionType } from '@hiq/crm.types';

export const MedicareIepNote: React.FunctionComponent = () => {
  const leadId = useActiveLeadId();
  const [answers, onChange] = useFormAnswers(leadId);
  const dob = answers['customer.person.dateOfBirth'];
  const confirmed = answers['customer.person.dateOfBirthConfirmed'];
  const isAvailable = isInitialEnrollmentPeriodAvailable(dob);

  const onDoBChange = React.useCallback(
    (val) => onChange('customer.person.dateOfBirthConfirmed', val),
    [onChange],
  );

  if (!isAvailable) return <></>;

  return (
    <>
      <QuestionView
        id="customer.person.dateOfBirthConfirmed"
        questionText="Was DoB Confirmed?"
        label="DoB Confirmed"
        value={confirmed}
        onChange={onDoBChange}
        type={QuestionType.RadioYesNo}
      />
      { confirmed && (
        <InfoBlock>
          IEP MAY BE AVAILABLE (IEP - 7 months surrounding Part A and Part B Effective dates (3 months before, month of,
          and 3 months after)
        </InfoBlock>
      )}
    </>
  );
};

function isInitialEnrollmentPeriodAvailable(dobString: string): boolean {
  if (!dobString) return false;

  const dob = moment(dobString);
  if (!dob.isValid()) {
    return false;
  }

  const dobMonth = dob.month() + 1; // months are 0 indexed

  return isWithinNextThreeMonths(dobMonth) || isWithinLastThreeMonths(dobMonth);
}

function isWithinNextThreeMonths(dobMonth: number) {
  const now = moment();
  const currentMonth = now.month() + 1; // months are 0 indexed
  if (dobMonth === currentMonth) return true;

  let accumulator = currentMonth;
  for (let i = 0; i < 3; i++) {
    accumulator = getNextMonth(accumulator);
    if (accumulator === dobMonth) return true;
  }
  return false;
}

function isWithinLastThreeMonths(dobMonth: number): boolean {
  const now = moment();
  const currentMonth = now.month() + 1; // months are 0 indexed
  if (dobMonth === currentMonth) return true;

  let accumulator = currentMonth;
  for (let i = 0; i < 3; i++) {
    accumulator = getPreviousMonth(accumulator);
    if (accumulator === dobMonth) return true;
  }
  return false;
}

function getNextMonth(current: number) {
  return current === 12 ? 1 : current + 1;
}

function getPreviousMonth(current: number) {
  return current === 1 ? 12 : current - 1;
}
