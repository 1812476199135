import { useMemo } from 'react';
import { ProductTypeLife } from '@hiq/crm.types';
import { useLifeCarriersQuery } from '../../../../../../graphql/queries';

export function useLifeCarriers(type?: ProductTypeLife) {
  const { data, loading, error } = useLifeCarriersQuery(type);
  const carrierOptions = useMemo(() => (
    data?.getAllLifeCarriers
      ?.map((carrier) => ({
        label: carrier.name,
        value: carrier.id,
      }))
      ?.sort((a, b) => (a.label > b.label ? 1 : -1)) ?? []
  ), [JSON.stringify(data)]);

  return {
    loading,
    error,
    carrierOptions,
  };
}
