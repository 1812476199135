import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const QuoteCellLayoutContainer = styled('div')(css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`);

export const QuoteCellPinColumn = styled('div')(css`
  max-width: 43px;
`);

export const QuoteCellCarrierColumn = styled('div')(css`
  flex: 2;
  min-width: 175px;
`);

export const QuoteCellDetailColumn = styled('div')(css`
  flex: 3;
  min-width: 200px;
`);

export const QuoteCellPremiumColumn = styled('div')(css`
  flex: 2;
  min-width: 120px;
`);

export const QuoteCellSelectColumn = styled('div')(css`
  max-width: 160px;
`);
