import * as React from 'react';
import { BlockQuoteRendererProps } from './BlockQuoteRenderer.interfaces';
import { BlockQuote } from '../../../ScriptBlock';

export const BlockQuoteRenderer: React.FunctionComponent<BlockQuoteRendererProps> = ({
  children,
}) => (
  <BlockQuote>
    { children }
  </BlockQuote>
);
