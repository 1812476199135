import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { ComponentWithThemeProps } from '../../../../themes';

export const FileListTitle = styled('h4')(css`
  padding: 0 20px;
`);

export const FileListContainer = styled('div')(css`
  padding: 0;
  margin: 0;
  border-radius: 4px;
`);

export const FileListItemContainer = styled('div')<ComponentWithThemeProps>(({ theme }) => `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 100%;
  margin: 20px 0px 0px 0px;
  border: 1px solid ${theme.color.gray};
  border-radius: 5px;
`);

export const FileListItemButtons = styled('div')(css`
  margin: 0 10px;
`);

export const EmptyFileListItem = styled('li')(css`
  padding: 10px 20px;
`);

export const FileItemButtonContainer = styled('span')(css``);
