import * as React from 'react';
import { FullHeightProps } from './FullHeight.interfaces';
import {
  FullHeightContainer,
} from './FullHeight.elements';

export const FullHeight: React.FunctionComponent<FullHeightProps> = ({
  children,
}) => (
  <FullHeightContainer>
    { children }
  </FullHeightContainer>
);
