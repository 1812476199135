import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../themes';

export const MainPanelLayoutContainer = styled('div')(css`
  display: flex;
  flex-direction: column;
`);

export const TabColumnContainer = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  width: 260px;
  overflow-x: hidden;
  border-right: 1px solid ${theme.color.grayLight};
`);

export const ContentColumnContainer = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  flex: 1;
  background-color: ${theme.color.grayLighter};
  height: 100%;
`);

export const ContentContainer = styled('div')(css`
  min-height: 100%;
`);
