import React, {
  MutableRefObject, RefObject, useEffect, useRef,
} from 'react';
import { TRMQuoteProps } from './TRMQuote.interfaces';
import { PlanSelectionPlan } from '@hiq/crm.types';
import { CompareHeader } from './CompareHeader/CompareHeader';
import { CompareContext, CompareContextData } from './CompareContext/CompareContext';
import CompareGrid from './CompareGrid/CompareGrid';

import {
  Container,
  Column,
} from './TRMQuote.elements';
import { CompareOop } from './CompareOop';
import { CompareSupplementalBenefits } from './CompareSupplementalBenefits/CompareSupplementalBenefits';
import { useFormAnswers, useEmitEventCmhrAware } from '../../../../hooks';
import CompareDoctor from './CompareDoctor/CompareDoctor';

import { NoPlansError } from './NoPlansError/NoPlansError';
import { CompareServiceScore } from './CompareServiceScore';
import { useActiveLeadId } from '../../../routers';

const PLAN_TYPE_KEY = 'lead.sunfire.v2.medicareType';
const ZIP_CODE_KEY = 'customer.person.homeAddress.zip';
const EFFECTIVE_DATE_QUESTION_ID = 'lead.sunfire.v2.effectiveDate';

export function useStateRef<T>(value: T):[T, React.Dispatch<React.SetStateAction<T>>, RefObject<T>] {
  const ref: MutableRefObject<T> = useRef<T>(value);
  const [state, setState] = React.useState<T>(value);

  useEffect(() => {
    ref.current = state;
  }, [state]);

  return [state, setState, ref];
}

const HEADER_LIMIT = 350;

export const TRMQuote = ({
  plans, currentPlan, baseUrl, customerCode, countyCode, prospectId,
}: TRMQuoteProps) => {
  const containerRef = React.useRef(null);
  const [smallHeader, setSmallHeader, smallHeaderRef] = useStateRef(false);

  const handleScroll = React.useCallback(() => {
    if (smallHeaderRef.current && containerRef.current.scrollTop < HEADER_LIMIT) setSmallHeader(false);
    if (!smallHeaderRef.current && containerRef?.current.scrollTop > HEADER_LIMIT) setSmallHeader(true);
  }, [setSmallHeader]);

  React.useEffect(() => {
    if (containerRef && containerRef.current) {
      containerRef?.current.addEventListener('scroll', handleScroll);
    }
    return () => {
      containerRef?.current?.removeEventListener('scroll');
    };
  }, []);

  const scrollToTop = React.useCallback(() => {
    containerRef.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const leadId = useActiveLeadId();
  const [currentComparisonPlanIndex, setCurrentComparisonPlanIndex] = React.useState(0);
  const [currentComparisonPlan, setCurrentComparisonPlan] = React.useState<PlanSelectionPlan>();
  const [carrierFilter, setCarrierFilter] = React.useState<string[]>([]);

  const [ratingFilter, setRatingFilter] = React.useState<number[]>([]);
  const [answers, onChange, persist] = useFormAnswers(leadId, false);
  const planType = answers[PLAN_TYPE_KEY];
  const zipCode = answers[ZIP_CODE_KEY];
  const year = answers[EFFECTIVE_DATE_QUESTION_ID];
  const emitEvent = useEmitEventCmhrAware();

  const maxPlanRank = React.useMemo(
    () => {
      const maxPlansRank = plans?.reduce((max, plan) => (plan.hiqRank > max ? plan.hiqRank : max), 0);
      return Math.max(maxPlansRank, currentPlan?.hiqRank || 0);
    }, [plans, currentPlan],
  );

  const emitPlanComparisonEvent = React.useCallback((message, properties = {}) => {
    const currentPlanRank = currentPlan ? currentPlan?.hiqRank : 'No current plan';
    const currentComparisonPlanRank = currentComparisonPlan?.hiqRank;
    emitEvent(message, { currentPlanRank, currentComparisonPlanRank, ...properties });
  }, [currentComparisonPlanIndex, currentPlan, emitEvent]);

  const updateCarrierFilter = React.useCallback((carriers: Array<string>) => {
    emitPlanComparisonEvent('Filter by Carrier', carriers);
    setCurrentComparisonPlanIndex(0);
    setCarrierFilter(carriers);
  }, [emitPlanComparisonEvent]);

  const updateRatingFilter = React.useCallback((ratings: Array<number>) => {
    emitPlanComparisonEvent('Filter by Rating', ratings);
    setCurrentComparisonPlanIndex(0);
    setRatingFilter(ratings);
  }, [emitPlanComparisonEvent]);

  const resetFilters = React.useCallback(() => {
    setCurrentComparisonPlanIndex(0);
    setRatingFilter([]);
    setCarrierFilter([]);
  }, []);

  const carriers = React.useMemo(
    () => plans?.map((plan) => plan.carrierName).filter((carrier, index, array) => array.indexOf(carrier) === index),
    [plans],
  );

  const availablePlans = React.useMemo(
    () => {
      if (!carrierFilter.length && !ratingFilter.length) return plans;
      return plans
        ?.filter((plan) => !carrierFilter.length || carrierFilter.includes(plan.carrierName))
        .filter((plan) => !ratingFilter.length || ratingFilter.includes(plan.rating));
    }, [plans, carrierFilter, ratingFilter],
  );

  React.useEffect(() => {
    resetFilters();
  }, [planType, year, resetFilters]);

  React.useEffect(() => {
    if (plans?.length) setCurrentComparisonPlan(availablePlans[currentComparisonPlanIndex]);
  }, [currentComparisonPlanIndex, availablePlans]);

  const parsePlanType = React.useCallback((planType: string, planName: string) => {
    if (planName?.includes('C-SNP')) return 'MAPD';
    if (planType === 'SN') return 'SNP';
    return planType;
  }, []);

  const parsePlanUrlType = React.useCallback((planType: string, planName: string) => {
    if (planName?.includes('C-SNP')) return 'csnp';
    if (planType === 'SN') return 'SNP';
    return planType;
  }, []);

  const onEnroll = React.useCallback(() => {
    emitPlanComparisonEvent('On enroll',
      {
        providersCovered: currentComparisonPlan?.hiqProviderResults?.providersCovered,
        providersConsidered: currentComparisonPlan?.hiqProviderResults?.providersConsidered,
      });
    // eslint-disable-next-line
    const url = `${baseUrl}/app/blaze/portal/?cc=${customerCode}&pspt=${prospectId}#/form/${zipCode}/${countyCode}/${parsePlanType(planType, currentComparisonPlan?.name)}/${currentComparisonPlan.id}/${parsePlanUrlType(planType, currentComparisonPlan?.name)}-${currentComparisonPlan.carrierId}-blazees/1`;
    window.open(url, '_blank');
    onChange('lead.sunfire.enrolledPlan.name', currentComparisonPlan.name);
    onChange('lead.sunfire.enrolledPlan.carrierBenefitsUrl', currentComparisonPlan.summaryBenefitsUrl);
    onChange('lead.sunfire.enrolledPlan.carrierName', currentComparisonPlan.carrierName);
    onChange('lead.sunfire.enrolledPlan.hiqRank', currentComparisonPlan.hiqRank);
    persist();
  }, [baseUrl, zipCode, countyCode, planType, parsePlanType, currentComparisonPlan, emitPlanComparisonEvent, customerCode, prospectId]);

  const nextPlan = React.useCallback(() => {
    emitPlanComparisonEvent('Next Plan');
    setCurrentComparisonPlanIndex((index) => index + 1);
  }, [emitPlanComparisonEvent]);

  const previousPlan = React.useCallback(() => {
    emitPlanComparisonEvent('Previous Plan');
    setCurrentComparisonPlanIndex((index) => index - 1);
  }, [emitPlanComparisonEvent]);

  const compareContextValue: CompareContextData = {
    baseUrl,
    currentComparisonPlanIndex,
    currentComparisonPlan,
    totalPlans: maxPlanRank,
    totalRecommendedPlans: availablePlans?.length,
    currentPlan,
    nextPlan,
    previousPlan,
    planType,
    zipCode,
    countyCode,
    customerCode,
    emitPlanComparisonEvent,
    updateCarrierFilter,
    carrierFilter,
    carriers,
    ratingFilter,
    updateRatingFilter,
    resetFilters,
  };

  if (availablePlans?.length === 0) return <NoPlansError planType={planType} />;

  return (
    <CompareContext.Provider value={compareContextValue}>
      <Container ref={containerRef}>
        <Column width="80%" gridGap="0px">
          <CompareHeader onEnroll={onEnroll} smallHeader={smallHeader} scrollToTop={scrollToTop} />
          {availablePlans?.length > 0 && (
          <>
            <CompareDoctor />
            <CompareOop />
            {currentComparisonPlan?.hiqDiagnosesResults?.diagnoses.map(
              (diagnose) => <CompareGrid key={diagnose.hiqDiseaseKey} title={diagnose.name} featureKey={diagnose.hiqDiseaseKey} />,
            )}
            <CompareServiceScore />
            {currentComparisonPlan?.hiqSupplementalBenefitsResults?.benefits?.length > 0 && (<CompareSupplementalBenefits />)}
          </>
)}
        </Column>
      </Container>
    </CompareContext.Provider>
  );
};
