import { gql } from 'apollo-boost';
import {
  CreateLeadMutationResponse,
  CreateLeadMutationVariables,
} from './useCreateLeadMutation.interfaces';
import { MutationCustomReturn, useMutationCustom } from '../useMutationCustom';

const CREATE_LEAD_MUTATION = gql`
  mutation CreateLead($input: CreateLeadInput!) {
    createLead(input: $input) {
      code
      success
      message
      timestamp
      type
      customerId
      leadId
    }
  }
`;

export function useCreateLeadMutation(): MutationCustomReturn<
CreateLeadMutationResponse,
CreateLeadMutationVariables> {
  return useMutationCustom<
  CreateLeadMutationResponse,
  CreateLeadMutationVariables>(CREATE_LEAD_MUTATION);
}
