import * as React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { CommunicationMethod } from '@hiq/crm.types';
import { PrimaryButton } from '../inputs/PrimaryButton';
import { CustomerCommunicationProps } from './CustomerCommunication.interfaces';
import { ModalContentContainer } from './CustomerCommunication.elements';
import { useCustomerId, useActiveLeadId } from '../../routers';
import { useFormAnswers } from '../../../hooks';
import { useSendCommunication } from '../../../graphql';
import { CustomerCommunicationQuestions } from './CustomerCommunicationQuestions';
import { emitAnalyticsEvent } from '../../../events/emitAnalyticsEvent';

export const CustomerCommunication: React.FunctionComponent<CustomerCommunicationProps> = ({
  communicationTemplate,
  buttonText,
  buttonSize,
  title,
}) => {
  const customerId = useCustomerId();
  const leadId = useActiveLeadId();
  const trackingId = React.useMemo(() => `CustomerCommunication-${Date.now()}`, []);
  const [formAnswers, onChange] = useFormAnswers(leadId, true, trackingId);
  const [open, setModalOpen] = React.useState(false);
  const [sendCommunication, { data, loading, error }] = useSendCommunication();

  const handleSubmit = React.useCallback(async () => {
    const result = await sendCommunication({ customerId, options: { communicationTemplate } });
    if (result?.data?.sendCommunication?.success) {
      setTimeout(() => setModalOpen(false), 3000);
    }
  }, []);

  const answersPresent = !!(
    formAnswers['customer.person.contactPreference']
    && formAnswers['customer.person.name.first']
    && formAnswers['customer.person.name.last']
    && (
      formAnswers['customer.person.contactPreference'] === CommunicationMethod.SMS
        ? formAnswers['customer.person.phone.number']
        : formAnswers['customer.person.email']
    )
  );

  const handleClick = () => {
    setModalOpen(true);
    emitAnalyticsEvent('Button Click', { name: buttonText });
  };

  return (
    <>
      <PrimaryButton
        onClick={handleClick}
        variant="contained"
        disabled={open}
        size={buttonSize}
      >
        { buttonText }
      </PrimaryButton>
      <Dialog open={open} onClose={() => setModalOpen(false)} fullWidth>
        <ModalContentContainer>
          <DialogTitle id="form-dialog-title">
            { title }
          </DialogTitle>
          <DialogContent>
            <CustomerCommunicationQuestions
              leadId={leadId}
              formAnswers={formAnswers}
              onChange={onChange}
              data={data}
              includeCommunicationPreference
              error={error}
            />
          </DialogContent>

          <DialogActions>
            <PrimaryButton onClick={() => setModalOpen(false)} variant="outlined">
              Cancel
            </PrimaryButton>
            <PrimaryButton
              variant="contained"
              onClick={handleSubmit}
              disabled={!answersPresent || loading || data?.sendCommunication?.success}
            >
              { loading ? 'Sending...' : 'Send'}
            </PrimaryButton>
          </DialogActions>
        </ModalContentContainer>
      </Dialog>
    </>
  );
};
