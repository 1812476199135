import * as React from 'react';
import { isPresent } from '@healthiqeng/core.util.is-present';
import { QuoteDetailsProps } from './QuoteDetails.interfaces';
import {
  QuoteDetailsContainer,
  QuoteDetailsItem,
  QuoteDetailsLabel,
} from './QuoteDetails.elements';

export const QuoteDetails: React.FunctionComponent<QuoteDetailsProps> = ({
  items,
}) => (
  <QuoteDetailsContainer>
    { items.filter(({ value }) => isPresent(value)).map(({ label, value }) => (
      <QuoteDetailsItem key={label}>
        <QuoteDetailsLabel>{ `${label}:` }</QuoteDetailsLabel>
        { value }
      </QuoteDetailsItem>
    )) }
  </QuoteDetailsContainer>
);
