import * as React from 'react';
import { Helmet } from 'react-helmet';
import { IMetaProps } from './Meta.interfaces';

export function Meta(props: IMetaProps) {
  return (
    <Helmet>
      <title>{props.title}</title>
      <meta name="title" content={props.title} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      {props.noIndex && <meta name="robots" content="noindex" />}
      <meta name="description" content={props.description} />
      <meta name="keywords" content={props.keywords} />
      <meta name="author" content={props.author} />

      <meta property="og:title" content={props.ogTitle} />
      <meta property="og:site_name" content={props.ogSiteName} />
      <meta property="og:url" content={props.ogUrl} />
      <meta property="og:description" content={props.ogDescription} />
      <meta property="og:image" content={props.ogImage} />

      <meta name="msvalidate.01" content="B2A0A15B0F1F1CB67995C3E1BF43099A" />
      {props.mobileWebAppCapable && <meta name="mobile-web-app-capable" content="yes" />}
    </Helmet>
  );
}
