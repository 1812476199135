import { useCallback } from 'react';
import { hiqCrmService } from '../../../../services';
import { useActiveLeadId } from '../../../routers/LeadRouter/useActiveLeadId';

export const upload = async (url: string, file: File) => new Promise<void>((resolve, reject) => {
  const xhr = new XMLHttpRequest();
  xhr.open('PUT', url);
  xhr.setRequestHeader('Content-Type', file.type);
  xhr.onreadystatechange = () => {
    if (xhr.readyState === 4) {
      if (xhr.status === 200) {
        resolve();
      } else {
        reject(new Error(`Could not upload file. Status ${xhr.status}.`));
      }
    }
  };
  xhr.send(file);
});

export const useFileUpload = () => useCallback(async (file: File) => {
  const leadId = useActiveLeadId();
  const url = await hiqCrmService.createSignedFileUploadUrl(file.name, file.type, leadId);
  return upload(url, file);
}, []);
