import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { MostRecentAppointmentsData } from './useMostRecentAppointmentsQuery.interfaces';
import { APPOINTMENT_FRAGMENT } from '../../fragments';

const DIALER_UPDATE_INTERVAL_MS = 60000;

export const USER_APPOINTMENTS_QUERY = gql`
  query MostRecentUserAppointments {
    lastAgentAppointment {
      ...AppointmentFragment
    }
    nextAgentAppointment {
      ...AppointmentFragment
    }
    upcomingAgentAppointments {
      ...AppointmentFragment
    }
    missedAgentAppointments {
      ...AppointmentFragment
    }
  }
  ${APPOINTMENT_FRAGMENT}
`;

export function useMostRecentAppointmentsQuery() {
  return useQuery<MostRecentAppointmentsData>(
    USER_APPOINTMENTS_QUERY, {
      pollInterval: DIALER_UPDATE_INTERVAL_MS,
    },
  );
}
