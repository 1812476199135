import { gql } from 'apollo-boost';
import { MutationHookOptions } from '@apollo/react-hooks';
import { useMutationCustom } from '../useMutationCustom';
import {
  SendSmsMutationResponse,
  SendSmsMutationVariables,
} from './useSendSmsMutation.interfaces';

const SEND_SMS_MUTATION = gql`
  mutation SendSms($body: String!, $leadId: ID!, $userId: ID!) {
    sendSms(body: $body, leadId: $leadId, userId: $userId) {
      code
      success
      message
      timestamp
    }
  }
`;

export function useSendSms(options: MutationHookOptions<SendSmsMutationResponse, SendSmsMutationVariables> = {}) {
  return useMutationCustom<SendSmsMutationResponse, SendSmsMutationVariables>(SEND_SMS_MUTATION, options);
}
