import { CarrierTextLink, RedirectIcon, Row } from './CheckMyHealthRecordVerifyUserCarrier.elements';
import React from 'react';
import { CarrierLinkProps } from './CheckMyHealthRecordVerifyUserCarrier.interfaces';

export const CarrierLink = ({ url, name }: CarrierLinkProps) => {
  if (!url) return <div />;
  return (
    <Row>
      <CarrierTextLink href={url} target="_blank">
        {name}
      </CarrierTextLink>
      <RedirectIcon />
    </Row>
  );
};
