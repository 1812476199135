import React from 'react';
import { useToggle } from '../useToggle';

export function useDeleteRows<D>(
  getRowId: (data: Partial<D>) => string,
): [string[], (data: Partial<D> | D) => void, boolean, () => void] {
  const [selectedRows, setSelectedRows] = React.useState<Partial<D>[]>([]);
  const [isDeleting, toggle] = useToggle(false);

  const selectedRowIds: string[] = React.useMemo(
    () => selectedRows.map((row: D) => getRowId(row as D)),
    [selectedRows, getRowId],
  );

  const onSelectionChange = React.useCallback((data: Partial<D>) => {
    const rowId = getRowId(data);
    setSelectedRows((rows) => {
      const idx = rows.findIndex((selected) => getRowId(selected) === rowId);
      if (idx === -1) {
        rows.push(data);
      } else {
        rows.splice(idx, 1);
      }
      return [...rows];
    });
  }, [setSelectedRows, getRowId]);
  return [selectedRowIds, onSelectionChange, isDeleting, toggle];
}
