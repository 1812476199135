import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../../themes';

export const NavBarSearchInputContainer = styled('div')<ComponentWithThemeProps>(css`
  position: relative;
  background-color: white;
  border-radius: 2px;
  width: 400px;
`);

export const NavBarSearchResultsList = styled('ul')(css`
  position: absolute;
  top: calc(100% + 3px);
  left: 0;
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: white;
  border: 1px solid #eee;
  border-radius: 2px;
  width: 400px;
  z-index: 2;
  box-shadow: 0 8px 6px -6px black;
`);

export const NavBarSearchResultsListItem = styled('li')(css`
  padding: 10px;
  border-bottom: 1px solid #eee;
  width: 100%;
  
  &:hover {
    background: #eee;
  }
`);

export const NavBarSearchResultsListItemRow = styled('p')(css`
  margin: 0;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`);

export const NavBarSearchEmptyMessage = styled('li')(css`
  padding: 10px;
  text-align: center;
  color: #333;
`);

export const NavbarSearchTextInputContainer = styled('div')(css`
  display: inline-block;
  width: 100%;
`);

export const NavbarSearchLoaderContainer = styled('div')(css`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(calc(-50% + 3px));
`);
