import * as React from 'react';
import { OAuthRequiredProps } from './OAuthRequired.interfaces';
import {
  OAuthRequiredContainer,
} from './OAuthRequired.elements';
import { ListOAuthAppsResponseItem } from '@hiq/crm.types';
import { useEffect } from 'react';
import { hiqCrmService } from '../../../services';
import { FormSkeleton } from '../FormSkeleton';

export const OAuthRequired: React.FunctionComponent<OAuthRequiredProps> = ({
  appId,
}) => {
  const [app, setApp] = React.useState<ListOAuthAppsResponseItem>(null);
  useEffect(() => {
    hiqCrmService
      .getOAuthApp(appId)
      .then((appResult) => setApp(appResult));
  }, []);
  return (
    <OAuthRequiredContainer>
      { !app && <FormSkeleton /> }
      { app && (
        <>
          <p>
            To use this widget, you must authorize
            {' '}
            { app.label }
            . Click the &quot;Authorize&quot; link below.
          </p>
          <div>
            <a rel="noreferrer noopener" href={hiqCrmService.getOAuthAppUrl(app)}>
              Authorize
              {' '}
              { app.label }
            </a>
          </div>
        </>
      ) }
    </OAuthRequiredContainer>
  );
};
