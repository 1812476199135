import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { logger } from '../../../services';
import { VariationChangeEvent, useEventListener } from '../../../events';
import { CanSubmitEappQueryData, CanSubmitEappQueryVariables } from './useCanSubmitEappQuery.interfaces';

const CAN_SUBMIT_EAPP_QUERY = gql`
    query CanSubmitEapp($leadId: ID!) {
      canSubmit(leadId: $leadId) {
        canSubmit
      }
    }
`;

export function useCanSubmitEappQuery(leadId: string) {
  const {
    loading,
    error,
    data,
    refetch,
  } = useQuery<CanSubmitEappQueryData, CanSubmitEappQueryVariables>(CAN_SUBMIT_EAPP_QUERY, {
    variables: { leadId },
  });

  useEventListener(VariationChangeEvent, () => {
    refetch().catch((err) => logger.crit(err));
  });

  return {
    loading, data, error, refetch,
  };
}
