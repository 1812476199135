import * as React from 'react';
import { useInitialContactRedirect, useUrlCallData } from './useInitialContactRedirect';
import { RouteComponentProps } from '@reach/router';
import { FunctionComponent } from 'react';
import { DialerTransferContext } from '../../../contexts';
import { Button, Container } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

interface CustomerInitialContactRedirectProps extends RouteComponentProps {
  content: FunctionComponent<any>;
}

export const CustomerInitialContactRedirect: React.FunctionComponent<CustomerInitialContactRedirectProps> = ({
  content: InitialContactViewComponent,
}) => {
  const callData = useUrlCallData();
  const { error, customerError } = useInitialContactRedirect(callData);
  const { setCampaignId } = React.useContext(DialerTransferContext);
  const customerName = parseCustomerName(callData?.customerFirstName, callData?.customerLastName);

  React.useEffect(() => {
    if (callData?.campaignId) setCampaignId(callData.campaignId);
  }, [callData?.campaignId]);

  if (error && customerError) {
    return (
      <div>
        <Container style={{ padding: 10 }}>
          <h1 style={{ fontSize: 70, textAlign: 'center' }}>OOPS !!</h1>
          <Alert severity="error" variant="filled">
            <AlertTitle>There was an error loading TRM. Please contact IT</AlertTitle>
            { error?.message }
          </Alert>
          <div style={{ marginTop: 10, textAlign: 'center' }}>
            <Button variant="contained" color="primary" onClick={() => { window.location.reload(); }}>Refresh Page</Button>
          </div>
        </Container>

      </div>
    );
  }
  // Initially render read-only InitialContact script for SDRs to view client details fast
  return (
    <InitialContactViewComponent
      customerPhoneNumber={callData?.customerPhoneNumber}
      customerName={customerName}
      customerState={callData?.customerState}
      campaignName={callData?.campaignName}
      callSkill={callData?.callSkill}
      domain={callData?.domain}
    />
  );
};

function parseCustomerName(firstName: string, lastName: string): string {
  return [firstName, lastName]
    .map((str) => str?.replace(/(\W|_)+/g, '')?.trim())
    .filter((str) => str)
    .join(' ');
}
