import * as React from 'react';
import {
  Card,
  PNCBaseExternalQuote,
  PrimaryButton,
  useConfirmedFinalQuote,
} from '../../../../../../common';
import { useSelectedQuoteDetector } from '../../../../useSelectedQuoteDetector';
import { PropertyAndCasualtySelectedQuotes } from '../PropertyAndCasualtySelectedQuotes';
import { PropertyAndCasualtyQuoter } from '../common';
import { usePropertyAndCasualtyHomeQuoteLazyQuery } from '../../../../../../../graphql';
import { ProductTypePropertyAndCasualty } from '@hiq/crm.types';
import { HomeQuotePanelProps } from './HomeQuotePanel.interfaces';
import { PropertyAndCasualtyFinalQuote } from '../common/PropertyAndCasualtyFinalQuote';

export const HomeQuotePanel: React.FunctionComponent<HomeQuotePanelProps> = ({
  productType,
}) => {
  const [getQuotes, { loading, data, error }] = usePropertyAndCasualtyHomeQuoteLazyQuery();
  const hasSelectedQuote = useSelectedQuoteDetector();
  const [useExternal, setUseExternal] = React.useState(false);
  const [confirmedFinalQuote] = useConfirmedFinalQuote();

  if (hasSelectedQuote) {
    return (
      <>
        <PropertyAndCasualtySelectedQuotes />
        { !confirmedFinalQuote && (
          <PropertyAndCasualtyFinalQuote
            productType={productType ?? ProductTypePropertyAndCasualty.Home}
          />
        ) }
      </>
    );
  }

  return (
    <>
      <PrimaryButton onClick={() => setUseExternal(!useExternal)}>
        { useExternal ? 'Use EZLynx Quotes' : 'Use External Quote' }
      </PrimaryButton>
      { useExternal && (
        <Card>
          <PNCBaseExternalQuote
            productType={productType ?? ProductTypePropertyAndCasualty.Home}
          />
        </Card>
      ) }
      { !useExternal && (
        <PropertyAndCasualtyQuoter
          getQuotes={getQuotes}
          quoteResults={data?.getPropertyAndCasualtyHomeQuotes?.quoteResults}
          quoteErrors={data?.getPropertyAndCasualtyHomeQuotes?.errors}
          loading={loading}
          error={error}
        />
      ) }
    </>
  );
};
