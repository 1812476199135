import React from 'react';

export interface CollapseContextData {
  collapseAll: boolean,
  toggle: () => void;
}

export const CollapseColumnsContext = React.createContext<CollapseContextData>({
  collapseAll: false,
  toggle: () => {},
});
