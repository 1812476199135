import React from 'react';
import { QueryLoader } from '../../common';
import {
  DiagnosesBody,
  DiagnosesContainer,
  DiagnosesTitle,
  DiagnosisHeader,
  NonIcd,
} from './Diagnoses.elements';
import { useDiseaseBenefitsQuery, useSelectedICDsQuery, useSelectedNonICDsQuery } from '../../../graphql';
import { DiseaseItem } from './Diagoses.interfaces';
import { DiagnosisItem } from './DiagnosisItem';
import { useLeadIdRoute } from '../../routers';

export const Diagnoses = () => {
  const [leadId] = useLeadIdRoute();
  const selectedIcd = useSelectedICDsQuery(leadId)?.data?.selectedICDs ?? [];
  const selectedNonIcd = useSelectedNonICDsQuery(leadId)?.data?.selectedNonICDs ?? [];
  const [fetchDiseaseBenefits, { data, loading, error }] = useDiseaseBenefitsQuery();

  React.useEffect(() => {
    if (selectedIcd.length) {
      fetchDiseaseBenefits({
        variables: {
          diseaseKeys: selectedIcd.map((icd) => ({ date: icd.diagnosisDate, diseaseKey: icd.diseaseKey })),
        },
      });
    }
  }, [selectedIcd]);

  const diseases = React.useMemo(() => {
    if (!data?.getDiseaseBenefits) return {};
    return data.getDiseaseBenefits.reduce((acc, { diseaseKey, benefits }) => {
      const disease = selectedIcd.find((current) => current.diseaseKey === diseaseKey);
      return {
        ...acc,
        [diseaseKey]: {
          description: disease.description,
          benefits,
        },
      };
    }, {} as { [key: string]: DiseaseItem });
  }, [selectedIcd, data?.getDiseaseBenefits]);

  return (
    <QueryLoader loading={loading} error={error} componentName="Diagnoses">
      <DiagnosesContainer>
        <DiagnosesTitle>Diagnoses-Specific Plan Features to look for</DiagnosesTitle>
        <DiagnosesBody>
          {Object.entries(diseases).map(([key, disease]) => (
            <DiagnosisItem diseaseKey={key} description={disease.description} benefits={disease.benefits} />
          ))}
        </DiagnosesBody>
        {selectedNonIcd.length ? (
          <DiagnosisHeader>Diagnoses not yet supported by Precision Medicare AI:</DiagnosisHeader>
        ) : null}
        {selectedNonIcd.map((icd) => (
          <NonIcd>{icd.description}</NonIcd>
        ))}
      </DiagnosesContainer>
    </QueryLoader>
  );
};
