import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { ProductTypeLife } from '@hiq/crm.types';
import { LifeCarriersQueryData } from './useLifeCarriersQuery.interfaces';

const LIFE_CARRIERS_QUERY = gql`
  query getAllLifeCarriers($productType: ProductTypeLife) {
    getAllLifeCarriers(productType: $productType) {
      id
      name
    }
  }
`;

export function useLifeCarriersQuery(productType?: ProductTypeLife) {
  return useQuery<LifeCarriersQueryData>(
    LIFE_CARRIERS_QUERY,
    { variables: { productType } },
  );
}
