import { useApolloClient, useQuery, useLazyQuery } from '@apollo/react-hooks';
import { gql, ApolloError } from 'apollo-boost';
import { QUESTION_FRAGMENT, QuestionFragment } from '../../fragments';
import { QuestionOptionsQueryData, QuestionQueryData, QuestionQueryVariables } from './useQuestionQuery.interfaces';
import { getQuestionDataId } from '../../../util';

const QUESTION_QUERY = gql`
  query getQuestion($leadId: ID!, $id: String!) {
    question(leadId: $leadId, id: $id) {
      ...QuestionFragment
    }
  }
  ${QUESTION_FRAGMENT}
`;

const QUESTION_OPTIONS_QUERY = gql`
  query getQuestionOptions($leadId: ID!, $id: String!) {
    questionOptions(leadId: $leadId, id: $id) {
      label
      value
    }
  }
`;

export function useQuestionQuery(leadId: string, id: string): QuestionQueryReturn {
  const dataId = getQuestionDataId({ leadId, id });
  const apolloClient = useApolloClient();
  const question = apolloClient.readFragment<QuestionQueryData['question']>({
    id: dataId,
    fragment: QUESTION_FRAGMENT,
  });

  const {
    loading, error, data,
  } = useQuery<QuestionQueryData, QuestionQueryVariables>(QUESTION_QUERY, {
    variables: {
      leadId,
      id,
    },
    fetchPolicy: question ? 'cache-only' : 'cache-first',
    skip: !id,
  });

  if (question) {
    return {
      loading: false,
      error: null as ApolloError,
      data: question,
    };
  }

  return {
    loading,
    error,
    data: data?.question,
  };
}

export function useQuestionOptionsLazyQuery(leadId: string, id: string) {
  return useLazyQuery<QuestionOptionsQueryData, QuestionQueryVariables>(QUESTION_OPTIONS_QUERY, {
    variables: {
      leadId,
      id,
    },
    fetchPolicy: 'network-only',
  });
}

interface QuestionQueryReturn {
  loading: boolean;
  error: ApolloError,
  data: QuestionFragment;
}
