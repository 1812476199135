import * as React from 'react';
import { ProductTypePropertyAndCasualty, ProductTypeMedicare, ProductTypeLife } from '@hiq/crm.types';
import { ExternalQuoteFactoryProps } from './ExternalQuoteFactory.interfaces';
import { MedicareSupplementExternalQuote } from './MedicareSupplementExternalQuote';
import { MedicareAdvantageExternalQuote } from './MedicareAdvantageExternalQuote';
import { MedicarePrescriptionDrugExternalQuote } from './MedicarePrescriptionDrugExternalQuote';
import { MedicareVisionExternalQuote } from './MedicareVisionExternalQuote';
import { MedicareDentalExternalQuote } from './MedicareDentalExternalQuote';
import { MedicareHospitalIndemnityExternalQuote } from './MedicareHospitalIndemnityExternalQuote';
import { PNCBaseExternalQuote } from './PNCBaseExternalQuote';
import { LifeExternalQuote } from './LifeExternalQuote';

export const ExternalQuoteFactory: React.FunctionComponent<ExternalQuoteFactoryProps> = ({
  type,
}) => {
  if (!type) {
    return (
      <>
        Must select Product Type before adding an external quote
      </>
    );
  }

  switch (type) {
    case ProductTypeMedicare.MedicareSupplement:
      return <MedicareSupplementExternalQuote />;
    case ProductTypeMedicare.MedicareAdvantage:
      return <MedicareAdvantageExternalQuote />;
    case ProductTypeMedicare.PrescriptionDrug:
      return <MedicarePrescriptionDrugExternalQuote />;
    case ProductTypeMedicare.Vision:
      return <MedicareVisionExternalQuote />;
    case ProductTypeMedicare.Dental:
      return <MedicareDentalExternalQuote />;
    case ProductTypeMedicare.HospitalIndemnity:
      return <MedicareHospitalIndemnityExternalQuote />;
    case ProductTypePropertyAndCasualty.Auto:
    case ProductTypePropertyAndCasualty.Home:
    case ProductTypePropertyAndCasualty.Renters:
    case ProductTypePropertyAndCasualty.Motorcycle:
    case ProductTypePropertyAndCasualty.Umbrella:
    case ProductTypePropertyAndCasualty.Watercraft:
    case ProductTypePropertyAndCasualty.Condo:
    case ProductTypePropertyAndCasualty.Powersports:
    case ProductTypePropertyAndCasualty.Landlord:
    case ProductTypePropertyAndCasualty.DwellingFire:
    case ProductTypePropertyAndCasualty.Earthquake:
    case ProductTypePropertyAndCasualty.Flood:
    case ProductTypePropertyAndCasualty.MobileHome:
    case ProductTypePropertyAndCasualty.InlandMarine:
    case ProductTypePropertyAndCasualty.OffRoad:
    case ProductTypePropertyAndCasualty.MotorhomeRV:
      return <PNCBaseExternalQuote productType={type as ProductTypePropertyAndCasualty} />;
    case ProductTypeLife.Accidental:
    case ProductTypeLife.Juvenile:
    case ProductTypeLife.WholeLife:
    case ProductTypeLife.CriticalIllness:
      return <LifeExternalQuote type={type as ProductTypeLife} />;
    default:
      return (
        <>
          { `External quote not implemented for product type ${type}` }
        </>
      );
  }
};
