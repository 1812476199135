import React from 'react';
import { CompareContext } from '../CompareContext/CompareContext';
import { useToggle } from '../../../CheckMyHealthRecord/CheckMyHealthRecordDataGrid/useToggle';
import {
  CompareContainer, CompareData, CompareLinkTitle, CompareTitleContainer, CompareDataBold, CompareTitleRow,
} from '../TRMQuote.elements';

import { CompareScore } from './CompareScore';
import { CompareDropdown } from '../CompareDropdown';

export const CompareSupplementalBenefits = () => {
  const {
    currentPlan, currentComparisonPlan, emitPlanComparisonEvent,
  } = React.useContext(CompareContext);

  const onToggle = React.useCallback(() => emitPlanComparisonEvent('Opened Supplemental Benefits'),
    [emitPlanComparisonEvent]);

  const [open, toggleSection] = useToggle(true, onToggle);

  return (
    <CompareContainer>
      <CompareTitleRow>
        <CompareData hidePadding>
          <CompareTitleContainer onClick={toggleSection}>
            <CompareDropdown open={open} />
            <CompareLinkTitle>Supplemental Benefits</CompareLinkTitle>
          </CompareTitleContainer>
        </CompareData>
        <CompareDataBold empty={!currentPlan}>
          {currentPlan ? `${currentPlan?.hiqSupplementalBenefitsResults.coveredCount} of 
          ${currentPlan?.hiqSupplementalBenefitsResults.benefits.length} included` : '' }
        </CompareDataBold>
        <CompareDataBold>
          {`${currentComparisonPlan?.hiqSupplementalBenefitsResults.coveredCount} of 
          ${currentComparisonPlan?.hiqSupplementalBenefitsResults.benefits.length} included`}
        </CompareDataBold>
      </CompareTitleRow>

      {open && currentComparisonPlan?.hiqSupplementalBenefitsResults?.benefits.map(
        (benefit, index) => (
          <CompareScore
            benefitName={benefit.benefit}
            comparisonScore={benefit.score}
            currentValue={currentPlan?.hiqSupplementalBenefitsResults?.benefits?.[index]?.detail}
            currentScore={currentPlan?.hiqSupplementalBenefitsResults?.benefits?.[index]?.score}
            comparisonValue={benefit.detail}
          />
        ),
      )}
    </CompareContainer>
  );
};
