import * as React from 'react';
import { isPresent } from '@healthiqeng/core.util.is-present';
import { QuotePresentationType } from '@hiq/crm.types';
import { formatPercent } from '@healthiqeng/core.util.format-percent';
import { formatDate } from '@healthiqeng/core.util.format-date';
import { formatCurrencyTruncated } from '@healthiqeng/core.util.format-currency-truncated';
import { MedicareSupplementQuoteCellProps } from './MedicareSupplementQuoteCell.interfaces';
import { QuoteCellLayout } from '../QuoteCellLayout';
import { QuoteCarrierInfo } from '../QuoteCarrierInfo';
import { QuoteDetails } from '../QuoteDetails';
import { QuoteRates } from '../QuoteRates';
import { QuoteSelectButton } from '../QuoteSelectButton';
import { QueryLoader } from '../../../QueryLoader';
import { useQuoteCell } from '../useQuoteCell';
import { PrimaryButton } from '../../../inputs/PrimaryButton';
import { QuotePin } from '../QuotePin';

export const MedicareSupplementQuoteCell: React.FunctionComponent<MedicareSupplementQuoteCellProps> = ({
  hash,
  onPin,
  isPinned,
}) => {
  const {
    quote,
    handleSelectClick,
    isSelected,
    loading,
    error,
  } = useQuoteCell(hash);
  return (
    <QueryLoader loading={loading} error={error} componentName="MedicareSupplementQuoteCell">
      <QuoteCellLayout
        pinColumn={onPin && <QuotePin onPin={onPin} isPinned={isPinned} />}
        carrierColumn={(
          <QuoteCarrierInfo
            parentCompanyName={quote?.product?.carrier?.name}
            companyName={quote?.product?.carrierSubsidiary?.name}
            amBestRating={quote?.product?.carrierSubsidiary?.details?.amBestRating}
            spRating={quote?.product?.carrierSubsidiary?.details?.spRating}
            yearsInMarket={quote?.product?.carrierSubsidiary?.details?.yearsInMarket}
          />
        )}
        detailColumn={(
          <QuoteDetails
            items={[
              { label: 'Plan', value: quote?.plan },
              { label: 'Rate Type', value: quote?.rateType },
              { label: 'Effective Date', value: formatDate(quote?.effectiveDate) },
              { label: 'Rating Class', value: isPresent(quote?.ratingClass) ? quote?.ratingClass : 'Standard' },
              { label: 'HH Discount', value: formatPercent(quote?.householdDiscount) },
              { label: 'Policy Fee', value: formatCurrencyTruncated(quote?.premium?.policyFee?.value, false, false) },
              { label: 'Plan Type', value: quote?.isSelectPlan ? 'Select Plan' : null },
            ]}
          />
        )}
        premiumColumn={(
          <QuoteRates
            rateMonthly={quote?.premium?.monthly?.value}
            rateAnnually={quote?.premium?.annually?.value}
          />
        )}
        selectColumn={(
          <>
            <QuoteSelectButton
              allowSelect={quote?.presentationType !== QuotePresentationType.Competition}
              isSelected={isSelected}
              onClick={handleSelectClick}
            />
            { quote?.eAppLink && (
              <div style={{ marginTop: '10px' }}>
                <PrimaryButton
                  onClick={() => window.open(quote?.eAppLink, '_blank')}
                  variant="outlined"
                  fullWidth
                >
                  E-App
                </PrimaryButton>
              </div>
            ) }
          </>
        )}
      />
    </QueryLoader>
  );
};
