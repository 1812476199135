import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const ScratchPadInput = styled('textarea')(css`
  height: 100%;
  border: 0;
  resize: none;
  outline: none;
  padding: 5px;
  font: inherit;
`);
