import * as React from 'react';
import { useLifeQuoteLazyQuery } from '../../../../../../../graphql';
import { useActiveLeadId } from '../../../../../../routers';
import {
  Card, QueryLoader, LifeQuoteOptions, QuoteSelector,
} from '../../../../../../common';
import { LifeQuoterProps } from './LifeQuoter.interfaces';
import { SpecialRatePrompt } from './SpecialRatePrompt';

export const LifeQuoter: React.FunctionComponent<LifeQuoterProps> = ({
  defaultProductType,
}) => {
  const [getQuotes, { loading, error, data }] = useLifeQuoteLazyQuery();
  const leadId = useActiveLeadId();
  const quoteResults = data?.lifeQuote?.quoteResults;
  const hasSpecialRateProduct = quoteResults?.some((result) => result?.quote?.product?.isSpecialRate);
  return (
    <>
      <Card>
        <LifeQuoteOptions
          onQuote={(inputs) => getQuotes({ variables: { leadId, inputs } })}
          isFetching={loading}
          defaultProductType={defaultProductType}
        />
      </Card>
      <QueryLoader loading={loading} error={error} componentName="LifeQuoter">
        <QuoteSelector
          quoteResults={quoteResults}
          isHighlight={(quote) => quote?.product?.isSpecialRate}
          showSendEmailButton
          preQuotesComponent={hasSpecialRateProduct ? <SpecialRatePrompt /> : undefined}
        />
        { (quoteResults && !quoteResults.length) && (
          <Card
            key="no-quotes"
            margin="20px 0"
            padding="20px"
          >
            No quotes available
          </Card>
        ) }
      </QueryLoader>
    </>
  );
};
