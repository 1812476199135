import * as React from 'react';
import { QuestionType } from '@hiq/crm.types';
import { QuestionView } from '../../FormGenerator/FormGeneratorQuestionFlow/Question/QuestionView';
import { useFormAnswers } from '../../../../hooks';
import { useActiveLeadId, useCustomerId } from '../../../routers';
import { useVerifyCmhrUserMutationByMarx } from '../../../../graphql/mutations/useVerifyCmhrUserMutation';
import { useDebounce } from '@healthiqeng/core.hooks.use-debounce';
import { HealthRecordErrorMessage } from '../CheckMyHealthRecordDataGrid/HealthRecordErrorMessage';
import { emitAnalyticsEventNoLimit } from '../../../../events/emitAnalyticsEvent';

export const CheckMyHealthRecordVerifyUserMarxNewScript: React.FunctionComponent<{}> = () => {
  const customerId = useCustomerId();
  const leadId = useActiveLeadId();
  const trackingId = React.useMemo(() => `CheckMyHealthRecordVerifyUserMarxNewScript-${Date.now()}`, []);
  const [answers, onChange] = useFormAnswers(leadId, true, trackingId);
  const checkMyHealthRecordCustomerMarxVerified = 'lead.medicareABMarxLookupVerify';

  const [
    verifyChmrUserByMarxMutation,
    { data: mutationData },
  ] = useVerifyCmhrUserMutationByMarx({ variables: { customerId }, refetchQueries: ['GetCustomerHealthRecordStatusQuery'] });
  const { success } = mutationData?.verifyCmhrUserByMarx ?? {};

  const handleVerifyUser = React.useCallback(() => {
    verifyChmrUserByMarxMutation({ variables: { customerId } });
  }, [verifyChmrUserByMarxMutation, customerId]);

  const debouncedCommunicationSend = useDebounce(handleVerifyUser, 500);

  const handleMarxVerificationChanged = React.useCallback(async (marxVerified: boolean) => {
    if (marxVerified !== answers[checkMyHealthRecordCustomerMarxVerified]) {
      onChange(checkMyHealthRecordCustomerMarxVerified, marxVerified);
      emitAnalyticsEventNoLimit('MARx Verified', { customerId, leadId, marxVerified });
      if (marxVerified === true) {
        debouncedCommunicationSend();
      }
    }
  }, [
    answers[checkMyHealthRecordCustomerMarxVerified],
    onChange,
    emitAnalyticsEventNoLimit,
    debouncedCommunicationSend,
  ]);

  return (
    <>
      <QuestionView
        id={checkMyHealthRecordCustomerMarxVerified}
        leadId={leadId}
        onChange={handleMarxVerificationChanged}
        value={answers[checkMyHealthRecordCustomerMarxVerified]}
        questionText={'```directional-copy\n Has the client been verified in MARx?\n```'}
        label="CMHR Digital Product Customer Marx Verification New script"
        type={QuestionType.RadioYesNo}
      />
      {success === false && (
        <HealthRecordErrorMessage
          message="Unexpected error. Failed to verify user."
        />
      )}
      {success === true && (
        <HealthRecordErrorMessage severity="info" message="User Verified" />
      )}
    </>
  );
};
