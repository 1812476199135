import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const PropertyAndCasualtyQuoteOptionsContainer = styled('div')(css``);

export const ActionsWrapper = styled('div')(css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  > button {
    margin-top: 10px;
  }
`);
