import moment from 'moment';
import React from 'react';
import { DatagridCustomColumn } from '../../../Datagrid';
import { HealthRecordDoctor } from '@hiq/crm.types';
import {
  Bold, Green, HealthRecordCell, Name, Paragraph,
} from '../CheckMyHealthRecordDataGrid.elements';
import { WarningMessage } from '../useColumnsConfig/useColumnsConfig.elements';
import { UseDoctorColumnsConfigProps } from './useDoctorColumnConfig.interfaces';
import { DoctorSelectionColumn } from './DoctorSelectionColumn';
import { DoctorRemovalSelectionColumn } from './DoctorRemovalSelectionColumn';
import { memoize } from '../memoizeRenderer';

export const useDoctorColumnConfig = ({
  cellWidth,
  isReducedViewport,
  onSelect,
  onCriticalSelect,
  isRemoving,
  disabled,
  hideCritical,
  hideInclude,
}: UseDoctorColumnsConfigProps): DatagridCustomColumn<HealthRecordDoctor>[] => {
  const doctorsColumns = React.useMemo<DatagridCustomColumn<HealthRecordDoctor>[]>(() => {
    if (isReducedViewport) {
      return [
        {
          id: '1',
          renderer: memoize((({
            row: {
              original: {
                name, distanceInMiles, practice, lastVisit, includedPlanCount,
              },
            },
          }) => (
            <HealthRecordCell>
              <Name smaller>{name}</Name>
              <Paragraph>
                Specialty:&nbsp;
                <Bold>{practice || 'N/A'}</Bold>
              </Paragraph>
              {lastVisit
                ? (
                  <Paragraph>
                    Last visit:&nbsp;
                    <Bold>{moment(lastVisit).format('M/D/yyyy')}</Bold>
                  </Paragraph>
                ) : null}
              {distanceInMiles > 100
                ? <WarningMessage severity="warning">Distance is greater than 100 mi</WarningMessage>
                : null}
              {includedPlanCount ? (
                <Paragraph>
                  included in
                  {' '}
                  <Green>
                    {includedPlanCount}
                    {' '}
                    plans
                  </Green>
                </Paragraph>
              ) : null}
            </HealthRecordCell>
          )), 'name', 'distanceInMiles', 'practice', 'lastVisit', 'includedPlanCount'),
          width: cellWidth,
        },
        {
          id: '2',
          renderer: memoize((({
            row: {
              original: {
                visitsLastYear, addressLine1, addressLine2, city, state, zip, visitsLast3Years, visitsLast5Years,
              },
            },
          }) => (
            <HealthRecordCell smaller>
              {visitsLastYear
                ? (
                  <>
                    <Paragraph>
                      <Bold>{visitsLastYear || 'N/A'}</Bold>
                      {' '}
                      visits in the last 12 months
                    </Paragraph>
                    <Paragraph>
                      <Bold>{visitsLast3Years || 'N/A'}</Bold>
                      {' '}
                      visits in the last 3 years
                    </Paragraph>

                    <Paragraph>
                      <Bold>{visitsLast5Years || 'N/A'}</Bold>
                      {' '}
                      visits in the last 5 years
                    </Paragraph>
                  </>
                ) : null}
              <Paragraph>{[addressLine1, addressLine2, city, state, zip].filter((str) => str).join(', ')}</Paragraph>
            </HealthRecordCell>
          )), 'visitsLastYear', 'addressLine1', 'addressLine2', 'city', 'state', 'zip', 'visitsLast3Years',
          'visitsLast5Years'),
          width: cellWidth + 40,
        },
        {
          id: '3',
          renderer: (renderer) => {
            if (hideInclude) return null;
            if (renderer?.row?.original?.unsupported) return null;
            if (isRemoving) return <DoctorRemovalSelectionColumn {...renderer} onSelect={onSelect} onCriticalSelect={onCriticalSelect} />;
            return <DoctorSelectionColumn {...renderer} onSelect={onSelect} onCriticalSelect={onCriticalSelect} />;
          },
          width: cellWidth,
        },
      ];
    }
    return [
      {
        id: '1',
        renderer: memoize((({
          row: {
            original: {
              name, practice, distanceInMiles, lastVisit, includedPlanCount,
            },
          },
        }) => (
          <HealthRecordCell>
            <Name>{name}</Name>
            <Paragraph>
              Specialty:&nbsp;
              <Bold>{practice || 'N/A'}</Bold>
            </Paragraph>
            {lastVisit
              ? (
                <Paragraph>
                  Last visit:&nbsp;
                  <Bold>{moment(lastVisit).format('M/D/yyyy')}</Bold>
                </Paragraph>
              ) : null}
            {distanceInMiles > 100
              ? <WarningMessage severity="warning">Distance is greater than 100 miles</WarningMessage>
              : null}
            {includedPlanCount >= 0 ? (
              <Paragraph>
                included in
                {' '}
                <Green>
                  {includedPlanCount}
                  {' '}
                  plans
                </Green>
              </Paragraph>
            ) : null}
          </HealthRecordCell>
        )), 'name', 'practice', 'distanceInMiles', 'lastVisit', 'includedPlanCount'),
        width: cellWidth,
      },
      {
        id: '2',
        renderer: memoize((({
          row: {
            original: {
              visitsLastYear, visitsLast3Years, visitsLast5Years,
            },
          },
        }) => (
          <HealthRecordCell>
            {visitsLastYear
              ? (
                <>
                  <Paragraph>
                    <Bold>{visitsLastYear || 'N/A'}</Bold>
                    {' '}
                    visits in the last 12 months
                  </Paragraph>

                  <Paragraph>
                    <Bold>{visitsLast3Years || 'N/A'}</Bold>
                    {' '}
                    visits in the last 3 years
                  </Paragraph>

                  <Paragraph>
                    <Bold>{visitsLast5Years || 'N/A'}</Bold>
                    {' '}
                    visits in the last 5 years
                  </Paragraph>
                </>
              ) : null}
          </HealthRecordCell>
        )), 'visitsLastYear', 'visitsLast3Years', 'visitsLast5Years'),
        width: cellWidth,
      },
      {
        id: '3',
        renderer: memoize((({
          row: {
            original: {
              addressLine1, addressLine2, city, state, zip,
            },
          },
        }) => (
          <HealthRecordCell>
            <Paragraph>{[addressLine1, addressLine2, city, state, zip].filter((str) => str).join(', ')}</Paragraph>
          </HealthRecordCell>
        )), 'addressLine1', 'addressLine2', 'city', 'state', 'zip'),
        width: cellWidth,
      },
      {
        id: '4',
        renderer: (renderer) => {
          if (hideInclude) return null;
          if (renderer?.row?.original?.unsupported) return null;
          if (isRemoving) return <DoctorRemovalSelectionColumn {...renderer} onSelect={onSelect} onCriticalSelect={onCriticalSelect} />;
          return (
            <DoctorSelectionColumn
              {...renderer}
              hideCritical={hideCritical}
              onSelect={onSelect}
              onCriticalSelect={onCriticalSelect}
              disabled={disabled}
            />
          );
        },
        width: cellWidth - 80,
      },
    ];
  }, [cellWidth, isReducedViewport, onSelect, onCriticalSelect, isRemoving]);
  return doctorsColumns;
};
