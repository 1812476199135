import { useQuery } from '@apollo/react-hooks';
import { isEmpty } from '@healthiqeng/core.util.is-empty';
import { gql } from 'apollo-boost';
import {
  MedicareSupplementPlansQueryData,
  MedicareSupplementPlansQueryVariables,
} from './useMedicareSupplementQuotePlans.interfaces';
import { useCustomerQuery } from '../useCustomerQuery';
import { MedicarePlanTypeOptions } from '../../../constants';

const MEDICARE_SUPPLEMENT_PLANS_QUERY = gql`
  query getMedicareSupplementPlans($zipCode: String, $state: String) {
    medicareSupplementPlans(zipCode: $zipCode, state: $state)
  }
`;

export function useMedicareSupplementQuotePlans() {
  const { data } = useCustomerQuery();
  const result = useQuery<MedicareSupplementPlansQueryData, MedicareSupplementPlansQueryVariables>(MEDICARE_SUPPLEMENT_PLANS_QUERY, {
    variables: {
      zipCode: data?.customer?.person?.homeAddress?.zip ?? '33101',
      state: data?.customer?.person?.homeAddress?.state?.code ?? 'FL',
    },
  });
  const plans = result?.data?.medicareSupplementPlans.map((plan) => ({ label: plan, value: plan }));

  return {
    error: result?.error,
    plansLoading: result?.loading,
    plans: !isEmpty(plans) ? plans : MedicarePlanTypeOptions,
  };
}
