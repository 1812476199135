import React, { FC } from 'react';
import {
  TableCell,
  TableRow,
} from '@material-ui/core';
import { TotalUsePerVehicleRowProps } from './TotalUsePerVehicleRow.interfaces';
import { TotalUsePerVehicleRowAmount } from './TotalUsePerVehicleRow.elements';

export const TotalUsePerVehicleRow: FC<TotalUsePerVehicleRowProps> = ({
  vehiclesAssignationSum,
}) => (
  <TableRow>
    <TableCell>
      <b>Total Use Per Vehicle</b>
    </TableCell>

    {vehiclesAssignationSum.map((totalAmount: number) => (
      <TableCell align="left">
        <TotalUsePerVehicleRowAmount value={totalAmount}>
          {totalAmount}
          %
        </TotalUsePerVehicleRowAmount>
      </TableCell>
    ))}
  </TableRow>
);
