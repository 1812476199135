import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../../themes';

export const DraggableDividerContainer = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  height: 100%;
  position: relative;
  background-color: ${theme.color.grayLight};
  overflow: visible;
`);

export const DraggableDividerElement = styled('div')(css`
  width: 7px;
  position: absolute;
  left: -3px;
  right: -3px;
  height: 100%;
  cursor: ew-resize;
  background-color: transparent;
`);
