import * as React from 'react';
import { Router } from '@reach/router';
import { DialerCallContextContainer } from './DialerCallContextContainer';

/**
 * The ultimate goal of this router is to parse the callUrlPath from the URL if present.
 * If not present, it should not affect the rendering at all.
 */
export const DialerCallRouter: React.FunctionComponent = ({ children }) => {
  const style = React.useMemo(() => ({ height: '100%' }), []);
  return (
    <Router style={style}>
      <DialerCallContextContainer path="/call/:callUrlPath/*" content={children} />
      <DialerCallContextContainer path="/*" content={children} />
    </Router>
  );
};
