import * as React from 'react';
import styled from '@emotion/styled';
import { ThemeLight } from '../../../themes';

export const Note: React.FunctionComponent<{ value: string }> = ({ children, value }) => {
  let title = 'Note';
  if (value.includes('::')) {
    [title] = value.split('::');
  }

  return (
    <NoteContainer>
      <NoteTitle>{title}</NoteTitle>
      {children}
    </NoteContainer>
  );
};

const NoteTitle = styled.span`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  color: ${ThemeLight.color.primaryDark};
`;

const NoteContainer = styled.div`
  padding: 10px 15px;
  background-color: ${ThemeLight.color.grayLighter};
  max-width: 720px;
`;
