import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import React, { ChangeEvent } from 'react';
import {
  CellProps,
  Column,
  Renderer,
  Row,
  TableInstance,
  useFlexLayout,
  useResizeColumns,
  useRowSelect,
  UseRowSelectInstanceProps,
  UseRowSelectRowProps,
  UseRowSelectState,
  useTable,
} from 'react-table';
import {
  DatagridColumnDefinition,
  DatagridDataType,
  DatagridFeedbackMessage,
  DatagridProps,
  ReactTableColumn,
  ReactTableHeader,
  ReactTableRow,
} from './Datagrid.interfaces';
import {
  DateCell, NumberCell, RowSelectionCheckbox, PhoneCell, TextCell,
} from './inputs';
import {
  SwitchContainer,
  CopyButtonSpacing,
  DatagridCell,
  DatagridTable,
  Resizer,
  SelectionLabel,
  FullWidthDatagridTable,
  DatagridRow,
} from './Datagrid.elements';
import { Icon } from '../Icon';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { RowSelectionRadio } from './inputs/RowSelectionRadio';
import { noop } from '../../../util';

const DEFAULT_CELL_WIDTH = 150;

export function Datagrid<D extends Object>({
  columns,
  data,
  minWidth = 40,
  maxWidth = 380,
  width = DEFAULT_CELL_WIDTH,
  customColumns,
  postDatagridContent,
  enableSelection = false,
  addStubSelectionColumn = false,
  selectedRowIds: initialSelectedRowIds,
  onSelectionChange,
  selectionLabel,
  getRowId,
  fullWidth = false,
  selectionMultiselect = true,
  selectedColor,
  selectedBackground,
  selectedLabelColor,
  alwaysIncluded = false,
  disableNewSelections,
}: DatagridProps<D>) {
  const defaultColumn = React.useMemo((): Partial<Column<D>> => ({
    minWidth,
    maxWidth,
    width,
  }), []);

  const customColumnsDef: Column<D>[] = React.useMemo(() => customColumns?.map((c) => ({
    id: c.id,
    Header: c.header,
    Cell: c.renderer,
    width: c.width ?? DEFAULT_CELL_WIDTH,
  })), [customColumns]);

  const columnsDef: Column<D>[] = React.useMemo(() => [{
    id: 'root',
    Header: '',
    columns: [
      ...(columns?.map((c) => adaptColumnsDefinition(c, defaultColumn)) ?? []),
      ...(customColumnsDef ?? []),
      ...(enableSelection
        ? getSelectionHeaders(
          onSelectionChange,
          selectionLabel,
          selectionMultiselect,
          selectedColor,
          selectedLabelColor,
          addStubSelectionColumn,
          alwaysIncluded,
          disableNewSelections,
        )
        : []
      ),
    ],
  }], [columns, customColumnsDef, onSelectionChange, selectionLabel, selectionMultiselect, enableSelection, disableNewSelections]);

  const tableInstance = useTable<D>({
    columns: columnsDef,
    data,
    defaultColumn,
    useControlledState: (state) => React.useMemo(
      () => ({
        ...state,
        selectedRowIds: arrayToSelectedMap(initialSelectedRowIds),
      }),
      [state, selectedRowIds],
    ),
    getRowId: getRowId || undefined,
  },
  useFlexLayout,
  useResizeColumns,
  useRowSelect) as TableInstance<D> & UseRowSelectInstanceProps<D>;

  const {
    state, getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, toggleAllRowsSelected, toggleRowSelected,
  } = tableInstance;

  React.useEffect(() => {
    if (!selectionMultiselect && initialSelectedRowIds?.length) {
      toggleAllRowsSelected(false);
      if (rows.some((row) => row.id === initialSelectedRowIds[0])) {
        toggleRowSelected(initialSelectedRowIds[0], true);
      }
    }
  }, [initialSelectedRowIds, selectionMultiselect]);

  const { selectedRowIds } = state as UseRowSelectState<D>;

  const PostDatagridComponent = postDatagridContent;

  const [feedback, setFeedback] = React.useState<Partial<DatagridFeedbackMessage>>({});
  const copyableCols = React.useMemo(() => columns?.reduce((acc, { accessor, copyable }) => ({ ...acc, [accessor]: copyable }),
    {} as { [key: string]: boolean }) ?? {}, [columns]);
  const [copiedCells, setCopiedCells] = React.useState([]);

  const TableComponent = React.useMemo(() => (fullWidth ? FullWidthDatagridTable : DatagridTable), [fullWidth]);
  return (
    <>
      <TableComponent {...getTableProps()}>
        <TableHead>
          {headerGroups.filter((group) => group.Header).map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: ReactTableHeader<D>) => (
                <DatagridCell {...column.getHeaderProps()}>
                  {column.render('Header')}
                  <Resizer {...column.getResizerProps()} isResizing={column.isResizing} />
                </DatagridCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row: ReactTableRow<D>) => {
            prepareRow(row);
            const isChecked = row.getToggleRowSelectedProps().checked;
            const customRowProps = isChecked
              ? [{ style: { border: `1px solid ${selectedColor}`, background: selectedBackground } }]
              : undefined;
            return (
              <DatagridRow {...row.getRowProps(customRowProps)}>
                {row.cells.map((cell) => {
                  const rowIdx = cell.row.index;
                  const colId = cell.column.id;
                  const onCopy = async () => {
                    const permissionName = 'clipboard-write' as PermissionName;
                    const permission = await navigator.permissions.query({ name: permissionName });
                    if (permission.state === 'denied') {
                      setFeedback({
                        message: `Failed to copy. Visit chrome://settings/content/all?searchSubpage=healthiq
                        and reset HealthIQ's site permissions`,
                        severity: 'error',
                      });
                    } else {
                      await navigator.clipboard.writeText(cell.value?.toString() ?? '');
                      if (!copiedCells[rowIdx]) copiedCells.push([]);
                      copiedCells[rowIdx][colId] = true;
                      setCopiedCells(copiedCells);
                      setFeedback({
                        message: 'Cell copied',
                        severity: 'success',
                      });
                    }
                  };
                  const isCopied = copiedCells[rowIdx] && copiedCells[rowIdx][colId];
                  const customCellProps = isCopied ? [{ style: { background: 'rgb(94, 205, 130)' } }] : undefined;
                  return (
                    <DatagridCell {...cell.getCellProps(customCellProps)}>
                      {cell.render('Cell')}
                      {copyableCols[colId]
                        ? (
                          <CopyButtonSpacing title="Copy" onClick={onCopy}>
                            <Icon type="copy" />
                          </CopyButtonSpacing>
                        ) : undefined}
                    </DatagridCell>
                  );
                })}
              </DatagridRow>
            );
          })}
        </TableBody>
      </TableComponent>
      {PostDatagridComponent ? <PostDatagridComponent {...tableInstance} selectedRowIds={selectedRowIds} /> : null}
      {feedback.message ? (
        <Snackbar open autoHideDuration={15000} onClose={() => setFeedback({ message: '' })}>
          <Alert severity={feedback.severity}>
            {feedback.message}
          </Alert>
        </Snackbar>
      ) : undefined}
    </>
  );
}

function adaptColumnsDefinition<D extends Object>(
  colDef: DatagridColumnDefinition<D>,
  defaults: Partial<Column<D>>,
): ReactTableColumn<D> {
  return {
    accessor: colDef.accessor,
    Cell: getCellComponent(colDef.type),
    Header: colDef.header,
    width: colDef.width ?? defaults.width,
    minWidth: colDef.minWidth ?? defaults.minWidth,
    maxWidth: colDef.maxWidth ?? defaults.maxWidth,
  };
}

function getCellComponent(type: DatagridDataType = DatagridDataType.Text): Renderer<CellProps<any>> {
  switch (type) {
    case DatagridDataType.Number:
      return NumberCell;
    case DatagridDataType.Date:
      return DateCell;
    case DatagridDataType.Text:
      return TextCell;
    case DatagridDataType.Phone:
      return PhoneCell;
    default:
      throw new Error(`Unsupported data grid cell type: ${type}`);
  }
}

function getSelectionHeaders<D extends Object>(
  onSelectionChange: (data: D) => void,
  label?: string,
  multiSelect?: boolean,
  selectedColor?: string,
  selectedLabelColor?: string,
  addStubSelectionColumn?: boolean,
  alwaysIncluded?: boolean,
  disableNewSelections?: boolean,
) {
  return [{
    id: 'selection',
    Cell: ({ row }: { row: Row<D> & UseRowSelectRowProps<D> }) => {
      if (addStubSelectionColumn) return null;
      if (alwaysIncluded) return 'Included';
      const toggleRowSelectedProps = row.getToggleRowSelectedProps();
      const onContainerClick = () => {
        if (multiSelect || !toggleRowSelectedProps.checked) {
          toggleRowSelectedProps.onChange({ target: { value: toggleRowSelectedProps.checked } } as any as ChangeEvent);
          if (onSelectionChange) {
            onSelectionChange({ ...row.original, ...row.values });
          }
        }
      };

      if (disableNewSelections && !toggleRowSelectedProps.checked) {
        return (
          <SwitchContainer cursor="auto">
            <SelectionLabel color={selectedLabelColor}>Disabled</SelectionLabel>
            {multiSelect
              ? (
                <RowSelectionCheckbox
                  {...toggleRowSelectedProps}
                  disabled
                  onChange={noop}
                  color={selectedColor}
                />
              )
              : <RowSelectionRadio {...toggleRowSelectedProps} onChange={noop} />}
          </SwitchContainer>
        );
      }

      return (
        <SwitchContainer onClick={onContainerClick}>
          {label ? <SelectionLabel color={selectedLabelColor}>{label}</SelectionLabel> : null}
          {multiSelect
            ? (
              <RowSelectionCheckbox
                {...toggleRowSelectedProps}
                onChange={noop}
                color={selectedColor}
              />
            )
            : <RowSelectionRadio {...toggleRowSelectedProps} onChange={noop} />}
        </SwitchContainer>
      );
    },
  }];
}

function arrayToSelectedMap(array: string[]): { [key: string]: boolean } {
  if (!array) return {};
  return array.reduce((obj, rowId) => ({
    ...obj,
    [rowId]: true,
  }), {} as { [key: string]: boolean });
}
