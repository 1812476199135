import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import * as React from 'react';
import { useEventListener } from '../../../events';
import { SaveFailureEvent } from '../../../graphql';

export const SaveAnswersListener: React.FunctionComponent = () => {
  const [open, setOpen] = React.useState(false);

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  useEventListener(SaveFailureEvent, () => {
    setOpen(true);
  }, [setOpen]);

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="error">
        Failed to save answers. Please try again.
      </Alert>
    </Snackbar>
  );
};
