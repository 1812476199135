import { gql } from 'apollo-boost';
import { useMutationCustom } from '../useMutationCustom';
import { AppSubmissionMutationResponse, AppSubmissionMutationVariables } from './useAppSubmissionMutation.interfaces';
import { useActiveLeadId } from '../../../components/routers/LeadRouter/useActiveLeadId';
import { LEAD_FRAGMENT } from '../../fragments/lead';

const APP_SUBMISSION_MUTATION = gql`
  mutation AppSubmission($leadId: ID!) {
    submitApp(leadId: $leadId) {
      code
      success
      message
      timestamp
      type
      errors
      lead {
        ...LeadFragment
      }
    }
  }
  ${LEAD_FRAGMENT}
`;

export function useAppSubmissionMutation(leadIdParam?: string) {
  const leadId = useActiveLeadId(leadIdParam);
  return useMutationCustom<AppSubmissionMutationResponse, AppSubmissionMutationVariables>(APP_SUBMISSION_MUTATION, {
    variables: {
      leadId,
    },
    update: (cache, { data }) => {
      if (!data?.submitApp?.success) return;
      cache.writeFragment({
        data: data.submitApp.lead,
        fragment: LEAD_FRAGMENT,
        fragmentName: 'LeadFragment',
      });
    },
  });
}
