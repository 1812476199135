import { gql } from 'apollo-boost';
import { CUSTOMER_FRAGMENT } from '../../fragments';
import { MutationCustomReturn, useMutationCustom } from '../useMutationCustom';
import { PullLeadMutationResponse, PullLeadMutationVariables } from './usePullLeadMutation.interfaces';

export const PULL_LEAD_MUTATION = gql`
  mutation PullLead($leadId: ID!) {
    pullLead(leadId: $leadId) {
      code
      success
      message
      timestamp
      customer {
        ...CustomerFragment
      }
    }
  }
  ${CUSTOMER_FRAGMENT}
`;

export function usePullLeadMutation(): MutationCustomReturn<
PullLeadMutationResponse,
PullLeadMutationVariables> {
  return useMutationCustom<
  PullLeadMutationResponse,
  PullLeadMutationVariables>(PULL_LEAD_MUTATION);
}
