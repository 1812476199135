import * as React from 'react';
import { MedicareStatusPanelProps } from './MedicareStatusPanel.interfaces';
import {
  MedicareStatusPanelContainer,
} from './MedicareStatusPanel.elements';
import { LeadPanelCard, LeadPanelStickyCard } from '../../../elements';
import {
  QuoteInfoCard,
  MedicareLeadStages,
  FormGenerator,
  FormGeneratorStyle,
} from '../../../../../common';
import { useLeadQuery } from '../../../../../../graphql';

export const MedicareStatusPanel: React.FunctionComponent<MedicareStatusPanelProps> = ({
  leadId,
}) => {
  const { data } = useLeadQuery(leadId);
  return (
    <MedicareStatusPanelContainer>
      <LeadPanelStickyCard>
        <QuoteInfoCard leadId={leadId} />
      </LeadPanelStickyCard>
      <LeadPanelCard>
        <MedicareLeadStages />
      </LeadPanelCard>
      <LeadPanelCard>
        <FormGenerator
          lockable
          style={FormGeneratorStyle.Compact}
          leadId={leadId}
          referenceId="medicare-status"
        />
      </LeadPanelCard>
      { !!data?.sfOpportunityId && (
        <LeadPanelCard>
          <a href={`https://hiq.my.salesforce.com/${data?.sfOpportunityId}`}>
            Salesforce Opportunity
          </a>
        </LeadPanelCard>
      ) }
    </MedicareStatusPanelContainer>
  );
};
