import { Alert } from '@material-ui/lab';
import React from 'react';
import { Padding } from './ReleaseOwnerErrorMessage.elements';
import { ReleaseOwnerErrorMessageProps } from './ReleaseOwnerErrorMessage.interfaces';

export const ReleaseOwnerErrorMessage = ({ message, severity = 'error' }: ReleaseOwnerErrorMessageProps) => (
  <Padding>
    <Alert severity={severity}>{message}</Alert>
  </Padding>
);
