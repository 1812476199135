import { useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { ADDRESS_LOOKUP_FRAGMENT } from '../../fragments';
import { PlaceLookupQueryData, PlaceLookupQueryVariables } from './usePlaceLookupQuery.interfaces';

const PLACE_LOOKUP_QUERY = gql`
  query PlaceLookup($input: String!, $sessionToken: String!) {
    searchPlace(input: $input, sessionToken: $sessionToken) {
      places {
        ...AddressLookupFragment
      }
    }
  }
  ${ADDRESS_LOOKUP_FRAGMENT}
`;

export function usePlaceLookupQuery() {
  return useLazyQuery<PlaceLookupQueryData, PlaceLookupQueryVariables>(PLACE_LOOKUP_QUERY);
}
