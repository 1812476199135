import * as React from 'react';
import Button from '@material-ui/core/Button';
import { FormItemTypeArrayProps } from './FormItemTypeArray.interfaces';
import { useConditionalRendering } from '../../../useConditionalRendering';
import { getNumArrayItems } from './getNumArrayItems';
import {
  AddAnotherContainer,
  ArrayFormHeader,
  ArrayItemContainer,
  ArrayItemHeaderContainer,
  ArrayItemHeaderItem,
} from './FormItemTypeArray.elements';
import { Icon } from '../../../../Icon';
import { deleteItem } from './deleteItem';

export const FormItemTypeArray: React.FunctionComponent<FormItemTypeArrayProps> = ({
  leadId,
  visibleCondition,
  depth,
  FormGeneratorQuestionFlow,
  formId,
  parentQuestionId,
  answers,
  onChange,
  minItems,
  maxItems,
  label,
}) => {
  const formItemVisible = useConditionalRendering({
    questionId: 'none',
    parentQuestionId,
    answers,
    visibleCondition,
  });
  const numItems = Math.max(getNumArrayItems(formId, answers), minItems);
  const indexes = Array.from({ length: numItems }, (v, i) => i);

  const onAddAnother = () => {
    onChange(`${formId}.${numItems}.__initialized`, true);
    onChange(`${formId}.${numItems}.__deleted`, false);
  };

  const onDelete = (index: number) => {
    deleteItem({
      index, formId, answers, onChange,
    });
  };

  if (!formItemVisible) return <></>;
  return (
    <>
      <ArrayFormHeader>{ label }</ArrayFormHeader>
      { indexes.map((index) => (
        <ArrayItemContainer isLast={index + 1 === numItems} key={`${formId}-${index}`}>
          { numItems > minItems && (
            <ArrayItemHeaderContainer>
              <ArrayItemHeaderItem>
                <Button
                  variant="text"
                  color="primary"
                  startIcon={<Icon type="delete" />}
                  onClick={() => onDelete(index)}
                >
                  Delete
                </Button>
              </ArrayItemHeaderItem>
            </ArrayItemHeaderContainer>
          ) }
          <FormGeneratorQuestionFlow
            leadId={leadId}
            referenceId={formId}
            depth={depth}
            arrayFormIndex={index}
            answers={answers}
            onChange={onChange}
          />
        </ArrayItemContainer>
      )) }
      { numItems < maxItems && (
        <AddAnotherContainer>
          <Button
            variant="text"
            color="primary"
            startIcon={<Icon type="add" />}
            onClick={onAddAnother}
          >
            Add Another
          </Button>
        </AddAnotherContainer>
      ) }
    </>
  );
};
