import * as React from 'react';
import {
  CreateActivityForm,
} from '../../common';
import { SidePanelContentLayout } from '../../layouts/SidePanelContentLayout';
import { MyActivities, AllActivities } from './Activities';
import { MainPanelLayoutTabItem, TabTopLayoutTabItem } from '../../layouts';
import {
  ContentContainer, ContentRowContainer,
  StyledTabs,
  StyledTabsContainer,
  TabRowContainer, TabTopLayoutContainer,
} from '../../layouts/TabTopLayout/TabTopLayout.elements';
import { noop } from '../../../util';
import { TabTopLayoutTab } from '../../layouts/TabTopLayout/TabTopLayoutTab';

export const ActivityFeed: React.FunctionComponent = () => {
  const [activeTab, setActiveTab] = React.useState('my-activities');

  const tabs: MainPanelLayoutTabItem[] = [
    {
      id: 'my-activities',
      label: 'MY',
      icon: 'note',
      content: (
        <MyActivities />
      ),
    },
    {
      id: 'all-activities',
      label: 'ALL',
      icon: 'note',
      content: (
        <AllActivities />
      ),
    },
  ];

  function getActiveIndex(tabs: TabTopLayoutTabItem[], activeId: string): number {
    const index = tabs.map((tab) => tab.id).indexOf(activeId);
    return Math.max(index, 0);
  }

  return (
    <SidePanelContentLayout header="Activity Feed">
      <CreateActivityForm />
      <TabTopLayoutContainer>
        <TabRowContainer>
          <StyledTabsContainer data-qa="top-layout-tabs">
            <StyledTabs
              value={getActiveIndex(tabs, activeTab)}
              onChange={noop}
              scrollButtons="auto"
              variant="scrollable"
            >
              {tabs.map(({ id, label }) => (
                <TabTopLayoutTab
                  key={id}
                  id={id}
                  label={label}
                  onClick={setActiveTab}
                />
              ))}
            </StyledTabs>
          </StyledTabsContainer>
        </TabRowContainer>
        <ContentRowContainer>
          {tabs.map(({ id, content }) => (
            <ContentContainer
              key={id}
              role="tabpanel"
              hidden={activeTab !== id}
            >
              {activeTab === id && content}
            </ContentContainer>
          ))}
        </ContentRowContainer>
      </TabTopLayoutContainer>
    </SidePanelContentLayout>
  );
};
