import { gql, InMemoryCache } from 'apollo-boost';
import { LeadFollowUpQueryData, LeadFollowUpQueryVariables } from './useLeadFollowUpQuery.interfaces';
import { useQuery } from '@apollo/react-hooks';
import { CALENDAR_EVENT_FRAGMENT, CalendarEventFragment } from '../../../fragments';
import { useActiveLeadId } from '../../../../components/routers/LeadRouter/useActiveLeadId';

export const LEAD_FOLLOW_UP_QUERY = gql`
  query scheduledLeadFollowUps($leadId: ID!) {
    scheduledLeadFollowUps(leadId: $leadId) {
      ...CalendarEventFragment
    }
  }
  ${CALENDAR_EVENT_FRAGMENT}
`;

export function useLeadFollowUpQuery(leadId?: string) {
  const activeLeadId = useActiveLeadId();
  return useQuery<LeadFollowUpQueryData, LeadFollowUpQueryVariables>(LEAD_FOLLOW_UP_QUERY, {
    variables: {
      leadId: leadId ?? activeLeadId,
    },
  });
}

export function readLeadFollowUpQuery(cache: InMemoryCache, leadId: string) {
  return cache.readQuery<LeadFollowUpQueryData, LeadFollowUpQueryVariables>({
    query: LEAD_FOLLOW_UP_QUERY,
    variables: { leadId },
  });
}

export function writeLeadFollowUpQuery(cache: InMemoryCache, leadId: string, data: LeadFollowUpQueryData) {
  cache.writeQuery<LeadFollowUpQueryData, LeadFollowUpQueryVariables>({
    query: LEAD_FOLLOW_UP_QUERY,
    variables: { leadId },
    data,
  });
}

export function addCalendarEvent(cache: InMemoryCache, leadId: string, event: CalendarEventFragment) {
  if (!event) return;
  const existingEvents = readLeadFollowUpQuery(cache, leadId);
  const newEvents = [
    ...(existingEvents?.scheduledLeadFollowUps ?? []),
    event,
  ].sort(compareEvents);
  writeLeadFollowUpQuery(cache, leadId, {
    scheduledLeadFollowUps: newEvents,
  });
}

export function deleteCalendarEvent(cache: InMemoryCache, leadId: string, eventId: string) {
  const existingEvents = readLeadFollowUpQuery(cache, leadId);
  if (!existingEvents?.scheduledLeadFollowUps?.length) return;
  const newEvents = existingEvents.scheduledLeadFollowUps.filter(({ id }) => id !== eventId);
  writeLeadFollowUpQuery(cache, leadId, {
    scheduledLeadFollowUps: newEvents,
  });
}

function compareEvents(a: CalendarEventFragment, b: CalendarEventFragment): number {
  const aTime = (new Date(a.start)).getTime();
  const bTime = (new Date(b.start)).getTime();
  return bTime - aTime;
}
