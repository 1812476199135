import * as React from 'react';
import { useSoldLeadInfoQuery } from '../../../graphql';
import { FormGeneratorProps, FormGeneratorStyle } from './FormGenerator.interfaces';
import { FormGeneratorCompact } from './FormGeneratorCompact';
import { FormGeneratorQuestionFlow } from './FormGeneratorQuestionFlow';
import { FormGeneratorReadOnly } from './FormGeneratorReadOnly';
import { FormGeneratorStandard } from './FormGeneratorStandard';
import { isFormLocked } from './isFormLocked';

export const FormGenerator: React.FunctionComponent<FormGeneratorProps> = ({
  leadId,
  referenceId,
  style,
  lockable,
  onFieldsChange,
  locked,
}) => {
  // TODO: This query should not be part of the FormGenerator, as it's tightly coupling
  // form rendering logic with business logic. E.g. this would lock EVERY FORM on a sold lead
  // which is almost definitely not what we would want to do.
  //
  // This logic should be moved upstream to whatever is calling the FormGenerator
  const { data: soldLeadInfoData } = useSoldLeadInfoQuery(leadId);

  if (isFormLocked(soldLeadInfoData, referenceId) || locked) {
    return (
      <FormGeneratorReadOnly
        leadId={leadId}
        referenceId={referenceId}
      />
    );
  }

  // Compact forms do not support `onFieldsChange` for
  // the time being.
  if (style === FormGeneratorStyle.Compact) {
    return (
      <FormGeneratorCompact
        leadId={leadId}
        referenceId={referenceId}
        lockable={lockable}
      />
    );
  }
  if (style === FormGeneratorStyle.ReadOnly) {
    return (
      <FormGeneratorReadOnly
        leadId={leadId}
        referenceId={referenceId}
      />
    );
  }
  if (style === FormGeneratorStyle.Standard) {
    return (
      <FormGeneratorStandard
        leadId={leadId}
        referenceId={referenceId}
      />
    );
  }
  return (
    <FormGeneratorQuestionFlow
      leadId={leadId}
      referenceId={referenceId}
      onFieldsChange={onFieldsChange}
    />
  );
};
