import { css } from '@emotion/core';
import styled from '@emotion/styled';
import * as React from 'react';
import { animated } from 'react-spring/renderprops.cjs';
import { resetDiv } from '../resets';

export const ExpanderFrame = styled(animated.div)(resetDiv, css`
  position: relative;
  overflow: hidden;
  width: 100%;
`);

export const ExpanderBody = styled(animated.div)(resetDiv, css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`);
