import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const QuoteCarrierInfoContainer = styled('div')(css``);

export const QuoteCompanyName = styled('h4')(css`
  margin: 0;
`);
export const QuoteParentCompanyName = styled('h2')(css`
  margin: 0;
`);
