import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../../themes';

export const WarningMessage = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  background-color: ${theme.color.error};
  color: white;
  text-align: center;
  padding: 10px 0;
`);
