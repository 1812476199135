import { useLeadQuery } from '../useLeadQuery';

export function useSelectedQuoteQuery(leadId: string) {
  const result = useLeadQuery(leadId);
  const selectedQuote = {
    ...result?.data?.selectedQuotes[0],
    billingFrequency: result?.data?.billingFrequency,
  };
  return {
    error: result?.error,
    loading: result?.loading,
    data: selectedQuote,
  };
}
