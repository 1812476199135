import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { DashboardListQueryData } from './useDashboardListQuery.interfaces';

const DASHBOARD_LIST_QUERY = gql`
  query listDashboards {
    dashboardList {
      name
      url
    }
  }
`;

export function useDashboardListQuery() {
  return useQuery<DashboardListQueryData>(DASHBOARD_LIST_QUERY);
}
