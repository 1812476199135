import { QuestionType } from '@hiq/crm.types';

export const isTextInputQuestionType = (questionType: QuestionType) => [
  QuestionType.Text,
  QuestionType.Currency,
  QuestionType.Number,
  QuestionType.Percent,
  QuestionType.TextArea,
  QuestionType.CreditCardNumber,
  QuestionType.CreditCardCVV,
  QuestionType.RoutingNumber,
  QuestionType.AddressLookup,
  QuestionType.Vin,
].includes(questionType);
