import React from 'react';
import { Padding } from '../HealthRecordErrorMessage/HealthRecordErrorMessage.elements';
import { OverlayToolbarProps } from './OverlayToolbar.interfaces';
import { AddItemButton, RemoveItemButton, SaveItemButton } from '../CheckMyHealthRecordDataGrid.elements';
import { Icon } from '../../../Icon';

export const OverlayToolbar = ({
  panel, showPanel, toggleShowPanel, addButtonText, removeButtonText, isRemoving, toggleRemove, onConfirmRemove, removeDisabled,
}: OverlayToolbarProps) => {
  const buttonContent = React.useMemo(() => {
    if (isRemoving) {
      return (
        <Padding>
          <RemoveItemButton variant="contained" startIcon={<Icon type="clear" />} onClick={toggleRemove}>
            Cancel
          </RemoveItemButton>
          <SaveItemButton variant="contained" startIcon={<Icon type="done" />} onClick={onConfirmRemove} disabled={removeDisabled}>
            Save
          </SaveItemButton>
        </Padding>
      );
    }
    return (
      <Padding>
        <RemoveItemButton variant="contained" startIcon={<Icon type="delete" />} onClick={toggleRemove}>
          {removeButtonText}
        </RemoveItemButton>
        <AddItemButton variant="contained" startIcon={<Icon type="add" />} onClick={toggleShowPanel}>
          {addButtonText}
        </AddItemButton>
      </Padding>
    );
  }, [isRemoving, onConfirmRemove, toggleShowPanel, toggleRemove, removeButtonText, addButtonText, removeDisabled]);

  return (
    <Padding>
      {showPanel ? panel : buttonContent}
    </Padding>
  );
};
