import * as React from 'react';
import { MedicarePaymentPanelProps } from './MedicarePaymentPanel.interfaces';
import { MedicarePaymentPanelContainer } from './MedicarePaymentPanel.elements';
import { LeadPanelStickyCard, LeadPanelCard } from '../../../elements';
import { FormGenerator, FormGeneratorStyle, QuoteInfoCard } from '../../../../../common';

export const MedicarePaymentPanel: React.FunctionComponent<MedicarePaymentPanelProps> = ({
  leadId,
}) => (
  <MedicarePaymentPanelContainer>
    <LeadPanelStickyCard>
      <QuoteInfoCard leadId={leadId} />
    </LeadPanelStickyCard>
    <LeadPanelCard>
      <FormGenerator
        lockable
        leadId={leadId}
        referenceId="medicare-payment"
        style={FormGeneratorStyle.Compact}
      />
    </LeadPanelCard>
  </MedicarePaymentPanelContainer>
);
