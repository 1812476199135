import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../themes';

export const UserDashboardLayoutContainer = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  height: 100%;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 50px auto auto auto;
  grid-template-areas:
    "nav-bar nav-bar"
    "left-panel right-top-panel"
    "left-panel right-middle-panel"
    "left-panel right-bottom-panel";
  row-gap: 15px;
  column-gap: 15px;
  padding: 0 15px 15px 15px;
  background-color: ${theme.color.backgroundBackdrop};
`);

export const NavBarContainer = styled('div')(css`
  margin: 0 -15px;
  grid-area: nav-bar;
`);

const PanelContainer = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  overflow: hidden;
  background-color: ${theme.color.background};
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
`);

export const LeftPanelContainer = styled(PanelContainer)(css`
  grid-area: left-panel;
`);

export const RightTopPanelContainer = styled(PanelContainer)(css`
  grid-area: right-top-panel;
`);

export const RightMiddlePanelContainer = styled(PanelContainer)(css`
  grid-area: right-middle-panel;
`);

export const RightBottomPanelContainer = styled(PanelContainer)(css`
  grid-area: right-bottom-panel;
`);
