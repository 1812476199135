import { Alert, AlertTitle } from '@material-ui/lab';
import React from 'react';
import { QueryLoader } from '../../QueryLoader';
import { useActiveLeadId, useCustomerId } from '../../../routers';
import { HealthRecordErrorMessage } from '../CheckMyHealthRecordDataGrid/HealthRecordErrorMessage/HealthRecordErrorMessage';
import { useGetLatestDoctorsQuery } from '../../../../graphql/queries/useGetLatestDoctorsQuery';
import { VerifyUserGrid } from './VerifyUserGrid';
import { useVerifyCmhrUserMutation } from '../../../../graphql/mutations/useVerifyCmhrUserMutation';
import { useFormAnswers } from '../../../../hooks';

export const CheckMyHealthRecordVerifyUser: React.FunctionComponent = () => {
  const customerId = useCustomerId();
  const leadId = useActiveLeadId();
  const userDoctorIds: string[] = [];
  const [
    verifyChmrUserMutation,
    { data: mutationData, error: mutationError, loading: mutationLoading },
  ] = useVerifyCmhrUserMutation({ variables: { customerId, userDoctorIds } });
  const [
    getLatestDoctorsQuery,
    { data: queryData, error: queryError, loading: queryLoading },
  ] = useGetLatestDoctorsQuery(customerId);
  const [answers] = useFormAnswers(leadId);
  const checkMyHealthRecordCustomerMarxVerified = 'lead.digitalProductCustomerMarxVerified';
  const isVerifiedByMarx = answers[checkMyHealthRecordCustomerMarxVerified];

  const { success } = mutationData?.verifyCmhrUser ?? {};
  const { complete, doctors } = queryData?.latestDoctors ?? {};

  React.useEffect(() => {
    if (isVerifiedByMarx === false) getLatestDoctorsQuery();
  }, [isVerifiedByMarx]);

  const handleVerifyUser = (userDoctorIds: string[]) => {
    verifyChmrUserMutation({ customerId, userDoctorIds });
  };

  const content = complete
    ? (
      <>
        <Alert severity="info">
          <AlertTitle>Verify User</AlertTitle>
          Only when the user provides the names of two correct doctors listed below, please click the Verify button.
        </Alert>
        <VerifyUserGrid
          data={doctors}
          label="Include in coverage check"
          title="Doctors"
          handleVerifyUser={handleVerifyUser}
        />
      </>
    )
    : (
      <HealthRecordErrorMessage
        message="Failed to retrieve health records. Cannot verify user."
        severity={!doctors?.length ? 'warning' : 'error'}
      />
    );

  return (
    <QueryLoader loading={mutationLoading || queryLoading} error={mutationError || queryError} componentName="CheckMyHealthRecord">
      {content}
      {success === false && (
        <HealthRecordErrorMessage
          message="Unexpected error. Failed to verify user."
        />
      )}
      {success === true && (
        <HealthRecordErrorMessage severity="info" message="User Verified" />
      )}
    </QueryLoader>
  );
};
