import * as React from 'react';
import { ProductTypeLife } from '@hiq/crm.types';
import { useActiveLeadId, useLeadSectionRoute } from '../../../../routers';
import {
  useLeadQuery,
  ConvertSuccessEvent,
  useQuestionFlowQuery,
} from '../../../../../graphql';
import { useEventListener } from '../../../../../events/useEventListener';
import { useQuestionFlowAnchorQuery } from '../../useQuestionFlowAnchorQuery';
import {
  LogPanelChange,
  QuestionFlow,
  IconProps, SpecialRateVerification, FileUploader,
} from '../../../../common';
import { MainPanelLayout, MainPanelLayoutTabItem } from '../../../../layouts';
import { LifeQuotePanelFactory, LifeStatusPanel } from '../panels';
import { LeadPanelContainer, LeadPanelContainerCard } from '../../elements';
import { LeadInfo } from '../../common/LeadInfo';
import { APPLICATION_PANEL_ID, LIFE_QUOTING_PANEL_ID } from '../LifeLead.interfaces';
import { useCurrentUserIsAgent } from '../../../../../hooks';

export const LifePostConversionLead: React.FunctionComponent = () => {
  const leadId = useActiveLeadId();
  const { data: leadData, refetch } = useLeadQuery();
  useEventListener(ConvertSuccessEvent, () => refetch());
  const isAgent = useCurrentUserIsAgent();
  const productType: ProductTypeLife = leadData?.productType as ProductTypeLife;
  const wholeLife = productType === ProductTypeLife.WholeLife;
  const interviewAnchors = useQuestionFlowAnchorQuery(leadId, wholeLife ? 'interview' : 'quoting');
  const [activeTab, setActiveTab] = useLeadSectionRoute(wholeLife ? 'interview' : 'quoting');

  const { data: application } = useQuestionFlowQuery(leadId, 'application');

  const isSpecialRate = leadData?.selectedQuote?.product?.isSpecialRate;

  const tabs: MainPanelLayoutTabItem[] = [
    {
      id: 'lead-info',
      label: 'Lead Info',
      icon: 'person',
      content: (
        <LeadPanelContainerCard>
          <LeadInfo leadId={leadId} />
        </LeadPanelContainerCard>
      ),
    },
    {
      id: 'status',
      label: 'Status',
      icon: 'assignment-turned-in',
      content: (
        <>
          <LogPanelChange panel="status" />
          <LifeStatusPanel leadId={leadId} />
        </>
      ),
    },
    {
      id: LIFE_QUOTING_PANEL_ID,
      label: 'Quote',
      icon: 'local-offer',
      content: (
        <LeadPanelContainer>
          <LogPanelChange panel="quoting" />
          <LifeQuotePanelFactory
            productType={productType}
          />
        </LeadPanelContainer>
      ),
    },
    ...(!isSpecialRate ? [] : [
      {
        id: 'special-rate-verification',
        label: 'Special Rate Verify',
        icon: 'hiq-torch' as IconProps['type'],
        content: (
          <LeadPanelContainerCard>
            <LogPanelChange panel="special-rate-verification" />
            <SpecialRateVerification />
          </LeadPanelContainerCard>
        ),
      },
    ]),
    {
      id: 'interview',
      label: 'Interview',
      icon: 'assignment',
      anchors: interviewAnchors,
      content: (
        <LeadPanelContainerCard>
          <LogPanelChange panel="interview" />
          <QuestionFlow
            leadId={leadId}
            referenceId="interview"
            root
          />
        </LeadPanelContainerCard>
      ),
    },
    (!isAgent && (
      {
        id: 'retention-specialist',
        label: 'Retention Specialist',
        icon: 'ballot' as IconProps['type'],
        content: (
          <LeadPanelContainerCard>
            <QuestionFlow leadId={leadId} referenceId="retention" allowExternal={false} />
          </LeadPanelContainerCard>
        ),
      }
    )),
    {
      id: 'attachments',
      label: 'Attachments',
      icon: 'assignment' as IconProps['type'],
      content: (
        <>
          <LogPanelChange panel="attachments" />
          <FileUploader />
        </>
      ),
    },
    ...(!application ? [] : [
      {
        id: APPLICATION_PANEL_ID,
        label: 'Application',
        icon: 'ballot' as IconProps['type'],
        content: (
          <LeadPanelContainerCard>
            <LogPanelChange panel="application" />
            <QuestionFlow leadId={leadId} referenceId="application" />
          </LeadPanelContainerCard>
        ),
      },
    ]),
  ];

  return (
    <MainPanelLayout
      tabs={tabs}
      activeId={mapActiveTab(activeTab)}
      onTabChange={setActiveTab}
    />
  );
};

function mapActiveTab(activeTab: string): string {
  if (activeTab === 'conversion') {
    return 'status';
  }
  return activeTab;
}
