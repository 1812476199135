import * as React from 'react';
import { useLogTabChange } from '../../../../graphql';
import { emitAnalyticsEvent } from '../../../../events/emitAnalyticsEvent';
import { LogTabChangeProps } from './LogTabChange.interfaces';

export const LogTabChange: React.FunctionComponent<LogTabChangeProps> = ({ tab, detail }: LogTabChangeProps) => {
  const [logTabChange] = useLogTabChange();
  React.useEffect(() => {
    logTabChange({ tab, detail });
    emitAnalyticsEvent('Tab Change', { tab, detail });
  }, []);
  return null;
};
