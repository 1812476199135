import { IEventBusEvent } from '@healthiqeng/core.services.event-bus';
import {
  HealthRecordEventProperties,
  HealthRecordMarxEventProperties, HealthRecordPullEventProperties,
  HealthRecordStatusEventProperties, RecommendedPlansEventProperties,
} from './MedDigitalAnalyticsEvent.interfaces';

export class MedDigitalAnalyticsEvent implements IEventBusEvent {
  public type: string = 'MED_DIGITAL_ANALYTICS_EVENT';
  constructor(
    public message: string,
    public properties?:
    HealthRecordStatusEventProperties |
    HealthRecordEventProperties |
    HealthRecordMarxEventProperties |
    HealthRecordPullEventProperties |
    RecommendedPlansEventProperties,
  ) {}
}
