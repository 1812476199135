import React from 'react';

export interface DementiaContextData {
  hasDementiaFlag: boolean
  toggleOpenDementiaFlag: () => void
  isOpenDementiaFlagWarning: boolean
  setHasDementiaFlag: (hasDementiaFlag: boolean) => void
}

export const DementiaContext = React.createContext<DementiaContextData>({
  hasDementiaFlag: false,
  toggleOpenDementiaFlag: () => {},
  isOpenDementiaFlagWarning: false,
  setHasDementiaFlag: () => {},
});
