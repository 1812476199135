import { isPresent } from '@healthiqeng/core.util.is-present';
import { InputTransformer } from '../InputTransformer';

export class IdentityTransformer extends InputTransformer<string> {
  constructor(initialValue: string) {
    super(initialValue);
    this.draftValue = initialValue;
  }

  public update(nextValue: string) {
    this.draftValue = nextValue;
    this.persistedValue = this.draftValue;
  }

  public getDraftValue() {
    if (!isPresent(this.draftValue)) {
      return '';
    }
    return this.draftValue;
  }

  public getPersistedValue() {
    return this.persistedValue;
  }

  public getFormattedValue(): string {
    return this.getPersistedValue();
  }
}
