import React from 'react';
import { DementiaContext } from '../../../contexts';
import { DementiaFlagContainer, DementiaFlagText } from './NavBarDementiaFlag.elements';
import { Icon } from '../../../common';
import { Box } from '@material-ui/core';
import { NavBarDementiaFlagWarning } from './NavBarDementiaFlagWarning';

export const NavBarDementiaFlag = () => {
  const { hasDementiaFlag, toggleOpenDementiaFlag, isOpenDementiaFlagWarning } = React.useContext(DementiaContext);

  if (hasDementiaFlag) {
    return (
      <Box>
        <DementiaFlagContainer onClick={toggleOpenDementiaFlag}>
          <Icon type="warning-outlined" style={{ fontSize: '14px' }} />
          <DementiaFlagText>
            Potential cognitive impairment
          </DementiaFlagText>
        </DementiaFlagContainer>
        <NavBarDementiaFlagWarning open={isOpenDementiaFlagWarning} onDialogClose={toggleOpenDementiaFlag} />
      </Box>

    );
  }
  return <></>;
};
