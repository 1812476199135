import { FormRecordItem } from '@hiq/crm.types';
import { FormRecordResult } from './useFormQuery.interfaces';
import { FormFragment, FormItemFragment } from '../../fragments';

export function buildRecursiveStructure(form: FormFragment): FormRecordResult {
  const rootItems = form.items.filter((item) => item.root);
  const leafItemsIndexed: { [referenceId: string]: FormItemFragment } = form.items
    .filter((item) => !item.root)
    .reduce((reduction, current) => ({
      ...reduction,
      [current.internalId]: current,
    }), {});

  const updatedRootItems = rootItems.map(updateChildren);
  return {
    ...form,
    items: updatedRootItems,
  };

  function updateChildren(item: FormItemFragment): FormRecordItem {
    return {
      ...item,
      children: item.children.map((id) => updateChildren(leafItemsIndexed[id])),
    };
  }
}
