import { gql } from 'apollo-boost';
import { useMutationCustom } from '../useMutationCustom';
import { CUSTOMER_FRAGMENT } from '../../fragments/customer';
import {
  SendCommunicationMutationResponse,
  SendCommunicationMutationVariables,
} from './useSendCommunicationMutation.interfaces';

const SEND_COMMUNICATION_MUTATION = gql`
  mutation SendCommunication($customerId: ID!, $options: SendCommunicationOptions) {
    sendCommunication(customerId: $customerId, options: $options) {
      code
      success
      message
      timestamp
      customer {
        ...CustomerFragment
      }
    }
  }
  ${CUSTOMER_FRAGMENT}
`;

export function useSendCommunication() {
  return useMutationCustom<SendCommunicationMutationResponse, SendCommunicationMutationVariables>(SEND_COMMUNICATION_MUTATION);
}
