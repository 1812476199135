import React from 'react';
import { CompareHeaderProps } from '../TRMQuote.interfaces';
import {
  CompareRow, CompareContainer, CompareData, Bold, Name,
} from './CompareHeader.elements';
import { CompareContext } from '../CompareContext/CompareContext';
import CyclePlans from './CyclePlans';
import {
  EnrollButton, Row, Column, ViewLinkButton, PPEButton,
} from '../TRMQuote.elements';
import { PlanRating } from './PlanRating';
import { PlanSelectionPlan, UserAbValues } from '@hiq/crm.types';
import { SunfirePlanType } from '../../SunfirePlanType';
import { CarrierFilter } from './CarrierFilter';
import { RatingFilter } from './RatingFilter';
import { ResetFilters } from './ResetFilters';
import { YearFilter } from './YearFilter';
import { SmallHeader } from './SmallHeader';
import { SendPPE } from '../SendPPE';
import { useToggle } from '../../../CheckMyHealthRecord/CheckMyHealthRecordDataGrid/useToggle';
import { PrimaryButton } from '../../../inputs';
import { useCurrentUserQuery, useFetchBionicPlansHtmlQuery } from '../../../../../graphql';
import { useActiveLeadId } from '../../../../routers';

export const CompareHeader = ({
  onEnroll, smallHeader, scrollToTop,
}: CompareHeaderProps) => {
  const {
    currentPlan,
    currentComparisonPlan,
    nextPlan,
    totalRecommendedPlans,
    currentComparisonPlanIndex,
    previousPlan,
    totalPlans,
    zipCode,
    countyCode,
    baseUrl,
    planType,
    emitPlanComparisonEvent,
  } = React.useContext(CompareContext);

  const [open, togglePPE] = useToggle();
  const leadId = useActiveLeadId();
  const { data: currentUser } = useCurrentUserQuery();

  const [loadHtml, loadHtmlData] = useFetchBionicPlansHtmlQuery(leadId);

  const showPlansHtml = React.useMemo(
    () => currentUser?.currentUser?.abValue === UserAbValues.COST_SHARE,
    [currentUser],
  );

  React.useEffect(() => {
    if (totalPlans && showPlansHtml) loadHtml();
  }, [totalPlans, showPlansHtml]);

  const openHtml = React.useCallback(async () => {
    if (!loadHtmlData?.data?.fetchBionicPlansHtml?.url) return;
    const uri = `data:text/html,${encodeURIComponent(loadHtmlData?.data?.fetchBionicPlansHtml?.url)}`;
    const win = window.open();
    // eslint-disable-next-line max-len
    win?.document?.write(`<iframe src="${uri}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`);
    win.document?.close();
  }, [loadHtmlData]);

  const openSummary = React.useCallback(({ summaryBenefitsUrl, id }: PlanSelectionPlan) => {
    emitPlanComparisonEvent('Opened Summary of Benefits');
    if (!summaryBenefitsUrl || !summaryBenefitsUrl.match(/^https?:\/\//)) {
      // eslint-disable-next-line max-len
      window.open(`${baseUrl}/app/blaze/portal/#/plandetails/benefits/${zipCode}/${countyCode}/${planType}/${id}`, '_blank');
    } else {
      window.open(summaryBenefitsUrl, '_blank');
    }
  }, [zipCode, countyCode, planType, baseUrl, emitPlanComparisonEvent]);

  const openComparisonPlanEvidence = React.useCallback(() => {
    emitPlanComparisonEvent('Opened Evidence of Coverage');
    window.open(currentComparisonPlan?.evidenceOfCoverage, '_blank');
  }, [currentComparisonPlan, emitPlanComparisonEvent]);

  const openCurrentPlanEvidence = React.useCallback(() => {
    emitPlanComparisonEvent('Opened Evidence of Coverage');
    window.open(currentPlan?.evidenceOfCoverage, '_blank');
  }, [currentPlan, emitPlanComparisonEvent]);

  const getKeyFromPlan = React.useCallback((plan: PlanSelectionPlan) => `${plan?.contractId}_${plan?.planId}_${plan?.segmentId}`, []);

  return (
    <>
      {smallHeader && <SmallHeader onEnroll={onEnroll} scrollToTop={scrollToTop} />}
      <CompareContainer>
        <CompareRow>
          <CompareData>
            <Column>
              Filter by
              <SunfirePlanType />
              <CarrierFilter />
              <RatingFilter />
              <YearFilter />
              {showPlansHtml
              && (
              <PrimaryButton
                onClick={openHtml}
                disabled={!loadHtmlData?.data?.fetchBionicPlansHtml?.url}
              >
                Generate HTML
              </PrimaryButton>
              )}
            </Column>
          </CompareData>
          <CompareData>
            <Column paddingTop="30px">
              <Bold>
                Current Plan
              </Bold>
              <Name>
                {currentPlan?.name ?? 'No current plan selected'}
              </Name>
              {currentPlan && (
                <Name>
                  Plan ID:
                  {' '}
                  {getKeyFromPlan(currentPlan)}
                </Name>
              )}
              {currentPlan && (
                <Name>
                  Plan Year:
                  {' '}
                  {currentPlan?.planYear}
                </Name>
              )}
              <PlanRating rating={currentPlan?.rating} useEmptyRating={!!currentComparisonPlan?.rating} />
              {!!currentPlan?.id && (
                <ViewLinkButton onClick={() => openSummary(currentPlan)}>
                  Summary of Benefits
                </ViewLinkButton>
              )}
              {!!currentPlan?.id && (
                <ViewLinkButton disabled={!currentPlan?.evidenceOfCoverage} onClick={openCurrentPlanEvidence}>
                  Evidence of Coverage
                </ViewLinkButton>
              )}
              {currentPlan ? <b>{`Ranked #${currentPlan?.hiqRank} / ${totalPlans}`}</b> : null}
            </Column>
          </CompareData>
          <CompareData>
            {totalRecommendedPlans > 0 && (
              <Row justifyContent="space-between" alignItems="flex-start">
                <Column width="70%">
                  <Bold>
                    Precision Medicare Recommendation
                  </Bold>
                  <Name>
                    {currentComparisonPlan?.name}
                  </Name>
                  <Name>
                    Plan ID:
                    {' '}
                    {getKeyFromPlan(currentComparisonPlan)}
                  </Name>
                  <Name>
                    Plan Year:
                    {' '}
                    {currentComparisonPlan?.planYear}
                  </Name>
                  <PlanRating rating={currentComparisonPlan?.rating} />
                  {!!currentComparisonPlan?.id && (
                  <ViewLinkButton onClick={() => openSummary(currentComparisonPlan)}>
                    Summary of Benefits
                  </ViewLinkButton>
                  )}
                  {!!currentComparisonPlan?.id && (
                  <ViewLinkButton disabled={!currentComparisonPlan?.evidenceOfCoverage} onClick={openComparisonPlanEvidence}>
                    Evidence of Coverage
                  </ViewLinkButton>
                  )}
                  <b>{`Ranked #${currentComparisonPlan?.hiqRank} / ${totalPlans}`}</b>
                  <Row width="100%" justifyContent="space-between">
                    <EnrollButton onClick={onEnroll}>Enroll</EnrollButton>
                    <PPEButton onClick={togglePPE}>Send PPE</PPEButton>
                  </Row>
                </Column>
                <Column justifyItems="flex-end">
                  <CyclePlans onClick={nextPlan} disabled={currentComparisonPlanIndex === totalRecommendedPlans - 1}>
                    Next Plan
                  </CyclePlans>
                  <CyclePlans onClick={previousPlan} disabled={currentComparisonPlanIndex === 0}>
                    Previous Plan
                  </CyclePlans>
                  {`${currentComparisonPlanIndex + 1} of ${totalRecommendedPlans}`}
                </Column>
              </Row>
            )}
            {totalRecommendedPlans === 0 && <ResetFilters />}
          </CompareData>
        </CompareRow>
      </CompareContainer>
      <SendPPE open={open} onClose={togglePPE} />
    </>
  );
};
