import * as React from 'react';
import { QuoteRatesProps } from './QuoteRates.interfaces';
import {
  QuoteRatesContainer,
  QuoteRateAmount,
} from './QuoteRates.elements';
import { formatCurrencyTruncated } from '@healthiqeng/core.util.format-currency-truncated';
import { isPresent } from '@healthiqeng/core.util.is-present';

export const QuoteRates: React.FunctionComponent<QuoteRatesProps> = ({
  rateMonthly,
  rateAnnually,
  rateQuarterly,
  rateSemiAnnually,
  labels = {},
}) => {
  const formatRateLabel = (rate: number, label: string) => `${formatCurrencyTruncated(rate, false, false)}${label}`;
  const formattedLabels = {
    monthly: formatRateLabel(rateMonthly, labels.monthly ?? '/month'),
    quarterly: formatRateLabel(rateQuarterly, labels.quarterly ?? '/quarter'),
    semiAnnually: formatRateLabel(rateSemiAnnually, labels.semiAnnually ?? ' semi-annually'),
    annually: formatRateLabel(rateAnnually, labels.annual ?? '/year'),
  };
  return (
    <QuoteRatesContainer>
      <QuoteRateAmount large>
        { isPresent(rateMonthly) && formattedLabels.monthly}
      </QuoteRateAmount>
      <QuoteRateAmount>
        { isPresent(rateAnnually) && formattedLabels.annually}
      </QuoteRateAmount>
      <QuoteRateAmount>
        { isPresent(rateQuarterly) && formattedLabels.quarterly}
      </QuoteRateAmount>
      <QuoteRateAmount>
        { isPresent(rateSemiAnnually) && formattedLabels.semiAnnually}
      </QuoteRateAmount>
    </QuoteRatesContainer>
  );
};
