/* eslint react/no-danger: 0 */
import * as React from 'react';
import { FollowUpDescriptionProps } from './FollowUpDescription.interfaces';
import { formatFollowUpDate } from '../../../../util';

export const FollowUpDescription: React.FunctionComponent<FollowUpDescriptionProps> = ({
  description,
  start,
  end,
}) => (
  <>
    { (start && end) && (
      <>
        { formatFollowUpDate(start, end) }
        <br />
      </>
    ) }
    <span dangerouslySetInnerHTML={{ __html: description }} />
  </>
);
