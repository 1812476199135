import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';
import { Uuid } from '@hiq/crm.types';
import { GetLatestDoctorsQueryData, GetLatestDoctorsQueryVariables } from './useGetLatestDoctorsQuery.interface';

const CMHR_QUERY = gql`
  query GetLatestDoctors($customerId: ID!) {
    latestDoctors(customerId: $customerId) {
      complete
      doctors {
        userDoctorId
        name
        addressLine1
        addressLine2
        phone
        city
        state
        lastVisit
      }
    }
  }
`;

export function useGetLatestDoctorsQuery(customerId: Uuid) {
  return useLazyQuery<GetLatestDoctorsQueryData, GetLatestDoctorsQueryVariables>(CMHR_QUERY, {
    variables: { customerId },
    fetchPolicy: 'no-cache',
  });
}
