import React from 'react';
import { Row } from '../../TRMQuote.elements';
import { PlanStarProps } from '../../TRMQuote.interfaces';
import { EmptyRating, PlanStarIcon, Text } from './PlanRating.elements';

export const PlanRating = ({ rating, useEmptyRating }: PlanStarProps) => {
  if (!rating && useEmptyRating) return <EmptyRating>No available rating for this plan</EmptyRating>;
  if (!rating) return null;
  return (
    <Row alignItems="center">
      <PlanStarIcon type="star" />
      <Text>
        {`${rating}/5`}
      </Text>
    </Row>
  );
};
