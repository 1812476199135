import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const InventoryHeader = styled('h1')(css``);

export const InventoryHelpText = styled('p')(css``);

export const CredsContainer = styled('div')(css`
  margin: 10px 0;
`);
