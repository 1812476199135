import { IEventBusEvent } from '@healthiqeng/core.services.event-bus';
import { useReducer } from 'react';
import {
  FormAnswersAction,
  FormAnswersActionType,
  FormAnswersChangeAction,
  FormAnswersPersistAction,
  FormAnswersRefreshAction,
  FormAnswersResetAction,
  FormAnswersState,
  UseFormAnswersReducerHook,
} from './useFormAnswersReducer.interfaces';
import { FormAnswers } from '@hiq/crm.types';
import { eventBus } from '../../../services';
import {
  MedicareApplicationTypeChangeEvent, SunfireV2DataChangeEvent, VariationChangeEvent, ZipOrStateChangeEvent,
} from '../../../events';

export const useFormAnswersReducer: UseFormAnswersReducerHook = (answers) => {
  const [answersState, dispatch] = useReducer(formAnswersReducer, initState(answers));
  return [answersState.answers, dispatch];
};

function initState(answers: FormAnswers): FormAnswersState {
  return {
    answers: { ...answers },
    dirtyKeys: new Set<string>(),
  };
}

function formAnswersReducer(state: FormAnswersState, action: FormAnswersAction) {
  if (isChangeAction(action)) {
    return formAnswersChangeReducer(state, action);
  }

  if (isRefreshAction(action)) {
    return formAnswersRefreshReducer(state, action);
  }

  if (isPersistAction(action)) {
    return formAnswersPersistReducer(state, action);
  }

  if (isResetAction(action)) {
    return formAnswersResetReducer(state, action);
  }

  return state;
}

function formAnswersChangeReducer(state: FormAnswersState, action: FormAnswersChangeAction) {
  const newState = {
    answers: {
      ...state.answers,
      [action.key]: action.value,
    },
    dirtyKeys: new Set<string>([...state.dirtyKeys, action.key]),
  };

  if (action.saveAnswers) {
    const isVariationChange = hasVariationChange(newState);
    const result = action.saveAnswers(newState, isVariationChange || !action.debounce);

    emitEventIfChange(newState, isVariationChange, new VariationChangeEvent(), result);
    emitEventIfChange(newState, hasSunfireV2Change, new SunfireV2DataChangeEvent(), result);
    emitEventIfChange(newState, hasZipOrStateChange, new ZipOrStateChangeEvent(), result);
    emitEventIfChange(newState, hasMedicareApplicationTypeStateChange, new MedicareApplicationTypeChangeEvent(), result);

    // Filters "call" keys since we don't fetch them and thus they never get cleaned up
    const filteredDirtyKeys = [...newState.dirtyKeys].filter((key) => !key?.startsWith('call.'));
    newState.dirtyKeys = new Set<string>(filteredDirtyKeys);
  }
  return newState;
}

function formAnswersRefreshReducer(state: FormAnswersState, action: FormAnswersRefreshAction) {
  const newState = initState(action.answers);
  [...state.dirtyKeys].forEach((key) => {
    const dirtyValue = state.answers[key];
    const refreshValue = newState.answers[key];
    if (isPristine(dirtyValue, refreshValue) || isDeleted(key, state)) return;
    newState.answers[key] = dirtyValue;
    newState.dirtyKeys.add(key);
  });
  return newState;
}

function isPristine(dirtyValue: any, refreshValue: any) {
  if (dirtyValue === refreshValue) return true;
  if (Array.isArray(dirtyValue) && Array.isArray(refreshValue)
    && dirtyValue.every((val, i) => isPristine(val, refreshValue[i]))) return true;
  return false;
}

function formAnswersPersistReducer(state: FormAnswersState, action: FormAnswersPersistAction) {
  action.saveAnswers(state, true);
  return state;
}

function formAnswersResetReducer(state: FormAnswersState, action: FormAnswersResetAction) {
  return initState(action.answers);
}

function isChangeAction(action: FormAnswersAction): action is FormAnswersChangeAction {
  return action.type === FormAnswersActionType.Change;
}

function isRefreshAction(action: FormAnswersAction): action is FormAnswersRefreshAction {
  return action.type === FormAnswersActionType.Refresh;
}

function isPersistAction(action: FormAnswersAction): action is FormAnswersPersistAction {
  return action.type === FormAnswersActionType.Persist;
}

function isResetAction(action: FormAnswersAction): action is FormAnswersResetAction {
  return action.type === FormAnswersActionType.Reset;
}

function emitEventIfChange(
  newState: FormAnswersState,
  condition: ((newState: FormAnswersState) => boolean) | boolean,
  event: IEventBusEvent,
  saveAnswersResult: Promise<unknown> | unknown,
) {
  if (typeof condition === 'boolean' ? condition : condition(newState)) {
    if (isPromise(saveAnswersResult)) {
      saveAnswersResult.then(() => {
        eventBus.emit(event);
      });
    } else {
      eventBus.emit(event);
    }
  }
}

function hasVariationChange(newState: FormAnswersState) {
  return newState.dirtyKeys.has('lead.productType')
    || hasZipOrStateChange(newState)
    || newState.dirtyKeys.has('customer.tcpaConsent')
    || newState.dirtyKeys.has('lead.medicarePlanTransition')
    || newState.dirtyKeys.has('lead.permissionToDiscussMaOrPdp')
    || newState.dirtyKeys.has('lead.digitalProductHipaaConsent')
    || newState.dirtyKeys.has('customer.person.hipaaConsentApproved')
    || newState.dirtyKeys.has('customer.person.hipaaConsentDatetime');
}

function hasZipOrStateChange(newState: FormAnswersState) {
  return newState.dirtyKeys.has('customer.person.homeAddress.zip')
    || newState.dirtyKeys.has('customer.person.homeAddress.state.code');
}

function hasMedicareApplicationTypeStateChange(newState: FormAnswersState) {
  return newState.dirtyKeys.has('lead.applicationType');
}

function hasSunfireV2Change(newState: FormAnswersState) {
  const watchList = [
    'lead.sunfire.v2.selectedPlan',
    'lead.sunfire.v2.currentlyEnrolledV2',
    'lead.sunfire.v2.currentlyEnrolledPlanType',
    'lead.sunfire.v2.medicalCareFrequency',
    'lead.sunfire.v2.medicareType',
    'lead.sunfire.v2.lisCode',
    'lead.sunfire.v2.lowIncomeSubsidyCodePercentage',
    'lead.sunfire.v2.lowIncomeSubsidyCode',
    'lead.sunfire.v2.benefitPreferences',
    'lead.sunfire.v2.countyCode',
  ];
  return watchList.some((key) => newState.dirtyKeys.has(key));
}

function isPromise<T = any>(candidate: any): candidate is Promise<T> {
  return candidate && typeof candidate?.then === 'function';
}

function isDeleted(key: string, state: FormAnswersState): boolean {
  if (!/\.\d+\./.test(key)) return false; // not an array key
  const prefix = key.match(/^(.*)\.\d+\..*/)?.[1];
  if (!prefix) return false;
  if (state.answers[`${prefix}.__deleted`]) return true;
  return false;
}
