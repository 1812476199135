import { gql } from 'apollo-boost';
import { MutationCustomReturn, useMutationCustom } from '../useMutationCustom';
import { LogUserInteractionMutationResponse, LogUserInteractionMutationVariables } from './useLogUserInteractionMutation.interfaces';

export const LOG_USER_INTERACTION_MUTATION = gql`
  mutation LogUserInteraction($interaction: UserInteractionInput!) {
    logUserInteraction(interaction: $interaction) {
        success
        code
        message
        timestamp
        type
    }
  }
`;

export function useLogUserInteractionMutation(): MutationCustomReturn<
LogUserInteractionMutationResponse,
LogUserInteractionMutationVariables> {
  return useMutationCustom<LogUserInteractionMutationResponse, LogUserInteractionMutationVariables>(LOG_USER_INTERACTION_MUTATION);
}
