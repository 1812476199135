import * as React from 'react';
import { BlockQuoteProps } from './BlockQuote.interfaces';
import {
  BlockQuoteContainer,
  BlockQuoteIcon,
  BlockQuoteIconContainer,
  BlockQuoteText,
} from './BlockQuote.elements';

export const BlockQuote: React.FunctionComponent<BlockQuoteProps> = ({
  children,
}) => (
  <BlockQuoteContainer>
    <BlockQuoteIconContainer>
      <BlockQuoteIcon src={require('../assets/quote-left.svg')} alt="quotes icon" />
    </BlockQuoteIconContainer>
    <BlockQuoteText>
      { children }
    </BlockQuoteText>
  </BlockQuoteContainer>
);
