import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { logger } from '../../../services';
import { VariationChangeEvent, useEventListener } from '../../../events';
import {
  FormAnswersByApplicationSectionQueryData,
  FormAnswersByApplicationSectionQueryVariables,
} from './useFormAnswersByApplicationSectionQuery.interfaces';

export const FORM_ANSWERS_BY_APPLICATION_SECTION_QUERY = gql`
    query formAnswersByApplicationSectionQuery($leadId: ID!, $referenceId: String!) {
      applicationSectionFormAnswers(leadId: $leadId, referenceId: $referenceId) {
        answers
      }
    }
`;

export function useFormAnswersByApplicationSectionQuery(leadId: string, referenceId: string): Record<string, any> {
  const result = useQuery<FormAnswersByApplicationSectionQueryData, FormAnswersByApplicationSectionQueryVariables>(
    FORM_ANSWERS_BY_APPLICATION_SECTION_QUERY,
    {
      variables: {
        leadId,
        referenceId,
      },
    },
  );

  const { data, refetch } = result;

  useEventListener(VariationChangeEvent, () => {
    refetch().catch((err) => logger.crit(err));
  }, [data]);

  return result?.data?.applicationSectionFormAnswers?.answers;
}
