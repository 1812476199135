import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../themes';

export const CustomerSummaryContainer = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  padding: 0 10px;
  background-color: ${theme.color.grayBackground};
  overflow: hidden;
  overflow-y: auto;
`);
