import styled from '@emotion/styled';
import { Card } from '@material-ui/core';

export const QuestionBlock = styled(Card)`
  padding: 10px;
  margin: 5px 0;
`;

export const QuestionContainer = styled.div`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  background-color: #F3F5F7;
`;
