import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const MedicareSupplementNoQuoteContainer = styled('div')(css``);
export const MedicareSupplementNoQuoteHeader = styled('p')(css`
  font-weight: 600;
  margin: 0;
`);
export const MedicareSupplementNoQuoteList = styled('ul')(css`
  margin: 5px 0;
`);
export const MedicareSupplementNoQuoteListItem = styled('li')(css``);
