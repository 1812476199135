import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { DetailsDialogProps } from './DetailsDialog.interfaces';
import { IconButton, DialogTitle, Dialog } from '@material-ui/core';
import { DetailsContent, DetailsTitleContainer } from './DetailsDialog.elements';

export const DetailsDialog = React.memo(({
  open,
  handleClose,
  title,
  message,
}: DetailsDialogProps) => (
  <Dialog open={open} onClose={handleClose}>
    <DetailsTitleContainer>
      <DialogTitle>
        {title}
        {' '}
      </DialogTitle>
      <IconButton aria-label="close" onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    </DetailsTitleContainer>

    <DetailsContent>
      <span>{message}</span>
    </DetailsContent>
  </Dialog>
));
