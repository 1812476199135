import * as React from 'react';
import { useLeadQuery } from '../../../../graphql/queries/useLeadQuery';
import { QueryLoader } from '../../../common/QueryLoader';
import { PropertyAndCasualtyPreConversionLead } from './PropertyAndCasualtyPreConversionLead';
import { PropertyAndCasualtyPostConversionLead } from './PropertyAndCasualtyPostConversionLead';
import { ComponentName } from '../../../common/Page/PageLoadDetector';

export const PropertyAndCasualtyLead: React.FunctionComponent = () => {
  const { data, loading, error } = useLeadQuery();

  return (
    <QueryLoader loading={loading} error={error} componentName={ComponentName.PropertyAndCasualtyLead}>
      <>
        { !data?.isSold && (
          <PropertyAndCasualtyPreConversionLead />
        ) }
        { data?.isSold && (
          <PropertyAndCasualtyPostConversionLead />
        ) }
      </>
    </QueryLoader>
  );
};
