import { Spinner } from '@healthiqeng/core.ui/src/index';
import * as React from 'react';
import { PageLoaderContainer } from './PageLoader.elements';
import { IPageLoaderProps } from './PageLoader.interfaces';

export class PageLoader extends React.Component<IPageLoaderProps> {
  public render() {
    return (
      <PageLoaderContainer>
        <Spinner />
      </PageLoaderContainer>
    );
  }
}
