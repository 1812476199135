/* eslint react/require-default-props: 0 */

import { CompareProps } from '../TRMQuote.interfaces';
import {
  CompareData, CompareDataBold, CompareRow, Row, ViewLinkButton,
} from '../TRMQuote.elements';
import React from 'react';
import { formatCurrencyTruncated } from '@healthiqeng/core.ui';
import { NoPlanPlaceHolder } from '../NoPlanPlaceHolder';

interface ComparePriceInterface extends CompareProps {
  comparisonLink?: string
  currentLink?: string
  allowNegative?: boolean
  avoidFormat?: boolean
  avoidHighlight?: boolean
}

export const ComparePrice = ({
  benefitName, currentValue, comparisonValue, currentLink, comparisonLink, allowNegative, avoidFormat, avoidHighlight,
}: ComparePriceInterface) => (
  <CompareRow>
    <CompareDataBold>
      {benefitName}
    </CompareDataBold>
    <CompareData empty={currentValue === undefined} highlight={!avoidHighlight && currentValue < comparisonValue}>
      <Row justifyContent="space-between">
        {currentValue === undefined ? <NoPlanPlaceHolder /> : formatCurrency(currentValue as number, allowNegative, avoidFormat)}
        {(currentValue && comparisonLink)
          ? <ViewLinkButton onClick={() => window.open(currentLink, '_blank')}>Schedule</ViewLinkButton> : undefined}
      </Row>
    </CompareData>
    <CompareData highlight={!avoidHighlight && comparisonValue < currentValue}>
      <Row justifyContent="space-between">
        {formatCurrency(comparisonValue as number, allowNegative, avoidFormat)}
        {comparisonLink && <ViewLinkButton onClick={() => window.open(comparisonLink, '_blank')}>Schedule</ViewLinkButton>}
      </Row>
    </CompareData>
  </CompareRow>
);

function formatCurrency(currency: number, allowNegative = false, avoidFormat = false) {
  if (avoidFormat) return currency;
  if (currency < 0 && !allowNegative) return 'N/A';
  const formatted = formatCurrencyTruncated(currency, false, false);
  if (!formatted) return 'N/A';
  return formatted;
}
