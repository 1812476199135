import React, { useEffect, useRef, useState } from 'react';
import { SunfireDrug, useUpsertOverlayMutation } from '../../../../../../graphql';
import { useActiveLeadId, useCustomerId } from '../../../../../routers';
import {
  SunfireDrugDosage, SunfireDosagePackage, SunfireMedication, HealthRecordDrug,
} from '@hiq/crm.types';
import { AddDrugPanelProps } from './AddDrugPanel.interfaces';
import { AddDrugPanelContainer } from './AddDrugPanel.elements';
import {
  ContentWrapper,
  CTA,
  PanelInnerWrapper,
  RemoveItemButton,
  SaveItemButton,
  SectionTitle,
} from '../../CheckMyHealthRecordDataGrid.elements';
import { DrugSearchPanel } from '../DrugSearchPanel';
import { DosageSearchPanel } from '../DosageSearchPanel';
import { FillQuantitySelection } from '../FillQuantitySelection';
import { PackageSelection } from '../PackageSelection';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { emitAnalyticsEventNoLimit } from '../../../../../../events/emitAnalyticsEvent';

export const AddDrugPanel = ({
  setPanelClosed,
  selectedOverlayId,
  dosages,
  selectedDrug,
  selectedDosage,
  selectedPackage,
  selectedFillQuantity,
  selectedDaysOfSupply,
}: AddDrugPanelProps) => {
  const panelRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    panelRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const [drug, setDrug] = useState<SunfireMedication>();
  const [dosage, setDosage] = useState<SunfireDrugDosage>();
  const [dosagePackage, setDosagePackage] = useState<SunfireDosagePackage>();
  const [refillQuantity, setRefillQuantity] = useState<number>();
  const [daysOfSupply, setDaysOfSupply] = useState<number>();

  useEffect(() => {
    setDrug(selectedDrug as unknown as SunfireMedication);
    setDosage(selectedDosage);
    setDosagePackage(selectedPackage);
    setRefillQuantity(selectedFillQuantity);
    setDaysOfSupply(selectedDaysOfSupply);
  }, [selectedDrug, selectedDosage, selectedPackage, selectedFillQuantity, selectedDaysOfSupply]);

  const [upsertOverlayDrug] = useUpsertOverlayMutation();
  const customerId = useCustomerId();
  const leadId = useActiveLeadId();
  const onCancel = () => setPanelClosed(true);

  const onSave = () => {
    const drugToInsert = {
      name: dosage.name,
      strength: dosage.strength.toString(),
      fillQuantity: refillQuantity,
      isGeneric: dosage.isGeneric,
      form: dosage.form,
      drugName: drug.name,
      daysOfSupply,
      fillsLastYear: undefined as number,
      pharmacyName: '',
    };
    const healthRecordDrug = { ...drugToInsert, ndc: dosagePackage?.proxyNationalDrugCode } as HealthRecordDrug;
    const sunfireDrug = { ...drugToInsert, id: dosage?.id } as SunfireDrug;
    if (shouldDisplayPackages) {
      upsertOverlayDrug({
        input: {
          id: selectedOverlayId,
          customerId,
          externalId: dosagePackage?.proxyNationalDrugCode,
          externalType: 'HealthRecordDrug',
          data: healthRecordDrug,
        },
      });
    } else {
      upsertOverlayDrug({
        input: {
          id: selectedOverlayId,
          customerId,
          externalId: dosage.id,
          externalType: 'SunfireDrug',
          data: sunfireDrug,
        },
      });
    }

    emitAnalyticsEventNoLimit('New Drug Added', {
      ...healthRecordDrug, id: dosage.id, customerId, leadId,
    });
    setPanelClosed(false);
  };

  const handleDrugSelected = (drug: SunfireMedication) => {
    setDrug(drug);
    setDosagePackage(undefined);
    setDosage(undefined);
  };
  const handleDosageSelected = (dosage?: SunfireDrugDosage) => {
    setDosage(dosage);
    if (dosage) {
      setRefillQuantity(dosage.quantity);
      setDaysOfSupply(dosage.defaultFrequency);
    } else {
      setDosagePackage(undefined);
    }
  };
  const handlePackageSelected = (dosagePackage?: SunfireDosagePackage) => setDosagePackage(dosagePackage);
  const handleOnRefillQuantityChanged = (value: number) => setRefillQuantity(value);
  const handleDaysOfSupplyChanged = React.useCallback((value: number) => setDaysOfSupply(value), [setDaysOfSupply]);

  const shouldDisplayPackages = drug && dosage && dosage.packages.length > 0;
  const isSaveDisabled = !drug || !dosage || !refillQuantity || !daysOfSupply || (shouldDisplayPackages && !dosagePackage);

  return (
    <AddDrugPanelContainer ref={panelRef}>
      <SectionTitle>
        {selectedDrug ? 'Edit Prescriptions' : 'Add Prescriptions'}
      </SectionTitle>
      <PanelInnerWrapper>
        <ContentWrapper>
          <h2 style={{ alignSelf: 'flex-start' }}>Medications</h2>
          <DrugSearchPanel onDrugSelected={handleDrugSelected} defaultDrugName={selectedDrug?.name} readOnly={!!selectedDrug} />
          {drug && <DosageSearchPanel onDosageSelected={handleDosageSelected} drug={drug} dosage={dosage} dosages={dosages} />}
          {shouldDisplayPackages && (
            <PackageSelection
              packages={dosage.packages}
              onPackageSelected={handlePackageSelected}
              dosage={dosage}
              dosagePackage={dosagePackage}
            />
          )}
          {drug && dosage && (!shouldDisplayPackages || (shouldDisplayPackages && dosagePackage)) && (
            <FillQuantitySelection
              onRefillQuantityChanged={handleOnRefillQuantityChanged}
              refillQuantity={refillQuantity}
              daysOfSupply={daysOfSupply}
              onDaysOfSupplyChanged={handleDaysOfSupplyChanged}
            />
          )}
        </ContentWrapper>
      </PanelInnerWrapper>

      <CTA>
        <RemoveItemButton variant="contained" startIcon={<CloseIcon />} onClick={onCancel}>
          Cancel
        </RemoveItemButton>
        <SaveItemButton variant="contained" startIcon={<CheckIcon />} onClick={onSave} disabled={isSaveDisabled}>
          Save
        </SaveItemButton>
      </CTA>
    </AddDrugPanelContainer>
  );
};
