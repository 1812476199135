import React from 'react';
import { Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import { DateTimeInput } from '../../../../common/inputs/DateTimeInput';
import { useScheduleSDRFollowUpMutation } from '../../../../../graphql';
import { ESQFollowUpContainer, ConfirmButton, CancelButton } from './ESQFollowUp.elements';
import { Alert, Autocomplete } from '@material-ui/lab';
import { TextField } from '../../../../common/inputs/TextInput/TextInput.elements';
import { useActiveLeadId } from '../../../../routers';

type EsqFollowUpProps = {
  open: boolean;
  onClose: () => void;
};

const TIMEZONE_OPTIONS = [
  {
    label: 'US/Alaska',
    value: 'US/Alaska',
  },
  {
    label: 'US/Central',
    value: 'US/Central',
  },
  {
    label: 'US/Eastern',
    value: 'US/Eastern',
  },
  {
    label: 'US/Hawaii',
    value: 'US/Hawaii',
  },
  {
    label: 'US/Mountain',
    value: 'US/Mountain',
  },
  {
    label: 'US/Pacific',
    value: 'US/Pacific',
  },
];

export const EsqFollowUp = ({ open, onClose }:EsqFollowUpProps) => {
  const leadId = useActiveLeadId();

  const [date, setDate] = React.useState(null);
  const [timezone, setTimezone] = React.useState(null);
  const [scheduleSDRFollowUp, scheduleSDRFollowUpData] = useScheduleSDRFollowUpMutation();

  const handleTimezoneChange = React.useCallback((event, newValue) => {
    setTimezone(newValue);
  }, []);

  const scheduleFollowUp = React.useCallback(() => {
    scheduleSDRFollowUp({
      date, timezone: timezone.value, leadId,
    });
    onClose();
  }, [date, timezone, leadId]);

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Schedule a followup
        </DialogTitle>
        <ESQFollowUpContainer>
          <DateTimeInput
            value={date}
            onChange={setDate}
            label="Follow up date"
            fullWidth
            variant="outlined"
            size="small"
          />
          <Autocomplete
            value={timezone}
            onChange={handleTimezoneChange}
            options={TIMEZONE_OPTIONS}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(option, value) => option.value === value.value}
            fullWidth
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Timezone"
                size="small"
                fullWidth
                variant="outlined"
              />
            )}
          />
        </ESQFollowUpContainer>
        <DialogActions>
          <CancelButton onClick={onClose}>
            Close
          </CancelButton>
          <ConfirmButton onClick={scheduleFollowUp} disabled={!timezone || !date}>
            Schedule
          </ConfirmButton>
        </DialogActions>
      </Dialog>
      {scheduleSDRFollowUpData?.data && (
        <Alert severity={scheduleSDRFollowUpData?.data?.scheduleSDRFollowUp?.success ? 'success' : 'error'}>
          {scheduleSDRFollowUpData?.data?.scheduleSDRFollowUp?.success
            ? 'Successfully scheduled follow up' : scheduleSDRFollowUpData?.data?.scheduleSDRFollowUp?.message
              ?? 'Could not schedule follow up'}
        </Alert>
)}
    </>

  );
};
