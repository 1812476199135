import { gql } from 'apollo-boost';

export const QUESTION_FLOW_FRAGMENT = gql`
  fragment QuestionFlowFragment on QuestionFlow {
    title
    description
    type
    body
    referenceId
    leadId
  }
`;
