import { UserProfile } from '@hiq/crm.types';

export function isSalesDevelopmentRep(profile: UserProfile): boolean {
  return [
    UserProfile.SalesDevelopmentRep,
    UserProfile.SalesDevelopmentRepPM,
    UserProfile.OffshoreSalesDevelopmentRep,
    UserProfile.OffshorePresaleSDR,
  ].includes(profile);
}
