import React from 'react';
import { useLocalFormAnswersMutation, useLocalFormAnswersQuery } from '../../../../graphql';
import { useCustomerId } from '../../../routers';
import { SunfireV2DataChangeEvent, useEventListener } from '../../../../events';

export const useDirtySunfireData = (): [boolean, (isDirty: boolean) => void] => {
  const customerId = useCustomerId();
  const { data: localFormAnswersData } = useLocalFormAnswersQuery();
  const localAnswers = localFormAnswersData.localFormAnswers;
  const [saveLocalAnswers] = useLocalFormAnswersMutation();
  const answerKey = `${customerId}-sunfire-data-dirty`;

  const isDirty = localAnswers[answerKey] !== false;
  const setIsDirty = React.useCallback((isDirty) => {
    saveLocalAnswers({
      answers: {
        ...localAnswers,
        [answerKey]: isDirty,
      },
    });
  }, [localAnswers, saveLocalAnswers]);

  useEventListener(SunfireV2DataChangeEvent, () => {
    setIsDirty(true);
  }, [setIsDirty]);

  return [isDirty, setIsDirty];
};
