import { css } from '@emotion/core';
import { COLOR } from '../constants';

/* Positional Normalizes */
export const hiqNormalizePosition = css`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
`;

export const hiqNormalizeDiv = hiqNormalizePosition;
export const hiqNormalizeArticle = hiqNormalizePosition;
export const hiqNormalizeHeader = hiqNormalizePosition;
export const hiqNormalizeFooter = hiqNormalizePosition;
export const hiqNormalizeUl = hiqNormalizePosition;
export const hiqNormalizeOl = hiqNormalizePosition;
export const hiqNormalizeForm = hiqNormalizePosition;

/* Text Normalizes */
export const hiqNormalizeText = css`
  ${hiqNormalizePosition};
  line-height: 1.15;
  text-transform: none;
`;

export const hiqNormalizeP = css`
  ${hiqNormalizeText};
  font-size: 16px;
`;

export const hiqNormalizeLi = hiqNormalizeP;
export const hiqNormalizeH1 = hiqNormalizeText;
export const hiqNormalizeH2 = hiqNormalizeText;
export const hiqNormalizeH3 = hiqNormalizeText;
export const hiqNormalizeH4 = hiqNormalizeText;
export const hiqNormalizeH5 = hiqNormalizeText;
export const hiqNormalizeH6 = hiqNormalizeText;

/* Embedded Content Resets */
export const hiqNormalizeImg = hiqNormalizePosition;
export const hiqNormalizeIframe = hiqNormalizePosition;

export const hiqNormalizeClickable = css`
  ${hiqNormalizeText};
  cursor: pointer;
  text-decoration: none;
  outline: none;
  user-select: none;
  appearance: none;
  touch-action: manipulation;
  white-space: nowrap;
  box-shadow: none;
  border: 0;

  transition: 0.3s;

  &:hover,
  &:active {
    outline: none;
  }

  &:focus {
    outline: -webkit-focus-ring-color auto 5px;
    outline-offset: -2px
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

export const hiqNormalizeA = css`
  ${hiqNormalizeText};
  ${hiqNormalizeClickable};
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: transparent;

  &:visited,
  &:hover,
  &:active {
    opacity: 0.7;
  }
`;

export const hiqNormalizeSpan = css`
  ${hiqNormalizeText};
`;

export const hiqNormalizeButton = css`
  ${hiqNormalizeText};
  ${hiqNormalizeClickable};
`;

export const hiqNormalizeInput = css`
  appearance: none;
  ::placeholder {
    line-height: normal;
  }
`;
