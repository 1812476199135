import React from 'react';
import {
  CollapsedColumnExpandButton,
  CollapsedColumnText, CollapsedColumnTextArrows, CollapsedColumnTextWrapper,
  CollapsedColumnWrapper,
  CollapseRow, CollapseRowButton,
} from './CollapsedResizableColumn.elements';
import { CollapsedResizableColumnProps } from './CollapsedResizableColumn.interfaces';

const COLLAPSE_WIDTH = 60;

export const CollapsedResizableColumn: React.FunctionComponent<CollapsedResizableColumnProps> = ({
  width,
  handleCollapse,
  columnIndex,
  columnContents,
}) => (
  <>
    {
        parseWidth(width) > COLLAPSE_WIDTH ? (
          <>
            {columnContents[Math.floor(columnIndex / 2)]}
            <CollapseRow onClick={() => handleCollapse(columnIndex)}>
              <CollapseRowButton>
                {'> < Collapse'}
              </CollapseRowButton>
            </CollapseRow>
          </>
        ) : (
          <CollapsedColumnWrapper onClick={() => handleCollapse(columnIndex)}>
            <CollapsedColumnExpandButton>
              <CollapsedColumnTextWrapper>
                <CollapsedColumnText>
                  Expand
                </CollapsedColumnText>
                <CollapsedColumnTextArrows>
                  {'< >'}
                </CollapsedColumnTextArrows>
              </CollapsedColumnTextWrapper>

            </CollapsedColumnExpandButton>
          </CollapsedColumnWrapper>
        )
      }
  </>
);

function parseWidth(width: string): number {
  return Number.parseFloat(width.replace(/[^0-9.]/g, ''));
}
