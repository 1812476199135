import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { AvertaPeNormal300 } from '@healthiqeng/core.fonts';
import { MQ } from '../constants';
import { resetDiv, resetP } from '../resets';

export const PressLogosWrapper = styled('div')(resetDiv, css`
  padding: 20px 0;
  margin: 0;
`);

export const PressLogosTitle = styled('p')(resetP, css`
  ${AvertaPeNormal300};
  padding-top: 5px;
  padding-bottom: 15px;
  text-align: center;
  font-size: 14px;
  color: white;
`);

export const PressLogosImageWrapper = styled('div')(resetDiv, css`
  height: 50px;
  width: 100%;
  padding-bottom: 5px;
`);

export const PRESS_LOGOS_DESKTOP_URL = require('./assets/desktop-logo-group.png');
export const PRESS_LOGOS_MOBILE_URL = require('./assets/logo-strip-press-release-mobile.png');

export const PressLogosImage = styled('div')(resetDiv, css`
  height: 100%;
  width: 80%;
  margin: 0 auto;
  background-position-x: center;
  background-position-y: center;
  background-size: contain;
  background-repeat: no-repeat;

  ${MQ.DESKTOP} {
    max-width: 700px;
    background-image: url(${PRESS_LOGOS_DESKTOP_URL});
  }
  ${MQ.TABLET} {
    max-width: 100%;
    background-image: url(${PRESS_LOGOS_MOBILE_URL});
  }
  ${MQ.MOBILE} {
    max-width: 100%;
    background-image: url(${PRESS_LOGOS_MOBILE_URL});
  }
  ${MQ.MOBILE_XS} {
    max-width: 100%;
    background-image: url(${PRESS_LOGOS_MOBILE_URL});
  }
`);
