import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';
import { BionicFetchPlansHtmlQueryVariables, BionicFetchPlansHtmlQueryData } from './useBionicFetchPlansHtmlQuery.interfaces';

const FETCH_BIONIC_PLANS_HTML_QUERY = gql`
  query FetchBionicPlansHtml($leadId: LeadId!) {
    fetchBionicPlansHtml(leadId: $leadId) {
     url
     success
    }
  }
`;

export function useFetchBionicPlansHtmlQuery(leadId: string) {
  return useLazyQuery<BionicFetchPlansHtmlQueryData, BionicFetchPlansHtmlQueryVariables>(FETCH_BIONIC_PLANS_HTML_QUERY,
    { variables: { leadId } });
}
