import * as React from 'react';
import moment from 'moment';
import { DateDividerProps } from './DateDivider.interfaces';
import {
  DateDividerContainer,
} from './DateDivider.elements';

export const DateDivider: React.FunctionComponent<DateDividerProps> = ({
  timestamp,
}) => (
  <DateDividerContainer data-content={formatDate(timestamp)} />
);

function formatDate(timestamp: string) {
  const m = moment(timestamp);
  return m.format('dddd, MMM Do YYYY');
}
