import * as React from 'react';
import { QuoteInfoCardProps } from './QuoteInfoCard.interfaces';
import { useActiveLeadId } from '../../routers';
import { FormGenerator, FormGeneratorStyle } from '../FormGenerator';
import { QuoteInfoCardContainer } from './QuoteInfoCard.elements';

export const QuoteInfoCard: React.FunctionComponent<QuoteInfoCardProps> = () => {
  const leadId = useActiveLeadId();

  return (
    <QuoteInfoCardContainer>
      <FormGenerator
        leadId={leadId}
        referenceId="quote-card"
        style={FormGeneratorStyle.ReadOnly}
      />
    </QuoteInfoCardContainer>
  );
};
