import * as React from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import { Sunfire } from '../Sunfire';
import {
  CsgSubmit, UnderwritingSubmit, PaymentModalRadio, PaymentMethodRadio,
} from '../Csg';
import { CustomElementProps } from './CustomElement.interfaces';
import { MedicareSoa } from '../MedicareSoa';
import { MedicareEnrollmentValidation } from '../MedicareEnrollmentValidation';
import { MedicareDSTNotification } from '../MedicareDSTNotification';
import { PncHomeCoverages } from '../PncHomeCoverages';
import { SDRFollowUp } from '../followUp';
import { MedicarePlanTransition } from '../MedicarePlanTransition';
import { MedicareIepNote } from '../MedicareIepNote';
import { MedicareSkipEligibilityButton } from '../MedicareIepSkipEligibilityButton';
import {
  CheckMyHealthRecordDataGrid,
  CheckMyHealthRecordHipaaAcceptedBefore,
  CheckMyHealthRecordHipaaAnswer,
  CheckMyHealthRecordHipaaAnswerPresales,
  CheckMyHealthRecordHipaaConsent, CheckMyHealthRecordHipaaConsentAttest, CheckMyHealthRecordLis,
  CheckMyHealthRecordPlaybackHipaaRecording,
  CheckMyHealthRecordPlaybackHipaaRecordingButton,
  CheckMyHealthRecordVerifyInfo,
  CheckMyHealthRecordVerifyUser,
  CheckMyHealthRecordVerifyUserCarrier,
  CheckMyRecordPullRecordButton,
  CheckMyHealthRecordLockedGrid,
} from '../CheckMyHealthRecord';
import { CurrentDate } from '../CurrentDate';
import { CheckMyHealthRecordVerifyUserCheck } from '../CheckMyHealthRecord/CheckMyHealthRecordVerifyUserCheck/CheckMyHealthRecordVerifyUserCheck';
import { DialerTransferButtons } from '../DialerTransferButtons';
import { MedicareGoToAnalysisSectionButton } from '../MedicareGoToAnalysisSectionButton';
import { CheckMyHealthRecordVerifyUserMarx } from '../CheckMyHealthRecord/CheckMyHealthRecordVerifyUserMarx';
import { SunfireIframe } from '../SunfireIframe';
import { AudienceDynamicScripts } from '../AudienceDynamicScripts';
import { SunfirePlanAutocomplete } from '../SunfirePlanAutocomplete';
import { MedicarePreSaleQualifyingQuestions } from '../MedicarePreSaleQualifyingQuestions';
import { CustomerEmailWithWarning } from '../CustomerEmailWithWarning';
import { MedicarePlanUpdate } from '../MedicarePlanUpdate';
import { AnnualWellnessExamDoctors } from '../AnnualWellnessExamDoctors';
import {
  CheckMyHealthRecordVerifyUserMarxNewScript,
} from '../CheckMyHealthRecord/CheckMyHealthRecordVerifyUserMarxNewScript';
import { MedicareRetentionSpecialistHRA } from '../MedicareRetentionSpecialistHRA';
import SunfireCounty from '../SunfireCounty/SunfireCounty';
import { CheckMyHealthRecordHipaaConsentApprove } from '../CheckMyHealthRecord/CheckMyHealthRecordHipaaConsentApprove';
import { RandomDateFollowup } from '../RandomDateFollowup';
import { MedicareRetentionSpecialistAetnaHRA } from '../MedicareRetentionSpecialistAetnaHRA';

export const CustomElement: React.FunctionComponent<CustomElementProps> = ({ referenceId, defaultRender }) => {
  switch (referenceId) {
    case 'sunfire':
      return <Sunfire />;
    case 'sunfire-iframe':
      return <SunfireIframe />;
    case 'sunfire-plan-autocomplete':
      return <SunfirePlanAutocomplete />;
    case 'sunfire-county':
      return <SunfireCounty />;
    case 'csg-submit':
      return <CsgSubmit />;
    case 'csg-underwriting-submit':
      return <UnderwritingSubmit />;
    case 'csg-payment-modal-radio':
      return <PaymentModalRadio />;
    case 'csg-payment-method-radio':
      return <PaymentMethodRadio />;
    case 'warning-sign':
      return <WarningIcon style={{ verticalAlign: 'middle' }} />;
    case 'soa-customer-consents':
      return <MedicareSoa />;
    case 'medicare-enrollment-validation':
      return <MedicareEnrollmentValidation />;
    case 'medicare-dst-notification':
      return <MedicareDSTNotification />;
    case 'pnc-home-coverages':
      return <PncHomeCoverages />;
    case 'schedule-sdr-follow-up':
      return <SDRFollowUp />;
    case 'medicare-plan-transition':
      return <MedicarePlanTransition />;
    case 'medicare-plan-update':
      return <MedicarePlanUpdate />;
    case 'doctors-annual-wellness':
      return <AnnualWellnessExamDoctors />;
    case 'medicare-iep-note':
      return <MedicareIepNote />;
    case 'medicare-skip-eligibility-button':
      return <MedicareSkipEligibilityButton />;
    case 'medicare-go-to-analysis-section-button':
      return <MedicareGoToAnalysisSectionButton />;
    case 'check-my-health-record-hipaa-consent':
      return <CheckMyHealthRecordHipaaConsent />;
    case 'check-my-health-record-hipaa-consent-no-text':
      return <CheckMyHealthRecordHipaaConsent hideQuestionText />;
    case 'check-my-health-record-verify-info':
      return <CheckMyHealthRecordVerifyInfo />;
    case 'check-my-health-record-data-grid':
      return <CheckMyHealthRecordDataGrid />;
    case 'check-my-health-record-data-locked-grid':
      return <CheckMyHealthRecordLockedGrid />;
    case 'check-my-health-record-verify-user':
      return <CheckMyHealthRecordVerifyUser />;
    case 'check-my-health-record-verify-user-check':
      return <CheckMyHealthRecordVerifyUserCheck />;
    case 'check-my-health-record-verify-user-marx':
      return <CheckMyHealthRecordVerifyUserMarx />;
    case 'check-my-health-record-verify-user-marx-new-script':
      return <CheckMyHealthRecordVerifyUserMarxNewScript />;
    case 'check-my-health-record-verify-user-carrier':
      return <CheckMyHealthRecordVerifyUserCarrier />;
    case 'check-my-health-record-lis':
      return <CheckMyHealthRecordLis />;
    case 'current-date':
      return <CurrentDate />;
    case 'dialer-transfer-buttons':
      return <DialerTransferButtons />;
    case 'dialer-transfer-buttons-disabled':
      return <DialerTransferButtons disabled />;
    case 'lead-audience-dynamic-scripts':
      return <AudienceDynamicScripts />;
    case 'check-my-health-record-hipaa-playback':
      return <CheckMyHealthRecordPlaybackHipaaRecording />;
    case 'check-my-health-record-hipaa-playback-button':
      return <CheckMyHealthRecordPlaybackHipaaRecordingButton />;
    case 'check-my-health-record-hipaa-answer':
      return <CheckMyHealthRecordHipaaAnswer />;
    case 'check-my-health-record-hipaa-answer-presales':
      return <CheckMyHealthRecordHipaaAnswerPresales />;
    case 'check-my-health-record-pull-button':
      return <CheckMyRecordPullRecordButton />;
    case 'check-my-health-record-hipaa-approve':
      return <CheckMyHealthRecordHipaaConsentApprove />;
    case 'check-my-health-record-hipaa-consent-attest':
      return <CheckMyHealthRecordHipaaConsentAttest />;
    case 'medicare-pre-sale-qualifying-questions':
      return <MedicarePreSaleQualifyingQuestions />;
    case 'customer-email-with-warning':
      return <CustomerEmailWithWarning />;
    case 'hipaa-accepted-before':
      return <CheckMyHealthRecordHipaaAcceptedBefore />;
    case 'medicare-retention-specialist-hra':
      return <MedicareRetentionSpecialistHRA />;
    case 'medicare-retention-specialist-aetna-hra':
      return <MedicareRetentionSpecialistAetnaHRA />;
    case 'random-date-followup':
      return <RandomDateFollowup />;
    default:
      return defaultRender ?? <div>{ `custom:${referenceId} not implemented` }</div>;
  }
};
