import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../themes';

export const SidePanelLayoutContainer = styled('div')(css`
  display: flex;
  width: 100%;
  height: 100%;
`);

type TabColumnContainerProps = ComponentWithThemeProps & { inverse?: boolean; };
export const TabColumnContainer = styled('div')<TabColumnContainerProps>(({ theme, inverse }) => css`
  background-color: ${inverse ? theme.color.backgroundInverse : 'initial'};
  width: 40px;
  height: 100%;
  border-right: 1px solid ${theme.color.grayLight};
`);

export const ContentColumnContainer = styled('div')(css`
  flex: 1;
  height: 100%;
`);

export const SidePanelLayoutContentContainer = styled('div')(css`
  height: 100%;
`);
export const SidePanelLayoutContent = styled('div')(css`
  height: 100%;
`);
