import { useLazyQuery } from '@apollo/react-hooks';
import {
  QuestionFlowsQueryData,
  QuestionFlowsQueryVariables,
  UseQuestionFlowsQueryOptions,
} from './useQuestionFlowsQuery.interfaces';
import { QUESTION_FLOWS_QUERY } from './useQuestionFlowsQuery';

export function useLazyQuestionFlowsQuery(leadId: string, referenceIds: string[], options: UseQuestionFlowsQueryOptions = {}) {
  const result = useLazyQuery<QuestionFlowsQueryData, QuestionFlowsQueryVariables>(QUESTION_FLOWS_QUERY, {
    variables: {
      leadId,
      referenceIds,
      shallow: !!options?.shallow,
    },
  });
  return result;
}
