import styled from '@emotion/styled';

export const ErrorMessage = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  font-family: Roboto, sans-serif;
`;
