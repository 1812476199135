import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../../themes';

export const CustomerNameContainer = styled('p')<ComponentWithThemeProps>(({ theme }) => css`
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  margin: 0 -10px 7px;
  padding: 9px 0;
  border-bottom: 1px solid ${theme.color.grayLight};
  min-height: 40px;
  background-color: white;
`);
