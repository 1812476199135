import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { ProductTypePropertyAndCasualty } from '@hiq/crm.types';
import { CarriersQueryData } from './useCarriersQuery.interfaces';

const MEDICARE_CARRIERS_QUERY = gql`
  query getAllMedicareCarriers($productType: String) {
    getAllMedicareCarriers {
      id
      name
    }
    getAllPropertyAndCasualtyCarriers(productType: $productType) {
      id
      name
    }
  }
`;

export function useCarriersQuery(productType?: ProductTypePropertyAndCasualty) {
  return useQuery<CarriersQueryData>(
    MEDICARE_CARRIERS_QUERY,
    { variables: { productType } },
  );
}
