import * as React from 'react';
import { ActivitiesContainer } from './Activities.elements';
import {
  ActivityFragment,
} from '../../../../graphql';
import { QueryLoader } from '../../../common/QueryLoader';
import { ActivityFactory } from './ActivityFactory';
import { DateDivider } from './DateDivider';
import { ComponentName } from '../../../common/Page/PageLoadDetector';
import { PrimaryButton } from '../../../common';
import { ActivityProps, GroupedActivities } from './Activity.interfaces';

export const ACTIVITIES_LIMIT = 10;

export const Activity: React.FunctionComponent<ActivityProps> = React.memo(({
  groupedActivities,
  customerLoading,
  loading,
  customerError,
  error,
  isFetchMoreVisible,
  onFetchMoreHandler,
}) => (
  <QueryLoader loading={customerLoading || loading} error={customerError || error} componentName={ComponentName.Activities}>
    <ActivitiesContainer>
      { groupedActivities.map(({ date, activities }) => (
        <div key={activities.map((activity) => activity.hash).join()}>
          <DateDivider timestamp={date} />
          { activities?.map((activity, idx) => (
            <ActivityFactory
              key={activity.hash}
              isLast={idx === activities.length - 1}
              {...activity}
            />
          )) }
        </div>
      )) }
      {isFetchMoreVisible && (
        <PrimaryButton
          disabled={customerLoading || loading || !!customerError}
          fullWidth
          onClick={onFetchMoreHandler}
        >
          Load more
        </PrimaryButton>
      )}
    </ActivitiesContainer>
  </QueryLoader>
));

export function groupActivities(activities: ActivityFragment[]): GroupedActivities {
  const indexedActivities = activities
    .sort((a, b) => (
      new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()))
    .reduce((reduction, activity) => {
      const date = getDate(activity.timestamp);
      const acts = reduction[date] ?? [];
      acts.push(activity);
      return {
        ...reduction,
        [date]: acts,
      };
    }, {} as any);
  return Object.entries(indexedActivities)
    .sort((a, b) => (new Date(b[0]).getTime()) - (new Date(a[0])).getTime())
    .map(([date, acts]) => ({ date, activities: acts })) as GroupedActivities;
}

function getDate(timestamp: string): string {
  const d = new Date(timestamp);
  d.setHours(0, 0, 0, 0);
  return d.toISOString();
}
