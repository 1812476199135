import * as React from 'react';
import { LifeLeadProps } from './LifeLead.interfaces';
import { TermLifeLead } from './panels';
import { useLeadQuery } from '../../../../graphql/queries/useLeadQuery';
import { QueryLoader } from '../../../common/QueryLoader';
import { LifePreConversionLead } from './LifePreConversionLead';
import { LifePostConversionLead } from './LifePostConversionLead';
import { ProductTypeLife } from '@hiq/crm.types';
import { ComponentName } from '../../../common/Page/PageLoadDetector';

export const LifeLead: React.FunctionComponent<LifeLeadProps> = () => {
  const { data, loading, error } = useLeadQuery();

  if (data?.productType === ProductTypeLife.Term) {
    return <TermLifeLead />;
  }

  return (
    <QueryLoader loading={loading} error={error} componentName={ComponentName.LifeLead}>
      <>
        { !data?.isSold && (
          <LifePreConversionLead />
        ) }
        { data?.isSold && (
          <LifePostConversionLead />
        ) }
      </>
    </QueryLoader>
  );
};
