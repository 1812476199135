import { gql } from 'apollo-boost';
import { MutationCustomReturn, useMutationCustom } from '../useMutationCustom';
import { UpsertOverlayMutationResponse, UpsertOverlayMutationVariables } from './useUpsertOverlayMutation.interfaces';
import { OVERLAY_QUERY, OverlayQueryData } from '../../queries';
import { OVERLAY_FRAGMENT, OverlayFragment } from '../../fragments';
import { getEventBus } from '../../../events';
import { OverlayUpsertEvent } from './events';

export const UPSERT_OVERLAY_MUTATION = gql`
  mutation UpsertOverlay($input: OverlayInput!) {
    upsertOverlay(input: $input) {
      success
      message
      overlay {
        ...OverlayFragment
      }
    }
  }
  ${OVERLAY_FRAGMENT}
`;

export function useUpsertOverlayMutation(): MutationCustomReturn<
UpsertOverlayMutationResponse,
UpsertOverlayMutationVariables
> {
  return useMutationCustom<UpsertOverlayMutationResponse, UpsertOverlayMutationVariables>(UPSERT_OVERLAY_MUTATION, {
    update(cache, { data: { upsertOverlay } }) {
      if (upsertOverlay.success) {
        const { id, customerId } = upsertOverlay.overlay;
        const cachedQuery: OverlayQueryData = cache.readQuery({
          query: OVERLAY_QUERY,
          variables: {
            customerId,
          },
        });
        let targetQuery: keyof OverlayQueryData;
        switch (upsertOverlay.overlay.externalType) {
          case 'HealthRecordDrug':
          case 'SunfireDrug':
            targetQuery = 'drugsOverlays';
            break;
          case 'HealthRecordDoctor':
            targetQuery = 'doctorsOverlays';
            break;
          case 'HealthRecordPharmacy':
            targetQuery = 'pharmaciesOverlays';
            break;
          case 'HealthRecordUserClaim':
            targetQuery = 'userClaimsOverlays';
            break;
          case 'HealthRecordAnticipatedNeed':
            targetQuery = 'anticipatedNeedsOverlays';
            break;
          default:
            break;
        }
        const targetOverlays = [...cachedQuery[targetQuery]];
        const idx = targetOverlays?.findIndex((overlay: OverlayFragment<any>) => overlay.id === id) ?? -1;
        const isNewOverlay = idx === -1;
        if (isNewOverlay) {
          targetOverlays.push(upsertOverlay.overlay);
        } else {
          targetOverlays[idx] = upsertOverlay.overlay;
        }
        cache.writeQuery({
          query: OVERLAY_QUERY,
          variables: {
            customerId,
          },
          data: {
            ...cachedQuery,
            [targetQuery]: targetOverlays,
          },
        });
        getEventBus().emit(new OverlayUpsertEvent(upsertOverlay.overlay.externalType, upsertOverlay.overlay.data));
      }
    },
  });
}
