import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { logger } from '../../../services';
import { MedicareApplicationTypeChangeEvent, useEventListener, ZipOrStateChangeEvent } from '../../../events';
import { ApplicationAnchorsQueryData, ApplicationAnchorsQueryVariables } from './useApplicationAnchorsQuery.interfaces';

const APPLICATION_ANCHORS_QUERY = gql`
    query ApplicationAnchors($leadId: ID!) {
      applicationAnchors(leadId: $leadId, allowExternal: true) {
        label,
        value
      }
    }
`;

export function useApplicationAnchorsQuery(leadId: string) {
  const {
    loading,
    error,
    data,
    refetch,
  } = useQuery<ApplicationAnchorsQueryData, ApplicationAnchorsQueryVariables>(APPLICATION_ANCHORS_QUERY, {
    variables: { leadId },
  });

  useEventListener(ZipOrStateChangeEvent, () => {
    refetch().catch((err) => logger.crit(err));
  });
  useEventListener(MedicareApplicationTypeChangeEvent, () => {
    refetch().catch((err) => logger.crit(err));
  });

  return {
    loading, data, error, refetch,
  };
}
