import * as React from 'react';
import { ProductTypeMedicare } from '@hiq/crm.types';
import { isPresent } from '@healthiqeng/core.util.is-present';
import { MedicareQuotePanelFactoryProps } from './MedicareQuotePanelFactory.interfaces';
import { MedicareSupplementQuotePanel } from './MedicareSupplementQuotePanel';
import { MedicareAdvantageQuotePanel } from './MedicareAdvantageQuotePanel';
import { MedicareDentalQuotePanel } from './MedicareDentalQuotePanel';
import { MedicareVisionQuotePanel } from './MedicareVisionQuotePanel';
import { MedicarePrescriptionDrugPlanQuotePanel } from './MedicarePrescriptionDrugPlanQuotePanel';
import { MedicareHospitalIndemnityQuotePanel } from './MedicareHospitalIndemnityQuotePanel';

export const MedicareQuotePanelFactory: React.FunctionComponent<MedicareQuotePanelFactoryProps> = ({
  productType,
}) => {
  if (!isPresent(productType)) {
    return <>Choose a product type to begin quoting</>;
  }
  switch (productType) {
    case ProductTypeMedicare.MedicareSupplement:
      return <MedicareSupplementQuotePanel />;
    case ProductTypeMedicare.MedicareAdvantage:
      return <MedicareAdvantageQuotePanel />;
    case ProductTypeMedicare.Dental:
      return <MedicareDentalQuotePanel />;
    case ProductTypeMedicare.Vision:
      return <MedicareVisionQuotePanel />;
    case ProductTypeMedicare.PrescriptionDrug:
      return <MedicarePrescriptionDrugPlanQuotePanel />;
    case ProductTypeMedicare.HospitalIndemnity:
      return <MedicareHospitalIndemnityQuotePanel />;
    default:
      return (
        <>
          { `Product type ${productType} not implemented` }
        </>
      );
  }
};
