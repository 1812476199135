const FIFTEEN_MINUTES = 1000 * 60 * 15;

export const followUpDurationOptions = [
  { label: '15 Minutes', value: FIFTEEN_MINUTES },
  { label: '30 Minutes', value: 2 * FIFTEEN_MINUTES },
  { label: '45 Minutes', value: 3 * FIFTEEN_MINUTES },
  { label: '1 Hour', value: 4 * FIFTEEN_MINUTES },
  { label: '1 Hour 15 Minutes', value: 5 * FIFTEEN_MINUTES },
  { label: '1 Hour 30 Minutes', value: 6 * FIFTEEN_MINUTES },
  { label: '1 Hour 45 Minutes', value: 7 * FIFTEEN_MINUTES },
  { label: '2 Hours', value: 8 * FIFTEEN_MINUTES },
];
