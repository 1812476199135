import * as React from 'react';
import { ProductTypeMedicare } from '@hiq/crm.types';
import { QuestionCell } from '../FormGenerator/FormGeneratorQuestionFlow/Question/QuestionView';
import { useActiveLeadId } from '../../routers';
import { useFormAnswers } from '../../../hooks';
import { SelectInput } from '../inputs';

export const MedicarePlanUpdate: React.FunctionComponent = () => {
  const leadId = useActiveLeadId();
  const [answers, onChange] = useFormAnswers(leadId);

  const interestedPlanQuestionId = 'lead.medicarePlanTransition';
  const interestedPlanProductType = answers[interestedPlanQuestionId];

  const onInterestedPlanChange = React.useCallback((value) => {
    onChange(interestedPlanQuestionId, value);
  }, [onChange]);

  return (
    <QuestionCell questionText="Agent to select plan type for quote" helpText="">
      <SelectInput
        value={interestedPlanProductType as string}
        onChange={onInterestedPlanChange}
        options={productTypeOptions}
        variant="outlined"
        fullWidth
        disableClearable
      />
    </QuestionCell>
  );
};

const productTypeOptions = [
  {
    label: 'Medicare Advantage',
    value: ProductTypeMedicare.MedicareAdvantage,
  },
  {
    label: 'Vision',
    value: ProductTypeMedicare.Vision,
  },
  {
    label: 'Dental',
    value: ProductTypeMedicare.Dental,
  }, {
    label: 'Medicare Supplement',
    value: ProductTypeMedicare.MedicareSupplement,
  },
];
