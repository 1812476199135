import { FormControlLabel as MuiFormControlLabel, FormControlLabelProps } from '@material-ui/core';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { ComponentWithThemeProps } from '../../../../themes';

type CustomFormControlLabelProps = ComponentWithThemeProps & FormControlLabelProps & { backgroundColor: string };

export const FormControlLabel = styled(MuiFormControlLabel)<CustomFormControlLabelProps>(
  ({ theme, backgroundColor }) => css`
    background-color: ${backgroundColor};
    border-radius: 7px;
    border: 1px solid ${theme.color.grayDark};
    padding-left: 5px;
  `,
);
