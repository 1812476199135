import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { LEAD_FRAGMENT } from '../../fragments';
import { UserLeadsFilter, UserLeadsQueryData, UserLeadsQueryVariables } from './useUserLeadsQuery.interfaces';

const USER_LEADS_QUERY = gql`
  query GetUserLeads($filter: UserLeadsFilter) {
    userViewedLeads(filter: $filter) {
      ...LeadFragment
    }
  }
  ${LEAD_FRAGMENT}
`;

export function useUserLeadsQuery(filter: UserLeadsFilter) {
  return useQuery<UserLeadsQueryData, UserLeadsQueryVariables>(USER_LEADS_QUERY, {
    variables: { filter },
  });
}
