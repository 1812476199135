import { DeleteItemOptions } from './deleteItem.interfaces';
import { getNumArrayItems } from '../getNumArrayItems';
import { getAllSubKeys } from '../getAllSubKeys';

export function deleteItem({
  index, formId, onChange, answers,
}: DeleteItemOptions): void {
  const numItems = getNumArrayItems(formId, answers);
  const allSubKeys = getAllSubKeys(answers, formId);
  onChange(`${formId}.${numItems - 1}.__deleted`, true);

  if (index + 1 !== numItems) {
    // Since we're not deleting the last item, shift all others down
    for (let shiftIndex = index; shiftIndex < numItems - 1; shiftIndex++) {
      allSubKeys.forEach((subKey) => {
        onChange(`${formId}.${shiftIndex}.${subKey}`, answers[`${formId}.${shiftIndex + 1}.${subKey}`] ?? null);
      });
    }
  }

  // Unset all values in the last entry
  allSubKeys.forEach((subKey) => {
    onChange(`${formId}.${numItems - 1}.${subKey}`, null);
  });
}
