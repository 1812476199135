import { CheckboxInput } from '../../../../inputs';
import React, { useEffect, useState } from 'react';
import { PackageSelectionProps } from './PackageSelection.interfaces';
import { SunfireDosagePackage } from '@hiq/crm.types';

export const PackageSelection = ({
  onPackageSelected,
  packages,
  dosage,
  dosagePackage,
}: PackageSelectionProps) => {
  const [dosagePackageOptions, setDosagePackageOptions] = useState(packagesToCheckboxOptions(packages, dosagePackage));

  const handleOnChange = (values: string[]) => {
    const value = values[0];
    const dosagePackage: SunfireDosagePackage = packages.find(({ packageId }) => packageId === value);
    onPackageSelected(dosagePackage);
  };

  useEffect(() => {
    const packagesToSet = packagesToCheckboxOptions(packages, dosagePackage);
    setDosagePackageOptions(packagesToSet);
  }, [packages, dosagePackage]);

  return (
    <div>
      <div>
        Select a package for&nbsp;
        {dosage.name}
      </div>
      <CheckboxInput
        options={dosagePackageOptions}
        onChange={handleOnChange}
        value={dosagePackage ? [dosagePackage.packageId] : []}
      />
    </div>
  );
};

function packagesToCheckboxOptions(packages: SunfireDosagePackage[], dosagePackage: SunfireDosagePackage) {
  return packages
    .filter(({ packageId }) => (dosagePackage ? dosagePackage.packageId === packageId : true))
    .map((dosagePackge: SunfireDosagePackage) => {
      const labelAppendix = dosagePackge.isCommonPackage
        ? '&nbsp;&nbsp;&nbsp;&nbsp;* This is the most common dosage package' : '';
      const label = `${dosagePackge.packageDescription}${labelAppendix}`;
      return {
        label,
        value: dosagePackge.packageId,
      };
    });
}
