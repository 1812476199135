import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { MQ } from '../constants';
import { resetDiv } from '../resets';

export type IDirection = 'left' | 'center' | 'right';
export interface IBoxContainerProps {
  boxAlign: [IDirection, IDirection, IDirection, IDirection];
  boxTop: [number, number, number, number];
  boxBottom: [number, number, number, number];
  boxLeft: [number, number, number, number];
  boxRight: [number, number, number, number];
}

const directionToJustifyContentMap = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

export const BoxContainer = styled('div')<IBoxContainerProps>(resetDiv, (props) => css`
    display: flex;
    width: 100%;
    ${MQ.DESKTOP} {
      justify-content: ${directionToJustifyContentMap[props.boxAlign[0]]};
      padding-top: ${`${props.boxTop[0] * 4}px`};
      padding-bottom: ${`${props.boxBottom[0] * 4}px`};
      padding-left: ${`${props.boxLeft[0] * 4}px`};
      padding-right: ${`${props.boxRight[0] * 4}px`};
    }
    ${MQ.TABLET} {
      justify-content: ${directionToJustifyContentMap[props.boxAlign[1]]};
      padding-top: ${`${props.boxTop[1] * 4}px`};
      padding-bottom: ${`${props.boxBottom[1] * 4}px`};
      padding-left: ${`${props.boxLeft[1] * 4}px`};
      padding-right: ${`${props.boxRight[1] * 4}px`};
    }
    ${MQ.MOBILE} {
      justify-content: ${directionToJustifyContentMap[props.boxAlign[2]]};
      padding-top: ${`${props.boxTop[2] * 4}px`};
      padding-bottom: ${`${props.boxBottom[2] * 4}px`};
      padding-left: ${`${props.boxLeft[2] * 4}px`};
      padding-right: ${`${props.boxRight[2] * 4}px`};
    }
    ${MQ.MOBILE_XS} {
      justify-content: ${directionToJustifyContentMap[props.boxAlign[3]]};
      padding-top: ${`${props.boxTop[3] * 4}px`};
      padding-bottom: ${`${props.boxBottom[3] * 4}px`};
      padding-left: ${`${props.boxLeft[3] * 4}px`};
      padding-right: ${`${props.boxRight[3] * 4}px`};
    }
  `);
