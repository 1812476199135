import { gql } from 'apollo-boost';
import { MutationCustomReturn, useMutationCustom } from '../useMutationCustom';
import { ScheduleSDRMutationResponse, ScheduleSDRMutationVariables } from './useScheduleSDRFollowUpMutation.interfaces';

const SCHEDULE_SDR_FOLLOW_UP_MUTATION = gql`
  mutation ScheduleSDRFollowUp($leadId: ID!, $date: Date!, $timezone: String!) {
    scheduleSDRFollowUp(leadId: $leadId, date: $date, timezone: $timezone) {
      success
    }
  }
`;

export function useScheduleSDRFollowUpMutation(): MutationCustomReturn<ScheduleSDRMutationResponse, ScheduleSDRMutationVariables> {
  return useMutationCustom<ScheduleSDRMutationResponse, ScheduleSDRMutationVariables>(SCHEDULE_SDR_FOLLOW_UP_MUTATION);
}
