import React from 'react';
import { SelectInput } from '../../inputs';
import { useFormAnswers } from '../../../../hooks';
import { useActiveLeadId } from '../../../routers';
import { LisContainer, LisLink, LisText } from './CheckMyHealthRecordLis.elements';

const Options2023 = [
  {
    label: '$1.45 - $4.30 for covered drugs',
    value: 'LTE100',
  },
  {
    label: '$4.15 - $10.35 for covered drugs',
    value: 'LTE135_L',
  },
  {
    label: 'Partial low-income subsidy',
    value: 'BT136_149_',
  },
];

const Options2022 = [
  {
    label: '$1.35 - $4.00 for covered drugs',
    value: 'LTE100',
  },
  {
    label: '$3.95 - $9.85 for covered drugs',
    value: 'LTE135_L',
  },
  {
    label: 'Partial low-income subsidy',
    value: 'BT136_149_',
  },
];

enum PossibleYears {
  LIS2022 = '2022',
  LIS2023 = '2023'
}

const LIS_QUESTION_ID = 'lead.sunfire.v2.lowIncomeSubsidyCode';

export const CheckMyHealthRecordLis = () => {
  const leadId = useActiveLeadId();
  const [answers, onChange] = useFormAnswers(leadId, true);
  const [selectedYear, setSelectedYear] = React.useState(PossibleYears.LIS2022);

  const toggleYear = React.useCallback(() => {
    if (selectedYear === PossibleYears.LIS2022) setSelectedYear(PossibleYears.LIS2023);
    else setSelectedYear(PossibleYears.LIS2022);
  }, [setSelectedYear, selectedYear]);

  const options = React.useMemo(() => {
    if (selectedYear === PossibleYears.LIS2022) return Options2022;
    return Options2023;
  }, [selectedYear]);

  const handleChange = React.useCallback((lisOption) => {
    onChange(LIS_QUESTION_ID, lisOption);
  }, [onChange]);

  return (
    <LisContainer>
      <LisText>
        Drug copay / coinsurance for&nbsp;
        {selectedYear}
&nbsp;
        <LisLink onClick={toggleYear}>
          show&nbsp;
          {selectedYear === PossibleYears.LIS2022 ? PossibleYears.LIS2023 : PossibleYears.LIS2022}
&nbsp;values
        </LisLink>
      </LisText>
      <SelectInput
        value={answers[LIS_QUESTION_ID]}
        onChange={handleChange}
        fullWidth
        options={options}
      />
    </LisContainer>
  );
};
