import { gql } from 'apollo-boost';
import { MutationCustomReturn, useMutationCustom } from '../useMutationCustom';

import { LEAD_FRAGMENT } from '../../fragments/lead';
import { ChangeOwnerMutationResponse, ChangeOwnerMutationVariables } from './useChangeOwnerMutation.interfaces';

export const CHANGE_OWNER_MUTATION = gql`
  mutation ChangeLeadOwnerMutation($leadId: ID!, $ownerId: ID!) {
    changeOwner(leadId: $leadId, ownerId: $ownerId) {
      code
      success
      message
      timestamp

      lead {
        ...LeadFragment
      }
    }
  }
  ${LEAD_FRAGMENT}
`;

export function useChangeOwnerMutation(): MutationCustomReturn<ChangeOwnerMutationResponse, ChangeOwnerMutationVariables> {
  return useMutationCustom<ChangeOwnerMutationResponse, ChangeOwnerMutationVariables>(CHANGE_OWNER_MUTATION);
}
