import { MutationHookOptions } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { useMutationCustom } from '../useMutationCustom';
import {
  AddNumbersToDoNotCallMutationResponse,
  AddNumbersToDoNotCallMutationVariables,
} from './useAddNumbersToDoNotCallMutation.interfaces';

const ADD_NUMBERS_TO_DNC_MUTATION = gql`
  mutation AddNumbersToDoNotCall($input: AddNumbersToDoNotCallInput!) {
    addNumbersToDoNotCall(input: $input) {
      code
      success
      message
      timestamp
    }
  }
`;

export function useAddNumbersToDoNotCall(
  options: MutationHookOptions<AddNumbersToDoNotCallMutationResponse, AddNumbersToDoNotCallMutationVariables> = {},
) {
  return useMutationCustom<AddNumbersToDoNotCallMutationResponse, AddNumbersToDoNotCallMutationVariables>(
    ADD_NUMBERS_TO_DNC_MUTATION,
    options,
  );
}
