import styled from '@emotion/styled';

export const CompareData = styled.td`
  padding: 10px;
`;

export const CompareDataBold = styled(CompareData)`
  font-weight: bold;
  font-size: 20px;
`;

export const CompareRow = styled.tr`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
`;

export const Bold = styled.span`
  font-weight: bold;
  font-size: 20px;
`;

export const Name = styled.span`
  font-size: 16px;
`;

export const CompareContainer = styled.table`
  width: 100%;
  background-color: white;
`;

export const CompareSmallContainer = styled.table`
  width: 100%;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
`;

export const SmallHeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 4px 2px -2px rgba(0, 0, 0, 0.25);
    padding: 16px 32px 16px 0px;
    height: 88px;
    width: 100%;
`;

export const SmallHeaderPlanName = styled.span`
    font-weight: bold;
    font-size: 16px;
`;

export const SmallHeaderFilters = styled.span`
  font-size: 16px;
  color: #7F8385;
`;

export const SmallHeaderText = styled.span`
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #1A1C1F;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
`;
