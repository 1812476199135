import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { HealthGridCellProps } from './CheckMyHealthRecordDataGrid.interfaces';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';

export const HealthRecordCell = styled.div<HealthGridCellProps>(({ smaller }) => css`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-height: 63px;
  ${smaller ? 'font-size: 12px;' : ''}
`);

export const Name = styled.span<HealthGridCellProps>(({ smaller }) => css`
  font-size: ${smaller ? '14px' : '20px'};
  font-weight: 500;
  word-break: break-word;
  line-height: ${smaller ? 1.4 : 1.2};
`);

export const Text = styled.span<HealthGridCellProps>(({ smaller }) => css`
  font-size: ${smaller ? '12px' : ''};
`);

export const Paragraph = styled.p`
  margin: 1px;
`;

export const TextLink = styled.span`
  text-decoration-line: underline;
  color: #0075CA;
  cursor: pointer;
`;

export const ParagraphWrap = styled.p`
  margin: 1px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const UnorderedList = styled.ul`
  margin: 0;
`;

export const Bold = styled.span`
  font-weight: bold;
`;

export const Green = styled(Bold)`
  color: green;
`;

export const SectionTitle = styled.div`
  font-weight: bold;
  color: #0075CA;
  font-size: 28px;
`;

export const CTA = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Divider = styled.div`
  height: 1px;
  margin: 35px 20px;
  background-color: #7F8385;
`;

export const Background = styled.div`
  background-color: #F3F5F7;
`;

export const PanelInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #F8F9FA;
  margin: 16px 0px;
  padding: 22px 20px;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  align-items: center;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 600px;
`;

export const PaddingVertical = styled.div`
  padding: 30px 0;
`;

export const PaddingBottom = styled.div`
  padding-bottom: 30px;
`;

const CMHRDGButton = withStyles({
  root: {
    height: 48,
    padding: '0 20px',
    textTransform: 'capitalize',
  },
})(Button);

export const AddItemButton = withStyles({
  root: {
    background: '#05AB5B',
    color: 'white',
    marginLeft: 16,
    '&:hover': {
      background: 'rgba(5,171,91,0.8)',
    },
  },
})(CMHRDGButton);

export const RemoveItemButton = withStyles({
  root: {
    background: '#FFFFFF',
    border: '1px solid #4A4A4A',
  },
})(CMHRDGButton);

export const SaveItemButton = withStyles({
  root: {
    background: 'rgba(0, 117, 202, 1)',
    color: 'white',
    marginLeft: 16,
    '&:hover': {
      background: 'rgba(0, 117, 202, 0.8)',
    },
  },
})(CMHRDGButton);

export const EditPanelPlaceholder = styled.div`
  height: 350px;
  background: #FFFFFF;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 20px;
  width: 100%;
`;

export const GridElementContainer = styled.div`
  padding: 10px;
`;

export const SelectColumnContainer = styled.div`
  min-width: 130px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
`;
