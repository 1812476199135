import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../themes';

export const NavBarContainer = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  background-color: ${theme.color.backgroundInverse};
  height: 50px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
  box-shadow: 0 5px 5px 0 rgba(0,0,0,0.2);
  flex-wrap: wrap;
`);

export const NavBarColumnContainer = styled('div')(css`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
`);

export const NavBarCenterContainer = styled('div')(css`
  display: flex;
  align-items: center;
`);

export const NavBarTitle = styled('div')<ComponentWithThemeProps>(({ theme }) => css`
  color: ${theme.color.textInverse};
  font-weight: 600;
  font-size: 18px;
  margin-left: 10px;
  min-width: 300px;
`);
