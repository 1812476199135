import * as React from 'react';
import Tabs from '@material-ui/core/Tabs';
import { SidePanelLayoutProps, SidePanelLayoutTab } from './SidePanelLayout.interfaces';
import {
  SidePanelLayoutContainer,
  SidePanelLayoutContentContainer,
  SidePanelLayoutContent,
  TabColumnContainer,
  ContentColumnContainer,
} from './SidePanelLayout.elements';
import { SidePanelTab } from './SidePanelTab';
import { noop } from '../../../util';
import { CollapseColumnsContext } from '../../contexts';
import { useLocation } from '@reach/router';
import { emitAnalyticsEventNoLimit } from '../../../events/emitAnalyticsEvent';
import { useDialerTransfer } from '../../common/DialerTransferButtons/useDialerTransferButtons/useDialerTransfer';

const getTabIndexById = (tabs: SidePanelLayoutTab[], id: string): number => tabs.findIndex((tab) => tab.id === id);

function getInitialIndex(tabs: SidePanelLayoutTab[], initialId: string): number {
  const index = tabs.map((tab) => tab.id).indexOf(initialId);
  return Math.max(index, 0);
}

export const SidePanelLayout: React.FunctionComponent<SidePanelLayoutProps> = ({
  initialId,
  tabs,
  collapsed,
  onClick,
  inverse,
}) => {
  const [activeTab, setActiveTab] = React.useState(getInitialIndex(tabs, initialId));
  const { toggle } = React.useContext(CollapseColumnsContext);

  const handleClick = React.useCallback((index: number) => {
    setActiveTab(index);
    if (onClick) {
      onClick();
    }
  }, [setActiveTab, onClick]);

  const { selectedDisposition } = useDialerTransfer();

  React.useEffect(() => {
    const followupIndex = 4;
    if (selectedDisposition?.label === 'Pipeline') {
      setActiveTab(followupIndex);
    }
  }, [selectedDisposition]);

  const location = useLocation();
  React.useEffect(() => {
    // Should match: /customer/9da55e57-e156-4b51-ae55-92266a699a13/lead/d14ac0ad-ed20-4583-907b-dff337cc1bc9/interview#quote
    const pathRegex = /^\/customer\/[\w\d-]+\/lead\/[\w\d-]+\/interview$/;
    if (location && pathRegex.test(location.pathname) && location.hash === '#quote') {
      const diagnosisTabIndex = getTabIndexById(tabs, 'diagnoses');
      if (diagnosisTabIndex > -1) {
        toggle();
        setActiveTab(diagnosisTabIndex);
      }
    } else if (!tabs[activeTab]?.id) {
      setActiveTab(getInitialIndex(tabs, initialId));
    }
  }, [location, tabs, initialId]);

  React.useEffect(() => {
    emitAnalyticsEventNoLimit('Section', { section: location.hash });
  }, [location.hash]);

  return (
    <SidePanelLayoutContainer data-qa="side-panel-layout">
      <TabColumnContainer inverse={inverse}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={activeTab}
          onChange={noop}
        >
          { tabs.map(({
            id, tooltip, icon, hide,
          }, index) => (!hide ? (
            <SidePanelTab
              key={id}
              id={id}
              icon={icon}
              tooltip={tooltip}
              onClick={handleClick}
              index={index}
              active={!collapsed && activeTab === index}
            />
          ) : null))}
        </Tabs>
      </TabColumnContainer>
      { !collapsed && (
        <ContentColumnContainer>
          { tabs.map(({ id, content }, index) => (
            <SidePanelLayoutContentContainer
              key={id}
              role="tabpanel"
              hidden={activeTab !== index}
              data-qa={`side-panel-layout-tab-content-${id}`}
            >
              { activeTab === index && (
                <SidePanelLayoutContent>
                  { content }
                </SidePanelLayoutContent>
              ) }
            </SidePanelLayoutContentContainer>
          )) }
        </ContentColumnContainer>
      ) }
    </SidePanelLayoutContainer>
  );
};
