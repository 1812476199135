import { gql } from 'apollo-boost';

export const QUESTION_FRAGMENT = gql`
  fragment QuestionFragment on Question {
    id
    questionText
    label
    type
    placeholder
    helpText
    options {
      label
      value
    }
    optionsId
    dependencies
    lockedCustomComponent
    leadId
    defaultValue
    minLength
    maxLength
    allowClientRefused
    maxItems
  }
`;
