import * as React from 'react';
import { QuoteCellLayoutProps } from './QuoteCellLayout.interfaces';
import {
  QuoteCellLayoutContainer,
  QuoteCellCarrierColumn,
  QuoteCellDetailColumn,
  QuoteCellPinColumn,
  QuoteCellPremiumColumn,
  QuoteCellSelectColumn,
} from './QuoteCellLayout.elements';

export const QuoteCellLayout: React.FunctionComponent<QuoteCellLayoutProps> = ({
  pinColumn,
  carrierColumn,
  detailColumn,
  premiumColumn,
  selectColumn,
}) => (
  <QuoteCellLayoutContainer>
    <QuoteCellPinColumn>
      { pinColumn }
    </QuoteCellPinColumn>
    <QuoteCellCarrierColumn>
      { carrierColumn }
    </QuoteCellCarrierColumn>
    <QuoteCellDetailColumn>
      { detailColumn }
    </QuoteCellDetailColumn>
    <QuoteCellPremiumColumn>
      { premiumColumn }
    </QuoteCellPremiumColumn>
    <QuoteCellSelectColumn>
      { selectColumn }
    </QuoteCellSelectColumn>
  </QuoteCellLayoutContainer>
);
