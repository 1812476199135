import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../../../../themes';

export const QuestionTagAsterisk = styled('span')<ComponentWithThemeProps>(({ theme }) => css`
  color: ${theme.color.error};
  font-size: 20px;
  font-weight: bold;
  margin-left: 5px;
`);
