import * as React from 'react';
import { RelatedCustomerProps } from './RelatedCustomer.interfaces';
import {
  RelatedCustomerContainer,
  RelatedCustomerLink,
  RelatedCustomerLabel,
} from './RelatedCustomer.elements';

export const RelatedCustomer: React.FunctionComponent<RelatedCustomerProps> = ({
  id,
  relationship,
  url,
  name,
}) => (
  <RelatedCustomerContainer>
    <RelatedCustomerLabel>
      { relationship }
    </RelatedCustomerLabel>
    <RelatedCustomerLink target="_blank" href={url ?? `/customer/${id}`}>
      { name }
    </RelatedCustomerLink>
  </RelatedCustomerContainer>
);
