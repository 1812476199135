import React, { FC } from 'react';
import { ProductTypeLife } from '@hiq/crm.types';
import { LifeQuotePanelFactoryProps } from './LifeQuotePanelFactory.interfaces';
import { WholeLifeQuotePanel } from './WholeLifeQuotePanel';
import { JuvenileQuotePanel } from './JuvenileQuotePanel';
import { AccidentalQuotePanel } from './AccidentalQuotePanel';
import { CriticalIllnessQuotePanel } from './CriticalIllnessQuotePanel';

export const LifeQuotePanelFactory: FC<LifeQuotePanelFactoryProps> = ({ productType }) => {
  switch (productType) {
    case ProductTypeLife.Juvenile:
      return <JuvenileQuotePanel />;
    case ProductTypeLife.Accidental:
      return <AccidentalQuotePanel />;
    case ProductTypeLife.CriticalIllness:
      return <CriticalIllnessQuotePanel />;
    default:
      return <WholeLifeQuotePanel />;
  }
};
