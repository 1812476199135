import * as React from 'react';
import { CopyButtonContainer } from './TermLifeLead.elements';
import { LeadPanelCard } from '../../../elements';
import { useLeadQuery } from '../../../../../../graphql/queries/useLeadQuery';
import { PrimaryButton } from '../../../../../common/inputs/PrimaryButton';
import { copyToClipboard } from '@healthiqeng/core.util.copy-to-clipboard';

export const TermLifeLead: React.FunctionComponent = () => {
  const { data } = useLeadQuery();
  const leadUrl = `https://hiq.my.salesforce.com/apex/apex/LeadOppRedirect?id=${data?.sfId}`;
  return (
    <LeadPanelCard>
      <h2>Term life insurance leads are managed through Salesforce</h2>
      <p>
        If you are selling a different product, use the
        <b>+SPOUSE/CROSS-SALE</b>
        {' '}
        tab
      </p>
      { data?.sfId ? (
        <div>
          <a href={leadUrl}>
            Salesforce Lead
          </a>
          <CopyButtonContainer>
            <PrimaryButton onClick={() => copyToClipboard(leadUrl)} variant="contained" size="small">
              Copy
            </PrimaryButton>
          </CopyButtonContainer>
        </div>
      ) : (
        <p>Salesforce lead not yet created</p>
      ) }
    </LeadPanelCard>
  );
};
