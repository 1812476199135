import styled from '@emotion/styled';
import { css } from '@emotion/core';

export const Container = styled('div')(css``);

export const ButtonsContainer = styled('div')(css`
  margin-top: 15px;
  display: flex;
`);

export const LockedMessage = styled('p')(css`
  text-align: left;
`);
