import { useMemo } from 'react';
import {
  FormAnswers,
  CompareRuleProperties,
  SimpleCompareRuleProperties,
  CompositeCompareRuleProperties,
  ComposableOperator,
} from '@hiq/crm.types';
import { isVisible } from '@hiq/crm.core.util.is-visible';
import { UseConditionalRenderingHook } from './useConditionalRendering.interfaces';
import { logger } from '../../../../services';

interface IsRuleVisibleProps {
  questionId: string;
  parentQuestionId?: string;
  answers: FormAnswers;
  visibleCondition: CompareRuleProperties;
}

interface IsSimpleRuleVisibleProps {
  questionId: string;
  parentQuestionId?: string;
  answers: FormAnswers;
  visibleCondition: SimpleCompareRuleProperties;
}

export const useConditionalRendering: UseConditionalRenderingHook = ({
  questionId,
  parentQuestionId,
  answers,
  visibleCondition,
}) => {
  const visible = useMemo(() => isRuleVisible({
    questionId,
    parentQuestionId,
    answers,
    visibleCondition,
  }), [questionId, parentQuestionId, answers, visibleCondition]);

  return visible;
};

function isRuleVisible(props: IsRuleVisibleProps): boolean {
  const {
    questionId, answers, visibleCondition, parentQuestionId,
  } = props;

  if (!visibleCondition) {
    return true;
  }

  // If visibility condition is a composite rule...
  if (visibleCondition.hasOwnProperty('operands')) { // eslint-disable-line no-prototype-builtins
    const { operands, composableOperator } = visibleCondition as CompositeCompareRuleProperties;
    const results = operands.map((cmp) => isRuleVisible({
      questionId, answers, parentQuestionId, visibleCondition: cmp,
    }));
    switch (composableOperator) {
      case ComposableOperator.And:
        return results.every(Boolean);
      case ComposableOperator.Or:
        return results.some(Boolean);
      default:
        return true;
    }
  }

  return isSimpleRuleVisible({
    questionId,
    parentQuestionId,
    answers,
    visibleCondition: visibleCondition as SimpleCompareRuleProperties,
  });
}

function isSimpleRuleVisible(props: IsSimpleRuleVisibleProps): boolean {
  const {
    questionId, answers, visibleCondition, parentQuestionId,
  } = props;

  if (!visibleCondition) {
    return true;
  }

  const value = getValue(answers, questionId);
  const parentValue = getValue(answers, parentQuestionId);
  const compareValue = getValue(answers, visibleCondition?.compareQuestionId);

  try {
    return isVisible(value, parentValue, visibleCondition, compareValue);
  } catch (e) {
    logger.crit(e);
    return true;
  }
}

function getValue(answers: FormAnswers, compareId: string) {
  return answers[compareId];
}
