import { JsonCompatible } from '@healthiqeng/core.types';
import * as React from 'react';
import { QuestionProps } from './Question.interfaces';
import { QueryLoader } from '../../../QueryLoader';
import { useQuestionQuery } from '../../../../../graphql';
import { QuestionView } from './QuestionView';
import { customViewRendererFactory } from '../../../QuestionFlow/QuestionFlowRenderer/CustomViewRenderer';
import { CustomElement } from '../../../CustomElement';
import { isPresent } from '@healthiqeng/core.util.is-present';
import { CLIENT_REFUSED_OPTION, NOT_SPECIFIED_OPTION } from '../../../../../constants';
import { QuestionOption } from '@hiq/crm.types';
import { isTextInputQuestionType } from '../../../../../util';

const QuestionFC: React.FunctionComponent<QuestionProps> = ({
  leadId,
  referenceId,
  answersKey,
  tags,
  helpText,
  onChange,
  locked,
  arrayFormIndex,
  value,
  overrideQuestionText,
}) => {
  const { error, loading, data } = useQuestionQuery(leadId, referenceId);
  const CustomViewRenderer = customViewRendererFactory(CustomElement);

  const handleChange = React.useCallback(
    (value: JsonCompatible) => (onChange(answersKey || referenceId, value, isTextInputQuestionType(data?.type))),
    [answersKey, referenceId, onChange, data?.type],
  );

  React.useEffect(() => {
    if (isPresent(data?.defaultValue) && !isPresent(value)) {
      handleChange(data.defaultValue);
    }
  }, []);

  return (
    <QueryLoader loading={loading} error={error} componentName="QuestionFC">
      <QuestionView
        {...data}
        locked={locked}
        tags={tags}
        helpText={helpText ?? data?.helpText}
        value={value}
        onChange={handleChange}
        CustomViewRenderer={CustomViewRenderer}
        referenceId={referenceId}
        answersKey={answersKey}
        arrayFormIndex={arrayFormIndex}
        dataQa={`question-${answersKey ?? referenceId}`}
        minLength={data?.minLength}
        maxLength={data?.maxLength}
        options={getQuestionOptions(data?.options, data?.allowClientRefused)}
        overrideQuestionText={overrideQuestionText}
      />
    </QueryLoader>
  );
};

export const Question = React.memo(QuestionFC);

function getQuestionOptions(options: QuestionOption[] | null, allowClientRefused: boolean): QuestionOption[] | null {
  if (!options) return options;
  if (allowClientRefused) {
    return [
      CLIENT_REFUSED_OPTION,
      NOT_SPECIFIED_OPTION,
      ...options,
    ];
  }

  return options;
}
