import * as React from 'react';
import { IHtmlContentProps } from './HtmlContent.interfaces';

export class HtmlContent extends React.Component<IHtmlContentProps> {
  public render() {
    return (
      <span dangerouslySetInnerHTML={{ __html: this.props.children }} />
    );
  }
}
