import { useLocation, useMatch, useNavigate } from '@reach/router';
import { useCallback } from 'react';
import { logger } from '../../../../services';

export function useLeadSectionRoute(defaultSection: string): [string, (id: string) => void] {
  const navigate = useNavigate();
  const location = useLocation();
  const match = useMatch('/customer/:customerId/lead/:leadId/call/:callUrlPath/:leadSection/*')
    ?? useMatch('/customer/:customerId/lead/:leadId/call/:callUrlPath/*')
    ?? useMatch('/customer/:customerId/lead/:leadId/:leadSection/*');
  const onChange = useCallback((section: string) => {
    if (!section) {
      return;
    }

    if (!match?.leadSection) {
      navigate(`${location.pathname}/${section}`.replace(/\/+/g, '/'))
        .catch((error) => logger.crit(error));
      return;
    }

    navigate(location.pathname.replace(match?.leadSection, section))
      .catch((error) => logger.crit(error));
  }, [navigate, location.pathname, match?.leadSection]);
  return [
    match?.leadSection ?? defaultSection,
    onChange,
  ];
}
