import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentWithThemeProps } from '../../../../../themes';

export const DateDividerContainer = styled('hr')<ComponentWithThemeProps>(({ theme }) => css`
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: ${theme.color.grayLight};
  text-align: center;
  height: 1.5em;
  &:before {
    content: '';
    background-color: ${theme.color.grayLight};
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: black;
    margin-bottom: 1px;
    padding: 0 .5em;
    line-height: 1.5em;
    color: ${theme.color.grayDark};
    background-color: #ffffff;
  }
`);
